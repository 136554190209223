import React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
//import BoxExtraDataFormatter from '../../Utils/BoxExtraDataFormatter';
import { getIconForNode } from '../../../Models/Roles.js';
import UUID from "../../../Utils/UUID";
import './DeviceSelector.css';

export default function DeviceSelector(props) {
	const factoryUUID = new UUID();
	const [value, setValue] = React.useState( ( props.defaultValue ) ? props.defaultValue : "" );
	
	const getDeviceById = id => {
		let found = null;
		if( props.devices !== null && props.devices !== undefined ) {
			props.devices.map( (box) => {
				if( box.id === id ) {
					found = box;
				}
				return true;
			} );
		}
		return found;
	}
	
	const handleChange = evt => {
		setValue( evt.target.value );
		if( props.onChange !== null && props.onChange !== undefined ) {
			props.onChange( evt , props.devices.indexOf( getDeviceById( evt.target.value ) ) );
		}
	};
	
	const isActiveDevice = device => {
		let isActive = false;
		let searchDate = new Date( Date.now() + (1000 * 60) ).getTime();
		if( device && device.periods ) {
			device.periods.forEach( period => {
				if( period ) {
					try {
						let begin = new Date (Date.parse( period.startedAt ) );
						let end = new Date (Date.parse( period.endedAt ) );
						
						if( isNaN( end.getTime() ) ) {
							end = new Date ( new Date().getTime() - ( new Date().getTimezoneOffset() * 60 * 1000 ) ); //currently opened period
						}
						
						if( searchDate >= begin.getTime() && end.getTime() >= searchDate ) {
							isActive = true;
						}
					} catch ( error ) {
						console.error( error );
					}
					
				}
			} );
		}
		return isActive;
	}
	
	const renderMenuItem = ( device ) => {
		
		if( device ) {
			if( props.activeOnly && !isActiveDevice( device ) ) {
				if( isActiveDevice( device ) ) {
					
				} else {
					return null;
				}
			}
			
			let optValue =  device.id;
			let optLabel =  renderDeviceLabel( device ) ;

			if( props.showIcon /*&& props.icons !== null && props.icons !== undefined /*&& props.icons.hasOwnProperty(optionValue)*/ ) {
				return (
					<MenuItem  key={`menu-device-selector-item-${optValue}-${factoryUUID.generate()}`}
							   value={optValue}>
						<FontAwesomeIcon icon={getIconForNode(device)} style={{fontSize:"1.25em"}}/>
						<span className="ml-2">{optLabel}</span>
					</MenuItem>
				);
			}
			return (
				<MenuItem  key={`menu-device-selector-item-${optValue}-${factoryUUID.generate()}`}
						   value={optValue}>
					{optLabel}
				</MenuItem>
			);
		}
		return (
			<MenuItem key={`menu-device-selector-item-none`} value="">
				<em>{I18n.get("None")}</em>
			</MenuItem>
		);
	}
	
	const renderDeviceLabel = (device) => {
		let label = "";
		if ( device ) {
			if ( device.hasOwnProperty("name") ) {
				label += `${device.name}`;
			}

			let extraData = null;//new BoxExtraDataFormatter( device , null ).format( false );
			if ( extraData !== null && extraData !== undefined && extraData.trim().length > 0 ) {
				label += ` ${extraData}`;
			}
			return label;
		}
		return "Error";
	};
	
	const options = [];
	if( props.allowEmpty ) {
		options.push( renderMenuItem ( null ) );
	}

	if( props.devices ) {
		props.devices.forEach( (box) => {
			options.push( renderMenuItem( box ) );
		} );
	}
	
	let css = "select-device-selector";
	let cssSelect = "combo-device-selector";
	let cssLabel = "label-device-selector";
	if( props.isDarkStyle ) {
		css += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}
	
	if( value !== props.defaultValue ) {
		setValue( props.defaultValue );
	}

	return (
		<React.Fragment>
			<FormControl variant="outlined" className={css} disabled={options.length < 2}>
				<InputLabel className={cssLabel} 
							id={`input-combo-device-selector`}>
					{props.label}
				</InputLabel>
				<Select classes={{selectMenu:cssSelect}}
						labelId={`combo-device-selector-label`}
						id={`combo-device-selector-select`}
						value={value}
						onChange={handleChange}
						MenuProps={{
							classes:{paper:cssSelect}
						}}
						label={props.label} >
					{options.map( item => item )}
				</Select>
			</FormControl>
		</React.Fragment>
	);
}
