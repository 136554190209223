import React from 'react';
import { I18n } from 'aws-amplify/utils';
import Grid from '@mui/material/Grid';
import './BoxHardware.css';

export default function BoxHardware( props ) {
	const convertSize = ( size ) => {
		return size / 1000;
	}
	
	const adaptSize = ( sizeInBytes ) => {
		let targetedUnits = ["KB" , "MB" , "GB" , "TB"];
		let currentValue = sizeInBytes;
		let returned = sizeInBytes + " Bytes";
		targetedUnits.map( (unit) => {
			if( currentValue > 1000 ) {
				currentValue = convertSize(currentValue);
				returned = new Intl.NumberFormat( props.locale , { maximumSignificantDigits: 2 }).format( Math.round( currentValue * 100 ) / 100 ) + " " + unit;
			}
			return true;
		} )
		return returned;
	}
	
	const renderDevices = ( box ) => {
		return(
				<div className="box">
					{box.informations.attributes.devices.map( device => {
						return (
							<div className="properties-line" key={`box-hardware-${device}`}>
								<div className="label">{device.split("_")[2]}</div>
								<div className="value">{device.split("_")[3]}</div>
							</div>
						);
					} )}
				</div>
			);
	}
	
	const renderPropertiesLine = ( label , value ) => {
		if( label === "vertical_resolution" ) {
			return null; //compute to horizontal no displayed line
		} else if ( label === "date" ) {
			//override bios date display
			try {
				//"20190718000000.000000+000"
				let date = new Date();
				date.setFullYear( parseInt(`${value}`.substring(0, 4) , 10 ) );	
				date.setMonth( parseInt(`${value}`.substring(4, 6) , 10 ) ); 
				date.setDate( parseInt(`${value}`.substring(6, 8) , 10 ) ); 
				date.setHours( parseInt(`${value}`.substring(8, 10) , 10 ) ); 
				date.setMinutes( parseInt(`${value}`.substring(10, 12) , 10 ) ); 
				date.setSeconds( parseInt(`${value}`.substring(12, 14) , 10 ) ); 
				value = date.toLocaleString( props.locale ).replace("," , "");
			} catch ( error ) {
				console.log( error );
			}
		} else if ( label === "disk_free" || label === "disk_size" || label === "memory_free" || label === "memory_size" ) {
			if ( label === "memory_free" || label === "memory_size" ) {
				value = value * 1000;
			}
			value = adaptSize(value);
		}
		
		if( value === true ) {
			value = I18n.get("YES");
		} else if ( value === false ) {
			value = I18n.get("NO");
		} else if ( value === "" ) {
			value = "-";
		}
		
		let cssValue = "value";
		if( label === "serial" || label === "ip_address" ) {
			cssValue += " small";
		}
		
		if( value === "64-bit" ) {
			value = "64 bits";
		} else if ( value === "32-bit" ) {
			value = "32 bits";
		} 
		
		return(
				<div key={`box-hardware-${label}`} className="properties-line">
					<div className="label">{I18n.get(label)}</div>
					<div className={cssValue}>{value}</div>
				</div>
			);
	}
	
	const renderPropertiesTable = ( property , data ) => {
		if( data && data.hasOwnProperty( property ) ) {
			return(
				<div className="properties-table" key={`box-hardware-${property}`}>
					<div className="header">{I18n.get(property)}</div>
					{
						Object.keys( data[property] ).map( (key) => {
							switch ( `${property}-${key}` ) {
								case "os-type": return renderPropertiesLine( "architecture" , data[property][key]);
								case "os-disk":
									return [
										renderPropertiesLine( "disk_free" , data[property][key].free ),
										renderPropertiesLine( "disk_size" , data[property][key].size )
									];
								case "os-ram":
									return [
										renderPropertiesLine( "memory_free" , data[property][key].free ),
										renderPropertiesLine( "memory_size" , data[property][key].size )
									];
								case "video-size":
									return renderPropertiesLine( "horizontal_resolution" , data[property][key].width + " * " + data[property][key].height);
								default:
									return renderPropertiesLine( key , data[property][key]);
							}
						} )
					}
				</div>
			);
		}
		return null;
	}
	
	let css = "BoxHardware-root";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	if( props.className ) {
		css += " " + props.className ;
	}
	
	const platform = props.box.platform;
	const software = props.box.software;

	if( platform === null || software === null ) {
		return null;
	}

	return (
		<React.Fragment>
			<div className={css}>
				<Grid container spacing={1} >
					<Grid className="container" item xs={12} md={4} lg={4}>
						<Grid container spacing={1} >
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "baseboard" , platform.attributes )}
							</Grid>
							
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "bios" , platform.attributes )}
							</Grid>
							
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "processor" , platform.attributes )}
							</Grid>
							
							<Grid className="item" item xs={12} md={12} lg={12}>
								<div className="properties-table">
									<div className="header">{I18n.get("Devices")}</div>
									{renderDevices( props.box )}
								</div>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid className="container" item xs={12} md={4} lg={4}>
						<Grid container spacing={1} >
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "os" , platform.attributes )}
							</Grid>
							
							<Grid className="item" item xs={12} md={12} lg={12}>
								<div className="properties-table">
									<div className="header">{I18n.get("software")}</div>
									{renderPropertiesLine( "model" , software.identity.model )}
									{renderPropertiesLine( "serial" , software.identity.serial )}
									{renderPropertiesLine( "version" , software.attributes.version )}
									{renderPropertiesLine( "organization" , software.attributes.organization )}
									{renderPropertiesLine( "run_on_server" , software.attributes.server )}
									{renderPropertiesLine( "run_through_tse" , software.attributes.tse )}
								</div>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid className="container" item xs={12} md={4} lg={4}>
						<Grid container spacing={1} >
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "network" , platform.attributes )}
							</Grid>
							
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "video" , platform.attributes )}
							</Grid>
							
							<Grid className="item" item xs={12} md={12} lg={12}>
								{renderPropertiesTable( "vm" , platform.attributes )}
							</Grid>

						</Grid>
					</Grid>
				</Grid>
			</div>
		</React.Fragment>
	);
}
