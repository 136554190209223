export const UserRoles = {
	INTERNAL: 1,
	ADMINISTRATIVE: 2,
	TECHNICAL: 3,
	SALE_ROLE: 34
};

export const roles = {
	UNKNOWN:0,
	ADMIN:1,
	SUPPORT:2,
	INTERNAL:3,
	RESELLER:4,
	CUSTOMER:5,
	PLACE:6,
	BOX:7,
	DEVICE:8,
	COMPANY:9,
	SHOP:10,
	TERMINAL:11
};
export const getIconForNode = ( node ) => {
	
	if( node.hasOwnProperty( "disabled" ) ) {
		return ["fas" , "eye-slash"];
	}
	
	if( node && node.role ) {
		switch( node.role ) {
			case roles.ADMIN: //fall through;
			case roles.SUPPORT: //fall through;
			case roles.INTERNAL: //fall through;
			case roles.RESELLER: return ["fas" , "industry"];
			case roles.CUSTOMER: return ["fas" , "shop"];
			case roles.COMPANY: //fall through
			case roles.SHOP:
			case roles.PLACE: return ["fas" , "globe-americas"];
			case roles.BOX: return ["fas" , "archive"];
			case roles.TERMINAL: return ["fas" , "display"];
			case roles.DEVICE: //return ["fas" , "home"];
				try {
					if( node.name.includes("BNR") || node.name.includes("SCR") ) {
						return ['fas', 'money-bill'];
					} else if ( node.name.includes("CLS") || node.name.includes("MDB") ) {
						return ['fas', 'coins'];
					} else if ( node.name.includes("VIPS") ) {
						return ['fas', 'money-check'];
					} else if ( node.name.includes("VirtualDevice") ) {
						return ['fas', 'desktop'];
					}
				} catch( error ) {
					return ["fas" , "home"];
				}
				break;
			default : return ["fas" , "home"];
		}
	}
	
	return ["fas" , "home"];
};
