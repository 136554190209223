import React from 'react';
import { I18n } from 'aws-amplify/utils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactCountryFlag from "react-country-flag";
import {
	TextField,
	List,
	InputAdornment,
	ListItem,
	Tooltip
} from "@mui/material";
import { roles , getIconForNode } from '../../../Models/Roles.js';

import {Search} from "@mui/icons-material";
import NoDataPlaceholder from "../NoDataPlaceholder/NoDataPlaceholder";
import DialogAccount from "./DialogAccount";
import GraphQlTool from "../../../Utils/GraphQlTool";
import PhoneNumberFormatter from "../../../Utils/PhoneNumberFormatter";
import {getAccountOptions, getDetailedAccounts} from "../../../graphql/queries";
import {
	enableAccount,
	insertAccount,
	deleteAccount,
	setAccountOptions,
	insertUser,
	updateUser, deleteUser, enableUser, sendUserInvitation
} from "../../../graphql/mutations";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import './InstallationManager.css';
import './AccountManager.css';
import DialogConfirmRemove from "../DialogConfirmRemove/DialogConfirmRemove";
import DialogManageAccountOptions from "./DialogManageAccountOptions";
import DialogConfirmResend from "../DialogConfirmResend/DialogConfirmResend";

export default function AccountManager( props ) {
	const [filterName, setFilterName] = React.useState( null );
	const [filterType, setFilterType] = React.useState( 0 );
	const [selected, setSelected] = React.useState( null );
	const [accounts, setAccounts] = React.useState( null );
	const [pendingEdit, setPendingEdit] = React.useState( null );
	const [pendingDelete, setPendingDelete] = React.useState( null );
	const [pendingResend, setPendingResend] = React.useState( null);
	const [pendingEditOptions, setPendingEditOptions] = React.useState( null );

	const ROOT = 1;
	const RESELLER = 4;
	const CUSTOMER = 5;

	const loadAccounts = () => {
		if( props.API ) {
			props.API
				.graphql({ query: getDetailedAccounts, variables: { accountId:props.user.accountId }})
				.then( returned => {
					const tool = new GraphQlTool( 'getDetailedAccounts' , returned );
					setAccounts( tool.extract() );

				})
				.catch((error) => {
					console.error("error" , error);
				})
		}
	}

	const onFilterNameChanges = evt => {
		setFilterName( evt.target.value );
	};

	const onFilterTypeChanges = evt => {
		setFilterType( evt.target.value );
	};

	const handleConfirm = newAccount => {
		if( newAccount ) {
			const phoneFormater = new PhoneNumberFormatter();
			if( props.API ) {
				props.API.graphql({
					query: insertAccount,
					variables: {
						input:{
							createdBy: props.user.accountId,
							accountType: newAccount.Type,
							email: newAccount.Email.trim(),
							name: newAccount.Name.trim(),
							code: ( newAccount.Code ) ? newAccount.Code.trim() : newAccount.Id.trim(),
							phone: phoneFormater.formatInternational( newAccount.Phone?.trim() , newAccount.Locale.toLowerCase().trim() ),
							locale: newAccount.Locale.toLowerCase().trim()
						}
					}
				})
				.then( returned => {
					const tool = new GraphQlTool( 'insertAccount' , returned );
					setAccounts( tool.extract() );
				})
				.catch((error) => {
					console.log("error" , error);
				});
			}
		}
		setPendingEdit( null );
	};

	const handleConfirmResend = (  mode , pending ) => {
		//Cognito manage as well email or sub id as username
		props.API.graphql({
			query: sendUserInvitation,
			variables: {
				email: pending.rootcognitoid
			}
		})
		.then( returned => {
			console.log( 'returned' , returned );
			setPendingResend(null);
		} )
		.catch((error) => {
			console.log("error" , error)
		});
	};

	const handleDialogConfirm = ( unused , account ) => {
		if ( account && account.action === "ENABLE" ) {
			if ( props.API ) {
				props.API.graphql({
					query: enableAccount,
					variables: {
						accountId: account.item.id,
						isActive: ! account.item.active
					}
				})
				.then( () => {
					loadAccounts();
					setPendingDelete( null );
				})
				.catch((error) => {
					console.log("error" , error);
					setPendingDelete( null );
				});
			}
		} else if ( account && account.action === "DELETE" ) {
			if ( props.API ) {
				props.API.graphql({
					query: deleteAccount,
					variables: {
						accountId: account.item.id
					}
				})
				.then( returned => {
					const tool = new GraphQlTool( 'deleteAccount' , returned );
					const usersToDelete = tool.extract();
					usersToDelete.forEach( user => {
						if( props.userDelegate ) {
							props.userDelegate( 'delete' , user.cognitoid );
						}
					} )
					loadAccounts();
					setSelected( null );
					setPendingDelete( null );
				})
				.catch((error) => {
					console.log("error" , error);
					setPendingDelete( null );
				});
			}
		} else {
			setPendingDelete( null );
		}

	};

	const handleClickRemoveInactive = evt => {
		console.log( `to implement handleClickRemoveInactive` );
	};

	const handleClickCreateAccount = evt => {
		setPendingEdit({ Type: CUSTOMER , Locale: props.user.accountLocale.toUpperCase() } );
	};

	const handleClickDeleteAccount = evt => {
		if( ! selected || selected.active ) return;
		setPendingDelete({
			title: I18n.get('Are you sure'),
			message: I18n.get('Confirm delete account'),
			action: "DELETE",
			item: selected
		});
	};

	const handleClickDisableAccount = evt => {
		if( ! selected ) return;
		setPendingDelete({
			title: I18n.get('Are you sure'),
			message: I18n.get('Confirm disable account'),
			action: "ENABLE",
			item: selected
		});
	};

	const handleClickManageAccountOptions = evt => {
		if( props.API ) {
			props.API.graphql({
				query: getAccountOptions,
				variables: { accountId: props.user.accountId }
			})
			.then( returned => {
				let accountOptions;
				if( returned ) {
					if( returned && returned.data && returned.data.getAccountOptions ) {
						accountOptions = returned.data.getAccountOptions;
					} else {
						accountOptions = {
							creation: new Date().toISOString(),
							options: '{"techAlertRecipients":[]}',
							updatedAt: new Date().toISOString(),
							accountId: props.user.accountId,
							licenses: 0
						};
					}
				} else {
					accountOptions = {
						creation: new Date().toISOString(),
						options: '{"techAlertRecipients":[]}',
						updatedAt: new Date().toISOString(),
						accountId: props.user.accountId,
						licenses: 0
					};
				}

				const optionsForAccount = {
					account: props.user.accountId ,
					techAlertRecipients: JSON.parse( accountOptions.options ).techAlertRecipients ,
					ifNotActiveForDays: ( JSON.parse( accountOptions.options ).ifNotActiveForDays ) ? JSON.parse( accountOptions.options ).ifNotActiveForDays : 30 ,
					creation: accountOptions.creation,
					licenses: accountOptions.licenses,
				};

				setPendingEditOptions( optionsForAccount );
			})
			.catch((error) => {
				console.log("error" , error);
			});
		}
	};

	const handleConfirmOptions = options => {
		if( options ) {
			if( props.API ) {
				props.API.graphql({
					query: setAccountOptions,
					variables: {
						input:{
							creation: options.creation,
							options: JSON.stringify( {
								techAlertRecipients: options.techAlertRecipients,
								ifNotActiveForDays: options.ifNotActiveForDays,
								licenses: options.licenses,
								accountOffset: new Date().getTimezoneOffset()
							} ),
							updatedAt: new Date().toISOString(),
							accountId: props.user.accountId
						}
					}
				})
				.then( returned => {
					console.log( 'Account options saved' , returned );
				})
				.catch((error) => {
					console.log("error" , error);
				});
			}
		}
		setPendingEditOptions( null );
	};

	const handleResend = () => {
		setPendingResend( selected )
	};

	const filterByName = list => {
		if( ! filterName ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( account => {
				if( account.name.includes( filterName ) ||
					account.code.includes( filterName ) ) {
					filtered.push( account );
				}
			} );
		}
		return filtered;
	}

	const filterByType = list => {
		if( ! filterType || filterType === 0 ) {
			return list;
		}
		const filtered = [];
		if( list && list.length > 0 ) {
			list.forEach( account => {
				if( account.type === filterType ) {
					filtered.push( account );
				}
			} );
		}
		return filtered;
	}

	const renderHeader = () => {
		console.log( 'selected' , selected );
		return (
			<div className={`header-admin`}>
				<div className={`action-bar`}>
					<div className={`title`}>{ I18n.get("Accounts") }</div>
					<div className={`count`}>({ ( accounts ) ? accounts.length : 0 })</div>
					<div className={`spacer`}></div>
					<div className={`actions`}>
						<div
							className={`button ${( ! selected ) ? 'disabled' : '' }`}
							onClick={handleResend}>
							{I18n.get("RESEND_MAIL")}
						</div>
						{ props.user.type === ROOT ?
							<div className={`button`}  onClick={handleClickRemoveInactive}>
								{ I18n.get("Remove inactive") }
							</div>
							: null }
						<div className={`button`} onClick={handleClickCreateAccount}>
							{ I18n.get("Add") }
						</div>
						{ props.user.type === ROOT ?
							<div className={`button`}  onClick={handleClickManageAccountOptions}>
								{ I18n.get("Manage options") }
							</div>
							: null }
						<div className={`button ${( ! selected || selected.type === ROOT || selected.active )? 'disabled':''}`}  onClick={handleClickDeleteAccount}>
							{ I18n.get("Delete") }
						</div>
						<div className={`button ${( ! selected || selected.type === ROOT )?'disabled':''}`}  onClick={handleClickDisableAccount}>
							{ (selected && selected.active ) ? I18n.get("Disable") : I18n.get("Enable") }
						</div>
					</div>
				</div>
				<div className={`filter-bar`}>
					<TextField id="search-installations"
							   className={`search-field-expand`}
							   label={ I18n.get("Filter account by name") }
							   variant="outlined"
							   size="small"
							   InputProps={{
								   startAdornment: (
									   <InputAdornment position="start">
										   <Search />
									   </InputAdornment>
								   ),
							   }}
							   onChange={ onFilterNameChanges }/>
					<div className={`spacer`}></div>
					<FormControl variant="outlined" className={`search-field-medium`} size={`small`}>
						<InputLabel className={``}
									id={`user-filter-role-input`}>
							{I18n.get("Filter by roles")}
						</InputLabel>
						<Select labelId={`account-filter-type-label`}
								id={`account-filter-type`}
								value={filterType}
								onChange={onFilterTypeChanges}
								MenuProps={{
									classes:{paper:`${(props.isDarkStyle) ? 'dark' : ''}`}
								}}
								label={I18n.get("Filter by roles")} >
							<MenuItem value={0}>
								<FontAwesomeIcon icon={["fas" , "users"]} style={{fontSize:"1.25em"}}/>
								<span className="ml-2">{I18n.get("All")}</span>
							</MenuItem>
							<MenuItem value={RESELLER}>
								<FontAwesomeIcon icon={getIconForNode( {role: roles.RESELLER} )} style={{fontSize:"1.25em"}}/>
								<span className="ml-2">{I18n.get("Reseller")}</span>
							</MenuItem>
							<MenuItem value={CUSTOMER}>
								<FontAwesomeIcon icon={getIconForNode( {role: roles.CUSTOMER} )} style={{fontSize:"1.25em"}}/>
								<span className="ml-2">{I18n.get("Customer")}</span>
							</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
		);
	};

    const renderListHeader = () => {
    	    return (
    	        <div className={`list-header payStation-card`}>
    	            <div className={`general-block`}>
                        <div className={`type-block accountmanager`}>{ I18n.get('Account name') }</div>
                        <div className={`type-block identity`}>{ I18n.get('Id') }</div>
                        <div className={`count-block`}></div>
                        <div className={`count-block`}></div>
                        <div className={`count-block`}>{ I18n.get('Info') }</div>
                        <div className={`count-block`}></div>
                        <div className={`count-block`}></div>
                        <div className={`count-block`}></div>
                        <div className={`locale`}>{ I18n.get('Country') }</div>
                    </div>
    	        </div>
    	    );
    	};

	const renderListItem = account => {
		const resellersTooltip = `${I18n.get("Active")} : ${( account.nbresellersactive ) ? account.nbresellersactive : 0 }     
							 ${I18n.get("Inactive")} : ${( account.nbresellersinactive ) ? account.nbresellersinactive : 0 }`;

		const customersTooltip = `${I18n.get("Active")} : ${( account.nbcustomersactive ) ? account.nbcustomersactive : 0 }     
							 ${I18n.get("Inactive")} : ${( account.nbcustomersinactive ) ? account.nbcustomersinactive : 0 }`;
		return (
			<ListItem key={account.id}
					  className={`payStation-card ${(selected === account)?'selected':''}`}
					  onClick={() => { setSelected( account ); }}>
				<div className={`type-block`}>
					<FontAwesomeIcon icon={getIconForNode( {role: account.type} )} style={{fontSize:"1.25em"}}/>
					<div className={`name`}> { account.name } </div>
				</div>
				<div className={`type-block`}>
					<div className={`code`}> { account.code } </div>
				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon={["fas" , "users"]} style={{fontSize:"1.25em"}}/>
					<div> {`${( account.nbusersactive ) ? account.nbusersactive : 0 } `} </div>
				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon={getIconForNode( {role: roles.BOX} )} style={{fontSize:"1.25em"}}/>
					<div> {`${( account.nbboxesactive ) ? account.nbboxesactive : 0 } `} </div>
				</div>
				<div className={`count-block`}>
					<FontAwesomeIcon icon={getIconForNode( {role: roles.TERMINAL} )} style={{fontSize:"1.25em"}}/>
					<div> {`${( account.nbterminalsactive ) ? account.nbterminalsactive : 0 } `} </div>
				</div>
				<Tooltip title={ resellersTooltip }>
					<div className={`count-block ${(account.type === CUSTOMER) ? 'hide-content' : '' }`}>
						<FontAwesomeIcon icon={getIconForNode( {role: roles.RESELLER} )} style={{fontSize:"1.25em"}}/>
						<div> {`${( account.nbresellers ) ? account.nbresellers : 0 } `} </div>
					</div>
				</Tooltip>
				<Tooltip title={ customersTooltip }>
					<div className={`count-block ${(account.type === CUSTOMER) ? 'hide-content' : '' }`}>
						<FontAwesomeIcon icon={getIconForNode( {role: roles.CUSTOMER} )} style={{fontSize:"1.25em"}}/>
						<div> {`${( account.nbcustomers ) ? account.nbcustomers : 0 } `} </div>
					</div>
				</Tooltip>
				<div className={`count-block ${ ( account.active ) ? ( account.lastrootconnection ? "success" : "warning" ) : '' }`} >
					{ account.active ?
						( account.lastrootconnection ?
							<Tooltip title={`${account.lastrootconnection.split('.')[0].replace('T' , ' ')}`}><FontAwesomeIcon icon="fa-solid fa-circle-check" style={{fontSize:"1.25em"}}/></Tooltip> :
							<FontAwesomeIcon icon="fa-solid fa-circle-exclamation" style={{fontSize:"1.25em"}}/>
						) :
						<FontAwesomeIcon icon={["fas" , "store-slash"]} style={{fontSize:"1.25em"}}/> }
				</div>
				<div className={`locale`} >
					<ReactCountryFlag
						countryCode={account.locale.toUpperCase()}
						svg
						cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
						cdnSuffix="svg"
						title={account.locale}
					/>
				</div>
			</ListItem>
		);
	};

	let filtered = [];
	const items = [];
	if( ! accounts ) {
		loadAccounts();
	} else {
		filtered = filterByType( filterByName( accounts ) );
		filtered.forEach( account => {
			items.push( renderListItem( account ) );
		} );
	}

	const shown = filtered.length < 1;
	if( shown && selected ) {
		setSelected( null );
	}

	return (
		<React.Fragment>
			<div id="account-manager">
				{renderHeader()}
				{renderListHeader()}
				<List>
					{items.map( item => item )}
				</List>
				<NoDataPlaceholder className="comparison-placeholder-no-data"
								   shown={shown}/>
				<DialogAccount 	handleClose={handleConfirm}
								pendingEdit={pendingEdit}
								isDarkStyle={props.isDarkStyle}/>
				<DialogManageAccountOptions handleClose={handleConfirmOptions}
								  			pendingEdit={pendingEditOptions}
								  			isDarkStyle={props.isDarkStyle}/>
				<DialogConfirmRemove handleClose={handleDialogConfirm}
									 pendingDelete={pendingDelete}
									 isDarkStyle={props.isDarkStyle}/>
				<DialogConfirmResend handleClose={handleConfirmResend}
									 pendingResend={pendingResend}
									 isDarkStyle={props.isDarkStyle}/>
			</div>
		</React.Fragment>
	);
}
