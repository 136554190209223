import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import './GroupedChart.css';

export default function GroupedChart( props ) {
	
	const maxValue = ( list ) => {
		let max = 0;
		if( list ) {
			list.forEach( candidate => {
				max = Math.max( max , candidate);
			} );
		}
		return max;
	};
	
	const renderItem = ( label , quantity , max ) => {
		let extraCss = "";
		if( props.largeLabels ) {
			extraCss += "XL";
		}
		
		if( max/2  >= quantity ) {
			extraCss += " Alt-1";
		} else if ( quantity < max * 2/3 ) {
			extraCss += " Alt-2";
		}
		
		return (
			<div className={`item-graph ${extraCss}`} key={`item-${label}`}>
				<div className={`label`} >{label}</div>
				<div className={`progress`} >
					<LinearProgress variant="determinate" value={(quantity/max) * 100} className="progress-bar-rounded progress-sm progress-bar-gradient" />
				</div>
				<div className={`quatity`} >{quantity}</div>
			</div>
		);
	};
	
	const max = maxValue( props.series[0].data );

	let items = [];
	if( props.labels && props.series ) {
		props.labels.map( (label) => {
			try {
				items.push( renderItem( label , props.series[0].data[props.labels.indexOf(label)] , max ) ); 
			} catch( error ) {
				console.warn( error );
			}
			return true;
		} );
	}
	return (
		<React.Fragment>
			<div className={`GroupedChart-root`} key={props.id}>
				{items.map( (item) => item )}
			</div>
		</React.Fragment>
	);
}
