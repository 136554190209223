import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";
import dayjs from 'dayjs';
import "dayjs/locale/fr";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import '../PeriodChooser/PeriodChooser.css';

const factoryUUID = new UUID();

export default function DateChooser(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedDate, setSelectedDate] = React.useState( props.date );
	const [isCustom, setCustom] = React.useState( false );

	const getLocalizedDateFormat = () => {
		const userLang = navigator.language || navigator.userLanguage;
		switch ( userLang ) {
			case "fr-FR": return "DD/MM/YYYY HH:mm:ss"
			default: return "MM/DD/YYYY HH:mm:ss"
		}
	}
	
	const is12HourTimeFormat = () => {
		const userLang = navigator.language || navigator.userLanguage; 
		switch ( userLang.toLowerCase() ) {
			case "fr-fr": return false
			case "fr-ca": return false
			case "fr-be": return false
			case "fr": return false
			default: return true
		}
	}
	
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const handleDateChange = dayJsDate => {
		setCustom( true );
		const date = new Date( dayJsDate.toISOString() );
		setSelectedDate( new Date( date ).getTime() );
		if( props.onDateChanged ) {
			props.onDateChanged( date );
		}
	};

	const labelStart = I18n.get("Date") + " :";

	let css = "Period-chooser";
	let cssMenu = "p-0";
	let cssPopup = "dropdown-menu-md overflow-hidden p-3 Period-chooser-Popup";
	let cssDial = "dial-cm";
	if( props.isDarkStyle ) {
		css += " dark";
		cssMenu += " dark";
		cssPopup += " dark";
		cssDial += " dark";
	} 

	const idButton = factoryUUID.generate();
	const tooltipTextTop = new Date( selectedDate ).toLocaleDateString() + " " + new Date( selectedDate ).toLocaleTimeString().substring(0,5);

	if( selectedDate < props.date && ! isCustom ) {
		setSelectedDate( props.date );
	}

	return (
		<React.Fragment>
			<div className={css}>
				<Button variant="text"  
						id={idButton}
						className="Icon" 
						disableRipple
						onClick={handleClick} >
					<FontAwesomeIcon icon={["fas" , "calendar-week"]} className="display-4"/>
					<div className="label-period">
						<div className="top">{tooltipTextTop}</div>
					</div>
				</Button>
				<Menu anchorEl={anchorEl}
					  keepMounted
					  anchorOrigin={{
						  vertical: 'bottom',
						  horizontal: 'left',
					  }}
					  transformOrigin={{
						  vertical: 'top',
						  horizontal: 'right',
					  }}
					  open={Boolean(anchorEl)}
					  classes={{ list: cssMenu }}
					  onClose={handleClose} >
					<div className={cssPopup}>
						<div className="grid-menu grid-menu-1col">
							<LocalizationProvider dateAdapter={AdapterDayjs}
												  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
												  adapterLocale="fr">
								<MobileDateTimePicker label={labelStart}
													  id={factoryUUID.generate()}
													  format={getLocalizedDateFormat()}
													  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
													  ampm={is12HourTimeFormat()}
													  onChange={handleDateChange}
													  labelFunc={(date , invalidLabel) => {return date.toLocaleDateString() + " " + date.toLocaleTimeString().substring(0,5)}}
													  disableFuture={true}
													  defaultValue={dayjs( new Date( selectedDate ).toISOString() )}/>
							</LocalizationProvider>
						</div>
					</div>
				</Menu>
			</div>
		</React.Fragment>
	);
}
