import french from './french.js'
import english from './english.js'

const translations = {
    'fr': french,
    'en': english,
	'us': english,
	'au': english,
	'gb': english,
};

export default translations;
