import React from "react";
import {I18n} from "aws-amplify/utils";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

let currentField = {
    id:null,
    lastChange:null,
    value:null
};

export default function DialogManageAccountLicenses( props ) {
    const [editing, setEditing] = React.useState(null );

    const handleCancel = () => {
        props.handleClose();
        setEditing(null);
    };

    const handleOk = () => {
        props.handleClose( (props.pendingEdit.licenses) ? props.pendingEdit.licenses : 0 );
        setEditing(null);
    };

    const handleInputChange = ( evt , property ) => {
        currentField = {
            id:`user-edited-${property}`,
            lastChange:new Date(),
            value:evt.target.value,
            property:property
        }

        setTimeout( () => {
            if( currentField.id ) {
                if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) {
                    props.pendingEdit[currentField.property] = currentField.value;
                    editing[currentField.property] = currentField.value;
                    currentField = {
                        id:null,
                        lastChange:null,
                        value:null,
                        property:null
                    };
                }
            }
        } , 5000 );

        let clone = Object.assign( {} , editing );
        clone[currentField.property] = currentField.value;
        props.pendingEdit[currentField.property] = currentField.value;
        setEditing( clone );
    }

    const handleInputBlur = evt => {
        if( currentField.id ) {
            props.pendingEdit[currentField.property] = currentField.value;
            editing[currentField.property] = currentField.value;
            currentField = {
                id:null,
                lastChange:null,
                value:null,
                property:null
            };
        }
    }

    const isSubmitDisabled = () => {
        return ! isValid();
    }

    const isValid = () => {
        if( props.pendingEdit ) {
            const licenses = parseInt( props.pendingEdit?.licenses?.trim() , 10 );
            const isInAllowedRange = props.pendingEdit?.licenses && licenses > -2;
            return isInAllowedRange && hasEnoughLicenses( licenses );
        }
        return false;
    }

    const hasEnoughLicenses = licenses => {
        const isRestricted = props.pendingEdit?.maxLicenses > -1;
        const hasEnoughLicenses = props.pendingEdit?.maxLicenses >= licenses && licenses >= 0;
        if( isRestricted ) {
            return hasEnoughLicenses;
        } else {
            return true;
        }
    }

    const getHelperText = () => {
        if( props.pendingEdit?.licenses && props.pendingEdit?.licenses.trim() !== '' ) {
            if( ! isValid() ) {
                const licenses = parseInt( props.pendingEdit?.licenses?.trim() , 10 );
                if( ! hasEnoughLicenses( licenses ) ) {
                    return I18n.get("Not enough licenses");
                }
                return I18n.get("Invalid value");
            }
            return null;
        } else {
            return I18n.get("Required field");
        }

        return null;
    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
    let cssForm = "select-task-properties";
    let cssSelect = "combo-task-properties";
    let cssLabel = "label-task-properties";

    if( props.isDarkStyle ) {
        css += " dark";
        cssForm += " dark";
        cssSelect += " dark";
        cssLabel += " dark";
    }

    if( ! props.pendingEdit ) {
        return null;
    }

    if( ! editing ) {
        setEditing( props.pendingEdit );
    }

    const licensesCount = `${ (editing?.licenses) ? editing.licenses : 0}`;
    if( editing ) {
        props.pendingEdit.licenses = licensesCount;
    }

    return (
        <Dialog classes={{ paper: css }}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.pendingEdit)}>
            <DialogTitle id="account-options-dialog-title">{I18n.get("Licenses")}</DialogTitle>
            <DialogContent dividers>
                <div>{I18n.get("Licenses allowed ( -1 for unlimited )")}</div>
                <div className="user-data-item name">
                    <TextField
                        required
                        error={!isValid()}
                        helperText={getHelperText()}
                        variant="outlined"
                        id={`edited-licenses-count`}

                        label={I18n.get("Licenses count")}
                        placeholder={I18n.get("Not Specified")}
                        defaultValue={licensesCount}
                        InputProps={{
                            readOnly: false,
                        }}
                        onChange={(evt) => handleInputChange( evt , "licenses" ) }
                        onBlur={(evt) => handleInputBlur( evt ) }
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} className="btn-neutral-secondary btn-pill text-danger mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("CANCEL")}
						</span>
                </Button>
                <Button onClick={handleOk} className="btn-neutral-secondary btn-pill text-danger mx-1" disabled={isSubmitDisabled()}>
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
                </Button>
            </DialogActions>
        </Dialog>

    );
}
