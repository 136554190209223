import { I18n } from "aws-amplify/utils";
let instanceInactivityDelegate;
class InactivityDelegate  {
	constructor( observer ) {
		instanceInactivityDelegate = this;
		this.enableTimer = false;
		this.timeout = 1000 * 60 * 60 * 3; //3 hour
		this.checkInterval = 1000 * 60 * 15; //check all 15 minutes
		this.lastActivity = Date.now();
		this.disconnected = false;
		this.observer = observer;
		if ( this.enableTimer ) {
			this.timerId = setInterval( this.checkIfAlive, this.checkInterval );
		}
	}

	updateActivity() {
		this.lastActivity = Date.now();
	}

	forceDisconnection( error ) {
		instanceInactivityDelegate.disconnect();
		console.warn(`Forced disconnection after subscription failure` , error);
	}

	checkIfAlive() {
		if ( ( Date.now() - instanceInactivityDelegate.lastActivity ) > instanceInactivityDelegate.timeout ) {
			instanceInactivityDelegate.disconnect();
		} else {
			instanceInactivityDelegate.disconnected = false;
		}
	}

	disconnect() {
		instanceInactivityDelegate.disconnected = true;
		if ( instanceInactivityDelegate.enableTimer ) {
			clearInterval( instanceInactivityDelegate.timerId );
		}

		if ( instanceInactivityDelegate.observer ) {
			instanceInactivityDelegate.observer.onDisconnection( I18n.get("Disconnected for inactivity") );
		}
	}

	setDisconnected( disconnected ) {
		this.disconnected = disconnected;
	}

	isDisconnected() {
		return this.disconnected;
	}
}

export default InactivityDelegate;
