import {fundType} from "../Models/Report/Enums";
import { I18n } from 'aws-amplify/utils';

class LevelManager {
	constructor( jsonData ) {
		this.data = jsonData;
	}
	
	getTotalQuantity() {
		let totalQuantity = 0;
		this.data.content.forEach( line => {
			totalQuantity += line.qty;
		} );
		return totalQuantity;
	}
	
	getTotalCapacity() {
		if( this.data && this.data.full ) {
			return this.data.full
		}
		return 0;
	}
	
	getTotalAmount() {
		let total = 0;
		this.data.forEach( moduleInventory => {
			if( moduleInventory && moduleInventory.data ) {
				total += this.getCashBoxAmount( moduleInventory.data.cashBox );
				total += this.getCashBoxAmount( moduleInventory.data.loader );
				if( moduleInventory.data.details ) {
					moduleInventory.data.details.forEach( detail => {
						total += ( detail.qty * detail.value );
					} );
				}
			}
		} );
		return total;
	}

	getCashBoxAmount( cashBoxData ) {
		let total = 0;
		if( cashBoxData && cashBoxData.content ) {
			cashBoxData.content.forEach( candidate => {
				total += ( candidate.qty * candidate.value );
			} );
		}
		return total;
	}
	
	getTotalAmountForBox( box ) {
		let total = 0;
		this.data.map( row => { 
			if( box != null ) {
				if( row.box.uuid === box.uuid ) {
					total += ( row.details.quantity * row.details.value );
				}
			}
				
			return true;
		} );
		
		return total;
	}
	
	getAmountForRow( line ) {
		let amount = 0;
		if ( line ) {
			amount += line.qty * line.value;
		}

		return amount;
	}
	
	getTotalQtyForCurrency( currency ) {
		let total = 0;
		this.data.forEach( row => {
			if ( row && row.hasOwnProperty("details") ) {
				row.details.forEach( line => {
					if( line.currency === currency ) {
						total += line.quantity ;
					}
				} );
			}
		} );
		return total;
	}
	
	getTotalAmountForCurrency( currency ) {
		let total = 0;
		try {
			this.data.content.forEach( line => {
				if( line.currency === currency ) {
					total += ( line.qty * line.value );
				}
			} );
		} catch( error ) {
			console.log( 'this.data' , this.data );
			console.error( `Unable to get total amount for currency : ${currency}` , error );
		}
		return total;
	}

	getTotalAmountForType( type ) {
		let total = 0;
		this.data.map( row => {
			if ( row !== null && row !== undefined && row.hasOwnProperty("details") ) {
				row.details.map( (line) => {
					if( line.fund_type === type ) {
						total += ( line.quantity * line.value );
					}
					return true;
				} );
			}
			return true;
		} );
		return total;
	}
	
	getTotalAmountForLocation( location ) {
		var total = 0;
		this.data.map( row => {
				if( row.fund_location === location ) {
					row.details.map( (line) => {
						total += ( line.quantity * line.value );
						return true;
					} );
				}
				return true;
			} );
		return total;
	}
	
	getFilteredTotalAmountForLocation( location , fundType ) {
		var total = 0;
		this.data.map( row => { 
				if( row.fund_location === location && row.details.fundType === fundType ) {
					total += ( row.details.quantity * parseFloat(row.details.value.replace("," , ".")) );
				}
				return true;
			} );
		return total;
	}
		
	getTotalFillingLevelInPct() {
		try {
			return this.getPct( this.getTotalQuantity() , this.getTotalCapacity() );
		} catch (error) {
			console.log("EXCEPTION : " , error)
			return 0;
		}
	}
	
	getFillingLevelInPct( row ) {
		try {
			return this.getPct( row.qty , row.full );
		} catch (error) {
			console.log("EXCEPTION : " , error)
			return 0;
		}
	}
	
	getFillingLevelInPctForLocation( location ) {	
		try {
			return this.getPct( this.getQuantityForLocation( location ) , 
								this.getCapacityForLocation( location ) );
		} catch (error) {
			console.log("EXCEPTION : " , error)
			return 0;
		}
	}
	
	getPct( quantity , capacity ) {
		if(  capacity > 0 ) {
			let ratio =   parseFloat( (Math.round( (quantity/capacity) * 1000 ) / 1000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] ) 
			let pct = ratio * 100;

			if( Math.round( pct ) === 0 ) {
				ratio = Math.round( (quantity/capacity) * 100000 ) / 100000 
				pct = ratio * 100;
				if( Math.round( pct ) < 1 ) {
					if( pct > 0 ) {
						return 1;
					}
				} else {
					return  Math.round( pct );
				}
			}
			return  Math.round( pct );
		}
		return 0;
	}
	
	getQuantityForLocation( location ) {
		var totalQuantity = 0;
		this.data.map( row => {
				if( row.fund_location === location ) {
					row.details.map( (line) => {
						totalQuantity += line.quantity;
						return true;
					} );
				}
				return true; 
			} );
		return totalQuantity;
	}
	
	getCapacityForLocation( location ) {
		var capacity = 0;
		this.data.map( row => {
				if( row.fund_location === location ) {
					capacity = row.high_stop;
				}
				return true;
		} );
		return capacity;
	}
	
	getLowLvlAlertForLocation( location ) {
		var level = 0;
		this.data.map( row => {
				if( row.fund_location === location ) {
					level = row.low_warning;
				}
				return true;
		} );
		return level;
	}
	
	getHighLvlAlertForLocation( location ) {
		var level = 0;
		this.data.map( row => {
				if( row.fund_location === location ) {
					level = row.high_warning;
				}
				return true;
		} );
		return level;
	}
	
	getMainCurrency() {
		const currencies = {};
		this.data.forEach( moduleInventory => {
			if( moduleInventory && moduleInventory.data ) {
				if( moduleInventory.data.details ) {
					moduleInventory.data.details.forEach( detail => {
						if( ! currencies[detail.currency] ) {
							currencies[detail.currency] = 0;
						}
						currencies[detail.currency]++;
					} );
				}
			}
		} );

		return this.getTopCurrency( currencies );
	}

	getTopCurrency( currencies ) {
		const currencyCodes = Object.keys( currencies );
		let returned = "EUR";
		let count = 0;
		currencyCodes.forEach( candidate => {
			if( currencies[candidate] > count ) {
				returned = candidate;
				count = currencyCodes[candidate];
			}
		} );
		return returned;
	}

	getCurrencyList() {
		const currencies = [];
		try {
			this.data.content.forEach( line => {
				if ( line ) {
					if( ! currencies.includes( line.currency ) ) {
						currencies.push( line.currency );
					}
				}
			} );
		} catch ( error ) {
			//Unused caught from inventory overview
		}

		try {
			this.data.forEach( row => {
				if ( row ) {
					if ( row.hasOwnProperty("details") ) {
						row.details.forEach( line => {
							if( ! currencies.includes( line.currency ) ) {
								currencies.push( line.currency );
							}
						} );
					}
				}
			} );
		} catch ( error ) {
			//Unused caught from inventoryExtendedCard
		}

		try {
			this.data.forEach( row => {
				if ( row ) {
					if ( row.hasOwnProperty("data") ) {
						row.data.details.forEach( line => {
							if( ! currencies.includes( line.currency ) ) {
								currencies.push( line.currency );
							}
						} );
					}
				}
			} );
		} catch ( error ) {
			//Unused caught from inventoryExtendedCard
		}

		try {
			this.data[0].data.cashBox.content.forEach( line => {
				if ( line ) {
					if( ! currencies.includes( line.currency ) ) {
						currencies.push( line.currency );
					}
				}
			} );
		} catch ( error ) {
			//Unused caught from inventoryExtendedCard
		}

		if( currencies.length < 1 ) {
			currencies.push('EUR');
		}

		return currencies;
	}

	getValueList() {
		let values = [];
		this.data.map( row => { 
			if( ! values.includes( row.details.value ) ) {
				values.push( row.details.value );
			}
			return true;
		} );
		return values;
	}

	getValueFormattedList( locale ) {
		let values = [];
		try {
			this.data.content.forEach( line => {
				if( ! values.includes( line.value ) && line.value > 0 && line.qty > 0 ) {
					let formatter = new Intl.NumberFormat( locale, { style: 'currency', currency: line.currency });
					let formattedParts = formatter.formatToParts( line.value );
					values.push( formatter.format( line.value ).replace( formattedParts.find(part => part.type === "currency").value , "" ) );
				}
			} );
		} catch ( error ) {
			console.error( `Unable to build value formatted list` , error );
		}
		return values;
	}

	getTypeFormattedList( locale ) {
		let values = [];
		Object.keys(fundType).map( (key) => {
			let includes = false;
			this.data.map( row => {
				if ( row !== null && row !== undefined && row.hasOwnProperty("details") ) {
					row.details.map( (line) => {
						if( line.fund_type === fundType[key] && line.value > 0 && line.quantity > 0 ) {
							includes = true;
						}
						return true;
					} );
				}
				return true;
			} );
			if ( includes ) {
				values.push( I18n.get( fundType.getName( fundType[key] ) ) );
			}
			return true;
		} );

		return values;
	}

	getFillingColor( row ) {
		if( row.details.quantity < row.low_warning + 1 ) {
			return "#11c5db";	//BLUE
		} else if ( row.details.quantity > row.high_warning ) {
			return "#f83245"; //RED
		} else {
			return "#1bc943"; //GREEN
		}
	}
	
	getColorCss( row ) {
		if( row.qty < row.low + 1 ) {
			return "info";	//BLUE
		} else if ( row.qty > row.high ) {
			return "danger"; //RED
		} else {
			return "success"; //GREEN
		}
	}
	
	getOverallColorCss() {
		let quantity = this.getTotalQuantity();
		if( quantity < this.data.low + 1 ) {
			return "info";	//BLUE
		} else if ( quantity > this.data.high ) {
			return "danger"; //RED
		} else {
			return "success"; //GREEN
		}
	}
	
	getCountPerCurrency() {
		let countPerCurrency = [];
		this.getCurrencyList().map( (currency) => {
			countPerCurrency.push( this.getTotalQtyForCurrency( currency ) );
			return true;
		} )
		return countPerCurrency;
	}
	
	getColorsForLabelCurrency() {
		var colors = [];
		this.getCurrencyList().map( (currency) => {
			colors.push( "#000000" );
			return true;
		} )
		return colors;
	}
	
	getTotalSeriesPerCurrency() {
		const totals = [];
		this.getCurrencyList().forEach( currency => {
			totals.push( this.getTotalAmountForCurrency( currency ) );
		} )
		return totals;
	}
	
	getTotalSeriesPerValues() {
		const totals = [];
		try {
			this.data.content.forEach( line => {
				if ( line && line.value > 0 && line.qty > 0 ) {
					totals.push( this.getAmountForRow( line ) );
				}
			} );
		} catch( error ) {
			console.error( `Unable to generate total series per values` );
		}
		return totals;
	}

	getTotalSeriesPerType() {
		let totals = [];
		Object.keys(fundType).map( (key) => {
			let subTotal = 0;
			this.data.map( (row) => {
				if ( row !== null && row !== undefined && row.hasOwnProperty( "details" )) {
					row.details.map( (line) => {
						if ( line !== null && line !== undefined && line.value > 0 && line.quantity > 0 ) {
							if ( line.fund_type === fundType[key] ) {
								subTotal += this.getAmountForRow( line );
							}
						}
						return true;
					} );
				}
				return true;
			} );
			if ( subTotal > 0 ) {
				totals.push( subTotal );
			}
			return true;
		} );

		return totals;
	}
	
	getHtmlDetailSeriesPerCurrency( locale ) {
		let details = [];
		this.getCurrencyList().map( (currency) => {
			let html = "";
			this.data.map( (row) => {
				if ( row !== null && row !== undefined && row.hasOwnProperty("details") ) {
					row.details.map( (line) => {
						if( line.currency === currency && line.value > 0 && line.quantity > 0 ) {
							let formatter = new Intl.NumberFormat( locale, { style: 'currency', currency: currency });
							let formattedParts = formatter.formatToParts( line.value );
							html += '<span class="apexcharts-tooltip-text-z-value forced-line">' +
								line.quantity +
								' x ' +
								formatter.format( line.value ).replace( formattedParts.find(part => part.type === "currency").value , "" ) +
								'</span>';
						}
						return true;
					} );
				}
				return true;
			} );
			details.push( html );
			return true;
		} )
		return details;
	}
	
	getHtmlDetailSeriesPerValue( locale ) {
		let details = [];

		try {
			this.data.content.forEach( line => {
				if ( line.qty > 0 && line.value > 0 ) {
					let html = "";
					let formatter = new Intl.NumberFormat( locale, { style: 'currency', currency: line.currency });
					let formattedParts = formatter.formatToParts( line.value );
					html += '<span class="apexcharts-tooltip-text-z-value forced-line">' +
						line.qty +
						' x ' +
						formatter.format( line.value ).replace( formattedParts.find(part => part.type === "currency").value , "" ) +
						'</span>';
					details.push( html );
				}
			} );
		} catch ( error ) {

		}
		return details;
	}

	getHtmlDetailSeriesPerType( locale ) {
		let details = [];

		Object.keys(fundType).map( (key) => {
			let html = "";
			this.data.map( (row) => {
				if ( row !== null && row !== undefined && row.hasOwnProperty("details") ) {
					row.details.map( (line) => {
						if( line.fund_type === fundType[key] && line.value > 0 && line.quantity > 0 ) {
							let formatter = new Intl.NumberFormat( locale, { style: 'currency', currency: "EUR" });
							let formattedParts = formatter.formatToParts( line.value );
							html += '<span class="apexcharts-tooltip-text-z-value forced-line">' +
								line.quantity +
								' x ' +
								formatter.format( line.value ).replace( formattedParts.find(part => part.type === "currency").value , "" ) +
								'</span>';
						}
						return true;
					} );
				}
				return true;
			} );
			if ( html.trim().length > 0 ) {
				details.push( html );
			}
			return true;
		} );
		return details;
	}
	
	getQtyPerValueList() {
		let quantity = [];
		try {
			this.data.content.forEach( line => {
				if ( line.qty > 0 && line.value > 0 ) {
					quantity.push( line.qty );
				}
			} );
		} catch ( error ) {
			console.log( `Unable to get quantity per value list` , error );
		}
		return quantity;
	}

	getQtyPerTypeList() {
		let quantity = [];

		Object.keys(fundType).map( (key) => {
			let subTotal = 0;
			this.data.map( (row) => {
				if ( row !== null && row !== undefined && row.hasOwnProperty("details") ) {
					row.details.map( (line) => {
						if ( line.fund_type === fundType[key] && line.quantity > 0 && line.value > 0 ) {
							subTotal += line.quantity;
						}
						return true;
					} );
				}
				return true;
			} );
			if ( subTotal > 0 ) {
				quantity.push( subTotal );
			}
			return true;
		} );

		return quantity;
	}

	filterForBox( box ) {
		let rows = [];
		
		this.data.map( (line) => {
			if( line.box.uuid === box.uuid ) {
				rows.push( line );
			}
			return true;
		} );
		
		return rows;
	}
}

export default LevelManager;
