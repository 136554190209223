import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { sharedTheme } from '../../Style/SharedTheme';

export default function ExpandableCard( props ) {
	const [expanded, setExpanded] = React.useState(( props.expanded ) ? props.expanded : true );
	const toggle = () => {
		setExpanded( ! expanded ); 
	};
	
	const id = ( props.id ) ? props.id : props.title.replace( " " , "-" );
	let css = "";
	if( props.className ) {
		css += " " + props.className ;
	}

	if( props.noCard ) {
		return (
			<Grid key={`panel-${id}`} item xs={12} md={12} lg={12} className={css}>	
				<div className="header noCard expandable" onClick={toggle}>
					{props.title}
					<div className="flex-btn" >{expanded ? <ExpandLess /> : <ExpandMore />} </div>
				</div>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					{props.children}
				</Collapse>
			</Grid>
		);
	} else {
		return (
			<Grid key={`panel-${id}`} item xs={12} md={12} lg={12} className={css}>		
				<Card className="card-box">	
					<div className="header card-header expandable" onClick={toggle}>
						<div className={sharedTheme.cardHeaderTitle}>
							<small className={sharedTheme.cardHeaderTitleSmallGrey}></small>
							<b className={sharedTheme.cardHeaderTitleBigBlack}>{props.title}  </b>
						</div>
						{expanded ? <ExpandLess /> : <ExpandMore />}
					</div>
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						{props.children}
					</Collapse>
				</Card>
			</Grid>
		);
	}
}
