/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const manageAmplifyException = /* GraphQL */ `
    mutation ManageAmplifyException( $input: ExceptionInput! ) {
        manageAmplifyException(input: $input) {
            error
            from
            raisedTime
            info
        }
    }
`;

export const sendEmail = /* GraphQL */ `
    mutation SendFromDashboard( $input: MailInput! ) {
        sendFromDashboard(input: $input) {
            messageId
            errors {
              message
              name
            }
        }
    }
`;

export const sendEmailWithAttachment = /* GraphQL */ `
    mutation SendMail( $input: MailInput! ) {
        sendMail(input: $input) {
            messageId
            errors {
              message
              name
            }
        }
    }
`;

export const setUserTask = /* GraphQL */ `
	mutation SetUserTask( $input: UserTaskInput! ) {
		setUserTask( input: $input ) {
			userId
			creation
			parameters
			updatedAt
			offset
		}
	}
`;

export const deleteUserTask = /* GraphQL */ `
	mutation DeleteUserTask ($userId: String!) {
		deleteUserTask(userId: $userId) {
			creation
			lastSentAt
			parameters
			updatedAt
			userId
			offset
		}
	}
`;

export const setUserAlert = /* GraphQL */ `
	mutation SetUserAlert( $input: UserAlertInput! ) {
		setUserAlert( input: $input ) {
			userId
			creation
			parameters
			updatedAt
		}
	}
`;

export const deleteUserAlert = /* GraphQL */ `
	mutation DeleteUserAlert ($userId: String!) {
		deleteUserAlert(userId: $userId) {
			creation
			lastSentAt
			parameters
			updatedAt
			userId
		}
	}
`;

export const setPreferences = /* GraphQL */ `
	mutation SetPreferences( $userIdAndBoxId: String! , $data: String! ) {
		setPreferences( userIdAndBoxId: $userIdAndBoxId , data: $data ) {
			userIdAndBoxId
			data
		}
	}
`;

export const setAccountOptions = /* GraphQL */ `
	mutation SetAccountOptions( $input: AccountOptionsInput! ) {
		setAccountOptions( input: $input ) {
			accountId
			creation
			options
			updatedAt
		}
	}
`;

export const insertUser = `
	mutation InsertUser( $input: UserInsertInput! ) {
		insertUser( input: $input )
	}
`;

export const deleteUser = `
	mutation DeleteUser ( $uuid: String! ){
		deleteUser( uuid: $uuid ) 
	}
`;

export const updateUser = `
	mutation UpdateUser( $uuid: String!, $input: UserUpdateInput! ) {
		updateUser(uuid:$uuid , input:$input)
	}
`;

export const enableUser = `
    mutation EnableUser( $uuid: String! , $isActive: Boolean! ) {
        enableUser( uuid:$uuid , isActive: $isActive )
    }
`;

export const sendUserInvitation = `
    mutation SendUserInvitation( $email: String! ) {
        sendUserInvitation( email:$email )
    }
`;



export const insertAccount = `
	mutation InsertAccount( $input: AccountInsertInput! ) {
		insertAccount( input: $input )
	}
`;

export const enableAccount = `
	mutation EnableAccount( $accountId: Int! , $isActive: Boolean! ) {
		enableAccount( accountId: $accountId , isActive: $isActive )
	}
`;

export const deleteAccount = `
	mutation DeleteAccount( $accountId: Int! ) {
		deleteAccount( accountId: $accountId )
	}
`;

export const insertGroup = `
	mutation InsertGroup( $input: GroupInput! ) {
		insertGroup( input: $input )
	}
`;

export const updateGroup = `
	mutation UpdateGroup( $input: GroupInput! ) {
		updateGroup( input: $input )
	}
`;

export const updateUsersForGroup = `
	mutation UpdateUsersForGroup( $input: UsersGroupInput! ) {
		updateUsersForGroup( input: $input )
	}
`;

export const deleteGroup = `
	mutation DeleteGroup( $groupId: Int! , $accountId: Int! ) {
		deleteGroup( groupId: $groupId , accountId: $accountId )
	}
`;

export const insertLog = `
	mutation InsertLog( $input: LogInput! ) {
		insertLog( input: $input ) {
			userId
			at
			type
			details
		}
	}
`;

export const setResource = /* GraphQL */ `
	mutation SetResource ( $id: String! , $data: String! ){
		setResource( id: $id , data: $data ) {
			id
			data
		}
	}
`;

export const deleteResource = /* GraphQL */ `
	mutation DeleteResource ( $id: String! ){
		deleteResource( id: $id ) {
			id
			data
		}
	}
`;

export const closePeriods = `
	mutation ClosePeriods ( $boxId: Int! ){
		closePeriods( boxId: $boxId ) 
	}
`;

export const closeDevicePeriod = `
	mutation CloseDevicePeriod ( $deviceId: Int! ){
		closeDevicePeriod( deviceId: $deviceId ) 
	}
`;

export const createNode = /* GraphQL */ `
  mutation CreateNode( $json: String! ) {
    createNode(json: $json)
  }
`;

export const deleteNode = /* GraphQL */ `
  mutation DeleteNode( $json: String! ) {
    deleteNode(json: $json) 
  }
`;

export const updateNode = /* GraphQL */ `
  mutation UpdateNode( $json: String! ) {
    updateNode(json: $json) 
  }
`;

export const insertCacheForOperation = /* GraphQL */ `
	mutation InsertCacheForOperation( $input: ComputedOperationInput! ) {
		insertCacheForOperation( input: $input ) {
			details
			nodeId
			operation_date
			updatedAt
		}
	}
`;

export const changeUserPassword = /* GraphQL */ `
	mutation ChangeUserPassword( $accessToken: String! , $previousPassword: String! , $proposedPassword: String! ) {
		changeUserPassword( accessToken: $accessToken , previousPassword: $previousPassword , proposedPassword: $proposedPassword ) 
	}
`;

export const notifyIotGateway = /* GraphQL */ `
	mutation NotifyIotGateway( $command: String! , $serial: String! , $parameters: String! ) {
		notifyIotGateway( command: $command , serial: $serial , parameters: $parameters ) 
	}
`;

/*export const createNode = `
  mutation CreateNode(
    $input: CreateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    createNode(input: $input, condition: $condition) {
      id
      name
      role
      createdAt
      updatedAt
    }
  }
`;*/

/*export const updateNode = `
  mutation UpdateNode(
    $input: UpdateNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    updateNode(input: $input, condition: $condition) {
      id
      name
      role
      createdAt
      updatedAt
    }
  }
`;*/

/*export const deleteNode =`
  mutation DeleteNode(
    $input: DeleteNodeInput!
    $condition: ModelNodeConditionInput
  ) {
    deleteNode(input: $input, condition: $condition) {
      id
      name
      role
      createdAt
      updatedAt
    }
  }
`;*/


