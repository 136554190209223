import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { I18n } from 'aws-amplify/utils';
import HardwareOverview from '../../Components/HardwareOverview/HardwareOverview';
import ExpandableCard from '../../Components/ExpandableCard/ExpandableCard';
import NoDataPlaceholder from "../../Components/NoDataPlaceholder/NoDataPlaceholder";
import BoxSelector from '../../Components/BoxSelector/BoxSelector';
import BoxHardware from "../../Components/BoxHardware/BoxHardware";
import UUID from "../../../Utils/UUID";
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";

import './Hardwares.css';
import PayStationFinder from "../../../Utils/PayStationFinder";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const factoryUUID = new UUID();
let instanceHardwares = null;
class Hardwares extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			value: -1,//this.getBoxIndex( this.props.selected ),
			forceRefresh:false
		}
		instanceHardwares = this;
	}

	getBoxIndex( boxId ) {
		let index = -1;
		if( this.props.payStations ) {
			this.props.payStations.forEach( payStation => {
				if( payStation.box.id === boxId ) {
					index = this.props.payStations.indexOf( payStation );
				}
			} );
		}
		return index;
	}

	setValue( newValue ) {
		instanceHardwares.setState({
			value:newValue,
			date: instanceHardwares.state.date,
			inventories:instanceHardwares.state.inventories
		});
	}

	handleChange( event, newValue ) {
		instanceHardwares.setValue(newValue);
	}

	renderTabPanel( box , counterIndex ) {
		let shown = false;
		if( ! ( box && box.informations ) ) {
			shown = true;
		}

		const key = "hardware-tab-panel-item-" + box.id;
		return (
			<TabPanel 	key={key}
						value={instanceHardwares.state.value}
						index={counterIndex}
						id={`scrollable-auto-tabpanel-${box.id}`}
						aria-labelledby={`scrollable-auto-tab-${box.id}`} >
				<Grid container spacing={1} >
					<Grid id="exportable-table" className="groupedBy" item xs={12} md={12} lg={12}>
						<div className="tab-panel-content">
							<Grid container spacing={1} >
								<Grid item xs={12} md={12} lg={12}>
									<BoxHardware locale={this.props.locale}
												 box={box}
												 platforms={this.state.platforms}
												 softwares={this.state.softwares}
												 isDarkStyle={this.props.isDarkStyle} />
									<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			</TabPanel>
		);

	}
	
	renderContent( panels ) {
		if( this.state && this.state.value >= 0 ) {
			setTimeout( () => {
				window.scrollTo( {
					top: 895,
					behavior: 'smooth'
				} );
			} , 100 );
			return ( <React.Fragment>{panels.map( item => item )}</React.Fragment> );
		} 
		return null;
	}
	
	render() {
		const tabs = [];
		const panels = [];
		let counterIndex = 0;
		this.allData = [];

		if ( ! this.props.payStations || this.props.payStations.length < 1 ) {
			return (<NoDataPlaceholder className="comparison-placeholder-no-data" shown={true}/>);
		}
		
		this.props.payStations.sort( ( a , b ) => a.box.name.localeCompare( b.box.name ) );
		const analyzer = new ConfigAnalyzer( this.props.payStations );
		const finder = new PayStationFinder( this.props.payStations );
		this.props.payStations.forEach( payStation => {
			if( analyzer.isActiveBoxAtSearchTime( payStation ) && analyzer.isValid( payStation ) ) {
				if( this.props.selected && this.state.value < 0 && this.props.selected === payStation.box.id ) {
					instanceHardwares.setValue( this.props.payStations.indexOf( payStation ) );
				}
				const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
				payStation.box.extraData = finder.extractCompanyShopLabel( finder.buildTerminalExtraData( terminal ) );
				tabs.push( payStation.box );
				panels.push( instanceHardwares.renderTabPanel( payStation.box , counterIndex ) );
				counterIndex++;
			}

			return true;
		} );
		
		if( this.state && this.state.forceRefresh ) {
			//ignored juste used to force refresh after asynchronous loading
		}

		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.rootNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>						
				<div className="z-over fullscreen">

					<ExpandableCard title={I18n.get("Overview")} className="mt-2 mb-3" key={`overview-${factoryUUID.generate()}`}>
						<HardwareOverview isDarkStyle={this.props.isDarkStyle}
										  list={this.props.payStations}/>
					</ExpandableCard>
					<ExpandableCard title={I18n.get("Configuration details")} expanded={true} className="pb-3" key={`config-details-${factoryUUID.generate()}`}>
						<div className="p-3 selector-container">
							<BoxSelector boxes={tabs}
										 allowEmpty={true}
										 label={I18n.get("Select box")}
										 selected={this.state.value} 
										 showIcon={true}
										 isDarkStyle={this.props.isDarkStyle}
										 onChange={this.handleChange} />
						</div>
						{this.renderContent(panels)}
					</ExpandableCard>
				</div>
			</React.Fragment>
		);
	}
}

export default Hardwares;
