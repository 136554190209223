import React from "react";
import LicenseMoveHistory from "../../Components/AdministrationManagers/LicenseMoveHistory";
import {getDetailedAccounts, getUsersForAccount, listLogs} from "../../../graphql/queries";
import GraphQlTool from "../../../Utils/GraphQlTool";
import SyncIndicator from "../../Components/SyncIndicator/SyncIndicator";
import PeriodChooser from "../../Components/PeriodChooser/PeriodChooser";

let instanceLicenseHistory;
class LicenseHistory extends React.Component {
    constructor( props ) {
        super( props );
        const periods = props.periodSynchronizer.getPeriods();
        this.state = {
            period:periods[0],
            isLoading: true,
            accounts: null,
            logs: []
        }
        this.queue = [];
        this.users = [];
        instanceLicenseHistory = this;
        this.retrieveUsersForAccount();
    }

    loadAccounts() {
        if( this.props.API ) {
            this.props.API
                .graphql({ query: getDetailedAccounts, variables: { accountId: this.props.currentUser.accountId }})
                .then( rawAccounts => {
                    const tool = new GraphQlTool( 'getDetailedAccounts' , rawAccounts );
                    this.setState( { accounts: tool.extract() } );
                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    retrieveUsersForAccount() {
        if( this.props.API && this.props.currentUser && this.props.currentUser.accountId > 0 ) {
            this.props.API
                .graphql({ query: getUsersForAccount, variables: { accountId: this.props.currentUser.accountId }})
                .then( returned => {
                    const tool = new GraphQlTool( 'getUsersForAccount' , returned );
                    this.users = tool.extract();
                    console.log( 'this.users' , this.users );
                    if( this.users && this.users.length > 0 ) {
                        this.users.forEach( user => {
                            this.queue.push( user.cognitoid );
                            this.loadLogs( user.cognitoid , this.state.period , null );
                        } );
                    }
                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    loadLogs( userId , period , nextToken ) {
        if( instanceLicenseHistory.props.API ) {
            instanceLicenseHistory.props.API
                .graphql({ query: listLogs, variables: {
                    input: {
                        userId: userId ,
                        count: 100 ,
                        from: new Date( period.start ).toISOString() ,
                        to: new Date( period.end ).toISOString(),
                        nextToken: nextToken
                    } } })
                .then( returned => {
                    if( returned && returned.data && returned.data.listLogs ) {
                        returned.data.listLogs.logs.forEach( log => {
                            instanceLicenseHistory.state.logs.push( log );
                        } );
                        if( returned.data.listLogs.nextToken ) {
                            instanceLicenseHistory.listLogs( userId , period , returned.data.listLogs.nextToken );
                        } else {
                            this.queue = this.queue.filter( item => item !== userId );
                            if( this.queue.length === 0 ) {
                                instanceLicenseHistory.setState( {
                                    isLoading: false
                                } );
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error( error );
                })
        }
    }

    handlePeriodSelection( period , identifier ) {
        if( instanceLicenseHistory.props.periodSynchronizer ) {
            instanceLicenseHistory.props.periodSynchronizer.updatePeriod( period , identifier );
        }
        instanceLicenseHistory.setState({
            isLoading: true,
            period:period,
            logs: []
        });
        instanceLicenseHistory.retrieveUsersForAccount();
    }

    render() {
        if( ! this.state.accounts ) {
            this.loadAccounts();
        }

        return (
            <React.Fragment>
                <div className="z-over fullscreen">
                    <div className="period">
                        <SyncIndicator loading={this.state.isLoading}
                                        className={"display-4 spining space-top"}/>
                        <PeriodChooser period={this.state.period}
                                       onPeriodChanged={this.handlePeriodSelection}
                                       callbackIdentifier="period"
                                       isDarkStyle={this.props.isDarkStyle} />
                    </div>
                    <LicenseMoveHistory isDarkStyle={this.props.isDarkStyle}
                                        user={this.props.currentUser}
                                        logs={this.state.logs}
                                        users={this.users}
                                        accounts={this.state.accounts}
                                        API={this.props.API}/>
                </div>
            </React.Fragment>
        );
    }
}

export default LicenseHistory;
