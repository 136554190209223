import React from 'react';
import Dialog from "@mui/material/Dialog";
import {I18n} from "aws-amplify/utils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

import '../../Views/Settings/Settings.css';
import '../../Views/Administration/Administration.css';
import './DialogGroupEdit.css'

const DialogGroupEdit = props => {

    const handleOk = () => {
        props.onClear();
    };

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";

    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.userGroups ) {
        return null;
    }

    const titleKey = ( props.title ) ? props.title : "Groups for the user";
    return (
        <Dialog disableEscapeKeyDown
                className={css}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.userGroups)}>
            <DialogTitle id="group-dialog-title">{I18n.get( titleKey )}</DialogTitle>
            <DialogContent dividers>
                {props.userGroups.split(',').map( group => <div>{group}</div> )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>
                    {I18n.get("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogGroupEdit;
