export default class ConfigAnalyzer {
    constructor( payStations ) {
        this.softwares = [];
        this.platforms = [];
        this.devices = [];
        this.terminals = [];

        if( payStations ) {
            payStations.forEach( payStation => {
                if( payStation && payStation.box && payStation.box.software ) {
                    this.softwares.push( payStation.box.software.identity );
                }

                if( payStation && payStation.devices ) {
                    payStation.devices.forEach( device => {
                        this.devices.push( device );
                    } );
                }

                if( payStation && payStation.terminals ) {
                    payStation.terminals.forEach( terminal => {
                        this.terminals.push( terminal );
                    } );
                }
            } )
        }
    }

    isActiveBoxAtSearchTime( payStation ) {
        /*let isActive = false;
        if( periods ) {
            periods.forEach( period => {
                let endedAt = period.endedAt;
                if( ! endedAt ) {
                    endedAt = new Date().toISOString();
                }

                if( period.startedAt >= requestedAt && endedAt >= requestedAt ) {
                    isActive = true;
                }
            } );
        }*/
        return true;
    }

    isActiveAtRequestTime( periods , requestedAt ) {
        let isActive = false;
        if( periods ) {
            periods.forEach( period => {
                let endedAt = period.endedAt;
                if( ! endedAt ) {
                    endedAt = new Date().toISOString();
                }

                if( period.startedAt >= requestedAt && endedAt >= requestedAt ) {
                    isActive = true;
                }
            } );
        }
        return true;
    }

    isValid( payStation ) {
        return  payStation &&
                payStation.box &&
                payStation.box.informations &&
                payStation.box.informations.hasOwnProperty( "identity" ) &&
                payStation.box.hasOwnProperty( "platform" ) &&
                payStation.box.hasOwnProperty( "software" );
    }

    getDistinctSoftwareLabels() {
        const returned = [];
        this.softwares.forEach( software => {
            if( software ) {
                if( ! returned.includes( software.model ) ) {
                    returned.push( software.model );
                }
            }
        });
        returned.sort();
        return returned;
    }

    getVersionsForSoftwareModel( model ) {
        const returned = [];
        this.softwares.forEach( software => {
            if( software ) {
                if( software.model === model ) {
                    if( ! returned.includes( software.version ) ) {
                        returned.push( software.version );
                    }
                }
            }
        });
        returned.sort();
        return returned;
    }

    getSeriesForSoftwareModel( model ) {
        const returned = [];
        const labels = this.getVersionsForSoftwareModel( model );
        this.softwares.forEach( software => {
            if( software ) {
                if( software.model === model ) {
                    const index = labels.indexOf( software.version );
                    if( returned[index] ) {
                        returned[index] = returned[index] + 1;
                    } else {
                        returned[index] = 1;
                    }
                }
            }
        });

        return [{data:returned}];
    }

    getDistinctActiveBoxCBMSList() {
        const returned = [];
        this.devices.forEach( device => {
            if( device.active && this.isActiveAtRequestTime( device.periods , new Date().toISOString() ) && device?.informations?.attributes?.box !== '' ) {
                returned.push( device?.informations?.attributes?.box );
            }
        } );
        return returned;
    }

    getDistinctActiveDeviceList() {
        const returned = [];
        this.devices.forEach( device => {
            if( device.active && this.isActiveAtRequestTime( device.periods , new Date().toISOString() ) ) {
                returned.push( device );
            }
        } );
        return returned;
    }

    getDistinctActiveTerminalList() {
        const returned = [];
        this.terminals.forEach( terminal => {
            if( terminal.active && this.isActiveAtRequestTime( terminal.periods , new Date().toISOString() ) ) {
                returned.push( terminal );
            }
        } );
        return returned;
    }

    getLabelsForType() {
        let returned = [];
        let activeDevices = this.getDistinctActiveDeviceList();
        activeDevices.forEach( device => {
            let label = `${device.informations?.identity?.model}`;
            if( ! returned.includes( label ) && label ) {
                returned.push( label );
            }
        } );
        return returned;
    }

    getSeriesForType() {
        const tmp = {};
        const returned = [];
        const activeDevices = this.getDistinctActiveDeviceList();
        const labels = this.getLabelsForType();
        activeDevices.forEach( device => {
            const label = `${device?.informations?.identity?.model}`;
            if( ! tmp.hasOwnProperty( label ) && label ) {
                tmp[label] = 0;
            }
            tmp[label]++;
        } );

        labels.forEach( label => {
            returned.push( tmp[label] );
        } );

        return returned;
    }

    getFirstActivation( periods ) {
        let first = new Date().getTime();
        if( periods ) {
            periods.forEach( period => {
                const candidate = new Date( period.startedAt );
                if( candidate.getTime() < first ) {
                    first = candidate.getTime();
                }
            })
        }
        return first;
    }

    getDistinctActivatedDeviceListSince( sinceTime , upToTime = new Date().getTime() ) {
        const returned = [];
        const activeDevices = this.getDistinctActiveDeviceList();
        activeDevices.forEach( candidate => {
            const firstActivation = this.getFirstActivation( candidate.periods );
            if( firstActivation >= sinceTime && firstActivation < upToTime ) {
                returned.push( candidate );
            }
        } );
        return returned;
    }

    getCountActivatedSoftwareSince( sinceTime , upToTime = new Date().getTime() ) {
        let count = 0;
        const activeDevices = this.getDistinctActiveDeviceList();
        const boxesDone = [];
        activeDevices.forEach( candidate => {
            try {
                const box = candidate.informations.attributes.box;
                const firstActivation = this.getFirstActivation( candidate.periods );
                if( box && box.trim() !== "" && ! boxesDone.includes( box ) && firstActivation >= sinceTime && firstActivation < upToTime ) {
                    count++;
                    boxesDone.push( box );
                }

            } catch ( error ) {
                //ignored
            }
        } );

        return count;
    }

    getLabelsForDeviceSoftwares() {
        const returned = [];
        const activeDevices = this.getDistinctActiveDeviceList();
        activeDevices.forEach( device => {
            try {
                const software = device?.informations?.attributes?.software?.split("_")[2];
                if( ! returned.includes( software ) && software ) {
                    returned.push( software );
                }
            } catch ( error ) {
                //ignored
            }
        } );
        return returned;
    }

    getSeriesForDeviceSoftwares() {
        const tmp = {};
        const returned = [];
        const activeDevices = this.getDistinctActiveDeviceList();
        const labels = this.getLabelsForDeviceSoftwares();
        const boxesDone = [];
        activeDevices.forEach( device => {
            try {
                const software = device?.informations?.attributes?.software?.split("_")[2];
                if( ! boxesDone.includes( device?.informations?.attributes?.box ) ) {
                    if( ! tmp.hasOwnProperty( software ) && software ) {
                        tmp[software] = 0;
                    }
                    tmp[software]++;
                    boxesDone.push( device?.informations?.attributes?.box );
                }
            } catch ( error ) {
                //ignored
            }
        } );

        labels.forEach( label => {
            returned.push( tmp[label] );
        } );

        return returned;
    }

    getActiveDeviceTimelines() {
        let devicesData = {};
        let activeDevices = this.getDistinctActiveDeviceList();
        activeDevices.forEach( device => {
            if( ! devicesData.hasOwnProperty( device.cbms ) ) {
                const firstActivation = this.getFirstActivation( device.periods );
                const bms = device?.informations?.bms;
                devicesData[bms] = {
                    firstOpening: firstActivation,
                    firstOpeningAsString:new Date( firstActivation ).toLocaleString()
                };
            }
        } );
        return devicesData
    }

    getDistinctTimeline( maxElement = 15 ) {
        const devicesData = this.getActiveDeviceTimelines();
        let timeline = [];
        Object.keys( devicesData ).forEach( bms => {
            const candidate = this.getLatestDayTimestamp( devicesData[bms].firstOpening );
            if( ! timeline.includes( candidate ) ) {
                timeline.push( candidate );
            }
        } );
        timeline.sort( (a,b) => b-a );

        let returned = [];
        timeline.forEach( candidate => {
            if( returned.length < maxElement ) {
                returned.unshift( candidate );
            }
        } );

        return returned;
    }

    getLatestDayTimestamp( timestamp ) {
        let date = new Date( timestamp );
        date.setHours( 23 , 59 , 59 );
        date.setMilliseconds(999);
        return date.getTime();
    }

    generateSeriesForTimelines( timelines ) {
        let series = [];
        let labels = this.getLabelsForType();
        let activeDevices = this.getActiveDeviceTimelines();
        labels.forEach( (label) => {
            series.push( this.generateSeriesForModel( label , timelines , activeDevices ) );
            return true;
        } );
        return series;
    }

    generateSeriesForModel( model , timelines , activeDevices ) {
        const series = {
            name:model,
            data:[]
        };

        timelines.forEach( timestamp => {
            let count = 0;
            /**let start = new Date(timestamp);
             start.setHours( 0 , 0 , 0 );*/
            Object.keys( activeDevices ).forEach( ( bms ) => {
                if( bms.includes(`_${model}_`) ) {
                    if( /*activeDevices[cbms].firstOpening > start && */activeDevices[bms].firstOpening < ( timestamp + 1 ) ) {
                        count++;
                    }
                }
            } );
            series.data.push( count );
        } );

        return series;
    }

    formatTimeline( timeline , locale , shortDisplay = false ) {
        let converted = [];
        if( timeline ) {
            timeline.forEach( timestamp => {
                if( shortDisplay ) {
                    converted.push( new Date( timestamp ).toLocaleDateString( locale ) );
                } else {
                    converted.push( new Date( timestamp ).toLocaleString( locale ) );
                }
            } )
        }
        return converted;
    }
}
