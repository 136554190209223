import React from 'react';
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";
import dayjs from 'dayjs';
import "dayjs/locale/fr";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import './PeriodChooser.css';
import {frFR} from "@mui/x-date-pickers/locales";

const factoryUUID = new UUID();
export default function TimeChooser(props) {
	const [selectedDate, setSelectedDate] = React.useState( props.period );

	const getLocale = () => {
		let locale = navigator.language || navigator.browserLanguage || ( navigator.languages || [ "fr" ] )[0];
		if( locale.indexOf("-") === -1 ) {
			locale = `${locale}-${locale.toUpperCase()}`;
		}
		return locale;
	}

	const getLocalizedDateFormat = () => {
		switch ( getLocale() ) {
			case "fr-FR": return "dd/MM/yyyy"
			default: return "MM/dd/yyyy"
		}
	}
	
	const is12HourTimeFormat = () => {
		let userLang = navigator.language || navigator.userLanguage; 
		switch ( userLang.toLowerCase() ) {
			case "fr-fr": return false
			case "fr-ca": return false
			case "fr-be": return false
			case "fr": return false
			default: return true
		}
	}
	
	const handleDateChange = dayJsDate => {
		const date = new Date( dayJsDate.toISOString() );
		setSelectedDate( date );
		if( props.onTimeChanged != null ) {
			props.onTimeChanged( date.toLocaleTimeString().substring(0,5) );
		}
	};

	const labelStart = I18n.get("Scheduled time") + " :";

	let css = "Period-chooser time-chooser";
	let cssDial = "dial-cm";
	if( props.isDarkStyle ) {
		css += " dark";
		cssDial += " dark";
	} 
	let dialogProps = {
		'className':cssDial
	};

	return (
		<React.Fragment>
			<div className={css}>
				<LocalizationProvider dateAdapter={AdapterDayjs}
									  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
									  adapterLocale="fr">

					<div className="period-line start">
						<MobileTimePicker label={labelStart}
										  id={factoryUUID.generate()}
										  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
										  ampm={is12HourTimeFormat()}
										  onChange={handleDateChange}
										  defaultValue={dayjs(selectedDate.toISOString())}/>
					</div>
				</LocalizationProvider>
			</div>
		</React.Fragment>
	);
}
