import React from 'react';
import DeviceSchema from '../../Components/DeviceSchema/DeviceSchema';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import './Diagnostic.css';
import NoDataPlaceholder from "../../Components/NoDataPlaceholder/NoDataPlaceholder";

let instance = null;
class Diagnostic extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			technicalData:props.technicalData
		};
		instance = this;
	}

	onNewTechnicalData( technicalData ) {
		instance.setState({
			technicalData:technicalData
		});
	}

	render() {
		const boxes = [];
		const analyzer = new ConfigAnalyzer( this.props.payStations );
		this.props.payStations?.forEach( payStation => {
			if( analyzer.isActiveBoxAtSearchTime( payStation ) && analyzer.isValid( payStation ) ) {
				boxes.push( payStation.box );
			}
		} );

		if ( ! boxes  || boxes.length < 1 ) {
			return (<NoDataPlaceholder className="comparison-placeholder-no-data" shown={true}/>);
		}

		return (
			<React.Fragment>
				<DeviceSchema isDarkStyle={this.props.isDarkStyle}
							  locale={this.props.locale}
							  boxList={boxes}
							  selected={this.props.selected}
							  selectedDevice={this.props.selectedDevice}
							  payStations={this.props.payStations}
							  isDesktop={this.props.isDesktop} />
			</React.Fragment>
		);
	}
}

export default Diagnostic;
