import UUID from "../../Utils/UUID";
const factoryUUID = new UUID();
class Task {
	constructor( observer ) {
		this.id = factoryUUID.generate();
		this.recipients = observer.availableRecipients;
		this.type = "Operations";
		this.filter = "REFILL_MANUAL";
		this.documentFormat = "PDF";
	}
	
	equals( anotherTask ) {
		return this.id === anotherTask.id;
	}
	
	static fromJson( jsonObject , observer ) {
		let task = new Task( observer );
		task.id = jsonObject.id;
		task.recipients = jsonObject.recipients;
		task.type = jsonObject.type;
		task.filter = jsonObject.filter;
		task.documentFormat = jsonObject.documentFormat;
		return task;
	}
	
	toJson() {
		return {
			id: this.id,
			recipients: this.recipients,
			type: this.type,
			filter: this.filter,
			documentFormat: this.documentFormat,
		};
	}
}

export default Task;
