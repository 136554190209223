export const getUserByUsername = `
  query GetUserByUsername ( $username: String! ) {
    getUserByUsername( username: $username )
  }
`;

export const getUsersForAccount = `
  query GetUsersForAccount ( $accountId: Int! ) {
    getUsersForAccount( accountId: $accountId )
  }
`;

export const getUserAttributes = `
  query GetUserAttributes ( $cognitoId: String! ) {
    getUserAttributes( cognitoId: $cognitoId )
  }
`;

export const getPayStationsForAccount = `
  query GetPayStationsForAccount ( $accountId: Int! , $cognitoId: String! , $offset: Int! ) {
    getPayStationsForAccount( accountId: $accountId , cognitoId: $cognitoId , offset: $offset )
  }
`;

export const getLatestBoxInf = `
  query GetLatestBoxInf ( $input: LatestIdentityInput! ) {
    getLatestBoxInf(input: $input) {
	  bms
      creation
      attributes {
		name
		devices 
		platform
		software
	  }
      identity {
		brand
		category
		name
		geolocation {
			latitude
			longitude
		}
		model
		serial
	  }
      sender {
		customer_code
		gateway {
			brand
			model
			serial
		}
		reseller_id
	  }
      at
	}
  }
`;

export const getLatestSoftwareInf = `
  query GetLatestSoftwareInf ( $input: LatestIdentityInput! ) {
    getLatestSoftwareInf(input: $input) {
	  bms
      creation
      attributes {
		organization
        type
        server
        tse
        version
        platform
        license
	  }
      identity {
		brand
		category
		name
		geolocation {
			latitude
			longitude
		}
		model
		serial
	  }
      sender {
		customer_code
		gateway {
			brand
			model
			serial
		}
		reseller_id
	  }
      at
	}
  }
`;

export const getLatestPlatformInf = `
  query GetLatestPlatformInf ( $input: LatestIdentityInput! ) {
    getLatestPlatformInf(input: $input) {
	  bms
      creation
      attributes {
		 baseboard {
                brand
                name
                version
            }
            bios {
                date
                brand
                name
                version
            }
            network {
                ip
                brand
                name
                mac
            }
            os {
                type
                deviceName
                disk {
                    free
                    size
                }
                ram {
                    free
                    size
                }
                name
                serial
                sku
                version
            }
            processor {
                brand
                name
                sockets
            }
            video {
                brand
                name
                size {
                    width
                    height
                }
            }
	  }
      identity {
		brand
		category
		name
		geolocation {
			latitude
			longitude
		}
		model
		serial
	  }
      sender {
		customer_code
		gateway {
			brand
			model
			serial
		}
		reseller_id
	  }
      at
	}
  }
`;

export const getLatestDeviceInf = `
  query GetLatestDeviceInf ( $input: LatestIdentityInput! ) {
    getLatestDeviceInf(input: $input) {
	  bms
      creation
      attributes {
		box
		series
		platform
		software
	  }
      identity {
		brand
		category
		name
		geolocation {
			latitude
			longitude
		}
		model
		serial
	  }
      sender {
		customer_code
		gateway {
			brand
			model
			serial
		}
		reseller_id
	  }
      at
	}
  }
`;

export const getLatestTerminalInf = `
  query GetLatestTerminalInf ( $input: LatestIdentityInput! ) {
    getLatestTerminalInf(input: $input) {
	  bms
      creation
      attributes {
		company
		shop
		terminal
		platform
		software
	  }
      identity {
		brand
		category
		name
		geolocation {
			latitude
			longitude
		}
		model
		serial
	  }
      sender {
		customer_code
		gateway {
			brand
			model
			serial
		}
		reseller_id
	  }
      at
	}
  }
`;

export const getAccounts = `
  query GetAccounts ( $accountId: Int! ) {
    getAccounts( accountId: $accountId )
  }
`;

export const getDetailedAccounts = `
  query GetDetailedAccounts ( $accountId: Int! ) {
    getDetailedAccounts( accountId: $accountId )
  }
`;

export const getGroups = `
  query GetGroups ( $accountId: Int! ) {
    getGroups( accountId: $accountId )
  }
`;

export const getUserGroups = `
  query GetUserGroups ( $cognitoId: String! ) {
    getUserGroups( cognitoId: $cognitoId )
  }
`;

export const getLatestState = /* GraphQL */ `
  query GetLatestState ($cbms: String! , $at: String!) {
    getLatestState( cbms: $cbms , at: $at ) {
      cbms
      at
      deviceType
      state
    }
  }
`;

export const getLatestTechnicalData = /* GraphQL */ `
  query GetLatestTechnicalData ($cbms: String! , $updatedAt: String!) {
    getLatestTechnicalData( cbms: $cbms , updatedAt: $updatedAt ) {
      cbms
      at
      data
    }
  }
`;

export const getLatestInventory = /* GraphQL */ `
  query GetLatestInventory ($cbms: String! , $requestDate: String!) {
    getLatestInventory( cbms: $cbms , requestDate: $requestDate ) {
		cbms
		data {
			deviceCBMS
			uuid
			details {
				value
				empty
				low
				high
				full
				qty
				currency
			}
			cashBox {
				empty
				low
				high
				full
				content {
					value
					qty
					currency
				}
			}
			loader {
				empty
				low
				high
				full
				content {
					value
					qty
					currency
				}
			}
		}
		at
		sender {
			customer_code
			gateway {
				brand
				model
				serial
			}
			reseller_id
		}
		uuid
    }
  }
`;

export const getPreferences = /* GraphQL */ `
	query GetPreferences ( $userIdAndBoxId: String! ) {
		getPreferences( userIdAndBoxId: $userIdAndBoxId ) {
			userIdAndBoxId
			data
		}
	}
`;

export const listRootNodesForUser = `
  query ListRootNodesForUser($input: SearchNodeInput!) {
    listRootNodesForUser(input: $input) 
  }
`;

//unused
export const searchNodeById = `
  query SearchNodeById($input: SearchNodeInput!) {
    searchNodeById(input: $input) 
  }
`;

export const listOperations = `
    query ListOperations($input: PaginatedOperationsInput!) {
        listOperations(input: $input) {
            nextToken
            operations {
                cbms
                at
                cashier
                currency
                type
                uuid
                data {
                    details {
                    	currency
                    	deviceCBMS
                    	qty
                    	rate
                    	type
                    	value
                    	VCR
                    	error
                    }
                }
                sender {
					customer_code
					gateway {
						brand
						model
						serial
					}
					reseller_id
				}
            }
        }
    }
`;

export const getOperationAt = `
    query GetOperationAt( $cbms: String! , $at: String! ) {
        getOperationAt( cbms: $cbms , at: $at) {
			cbms
			at
			cashier
			currency
			type
			uuid
			data {
				details {
					currency
					deviceCBMS
					qty
					rate
					type
					value
					VCR
					error
				}
			}
			sender {
				customer_code
				gateway {
					brand
					model
					serial
				}
				reseller_id
			}
        }
    }
`;

export const listLogs = `
    query ListLogs($input: LogInput!) {
        listLogs(input: $input) {
            nextToken
            logs {
            	userId
			    at
			    type
			    details
            }
        }
    }
`;

export const listCachesForOperations = `
    query ListCachesForOperations($input: PaginatedCacheOperationsInput!) {
        listCachesForOperations(input: $input) {
            cacheOperations {
				details
				nodeId
				operation_date
				updatedAt
			}
			nextToken
        }
    }
`;

export const listInventories = `
    query ListInventories($input: PaginatedInventoriesInput!) {
        listInventories(input: $input) {
            nextToken
            inventories {
                cbms
                data {
                    local_timestamp
                    utc_timestamp
                    uuid
                    locations {
                        currency
                        details {
                            currency
                            fund_type
                            quantity
                            value
                        }
                        fund_location
                        fund_type
                        high_stop
                        high_warning
                        low_stop
                        low_warning
                        value
                    }
                    identity {
                        brand
                        category
                        hash_value
                        id
                        model
                        serial
                    }
                }
                inventory_date
                client_date
                sender {
                    customer_code
                    gateway {
                        brand
                        hash_value
                        model
                        serial
                    }
                    reseller_id
                }
            }
        }
    }
`;

export const listDailyInventories = `
    query ListDailyInventories($input: PaginatedDailyInventoriesInput!) {
        listDailyInventories(input: $input) {
            nextToken
            inventories {
                cbms
				inventory_date
				inventory
				updatedAt
            }
        }
    }
`;

export const listMessages = `
    query ListMessages($input: PaginatedMessagesInput!) {
        listMessages(input: $input) {
            nextToken
            messages {
                cbms
                at
                type
                text
                level
            }
        }
    }
`;

export const getUserByUuid = `
    query GetUserByUuid($uuid: String!) {
        getUserByUuid(uuid: $uuid) {
            access_level
			email
			firstname
			group_id
			id
			lastname
			node_id
			phone
			uuid
        }
    }
`;

export const getAvailableUserList = `
    query GetAvailableUserList($uuid: String!) {
        getAvailableUserList(uuid: $uuid) {
            access_level
			email
			firstname
			group_id
			id
			lastname
			node_id
			phone
			uuid
        }
    }
`;

/*export const listRootNodesForUser = `
  query ListRootNodesForUser($cognitoId: String!) {
    listRootNodesForUser(cognitoId: $cognitoId) {
		id
		brand
		category
		city
		country
		lang
		latitude
		longitude
		model
		name
		role
		serial
		street
		urlMap
		zip_code
    }
  }
`;*/

//unused
export const listChildrenForNode = /* GraphQL */ `
  query ListChildrenForNode($id: Int!) {
    listChildrenForNode(id: $id) {
		city
		country
		id
		lang
		latitude
		longitude
		name
		role
		street
		urlMap
		zipCode
		brand
		category
		model
		serial
    }
  }
`;

export const getTerminalInf = /* GraphQL */ `
    query GetTerminalInf($input: GetIdentityInput!) {
        getTerminalInf(input: $input) {
            bms
            creation
            hashvalue
            updatedAt
            data {
                custom {
                    company
                    shop
                   terminal
                }
                identity {
                    brand
                    category
                    hash_value
                    id
                    model
                    serial
                }
            }
        }
    }
`;

export const getMergersForBox = `
    query GetMergersForBox($input: GetMergerInput!) {
        getMergersForBox(input: $input) {
            bms_box
            hashvalue_box
            bms_merger
            hashvalue_merger
        }
    }
`;

export const getBoxConfigurationsForPeriod = `
    query GetBoxConfigurationsForPeriod($input: PeriodIdentityInput!) {
        getBoxConfigurationsForPeriod(input: $input) {
            bms
            hashvalue
        }
    }
`;

export const getServerTime = /* GraphQL */ `
    query GetServerTime {
        getServerTime
    }
`;

export const generateDoc = /* GraphQL */ `
    query GeneratePDF( $input: InputPDF! ) {
		generatePDF( input: $input )
	}
`;

export const getAlertForUser = /* GraphQL */ `
    query GetAlertForUser( $input: String! ) {
		getAlertForUser( userId: $input ) {
			creation
			parameters
			updatedAt
			userId
			lastSentAt
		}
	}
`;

export const getTaskForUser = /* GraphQL */ `
    query GetTaskForUser( $input: String! ) {
		getTaskForUser( userId: $input ) {
			creation
			parameters
			updatedAt
			userId
			offset
			lastSentAt
		}
	}
`;

export const getAccountOptions = /* GraphQL */ `
	query GetAccountOptions( $accountId: Int! ) {
		getAccountOptions( accountId: $accountId ) {
			creation
			options
			updatedAt
			accountId
			lastSentAt
		}
	}
`;

export const listUsersByNodes = /* GraphQL */ `
    query ListUsersByNodes( $input: String! ) {
		listUsersByNodes( node_list: $input ) {
			access_level
			email
			firstname
			group_id
			id
			lastname
			node_id
			phone
			uuid
		}
	}
`;

export const getResource = /* GraphQL */ `
	query GetResource($id: String!) {
		getResource(id: $id) {
			id
			data
		}
	}
`;

/*export const listNodes = `
  query ListNodes(
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;*/
