import React from "react";
import {I18n} from "aws-amplify/utils";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import UUID from "../../../Utils/UUID";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputRecipients from "../InputRecipients/InputRecipients";

let currentField = {
    id:null,
    lastChange:null,
    value:null
};

export default function DialogManageAccountOptions( props ) {
    const [editing, setEditing] = React.useState(null );
    const [techAlertRecipients, setTechAlertRecipients] = React.useState( ( props.pendingEdit?.techAlertRecipients ) ? props.pendingEdit?.techAlertRecipients : null );

    const handleCancel = () => {
        props.handleClose();
        setEditing(null);
    };

    const handleOk = () => {
        editing.techAlertRecipients = JSON.parse( JSON.stringify( techAlertRecipients ) );
        editing.ifNotActiveForDays = props.pendingEdit.Days;
        editing.licenses = props.pendingEdit.licenses;
        props.handleClose( editing );
        setEditing(null);
    };

    const newEntryHandler = evt => {
        //unused
    }

    const handleRecipientsChange = ( id , value ) => {
        setTechAlertRecipients( value );
    }

    const isSubmitDisabled = () => {
        return ! ( techAlertRecipients?.length > 0 && isValidDays() );
    }

    const handleInputChange = ( evt , property ) => {
        currentField = {
            id:`user-edited-${property}`,
            lastChange:new Date(),
            value:evt.target.value,
            property:property
        }

        setTimeout( () => {
            if( currentField.id ) {
                if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) {
                    props.pendingEdit[currentField.property] = currentField.value;
                    editing[currentField.property] = currentField.value;
                    currentField = {
                        id:null,
                        lastChange:null,
                        value:null,
                        property:null
                    };
                }
            }
        } , 5000 );

        let clone = Object.assign( {} , editing );
        clone[currentField.property] = currentField.value;
        props.pendingEdit[currentField.property] = currentField.value;
        setEditing( clone );
    }

    const handleInputBlur = evt => {
        if( currentField.id ) {
            props.pendingEdit[currentField.property] = currentField.value;
            editing[currentField.property] = currentField.value;
            currentField = {
                id:null,
                lastChange:null,
                value:null,
                property:null
            };
        }
    }

    const isValidDays = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Days && parseInt( props.pendingEdit.Days.trim() , 10 ) > 0 ) {
                return true;
            }
        }
        return false;
    }

    const getHelperTextDays = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Days && props.pendingEdit.Days.trim() !== '' ) {
                return null;
            } else {
                return I18n.get("Required field");
            }
        }

        return null;
    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";

    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.pendingEdit ) {
        return null;
    }

    if( ! editing ) {
        setEditing( props.pendingEdit );
    }

    if( props.pendingEdit?.techAlertRecipients && props.pendingEdit?.techAlertRecipients?.length > 0 && techAlertRecipients?.length !== props.pendingEdit?.techAlertRecipients?.length ) {
        setTechAlertRecipients( props.pendingEdit?.techAlertRecipients );
    }

    const daysIfExists = ( props.pendingEdit && props.pendingEdit.hasOwnProperty("ifNotActiveForDays") ) ? props.pendingEdit.ifNotActiveForDays : 30;
    if( ! props.pendingEdit.Days ) {
        props.pendingEdit["Days"] = `${daysIfExists}`;
        props.pendingEdit["ifNotActiveForDays"] = `${daysIfExists}`;
    }

    return (
        <Dialog classes={{ paper: css }}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.pendingEdit)}>
            <DialogTitle id="account-options-dialog-title">{I18n.get("Account options")}</DialogTitle>
            <DialogContent dividers>
                <div>{I18n.get("Alert if inactive for ( days )")}</div>
                <div className="user-data-item name">
                    <TextField
                        required
                        error={!isValidDays()}
                        helperText={getHelperTextDays()}
                        variant="outlined"
                        id={`user-edited-name`}

                        label={I18n.get("Days of inactivity")}
                        placeholder={I18n.get("Not Specified")}
                        defaultValue={daysIfExists}
                        InputProps={{
                            readOnly: false,
                        }}
                        onChange={(evt) => handleInputChange( evt , "Days" ) }
                        onBlur={(evt) => handleInputBlur( evt ) }
                    />
                </div>
                <div className="user-data-item name">
                    <div>{I18n.get("Technical alerts recipients")}</div>
                    <InputRecipients id="recipients"
                                     label={I18n.get("Recipients")}
                                     recipients={techAlertRecipients}
                                     newEntryObserver={newEntryHandler}
                                     onValueChanged={handleRecipientsChange}
                                     isDarkStyle={props.isDarkStyle} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} className="btn-neutral-secondary btn-pill text-danger mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("CANCEL")}
						</span>
                </Button>
                <Button onClick={handleOk} className="btn-neutral-secondary btn-pill text-danger mx-1" disabled={isSubmitDisabled()}>
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
                </Button>
            </DialogActions>
        </Dialog>

    );
}
