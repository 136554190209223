
class GraphQlTool {
	constructor( queryName , result ) {
		this.result = this.convert( result.data[queryName] );
	}

	convert( toClean ) {
		if( toClean === '{}' ) return {};

		try {
			return JSON.parse( toClean.replace(/}$/, '').substring( toClean.indexOf('=') + 1 ) );
		} catch ( error ) {
			console.error( error );
			console.error( 'toClean' , toClean );
			return {};
		}
	}

	extract() {
		return this.result;
	}
}

export default GraphQlTool;
