import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'aws-amplify/utils';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DialogConfirmRemove from '../../Components/DialogConfirmRemove/DialogConfirmRemove';
import InstallationManager from "../../Components/AdministrationManagers/InstallationManager";
import AccountManager from "../../Components/AdministrationManagers/AccountManager";
import UserManager from "../../Components/AdministrationManagers/UserManager";
import GraphQlTool from "../../../Utils/GraphQlTool";
import {getUsersForAccount, getUserAttributes, getDetailedAccounts} from "../../../graphql/queries";
import '../Settings/Settings.css';
import './Administration.css';
import GroupManager from "../../Components/AdministrationManagers/GroupManager";
import DialogDisplayGroups from "../../Components/AdministrationManagers/DialogDisplayGroups";
import DialogUserData from "../../Components/AdministrationManagers/DialogUserData";

const ROOT = 1;
const ADMIN = 2;
const USER = 3;
const RESELLER = 4;
const CUSTOMER = 5;

let adminInstance;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

class Administration extends React.Component {
	constructor( props ) {
		super( props );
		const tab = ( props.removedBoxId && props.removedBoxId > 0 ) ? 1 : 0;
		this.state = {
			rows: [],
			value: tab,
			pendingDelete:null,
			pendingEdit:null,
			currentFilterRole:0,
			currentFilterField:null,
			isLoading: true,
			userGroups: null,
			accounts: null
		};

		this.nodeMapRef = null;
		this.queuedNodeUserDeletion = [];
		adminInstance = this;
	};

	loadAccounts() {
		if( this.props.API ) {
			this.props.API
				.graphql({ query: getDetailedAccounts, variables: { accountId: this.props.observer.currentUser.accountId }})
				.then( returned => {
					const tool = new GraphQlTool( 'getDetailedAccounts' , returned );
					this.setState( { accounts: tool.extract() } );

				})
				.catch((error) => {
					console.error("error" , error);
				})
		}
	};

	handleChange(event, newValue) {
		if( adminInstance.state.pendingDelete ) {
			adminInstance.state.pendingDelete.usertype = newValue;
		}
		
		if( adminInstance.state.pendingEdit ) {
			adminInstance.state.pendingEdit.usertype = newValue;
		}
		
        adminInstance.setState({
			rows:adminInstance.state.rows,
			value:newValue,
			pendingDelete:adminInstance.state.pendingDelete,
			pendingEdit:adminInstance.state.pendingEdit,
			currentFilterRole:adminInstance.state.currentFilterRole,
			currentFilterField:adminInstance.state.currentFilterField,
		});
    };

	displayGroupsHandler( groups ) {
		adminInstance.setState({
			value:adminInstance.state.value,
			pendingDelete:adminInstance.state.pendingDelete,
			pendingEdit:adminInstance.state.pendingEdit,
			currentFilterRole:adminInstance.state.currentFilterRole,
			currentFilterField:adminInstance.state.currentFilterField,
			userGroups:groups
		});
	};

	handleClearUserGroups( groups ) {
		adminInstance.setState({
			value:adminInstance.state.value,
			pendingDelete:adminInstance.state.pendingDelete,
			pendingEdit:adminInstance.state.pendingEdit,
			currentFilterRole:adminInstance.state.currentFilterRole,
			currentFilterField:adminInstance.state.currentFilterField,
			userGroups:null
		});
	};
	
	filterRegistered( rows ) {
		/*if( this.props.registeredNodes ) {
			let filtered = [];
			rows.map( (candidate) => {
				if( Object.keys( this.props.registeredNodes ).includes( `${candidate["node_id"]}` ) ) {
					filtered.push( candidate );
				}
				return true;
			} );
			return filtered;
		}*/
		return rows;
	}
	
	filterByRoles( rows ) {
		if( adminInstance.state.currentFilterRole !== 0 ) {
			if( rows ) {
				let filtered = [];
				rows.forEach( (candidate) => {
					if( candidate.usertype === adminInstance.state.currentFilterRole ) {
						filtered.push( candidate );
					}
				} );
				return filtered;
			}
			
		}
		return rows;
	}
	
	filterByName( rows ) {
		if( adminInstance.state.currentFilterField && adminInstance.state.currentFilterField !== "" ) {
			if( rows ) {
				let filtered = [];
				rows.forEach( (candidate) => {
					if( candidate.userlabel.toLowerCase().includes( adminInstance.state.currentFilterField.toLowerCase() ) ) {
						filtered.push( candidate );	
					}
					return true;
				} );
				return filtered;
			}
			
		}
		return rows;
	}
	
	filterDisplayedUser( rows ) {
		const ROOT = 1;
		let displayed = [];
		rows.map( ( user ) => {
			if( user.accessLevel !== ROOT ) {
				displayed.push( user );
			}
			return true;
		} );
		return displayed;
	}
	
	renderTab( labelKey ) {
		const key = "administration-tab-nav-item-" + labelKey.replace(/\s/g , "-");
		return ( <Tab key={key}
					  label={I18n.get( labelKey )}
					  className={`admin-button`}
					  disabled={( adminInstance.props.observer.currentUser.type !== ROOT &&
						  	adminInstance.props.observer.currentUser.type !== ADMIN &&
						  	adminInstance.props.observer.currentUser.type !== RESELLER ) || adminInstance.state.isLoading }/> );
	}
	
	renderTabPanel( labelKey , counterIndex , data ) {
		const key = "administration-tab-panel-item-" + labelKey.replace(/\s/g , "-");
		const spaceBottom = "mb-3"

		if( counterIndex === 0 ) {
			let shown = false;
			let cssTable = "";//"comparison-table";
			if( data.length < 1 && ! adminInstance.state.isLoading ) {
				shown = true;
				cssTable += " hidden";
			}
			return ( 
				<TabPanel key={key} value={adminInstance.state.value} index={counterIndex}>
                	<div className="tab-panel-content">
                		<Grid container spacing={1} >
                			<Grid item xs={12} md={12} lg={12}>
                				<Paper className="card-box">
                                    <UserManager payStations={adminInstance.props.payStations}
                								 user={adminInstance.props.observer.currentUser}
                								 API={adminInstance.props.API}
                								 isDarkStyle={adminInstance.props.isDarkStyle}
                								 isDesktop={adminInstance.props.isDesktop}
                								 observer={adminInstance.props.observer}
                								 onGroupDisplay={adminInstance.displayGroupsHandler}
                								 excludeId={adminInstance.props.observer.currentUser.id}
												 accounts={adminInstance.state.accounts}
												 onLoadingComplete={() => {adminInstance.setState({ isLoading: false })}}/>
                                </Paper>
                			</Grid>
                		</Grid>
                	</div>
                </TabPanel>
			);
		} else if ( counterIndex === 1 && this.props?.observer?.currentUser?.accountType !== CUSTOMER ) {
			return (
				<TabPanel key={key} value={adminInstance.state.value} index={counterIndex}>
					<div className="tab-panel-content">
						<Grid container spacing={1} >
							<Grid item xs={12} md={12} lg={12}>
								<Paper className={ `card-box ${spaceBottom}` }>
									<InstallationManager payStations={adminInstance.props.payStations}
														 user={adminInstance.props.observer.currentUser}
														 API={adminInstance.props.API}
														 isDarkStyle={adminInstance.props.isDarkStyle}
														 isDesktop={adminInstance.props.isDesktop}
														 observer={adminInstance.props.observer}
														 onPaystationRemoved={adminInstance.props.onPaystationRemoved}
														 onRequestedBoxOverview={adminInstance.props.onRequestedBoxOverview}
														 onRequestedBoxDiagnostic={adminInstance.props.onRequestedBoxDiagnostic}/>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</TabPanel>
			);
		} else if ( counterIndex === 2 ) {
			return (
				<TabPanel key={key} value={adminInstance.state.value} index={counterIndex}>
					<div className="tab-panel-content">
						<Grid container spacing={1} >
							<Grid item xs={12} md={12} lg={12}>
								<Paper className={ `card-box ${spaceBottom}` }>
									<AccountManager isDarkStyle={adminInstance.props.isDarkStyle}
													user={adminInstance.props.observer.currentUser}
													API={adminInstance.props.API}
													userDelegate={adminInstance.handleConfirm}/>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</TabPanel>
			);
		} else if ( counterIndex === 3 || ( this.props?.observer?.currentUser?.accountType === CUSTOMER && counterIndex === 1 ) ) {
			return (
				<TabPanel key={key} value={adminInstance.state.value} index={counterIndex}>
					<div className="tab-panel-content">
						<Grid container spacing={1} >
							<Grid item xs={12} md={12} lg={12}>
								<Paper className={ `card-box ${spaceBottom}` }>
									<GroupManager isDarkStyle={adminInstance.props.isDarkStyle}
												  user={adminInstance.props.observer.currentUser}
												  API={adminInstance.props.API}
												  payStations={adminInstance.props.payStations}
												  filteredPayStations={adminInstance.props.filteredPayStations}
												  availableUsers={data}/>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</TabPanel>
			);
		}
		return null;
	}

	render() {
		if( ! this.state.accounts ) {
			this.loadAccounts();
		}

		let tabs = [];
		tabs.push( this.renderTab( "Manage Users" ) );
		let panels = [];
		let data = ( ! this.state.isLoading ) ? this.filterByName( this.filterByRoles( this.filterDisplayedUser( this.filterRegistered( this.state.rows ) ) ) ) : [];
		panels.push( this.renderTabPanel( "Manage Users" , 0 , data ) );

		if( this.props?.observer?.currentUser?.accountType !== CUSTOMER ) {
			tabs.push( this.renderTab( "Manage Installations" ) );
			tabs.push( this.renderTab( "Manage Accounts" ) );
			panels.push( this.renderTabPanel( "Manage Installations" , 1 , data ) ) ;
			panels.push( this.renderTabPanel( "Manage Accounts" , 2 , data ) ) ;
			panels.push( this.renderTabPanel( "Manage Groups" , 3 , data ) ) ;
		} else {
			panels.push( this.renderTabPanel( "Manage Groups" , 1 , data ) ) ;
		}
		tabs.push( this.renderTab( "Manage Groups" ) );

		if( this.props.isDesktop ) {
			return (
				<React.Fragment>
					<div className="z-over fullscreen">
						<Tabs 	className="nav-tabs-primary" 
								key="administration-tab-nav"
								value={adminInstance.state.value}
								variant="fullWidth"
								onChange={adminInstance.handleChange}>
							{tabs.map( item => item )}	
						</Tabs>
						{panels.map( item => item )}
						<DialogConfirmRemove handleClose={this.handleConfirm}
											 pendingDelete={this.state.pendingDelete}
											 isDarkStyle={this.props.isDarkStyle}/>
											 
						<DialogUserData handleClose={this.handleConfirm}
										user={this.props.user}
										userRDS={this.props.observer.currentUser}
										pendingEdit={this.state.pendingEdit}
										isDarkStyle={this.props.isDarkStyle}/>

						<DialogDisplayGroups onClear={this.handleClearUserGroups}
											 isDarkStyle={this.props.isDarkStyle}
											 userGroups={this.state.userGroups}/>
					</div>
				</React.Fragment>
			);
		}
		
		return (
			<React.Fragment>
				<div className="z-over fullscreen">
					<Tabs 	className="nav-tabs-primary" 
							key="administration-tab-nav"
							value={adminInstance.state.value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={adminInstance.handleChange}>
						{tabs.map( item => item )}	
					</Tabs>
					{panels.map( item => item )}

					<DialogConfirmRemove handleClose={this.handleConfirm}
										 pendingDelete={this.state.pendingDelete}
										 isDarkStyle={this.props.isDarkStyle}/>
										 
					<DialogUserData handleClose={this.handleConfirm}
									user={this.props.user}
									userRDS={this.props.observer.currentUser}
									pendingEdit={this.state.pendingEdit}
									isDarkStyle={this.props.isDarkStyle}/>

					<DialogDisplayGroups onClear={this.handleClearUserGroups}
										 isDarkStyle={this.props.isDarkStyle}
										 userGroups={this.state.userGroups}/>
				</div>
			</React.Fragment>
		);
	}
}

export default Administration;
