import DeviceTemplate , {Mode} from './DeviceTemplate';

export const StateLevel = {
	OPERATIONAL:"operational",
	CLEANUP:"cleanup",
	MAINTENANCE:"maintenance",
};

class DeviceDataAnalyzer {
	constructor( model , variant , attributes , data , mode ) {
		this.template = new DeviceTemplate( model , variant , attributes ).getTemplate();
		this.data = data;
		this.mode = mode;
		this.model = model;
		this.variant = variant;
		this.attributes = attributes;
		this.slippageAdjustement = 1; //10 to have a 100% gauge
	}
	
	setLocale( locale ) {
		this.locale = locale;
	}
	
	extractRecyclerGroupsFromParameter( parameters ) {
		let groups = [];
		if( parameters !== null && parameters !== undefined ) {
			parameters.map( ( candidateId ) => {
				let candidateGroup = null;
				switch( candidateId ) {
					case "198450"://fall through
					case "198451": candidateGroup = "r1-2";
					break;
					case "198452"://fall through
					case "198453": candidateGroup = "r3-4";
					break;
					case "198454"://fall through
					case "198455": candidateGroup = "r5-6";
					break;
					default:
					break;
				}
				
				if( ! groups.includes( candidateGroup ) ) {
					groups.push( candidateGroup );
				}
				
				return true;
			} );
		}
		return groups;
	}

	extractThermomethersCandidates() {
		if( this.state.device !== null && this.state.device !== undefined ) {
			return this.extractModuleThermometers( this.state.device );
		} else {
			return this.extractDeviceThermometers();
		}
	}
	
	extractDeviceThermometers() {
		let candidates = [];
		
		return candidates;
	}
	
	extractModuleThermometers( device ) {
		let candidates = [];
		switch(device.name) {
			case "mainModule":
			break;
			case "bundler":
			break;
			case "acceptor":
			break;
			case "cashbox":
			break;
			case "loader":
			break;
			case "r3-4":
			break;
			case "r5-6":
			break;
			case "acceptor_input":
			break;
			default:
			break;
		}
		candidates.push("tmp");
		return candidates;
	}
	
	extractDataGroupedByType() {
		let returned = {};
		let templateRoot = null;
		let dataRoot = null;
		if( this.template ) {
			dataRoot = this.data;
			templateRoot = this.template;
			
			if( templateRoot && dataRoot ) {
				returned = this.extractDataByType( templateRoot , dataRoot );
			}
		}

		return returned;
	}
	
	extractDataByType( template , rootObject ) {
		let returned = {};
		Object.keys( template ).map( ( key ) => {
			if( key && rootObject && rootObject.hasOwnProperty( key ) ) {
				let candidate = template[key];
				let data = rootObject[key];
				if( candidate.hasOwnProperty( "type" ) ) {
					if( candidate.hasOwnProperty( "modes" ) && ( candidate.modes.includes( this.mode ) || this.mode === Mode.ALL ) ) {
						if( ! returned.hasOwnProperty( candidate.type ) ) {
							returned[candidate.type] = [];
						}
						
						if( candidate.hasOwnProperty( "attributes" ) ) {
							//list values
						} else {
							//object value
							returned[candidate.type].push( { key:key , value:data } );
						}
					}
				} else {
					const returnedFromChildren = this.extractDataByType( candidate , data );
					Object.keys( returnedFromChildren ).map( ( keyChild ) => {
						if( ! returned.hasOwnProperty( keyChild ) ) {
							returned[keyChild] = [];
						}
						
						returnedFromChildren[keyChild].map( ( toAdd ) => {
							returned[keyChild].push( toAdd );
							return true;
						} );
						return true;
					} );
				}
			}
			return true;
		} );
		return returned;
	}
	
	extractSensorListForModule( module ) {
		const returned = [];
		if( this.template && this.template.hasOwnProperty("modules") && this.template.modules.hasOwnProperty( module ) && this.template.modules[module].hasOwnProperty( "sensors" ) ) {
			Object.keys( this.template.modules[module].sensors ).forEach( sensor => {
				//Wait CPI implementation
				if( sensor !== '196872' ) {
					returned.push( sensor );
				}
			} );
		}
		return returned;
	}
	
	extractSensorIdList( state , module , organizedData ) {
		let returned = [];
		let sensorList = this.extractSensorListForModule( module );
		if( sensorList.length > 0 ) {
			sensorList.map( (sensor) => {
				const sensorCurrentValue = this.getValueFromData( organizedData , "Sensor" , sensor );
				if( this.extractStateForSensor( module , sensorCurrentValue ) === state ) {
					returned.push(sensor);
				}
				return true;
			} );
			return returned;
		}

		if( module === "recycler" ) {
			for( let i = 1 ; i < 7 ; i++ ) {
				let sensorList = this.extractSensorListForModule( module + i );
				if( sensorList.length > 0 ) {
					for( let j = 0 ; j < sensorList.length ; j++ ) {
						const sensor = sensorList[j];
						if( sensor !== null && sensor !== undefined ) {
							const sensorCurrentValue = this.getValueFromData( organizedData , "Sensor" , sensor );
							if( this.extractStateForSensor( module , sensorCurrentValue ) === state ) {
								returned.push(sensor);
							}
						}
					}
				}
			}
			return returned;
		}
		
		//no sensor for the module make different a 0 count and unavailable feature
		return "";
	}
	
	extractSensorCount( state , module , organizedData ) {
		const sensorIdList = this.extractSensorIdList(state , module , organizedData );
		if( sensorIdList !== null && sensorIdList !== undefined && sensorIdList !== "" ) {
			return sensorIdList.length;
		}

		//no sensor for the module make different a 0 count and unavailable feature
		return "";
	}
	
	extractStateForSensor( module , currentValue ) {
		let maintenanceLevel = 15;
		let cleanUpLevel = 30;
		const precision = 0.00001;
		
		if( module === "mainModule" ) {
			//override thresholds for level
			maintenanceLevel = 20;
			cleanUpLevel = 70;
		} else if ( module === "slippage" ) {
			if( this.isAdvance ) {
				maintenanceLevel = 8.1 * this.slippageAdjustement;
				cleanUpLevel = 6.1 * this.slippageAdjustement;
			} else {
				maintenanceLevel = 8.1 * this.slippageAdjustement;
				cleanUpLevel = 3.1 * this.slippageAdjustement;
			}
		}
		
		if( module === "slippage" ) {
			if( currentValue < cleanUpLevel ) {
				return StateLevel.OPERATIONAL;
			} else if ( currentValue < maintenanceLevel ) {
				return StateLevel.CLEANUP;
			} else {
				return StateLevel.MAINTENANCE;
			}
		} else {
			if( currentValue > cleanUpLevel ) {
				return StateLevel.OPERATIONAL;
			} else if ( currentValue > maintenanceLevel && currentValue < ( cleanUpLevel + precision ) ) {
				return StateLevel.CLEANUP;
			} else if( currentValue < ( maintenanceLevel + precision ) ) {
				return StateLevel.MAINTENANCE;
			}
		}

		return "UNKNOWN";
	}
	
	extractLowestSensor( module , organizedData ) {
		let sensorList = this.extractSensorListForModule( module );
		let lowestSensor = {id:"Unknown" , value:101};
		if( sensorList.length > 0 ) {
			sensorList.map( (sensor) => {
				if( sensor !== null && sensor !== undefined ) {
					const sensorCurrentValue = this.getValueFromData( organizedData , "Sensor" , sensor );
					if( sensorCurrentValue < lowestSensor.value ) {
						lowestSensor = {id:sensor , value:sensorCurrentValue , state:this.extractStateForSensor( module , sensorCurrentValue )};
					}
				}
				return true;
			} );
			return lowestSensor;
		}
		
		if( module === "recycler" ) {
			for( let i = 1 ; i < 7 ; i++ ) {
				let sensorList = this.extractSensorListForModule( module + i );
				if( sensorList.length > 0 ) {
					for( let j = 0 ; j < sensorList.length ; j++ ) {
						const sensor = sensorList[j];
						if( sensor !== null && sensor !== undefined ) {
							const sensorCurrentValue = this.getValueFromData( organizedData , "Sensor" , sensor );
							if( sensorCurrentValue < lowestSensor.value ) {
								lowestSensor = {id:sensor , value:sensorCurrentValue , state:this.extractStateForSensor( module , sensorCurrentValue )};
							}
						}
					}
				}
			}
			return lowestSensor;
		}
		
		return null;
	}
	
	isAdvance() {
		return false;
	}
	
	getValueFromData( data , type , key ) {
		let returned = null;
		if( data !== null && data !== undefined && data.hasOwnProperty( type ) ) {
			data[type].map( ( candidate ) => {
				if( candidate !== null && candidate !== undefined && candidate.hasOwnProperty("key") && candidate.key === key ) {
					returned = candidate.value;
				}
				return true;
			} );
		}
		return returned;
	}

	sensorHasAnomaly( sensorIdList , cleanUpList , maintenanceList ) {
		let anomalyFound = false;
		sensorIdList.map( ( sensorToCheck ) => {
			if( cleanUpList.includes( sensorToCheck ) || maintenanceList.includes( sensorToCheck ) ) {
				anomalyFound = true;
			}
			return true;
		} );
		return anomalyFound;
	}

	getBNRState() {
		/*const deviceData = {
			mainModule:{
				state:"error",
				errors:["Something wrong"],
				//state:"valid",
				acceptor:{
					state:"error",
					errors:["Something wrong"],
					//state:"valid",
					input:{
						state:"error",
						errors:["Something wrong"],
						state:"valid"
					},
					output:{
						state:"error",
						errors:["Something wrong"],
						state:"valid",
					}
				}
			},
			recycler1:{
				state:"valid"
			},
			recycler2:{
				state:"error",
				errors:["Something wrong"]
				state:"valid",
			},
			recycler3:{
				state:"valid"
			},
			recycler4:{
				state:"valid"
				state:"error",
				errors:["Something wrong"]
				state:"valid",
			},
			cashbox:{
				state:"error",
				errors:["Something wrong"],
				state:"valid",
				lock: {
					opened:false,
					unlocked:false
				},
			},
			loader:{
				//state:"valid"
				state:"error",
				errors:["Something wrong"]
				state:"valid",
			},
			usb:{
				state:"error",
				errors:["Something wrong"]
				state:"valid",
			},
			power:{
				//state:"valid",
				state:"error",
				errors:["Something wrong"]
				state:"valid",
			},
			bundler:{
				state:"error",
				errors:["Something wrong"]
				state:"valid",
			}
		};*/
		let organizedData = this.extractDataGroupedByType();
		
		const mainModuleCleanup = this.extractSensorIdList( StateLevel.CLEANUP , "mainModule" , organizedData );
		const mainModuleMaintenance = this.extractSensorIdList( StateLevel.MAINTENANCE , "mainModule" , organizedData );
		
		const spineCleanup = this.extractSensorIdList( StateLevel.CLEANUP , "spine" , organizedData );
		const spineMaintenance = this.extractSensorIdList( StateLevel.MAINTENANCE , "spine" , organizedData );
		
		const recyclersCleanup = this.extractSensorIdList( StateLevel.CLEANUP , "recycler" , organizedData );
		const recyclersMaintenance = this.extractSensorIdList( StateLevel.MAINTENANCE , "recycler" , organizedData );

		let owlState = `${( this.sensorHasAnomaly( ["196871" , "196872"] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`;
		if( this.variant !== null && this.variant !== undefined && this.variant.trim().endsWith("Advance") ) {
			owlState = `${( this.sensorHasAnomaly( ["196871" , "196875"] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`;
		}

		const deviceData = {
			mainModule:{
				//state:`${( ( mainModuleCleanup.length + mainModuleMaintenance.length ) > 0 ) ? "error" : "valid"}`, //full coloration
				state:`${( this.sensorHasAnomaly( ["196870"] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`,
				cover:{state:`${( this.sensorHasAnomaly( ["196869" , "196864"] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`},
				owl:{state:owlState},
				acceptor:{
					state:`${( this.sensorHasAnomaly( ["196873" , "196874" , "198144" , "196865" , "196866" , "196867" , "196868"] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`,
					input:{
						state:`${( this.sensorHasAnomaly( ["196873" , "196874"] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`
					},
					output:{
						state:`${( this.sensorHasAnomaly( [] , mainModuleCleanup , mainModuleMaintenance ) ) ? "error" : "valid"}`,
					}
				}
			},
			spine:{
				state:`${( this.sensorHasAnomaly( ["198402" , "198403" , "198404"] , spineCleanup , spineMaintenance ) ) ? "error" : "valid"}`,
			},
			recycler1:{
				state:`${( this.sensorHasAnomaly( ["198450" , "198452"] , recyclersCleanup , recyclersMaintenance ) ) ? "error" : "valid"}`,
			},
			recycler2:{
				state:`${( this.sensorHasAnomaly( ["198451" , "198453"] , recyclersCleanup , recyclersMaintenance ) ) ? "error" : "valid"}`,
			},
			recycler3:{
				state:`${( this.sensorHasAnomaly( ["198454"] , recyclersCleanup , recyclersMaintenance ) ) ? "error" : "valid"}`,
			},
			recycler4:{
				state:`${( this.sensorHasAnomaly( ["198455"] , recyclersCleanup , recyclersMaintenance ) ) ? "error" : "valid"}`,
			},
			cashbox:{
				state:"valid",
			},
			loader:{
				state:"valid",
			},
		};
		
		return deviceData;
	}
	
	getCLSState() {

		const deviceData = {
			device:{
				state:"valid",
			},
			modules:{
				hopper1:{
					state:"valid",
				},
				hopper2:{
					state:"valid",
				},
				hopper3:{
					state:"valid",
				},
				hopper4:{
					state:"valid",
				},
				hopper5:{
					state:"valid",
				},
				hopper6:{
					state:"valid",
				},
				hopper7:{
					state:"valid",
				},
				hopper8:{
					state:"valid",
				},
				centrifuge:{
					state:"valid",
				},
				conveyor:{
					state:"valid",
				},
				acceptor:{
					state:"valid",
				}
			}
		};
		
		return deviceData;
	}
	
	getDeviceState() {
		switch( this.model ) {
			case "BNR": 
				return this.getBNRState();
			case "CLS": return this.getCLSState();
			case "SCR": return {};
			case "MDB_CoinRecycler": return {};
			case "Scanner": return {};
			default : return {};
		}
	}
	
	getTemplate() {
		return this.template;
	}
	
	getRegisteredShapes( face , optionalParams ) {
		switch( this.model ) {
			case "BNR": 
				if( face === "BACK" ) {
					if( this.variant.includes("BNR3") ) {
						return {
							mainModule:[ [75 , 45] , [75 , 200] , [240 , 265] , [250 , 310] , [275 , 320] , [425 , 275] , [475 , 75] , [225 , 20] ],
							spine:[ [275 , 325] , [275 , 675] , [410 , 610] , [425 , 275] ]
						};
					} else {
						return {
							mainModule:[ [62 , 186] , [62 , 173] , [51 , 162] , [51 , 109] , [55 , 81] , [65 , 55] , [199 , 35] , [315 , 89] , [315 , 116] , [330 , 123] , [330 , 254] , [193 , 275] , [173 , 266] , [172 , 240] ],
							spine:[ [191 , 275] , [330 , 254] , [330 , 657] , [191 , 680] ]
						};
					}
				} else {
					if( this.variant.includes("BNR3") ) {
						return {
							mainModule:[ [68 , 75] , [68 , 225] , [250 , 300] , [430 , 260] , [410 , 100] , [205 , 40] ],
							cashbox:[ [250 , 300] , [250 , 462] , [388 , 427] , [400 , 275] ],
							"r1-2":[ [250 , 475] , [250 , 574] , [388 , 520] ,[388 , 430] ],
							"r3-4":[ [250 , 578] , [250 , 611] , [388 , 612] ,[388 , 530] ],
						}
					} else {
						return {
							mainModule:[ [161 , 236] , [161 , 107] , [177 , 117] , [193 , 102] , [180 , 89] , [276 , 37] , [462 , 147] , [460 , 273] , [361 , 295] , [340 , 291] , [326 , 299] , [326 , 305] , [194 , 230] , [183 , 230] , [183 , 248] ],
							cashbox:[ [250 , 445] , [250 , 360] , [351 , 356] , [351 , 320] , [371 , 309] , [400 , 323] , [412 , 323] , [459 , 295] , [458 , 382] ],
							loader:[ [250 , 526] , [250 , 452] , [458 , 391] ,[458 , 464] ],
							"r3-4":[ [250 , 606] , [250 , 532] , [458 , 471] ,[458 , 544] ],
							"r5-6":[ [250 , 685] , [250 , 611] , [458 , 550] ,[458 , 623] ],
						}
					}
				}
			case "CLS": 
				if( face === "BACK" ) {
					return this.buildHopperShapes( optionalParams , {
						hopper1:[ [69 , 458] , [69 , 582] , [250 , 582] , [250 , 458] ],
						hopper2:[ [69 , 350] , [69 , 455] , [250 , 455] , [250 , 350] ],
						hopper3:[ [69 , 250] , [69 , 349] , [250 , 349] , [250 , 250] ],
						hopper4:[ [69 , 141] , [69 , 248] , [250 , 248] , [250 , 141] ],
						hopper5:[ [251 , 141] , [251 , 248] , [431 , 248] , [431 , 141] ],
						hopper6:[ [251 , 250] , [251 , 349] , [431 , 349] , [431 , 250] ],
						hopper7:[ [251 , 350] , [251 , 455] , [431 , 455] , [431 , 350] ],
						hopper8:[ [251 , 458] , [251 , 582] , [431 , 582] , [431 , 458] ],
					} ); 
				} else {
					return this.buildHopperShapes( optionalParams , {
						hopper1:[ [29 , 566] , [29 , 612] , [98 , 612] , [98 , 566] ],
						hopper2:[ [29 , 523] , [29 , 562] , [98 , 562] , [98 , 523] ],
						hopper3:[ [29 , 482] , [29 , 519] , [98 , 519] , [98 , 482] ],
						hopper4:[ [29 , 440] , [29 , 477] , [98 , 477] , [98 , 440] ],
						hopper5:[ [102 , 440] , [102 , 477] , [171 , 477] , [171 , 440] ],
						hopper6:[ [102 , 482] , [102 , 519] , [171 , 519] , [171 , 482] ],
						hopper7:[ [102 , 523] , [102 , 562] , [171 , 562] , [171 , 523] ],
						hopper8:[ [102 , 566] , [102 , 612] , [171 , 612] , [171 , 566] ],
					} ); 
				}
			case "SCR": return {
				acceptor:[ [ 210 , 92 ] , [ 272 , 46 ] , [ 277 , 35 ] , [ 314 , 25 ] , [ 393 , 25 ] , [ 395 , 37 ] , [ 403 , 38 ] , [ 399 , 43 ] , [ 399 , 48 ] , [ 405 , 51 ] , [ 405 , 60 ] , [ 411 , 60 ] , [ 411 , 83 ] , [ 404 , 107 ] , [ 404 , 128 ] , [ 410 , 128 ] , [ 412 , 132 ] , [ 412 , 143 ] , [ 411 , 146 ] , [ 340 , 146 ] , [ 325 , 151 ] , [ 317 , 151 ] , [ 317 , 142 ] , [ 313 , 136 ] , [ 304 , 136 ] , [ 304 , 142 ] , [ 307 , 142 ] , [ 306 , 147 ] , [ 302 , 145 ] , [ 302 , 132 ] , [ 294 , 133 ] , [ 294 , 127 ] , [ 302 , 126 ] , [ 302 , 120 ] , [ 297 , 110 ] , [ 285 , 97 ] , [ 262 , 97 ] , [ 237 , 115 ] , [ 217 , 115 ] ],
				//vault:[ [ 273 , 156 ] , [ 293 , 157 ] , [ 312 , 157 ] , [ 316 , 152 ] , [ 326 , 151 ] , [ 342 , 146 ] , [ 370 , 146 ] , [ 367 , 149 ] , [ 367 , 164 ] , [ 364 , 167 ] , [ 359 , 167 ] , [ 356 , 175 ] , [ 282 , 181 ] , [ 271 , 181 ] , [ 271 , 160 ] ],
				recyclers:[ [ 242 , 181 ] , [ 269 , 181 ] , [ 270 , 182 ] , [ 329 , 179 ] , [ 335 , 176 ] , [ 357 , 176 ] , [ 361 , 179 ] , [ 362 , 223 ] , [ 368 , 228 ] , [ 367 , 398 ] , [ 359 , 408 ] , [ 279 , 429 ] , [ 276 , 434 ] , [ 272 , 434 ] , [ 272 , 404 ] , [ 218 , 352 ] , [ 218 , 232 ] , [ 214 , 231 ] , [ 214 , 218 ] , [ 218 , 217 ] , [ 218 , 207 ] ],
				cashbox:[ [ 280 , 431 ] , [ 352 , 411 ] , [ 352 , 461 ] , [ 341 , 467 ] , [ 342 , 555 ] , [ 354 , 565 ] , [ 354 , 632 ] , [ 274 , 664 ] , [ 274 , 609 ] , [ 247 , 585 ] , [ 242 , 566 ] , [ 243 , 490 ] , [ 253 , 482 ] , [ 264 , 486 ] , [ 272 , 480 ] , [ 272 , 437 ] ],
				//chassis:[ [ 122 , 96 ] , [ 190 , 98 ] , [ 198 , 93 ] , [ 214 , 117 ] , [ 238 , 117 ] , [ 268 , 98 ] , [ 283 , 97 ] , [ 300 , 119 ] , [ 300 , 126 ] , [ 292 , 128 ] , [ 292 , 133 ] , [ 301 , 133 ] , [ 301 , 145 ] , [ 305 , 147 ] , [ 308 , 143 ] , [ 304 , 141 ] , [ 304 , 136 ] , [ 312 , 136 ] , [ 316 , 147 ] , [ 309 , 156 ] , [ 273 , 156 ] , [ 269 , 163 ] , [ 269 , 179 ] , [ 239 , 180 ] , [ 216 , 208 ] , [ 217 , 354 ] , [ 263 , 403 ] , [ 268 , 403 ] , [ 271 , 412 ] , [ 268 , 412 ] , [ 268 , 420 ] , [ 272 , 420 ] , [ 271 , 481 ] , [ 268 , 483 ] , [ 259 , 483 ] , [ 251 , 479 ] , [ 238 , 490 ] , [ 238 , 576 ] , [ 252 , 598 ] , [ 270 , 605 ] , [ 274 , 613 ] , [ 273 , 681 ] , [ 126 , 618 ] ]
			};
			case "MDB_CoinRecycler": return {};
			case "Scanner": return {};
			default : return {};
		}
		
		/*return {
			//usb:[ [10 , 16] , [18 , 11] , [23 , 15] , [31 , 14] , [50 , 32] , [179 , 107] , [174 , 112] , [44 , 37] , [15 , 26] ],
			//power:[ [86 , 52] , [87 , 36] , [95 , 39] , [104 , 50] , [188 , 102] , [179 , 105] ],
			//"acceptor_input":[ [391 , 231] , [413 , 233] , [475 , 191] , [483 , 200] , [482 , 208] , [448 , 233] , [406 , 248] ],
			//"acceptor_output":[ [0 , 0] , [0 , 0] , [0 , 0] , [0 , 0] , [0 , 0] , [0 , 0] , [0 , 0] , [0 , 0] , [0 , 0] ],
			//acceptor:[ [366 , 303] , [367 , 207] , [456 , 158] , [475 , 181] , [488 , 275] , [412 , 319] , [397 , 319] ],
			mainModule:[ [161 , 236] , [161 , 107] , [177 , 117] , [193 , 102] , [180 , 89] , [276 , 37] , [462 , 147] , [460 , 273] , [361 , 295] , [340 , 291] , [326 , 299] , [326 , 305] , [194 , 230] , [183 , 230] , [183 , 248] ],
			//bundler:[ [162 , 243] , [184 , 254] , [190 , 236] ,[338 , 321] , [342 , 684] , [162 , 581] ],
			cashbox:[ [347 , 445] , [347 , 360] , [351 , 356] , [351 , 320] , [371 , 309] , [400 , 323] , [412 , 323] , [459 , 295] , [458 , 382] ],
			loader:[ [347 , 526] , [347 , 452] , [458 , 391] ,[458 , 464] ],
			"r3-4":[ [347 , 606] , [347 , 532] , [458 , 471] ,[458 , 544] ],
			"r5-6":[ [347 , 685] , [347 , 611] , [458 , 550] ,[458 , 623] ],
		}*/
	}

	buildHopperShapes( optionalParams , individualShapes ) {
		let returned = {};
		if( optionalParams !== null && optionalParams !== undefined ) {
			optionalParams.map( ( candidate ) => {
				if( candidate !== null && candidate !== undefined ) {
					if( candidate.includes( "-" ) ) {
						returned[`hopper${candidate}`] = this.extractMergedShape( candidate.split( "-" ) , individualShapes );
					} else {
						if( individualShapes.hasOwnProperty( `hopper${candidate}` ) ){
							returned[`hopper${candidate}`] = individualShapes[`hopper${candidate}`];
						}
					}
				}
				return true;
			} );
		}
		return returned;
	}
	
	extractMergedShape( idList , individualShapes ) {
		let merged = null;
		if( idList ) {
			idList.map( ( toMerge ) => {
				let candidate = individualShapes[`hopper${toMerge}`];
				if( merged === null) {
					merged = candidate;
				} else {
					if( merged.length === candidate.length ) {
						for( let i=0 ; i<merged.length ; i++ ) {
							if( i===0 ) {
								merged[i] = [ Math.min( merged[i][0] , candidate[i][0] ) , Math.min( merged[i][1] , candidate[i][1] ) ];
							} else if ( i===1 ) {
								merged[i] = [ Math.min( merged[i][0] , candidate[i][0] ) , Math.max( merged[i][1] , candidate[i][1] ) ];
							} else if ( i===2 ) {
								merged[i] = [ Math.max( merged[i][0] , candidate[i][0] ) , Math.max( merged[i][1] , candidate[i][1] ) ];
							} else if ( i===3 ) {
								merged[i] = [ Math.max( merged[i][0] , candidate[i][0] ) , Math.min( merged[i][1] , candidate[i][1] ) ];
							}
						}
					}
				}
				return true;
			} );
		}
		return merged;
	}
}

export default DeviceDataAnalyzer;
