import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'aws-amplify/utils';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import NoDataPlaceholder from '../../Components/NoDataPlaceholder/NoDataPlaceholder';
import PeriodChooser from '../../Components/PeriodChooser/PeriodChooser';
import SortableTable from '../../Components/SortableTable/SortableTable';
import GraphObsContainer from '../../Components/GraphObsContainer/GraphObsContainer';
import AmountFormatter from '../../../Utils/AmountFormatter';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import PayStationFinder from "../../../Utils/PayStationFinder";
import {operationType, operationTypeTranslations} from "../../../Models/Report/Enums.js";
import SyncIndicator from '../../Components/SyncIndicator/SyncIndicator';
import {listOperations} from "../../../graphql/queries";
import './Operation.css';
import {UserRoles} from "../../../Models/Roles";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

I18n.putVocabularies( operationTypeTranslations );

let loadingTerminals = [];
let loadedTerminals = [];
let operationInstance = null;
let hasAtLeastOneLoader = false;
class Operation extends React.Component {
	
	constructor( props ) {
		super( props );
		operationInstance = this;

		let value = 0;
		if ( this.props.optionalNavigationData ) {
			if ( this.props.optionalNavigationData.startsWith("LATEST-") ) {
				//Init from the Last operation highlight direct click from list element
				value = this.getIndexFromI18nLabel( this.props.optionalNavigationData.replace("LATEST-" , "") );
			}
		}
		const periods = props.periodSynchronizer.getPeriods();
		this.state = {
			value: value,
			period1:periods[0],
			operations:[],
			inventories:props.inventories,
			isLoading: false,
		}

		if( props.periodSynchronizer.isUserChoice() ) {
			const analyzer = new ConfigAnalyzer( this.props.payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			loadingTerminals = terminals;
			loadedTerminals = [];
			terminals.forEach( terminal => {
				terminal.operations = [];
				this.loadOperations( terminal , `6_${terminal.informations.bms}` , {
					start: new Date( this.state.period1.start ).toISOString(),
					end: new Date( this.state.period1.end ).toISOString()
				} , null );
			} );
		}


		this.chartContainerRef = null;
	}

	componentDidMount() {
		this.extractOperations( operationInstance.props.payStations )
			.then( operations => {
				operationInstance.setState({
					isLoading: false,
					value:operationInstance.state.value,
					period1:operationInstance.state.period1,
					operations: operations
				});
			} );
	}

	extractOperations( payStations ) {
		return new Promise( resolve => {
			const anonymized = {};
			const analyzer = new ConfigAnalyzer( payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			const operations = [];
			terminals.forEach( terminal => {
				if( terminal.operations.length > 0 ) {
					terminal.operations.forEach( operation => {
						if( operationInstance.props.currentUser.role === UserRoles.SALE_ROLE ) {
							if( ! anonymized[operation.cashier] ) {
								anonymized[operation.cashier] = `Caissier ${Object.keys( anonymized ).length + 1}`;
							}
							operation.cashier = anonymized[operation.cashier];
						}
						operations.push( operation );
						if( terminals.indexOf( terminal ) === terminals.length - 1 &&
							terminal.operations.indexOf( operation ) === terminal.operations.length - 1 ) {
							resolve( operations );
						}
					} );
				} else {
					if( terminals.indexOf( terminal ) === terminals.length - 1 ) {
						resolve( operations );
					}
				}
			} );
		} );
	}

	onNewIncomingOperation() {
		operationInstance.extractOperations( operationInstance.props.payStations ).then( operations => {
			this.setState( {operations: operations} );
		} );
	}
	
	generateSeries( data ) {
		let series = [];
		let distinctTimeline = this.extractDistinctTimelineList();
		distinctTimeline.sort();
		let boxes = this.extractDistinctBoxList();
		boxes.map( (boxLabel) => {
			series.push({ name: boxLabel.typeLabel +" "+ boxLabel.optDataLabel, data:this.extractSeries(boxLabel , distinctTimeline) });
			return true;
		} )

		return series;
	}
	
	generateTimelineLabels( data ) {
		let labels = [];
		let distinctTimeline = this.extractDistinctTimelineDateList(); //this.extractDistinctTimelineList();
		distinctTimeline.sort();
		distinctTimeline.map( (date) => {
			labels.push( new Date(date * 1000).toLocaleDateString() + " " + new Date(date * 1000).toLocaleTimeString() );
			return true;
		} );
				
		return labels;
	}
	
	extractDistinctBoxList() {
		let returned = [];
		
		this.allData.map( (operationExtractedData) => {
			let extractedData = operationExtractedData.data;
			extractedData.map( (lineData) => {
				let label = lineData.label;
				let shouldAdd = true;
				returned.map( (labelToCheck) => {
					if( label.typeLabel === labelToCheck.typeLabel &&
						label.optDataLabel === labelToCheck.optDataLabel &&
						label.boxId === labelToCheck.boxId ) {
							shouldAdd = false;
						}
					return true;
				} );
				if( shouldAdd ) {
					returned.push(label);
				}
				return true;
			})
			return true;
		} )
		
		return returned;
	}

	extractDistinctTimelineDateList() {
		let returned = [];

		this.extractDistinctTimelineList().map( (timeline) => {
			returned.push( timeline.date );
			return true;
		} )

		return returned;
	}

	extractDistinctTimelineList() {
		let returned = [];
		
		this.allData.map( (operationExtractedData) => {
			let extractedData = operationExtractedData.data;
			extractedData.map( (lineData) => {
				let date = lineData.date;
				let shouldAdd = true;
				returned.map( (dateToCheck) => {
					if( date === dateToCheck.date  ) {
							shouldAdd = false;
						}
					return true;
				} );
				if( shouldAdd ) {
					if( this.props.onLoadingRequired !== null && this.props.onLoadingRequired !== undefined ) {
						this.props.onLoadingRequired( "inventory" , new Date( date * 1000 ) );
					}
					returned.push({date:date , operation:lineData });
				}
				return true;
			})
			return true;
		} )
		
		return returned;
	}

	isValidCandidateInventory( inventoryData ) {
		if(inventoryData.inventory !== null && inventoryData.inventory !== undefined && inventoryData.inventory.data !== null && inventoryData.inventory.data !== undefined && inventoryData.inventory.data.locations.length > 0 ) {
			return true;
		} 
		return false;
	}

	extractSeries( labelBox , distinctTimeline ) {
		let series = [];
		distinctTimeline.sort( ( objA , objB ) => {
			return objA.date - objB.date;
		} );

		/*distinctTimeline.map( (timelinePoint) => {
			let box = Object.assign( {} , this.getBoxById( labelBox.boxId , this.props.boxList ) );
			let boxInventoryManager = new BoxInventoryManager( box , this.state.inventories );
			let inventory = boxInventoryManager.extractCurrent( timelinePoint.date * 1000 );//this.extractInventory( timelinePoint , labelBox );		
			if( inventory === null ) {
				//load in data inventory at this specific timeline
				if( this.props.onLoadingRequired !== null && this.props.onLoadingRequired !== undefined ) {
					this.props.onLoadingRequired( "inventory" , new Date( timelinePoint.date * 1000 ) );
				}
			}
			
			//console.log("inventory" , inventory);
			if ( inventory !== null && inventory !== undefined && inventory.hasOwnProperty("total") ) {
				series.push( this.filterInventoryTotalByType( inventory ) );
			}
			return true;
		} );*/

		return series;
	}

	setValue( newValue ) {
		operationInstance.setState({
			value:newValue
		});
	}
	
	handleChange(event, newValue) {
        operationInstance.setValue(newValue);
    };
	
	isBackOfficeOperation( operation ) {
		if( ! hasAtLeastOneLoader ) {
			return ( operation === operationType.REFILL_MANUAL ||
					 operation === operationType.PICKUP_MANUAL ||
					 operation === operationType.PICKUP_BY_CASHBOX );
		}
		return ( operation === operationType.REFILL_MANUAL ||
				 operation === operationType.REFILL_BY_CASHBOX ||
				 operation === operationType.PICKUP_MANUAL ||
				 operation === operationType.PICKUP_BY_CASHBOX );
	}

	getIndexFromI18nLabel( i18nLabel ) {
		switch ( parseInt(i18nLabel) ) {
			case operationType.REFILL_BY_CASHBOX: return 1;
			case operationType.PICKUP_MANUAL: return 2;
			case operationType.PICKUP_BY_CASHBOX: return 3;
			default: return 0;
		}
	}

	filterByTypeForPeriod( operationTypeValue ) {
		const filtered = [];
		if( this.state.operations ) {
			this.state.operations.forEach( operation => {
				if( operation && operation.at ) {
					if( this.state.period1.end >= new Date( operation.at ).getTime() &&
						new Date( operation.at ).getTime() >= this.state.period1.start &&
						operation.type === operationTypeValue ) {
						filtered.push( operation );
					}
				}
			} );
		}
		return filtered;
	}

	calculateTotalForOperation( details ) {
		let total = 0;
		if( details ) {
			details.forEach( detail => {
				total += detail.qty * detail.value * detail.rate ;
			} );
		}
		return total;
	}

	extractDataForPeriods( operationTypeValue ) {

		let returnedRow = [];
		let currencies = [];
		const analyzer = new ConfigAnalyzer( operationInstance.props.payStations );
		const finder = new PayStationFinder( operationInstance.props.payStations );
		const terminals = analyzer.getDistinctActiveTerminalList();
		const filtered = this.filterByTypeForPeriod( operationTypeValue );

		if( currencies.length < 1 ) {
			currencies.push("EUR");
		}

		const defaultErrorMessage = I18n.get( "Invalid fund type detection" );

		const added = [];
		filtered.sort( (a , b) => new Date( b.at ).getTime() - new Date( a.at ).getTime() );
		filtered.forEach( candidate => {
			const terminal = finder.getTerminalByCBMS( candidate.cbms , terminals );
			const box = finder.getBoxForDevice( candidate.data.details[0].deviceCBMS );
			if( box && terminal && ! added.includes( candidate.uuid ) && candidate.currency === this.props.currencySelected ) {
				added.push( candidate.uuid );
				returnedRow.push({
					label: {
						typeLabel: box.informations.attributes.name,
						optDataLabel: finder.buildTerminalExtraData( terminal ),
						boxId: box.id
					},
					date: new Date( candidate.at ).getTime()/1000,
					terminal:( terminal.informations.attributes.terminal ) ? terminal.informations.attributes.terminal : terminal.informations.attributes.name,
					cashier: candidate.cashier,
					amount: this.calculateTotalForOperation( candidate.data.details ),
					details: candidate.data.details,
					currency: candidate.currency,
					boxId: box.id,
					manual:finder.isManual( candidate.data.details ),
					operationType:candidate.type,
					hasError: finder.hasError( candidate.data.details ),
					errorMessages:  finder.extractErrorMessageList( candidate.data.details , defaultErrorMessage )
				});
			} else {
				console.log("Invalid mapping detected");
				console.log("box" , box);
				console.log("terminal" , terminal );
			}
		} );

		if( filtered.length > 0 && this.props.onCurrenciesUpdate ) {
			this.props.onCurrenciesUpdate( currencies );
		}

		return returnedRow;
	} 
	
	getHeaderProperties( data ) {
		if( data.length < 1 ) {
			return [];
		}
		if( this.props.isDesktop ) {
			return [
				{ id: "label", 		numeric: false, disablePadding: false, label: I18n.get("Box"), 		money: false, absolute: false, 	className:"header type", 		width: 0 	, align: 'center' },
				{ id: 'error', 		numeric: false, disablePadding: false, label: "", 							money: false, absolute: false, 	className:"header error" , 		width: 25	, align: 'center' },
				{ id: 'manual', 	numeric: false, disablePadding: false, label: "", 							money: false, absolute: false, 	className:"header manual" , 	width: 25	, align: 'center' },
				{ id: 'date', 		numeric: false, disablePadding: false, label: I18n.get("Date"), 		money: false, absolute: false, 	className:"header date ope", 	width: 80	, align: 'center' },
				{ id: 'terminal', 	numeric: false, disablePadding: false, label: I18n.get("Terminal"), 	money: false, absolute: false, 	className:"header terminal", 	width: 210	, align: 'center' },
				{ id: 'cashier', 	numeric: false, disablePadding: false, label: I18n.get("Cashier"), 	money: false, absolute: false, 	className:"header cashier", 	width: 80	, align: 'center' },
				{ id: 'amount', 	numeric: true, 	disablePadding: false, label: I18n.get("Amount"), 		money: true,  absolute: true, 	className:"header sale-number", width: 100	, align: 'center' },
			];
		}
		return [
			{ id: "label", 		numeric: false, disablePadding: false, label: I18n.get("Box"), 		money: false, absolute: false, 	className:"header type" , 		width: 0 	, 	align: 'center' },
			{ id: 'date', 		numeric: false, disablePadding: false, label: I18n.get("Date"), 		money: false, absolute: false, 	className:"header sale", 		width: 80	, 	align: 'center' },
			{ id: 'amount', 	numeric: true, 	disablePadding: false, label: I18n.get("Amount"), 		money: true,  absolute: true, 	className:"header sale-number", width: 100  , 	align: 'center' },
		];
	}

	loadOperations( terminal , cbms , period , nextToken ) {
		if( operationInstance.props.API ) {
			operationInstance.props.API
				.graphql({ query: listOperations, variables: { input: { cbms: cbms , count: 10 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listOperations ) {
						returned.data.listOperations.operations.forEach( operation => {
							terminal.operations.push( operation );
						} );
						if( returned.data.listOperations.nextToken ) {
							operationInstance.loadOperations( terminal , cbms , period , returned.data.listOperations.nextToken );
						} else {
							loadedTerminals.push( terminal );
							if( loadedTerminals.length === loadingTerminals.length ) {
								operationInstance.extractOperations( operationInstance.props.payStations )
									.then( operations => {
										operationInstance.setState({
											isLoading: false,
											value:operationInstance.state.value,
											period1:operationInstance.state.period1,
											operations: operations
										});
									} );

							}
 						}
					}
				})
				.catch((error) => {

				})
		}
	}

	handlePeriodSelection( period , identifier ) {
		if( operationInstance.props.periodSynchronizer ) {
			operationInstance.props.periodSynchronizer.updatePeriod( period , identifier );
		}
		if( operationInstance.props.payStations ) {
			operationInstance.setState({
				isLoading: true,
				value:operationInstance.state.value,
				period1:period,
			});
			const analyzer = new ConfigAnalyzer( operationInstance.props.payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			loadingTerminals = terminals;
			loadedTerminals = [];
			terminals.forEach( terminal => {
				terminal.operations = [];
				operationInstance.loadOperations( terminal , `6_${terminal.informations.bms}` , {
					start: new Date( period.start ).toISOString(),
					end: new Date( period.end ).toISOString()
				} , null );
			} );
		}
	}
	
	handlePageChanged( page ) {
		if( operationInstance.chartContainerRef ) {
			operationInstance.chartContainerRef.setPage( page );
		}
	}
	
	handleRowsPerPageChanged( rowsPerPage ) {
		if( operationInstance.chartContainerRef ) {
			operationInstance.chartContainerRef.setRowsPerPage( rowsPerPage );
		}
	}
	
	handleSorting( sortedData , tag ) {
		if( operationInstance.chartContainerRef ) {
			operationInstance.allData.map( ( lineData ) => {
				if( lineData.operationType === tag ) {
					lineData.data = sortedData;
				}
				return true;
			} )
			operationInstance.chartContainerRef.setData( operationInstance.generateSeries( sortedData ) , 
															operationInstance.generateTimelineLabels( sortedData ) ,
															operationInstance.allData );
		}
	}
	
	prepareDataForPdf( data ) {
		const map = {};
		const finder = new PayStationFinder( operationInstance.props.payStations );

		data.forEach( operation => {
			let formattedOperation = Object.assign( {}, operation );
			if( ! map.hasOwnProperty( operation.boxId ) ) {
				const box = Object.assign( {} , finder.getBoxForDevice( operation.details[0].deviceCBMS ) );
				map[operation.boxId] = {
					list:[],
					subTotal: { currency: operation.currency , amount:0 , name:operation.label.typeLabel },
					node: box,
					extraData: operation.label.optDataLabel,
				}
			}
			formattedOperation.total = operation.amount;
			formattedOperation["operation_date"] = new Date( operation.date * 1000).toISOString();
			map[operation.boxId].list.push( formattedOperation );
			map[operation.boxId].subTotal.amount += operation.amount;
		} );

		return map;
	}
	
	renderTab( enumValue ) {
		const key = "backoffice-tab-nav-item-" + enumValue;
		return ( 
			<Tab 	key={key} 
					id={`scrollable-force-tab-${enumValue}`}
					label={I18n.get( operationType.getName( enumValue ))}
					aria-controls={`scrollable-auto-tabpanel-${enumValue}`}/> );
	}
	
	renderTabPanel( enumValue , counterIndex , data ) {
		let shown = false;
		let cssTable = "comparison-table";
		if( data.length < 1 ) {
			shown = true;
			cssTable += " hidden";
		}
		
		if( operationInstance.props.pdfDataDelegate && counterIndex === operationInstance.state.value ) {
			delete operationInstance.props.pdfDataDelegate.map;
			delete operationInstance.props.pdfDataDelegate.lines;
			operationInstance.props.pdfDataDelegate.columnDefinition = this.getHeaderProperties(data);
			operationInstance.props.pdfDataDelegate.map = this.prepareDataForPdf( data );
			operationInstance.props.pdfDataDelegate.subTitle = I18n.get( operationType.getName( enumValue ));
			operationInstance.props.pdfDataDelegate.startLocalizedDate = new Date(operationInstance.state.period1.start).toLocaleString().replace("," , "").substring( 0 , new Date(operationInstance.state.period1.start).toLocaleString().replace("," , "").length - 3 );
			operationInstance.props.pdfDataDelegate.endLocalizedDate = new Date(operationInstance.state.period1.end).toLocaleString().replace("," , "").substring( 0 , new Date(operationInstance.state.period1.end).toLocaleString().replace("," , "").length - 3 );
			let handlerExtraData = {
				locale: operationInstance.props.locale.substring( 0 , operationInstance.props.locale.indexOf("-") ),
				offset:0
			}
			operationInstance.props.pdfDataDelegate.data = {
				boxExtraData: I18n.get( "From" ) + ' ' + operationInstance.props.pdfDataDelegate.startLocalizedDate + ' ' +
					I18n.get( "To" ).toLowerCase() + ' ' + operationInstance.props.pdfDataDelegate.endLocalizedDate
			}
			operationInstance.props.pdfDataDelegate.handler = JSON.stringify( handlerExtraData );
		}
		
		const key = "backoffice-tab-panel-item-" + enumValue;
		return ( 
			<TabPanel 	key={key} 
						value={operationInstance.state.value} 
						index={counterIndex}
						id={`scrollable-auto-tabpanel-${enumValue}`}
						aria-labelledby={`scrollable-auto-tab-${enumValue}`} >
				<div className="tab-panel-content">
					<Grid container spacing={1} >
						<Grid id="exportable-table" className="groupedBy operation-padding2" item xs={12} md={12} lg={12}>
							<Paper className="card-box">		
								<SortableTable header={this.getHeaderProperties( data )} 
											   rows={data} 
											   className={cssTable} 
											   locale={this.props.locale}
											   isDarkStyle={this.props.isDarkStyle}
											   isDesktop={this.props.isDesktop}
											   forceAbsoluteInDetails={true}
											   onPageChanged={this.handlePageChanged}
											   onRowsPerPageChanged={this.handleRowsPerPageChanged}
											   onSortingData={this.handleSorting}
											   orderBy="id"
											   groupBy="label"
											   defaultRowsPerPage={25}
											   tag={enumValue}/>
								<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
							</Paper>
						</Grid>
					</Grid>
				</div>
			</TabPanel>
		);
		
	}
	
	renderReminderPanel( enumValue , counterIndex , data ) {
		
		const key = "backoffice-tab-reminder-item-" + enumValue;

		let total = 0;
		let currency = "EUR";
		data.forEach( line  => {
			if( line ) {
				if( line.operationType === enumValue ) {
					total += line.amount;
					currency = line.currency;
				}
			}
		});
		const formatter = new AmountFormatter(this.props.locale,  currency );
		if( ! hasAtLeastOneLoader ) {
			return (
				<Grid key={key} item xs={12} md={4} lg={4}>
					<div className={`tab-panel-reminder item-${counterIndex} ${(counterIndex === operationInstance.state.value) ? 'active' : ''}`}
						 onClick={event => {
							 operationInstance.handleChange( event , counterIndex );
						 }}>
						<Paper className="card-box reminder  operation-reminder">
							<div className="type-reminder">
								{I18n.get("Total")} {I18n.get( operationType.getName( enumValue )).toLowerCase()}
							</div>
							<div className="total-reminder-content">
								{formatter.format(Math.abs(total))}
							</div>
						</Paper>
					</div>
				</Grid>
			);
		}
		return ( 
			<Grid key={key} item xs={12} md={3} lg={3}>
				<div className={`tab-panel-reminder item-${counterIndex} ${(counterIndex === operationInstance.state.value) ? 'active' : ''}`}
					 onClick={event => {
						 operationInstance.handleChange( event , counterIndex );
					 }}>
					<Paper className="card-box reminder  operation-reminder">	
							<div className="type-reminder">
								{I18n.get("Total")} {I18n.get( operationType.getName( enumValue )).toLowerCase()} 
							</div>
							<div className="total-reminder-content">							
								{formatter.format(Math.abs(total))}
							</div>							
					</Paper>
				</div>
			</Grid>
		);
		
	}
	
	render() {
		let tabs = [];
		let panels = [];
		let reminders = [];
		let counterIndex = 0;
		this.allData = [];
		Object.keys(operationType).map( (key) => {
			if( operationInstance.isBackOfficeOperation( operationType[ key ] ) ) {
				let data = operationInstance.extractDataForPeriods( operationType[ key ] );
				tabs.push( operationInstance.renderTab( operationType[ key ] ) );
				panels.push( operationInstance.renderTabPanel( operationType[ key ] , counterIndex , data ) );
				reminders.push( operationInstance.renderReminderPanel( operationType[ key ] , counterIndex , data ) );
				counterIndex++;
				this.allData.push( {currency:"EUR" , operationType: operationType[ key ] ,data:data } );
			}
			return true;
		} )
		
		if( this.props.isDesktop ) {
			return (
				<React.Fragment>
					<div className="z-over fullscreen mb-2 read-only-breadscrum">
						{/*<NavigationSelector selectedNode={this.props.selectedNode}
											isDarkStyle={this.props.isDarkStyle}
											onSelectionChanged={this.props.onNodeChanged}
											readOnly={true}
											user={this.props.user} />*/}
					</div>	
					<div id="exportable" className="z-over fullscreen">
						<div className="period">
							<SyncIndicator loading={this.state.isLoading}
							               className={"display-4 spining space-top"}/>
							<PeriodChooser period={this.state.period1}
										   onPeriodChanged={this.handlePeriodSelection} 
										   callbackIdentifier="period1" 
										   isDarkStyle={this.props.isDarkStyle} />
						</div>
						<Tabs 	className="nav-tabs-primary operations hidden"
								key="backoffice-tab-nav"
								value={operationInstance.state.value}
								variant="fullWidth"
								onChange={operationInstance.handleChange}>
							{tabs.map( item => item )}	
						</Tabs>
						<Grid container spacing={1} >
							<Grid id="exportable-reminder" className="operation-margin operation-padding" container spacing={1}>
								{reminders.map( item => item )}
							</Grid>
							{/*<Grid id="exportable-table" className="groupedBy" item xs={12} md={5} lg={5}>
								{panels.map( item => item )}
							</Grid>*/}
							{/*<Grid id="exportable-table" className="groupedBy" item xs={12} md={12} lg={12}>
								{panels.map( item => item )}
							</Grid>*/}
							{panels.map( item => item )}
							{/*<Grid item xs={12} md={7} lg={7}>
								<Paper id="exportable-graphic" className="card-box mt-3">	
									<GraphObsContainer 	options={{}} 
														series={this.generateSeries( this.allData )} 
														type="line"
														name="comparison"
														defaultRowsPerPage={25}
														details={this.allData}
														isDesktop={this.props.isDesktop}
														defaultFilter="operationAmountPeriod"
														shared={true}
														locale={this.props.locale}
														selected={operationInstance.state.value}
														ref={(component) => operationInstance.chartContainerRef = component} />
								</Paper>
							</Grid>*/}
						</Grid>
					</div>
				</React.Fragment>
			);
		}
		
		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.selectedNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>	
				<div id="exportable" className="z-over fullscreen">
					<div className="period">
						<SyncIndicator loading={this.state.isLoading}
						               className={"display-4 spining space-top"}/>
						<PeriodChooser period={this.state.period1}
									   onPeriodChanged={this.handlePeriodSelection} 
									   callbackIdentifier="period1" 
									   isDarkStyle={this.props.isDarkStyle} />
					</div>
					<Tabs 	className="nav-tabs-primary operations hidden"
							key="backoffice-tab-nav"
							value={operationInstance.state.value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={operationInstance.handleChange}>
						{tabs.map( item => item )}	
					</Tabs>
					<Grid container spacing={1} >
						<Grid id="exportable-reminder" container spacing={1}>
							{reminders.map( item => item )}
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<div id="exportable-table" className="groupedBy">
								{panels.map( item => item )}
							</div>
						</Grid>
						{/*<Grid item xs={12} md={6} lg={6}>
							<Paper id="exportable-graphic" className="card-box mt-3">	
								<GraphObsContainer 	options={{}} 
													series={this.generateSeries( this.allData )} 
													type="line"
													name="comparison"
													defaultRowsPerPage={25}
													details={this.allData}
													isDesktop={this.props.isDesktop}
													defaultFilter="operationAmountPeriod"
													shared={true}
													locale={this.props.locale}
													selected={operationInstance.state.value}
													ref={(component) => operationInstance.chartContainerRef = component} />
							</Paper>
						</Grid>*/}
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}

export default Operation;
