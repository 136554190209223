import React from 'react';
import { I18n } from 'aws-amplify/utils';
import Grid from '@mui/material/Grid';
import CountUp from 'react-countup';
import Chart from 'react-apexcharts';
import LevelManager from '../../../Utils/LevelManager';
import UUID from "../../../Utils/UUID";
import SyncIndicator from '../SyncIndicator/SyncIndicator';
import './TotalInventory.css';

const factoryUUID = new UUID();

export default function TotalInventory( props ) {
	const id = factoryUUID.generate();
	
	const calculatePrctOnRange = ( range , value ) => {
		let deltaRange = range.max - range.min;
		let deltaValue = value - range.min;
		let unit = deltaRange / 100;
		return 100 - parseInt( deltaValue / unit );
	}
	
	const calculateRangeForGraph = ( series ) => {
		let min = -1;
		let max = -1;
		
		if( series.length === 1 ) {
			series[0].data.map( serie => {
				if ( min >= 0 ) {
					min = Math.min( min , serie );
				} else {
					min = serie;
				}
				
				if ( max >= 0 ) {
					max = Math.max( max , serie ) ;
				} else {
					max = serie;
				}
				
				return true;
			} );
		}
		
		return {min:min,max:max};
	}

	const isAlertActive = () => {
		return props.alertParams.level && props.alertParams.level > 0;
	}

	const generateColorStops = ( range ) => {
		
		const opacity = 1;
		const normalColor = "#1bc943";
		const warningColor = "#f4772e";
		const alertColor = "#f83245";
		const gradientScale = 2.5;
		let colorStops = [];
		var currentOffset = 0;
		
		if( range.min >= props.alertParams.level && isAlertActive() ) {
			colorStops.push( {offset: 0 , color: alertColor, opacity: opacity } );
			colorStops.push( {offset: 100 , color: alertColor, opacity: opacity } );
		} else if ( range.max < ( props.alertParams.level - props.alertParams.warningAmount ) && isAlertActive() ) {
			colorStops.push( {offset: 0 , color: normalColor, opacity: opacity } );
			colorStops.push( {offset: 100 , color: normalColor, opacity: opacity } );
		} else {
			if( range.max >= props.alertParams.level && isAlertActive() ) {
				colorStops.push( {offset: 0 , color: alertColor, opacity: opacity } );
				currentOffset = calculatePrctOnRange( range , props.alertParams.level );
			}
			
			if( range.max >= ( props.alertParams.level - props.alertParams.warningAmount ) && isAlertActive() ) {
				if( currentOffset > 0 ) {
					//from Alert to warning
					colorStops.push( {offset: currentOffset , color: warningColor, opacity: opacity } );
				} else {
					//graph starts in warning
					colorStops.push( {offset: 0 , color: warningColor, opacity: opacity } );
				}
				
			}
			
			//Add warning bottom
			colorStops.push( {offset: calculatePrctOnRange( range , ( props.alertParams.level - ( props.alertParams.warningAmount * gradientScale ) ) ) , color: normalColor, opacity: opacity } );
			colorStops.push( {offset: 100 , color: normalColor, opacity: opacity } );
		}
		
		return colorStops;
	}
	
	const generateTimeline = ( nbTicks ) => {
		let timelines = [];
		let timestamps = extractTimestampsForTicks( nbTicks );
		if ( timestamps !== null && timestamps !== undefined ) {
			timestamps.map( ( oneTime ) => {
				timelines.push( new Date( oneTime ).toLocaleString() );
				return true;
			} );
		}
		return timelines;
	}

	const extractTimestampsForTicks = ( nbTicks ) => {
		const oneHour = 1000*60*60;
		const oneDay = oneHour * 24;
		let now = new Date();
		if ( props.date !== undefined && props.date !== null ) {
			now = new Date( props.date );
		}
		now.setHours( now.getHours() , 0 , 0 );
		//To extract from config
		let openingTime = new Date();
		openingTime.setHours(6, 30, 0);

		let closingTime = new Date(now.getTime() - oneDay);
		closingTime.setHours(20, 30, 0);
		let current = now;
		let timelines = [];
		for( let i=0 ; i < nbTicks ; i++ ) {
			//withdrawal 1 Hour from now
			current = new Date(current.getTime() - oneHour);
			now = current;
			if( current.getTime() < openingTime.getTime() ) {
				current = closingTime;
				openingTime.setDate( current.getDay() );

			}
			timelines.push( current.getTime() );
		}
		timelines.reverse();
		return timelines;
	}

	const chartOptions = ( series ) => {
		let manager = new LevelManager( props.rows );
		let range = calculateRangeForGraph( series );
		let colorStops = generateColorStops( range );
		//let colorsMarker = generateColorMarkerArray( series );
		//let customMarkers = generateCustomMarkerArray( colorsMarker , series[0].length );
		
		let categories = ['H-11', 'H-10', 'H-9', 'H-8', 'H-7', 'H-6', 'H-5', 'H-4', 'H-3', 'H-2', 'H-1'];
		let calculatedDate = generateTimeline( categories.length );

		const colors = ["black"];
		return {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        sparkline: {
                            enabled: true
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0,
						colors:colors,
                    },
                    colors: ['#1bc943'],
                    stroke: {
                        color: '#1bc943',
                        width: 3,
						curve: 'smooth'
                    },
                    legend: {
                        show: false
                    },
					fill: {
						type: 'gradient',
						gradient: {
							shade: 'dark',
						/*gradientToColors: [ '#f83245'],*/
							shadeIntensity: 1,
							type: 'vertical',
							opacityFrom: 1,
							opacityTo: 1,
							colorStops: colorStops
						},
					},
                    xaxis: {
                        crosshairs: {
                            width: 0
                        },
						labels: {
							formatter: function (value) {
								if( value != null ) {
									return calculatedDate[categories.indexOf(value)]; //label time
								}
								
								return ""; //label time
							}
						},
						categories: categories,
                    },
                    yaxis: {
                        min: 0,
						//max: maxValue,
						labels: {
							formatter: function (value) {
								return new Intl.NumberFormat(props.locale, { style: 'currency', currency: manager.getMainCurrency() }).format( value ); //label money
							}
						},
                    },
					tooltip: {
						marker: {
							fillColors:colors
						}
					},
                };
	}
	
	const chartData = () => {
		return [
			{
				name: I18n.get( "Total" ) ,
				data: getTotalForLastInventories(10 )
			}
		];
	}
	
	const getTotalForLastInventories = ( nbInventoryIncluded ) => {
		let data = [];
		let timestamps = extractTimestampsForTicks( nbInventoryIncluded );

		if ( timestamps !== null && timestamps !== undefined ) {
			timestamps.map( ( oneTime ) => {
				let inventoryAt = extractInventoryAt( oneTime );
				if ( inventoryAt !== undefined && inventoryAt.hasOwnProperty("total") ) {
					data.push( inventoryAt.total );
				}

				return true;
			} );
		}
		return data;
	}

	const extractInventoryAt = ( timestamp ) => {
		let bestCandidate = { date:0 };
		if ( props.inventories !== null && props.inventories !== undefined ) {
			props.inventories.map( ( candidateInventory ) => {
				if ( candidateInventory !== null && candidateInventory !== undefined && candidateInventory.hasOwnProperty("date") ) {
					if ( candidateInventory.date < ( timestamp + 1 ) ) {
						if ( candidateInventory.date >  bestCandidate.date ) {
							if( candidateInventory.inventory.data !== null && candidateInventory.inventory.data !== undefined ) {
								if ( candidateInventory.inventory.data.locations.length > 0 ) {
									bestCandidate = candidateInventory;
								}
							}
						}
					}
				}
				return true;
			} );
		}

		return bestCandidate;
	}

	const getCurrencyFormat = ( currencyCode ) => {
		if( currencyCode === 'XPF' ) {
			return {
				locale: props.locale,
				separator: ' ',
				decimalSeparator: '',
				nbDecimal: 0,
				prefix: '',
				suffix: ' ' + currencyCode,
				currencyCode: currencyCode
			};
		}

		const numberWithGroupAndDecimalSeparator = 1000.1;
		let formatter = new Intl.NumberFormat(props.locale, { style: 'currency', currency: currencyCode });
		let formatted = formatter.format( numberWithGroupAndDecimalSeparator );
		let formattedParts = formatter.formatToParts(numberWithGroupAndDecimalSeparator);
		let parts = formatted.replace( formattedParts.find(part => part.type === "group")?.value , "" ).split( formattedParts.find(part => part.type === "decimal")?.value );
		let matchesDecimal = parts[1]?.match(/(\d+)/);
		let matchesPrefix = parts[0]?.match(/(\d+)/);
		let matchesSuffix = parts[1]?.match(/(\d+)/);
		let decimalLength = 0;
		let prefix = "";
		let suffix = "";
		if (matchesDecimal) { 
			decimalLength = matchesDecimal[0].length;
		} 
		
		if (matchesPrefix) { 
			prefix = parts[0].replace( matchesPrefix[0] , "" );
		} 
		
		if (matchesSuffix) { 
			suffix = parts[1].replace( matchesSuffix[0] , "" );
		} 

		return {
			locale:props.locale,
			separator:formattedParts.find(part => part.type === "group")?.value,
			decimalSeparator:formattedParts.find(part => part.type === "decimal")?.value,
			nbDecimal:decimalLength,
			prefix:prefix,
			suffix:suffix,
			currencyCode:currencyCode
		};
	}
	
	const renderAmountCountUp = ( amount , currencyCode ) => {
		let currencyFormat = getCurrencyFormat( currencyCode );
		let cssText = "display-4 font-weight-bold ";
		return (
			<CountUp
				start={0}
				end={amount}
				duration={2}
				deplay={0}
				separator={currencyFormat.separator}
				decimals={currencyFormat.nbDecimal}
				decimal={currencyFormat.decimalSeparator}
				prefix={currencyFormat.prefix}
				suffix={currencyFormat.suffix}
				className={cssText}
			/>
		);
	}

	if( props.inventories && props.inventories.length > 0 ) {
		//Box is not empty
		const manager = new LevelManager( props.inventories );
		const data = chartData();
		return (
			<Grid item sd={12} md={12} lg={12} key={id} className="cm-total-inventory">
				<div className="px-3 pt-3 d-flex justify-content-center">
					<div className="block-total-data">
						<div className="text-uppercase font-weight-bold font-size-sm mb-2">
							{I18n.get( "Total" )}
						</div>
						<div className="font-weight-bold">
							<div className="font-size-xl mb-0">
								{renderAmountCountUp( manager.getTotalAmount() , manager.getMainCurrency() )}
							</div>
						</div>
					</div>
					{/*<div className="d-flex align-items-center pt-2 px-3 cm-graph">
							<Chart options={chartOptions( data )} series={data} type="line" height={90} />
						</div>*/}
					<div className="d-flex align-items-center">
						<SyncIndicator loading={props.isLoadingInventory}/>
					</div>
				</div>
			</Grid>
		);
	}
	return null;
}
