import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import Card from '@mui/material/Card';
import ReactCardFlip from 'react-card-flip';
import Chart from 'react-apexcharts';
import { CircularProgressbar } from 'react-circular-progressbar';
import LevelManager from '../../../Utils/LevelManager';
import UUID from "../../../Utils/UUID";
import '../InventoryCard/InventoryCard.css';
import {fundType} from "../../../Models/Report/Enums";

const factoryUUID = new UUID();

export default function InventoryExtendedCard( props ) {
	const [flipped, setFlipped] = React.useState(false);
	const id = factoryUUID.generate();
	
	const handleClick = () => {
		setFlipped( !flipped );
	}

	const formatAmount = ( amount , currencyCode ) => {
		if( currencyCode === 'XPF' ) {
			return new Intl.NumberFormat( props.locale ).format( amount ) + ' ' + currencyCode;
		} else {
			return new Intl.NumberFormat( props.locale, { style: 'currency', currency: currencyCode }).format( amount );
		}
	}
	
	const generateColorsForSeries = series => {
		let colors = [];
		series.forEach( item => colors.push( retrieveColor( item.trim() ) ) );
		return colors;
	}
	
	const retrieveColor = ( value ) => {
		switch( value ) {
			case I18n.get(fundType.getName(fundType.RESTAURANT_VOUCHER)): return "#10c5db";
			case I18n.get(fundType.getName(fundType.GIFT)): return "rgb(0, 143, 251)";
			case I18n.get(fundType.getName(fundType.VACANCY_CHECK)): return "rgb(0, 227, 150)";
			case I18n.get(fundType.getName(fundType.MISCELLANEOUS_CASHBOXED)): return "rgb(254, 176, 25)";
			default:
				try {
					let decimalValue = parseFloat( value.replace(',' , '.' ) );
					switch( decimalValue ) {
						case 1: return "#10c5db";
						case 2:
						case 5: return "rgb(0, 143, 251)";
						case 0.10:
						case 10: return "rgb(0, 227, 150)";
						case 0.20:
						case 20: return "rgb(254, 176, 25)";
						case 0.50:
						case 50: return "rgb(255, 69, 96)";
						case 0.01:
						case 100: return "rgb(119, 93, 208)";
						case 0.02:
						case 200: return "#d622a1";
						case 0.05:
						case 500: return "#008080";
						default:
							switch( value ) {
								case "EUR": return "rgb(0, 143, 251)";
								case "USD": return "rgb(0, 227, 150)";
								case "GBP": return "rgb(254, 176, 25)";
								default: return "red";
							}
					}
				} catch ( exception ) {
					console.log("Exception" , exception)
				}
		}

		/**/
	}
	
	const renderCurrenciesGraph = ( currencies ) => {
		const list = ( props.inventory.data.cashBox ) ? props.inventory.data.cashBox : props.inventory.data.loader
		const manager = new LevelManager( list );
		const totalSeries = manager.getTotalSeriesPerCurrency();
		const detailsSeries = manager.getHtmlDetailSeriesPerCurrency( props.locale );
		const series=manager.getCountPerCurrency();
		return (
			renderDonutGraph( series , currencies , currencies, totalSeries , detailsSeries )
		);
	}
	
	const renderDonutGraph = ( series , labels , currencies , totalSeries , detailsSeries ) => {
		let colors = [];
		series.map((item) => colors.push("#FFFFFF") );
		let position = "top";
		let width;
		let graphCss = "graph-cm"
		if(series.length > 3) {
			//position = "right";
			width="300";
			graphCss += " large";
		} else {
			//position = "top";
			width="250";
		}
		
		//const generatedColors = generateColorsForSeries( labels );
		//const fillColors = ['#17c840', '#f83245', '#10c5db', '#e6a00a', '#c63232', '#5f31c7', '#272fc9', '#d622a1', '#94c731', '#9C27B0'];
		//const fillColors = ['rgb(0, 143, 251)' , 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', 'rgb(255, 69, 96)', 'rgb(119, 93, 208)', '#d622a1', '#008080', '#10c5db', '#f83245', '#17c840'];
		const fillColors = generateColorsForSeries( labels );
		const options={ 
			plotOptions: {
				pie: {
				  donut: {
					size: '45%'
				  }
				}
			},
			fill: {
			  colors: fillColors
			},
			labels: labels ,
			dataLabels: {
				style: {
					colors: colors,
				},
			},
			legend: {
				position: position,
				markers: {
					  fillColors: fillColors,
				},
			},
			tooltip: {
				custom: function({series, seriesIndex, dataPointIndex, w}) {

					return 	'<div class="apexcharts-tooltip-series-group" style="order: '+( seriesIndex+1 )+'; display: flex; background-color: '+fillColors[seriesIndex]+';">' +
						'	<span class="apexcharts-tooltip-marker" style="background-color: '+fillColors[seriesIndex]+'; display: none;"></span>' +
						'	<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
						'		<div class="apexcharts-tooltip-y-group font-size-lg">' +
						'			<span class="apexcharts-tooltip-text-label">'+ I18n.get( "Total" ) +' : </span>' +
						'			<span class="apexcharts-tooltip-text-value">' + formatAmount( totalSeries[seriesIndex] , currencies[seriesIndex] ) + '</span>' +
						'			<span class="cm-line-separator"></span>' +
						'		</div>' +
						'		<div class="apexcharts-tooltip-z-group">' +
						'			<span class="apexcharts-tooltip-text-z-label"> '+ I18n.get( "Quantity" ) +' : </span>' +
						'			<span class="apexcharts-tooltip-text-z-value">' + series[seriesIndex] + '</span>' +
						'			<div class="cm-detail-content">' +
						'				<span class="apexcharts-tooltip-text-z-label"> '+ I18n.get( "Details" ) +' : </span>' +
						'				' + detailsSeries[seriesIndex] +
						'			</div>' +
						'		</div>' +
						'	</div>' +
						'</div>' +
						'';
				}
			}
		};
		
		return (
			<div className="d-flex justify-content-center" key={factoryUUID.generate()}>
                <Chart options={options} series={series} type="donut" width={width} className={graphCss}/>
            </div>
		);
	}
	
	const renderForCurrency = ( currency ) => {
		let itemList = [];
		const list = ( props.inventory.data?.cashBox ) ? props.inventory.data.cashBox : props.inventory.data.loader;
		list.forEach( row => {
			if ( row && row.hasOwnProperty("details") ) {
				row.details.forEach( line => {
					if ( line ) {
						if ( line.hasOwnProperty("quantity") && line.quantity > 0 ) {
							//exclude useless lines broadcast by server
							if( line.currency === currency && line.value > 0 ) {
								itemList.push( renderLine( line ) );
							}
						}
					}
				} );
			}
		} );
		return (
			<div className="currency-block-list" key={factoryUUID.generate()}>
				<div className="currency-header"> {currency} </div>
				<div className="currency-content"> 
					{ itemList.map( (item) => item) }
				</div>
			</div>
		);
	}

	const renderForVips = () => {
		let manager = new LevelManager( props.inventory.data.cashBox );
		const totalSeries = manager.getTotalSeriesPerType();
		const detailsSeries = manager.getHtmlDetailSeriesPerType( props.locale );
		const series=manager.getQtyPerTypeList();
		const labels=manager.getTypeFormattedList( props.locale );
		let currencies = [];

		props.inventory.data.cashBox.forEach(( row ) => {
			if( row !== null && row !== undefined && row.hasOwnProperty("details") ) {
				row.details.map( (line) => {
					if ( line !== null && line !== undefined && line.quantity > 0 && line.value > 0 ) {
						currencies.push( line.currency );
					}
					return true;
				} );
			}
			return true;
		} );

		return (
			renderDonutGraph( series , labels , currencies , totalSeries , detailsSeries )
		);
	}
	
	const renderForSameCurrency = () => {
		const currencies = [];
		try {
			const list = ( props.inventory.data?.cashBox ) ? props.inventory.data.cashBox : props.inventory.data.loader;
			const manager = new LevelManager( list );
			const totalSeries = manager.getTotalSeriesPerValues();
			const detailsSeries = manager.getHtmlDetailSeriesPerValue( props.locale );
			const series=manager.getQtyPerValueList();
			const labels=manager.getValueFormattedList( props.locale );

			list.content.forEach( line => {
				if ( line && line.qty > 0 && line.value > 0 ) {
					currencies.push( line.currency );
				}
			} );

			return (
				renderDonutGraph( series , labels , currencies , totalSeries , detailsSeries )
			);
		} catch ( error ) {
			console.error( `Unable to render for same currency` , error );
			return null;
		}
	}
	
	const renderLine = ( row ) => {
		let manager = new LevelManager( ( props.inventory.data.cashBox ? props.inventory.data.cashBox : props.inventory.data.loader ) );
		let total = manager.getAmountForRow( row );
		let formatter = new Intl.NumberFormat(props.locale, { style: 'currency', currency: row.currency });
		return (
			<div className="line-cm" key={factoryUUID.generate()}>
				<div className="left opacity-6">
					<div className="qty"> {row.quantity} </div>
					<div className="multiplicator"> x </div>
					<div className="amount">{formatAmount( row.value , row.currency )}</div>
				</div>
				<div className="right opacity-4">
					<div className="total">( {formatAmount( total , row.currency )} )</div>
				</div>
			</div>
		);
	}

	const renderQuantityExtraData = ( isVips , isMultiCurrency , boxType = 'cashBox' ) => {
		let manager = new LevelManager( props.inventory.data[boxType] );
		if ( isVips ) {
			let items = [];
			Object.keys(fundType).forEach( (key) => {
				let quantity = 0;
				props.inventory.data[boxType].forEach( row => {
					if ( row && row.hasOwnProperty("details") ) {
						row.details.forEach( line => {
							if ( line.fund_type === fundType[key] && line.quantity > 0 && line.value > 0 ) {
								quantity += line.quantity;
							}
						} );
					}
				} );
				if ( quantity > 0 ) {
					items.push( renderExtraQuantity( renderVipsIcon( fundType[key] ) , quantity ) );
				}
			} );
			return (
				<div className={"mt-2"}>
					{items.map( item => item )}
				</div>
			);
		} else if ( isMultiCurrency ) {
			let items = [];
			const series = manager.getCountPerCurrency();
			let currencies = manager.getCurrencyList();
			currencies.forEach( currency => {
				items.push( renderExtraQuantity( currency , series[currencies.indexOf(currency)]) );
				return true;
			} )
			return (
				<div className={"mt-2"}>
					{items.map( item => item )}
				</div>
			);
		}
		return null;
	}

	const renderVipsIcon = (type) => {
		return (  <FontAwesomeIcon icon={fundType.getIcon(type)} className="display-5" /> );
	}

	const renderExtraQuantity = ( label , Quantity ) => {
		return (
			<div key={factoryUUID.generate()}>{label} : {Quantity}</div>
		);
	}

	if( props.inventory && props.inventory.data &&
		( ( props.inventory.data.cashBox && props.inventory.data.cashBox.content ) ||
			( props.inventory.data.loader && props.inventory.data.loader.content ) )
	) {
		if ( props.inventory.data.cashBox?.content?.length > 0 || props.inventory.data.loader?.content?.length > 0 ) {
			const boxInventory = ( props.inventory.data?.cashBox ) ? props.inventory.data.cashBox : props.inventory.data.loader;
			const boxType = ( props.inventory.data?.cashBox ) ? 'cashBox' : 'loader';
			const manager = new LevelManager( boxInventory );
			let progress = manager.getTotalFillingLevelInPct();
			if( progress > 100 ) {
				progress = 100;
			}
			let dynamicCss = manager.getOverallColorCss();

			let cssCard = "card-box card-box-border-bottom border-" + dynamicCss ;//+ " shadow-" + dynamicCss + "-sm";
			let cssIcon = "font-size-lg mr-2 text-" + dynamicCss + " font-weight-bold";
			let cssProgress = "m-3 circular-progress-cm circular-progress-sm circular-progress-" + dynamicCss;
			let totalQuantity = manager.getTotalQuantity();
			let itemList = [];
			let itemListMobile = [];
			let itemListBack = [];
			let itemListMobileBack = [];
			let currencies = manager.getCurrencyList();

			if ( props.type === fundType.MISCELLANEOUS_CASHBOXED ) {
				//VIPS
				if( currencies.length === 1 ) {
					itemList.push( renderForVips() );
					itemListMobile.push( renderForVips() );
					itemListBack.push( renderForVips() );
					itemListMobileBack.push( renderForVips() );
				} else {
					throw new Error("Invalid use of fundType.MISCELLANEOUS_CASHBOXED : doesn't support Multi currency ");
				}
			} else {
				if( currencies.length === 1 ) {
					itemList.push( renderForSameCurrency() );
					itemListMobile.push( renderForSameCurrency() );
					itemListBack.push( renderForSameCurrency() );
					itemListMobileBack.push( renderForSameCurrency() );
				} else {
					itemList.push( renderCurrenciesGraph( currencies ) );
					itemListMobile.push( renderCurrenciesGraph( currencies ) );
					currencies.map( (currency) => {
						itemListBack.push( renderForCurrency( currency ) );
						itemListMobileBack.push( renderForCurrency( currency ) );
						return true;
					} );
				}
			}

			return (
				<ReactCardFlip id={id} isFlipped={flipped} flipDirection="vertical">
					<Card className={cssCard}>
						<div className="d-flex justify-content-start align-items-center">
							<div className="p-3 cm-card-data extended">
								<div className="text-black-50 text-uppercase pb-2 font-size-sm">
									
								</div>
								<h3 className="font-weight-bold display-4 mb-0 text-black">
									<span className={cssIcon}>
										<FontAwesomeIcon icon={props.iconType} className="display-4" />
									</span>
									{totalQuantity}
									<div className="opacity-6 pl-1 text-black-20 font-size-lg">
									
									</div>
								</h3>
								<div className="">
									{renderQuantityExtraData( props.type === fundType.MISCELLANEOUS_CASHBOXED , currencies.length > 1 , boxType )}
								</div>
							</div>
							<div className="cm-extended-details front">
							{itemList.map( (item) => item )}
							</div>
							<CircularProgressbar value={progress} text={progress + '%'} strokeWidth={8} className={cssProgress} />
							<div className="font-size-md opacity-5 flip-card-cm" onClick={handleClick} style={{display:"none"}}>
								<FontAwesomeIcon icon={['fas', 'chevron-right']} />
							</div>
						</div>
						<div className="cm-extended-details-sm front">
							{itemListMobile.map( (item) => item )}
						</div>
					</Card>
					<Card className={cssCard}>
						<div className="d-flex justify-content-start align-items-center">
							<div className="p-3 cm-card-data extended">
								<div className="text-black-50 text-uppercase pb-2 font-size-sm">
									
								</div>
								<h3 className="font-weight-bold display-4 mb-0 text-black">
									<span className={cssIcon}>
										<FontAwesomeIcon icon={props.iconType} className="display-4"/>
									</span>
									{totalQuantity}
									<div className="opacity-6 pl-1 text-black-20 font-size-lg" >
										
									</div>
								</h3>
							</div>
							<div className="cm-extended-details back">
							{itemListBack.map( (item) => item )}
							</div>
							<CircularProgressbar value={progress} text={progress + '%'} strokeWidth={8} className={cssProgress} />
							<div className="font-size-md opacity-5 flip-card-cm" onClick={handleClick}>
								<FontAwesomeIcon icon={['fas', 'chevron-right']} />
							</div>
						</div>
						<div className="cm-extended-details-sm back">
						{itemListMobileBack.map( (item) => item )}
						</div>
					</Card>
				</ReactCardFlip>	
			);
		}
	}
	return null;
}
