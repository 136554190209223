import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";

const factoryUUID = new UUID();
const optionSeparator = "[opt-separator]";
export default function SelectHidingMenu(props ) {
	const [value, setValue] = React.useState( ( props.defaultValue ) ? props.defaultValue : "" );
	//const [open, setOpen] = React.useState(false);
	
	const handleClose = () => {
		//setOpen(false);
	};

	const handleOpen = (evt) => {
		if( props.handleClick  ) {
			props.handleClick(evt);
		}
	};
	
	const handleChange = ( evt ) => {
		setValue( evt.target.value );
		if( props.onValueChanged ) {
			props.onValueChanged( props.id , evt.target.value );
		}
	};
	
	const renderLocation = ( locationNode ) => {
		return (
			<div key={factoryUUID.generate()} className="path-element">{locationNode}</div>
		);
	}
	
	const renderMenuItem = ( optionValue ) => {
		
		if( optionValue && optionValue !== "" ) {
			let optValue =  optionValue;
			let optLabel =  I18n.get(optionValue);
			let locations = [];
			if( props.isHashMapOption ) {
				let parts = optionValue.split( optionSeparator );
				optValue =  parts[0];
				optLabel =  parts[1];
				if( parts.length > 2 ) {
					let isFirst = true;
					let locationNodes = parts[2].split(">");
					locationNodes.forEach( locationNode => {
						if( isFirst ) {
							isFirst = false;
						} else {
							locations.push( renderLocation(" > ") );
						}
						locations.push( renderLocation(locationNode) );
					} );

					
				}
			}
						
			if( props.showIcon && props.icons && props.icons.hasOwnProperty(optionValue) ) {
				return (
					<MenuItem  key={`menu-${props.id}-item-${optionValue}-${factoryUUID.generate()}`} 
							   value={optionValue}>
						<FontAwesomeIcon icon={props.icons[optValue]} style={{fontSize:"1.25em"}}/>
						<div className="label-opt ml-2">{optLabel}</div>
						<div className="label-location">
							{locations.map( item => item )}
						</div>
					</MenuItem>
				);
			}
			return (
				<MenuItem  key={`menu-${props.id}-item-${optionValue}-${factoryUUID.generate()}`} 
						   value={optValue}>
					<div className="label-opt">{optLabel}</div>
					<div className="label-location">
						{locations.map( item => item )}
					</div>
				</MenuItem>
			);
		}
		return (
			<MenuItem key={`menu-${props.id}-item-none`} value="">
				<em>{I18n.get("None")}</em>
			</MenuItem>
		);
	}
	
	const options = [];
	if( props.options ) {
		if( props.allowEmpty ) {
			options.push( renderMenuItem ( null ) );
		}
		if( props.isHashMapOption ) {
			props.options.forEach( (opt) => {
				options.push( renderMenuItem ( opt ) );
			} );
		} else {
			if( props.options.includes( value ) || ( props.allowEmpty && value === "" ) ) {
				props.options.forEach( (opt) => {
					options.push( renderMenuItem ( opt ) );
				} );
			}
		}
	}
	
	let css = "select-task-properties";
	let cssSelect = "combo-task-properties";
	let cssLabel = "label-task-properties";
	if( props.isDarkStyle ) {
		css += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}
	
	React.useEffect(() => {
		if( props.id === "node" ) {
			setValue(props.defaultValue);
		}
	}, [ props.defaultValue , props.id ]);

	return (
		<FormControl variant="outlined" className={css}>
			<InputLabel className={cssLabel} 
						id={`input-combo-${props.id}`}>
				{props.label}
			</InputLabel>
			<Select classes={{selectMenu:cssSelect}}
					labelId={`combo-${props.id}-label`}
					id={`combo-${props.id}-select`}
					value={value}
					onChange={handleChange}
					open={false}
					onClose={handleClose}
					onOpen={handleOpen}
					disabled={(props.disabled)}
					MenuProps={{
						classes:{paper:cssSelect}
					}}
					label={props.label} >
				{options.map( item => item )}
			</Select>
		</FormControl>
	);
}
