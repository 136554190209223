import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { I18n } from 'aws-amplify/utils';
import './ElapsedTimeIndicator.css';

export default function ElapsedTimeIndicator( props ) {
	
	const formatTime = ( timeInSecond ) => {
		let returned = "";
		const minute = 60;
		const hour = minute * 60;
		const day = hour * 24;
		const year = 365 * day; //excluding year with 366 days not relevant in this case, so we keep it simple
		const nbLevelDisplayed = ( props.nbLevelDisplayed && props.nbLevelDisplayed > 0 ) ? props.nbLevelDisplayed : 5;
		let currentLevel = 0;
		
		let currentBunchOfSecond = timeInSecond;
		const candidateYears = currentBunchOfSecond/year;
		if( candidateYears >= 1 && currentLevel < nbLevelDisplayed ) {
			returned += `${parseInt(candidateYears)} ${I18n.get("Year")} `;
			currentBunchOfSecond = currentBunchOfSecond - ( parseInt(candidateYears) * year );
			currentLevel++;
		}
		
		const candidateDays = currentBunchOfSecond/day;
		if( candidateDays >= 1 && currentLevel < nbLevelDisplayed  ) {
			returned += `${parseInt(candidateDays)} ${I18n.get("Day")} `;
			currentBunchOfSecond = currentBunchOfSecond - ( parseInt(candidateDays) * day );
			currentLevel++;
		}
		
		const candidateHours = currentBunchOfSecond/hour;
		if( candidateHours >= 1 && currentLevel < nbLevelDisplayed  ) {
			returned += `${parseInt(candidateHours)} ${I18n.get("Hour")} `;
			currentBunchOfSecond = currentBunchOfSecond - ( parseInt(candidateHours) * hour );
			currentLevel++;
		}
		
		const candidateMinutes = currentBunchOfSecond/minute;
		if( candidateMinutes >= 1 && currentLevel < nbLevelDisplayed  ) {
			returned += `${parseInt(candidateMinutes)} ${I18n.get("Minute")} `;
			currentBunchOfSecond = currentBunchOfSecond - ( parseInt(candidateMinutes) * minute );
			currentLevel++;
		}
		
		if( currentBunchOfSecond >= 0 && currentLevel < nbLevelDisplayed  ) {
			returned += `${currentBunchOfSecond} ${I18n.get("Seconde")}`;
			currentLevel++;
		}
		
		return returned;
	}
	
	return( 
		<React.Fragment>
			<div className={`elapsed-time-indicator-root`}>
				<div className={`icon`}>
					<FontAwesomeIcon icon={['fas', 'stopwatch']} className="display-4 mx-2" />
				</div>
				<div className={`details`}>
					<div className={`label`}>
						{`${( props.label ) ? props.label : ""}`}
					</div>
					<div className={`value`}>
						{`${( props.value ) ? formatTime( props.value ) : ""}`}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
