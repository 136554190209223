import UserDevices from './UserDevices';
import ScheduledTask from './ScheduledTask';
import Filter from "./Filters";

const themeStyle = {
	LIGHT:false,
	DARK:true
};

class UserProperties {
	
	constructor( preferences , observer ) {
		this.properties = this.getDefault();
		this.observer = observer;
		if( preferences && preferences.hasOwnProperty("themeStyle") ) {
			this.properties = preferences;
		}
		this.userDevices = new UserDevices ( preferences?.userDevices , preferences?.currentDevice);
		this.currentDevice = preferences?.currentDevice;
		this.scheduledTasks = [];
		if( preferences?.hasOwnProperty("scheduledTasks") ) {
			preferences?.scheduledTasks.forEach( scheduledTask => {
				this.scheduledTasks.push( ScheduledTask.fromJson( scheduledTask , observer ) );
			} );
		}
		this.filters = [];
		if( preferences?.hasOwnProperty("filters") ) {
			preferences?.filters.forEach( filter => {
				this.filters.push( Filter.fromJson( filter , observer ) );
			} );
		}
	}
	
	getDefault() {
		return {
			themeStyle: themeStyle.DARK,
			name:null,
			phone:null,
			emailLocale:null,
			favoriteGraphType:"area",
			favoriteSaleComparator:"J-1",
			alarmInventoryLevel:null,
			warningInventoryLevel:null,
			alarmInventoryFrequency:null,
			alarmInventoryTemporalUnit:"HOUR",
			sendInventoryAlertByMail:false,
			sendInventoryAlertByPhone:false,
			hideLockedDevices:true,
			hideEmptyPaths:true,
			currentFilter: '-1'
		}
	}
	
	updateProperty( name , value ) {
	    value = ( name === "warningInventoryLevel" ) ? this.properties.alarmInventoryLevel - value : value;
		this.properties[name] = value;
		this.observer?.onPropertyChanged( name , value );
	}
	
	updateScheduledTasks( tasks ) {
		this.scheduledTasks = tasks;
		this.observer?.onPropertyChanged( "scheduledTasks" , this.scheduledTasks );
	}

	updateFilters( filters ) {
		this.filters = filters;
		this.observer?.onPropertyChanged( "filters" , this.filters );
	}
	
	getCurrentDevice() {
		return this.currentDevice;
	}
	
	getDevices() {
		return this.userDevices.devices;
	}
	
	removeCurrentDevice() {
		this.userDevices.removeCurrent();
		this.observer?.onPropertyChanged( "userDevices" , this.userDevices );
	}
	
	toJson() {
		return {
			themeStyle: this.properties.themeStyle,
			name:this.properties.name,
			phone:this.properties.phone,
			emailLocale:this.properties.emailLocale,
			favoriteGraphType:this.properties.favoriteGraphType,
			favoriteSaleComparator:this.properties.favoriteSaleComparator,
			contactName:this.properties.contactName,
			userDevices:this.userDevices.devices,
			currentDevice:this.currentDevice,
			scheduledTasks:this.scheduledTasks,
			filters: this.filters,
			alarmInventoryLevel:this.properties.alarmInventoryLevel,
			warningInventoryLevel:`${this.properties.warningInventoryLevel}`,
			alarmInventoryFrequency:this.properties.alarmInventoryFrequency,
			alarmInventoryTemporalUnit:this.properties.alarmInventoryTemporalUnit,
			sendInventoryAlertByMail:this.properties.sendInventoryAlertByMail,
			sendInventoryAlertByPhone:this.properties.sendInventoryAlertByPhone,
			hideLockedDevices:this.properties.hideLockedDevices,
			hideEmptyPaths:this.properties.hideEmptyPaths,
			currentFilter:this.properties.currentFilter
		}
	}
	
}

export default UserProperties;
