export const fundLocation = {
	UNKNOWN:0,
	RECYCLER:1,
	CASHBOX1:2,
	CASHBOX2:3,
	LOADER:4,
	fromName( name ) {
		switch(name) {
			case "RECYCLER":return fundLocation.RECYCLER;
			case "CASHBOX1":return fundLocation.CASHBOX1;
			case "CASHBOX2":return fundLocation.CASHBOX2;
			case "LOADER":return fundLocation.LOADER;
			default:return fundLocation.UNKNOWN;
		}
	},
	getName( enumValue ) {
		switch( enumValue ) {
			case fundLocation.RECYCLER:return "RECYCLER";
			case fundLocation.CASHBOX1:return "CASHBOX1";
			case fundLocation.CASHBOX2:return "CASHBOX2";
			case fundLocation.LOADER:return "LOADER";
			default:return "UNKNOWN";
		}
	},
	getIcon( enumValue ) {
		switch( enumValue ) {
			case fundLocation.RECYCLER:return ['fas', 'coins'];
			case fundLocation.CASHBOX1:return ['fas', 'money-bill'];
			case fundLocation.CASHBOX2:return ['fas', 'coins'];
			case fundLocation.LOADER:return ['fas', 'money-bill'];
			default:return "UNKNOWN";
		}
	},
};

export const fundLocationTranslations = {
	'fr': {
		"UNKNOWN":"Inconnu",
		"RECYCLER":"Recycleur",
		"CASHBOX":"Cassette",
		"CASHBOX1":"Cassette billets",
		"CASHBOX2":"Cassette pièces",
		"LOADER":"Chargeur",
    },
    'en': {
        "UNKNOWN":"Unknown",
		"RECYCLER":"Recycler",
		"CASHBOX":"Cashbox",
		"CASHBOX1":"Cashbox notes",
		"CASHBOX2":"Cashbox coins",
		"LOADER":"Loader",
    }
	
};

export const fundType = {
	UNKNOWN:0,
	CASH:1,
	CASH_COIN:2,
	CASH_NOTE:3,
	CREDIT_CARD:4,
	CHECK:5,
	ELECTRONIC_WALLET:6,
	RESTAURANT_VOUCHER:7,
	RESTAURANT_VOUCHER_DEMATERIALIZED:8,
	VACANCY_CHECK:9,
	GIFT:10,
	TEST_DEVICE:11,
	PAYMENT_ROUNDING:12,
	MISCELLANEOUS_CASHBOXED:13,
	fromName( name ) {
		switch(name) {
			case "CASH":return fundType.CASH;
			case "CASH_COIN":return fundType.CASH_COIN;
			case "CASH_NOTE":return fundType.CASH_NOTE;
			case "CREDIT_CARD":return fundType.CREDIT_CARD;
			case "CHECK":return fundType.CHECK;
			case "ELECTRONIC_WALLET":return fundType.ELECTRONIC_WALLET;
			case "RESTAURANT_VOUCHER":return fundType.RESTAURANT_VOUCHER;
			case "RESTAURANT_VOUCHER_DEMATERIALIZED":return fundType.RESTAURANT_VOUCHER_DEMATERIALIZED;
			case "VACANCY_CHECK":return fundType.VACANCY_CHECK;
			case "GIFT":return fundType.GIFT;
			case "TEST_DEVICE":return fundType.TEST_DEVICE;
			case "PAYMENT_ROUNDING":return fundType.PAYMENT_ROUNDING;
			case "MISCELLANEOUS_CASHBOXED":return fundType.MISCELLANEOUS_CASHBOXED;
			default:return fundType.UNKNOWN;
		}
	},
	getName( enumValue ) {
		switch( enumValue ) {
			case fundType.CASH:return "CASH";
			case fundType.CASH_COIN:return "CASH_COIN";
			case fundType.CASH_NOTE:return "CASH_NOTE";
			case fundType.CREDIT_CARD:return "CREDIT_CARD";
			case fundType.CHECK:return "CHECK";
			case fundType.ELECTRONIC_WALLET:return "ELECTRONIC_WALLET";
			case fundType.RESTAURANT_VOUCHER:return "RESTAURANT_VOUCHER";
			case fundType.RESTAURANT_VOUCHER_DEMATERIALIZED:return "RESTAURANT_VOUCHER_DEMATERIALIZED";
			case fundType.VACANCY_CHECK:return "VACANCY_CHECK";
			case fundType.GIFT:return "GIFT";
			case fundType.TEST_DEVICE:return "TEST_DEVICE";
			case fundType.PAYMENT_ROUNDING:return "PAYMENT_ROUNDING";
			case fundType.MISCELLANEOUS_CASHBOXED:return "MISCELLANEOUS_CASHBOXED";
			default:return "UNKNOWN";
		}
	},
	getIcon( enumValue ) {
		switch( enumValue ) {
			case fundType.CASH:return ['fas', 'coins'];
			case fundType.CASH_COIN:return ['fas', 'coins'];
			case fundType.CASH_NOTE:return ['fas', 'money-bill'];
			case fundType.CREDIT_CARD:return ['fas', 'credit-card'];
			case fundType.CHECK:return ['fas', 'money-check'];
			case fundType.ELECTRONIC_WALLET:return ['fas', 'coins'];
			case fundType.RESTAURANT_VOUCHER:return ['fas', 'utensils'];
			case fundType.RESTAURANT_VOUCHER_DEMATERIALIZED:return ['fas', 'money-check'];
			case fundType.VACANCY_CHECK:return ['fas', 'suitcase-rolling'];
			case fundType.GIFT:return ['fas', 'gift'];
			case fundType.TEST_DEVICE:return ['fas', 'coins'];
			case fundType.PAYMENT_ROUNDING:return ['fas', 'coins'];
			case fundType.MISCELLANEOUS_CASHBOXED:return ['fas', 'receipt'];
			default:return ['fas', 'question'];
		}
	},
};

export const fundTypeTranslations = {
	'fr': {
		"UNKNOWN":"Inconnu",
		"CASH":"Espèce",
		"CASH_COIN":"Pièce",
		"CASH_NOTE":"Billet",
		"CREDIT_CARD":"Carte Crédit",
		"CHECK":"Chèque",
		"ELECTRONIC_WALLET":"Porte Monnaie",
		"RESTAURANT_VOUCHER":"Titre Restaurant",
		"RESTAURANT_VOUCHER_DEMATERIALIZED":"Titre Restaurant Dématérialisé",
		"VACANCY_CHECK":"Chèque Vacances",
		"GIFT":"Chèque Cadeaux",
		"TEST_DEVICE":"Test",
		"PAYMENT_ROUNDING":"Arrondi",
		"MISCELLANEOUS_CASHBOXED":"Titre de paiement divers",
    },
    'en': {
        "UNKNOWN":"Unknown",
		"CASH":"Cash",
		"CASH_COIN":"Coin",
		"CASH_NOTE":"Note",
		"CREDIT_CARD":"Credit card",
		"CHECK":"Check",
		"ELECTRONIC_WALLET":"Electronic wallet",
		"RESTAURANT_VOUCHER":"Restaurant voucher",
		"RESTAURANT_VOUCHER_DEMATERIALIZED":"Dematerialized restaurant voucher",
		"VACANCY_CHECK":"Vacancy check",
		"GIFT":"Gift",
		"TEST_DEVICE":"Test device",
		"PAYMENT_ROUNDING":"Rounding",
		"MISCELLANEOUS_CASHBOXED":"Miscellaneous payment voucher",
    }
	
};

export const stateType = {
	UNKNOWN:0,
	OFFLINE:1,
	PARTIALLY:2,
	ONLINE:3,
	UNDER_MAINTENANCE:4,
	MAINTENANCE_REQUIRED:5,
	STOPPED:6,
	
	fromName( name ) {
		switch(name) {
			case "OFFLINE":return stateType.OFFLINE;
			case "PARTIALLY":return stateType.PARTIALLY;
			case "ONLINE":return stateType.ONLINE;
			case "UNDER_MAINTENANCE":return stateType.UNDER_MAINTENANCE;
			case "MAINTENANCE_REQUIRED":return stateType.MAINTENANCE_REQUIRED;
			case "STOPPED":return stateType.STOPPED;
			default:return stateType.UNKNOWN;
		}
	},
	getName( enumValue ) {
		switch( enumValue ) {
			case stateType.OFFLINE:return "OFFLINE";
			case stateType.PARTIALLY:return "PARTIALLY";
			case stateType.ONLINE:return "ONLINE";
			case stateType.UNDER_MAINTENANCE:return "UNDER_MAINTENANCE";
			case stateType.MAINTENANCE_REQUIRED:return "MAINTENANCE_REQUIRED";
			case stateType.STOPPED:return "STOPPED";
			default:return "UNKNOWN";
		}
	},
	getCssClass( enumValue ) {
		return this.getName( enumValue ).toLowerCase();
	},
};

export const boxDevices = {
	UNKNOWN:0,
	CLS:1,
	SCR:2,
	BNR:3,
	VCR:4,
	VIPS:5,
	MDB:6,
	
	fromName( name ) {
		switch(name) {
			case "CLS":return boxDevices.CLS;
			case "SCR":return boxDevices.SCR;
			case "BNR":return boxDevices.BNR;
			case "VCR":return boxDevices.VCR;
			case "Scanner": //fall through
			case "VIPS":return boxDevices.VIPS;
			case "MDB-CoinRecycler":
			case "MDB_CoinRecycler":
			case "MDB":return boxDevices.MDB;
			default:return boxDevices.UNKNOWN;
		}
	},
	getName( enumValue ) {
		switch( enumValue ) {
			case boxDevices.CLS:return "CLS";
			case boxDevices.SCR:return "SCR";
			case boxDevices.BNR:return "BNR";
			case boxDevices.VCR:return "VCR";
			case boxDevices.VIPS:return "VIPS";
			case boxDevices.MDB:return "MDB";
			default:return "UNKNOWN";
		}
	},
	getIcon( enumValue ) {
		switch( enumValue ) {
			case boxDevices.CLS:return ['fas', 'coins'];
			case boxDevices.SCR:return ['fas', 'money-bill'];
			case boxDevices.BNR:return ['fas', 'money-bill'];
			case boxDevices.VCR:return ['fas', 'desktop'];
			case boxDevices.VIPS:return ['fas', 'money-check'];
			case boxDevices.MDB:return ['fas', 'coins'];
			default:return ['fas', 'question'];
		}
	},
};

export const boxDevicesTranslations = {
	'fr': {
		"UNKNOWN":"Inconnu",
		"CLS":"Monnayeur pièces",
		"SCR":"Monnayeur billets",
		"BNR":"Monnayeur billets",
		"VCR":"Monnayeur Virtuel",
		"VIPS":"Titres de paiements",
    },
    'en': {
		"UNKNOWN":"Unknown",
        "CLS":"CLS",
		"SCR":"SCR",
		"BNR":"BNR",
		"VCR":"VCR",
		"VIPS":"Payment voucher",
    }
	
};

export const operationType = {
	UNKNOWN:0,
	SALE:1,
	SALE_RETURN:2,
	PURCHASE:3,
	PURCHASE_RETURN:4,
	REFILL_MANUAL:5,
	REFILL_BY_CASHBOX:6,
	PICKUP_MANUAL:7,
	PICKUP_BY_CASHBOX:8,
	EXCHANGE:9,
	USER_LOGIN:10,
	USER_LOGOUT:11,
	USER_LOGIN_FAILED:12,
	TRANSFER_FROM_RECYCLER_TO_CASHBOX:13,
	REFUND_ALL:14,
	CASH_IN:15,
	DISPENSE:16,
	IOT_GATEWAY:17,
	OPEN_CASHDRAWER:18,
	CONFIGURATION:19,
	fromName( name ) {
		switch(name) {
			case "SALE":return operationType.SALE;
			case "SALE_RETURN":return operationType.SALE_RETURN;
			case "PURCHASE":return operationType.PURCHASE;
			case "PURCHASE_RETURN":return operationType.PURCHASE_RETURN;
			case "REFILL_MANUAL":return operationType.REFILL_MANUAL;
			case "REFILL_BY_CASHBOX":return operationType.REFILL_BY_CASHBOX;
			case "PICKUP_MANUAL":return operationType.PICKUP_MANUAL;
			case "PICKUP_BY_CASHBOX":return operationType.PICKUP_BY_CASHBOX;
			case "EXCHANGE":return operationType.EXCHANGE;
			case "USER_LOGIN":return operationType.USER_LOGIN;
			case "USER_LOGOUT":return operationType.USER_LOGOUT;
			case "USER_LOGIN_FAILED":return operationType.USER_LOGIN_FAILED;
			case "TRANSFER_FROM_RECYCLER_TO_CASHBOX":return operationType.TRANSFER_FROM_RECYCLER_TO_CASHBOX;
			case "REFUND_ALL":return operationType.REFUND_ALL;
			case "CASH_IN":return operationType.CASH_IN;
			case "DISPENSE":return operationType.DISPENSE;
			case "IOT_GATEWAY":return operationType.IOT_GATEWAY;
			case "OPEN_CASHDRAWER":return operationType.OPEN_CASHDRAWER;
			case "CONFIGURATION":return operationType.CONFIGURATION;
			default:return operationType.UNKNOWN;
		}
	},
	getName( enumValue ) {
		switch( enumValue ) {
			case operationType.SALE:return "SALE";
			case operationType.SALE_RETURN:return "SALE_RETURN";
			case operationType.PURCHASE:return "PURCHASE";
			case operationType.PURCHASE_RETURN:return "PURCHASE_RETURN";
			case operationType.REFILL_MANUAL:return "REFILL_MANUAL";
			case operationType.REFILL_BY_CASHBOX:return "REFILL_BY_CASHBOX";
			case operationType.PICKUP_MANUAL:return "PICKUP_MANUAL";
			case operationType.PICKUP_BY_CASHBOX:return "PICKUP_BY_CASHBOX";
			case operationType.EXCHANGE:return "EXCHANGE";
			case operationType.USER_LOGIN:return "USER_LOGIN";
			case operationType.USER_LOGOUT:return "USER_LOGOUT";
			case operationType.USER_LOGIN_FAILED:return "USER_LOGIN_FAILED";
			case operationType.TRANSFER_FROM_RECYCLER_TO_CASHBOX:return "TRANSFER_FROM_RECYCLER_TO_CASHBOX";
			case operationType.REFUND_ALL:return "REFUND_ALL";
			case operationType.CASH_IN:return "CASH_IN";
			case operationType.DISPENSE:return "DISPENSE";
			case operationType.IOT_GATEWAY:return "IOT_GATEWAY";
			case operationType.OPEN_CASHDRAWER:return "OPEN_CASHDRAWER";
			case operationType.CONFIGURATION:return "CONFIGURATION";
			default:return "UNKNOWN";
		}
	},
	getIcon( enumValue ) {
		switch( enumValue ) {
			case operationType.SALE:return ['fas', 'coins'];
			case operationType.SALE_RETURN:return ['fas', 'coins'];
			case operationType.PURCHASE:return ['fas', 'money-bill'];
			case operationType.PURCHASE_RETURN:return ['fas', 'coins'];
			case operationType.REFILL_MANUAL:return ['fas', 'coins'];
			case operationType.REFILL_BY_CASHBOX:return ['fas', 'coins'];
			case operationType.PICKUP_MANUAL:return ['fas', 'money-check'];
			case operationType.PICKUP_BY_CASHBOX:return ['fas', 'money-check'];
			case operationType.EXCHANGE:return ['fas', 'money-check'];
			case operationType.USER_LOGIN:return ['fas', 'coins'];
			case operationType.USER_LOGOUT:return ['fas', 'coins'];
			case operationType.USER_LOGIN_FAILED:return ['fas', 'coins'];
			case operationType.TRANSFER_FROM_RECYCLER_TO_CASHBOX:return ['fas', 'money-check'];
			case operationType.REFUND_ALL:return ['fas', 'money-check'];
			case operationType.CASH_IN:return ['fas', 'money-check'];
			case operationType.DISPENSE:return ['fas', 'money-check'];
			case operationType.IOT_GATEWAY:return ['fas', 'money-check'];
			case operationType.OPEN_CASHDRAWER:return ['fas', 'money-check'];
			case operationType.CONFIGURATION:return ['fas', 'money-check'];
			default:return ['fas', 'question'];
		}
	},
};

export const operationTypeTranslations = {
	'fr': {
		"UNKNOWN":"Inconnu",
		"SALE":"Vente",
		"SALE_RETURN":"Retour Vente",
		"PURCHASE":"Achat",
		"PURCHASE_RETURN":"Retour Achat",
		"REFILL_MANUAL":"Rechargement",
		"REFILL_BY_CASHBOX":"Réapprovisionnement",
		"PICKUP_MANUAL":"Délestage",
		"PICKUP_BY_CASHBOX":"Sortie Cassette",
		"EXCHANGE":"Change",
		"USER_LOGIN":"Connexion Utilisateur",
		"USER_LOGOUT":"Déconnexion Utilisateur",
		"USER_LOGIN_FAILED":"Arrondi",
		"TRANSFER_FROM_RECYCLER_TO_CASHBOX":"Erreur Identification Utilisateur",
		"REFUND_ALL":"Annulation",
		"CASH_IN":"Encaissement",
		"DISPENSE":"Distribution",
		"IOT_GATEWAY":"IOT_GATEWAY",
		"OPEN_CASHDRAWER":"Ouverture Tiroir",
		"CONFIGURATION":"Configuration",
    },
    'en': {
        "UNKNOWN":"Unknown",
		"SALE":"Sale",
		"SALE_RETURN":"Sale Return",
		"PURCHASE":"Purchase",
		"PURCHASE_RETURN":"Purchase Return",
		"REFILL_MANUAL":"Manual Refill",
		"REFILL_BY_CASHBOX":"Cash Box Refill",
		"PICKUP_MANUAL":"Manual Pickup",
		"PICKUP_BY_CASHBOX":"Cash Box Pickup",
		"EXCHANGE":"Exchange",
		"USER_LOGIN":"User Login",
		"USER_LOGOUT":"User Logout",
		"USER_LOGIN_FAILED":"User Login Failure",
		"TRANSFER_FROM_RECYCLER_TO_CASHBOX":"Transfert from Recycler to Cash Box",
		"REFUND_ALL":"Refund All",
		"CASH_IN":"Cash In",
		"DISPENSE":"Dispense",
		"IOT_GATEWAY":"IOT_GATEWAY",
		"OPEN_CASHDRAWER":"Open Cashdrawer",
		"CONFIGURATION":"Configuration",
    }
	
};

export const messageType = {
	UNKNOWN:-1,
	INFORMATION:0,
	WARNING:1,
	SERIOUS_ERROR:2,
	PROGRESSION:3,
	TRACE_LOG:4,
	
	fromName( name ) {
		switch(name) {
			case "INFORMATION":return messageType.INFORMATION;
			case "WARNING":return messageType.WARNING;
			case "SERIOUS_ERROR":return messageType.SERIOUS_ERROR;
			case "PROGRESSION":return messageType.PROGRESSION;
			case "TRACE_LOG":return messageType.TRACE_LOG;
			default:return messageType.UNKNOWN;
		}
	},
	getName( enumValue ) {
		switch( enumValue ) {
			case messageType.INFORMATION:return "INFORMATION";
			case messageType.WARNING:return "WARNING";
			case messageType.SERIOUS_ERROR:return "SERIOUS_ERROR";
			case messageType.PROGRESSION:return "PROGRESSION";
			case messageType.TRACE_LOG:return "TRACE_LOG";
			default:return "UNKNOWN";
		}
	},
	getIcon( enumValue ) {
		switch( enumValue ) {
			case messageType.INFORMATION:return ['fas', 'info'];
			case messageType.WARNING:return ['fas', 'exclamation'];
			case messageType.SERIOUS_ERROR:return ['fas', 'times'];
			case messageType.PROGRESSION:return ['fas', 'spinner'];
			case messageType.TRACE_LOG:return ['fas', 'clipboard-check'];
			default:return ['fas', 'coins'];
		}
	},
};

export const messageTypeTranslations = {
	'fr': {
		"UNKNOWN":"Inconnu",
		"INFORMATION":"Information",
		"WARNING":"Avertissement",
		"SERIOUS_ERROR":"Erreur",
		"PROGRESSION":"Progression",
		"TRACE_LOG":"Log",
    },
    'en': {
        "UNKNOWN":"Unknown",
		"INFORMATION":"Information",
		"WARNING":"Warning",
		"SERIOUS_ERROR":"Error",
		"PROGRESSION":"Progress",
		"TRACE_LOG":"Log",
    }
	
};

