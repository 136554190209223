import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'aws-amplify/utils';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import NoDataPlaceholder from '../../Components/NoDataPlaceholder/NoDataPlaceholder';
import PeriodChooser from '../../Components/PeriodChooser/PeriodChooser';
import SortableTable from '../../Components/SortableTable/SortableTable';
import SyncIndicator from '../../Components/SyncIndicator/SyncIndicator';
import AmountFormatter from '../../../Utils/AmountFormatter';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import PayStationFinder from "../../../Utils/PayStationFinder";
import {operationType, operationTypeTranslations} from "../../../Models/Report/Enums.js";
//import NavigationSelector from '../../Components/NavigationSelector';
import './Transaction.css';
import '../Operation/Operation.css';
import {listOperations} from "../../../graphql/queries";
import {UserRoles} from "../../../Models/Roles";

const TransactionType = {
	SALE: "SALE",
	REFUND: "REFUND",
	CANCELLATION: "CANCELLATION"
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

I18n.putVocabularies( operationTypeTranslations );

let loadingTerminals = [];
let loadedTerminals = [];
let transactionInstance = null;
class Transaction extends React.Component {
	
	constructor( props ) {
		super( props );
		transactionInstance = this;
		let value = 0;
		if ( this.props.optionalNavigationData ) {
			if ( this.props.optionalNavigationData.startsWith("LATEST-") ) {
				//Init from the Last operation highlight direct click from list element
				value = this.getIndexFromI18nLabel( this.props.optionalNavigationData.replace("LATEST-" , "") );
			}
		}
		const periods = props.periodSynchronizer.getPeriods();
		this.state = {
			value: value,
			period1:periods[0],
			operations:[],
			isLoading: true
		}
	}

	componentDidMount() {
		this.extractOperations( transactionInstance.props.payStations )
			.then( operations => {
				transactionInstance.setState({
					isLoading: false,
					value:transactionInstance.state.value,
					period1:transactionInstance.state.period1,
					operations: operations
				});
			} );
	}

	extractOperations( payStations ) {
		return new Promise( resolve => {
			const anonymized = {};
			const analyzer = new ConfigAnalyzer( payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			const operations = [];
			terminals.forEach( terminal => {
				if( terminal.operations.length > 0 ) {
					terminal.operations.forEach( operation => {
						if( transactionInstance.props.currentUser.role === UserRoles.SALE_ROLE ) {
							if( ! anonymized[operation.cashier] ) {
								anonymized[operation.cashier] = `Caissier ${Object.keys( anonymized ).length + 1}`;
							}
							operation.cashier = anonymized[operation.cashier];
						}
						operations.push( operation );
						if( terminals.indexOf( terminal ) === terminals.length - 1 &&
							terminal.operations.indexOf( operation ) === terminal.operations.length - 1 ) {
							resolve( operations );
						}
					} );
				} else {
					if( terminals.indexOf( terminal ) === terminals.length - 1 ) {
						resolve( operations );
					}
				}
			} );
		} );
	}

	setValue( newValue ) {
		transactionInstance.setState({
			value:newValue
		});
	}

	onNewIncomingOperation() {
		transactionInstance.extractOperations( transactionInstance.props.payStations ).then( operations => {
			//this.setState( {operations: operations} );
		} );
	}

	handleChange(event, newValue) {
        transactionInstance.setValue(newValue);
    };
	
	isSaleOperation( operation ) {
		return ( operation === operationType.SALE ||
				 operation === operationType.SALE_REFUND  );
	}

	getIndexFromI18nLabel( i18nLabel ) {
		switch ( i18nLabel.trim() ) {
			case I18n.get("Cash in").trim(): return 0;
			case I18n.get("Refund").trim(): return 1;
			default: return 0;
		}
	}

	hasError( details ) {
		let hasError = false;
		if ( details ) {
			details.forEach( line => {
				if ( line && line.hasOwnProperty("hasError")) {
					if ( line.hasError ) {
						hasError = true;
					}
				}
			} );
		}
		return hasError
	}

	extractErrorMessageList( details ) {
		let errorMessageList = [];
		if ( details ) {
			details.forEach( line => {
				if ( line && line.hasOwnProperty("hasError")) {
					if ( line.hasError ) {
						const formatter = new AmountFormatter(this.props.locale,  line.currency );
						errorMessageList.push( line.errorMsg + " ( " + formatter.format( line.amount ) + " ) " );
					}
				}
				return true;
			} );
		}
		return errorMessageList;
	}
	
	extractErrorAmount( details ) {
		let amount = 0;
		if ( details ) {
			details.forEach(  line  => {
				if ( line && line.hasOwnProperty("hasError")) {
					if ( line.hasError ) {
						amount += line.amount;
					}
				}
				return true;
			} )
		}
		return amount;
	}

	filterByTypeForPeriod( operationTypeValue ) {
		const filtered = [];
		if( transactionInstance.state.operations ) {
			transactionInstance.state.operations.forEach( operation => {
				if( operation && operation.at ) {
					if( transactionInstance.state.period1.end >= new Date( operation.at ).getTime() &&
						new Date( operation.at ).getTime() >= transactionInstance.state.period1.start &&
						( operation.type === operationTypeValue || operation.type === 2 ) ) {
						filtered.push( operation );
					}
				}
			} );
		}
		return filtered;
	}

	extractDataForPeriods( operationTypeValue , type = TransactionType.SALE ) {
		let returnedRow = [];
		let currencies = [];
		const analyzer = new ConfigAnalyzer( transactionInstance.props.payStations );
		const finder = new PayStationFinder( transactionInstance.props.payStations );
		const terminals = analyzer.getDistinctActiveTerminalList();
		const filtered = this.filterByTypeForPeriod( operationTypeValue );

		const defaultErrorMessage = I18n.get( "Invalid fund type detection" );

		const added = [];
		filtered.sort( (a , b) => new Date( b.at ).getTime() - new Date( a.at ).getTime() );
		filtered.forEach( candidate => {
			if( ! currencies.includes( candidate.currency )  ) {
				currencies.push( candidate.currency );
			}

			const terminal = finder.getTerminalByCBMS( candidate.cbms , terminals );
			let box = finder.getBoxForDevice( candidate.data.details[0].deviceCBMS );
			if( candidate.data?.details[0].deviceCBMS?.startsWith( `5_Generic_EPT_` ) ) {
				box = finder.getBoxForEptOperation( terminal.name )
			}
			if( box && terminal ) {
				if( ! added.includes( candidate.uuid ) )
				if ( transactionInstance.getFilterForExtraction( type , candidate ) && candidate.currency === this.props.currencySelected ) {
					added.push( candidate.uuid );
					returnedRow.push({
						label: {
							typeLabel: ( box ) ? box?.informations?.attributes?.name : terminal?.informations?.attributes?.name,
							optDataLabel: finder.buildTerminalExtraData( terminal ),
							boxId: ( box ) ? box.id : terminal.id
						},
						date: new Date( candidate.at ).getTime()/1000,
						terminal:( terminal.informations.attributes.terminal ) ? terminal.informations.attributes.terminal : terminal.informations.attributes.name,
						cashier: candidate.cashier,
						amount: this.calculateTotalForOperation( candidate.data.details ),
						details: candidate.data.details,
						currency: candidate.currency,
						boxId: ( box ) ? box.id : terminal.id,
						manual:finder.isManual( candidate.data.details ),
						operationType:candidate.type,
						hasError: finder.hasError( candidate.data.details ),
						errorMessages:  finder.extractErrorMessageList( candidate.data.details , defaultErrorMessage )
					});
				}
			} else {
				console.log("Invalid mapping detected");
				console.log("box for " + candidate.data.details[0].deviceCBMS , box);
				console.log("terminal" , terminal );
			}
		} )

		if( currencies.length < 1 ) {
			currencies.push("EUR");
		}

		if( filtered.length > 0 && this.props.onCurrenciesUpdate ) {
			this.props.onCurrenciesUpdate( currencies );
		}

		return returnedRow;
	}

	calculateTotalForOperation( details ) {
		let total = 0;
		if( details ) {
			details.forEach( detail => {
				total += detail.qty * detail.value * detail.rate ;
			} );
		}
		return total;
	}

	getFilterForExtraction( type , candidate ) {
		switch ( type ) {
			case TransactionType.SALE: return transactionInstance.calculateTotalForOperation( candidate.data.details ) > 0 && ! transactionInstance.isCancellation( candidate );
			case TransactionType.REFUND: return transactionInstance.calculateTotalForOperation( candidate.data.details ) < 0 && ! transactionInstance.isCancellation( candidate );
			case TransactionType.CANCELLATION: return transactionInstance.isCancellation( candidate );
			default: throw new Error( `Invalid type ${type} only support TransactionType.SALE | TransactionType.REFUND | TransactionType.CANCELLATION` );
		}
	}
	
	extractRefundDataForPeriods( operationTypeValue ) {
		return transactionInstance.extractDataForPeriods( operationTypeValue , TransactionType.REFUND );
	} 
	
	extractCancelledDataForPeriods( operationTypeValue ) {
		return transactionInstance.extractDataForPeriods( operationTypeValue , TransactionType.CANCELLATION );
	} 
	
	isCancellation( operation ) {
		try {
			if( operation.data.details[0].error && ( operation.data.details[0].error.includes( I18n.get( "Cancelled operation" ) ) || operation.data.details[0].error.includes("Op?ration abandonn?e par l%5Cu0027op?rateur !" ) ) ) {
				return true;
			}
		} catch( error ) {
			console.error( error );
		}

		return false;
	}
	
	getHeaderProperties( data ) {
		if( data.length < 1 ) {
			return [];
		}

		if( this.props.isDesktop ) {
			return [
				{ id: "label", 		numeric: false, disablePadding: false, label: I18n.get("Box"), 		money: false, absolute: false, 	className:"header type" , 			width: 0 	, 	align: 'center' },
				{ id: 'error', 		numeric: false, disablePadding: false, label: "", 							money: false, absolute: false, 	className:"header error" , 			width: 25	, 	align: 'center'},
				{ id: 'manual', 	numeric: false, disablePadding: false, label: "", 							money: false, absolute: false, 	className:"header manual" , 		width: 25	, 	align: 'center'},
				{ id: 'date', 		numeric: false, disablePadding: false, label: I18n.get("Date"), 		money: false, absolute: false, 	className:"header sale date", 		width: 80	, 	align: 'center' },
				{ id: 'terminal', 	numeric: false, disablePadding: false, label: I18n.get("Terminal"), 	money: false, absolute: false, 	className:"header sale terminal",  	width: 210  , 	align: 'center' },
				{ id: 'cashier', 	numeric: false, disablePadding: false, label: I18n.get("Cashier"), 	money: false, absolute: false, 	className:"header sale cashier", 	width: 80	, 	align: 'center' },
				{ id: 'amount', 	numeric: true, 	disablePadding: false, label: I18n.get("Amount"), 		money: true,  absolute: true, 	className:"header sale-number", 	width: 100  , 	align: 'center' },
			];
		}
		return [
		  	{ id: "label", 		numeric: false, disablePadding: false, label: I18n.get("Box"), 		money: false, absolute: false, 	className:"header type" , 		width: 0 	, 	align: 'center' },
		  	{ id: 'date', 		numeric: false, disablePadding: false, label: I18n.get("Date"), 		money: false, absolute: false, 	className:"header sale", 		width: 80	, 	align: 'center' },
		  	{ id: 'amount', 	numeric: true, 	disablePadding: false, label: I18n.get("Amount"), 		money: true,  absolute: true, 	className:"header sale-number", width: 100  , 	align: 'center' },
		];
	}

	loadOperations( terminal , cbms , period , nextToken ) {
		if( transactionInstance.props.API ) {
			transactionInstance.props.API
				.graphql({ query: listOperations, variables: { input: { cbms: cbms , count: 10 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listOperations ) {
						returned.data.listOperations.operations.forEach( operation => {
							terminal.operations.push( operation );
						} );
						if( returned.data.listOperations.nextToken ) {
							transactionInstance.loadOperations( terminal , cbms , period , returned.data.listOperations.nextToken );
						} else {
							loadedTerminals.push( terminal );
							if( loadedTerminals.length === loadingTerminals.length ) {
								transactionInstance.extractOperations( transactionInstance.props.payStations )
									.then( operations => {
										transactionInstance.setState({
											isLoading: false,
											value:transactionInstance.state.value,
											period1:transactionInstance.state.period1,
											operations: operations
										});
									} );
							}
						}
					}
				})
				.catch((error) => {

				})
		}
	}

	handlePeriodSelection( period , identifier ) {
		if( transactionInstance.props.periodSynchronizer ) {
			transactionInstance.props.periodSynchronizer.updatePeriod( period , identifier );
		}
		if( transactionInstance.props.payStations ) {
			transactionInstance.setState({
				isLoading: true,
				value:transactionInstance.state.value,
				period1:period,
			});
			const analyzer = new ConfigAnalyzer( transactionInstance.props.payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			loadingTerminals = terminals;
			loadedTerminals = [];
			terminals.forEach( terminal => {
				terminal.operations = [];
				transactionInstance.loadOperations( terminal , `6_${terminal.informations.bms}` , {
					start: new Date( period.start ).toISOString(),
					end: new Date( period.end ).toISOString()
				} , null );
			} );
		}
	}
	
	prepareDataForPdf( data ) {
		const map = {};
		const finder = new PayStationFinder( transactionInstance.props.payStations );

		data.forEach( operation => {
			let formattedOperation = Object.assign( {}, operation );
			if( ! map.hasOwnProperty( operation.boxId ) ) {
				const box = Object.assign( {} , finder.getBoxForDevice( operation.details[0].deviceCBMS ) );
				map[operation.boxId] = {
					list:[],
					subTotal: { currency: operation.currency , amount:0 , name:operation.label.typeLabel },
					node: box,
					extraData: operation.label.optDataLabel,
				}
			}
			formattedOperation.total = operation.amount;
			formattedOperation["operation_date"] = new Date( operation.date * 1000).toISOString();
			map[operation.boxId].list.push( formattedOperation );
			map[operation.boxId].subTotal.amount += operation.amount;
		} );

		return map;
	}
	
	renderTab( enumValue ) {
		const key = "backoffice-tab-nav-item-" + enumValue;
		return ( <Tab key={key} label={I18n.get( operationType.getName( enumValue ))}/> );
	}
	
	renderCustomTab( labelKey ) {
		const key = "backoffice-tab-nav-item-" + labelKey;
		return ( <Tab key={key} label={I18n.get( labelKey )}/> );
	}
	
	renderTabPanel( enumValue , counterIndex , data ) {
		let shown = false;
		let cssTable = "comparison-table";
		if( data.length < 1 ) {
			shown = true;
			cssTable += " hidden";
		}
		
		if( counterIndex === transactionInstance.state.value ) {
			delete transactionInstance.props.pdfDataDelegate.map;
			delete transactionInstance.props.pdfDataDelegate.lines;
			transactionInstance.props.pdfDataDelegate.columnDefinition = this.getHeaderProperties(data);
			transactionInstance.props.pdfDataDelegate.map = this.prepareDataForPdf( data );
			switch( transactionInstance.state.value ) {
				case 1: transactionInstance.props.pdfDataDelegate.subTitle = I18n.get( "Refund" );
				break;
				case 2: transactionInstance.props.pdfDataDelegate.subTitle = I18n.get( "Cancellation" );
				break;
				default: transactionInstance.props.pdfDataDelegate.subTitle = I18n.get( operationType.getName( enumValue ));
				break;
			}
			transactionInstance.props.pdfDataDelegate.startLocalizedDate = new Date(transactionInstance.state.period1.start).toLocaleString().replace("," , "").substring( 0 , new Date(transactionInstance.state.period1.start).toLocaleString().replace("," , "").length - 3 );
			transactionInstance.props.pdfDataDelegate.endLocalizedDate = new Date(transactionInstance.state.period1.end).toLocaleString().replace("," , "").substring( 0 , new Date(transactionInstance.state.period1.end).toLocaleString().replace("," , "").length - 3 );
			let handlerExtraData = {
				locale: transactionInstance.props.locale.substring( 0 , transactionInstance.props.locale.indexOf("-") ),
				offset:new Date().getTimezoneOffset()
			}
			transactionInstance.props.pdfDataDelegate.data = {
				boxExtraData: I18n.get( "From" ) + ' ' + transactionInstance.props.pdfDataDelegate.startLocalizedDate + ' ' +
					I18n.get( "To" ).toLowerCase() + ' ' + transactionInstance.props.pdfDataDelegate.endLocalizedDate
			}
			transactionInstance.props.pdfDataDelegate.handler = JSON.stringify( handlerExtraData );
		}
		
		const key = "backoffice-tab-panel-item-" + enumValue;
		return ( 
			<TabPanel key={key} value={transactionInstance.state.value} index={counterIndex}>
				<div className="tab-panel-content">
					<Grid container spacing={1} >
						<Grid id="exportable-table" className="groupedBy" item xs={12} md={12} lg={12}>
							<Paper className="card-box">		
								<SortableTable header={this.getHeaderProperties( data )} 
											   rows={data} 
											   className={cssTable} 
											   locale={this.props.locale}
											   isDarkStyle={this.props.isDarkStyle}
											   isDesktop={this.props.isDesktop}
											   onPageChanged={this.handlePageChanged}
											   onRowsPerPageChanged={this.handleRowsPerPageChanged}
											   onSortingData={this.handleSorting}
											   forceAbsoluteInDetails={ counterIndex === 1 }
											   orderBy="id"
											   groupBy="label"
											   defaultRowsPerPage={25}/>
								<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
							</Paper>
						</Grid>
					</Grid>
				</div>
			</TabPanel>
		);
		
	}
	
	renderReminderPanel( enumValue , counterIndex , data ) {
		
		const key = "backoffice-tab-reminder-item-" + enumValue;

		let total = 0;
		let currency = "EUR";
		data.map(( line ) => {
			if( line ) {
				total += line.amount;
				currency = line.currency;
			}
			return true;
		});
		let displayedType;
		if( isNaN( enumValue ) ) {
			displayedType = I18n.get( enumValue ).toLowerCase();
		} else {
			displayedType = I18n.get( operationType.getName( enumValue )).toLowerCase();
		}
		const formatter = new AmountFormatter(this.props.locale,  currency );
		return ( 
			<Grid key={key} item xs={12} md={12} lg={4}>
				<div className={`tab-panel-reminder item-${counterIndex} ${(counterIndex === transactionInstance.state.value) ? 'active' : ''}`}
					 onClick={event => {
						 transactionInstance.handleChange( event , counterIndex );
					 }}>
					<Paper className="card-box reminder  operation-reminder">	
							<div className="type-reminder">
								{I18n.get("Total")} {displayedType}
							</div>
							<div className="total-reminder-content">							
								{formatter.format(Math.abs(total))}
							</div>							
					</Paper>
				</div>
			</Grid>
		);
		
	}
	
	render() {
		if( this.state.operations.length === 0 ) {
			//ignored force refresh on operation
		}
		let tabs = [];
		let panels = [];
		let reminders = [];
		let counterIndex = 0;
		Object.keys(operationType).forEach( (key) => {
			if( transactionInstance.isSaleOperation( operationType[ key ] ) ) {
				let data = transactionInstance.extractDataForPeriods( operationType[ key ] );
				tabs.push( transactionInstance.renderTab( operationType[ key ] ) );
				panels.push( transactionInstance.renderTabPanel( operationType[ key ] , counterIndex , data ) );
				reminders.push( transactionInstance.renderReminderPanel( operationType[ key ] , counterIndex , data ) );
				counterIndex++;
				
				let dataRefund = transactionInstance.extractRefundDataForPeriods( operationType[ key ] );
				tabs.push( transactionInstance.renderCustomTab( "Refund" ) );
				panels.push( transactionInstance.renderTabPanel( "Refund" , counterIndex , dataRefund ) );
				reminders.push( transactionInstance.renderReminderPanel( "Refund" , counterIndex , dataRefund ) );
				counterIndex++;
				
				let dataCancelled = transactionInstance.extractCancelledDataForPeriods( operationType[ key ] );
				tabs.push( transactionInstance.renderCustomTab( "Cancellation" ) );
				panels.push( transactionInstance.renderTabPanel( "Cancellation" , counterIndex , dataCancelled ) );
				reminders.push( transactionInstance.renderReminderPanel( "Cancellation" , counterIndex , dataCancelled ) );
				counterIndex++;
			}
		} )
		
		if( this.props.isDesktop ) {
			return (
				<React.Fragment>
					<div className="z-over fullscreen mb-2 read-only-breadscrum">
						{/*<NavigationSelector selectedNode={this.props.selectedNode}
											isDarkStyle={this.props.isDarkStyle}
											onSelectionChanged={this.props.onNodeChanged}
											readOnly={true}
											user={this.props.user} />*/}
					</div>	
					<div id="exportable" className="z-over fullscreen">
						<div className="period">
							<SyncIndicator loading={this.state.isLoading}
							               className={"display-4 spining space-top"}/>
							<PeriodChooser period={this.state.period1}
										   onPeriodChanged={this.handlePeriodSelection} 
										   callbackIdentifier="period1" 
										   isDarkStyle={this.props.isDarkStyle} />
						</div>
						<Tabs 	className="nav-tabs-primary hidden"
								key="backoffice-tab-nav"
								value={transactionInstance.state.value}
								variant="fullWidth"
								onChange={transactionInstance.handleChange}>
							{tabs.map( item => item )}	
						</Tabs>
						<Grid id="exportable-reminder" container spacing={1}>
							{reminders.map( item => item )}
						</Grid>
						{panels.map( item => item )}	
					</div>
				</React.Fragment>
			);
		}
		
		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.selectedNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>	
				<div id="exportable" className="z-over fullscreen">
					<div className="period">
						<SyncIndicator loading={this.state.isLoading}
						               className={"display-4 spining space-top"}/>
						<PeriodChooser period={this.state.period1}
									   onPeriodChanged={this.handlePeriodSelection} 
									   callbackIdentifier="period1" 
									   isDarkStyle={this.props.isDarkStyle} />
					</div>
					<Tabs 	className="nav-tabs-primary hidden"
							key="backoffice-tab-nav"
							value={transactionInstance.state.value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={transactionInstance.handleChange}>
						{tabs.map( item => item )}	
					</Tabs>
					<Grid id="exportable-reminder" container spacing={1}>
						{reminders.map( item => item )}
					</Grid>
					{panels.map( item => item )}	
				</div>
			</React.Fragment>
		);
	}
}

export default Transaction;
