import React from 'react';
import Dialog from "@mui/material/Dialog";
import {I18n} from "aws-amplify/utils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import UUID from "../../../Utils/UUID";

import '../../Views/Settings/Settings.css';
import '../../Views/Administration/Administration.css';
import './DialogGroupEdit.css'
import './InstallationManager.css'
import './DialogRemoteCommands.css'
import {notifyIotGateway} from "../../../graphql/mutations";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

const factoryUUID = new UUID();

const optionSeparator = "[opt-separator]";

const SupportedCommand = {
    PING:"ping",
    ENABLE_DATA_SENDING:"enableDataSending",
    INIT_PARAMETERS:"initParams",
    /*DIAGNOSTIC:"diagnostic",
    RESET:"reset",
    RESTART:"restart",
    FORCE_OUT:"forceOut",
    REVOKE_LICENSE:"revokeLicense",*/
}

const DialogRemoteCommands = props => {
    const [command, setCommand] = React.useState( ( props.command ) ? props.command : null );

    const handleOk = () => {
        props.onClear();
    };

    const onRemoteCommandRequested = ( command , serial , parameters ) => {
        if( props.API ) {
            if( props.observer ) {
                props.observer.onNotificationSent( parameters );
            }
            props.API.graphql( { query: notifyIotGateway, variables: { command:command , serial:serial , parameters:JSON.stringify( parameters ) } } )
                .then( returned => {
                    console.log( "returned" , returned );
                    handleOk();
                })
                .catch(error => {
                    console.log( "error" , error );
                    handleOk();
                });
        }
    }

    const handleCommand = () => {
        const parameters = JSON.parse( JSON.stringify( command ) );
        parameters.jobId = factoryUUID.generate();
        parameters.senderId = props.observer.currentUser.uuid;
        parameters.requestedAt = new Date().getTime();
        let gatewaySerial = '';
        props.observer?.payStations?.forEach( payStation => {
            if( payStation?.box?.id === props.selected ) {
                gatewaySerial = payStation.box.informations?.sender?.gateway?.serial;
            }
        } );
        onRemoteCommandRequested( parameters.command , gatewaySerial , parameters );
    }

    const isSubmitDisabled = () => {
        if( command ) {
            switch( command.command ) {
                case "PING": return false;
                case "ENABLE_DATA_SENDING":
                    if( command.type && ( command.type === "TECHNICAL" || command.type === "FINANCIAL" ) ) {
                        if( command.enabled === true || command.enabled === false ) {
                            return false;
                        }
                    }
                    break;
                case "INIT_PARAMETERS":
                    if( command.resellerId && command.resellerId !== "" && command.customerCode && command.customerCode !== "" ) {
                        return false;
                    }
                    break;
                case "DIAGNOSTIC":
                    break;
                case "RESET":
                    break;
                case "RESTART":
                    break;
                case "FORCE_OUT":
                    break;
                case "REVOKE_LICENSE":
                    break;
                default:
                    break;
            }
        }
        return true;
    }

    const handleChange = ( evt , type ) => {
        if( evt.target ) {
            let clone = Object.assign( {} , command );

            switch( type ) {
                case "command":
                    clone.command = evt.target.value.split( optionSeparator )[0];
                    break;
                case "type":
                    clone.type = evt.target.value.split( optionSeparator )[0];
                    break;
                case "enabling":
                    clone.enabling = evt.target.value.split( optionSeparator )[0];
                    if( clone.enabling === "ENABLED" ) {
                        clone.enabled = true;
                    } else if ( clone.enabling === "DISABLED" ) {
                        clone.enabled = false;
                    } else {
                        clone.enabled = null;
                    }
                    break;
                case "resellerId":
                    clone.resellerId = evt.target.value;
                    break;
                case "customerCode":
                    clone.customerCode = evt.target.value;
                    break;
                default:
                    break;
            }
            setCommand( clone );
        }
    };

    const renderMenuItem = ( optionValue ) => {
        if( optionValue && optionValue !== "" ) {
            let optValue =  optionValue;
            let optLabel =  I18n.get(optionValue);
            if( optionValue.hasOwnProperty("label") && optionValue.hasOwnProperty("id") ) {
                optValue =  optionValue.id;
                optLabel =  optionValue.label;
            }

            return (
                <MenuItem  key={`menu-command-item-${optionValue}-${factoryUUID.generate()}`}
                           value={optValue}>
                    <em>{optLabel}</em>
                </MenuItem>
            );
        }
        return (
            <MenuItem key={`menu-command-item-none`} value="">
                <em>{I18n.get("None")}</em>
            </MenuItem>
        );
    }

    const renderSelect = ( label , values , property ) => {
        const options = [];

        let css = "select-command";
        let cssSelect = "combo-command";
        let cssLabel = "label-command";
        if( props.isDarkStyle ) {
            css += " dark";
            cssSelect += " dark";
            cssLabel += " dark";
        }

        values.forEach(  value  => {
            options.push( renderMenuItem( value ) );
        } );

        return (
            <FormControl variant="outlined" className={css}>
                <InputLabel className={cssLabel}
                            id={`input-combo-${property}`}>
                    {label}
                </InputLabel>
                <Select classes={{selectMenu:cssSelect}}
                        labelId={`combo-${property}-label`}
                        id={`combo-${property}-select`}
                        value={( command && command.hasOwnProperty( property ) ) ? command[property] : "" }
                        onChange={(evt) => {handleChange(evt , property)}}
                        MenuProps={{
                            classes:{paper:cssSelect}
                        }}
                        label={label} >
                    {options.map( item => item )}
                </Select>
            </FormControl>
        );
    }

    const renderCommandSelect = () => {
        let values = [];
        values.push( "" );
        Object.keys( SupportedCommand ).forEach( availableCommand => {
            values.push( availableCommand );
            return true;
        } );
        return renderSelect( I18n.get("Command to execute") , values , "command" );
    }

    const renderDataTypeSelect = () => {
        return renderSelect( I18n.get("Data type") , ["","TECHNICAL","FINANCIAL"] , "type" );
    }

    const renderEnableSelect = () => {
        return renderSelect( I18n.get("Enabling") , ["","ENABLED","DISABLED"] , "enabling" );
    }

    const renderResellerIdSelect = () => {
        const values = [""];
        props.resellerAccounts?.sort( ( a , b ) => a.name.localeCompare( b.name ) );
        props.resellerAccounts?.forEach( value => {
            values.push( {
                id: value.code,
                label: value.name
            } );
        } );
        return renderSelect( I18n.get("Reseller id") , values , "resellerId" );
    }

    const renderCustomerCodeSelect = () => {
        const values = [""];
        if( command.resellerId ) {
            const reseller = props.resellerAccounts?.filter( item => item.code === command.resellerId );
            props.customerAccounts?.sort( ( a , b ) => a.name.localeCompare( b.name ) );
            props.customerAccounts?.forEach( value => {
                console.log( `${reseller[0].id} === ${value.createdby}` , reseller.id === value.createdby );
                if( reseller && reseller.length === 1 && reseller[0].id === value.createdby ) {
                    values.push( {
                        id: value.code,
                        label: value.name
                    } );
                }
            } );
        }
        return renderSelect( I18n.get("Customer code") , values , "customerCode" );
    }

    const renderEnableDataSending = () => {
        return (
            <div key="enable-data-sending" className={`data-sending`}>
                {renderDataTypeSelect()}
                {renderEnableSelect()}
            </div>
        );
    }

    const renderInitParameters = () => {
        return (
            <div key="init-parameters" className={`init-parameters`}>
                {renderResellerIdSelect()}
                {renderCustomerCodeSelect()}
            </div>
        );
    }

    /*const renderDiagnostic = () => {
		return (
			<div className="diagnostic">
				extra data diagnostic
			</div>
		);
	}

	const renderReset = () => {
		return (
			<div className="reset">
				extra data reset
			</div>
		);
	}

	const renderRestart = () => {
		return (
			<div className="restart">
				extra data restart
			</div>
		);
	}

	const renderForceOut = () => {
		return (
			<div className="force-out">
				extra data force
			</div>
		);
	}

	const renderRevokeLicense = () => {
		return (
			<div className="revoke-license">
				extra data revoke
			</div>
		);
	}
	*/

    const renderOptionalContent = () => {
        if( command !== null && command !== undefined ) {
            switch( command.command ) {
                case "PING":
                case "DIAGNOSTIC":
                case "RESET":
                case "RESTART":
                case "FORCE_OUT":
                case "REVOKE_LICENSE":
                    return null;
                case "ENABLE_DATA_SENDING":
                    return renderEnableDataSending();
                case "INIT_PARAMETERS":
                    return renderInitParameters();
                /*case "DIAGNOSTIC":
                    return renderDiagnostic();
                case "RESET":
                    return renderReset();
                case "RESTART":
                    return renderRestart();
                case "FORCE_OUT":
                    return renderForceOut();
                case "REVOKE_LICENSE":
                    return renderRevokeLicense();*/
                default:
                    break;
            }
        }
    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.opened ) {
        return null;
    }

    const titleKey = ( props.title ) ? props.title : "Remote commands";
    return (
        <Dialog disableEscapeKeyDown
                className={css}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.opened)}>
            <DialogTitle id="remote-command-dialog-title">{I18n.get( titleKey )}</DialogTitle>
            <DialogContent dividers>
                {renderCommandSelect()}
                {renderOptionalContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>
					<span className="btn-wrapper--label">
						{I18n.get("CANCEL")}
					</span>
                </Button>
                <Button disabled={isSubmitDisabled()} onClick={handleCommand}>
					<span className="btn-wrapper--label">
						{I18n.get("OK")}
					</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogRemoteCommands;
