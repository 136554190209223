export default class PhoneNumberFormatter {
    formatInternational( unformattedPhoneNumber , locale ) {
        let cleanedNumber = unformattedPhoneNumber?.replace(/[^0-9+]/g, '');
        const countryCodes = {
            'FR': '33',
            'BE': '32',
            'RE': '262',
            'GP': '590',
            'MQ': '596',
            'ES': '34',
            'CH': '41',
            'LU': '352',
            'PT': '351'
        };
        const countryCode = countryCodes[ locale.toUpperCase() ];
        if ( ! countryCode ) {
            console.error('Unsupported locale:', locale);
            return '';
        }

        if ( cleanedNumber?.startsWith( `+${countryCode}` ) ) {
            const countryCodeRegex = new RegExp(`^\\+${countryCode}0*`);
            cleanedNumber = cleanedNumber?.replace(countryCodeRegex, `+${countryCode}`);
            return cleanedNumber;
        } else if ( cleanedNumber?.startsWith('00') ) {
            cleanedNumber = `+${cleanedNumber.slice(2)}`;
        } else if ( cleanedNumber?.startsWith('0') ) {
            cleanedNumber = `+${countryCode}${cleanedNumber.slice(1)}`;
        } else if ( ! cleanedNumber?.startsWith('+') && cleanedNumber && cleanedNumber !== '' ) {
            console.log( 'cleanedNumber' , cleanedNumber );
            cleanedNumber = `+${countryCode}${cleanedNumber}`;
        }

        return cleanedNumber;
    }
}
