import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import './Menu.css';

export default function MenuUser(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const ACCOUNT = "account";
	const LOGOUT = "logout";
	
	const navigateTo = idNav => {
		switch ( idNav ) {
			case ACCOUNT: 
				props.handleListItemClick( null , 11 );
			break;
			case LOGOUT: 
				props.handleLogout();
			break;
			default :
			break;
		}
		//handleClose();
	};
	
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};

	let css = "Menu-CashMonitor User";
	let cssMenu = "p-0";
	let cssContent = "dropdown-menu-md overflow-hidden p-3 Menu-CashMonitor-Popup";
	let cssPaper = "";
	if( props.isDarkStyle ) {
		//css += " dark";
		//cssMenu += " dark";
		cssContent += " dark";
		cssPaper += " dark";
	}
	
	return (
		<React.Fragment>
			<div className={css}>
				<IconButton variant="text"  
							className="Icon" 
							disableRipple
							onClick={handleClick} >
					<FontAwesomeIcon icon={["fas" , "user"]} />
				</IconButton>
				<Menu anchorEl={anchorEl}
					  keepMounted
					  anchorOrigin={{
						  vertical: 'bottom',
						  horizontal: 'left',
					  }}
					  transformOrigin={{
						  vertical: 'top',
						  horizontal: 'right',
					  }}
					  open={Boolean(anchorEl)}
					  classes={{ list: cssMenu , paper: cssPaper}}
					  onClose={handleClose} >
					<div className={cssContent}>
						<div className="grid-menu grid-menu-1col">
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									onClick={evt => {
											navigateTo(ACCOUNT);
											handleClose();
										}} >
								{I18n.get("Account")}
							</Button>
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									onClick={(evt) => {
											navigateTo(LOGOUT);
											handleClose();
										}} >
								{I18n.get("Sign Out")}
							</Button>
						</div>
					</div>
				</Menu>
			</div>
		</React.Fragment>
	);
}
