import React from 'react';
import {I18n} from "aws-amplify/utils";
import {UserRoles} from "../../../Models/Roles";
import UUID from "../../../Utils/UUID";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import '../../Views/Settings/Settings.css';

let currentField = {
	id:null,
	lastChange:null,
	value:null
};

const factoryUUID = new UUID();

export default function DialogUserData( props ) {
	const [editing, setEditing] = React.useState( ( props.pendingEdit ) ? props.pendingEdit : null );

	const handleCancel = () => {
		props.handleClose();
		setEditing(null);
	};

	const handleOk = () => {
		editing.name = props.pendingEdit.name;
		editing.phone = props.pendingEdit.phone;
		editing.email = props.pendingEdit.email;
		editing.usertype = props.pendingEdit.usertype;
		editing.userrole = props.pendingEdit.userrole;
		editing.accountid = props.pendingEdit.accountid;
		if( props.pendingEdit.userid > 0 ) {
			props.handleClose( "edit" , editing );
		} else {
			props.handleClose( "add" , editing );
		}
		setEditing(null);
	};

	const handleInputChange = ( evt , property ) => {
		currentField = {
			id:`user-edited-${property}`,
			lastChange:new Date(),
			value:evt.target.value,
			property:property
		}

		setTimeout( () => {
			if( currentField.id ) {
				if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) {
					props.pendingEdit[currentField.property] = currentField.value;
					editing[currentField.property] = currentField.value;
					currentField = {
						id:null,
						lastChange:null,
						value:null,
						property:null
					};
				}
			}
		} , 5000 );

		let clone = Object.assign( {} , editing );
		clone[currentField.property] = currentField.value;
		props.pendingEdit[currentField.property] = currentField.value;
		setEditing( clone );
	}

	const handleSelectChange = ( evt ) => {
		let clone = Object.assign({}, editing);
		props.pendingEdit["usertype"] = evt.target.value;
		clone["usertype"] = evt.target.value;
		setEditing(clone);
	};

	const handleSelectRoleChange = ( evt ) => {
		let clone = Object.assign({}, editing);
		props.pendingEdit["userrole"] = evt.target.value;
		clone["userrole"] = evt.target.value;
		setEditing(clone);
	};

	const handleSelectAccountChange = ( evt ) => {
		let clone = Object.assign({}, editing);
		props.pendingEdit["accountid"] = evt.target.value;
		clone["accountid"] = evt.target.value;
		setEditing(clone);
	};

	const handleInputBlur = ( evt ) => {
		if( currentField.id ) {
			props.pendingEdit[currentField.property] = currentField.value;
			editing[currentField.property] = currentField.value;
			currentField = {
				id:null,
				lastChange:null,
				value:null,
				property:null
			};
		}
	}

	const isSubmitDisabled = () => {
		return !isValidName() || !isValidEmail() /*|| !isValidPhoneNumber()*/;
	}

	const isValidName = () => {
		if( props.pendingEdit ) {
			if( props.pendingEdit.name && props.pendingEdit.name.trim() !== "" ) {
				return true;
			}
		}
		return false;
	}

	const isValidEmail = () => {
		if( props.pendingEdit ) {
			if( props.pendingEdit.email ) {
				return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( props.pendingEdit.email );
			}
		}
		return false;
	}

	const isValidPhoneNumber = () => {
		/*if( props.pendingEdit ) {
			if( props.pendingEdit.phone && props.pendingEdit.phone.trim() !== "" ) {
				return /^\+[1-9]\d{10,14}$/.test( props.pendingEdit.phone?.replace(/[^0-9+]/g, '') );
			}
		}*/
		return true; //optional
	}

	const getHelperTextEmail = () => {
		if( props.pendingEdit ) {
			if( props.pendingEdit.email && props.pendingEdit.email.trim() !== "" ) {
				if( ! isValidEmail() ) {
					return I18n.get("Invalid field").replace("%format%" , I18n.get( "Email format" ) );
				}
			} else {
				return I18n.get("Required field");
			}
		}

		return null;
	}

	const getHelperTextName = () => {
		if( props.pendingEdit ) {
			if( props.pendingEdit.name && props.pendingEdit.name.trim() !== "" ) {
				return null;
			} else {
				return I18n.get("Required field");
			}
		}

		return null;
	}

	const getHelperTextPhone = () => {
		if( props.pendingEdit ) {
			if( props.pendingEdit.phone && props.pendingEdit.phone.trim() !== "" ) {
				if( !isValidPhoneNumber() ) {
					return I18n.get("Invalid field").replace("%format%" , I18n.get( "Phone format" ) );
				}
			}
		}

		return null;
	}

	const isLockedType = () => {
		if( props.userRDS && props.pendingEdit ) {
			if( props.userRDS.id === props.pendingEdit.userid ) {
				//user can't change his own type
				return true;
			}
			const USER = 3;
			if( props.userRDS["type"] === USER ) {
				return true;
			}
		}

		return false;
	}

	const isLockedRole = () => {
		return ! ( props.userRDS["type"] === 1 || props.userRDS["role"] === UserRoles.INTERNAL );
	}

	const renderSelectType = () => {
		const renderOptionalItem = userType => {
			if( parseInt( `${userType}` , 10 ) > 2 ) {
				return null;
			}
			return (
				<MenuItem value={2} key={factoryUUID.generate()}>
					<FontAwesomeIcon icon={["fas" , "user-shield"]} style={{fontSize:"1.25em"}}/>
					<span className="ml-2">{I18n.get("Admin")}</span>
				</MenuItem>
			);
		}

		const optionalItem = renderOptionalItem( props.userRDS["type"] );

		return (
			<Select classes={{selectMenu:cssSelect}}
					labelId={`user-edited-role-label`}
					id={`user-edited-role`}
					value={(props.pendingEdit["usertype"])?props.pendingEdit["usertype"]:3}
					onChange={handleSelectChange}
					MenuProps={{
						classes:{paper:cssSelect}
					}}
					disabled={isLockedType() || (!optionalItem)}
					label={I18n.get("Type")} >
				<MenuItem value={3} key={factoryUUID.generate()}>
					<FontAwesomeIcon icon={["fas" , "user"]} style={{fontSize:"1.25em"}}/>
					<span className="ml-2">{I18n.get("User")}</span>
				</MenuItem>
				{optionalItem}
			</Select>
		);
	};

	const renderSelectRole = () => {
		if( ! props.pendingEdit ) {
			return null;
		}

		const getIcon = role => {
			switch ( role ) {
				case UserRoles.INTERNAL: return "gear";
				case UserRoles.ADMINISTRATIVE: return "receipt";
				case UserRoles.TECHNICAL: return "headset";
				case UserRoles.SALE_ROLE: return "user-tie";
				default: return "x";
			}
		}

		if( props.userRDS["type"] === 1 || props.userRDS["role"] === UserRoles.INTERNAL ) {
			return (
				<FormControl variant="outlined" className={cssForm} disabled={isLockedRole()}>
					<InputLabel className={cssLabel}
								id={`user-edited-role-input`}>
						{I18n.get("Role")}
					</InputLabel>
					<Select classes={{selectMenu:cssSelect}}
							labelId={`user-edited-role-label`}
							id={`user-edited-role`}
							required
							value={ (props.pendingEdit["userrole"]) ? props.pendingEdit["userrole"] : 0}
							onChange={handleSelectRoleChange}
							MenuProps={{
								classes:{paper:cssSelect}
							}}
							label={I18n.get("Role")} >
						<MenuItem value={0} key={factoryUUID.generate()}>
							<FontAwesomeIcon icon={["fas" , "x"]} style={{fontSize:"1.25em"}}/>
							<span className="ml-2">{I18n.get("None")}</span>
						</MenuItem>
						{
							Object.keys( UserRoles ).map( key =>
								<MenuItem value={UserRoles[key]} key={factoryUUID.generate()}>
									<FontAwesomeIcon icon={["fas" , getIcon( UserRoles[key] )]} style={{fontSize:"1.25em"}}/>
									<span className="ml-2">{I18n.get(key)}</span>
								</MenuItem>
							)
						}

						{/*<MenuItem value={1}>
						<FontAwesomeIcon icon={["fas" , "user-lock"]} style={{fontSize:"1.25em"}}/>
						<span className="ml-2">{I18n.get("Root")}</span>
					</MenuItem>*/}
					</Select>
				</FormControl>
			);
		}
		return null;
	}

	const renderSelectAccount = () => {
		if( ! props.pendingEdit ) {
			return null;
		}

		const available = props.accounts.filter( item => item.type === 4 && ( item.createdby === props.userRDS.accountId || item.id === props.userRDS.accountId ) )
													  .sort( (a,b) => a.name.localeCompare( b.name ) );

		if( props.userRDS["type"] === 1 || props.userRDS["role"] === UserRoles.INTERNAL ) {
			return (
				<FormControl variant="outlined" className={cssForm} disabled={isLockedRole()}>
					<InputLabel className={cssLabel}
								id={`user-edited-account-input`}>
						{I18n.get("Account")}
					</InputLabel>
					<Select classes={{selectMenu:cssSelect}}
							labelId={`user-edited-role-label`}
							id={`user-edited-role`}
							required
							value={ (props.pendingEdit["accountid"]) ? props.pendingEdit["accountid"] : props.userRDS.accountId }
							onChange={handleSelectAccountChange}
							MenuProps={{
								classes:{paper:cssSelect}
							}}
							label={I18n.get("Account")} >
						{
							available.map( account =>
								<MenuItem value={account.id} key={factoryUUID.generate()}>
									<span className="ml-2">{account.name}</span>
								</MenuItem>
							)
						}
					</Select>
				</FormControl>
			);
		}
		return null;
	}

	let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
	let cssForm = "select-task-properties";
	let cssSelect = "combo-task-properties";
	let cssLabel = "label-task-properties";

	if( props.isDarkStyle ) {
		css += " dark";
		cssForm += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}

	if( ! props.pendingEdit ) {
		return null;
	}

	if( ! editing ) {
		setEditing( props.pendingEdit );
	}

	const idIfNotNew = (props.pendingEdit && props.pendingEdit.hasOwnProperty("cognitoid") && props.pendingEdit.cognitoid !== '') ? props.pendingEdit.cognitoid : "";
	const nameIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("userlabel") ) ? props.pendingEdit.userlabel : "";
	const emailIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Email") ) ? props.pendingEdit.Email : "";
	const phoneIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("PhoneNumber") ) ? props.pendingEdit.PhoneNumber : "";

	if( editing ) {
		editing.name = nameIfExists;
		editing.email = emailIfExists;
		editing.phone = phoneIfExists;
	}

	return (
		<Dialog classes={{ paper: css }}
				aria-labelledby="simple-dialog-title"
				open={Boolean(props.pendingEdit)}>
			<div className="text-center p-3">
				<div className="user-data">
					<div className="user-data-item id">
						<TextField
						  variant="outlined"
						  id={`user-edited-id`}
						  label={I18n.get("Id")}
						  placeholder={I18n.get("New")}
						  defaultValue={idIfNotNew}
						  autoComplete={`off`}
						  InputProps={{
							readOnly: true,
						  }}
						/>
					</div>
					<div className="user-data-item name">
						<TextField
							required
							error={!isValidName()}
							helperText={getHelperTextName()}
							variant="outlined"
							autoComplete={`off`}
							id={`user-edited-name`}
							label={I18n.get("Name")}
							placeholder={I18n.get("Not Specified")}
							defaultValue={nameIfExists}
							InputProps={{
								readOnly: false,
							}}
							onChange={(evt) => handleInputChange( evt , "name" ) }
							onBlur={(evt) => handleInputBlur( evt ) }
						/>
					</div>
					{/*<div className="user-data-item node">
						{renderNodeChooser()}
					</div>*/}
					<div className="user-data-item email">
						<TextField
						  variant="outlined"
						  required
						  error={!isValidEmail()}
						  helperText={getHelperTextEmail()}
						  autoComplete={`off`}
						  id={`user-edited-email`}
						  label={I18n.get("Email")}
						  placeholder={I18n.get("Not Specified")}
						  defaultValue={emailIfExists}
						  InputProps={{
							readOnly: (props.pendingEdit.userid > 0),
							required: true
						  }}
						  onChange={(evt) => handleInputChange( evt , "email" ) }
						  onBlur={(evt) => handleInputBlur( evt ) }
						/>
					</div>
					<div className="user-data-item phone">
						<TextField
						  variant="outlined"
						  error={!isValidPhoneNumber()}
						  autoComplete={`off`}
						  helperText={getHelperTextPhone()}
						  id={`user-edited-phone`}
						  label={I18n.get("Phone")}
						  placeholder={I18n.get("Not Specified")}
						  defaultValue={phoneIfExists}
						  InputProps={{
							readOnly: false,
						  }}
						  onChange={(evt) => handleInputChange( evt , "phone" ) }
						  onBlur={(evt) => handleInputBlur( evt ) }
						/>
					</div>
					<div className="user-data-item role">
						<FormControl variant="outlined" className={cssForm} disabled={isLockedType()}>
							<InputLabel className={cssLabel}
										id={`user-edited-type-input`}>
								{I18n.get("Type")}
							</InputLabel>
							{renderSelectType()}
						</FormControl>
					</div>
					<div className="user-data-item role">
						{renderSelectRole()}
					</div>
					{(props.accounts?.length > 0) ? <div className="user-data-item role">
						{renderSelectAccount()}
					</div> : ''}
				</div>
				<div className="pt-1">
					<Button autoFocus onClick={handleCancel} className="btn-neutral-secondary btn-pill text-danger mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("CANCEL")}
						</span>
					</Button>
					<Button onClick={handleOk} className="btn-neutral-secondary btn-pill text-danger mx-1" disabled={isSubmitDisabled()}>
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
					</Button>
				</div>
			</div>
		</Dialog>

	);
}