import React from 'react';
import { I18n } from 'aws-amplify/utils';
import Grid from '@mui/material/Grid';
import Chart from 'react-apexcharts';
import NoDataPlaceholder from "../../Components/NoDataPlaceholder/NoDataPlaceholder";
import GroupedChart from "../../Components/GroupedChart/GroupedChart";
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import './HardwareOverview.css';
import '../LastWeekSales/LastWeekSales.css';

export default function HardwareOverview( props ) {
	const inspector = new ConfigAnalyzer( props.list );

	const isActiveBoxAtSearchTime = ( box ) => {
		let isActive = false;
		/*let searchDate = new Date().getTime();
		if( box !== null && box !== undefined && box.hasOwnProperty("periods") ) {
			box.periods.map( (period) => {
				if( period !== null && period !== undefined ) {
					try {
						let begin = new Date (Date.parse(period.begin));
						let end = new Date (Date.parse(period.end));
						
						if( isNaN( end.getTime() ) ) {
							end = new Date ( new Date().getTime() - ( new Date().getTimezoneOffset() * 60 * 1000 ) ); //currently opened period
						}
						
						if( searchDate >= begin.getTime() && end.getTime() >= searchDate ) {
							isActive = true;
						}
					} catch ( error ) {
						console.error( error );
					}
					
				} 
				return true;
			} );
			
			if( box.children.length < 1 ) {
				//hide empty box
				isActive = false;
			}
			
			if( ! inspector.isActive( box ) ) {
				isActive = false;
			}
		}*/
		isActive = true;
		return isActive;
	}
	
	const isValid = ( box ) => {
		return  box &&
				box.informations &&
				box.informations.data &&
				box.informations.data.identity &&
				box.informations.data.identity.platform &&
				box.informations.data.identity.software;
	}
	
	const extractCustomProperties = ( identity , map ) => {
		if( identity !== null && identity !== undefined ) {
			let cbms = `${identity.category}_${identity.brand}_${identity.model}_${identity.serial}`;
			if( map !== null && map !== undefined && map.hasOwnProperty(cbms) ) {
				if( map[cbms].hasOwnProperty("data") && map[cbms].data.hasOwnProperty("custom") ) {
					return map[cbms].data.custom;
				}
			}
		}
		return null;
	}
	
	const convertSize = ( size ) => {
		return size / 1000;
	}
	
	const adaptSize = ( sizeInBytes , forceInt=false ) => {
		let targetedUnits = ["KB" , "MB" , "GB" , "TB"];
		let currentValue = sizeInBytes;
		let returned = sizeInBytes + " Bytes";
		targetedUnits.map( (unit) => {
			if( currentValue > 1000 ) {
				currentValue = convertSize(currentValue);
				if( forceInt ) {
					returned = new Intl.NumberFormat( props.locale , { maximumSignificantDigits: 2 }).format( Math.round( Math.round( currentValue * 100 ) / 100 ) ) + " " + unit;
				} else {
					returned = new Intl.NumberFormat( props.locale , { maximumSignificantDigits: 2 }).format( Math.round( currentValue * 100 ) / 100 ) + " " + unit;
				}
			}
			return true;
		} )
		return returned;
	}
	
	const renderOperatingSystemGraph = ( labels , series ) => {
		return renderDonuts( "architecture" , labels , series );
	} 
	
	const renderResolutionGraph = ( labels , series ) => {
		//return renderDonuts( "resolution" , labels , series );
		
		labels.sort( ( a , b ) => series[labels.indexOf(b)] - series[labels.indexOf(a)] );
		series.sort( ( a , b ) => b - a );
		return(
			<GroupedChart id={`renderProcessorGraph`}
						  largeLabels={true}
						  labels={labels}
						  series={[{data:series}]} />
		);
	} 
	
	const renderMemorySystemGraph = (series) => {
		const labels = [];
		const data = [];
		series.map( ( candidate ) => {
			labels.push( candidate.name );
			data.push( candidate.data[0] );
			return true;
		} );
		
		labels.sort( ( a , b ) => data[labels.indexOf(b)] - data[labels.indexOf(a)] );
		data.sort( ( a , b ) => b - a );
		return(
			<GroupedChart id={`renderMemorySystemGraph`}
						  labels={labels}
						  series={[{data:data}]} />
		);
	} 
	
	const renderOperatingSystemVersionGraph = ( labels , series ) => {
		labels.sort( ( a , b ) => series[0].data[labels.indexOf(b)] - series[0].data[labels.indexOf(a)] );
		series[0].data.sort( ( a , b ) => b - a );
		return(
			<GroupedChart id={`renderOperatingSystemVersionGraph`}
						  largeLabels={true}
						  labels={labels}
						  series={series} />
		);
	} 
	
	const renderDevicesGraph = () => {
		return renderDonuts("devices" , inspector.getLabelsForType() , inspector.getSeriesForType());
	} 

	const renderProcessorGraph = ( series ) => {
		let labels = [];
		let data = [];
		series.map( ( candidate ) => {
			labels.push( candidate.name );
			data.push( candidate.data[0] );
			return true;
		} );
		
		labels.sort( ( a , b ) => data[labels.indexOf(b)] - data[labels.indexOf(a)] );
		data.sort( ( a , b ) => b - a );
		return(
			<GroupedChart id={`renderProcessorGraph`}
						  largeLabels={true}
						  labels={labels}
						  series={[{data:data}]} />
		);
	} 
	
	const renderDonuts = ( key , labels , series ) => {
		if ( ! labels || labels.length < 1 ) {
			return (<NoDataPlaceholder className="comparison-placeholder-no-data" shown={true}/>);
		}

		return (
			<Chart 	options={{
								labels:labels,
								colors:[
									'#10c5db',
									'rgb(0, 143, 251)',
									'rgb(0, 227, 150)' ,
									'rgb(254, 176, 25)' ,
									'rgb(255, 69, 96)' ,
									'rgb(119, 93, 208)' ,
									'#d622a1' ,
									'#008080'
								],
								legend: {
									position:"bottom"
								}
							}} 
					series={series}
					type="pie"
					height="100%"
					width="100%"
					key={key}
					style={{maxWidth:"100%",minHeight:"250px", maxHeight:"250px"}}
					className="cm-chart"/>
		);
	}
	
	const renderSoftwareGraphForModel = ( model ) => {
		return (
			<Grid className="container graphCard" item xs={6} md={3} lg={3} key={`graph-grid-${model}`}>
				<div className="title-graph">{model}</div>
				<GroupedChart id={`software-${model}`} 
							  labels={inspector.getVersionsForSoftwareModel( model )}
							  series={inspector.getSeriesForSoftwareModel( model )} />
			</Grid>
		);
	}
	
	const renderSoftwaresGraphs = () => {
		//getDistinctSoftwareLabels()
		let items = [];
		inspector.getDistinctSoftwareLabels().forEach( model => {
			items.push( renderSoftwareGraphForModel( model ) );
		} );
		return (
			<Grid className="container" item xs={12} md={12} lg={12}>
				<Grid container spacing={1} >
					{items.map( item => item )}
				</Grid>
			</Grid>
		);
	}
	
	let filtered = [];
	
	if( props.list ) {
		props.list.map( (box) => {
			/*if( isActiveBoxAtSearchTime( box ) && isValid( box ) ) {
				filtered.push( box );
			}*/
			filtered.push( box );
			return true;
		} );
	}
	
	const calculated = {
		os:{
			labels:[],
			series:[]
		},
		osSoftware:{
			labels:[],
			series:[]
		},
		osMemory:{
			series:[]
		},
		resolution:{
			labels:[],
			series:[]
		},
		processor:{
			series:[]
		},
	};

	filtered.forEach( payStation => {
		const platform = payStation.box.platform;

		let platformCustomProperties = platform?.attributes;
		if( platformCustomProperties ) {
			if( platformCustomProperties.os && platformCustomProperties.os.type ) {
				const regex = /[0-9]{2}/g;
				const found = platformCustomProperties.os.type.match(regex) + " Bits";
				if( ! calculated.os.labels.includes( found ) ) {
					calculated.os.labels.push(found);
				}
				let index = calculated.os.labels.indexOf(found);
				calculated.os.series[index] = (( calculated.os.series[index] )?calculated.os.series[index]:0) + 1;
			}
			
			if( platformCustomProperties.os && platformCustomProperties.os.ram && platformCustomProperties.os.ram.size ) {
				let os = null;
				let size = adaptSize( platformCustomProperties.os.ram.size * 1000 , true );
				calculated.osMemory.series.forEach( candidate => {
					if( candidate.name === size ) {
						os = candidate;
					}
				} );
				
				if( os === null ) {
					os = {name:size , data:[0]};
					calculated.osMemory.series.push( os );
				}
				
				os.data[0] = os.data[0] + 1;
			}
			
			if( platformCustomProperties.processor && platformCustomProperties.processor.name ) {
				let os = null;
				calculated.processor.series.forEach( candidate => {
					if( candidate.name === platformCustomProperties.processor.name ) {
						os = candidate;
					}
				} )
				
				if( os === null ) {
					os = {name:platformCustomProperties.processor.name , data:[0]};
					calculated.processor.series.push( os );
				}

				os.data[0] = os.data[0] + 1;
			}
			
			if( platformCustomProperties.os && platformCustomProperties.os.name ) {
				
				let key = `${platformCustomProperties.os.name} ${platformCustomProperties.os.version}`;
				if( ! calculated.osSoftware.labels.includes( key ) ) {
					calculated.osSoftware.labels.push( key );
				}
				let index = calculated.osSoftware.labels.indexOf( key );
				if( calculated.osSoftware.series[index] ) {
					calculated.osSoftware.series[index] = calculated.osSoftware.series[index] + 1;
				} else {
					calculated.osSoftware.series[index] = 1;
				}
			}
			
			if( platformCustomProperties.video && platformCustomProperties.video.size ) {
				const found = platformCustomProperties.video.size.width + " * " + platformCustomProperties.video.size.height;
				if( ! calculated.resolution.labels.includes( found ) ) {
					calculated.resolution.labels.push(found);
				}
				let index = calculated.resolution.labels.indexOf( found );
				calculated.resolution.series[index] = (( calculated.resolution.series[index] ) ? calculated.resolution.series[index] : 0 ) + 1;
			}
		}
	} );

	let css = "HardwareOverview-root";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	if( props.className ) {
		css += " " + props.className ;
	}
	
	return (
		<React.Fragment>
			<div className={css}>
				<Grid container spacing={1} >
					{renderSoftwaresGraphs()}
					
					<Grid className="container graphCard" item xs={12} md={4} lg={4}>
						<div className="title-graph">{I18n.get("Units grouped by OS versions")}</div>
						{renderOperatingSystemVersionGraph( calculated.osSoftware.labels , [{data:calculated.osSoftware.series}] )}
					</Grid>
				
					<Grid className="container graphCard" item xs={12} md={4} lg={4}>
						<div className="title-graph">{I18n.get("Units grouped by memory sizes")}</div>
						{renderMemorySystemGraph( calculated.osMemory.series )}
					</Grid>
				
					<Grid className="container graphCard" item xs={12} md={4} lg={4}>
						<div className="title-graph">{I18n.get("Units grouped by manufacturers and processors")}</div>
						{renderProcessorGraph( calculated.processor.series )}
					</Grid>
				
					<Grid className="container graphCard" item xs={12} md={4} lg={4}>
						<div className="title-graph">{I18n.get("Devices grouped models")}</div>
						{renderDevicesGraph()}
					</Grid>
				
					<Grid className="container graphCard" item xs={12} md={4} lg={4}>
						<div className="title-graph">{I18n.get("Units grouped by architectures")}</div>
						{renderOperatingSystemGraph( calculated.os.labels , calculated.os.series )}
					</Grid>
					
					<Grid className="container graphCard" item xs={12} md={4} lg={4}>
						<div className="title-graph">{I18n.get("Units grouped by screen resolutions")}</div>
						{renderResolutionGraph( calculated.resolution.labels , calculated.resolution.series )}
					</Grid>
				</Grid>
			</div>
		</React.Fragment>
	);
}
