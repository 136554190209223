import React from 'react';
import Dialog from "@mui/material/Dialog";
import {I18n} from "aws-amplify/utils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import UUID from "../../../Utils/UUID";

import './DialogCustomNameManagement.css'
import TextField from "@mui/material/TextField";
import {setPreferences} from "../../../graphql/mutations";

const factoryUUID = new UUID();

let currentField = {
    id:null,
    lastChange:null,
    value:null
};

let timeoutId = null;

const DialogCustomNameManagement = props => {
    const [boxPreferences, setBoxPreferences] = React.useState( ( props.boxPreferences ) ? props.boxPreferences : null );
    const handleOk = () => {
        props.onClear();
        setBoxPreferences( null );
    };

    const isSubmitDisabled = () => {
        const hasValidTerminalNames = () => {
            let isValid = true;
            boxPreferences?.terminals?.forEach( terminal => {
                if( ! terminal.name || terminal.name.trim() === '' ) {
                    isValid = false;
                }
            } );

            Object.keys( boxPreferences ).forEach( key => {
                if( key.startsWith( 'terminal-' ) ) {
                    if( ! boxPreferences[key] || boxPreferences[key].trim() === '' ) {
                        isValid = false;
                    }
                }
            } )

            return isValid;
        };

        return !boxPreferences?.box || boxPreferences?.box === '' || ! hasValidTerminalNames();
    }

    const handleChange = ( evt , property ) => {
        if( evt.target ) {
            currentField = {
                id:`user-edited-${property}`,
                lastChange:new Date(),
                value:evt.target.value,
                property:property
            }

            timeoutId = setTimeout( () => {
                if( currentField.id ) {
                    if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) {
                        props.boxPreferences[currentField.property] = currentField.value;
                        boxPreferences[currentField.property] = currentField.value;

                        let clone = Object.assign( {} , boxPreferences );
                        clone[currentField.property] = currentField.value;
                        props.boxPreferences[currentField.property] = currentField.value;
                        setBoxPreferences( clone );

                        currentField = {
                            id:null,
                            lastChange:null,
                            value:null,
                            property:null
                        };
                    }
                }
            } , 5000 );
        }
    };

    const handleBlur = evt => {
        if( currentField.id ) {
            if( timeoutId ) {
                clearTimeout( timeoutId );
            }

            let clone = Object.assign( {} , boxPreferences );
            clone[currentField.property] = currentField.value;
            props.boxPreferences[currentField.property] = currentField.value;
            setBoxPreferences( clone );

            currentField = {
                id:null,
                lastChange:null,
                value:null,
                property:null
            };
        }
    }

    const handleSubmit = () => {
        const preparedDataToSave = formatPreferences();
        props.API.graphql({ query: setPreferences, variables: { userIdAndBoxId:`${props.observer.currentUser.uuid}@${boxPreferences.boxId}` , data: JSON.stringify( preparedDataToSave ) } })
            .then( returned => {
                if( props.observer?.onBoxUpdated ) {
                    props.observer?.onBoxUpdated( JSON.parse( returned.data?.setPreferences?.data ) );
                }
                handleOk();
            })
            .catch((error) => {
                console.error("error" , error);
                handleOk();
            });
    };

    const formatPreferences = () => {
        const formatted = {};
        Object.keys( boxPreferences ).sort().forEach( key => {
            if( key.startsWith( 'terminal-' ) ) {
                if( ! formatted.terminals  ) {
                    formatted.terminals = [];
                }
                formatted.terminals.push( { name: boxPreferences[key] } );
            } else if ( key !== 'terminals' ) {
                if( boxPreferences[key] ) {
                    formatted[key] = boxPreferences[key];
                }
            }
        } );

        if( ! formatted.terminals || formatted.terminals.length < 1 ) {
            formatted.terminals = props.boxPreferences.terminals;
        }

        return formatted;
    };

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.boxPreferences ) {
        return null;
    }

    const boxIfExists = ( boxPreferences?.box ) ? boxPreferences?.box : "";
    const companyIfExists = ( boxPreferences?.company ) ? boxPreferences?.company : "";
    const shopIfExists = ( boxPreferences?.shop ) ? boxPreferences?.shop : "";
    const terminalsIfExists = [];

    if( ! boxPreferences ) {
        setBoxPreferences( props.boxPreferences );
    } else {
        boxPreferences?.terminals?.forEach( terminal => {
            const editedTerminal = boxPreferences[`terminal-` + ( boxPreferences.terminals.indexOf( terminal ) + 1 )];
            // Nullish Coalescing Operator (ES2020+) Otherwise empty strings are considered has rejected
            terminalsIfExists.push( `${editedTerminal ?? terminal.name}` );
        } );
    }

    const titleKey = ( props.title ) ? props.title : "Customization";
    return (
        <Dialog disableEscapeKeyDown
                className={css}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.boxPreferences)}>
            <DialogTitle id="remote-command-dialog-title">{I18n.get( titleKey )}</DialogTitle>
            <DialogContent dividers>
                <TextField
                    required
                    variant="outlined"
                    id={`custom-name-box`}
                    key={factoryUUID.generate()}
                    label={ I18n.get( 'Box' ) }
                    placeholder={ I18n.get("Not Specified") }
                    defaultValue={ boxIfExists }
                    InputProps={{
                        readOnly: false,
                    }}
                    onChange={(evt) => handleChange( evt , 'box' ) }
                    onBlur={(evt) => handleBlur( evt ) }
                />
                <TextField
                    variant="outlined"
                    id={`custom-name-company`}
                    key={factoryUUID.generate()}
                    label={ I18n.get( 'Company' ) }
                    placeholder={ I18n.get("Not Specified") }
                    defaultValue={ companyIfExists }
                    InputProps={{
                        readOnly: false,
                    }}
                    onChange={(evt) => handleChange( evt , 'company' ) }
                    onBlur={(evt) => handleBlur( evt ) }
                />
                <TextField
                    variant="outlined"
                    id={`custom-name-shop`}
                    key={factoryUUID.generate()}
                    label={ I18n.get( 'Shop' ) }
                    placeholder={ I18n.get("Not Specified") }
                    defaultValue={ shopIfExists }
                    InputProps={{
                        readOnly: false,
                    }}
                    onChange={(evt) => handleChange( evt , 'shop' ) }
                    onBlur={(evt) => handleBlur( evt ) }
                />
                {
                    boxPreferences?.terminals?.map( terminal =>
                        <TextField
                            required
                            variant="outlined"
                            id={`custom-name-terminal-${ boxPreferences?.terminals?.indexOf( terminal ) + 1 }`}
                            key={factoryUUID.generate()}
                            label={ `${I18n.get('Terminal')} ${ boxPreferences?.terminals?.indexOf( terminal ) + 1 }` }
                            placeholder={ I18n.get("Not Specified") }
                            defaultValue={ terminalsIfExists[boxPreferences?.terminals?.indexOf( terminal )] }
                            InputProps={{
                                readOnly: false,
                            }}
                            onChange={(evt) => handleChange( evt , `terminal-${ boxPreferences?.terminals?.indexOf( terminal ) + 1 }` ) }
                            onBlur={(evt) => handleBlur( evt ) }
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk}>
					<span className="btn-wrapper--label">
						{I18n.get("CANCEL")}
					</span>
                </Button>
                <Button disabled={isSubmitDisabled()} onClick={handleSubmit}>
					<span className="btn-wrapper--label">
						{I18n.get("OK")}
					</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogCustomNameManagement;
