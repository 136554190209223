import React from 'react';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {I18n} from "aws-amplify/utils";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import GuidedSelection from "../UserFilteringEngine/GuidedSelection";
import IconButton from "@mui/material/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UUID from "../../../Utils/UUID";

import '../../Views/Settings/Settings.css';
import '../../Views/Administration/Administration.css';
import './DialogGroupEdit.css'

const factoryUUID = new UUID();
let currentField = {
    id:null,
    lastChange:null,
    value:null
};

const DialogGroupEdit = props => {

    const [editing, setEditing] = React.useState( ( props.pendingEdit ) ? props.pendingEdit : null );
    const [targetSelection, setTargetSelection] = React.useState( ( props.pendingEdit ) ? props.pendingEdit?.selection : [] );
    const [isSelecting, setIsSelecting] = React.useState( false );
    let modeSelectionClearDelegate = null;

    const handleCancel = () => {
        if( modeSelectionClearDelegate ) {
            modeSelectionClearDelegate();
            modeSelectionClearDelegate = null;
            setTargetSelection( [] );
        } else if ( isSelecting ) {
            setIsSelecting( false );
        } else {
            props.handleClose();
            setEditing(null);
        }
    };

    const handleOk = () => {
        if( modeSelectionClearDelegate ) {
            modeSelectionClearDelegate();
            modeSelectionClearDelegate = null;
        }

        if ( isSelecting ) {
            props.pendingEdit.selection = targetSelection;
            setIsSelecting( false );
        } else {
            buildSelection();
            if( props.pendingEdit.id > 0 ) {
                props.handleClose( "edit" , editing );
            } else {
                props.handleClose( "add" , editing );
            }
            setEditing(null);
        }
    };

    const handleInputChange = ( evt , property ) => {
        currentField = {
            id:`user-edited-${property}`,
            lastChange:new Date(),
            value:evt.target.value,
            property:property
        }

        setTimeout( () => {
            if( currentField.id ) {
                if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) {
                    props.pendingEdit[currentField.property] = currentField.value;
                    editing[currentField.property] = currentField.value;
                    currentField = {
                        id:null,
                        lastChange:null,
                        value:null,
                        property:null
                    };
                }
            }
        } , 5000 );

        let clone = Object.assign( {} , editing );
        clone[currentField.property] = currentField.value;
        props.pendingEdit[currentField.property] = currentField.value;
        setEditing( clone );
    }

    const handleInputBlur = ( evt ) => {
        if( currentField.id ) {
            props.pendingEdit[currentField.property] = currentField.value;
            editing[currentField.property] = currentField.value;
            currentField = {
                id:null,
                lastChange:null,
                value:null,
                property:null
            };
        }
    }

    const handleAddTarget = () => {
        setIsSelecting( true );
    }

    const handleSelection = selection => {
        props.pendingEdit.selection = selection;
        setTargetSelection( selection );
    };

    const handleModeSelectionActive = onCancel => {
        modeSelectionClearDelegate = onCancel;
    }

    const buildSelection = () => {
        editing.selection = [];
        targetSelection.forEach( selection => {
            editing.selection.push( {
                financial: ( editing.hasFinancial && editing.hasFinancial === true ) ? true : false,
                technical: ( editing.hasTechnical && editing.hasTechnical === true ) ? true : false,
                type: `${(selection.tag.startsWith('account-')) ? 'ACCOUNT' : 'BOX'}`,
                box: (!selection.tag.startsWith('account-')) ? parseInt( selection.tag.replace( 'box-' , '' ) , 10 ) : null,
                account: (selection.tag.startsWith('account-')) ? parseInt( selection.tag.split( '-' )[1] , 10 ) : null
            } );
        } );
    }

    const getHelperTextName = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.name && props.pendingEdit.name.trim() !== "" ) {
                return null;
            } else {
                return I18n.get("Required field");
            }
        }

        return null;
    }

    const isValidName = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.name && props.pendingEdit.name.trim() !== "" ) {
                return true;
            }
        }
        return false;
    }

    const isSubmitDisabled = () => {
        const hasNoSelection = targetSelection.length < 1;
        const hasNoAutorisation = ! props.pendingEdit.hasFinancial && ! props.pendingEdit.hasTechnical;
        return !isSelecting && ( ! isValidName() || hasNoSelection || hasNoAutorisation );
    };

    const renderForm = () => {
        if(  isSelecting ) {
            return renderTargetSelection();
        }

        return (
            <div className="text-center p-3">
                <div className="group-data">
                    <div className="user-data-item id">
                        <TextField
                            variant="outlined"
                            id={`user-edited-id`}
                            label={I18n.get("Id")}
                            placeholder={I18n.get("New")}
                            defaultValue={idIfNotNew}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </div>
                    <div className="user-data-item name">
                        <TextField
                            required
                            error={!isValidName()}
                            helperText={getHelperTextName()}
                            variant="outlined"
                            id={`user-edited-name`}

                            label={I18n.get("Name")}
                            placeholder={I18n.get("Not Specified")}
                            defaultValue={nameIfExists}
                            InputProps={{
                                readOnly: false,
                            }}
                            onChange={(evt) => handleInputChange( evt , "name" ) }
                            onBlur={(evt) => handleInputBlur( evt ) }
                        />
                    </div>
                    <div className="user-data-item target">
                        {renderTargetSelection()}
                    </div>
                    <div className="user-data-item type">
                        {renderTypeSelection()}
                    </div>
                </div>
            </div>
        );
    }

    const renderTargetSelection = () => {
        if( targetSelection.length !== props.pendingEdit?.selection?.length ||
            ( targetSelection.length === props.pendingEdit?.selection?.length && JSON.stringify( targetSelection ) !== JSON.stringify( props.pendingEdit?.selection ) ) ) {
            setTargetSelection( props.pendingEdit?.selection );
        }

        if(  isSelecting ) {
            return (
                <GuidedSelection API={props.API}
                                 user={props.user}
                                 defaultValue={targetSelection}
                                 notifySelection={handleSelection}
                                 payStations={props.filteredPayStations}
                                 hideBoxesSelection={false}
                                 onModeSelection={handleModeSelectionActive}/>
            );
        } else {
            if( targetSelection && targetSelection.length > 0 ) {
                return (
                    <div className="material-container">
                        <div className="container-title">{I18n.get('Your selection')} : </div>
                        <div className={`container-content  ${(props.payStations?.length === 1) ? '' : 'clickable'}`}
                             onClick={ () => {
                                 setIsSelecting( true );
                             } }>
                            {targetSelection.map( item => <div key={factoryUUID.generate()}>{item.name}</div> )}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="material-container">
                        <div className="container-title">{I18n.get('Your selection')} : </div>
                        <div className="container-content">
                            {I18n.get("No selection")}
                            <IconButton variant="text"
                                        className="Task-Icon"
                                        disableRipple
                                        onClick={handleAddTarget} >
                                <FontAwesomeIcon icon={["fas" , "plus-circle"]} />
                            </IconButton>
                            {I18n.get("Add")}
                        </div>
                    </div>
                );
            }
        }
    }

    const renderTypeSelection = () => {
        return (
            <div className="material-container">
                <div className="container-title">{I18n.get('Data type allowed')} : </div>
                <div className={`container-content`}>
                    {renderTypeSelectionItem( 'hasFinancial' , I18n.get('Financial data') )}
                    {renderTypeSelectionItem( 'hasTechnical' , I18n.get('Technical data') )}
                </div>
            </div>
        );
    }

    const renderTypeSelectionItem = ( key , label ) => {
        const isActive = props.pendingEdit[key];
        return (
            <div className={`data-type-line`}>
                <div className={`label`}>
                    {label} :
                </div>
                <div className={`selector`}>
                    <Button className={`${( isActive ) ? 'selected' : ''} selection-button`}
                            key={factoryUUID.generate()}
                            onClick={ evt => {
                                handleInputChange( {target: { value: !isActive }} , key );
                                handleInputBlur( evt );
                            }}> {( isActive ) ? I18n.get('Yes') : I18n.get('No')} </Button>

                </div>
            </div>
        );
    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";

    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.pendingEdit ) {
        return null;
    }

    if( ! editing ) {
        setEditing( props.pendingEdit );
    }

    const idIfNotNew = ( props.pendingEdit && props.pendingEdit.id ) ? props.pendingEdit.id : null;
    const nameIfExists = ( props.pendingEdit && props.pendingEdit.name ) ? props.pendingEdit.name : "";
    const titleKey = ( props.pendingEdit && props.pendingEdit.isNew ) ? "Group creation" : "Group modification";
    return (
        <Dialog disableEscapeKeyDown
                className={css}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.pendingEdit)}>
            <DialogTitle id="group-dialog-title">{I18n.get( titleKey )}</DialogTitle>
            <DialogContent dividers>
                {renderForm()}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} >
                    {I18n.get("CANCEL")}
                </Button>
                <Button onClick={handleOk} disabled={isSubmitDisabled()}>
                    {I18n.get("OK")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogGroupEdit;
