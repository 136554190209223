import Device from './Device';

class UserDevices {
	constructor( list , currentDevice ) {
		this.devices = [];
		if( list !== null && list !== undefined ) {
			if( !( list instanceof UserDevices) ) {
				list.map( ( deviceData ) => {
					if( deviceData ) {
						let device = Device.fromJson( deviceData );
						if( this.shouldRegister( device ) ) {
							this.devices.push( device );
						}
					}
					return true;
				} );
			}
		}
		
		if( currentDevice ) {
			this.current = Device.fromJson( currentDevice );
			if( this.shouldRegister( this.current ) ) {
				this.devices.push( this.current );
			}
		}
	}

	updateCurrent( ip , countryTag ) {
		if( this.current ) {
			this.current.ip = ip;
			this.current.countryTag = countryTag;
			this.devices.forEach( registeredDevice => {
				if( registeredDevice ) {
					if( registeredDevice.equals( this.current ) ) {
						registeredDevice.ip = ip;
						registeredDevice.countryTag = countryTag;
					}
				}
			} );
		}
	}
	
	removeCurrent() {
		let newDevicesSelection = [];
		this.devices.forEach( (registeredDevice) => {
			if( registeredDevice ) {
				if( ! registeredDevice.equals( this.current ) ) {
					newDevicesSelection.push( registeredDevice );
				}
			}
		} );
		this.devices = newDevicesSelection;
	}

	shouldRegister( device ) {
		let shouldRegister = true;
		this.devices.forEach( registeredDevice => {
			if( registeredDevice ) {
				if( registeredDevice.equals( device ) ) {
					shouldRegister = false;
				}
			}
			return true;
		} );
		return shouldRegister;
	}
	
	toJson() {
		return this.devices;
	}
}

export default UserDevices;
