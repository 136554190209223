import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Thermometer.css';

export default function Thermometer( props ) {
	return( 
		<React.Fragment>
			<div className={`thermometer-root`}>
				<FontAwesomeIcon icon={['fas', 'thermometer-half']} className="display-4 mx-2" /> {`${( props.value ) ? props.value : ""}`}
			</div>
		</React.Fragment>
	);
}
