import React from 'react';
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";
import './NoDataPlaceholder.css';

const factoryUUID = new UUID();
export default function NoDataPlaceholder( props ) {
	const id = factoryUUID.generate();

	let noDataPlaceholderCss = "no-data-placeholder";

	if( props.shown ) {
		noDataPlaceholderCss += " shown";
	} 
	
	if( props.className ) {
		noDataPlaceholderCss += " " + props.className ;
	}
	
	return (
		<React.Fragment>
			<div key={id} className={noDataPlaceholderCss}>
				{I18n.get("No available data")}
			</div>
		</React.Fragment>
	);
}
