import React from 'react';
import {getDetailedAccounts} from "../../../graphql/queries";
import GraphQlTool from "../../../Utils/GraphQlTool";
import SyncIndicator from "../SyncIndicator/SyncIndicator";
import {I18n} from "aws-amplify/utils";
import Button from "@mui/material/Button";
import './GuidedSelection.css';
import UUID from "../../../Utils/UUID";
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import PayStationFinder from "../../../Utils/PayStationFinder";
import {UserRoles} from "../../../Models/Roles";

const factoryUUID = new UUID();
const GuidedSelection = props => {
    const Modes = {
        RESELLERS: "RESELLERS",
        CUSTOMERS: "CUSTOMERS",
        PAY_STATIONS: "PAY_STATIONS",
        FILTERS: "FILTERS",
    };
    const ADMIN = 1;
    const SUPPORT = 2;
    const RESELLER = 4;
    const CUSTOMER = 5;
    const configAnalyzer = new ConfigAnalyzer( props.payStations );
    const finder = new PayStationFinder( props.payStations );
    const resolveModeOnEdit = () => {
        if( props.defaultValue && props.defaultValue.length > 0 ) {
            const sample = props.defaultValue[0].tag;
            if( sample.startsWith('account-') && sample.endsWith('-reseller') ) {
                return Modes.RESELLERS;
            } else if( sample.startsWith('account-') && sample.endsWith('-customer') ) {
                return Modes.CUSTOMERS;
            } else if( sample.startsWith('box-') ) {
                return Modes.PAY_STATIONS;
            } else {
                return Modes.FILTERS;
            }
        }
        return null;
    };

    const [selection , setSelection] = React.useState( ( props.defaultValue ) ? props.defaultValue : [] );
    const [accounts, setAccounts] = React.useState( null );
    const [mode, setMode] = React.useState( resolveModeOnEdit() );

    const user = ( props.user?.accountId ) ? props.user : props.user?.observer?.currentUser;

    const extractCompanyShopLabel = fullLabel => {
        const firstIndex = fullLabel.indexOf('|');
        if (firstIndex === -1) {
            return fullLabel;
        }

        const secondIndex = fullLabel.indexOf('|', firstIndex + 1);
        if (secondIndex === -1) {
            return fullLabel;
        }

        return fullLabel.substring(0, secondIndex);
    }

    const loadAccounts = () => {
        if( props.API && user.accountId ) {
            props.API
                .graphql({ query: getDetailedAccounts, variables: { accountId: user.accountId }})
                .then( returned => {
                    const tool = new GraphQlTool( 'getDetailedAccounts' , returned );
                    setAccounts( tool.extract() );

                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    const clearModeSelection = () => {
        setMode( null );
    }

    const handleSelection = ( tag , name ) => {
        const copy = JSON.parse( JSON.stringify( selection ) );
        const index = copy.findIndex( candidate => candidate.tag === tag );
        if (index !== -1) {
            copy.splice(index, 1);
        } else {
            copy.push( {tag: tag, name: name} );
        }
        setSelection( copy );
        props.notifySelection( copy );
    }

    const renderAvailableChoices = () => {
        return (
            <div className={`available-choices`}>
                {renderResellerChoice()}
                {renderCustomerChoice()}
                {renderPayStationListChoice()}
                {renderFilterChoice()}
            </div>
        );
    }

    const renderResellerChoice = () => {
        const allowed = [ADMIN , SUPPORT , RESELLER];
        if( allowed.includes( user.accountType )  &&
            accounts?.some( account => account.type === RESELLER && account.id !== user.accountId ) ) {
            return (
                <Button className={`mode-button`}
                        disabled={user.role === UserRoles.SALE_ROLE}
                        onClick={ () => {
                            setMode( Modes.RESELLERS );
                            props.onModeSelection( clearModeSelection );
                        }}>
                    {I18n.get('Select a reseller')}
                </Button> );
        }
        return null;
    }

    const renderCustomerChoice = () => {
        const allowed = [ADMIN , SUPPORT , RESELLER];
        if( allowed.includes( user.accountType )  &&
            accounts?.some( account => account.type === RESELLER && account.id !== user.accountId ) ) {
            return (
                <Button className={`mode-button`}
                        disabled={user.role === UserRoles.SALE_ROLE}
                        onClick={ () => {
                            setMode( Modes.CUSTOMERS ) ;
                            props.onModeSelection( clearModeSelection );
                        }}>
                    {I18n.get('Select a customer')}
                </Button> );
        }
        return null;
    }

    const renderPayStationListChoice = () => {
        if( props.hideBoxesSelection === true ) {
            return null;
        }

        const allowed = [ADMIN , SUPPORT , RESELLER , CUSTOMER];
        if( allowed.includes( user.accountType )  &&
            ( ( accounts?.some( account => ( account.type === RESELLER || account.type === CUSTOMER ) && account.id !== user.accountId ) ) ||
            ( props.payStations?.length > 0 ) ) ) {
            return (
                <Button className={`mode-button`}
                        onClick={ () => {
                            setMode( Modes.PAY_STATIONS );
                            props.onModeSelection( clearModeSelection );
                        }}>
                    {I18n.get('Select a pay station list')}
                </Button> );
        }
        return null;
    }

    const renderFilterChoice = () => {
        return null; //for now hide it in v1
        /*if( props.user?.userProperties?.filters?.length > 0 ) {
            return (
                <Button className={`mode-button`}
                        onClick={ () => {
                            setMode( Modes.FILTERS );
                            props.onModeSelection( clearModeSelection );
                        }}>
                    {I18n.get('Select a filter')}
                </Button> );
        }
        return null;*/
    }

    const renderResellerSelection = () => {
        const resellers = accounts?.filter( account => account.type === RESELLER );
        return (
            <div className="material-container">
                <div className="container-title">{I18n.get('Select a reseller')} : </div>
                <div className="container-content">
                    {resellers?.map( reseller => <Button className={`${(selection.some( candidate => candidate.tag === `account-${reseller.id}-reseller`)) ? 'selected' : ''} selection-button`}
                                                         key={factoryUUID.generate()}
                                                         onClick={ () => {
                                                             handleSelection( `account-${reseller.id}-reseller` , reseller.name );
                                                        }}> {reseller.name} </Button> )}
                </div>
            </div>
        );
    }

    const renderCustomerSelection = () => {
        const customers = accounts?.filter( account => account.type === CUSTOMER );
        return (
            <div className="material-container">
                <div className="container-title">{I18n.get('Select a customer')} : </div>
                <div className="container-content">
                    {customers?.map( customer => <Button className={`${(selection.some( candidate => candidate.tag === `account-${customer.id}-customer`)) ? 'selected' : ''} selection-button`}
                                                         key={factoryUUID.generate()}
                                                         onClick={ () => {
                                                             handleSelection( `account-${customer.id}-customer` , customer.name );
                                                         }}> {customer.name} </Button> )}
                </div>
            </div>
        );
    }

    const renderPayStationListSelection = () => {
        if( props.hideBoxesSelection === true ) {
            return null;
        }

        const activePayStations = configAnalyzer.getDistinctActiveBoxCBMSList();

        return (
            <div className="material-container">
                <div className="container-title">{I18n.get('Select a pay station list')} : </div>
                <div className="container-content">
                    {props.payStations?.map( payStation => renderOptionalBoxButton( payStation , activePayStations ) )}
                </div>
            </div>
        );
    }

    const renderOptionalBoxButton = ( payStation , activePayStations ) => {
        if( activePayStations.includes( `4_${payStation.box.informations?.bms}` ) ) {
            const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
            const boxExtraData = extractCompanyShopLabel( finder.buildTerminalExtraData( terminal ) );
            return (
                <Button className={`${(selection.some( candidate => candidate.tag === `box-${payStation?.box?.id}`)) ? 'selected' : ''} selection-button`}
                        key={factoryUUID.generate()}
                        onClick={ () => {
                            handleSelection( `box-${payStation?.box?.id}`, `${ ( payStation?.box?.informations?.attributes?.name ) ? payStation?.box?.informations?.attributes?.name : payStation?.box?.name }` );
                        }}>
                    <div className={`name`}>{`${ ( payStation?.box?.informations?.attributes?.name ) ? payStation?.box?.informations?.attributes?.name : payStation?.box?.name }`} </div>
                    <div className={`extra-info`}>{boxExtraData}</div>

                </Button>
            );
        } else {
            console.log( 'Exclude' , `4_${payStation.box.informations?.bms}` );
        }
        return null;
    }

    const renderFilterSelection = () => {
        return (
            <div className="material-container">
                <div className="container-title">{I18n.get('Select a filter')} : </div>
                <div className="container-content">
                    <div> Prepare the select </div>
                </div>
            </div>
        );
    }

    if( ! accounts ) {
        loadAccounts();
        return (<SyncIndicator loading={true}/>);
    }

    if( props.defaultValue && props.defaultValue.length > 0 ) {
        props.onModeSelection( clearModeSelection );
    }

    if( ! mode ) {
        props.onModeSelection( null );
        return renderAvailableChoices();
    } else {
        switch ( mode ) {
            case Modes.RESELLERS : return renderResellerSelection();
            case Modes.CUSTOMERS : return renderCustomerSelection();
            case Modes.PAY_STATIONS : return renderPayStationListSelection();
            case Modes.FILTERS : return renderFilterSelection();
            default: throw new Error( `Unsupported mode [${mode}]` );
        }
    }
};

export default GuidedSelection;
