const Features = {
    FLAGS: {
        INVENTORY_IDENTITY: false,
        ADMIN_DIAGNOSTIC: false,
        ADMIN_SYSTEM_INFO: false,
    },
    identifyFeaturesAccess( cognitoId ) {
        if( cognitoId === '91b9a0fe-7041-7032-3bf6-ee3a150fb8c4' ) {
            return {
                INVENTORY_IDENTITY: true,
                ADMIN_DIAGNOSTIC: true,
                ADMIN_SYSTEM_INFO: true,
            };
        }
        return Features.FLAGS;
    }
};

export default Features;
