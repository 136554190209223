import { v4 as uuidv4 } from 'uuid';

class Recipient {
	constructor( address ) {
		this.id = uuidv4();
		this.emailAddress = "";
		this.locale = "FR";
		
		if( address !== null && address !== undefined ) {
			this.emailAddress = address;
		}
	}
	
	equals( anotherTask ) {
		return this.id === anotherTask.id;
	}
	
	static fromJson( jsonObject ) {
		let recipient = new Recipient( jsonObject.emailAddress );
		recipient.id = jsonObject.id;
		recipient.locale = jsonObject.locale;

		return recipient;
	}
	
	toJson() {
		return {
			id: this.id,
			emailAddress: this.emailAddress,
			locale: this.locale,
		};
	}
}

export default Recipient;
