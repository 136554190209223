import React from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import html2canvas from 'html2canvas';
import InputRecipients from '../InputRecipients/InputRecipients';
import * as queries from '../../../graphql/queries';
import translations from '../../../AWS/i18n/Translations';
import {sendEmailWithAttachment} from '../../../graphql/mutations';
import './Menu.css';
import ListItemButton from "@mui/material/ListItemButton";

let printRequired = false;
let emailRequired = false;
let currentOutputFormat;
let availableRecipients = [];

export default function MenuAction(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorExportEl, setAnchorExportEl] = React.useState(null);
	const [anchorSendEl, setAnchorSendEl] = React.useState(null);
	const [openDialogPdf, setOpenDialogPdf] = React.useState(false);
	const [openDialogRecipients, setOpenDialogRecipients] = React.useState(false);
	const [expanded, setExpanded] = React.useState(false);
	const [selectedRecipients, setSelectedRecipients] = React.useState([]);
	const REFRESH = "refresh";
	const PRINT = "print";
	const EXPORT = "export";
	const SEND = "send";
	const CONFIG = "config";
	const EXPORTABLE_TABLE = "#exportable-table";
	const EXPORTABLE_GRAPHIC = "#exportable-graphic";
	const PDF = "PDF";
	const CSV = "CSV";
	const PNG = "PNG";
	const SVG = "SVG";
	
	const navigateTo = idNav => {
		printRequired = false;
		emailRequired = false;
		currentOutputFormat = null;
		switch ( idNav ) {
			case REFRESH: 
				if( props.onRefreshRequested ) {
					props.onRefreshRequested();
				}
			break;
			case PRINT: 
				printRequired = true;
				handleClickOpenDialogPdf();
			break;
			case SEND:
				emailRequired = true;
				handleClickOpenDialogPdf();
				break;
			case EXPORT:
				handleClickOpenDialogPdf();
			break;
			case CONFIG: props.handleListItemClick( null , 12 );
			break;
			default :
			break;
		}
	};
	
	const exportAS = ( outputFormat , shouldSend ) => {
		printRequired = false;
		emailRequired = false;
		currentOutputFormat = null;
		if( shouldSend ) {
			emailRequired = true;
			currentOutputFormat = outputFormat;
			availableRecipients = props.observer.state.user.getAvailableRecipientAddresses();
			setSelectedRecipients([]);
			handleClickOpenDialogRecipients();
		} else {
			generateExport( outputFormat );
		}
	}
	
	const generateExport = outputFormat => {
		switch( outputFormat ) {
			case PDF: handleClickOpenDialogPdf();
			break;
			case CSV: generateCsv();
			break;
			case PNG: generatePng();
			break;
			case SVG:
			break;
			default:
			break;
		}
	}
	
	const extractSelectedTabLabel = () => {
		let selectedTabLabel = "";
		let tabBar = document.getElementsByClassName("MuiTabs-flexContainer")[0];
		if( tabBar ) {
			for( let i in tabBar.childNodes ) {
				let child = tabBar.childNodes[i];
				if( child &&
					( child.tagName === "button" || child.tagName === "BUTTON" ) ) {
					if( child.classList.contains("Mui-selected") )	{
						selectedTabLabel = child.textContent;
						if( selectedTabLabel.startsWith("<svg") ) {
							selectedTabLabel = selectedTabLabel.replace(/<svg .*svg>/i , "");
						}
					}
				}
			}
		}
		return selectedTabLabel;
	}
	
	const generateFilename = type => {
		let now = new Date();
		return ( getTitle() + "_" +
					extractSelectedTabLabel() + "_" +
					now.toLocaleDateString() + "_" +
					now.toLocaleTimeString() + "." +
					type.toLowerCase() ).replace(" " , "_");
	}
	
	const generatePdf = type => {
		let loader = document.querySelector("#modal-loader");
		loader.className = "loading";
		
		switch( type ) {
			case 0: //fallthrough
			case 1:
			case 2:
			break;
			default:
				throw new Error('Unsupported generation type for PDF only allowed : [0: table only, 1:graphic only, 2: both]');
		}
		
		//PDF from lambda
		getInput( type , PDF )
		.then( input => props.observer.props.API.graphql({ query: queries.generateDoc, variables:{ input : input } } ) )
		.then( onPdfGenerated )
		.catch( error => {
			console.error( error );
			loader.className = "";				
		} );
	}

	const getInput = ( type , format ) => {

		const separatorIndex = props.observer.locale.indexOf("-");
		const cleanLocale = ( separatorIndex > 0 ) ? props.observer.locale.substring( 0 , separatorIndex ) : props.observer.locale;

		//input shared part
		let input = {
			title: props.observer.getAppTitle( true ),
			subTitle: props.observer.pdfDataDelegate.subTitle,
			extraLabel: props.observer.pdfDataDelegate.extraLabel,
			inventoryDate: ( props.selectedIndex === 5 ) ? props.observer.pdfDataDelegate.inventoryDate : null,
			startLocalizedDate: props.observer.pdfDataDelegate.startLocalizedDate,
			endLocalizedDate: props.observer.pdfDataDelegate.endLocalizedDate,
			dictionary: JSON.stringify( translations[ cleanLocale ] ),
			handler: props.observer.pdfDataDelegate.handler,
			columnDefinition: JSON.stringify( props.observer.pdfDataDelegate.columnDefinition ),
			hasTable:type === 0 || type === 2,
			hasGraphic:type === 1 || type === 2,
			user: props.observer?.props?.user?.username,
			format:format,
			withHeader:true
		} ;
		
		return new Promise((resolve, reject) => {
			if( type > 0 ) {
				let dashboard = document.querySelector("#Dashboard-Root");
				dashboard.className += " Printing";
				const domElement = document.querySelector( EXPORTABLE_GRAPHIC );
				
				html2canvas(domElement).then(canvas => {
					let graphicBase64Img = canvas.toDataURL('image/jpeg');
					dashboard.className = dashboard.className.replace(" Printing", "");
					if( props.observer.pdfDataDelegate.hasOwnProperty( "lines" ) ) {
						input.data = `{"list": ${JSON.stringify(props.observer.pdfDataDelegate.lines)},"graphic":"${graphicBase64Img}"}`;
					} else {
						//as map
						props.observer.pdfDataDelegate.map.graphic = graphicBase64Img;
						input.data = JSON.stringify( props.observer.pdfDataDelegate.map );
					}

					resolve( input );
				});

			} else {
				if( props.observer.pdfDataDelegate.hasOwnProperty( "lines" ) && props.observer.pdfDataDelegate.lines.length > 0 ) {
					input.data = `{"list": ${JSON.stringify(props.observer.pdfDataDelegate.lines)}}`;
				} else {
					//as map
					input.data = JSON.stringify( props.observer.pdfDataDelegate.map );
				}

				resolve( input );
			} 
		});
	}

	const onPdfGenerated = results => {
		const base64PDF = results.data.generatePDF;
		if( base64PDF ) {
			if( printRequired ) {
				//Open in a new tab and trigger the print dialog pop up opening
				let byteCharacters = atob(base64PDF);
				let byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
				  byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				let byteArray = new Uint8Array(byteNumbers);
				let file = new Blob([byteArray], { type: 'application/pdf;base64' });
				let fileURL = URL.createObjectURL(file);
				const newTab = window.open(fileURL);
				newTab.print();
				
			} else if ( emailRequired ) {
				sendEmail( [JSON.stringify( {filename:generateFilename(PDF) , encoding:"base64" , description:I18n.get("Manual email subject"), content:base64PDF} )] );
			} else {
				//create and trigger the click on a download link
				let a = document.createElement("a");
				a.href = "data:application/octet-stream;base64,"+base64PDF;
				a.download = generateFilename(PDF);
				a.click();
			}
			
		} else {
			console.error("Unable to generate PDF");
		}
		let loader = document.querySelector("#modal-loader");
		loader.className = "";
	}

	const generateCsv = () => {
		let loader = document.querySelector("#modal-loader");
		loader.className = "loading";
		
		//CSV from lambda
		getInput( 0 , CSV )
		.then( (input) => props.observer.props.API.graphql({ query: queries.generateDoc, variables:{ input : input } } ) )
		.then( onCsvGenerated )
		.catch( (error) => { 
			console.error( error );
			loader.className = "";				
		} );
	}

	const onCsvGenerated = results => {
		const base64CSV = results.data.generatePDF;
		if( emailRequired ) {
			sendEmail( [JSON.stringify( {filename:generateFilename(CSV) , encoding:"base64" , description:I18n.get("Manual email subject"), content:base64CSV} )] );
		} else {
			//let csvContent = atob( base64CSV );	<= encoding issue on utf-8

			let csvContent = decodeURIComponent( atob( base64CSV ).split('').map( function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			
		

													//BOM : Byte-order-mark
			/*let csvContent = "data:attachment/csv;charset=utf-8,%EF%BB%BF"
				+ encodeURI( atob( base64CSV ) );*/
			
			let blob = new Blob([csvContent], {type: "data:application/octet-stream;base64"});
			//let blob = new Blob([csvContent], {type: "data:attachment/csv;charset=utf-8,%EF%BB%BF"});//BOM : Byte-order-mark	
			//let blob = new Blob(['\ufeff' + csvContent], {type: 'text/csv;charset=utf-8'});
			let url  = window.URL.createObjectURL( blob );

			let a = document.createElement('a');
			a.href = url;
			a.download = generateFilename(CSV);
			a.click();
		}
		
		let loader = document.querySelector("#modal-loader");
		loader.className = "";
	}

	const generatePng = () => {
		let loader = document.querySelector("#modal-loader");
		loader.className = "loading";
		let dashboard = document.querySelector("#Dashboard-Root"); 
		dashboard.className += " Printing";
		html2canvas( document.querySelector("#exportable").parentNode ).then( canvas => {
			dashboard.className = dashboard.className.replace(" Printing" , "" );  
			if( emailRequired ) {
				if( emailRequired ) {
					sendEmail( [JSON.stringify( {filename:generateFilename(PNG) , encoding:"base64" , description:I18n.get("Manual email subject"), content:canvas.toDataURL().split(';base64,')[1]} )] );
				}
			} else {
				var a = document.createElement('a');
				a.href = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
				a.download = generateFilename(PNG);
				a.click();
			}
			loader.className = "";
		} );
	}

	const sendEmail = attachments => {
		const loader = document.querySelector("#modal-loader");
		props.observer.props.API.graphql({ query: sendEmailWithAttachment, variables:{ input : {
			recipients: selectedRecipients, 
			subject: I18n.get("Manual email subject"), 
			body: JSON.stringify({
				html:"<div>"+ I18n.get("Manual email body") +" "+ props.observer.currentUser.email +"</div>",
				plainText: I18n.get("Manual email body") +" "+ props.observer.currentUser.email,
				reportName: props.observer.getAppTitle( true ),
				boxExtraData: props.observer.pdfDataDelegate.data.boxExtraData,
				date: ( props.selectedIndex === 5 ) ? props.observer.pdfDataDelegate.inventoryDate : new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
			}),
			attachments:attachments,
			username:props.observer.currentUser.email
		} } } ).then( (results) => { console.log( results ); } ) 
		.catch( (error) => { 
			console.error( error );
			loader.className = "";				
		} );
	}

	const onRecipientsSelected = () => {
		generateExport( currentOutputFormat );
	}

	const getTitle = () => {
		let selector;
		switch ( props.selectedIndex ) {
			case 4: selector = I18n.get("Cash in");;
				break;
			case 5: selector = I18n.get("Inventories");
				break;
			case 6: selector = I18n.get("Messages");
				break;
			case 8: selector = I18n.get("Comparisons");
				break;
			case 9: selector = I18n.get("Operations");
				break;
			case 10: selector = I18n.get("Distributions");
				break;
			default : selector = "";
			break;
		}
		return selector;
	}

	const getPdfDialogTitle = () => {
		if( printRequired ) {
			return I18n.get("Printing");
		}
		return I18n.get("PDF export");
	}

	const isDisabled = buttonType => {
		switch ( buttonType ) {
			case REFRESH: 
				return false;
			case PRINT: 
				return !( props.selectedIndex > 3 && props.selectedIndex < 11 && props.selectedIndex !== 7 /*&& props.selectedIndex !== 5*/ );
			case SEND:
				return !( props.selectedIndex > 3 && props.selectedIndex < 11 && props.selectedIndex !== 7 /*&& props.selectedIndex !== 5*/ );
			case EXPORT: 
				return !( props.selectedIndex > 3 && props.selectedIndex < 11 && props.selectedIndex !== 7  /*&& props.selectedIndex !== 5*/ );
			case CONFIG: 
				return false;
			default :return false;
		}
	}
	
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
		setExpanded( false ); 
	};
	
	const handleMobileClick = event => {
		setExpanded( true ); 
	};
	
	const handleExportClick = ( event , label ) => {
		if( label === "Export" ) {
			setAnchorExportEl(event.currentTarget);
		} else {
			setAnchorSendEl(event.currentTarget);
		}
	};
	
	const handleExportClose = () => {
		setAnchorExportEl(null);
		setAnchorSendEl(null);
	};

	const handleClickOpenDialogPdf = () => {
		/*if( props.selectedIndex !== 5 ) {
			setOpenDialogPdf(true);
		} else {
			//inventory PDF is only based on date skip choice for table only !!!
			generatePdf(0);
		}*/
		//inventory PDF is only based on date skip choice for table only !!!
		generatePdf(0);
	};
	
	const handleClickOpenDialogRecipients = () => {
		setOpenDialogRecipients(true);
	};
	
	const handleCloseDialogPdf = () => {
		setOpenDialogPdf(false);
	};
	
	const handleCloseDialogRecipients = () => {
		setOpenDialogRecipients(false);
	};
	
	const handleNestedListClick = () => {
		setExpanded( ! expanded ); 
	};
	
	const handleToggleRecipient = address => {
		let newRecipients = [];
		if( ! selectedRecipients.includes( address ) ) {
			newRecipients.push( address );
		} 
		selectedRecipients.forEach( candidate => {
			if( candidate !== address ) {
				newRecipients.push( candidate );
			}
		} );
		setSelectedRecipients( newRecipients );
	}
	
	const exportableExists = exportableID => {
		let div = document.querySelector( exportableID );
		return div !== null && div !== undefined;
	}
	
	const onNewRecipient = recipient => {
		let newRecipients = [];
		//add new if not exists
		if( ! selectedRecipients.includes( recipient ) ) {
			newRecipients.push( recipient );
		} 
		
		//add current selected excluding duplicate
		selectedRecipients.forEach( candidate => {
			if( candidate !== recipient ) {
				newRecipients.push( candidate );
			}
		} );
		setSelectedRecipients( newRecipients );
	}
	
	const onUpdatedRecipients = ( propertyId , recipients ) => {
		let newRecipients = [];
		
		//update from field
		recipients.forEach( ( recipient ) => {
			newRecipients.push( recipient.emailAddress );
		} );
		
		//add toggable selected
		availableRecipients.forEach(  candidate  => {
			if( selectedRecipients.includes( candidate ) ) {
				newRecipients.push( candidate );
			}
		} );
		
		setSelectedRecipients( newRecipients );
	}
	
	let css = "Menu-CashMonitor Action";
	let cssMenu = "p-0";
	let cssPopup = "dropdown-menu-md overflow-hidden p-3 Menu-CashMonitor-Popup";

	if( props.isDarkStyle ) {
		css += " dark";
		cssMenu += " dark";
		cssPopup += " dark";
	}
	
	const renderExportAsList = label => {
		return (
			<div>
				<Button variant="text"  
						className="Item hydrated" 
						disableRipple
						disabled={isDisabled(EXPORT)}
						onClick={handleNestedListClick} >
					{I18n.get( label )}
					{expanded ? <ExpandLess /> : <ExpandMore />}
				</Button>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<List component="div" disablePadding className="subList">
						<ListItemButton
								  onClick={(event) => {
										exportAS(PDF);
										handleClose();
									}}
								  className="">
							<ListItemText primary={I18n.get("Export as PDF")} disableTypography={true}/>
						</ListItemButton>
						<ListItemButton
								  onClick={(event) => {
										exportAS(CSV);
										handleClose();
									}}
								  className="">
							<ListItemText primary={I18n.get("Export as CSV")} disableTypography={true}/>
						</ListItemButton>
						<ListItemButton
								  onClick={(event) => {
										exportAS(PNG);
										handleClose();
									}}
								  className="">
							<ListItemText primary={I18n.get("Export as PNG")} disableTypography={true}/>
						</ListItemButton>
						<ListItemButton
								  onClick={(event) => {
										exportAS(SVG);
										handleClose();
									}}
								  className="">
							<ListItemText primary={I18n.get("Export as SVG")} disableTypography={true}/>
						</ListItemButton>
					</List>
				</Collapse>
			</div>
		);
	}
	
	const renderExportAsMenu = ( label , anchorEl ) => {
		if( props.isDesktop ) {
			let icon;
			if( Boolean(anchorEl) ) {
				icon = "chevron-down";
			} else {
				icon = "chevron-right";
			}
			return (
				<div>
					<Button variant="text"  
							className="Item hydrated" 
							disableRipple
							disabled={isDisabled(EXPORT)}
							onClick={( event ) => handleExportClick( event , label )} >
						{I18n.get( label )}
						<FontAwesomeIcon icon={["fas", icon]} className="ml-3 display-5" />
					</Button>
					<Menu anchorEl={anchorEl}
						  keepMounted
						  anchorOrigin={{
							  vertical: 'top',
							  horizontal: 'left',
						  }}
						  transformOrigin={{
							  vertical: 'top',
							  horizontal: 'right',
						  }}
						  open={Boolean(anchorEl)}
						  classes={{ list: cssMenu }}
						  onClose={handleExportClose} >
						<div className={cssPopup}>
							<div className="grid-menu grid-menu-1col">
								<Button variant="text"  
										className="Item hydrated" 
										disableRipple
										onClick={(evt) => {
												exportAS(PDF , label === "Send");
												handleExportClose();
												handleClose();
											}} >
									{(label === 'Send') ? I18n.get("Send as PDF") : I18n.get("Export as PDF") }
								</Button>
								<Button variant="text"  
										className="Item hydrated" 
										disableRipple
										onClick={(evt) => {
												exportAS(CSV , label === "Send");
												handleExportClose();
												handleClose();
											}} >
									{(label === 'Send') ? I18n.get("Send as CSV") : I18n.get("Export as CSV") }
								</Button>
								<Button variant="text"  
										className="Item hydrated" 
										disableRipple
										onClick={(evt) => {
												exportAS(PNG , label === "Send");
												handleExportClose();
												handleClose();
											}} >
									{(label === 'Send') ? I18n.get("Send as PNG") : I18n.get("Export as PNG") }
								</Button>
								{/*<Button variant="text"  
										className="Item hydrated" 
										disableRipple
										onClick={(evt) => {
												exportAS(SVG);
												handleExportClose();
												handleClose();
											}} >
									{I18n.get("Export as SVG")}
								</Button>*/}
							</div>
						</div>
					</Menu>
				</div>
			);
		}
		
		return (
			<div>
				<Button variant="text"  
						className="Item hydrated" 
						disableRipple
						disabled={isDisabled(EXPORT)}
						onClick={handleMobileClick} >
					{I18n.get("Export")}
					<FontAwesomeIcon icon={["fas","chevron-right"]} className="ml-3 display-5" />
				</Button>
				<Menu anchorEl={anchorEl}
					  keepMounted
					  anchorOrigin={{
						  vertical: 'bottom',
						  horizontal: 'left',
					  }}
					  transformOrigin={{
						  vertical: 'top',
						  horizontal: 'right',
					  }}
					  open={Boolean(anchorEl) && expanded}
					  classes={{ list: cssMenu }}
					  onClose={handleClose} >
					<div className={cssPopup}>
						<div className="grid-menu grid-menu-1col">
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									onClick={(evt) => {
											exportAS(PDF);
											handleExportClose();
											handleClose();
										}} >
								{(label === 'Send') ? I18n.get("Send as PDF") : I18n.get("Export as PDF") }
							</Button>
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									onClick={(evt) => {
											exportAS(CSV);
											handleExportClose();
											handleClose();
										}} >
								{(label === 'Send') ? I18n.get("Send as CSV") : I18n.get("Export as CSV") }
							</Button>
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									onClick={(evt) => {
											exportAS(PNG);
											handleExportClose();
											handleClose();
										}} >
								{(label === 'Send') ? I18n.get("Send as PNG") : I18n.get("Export as PNG") }
							</Button>
							{/*<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									onClick={(evt) => {
											exportAS(SVG);
											handleExportClose();
											handleClose();
										}} >
								{I18n.get("Export as SVG")}
							</Button>*/}
						</div>
					</div>
				</Menu>
			</div>
		);
	}
	
	const renderExport = ( label , anchorEl ) => {
		if( props.displayAsList ) {
			return renderExportAsList( label );
		} else {
			return renderExportAsMenu( label , anchorEl );
		}
	}
	
	const renderDialogRecipient = () => {
		return (
			<Dialog className="cm-dialog-recipients-selection card-box" 
					onClose={handleCloseDialogRecipients} 
					aria-labelledby="simple-dialog-title" 
					open={openDialogRecipients}>
				<div className={css}>
					<div className="card-header">
						<div className="card-header--title">
							<b>{I18n.get("Recipients")} :</b>
						</div>
					</div>
					<div className="card-content">
						{renderRecipientsList()}
						{renderRecipientsField()}
						<Button variant="text"  
								className="Item hydrated submit" 
								disableRipple
								disabled={selectedRecipients.length < 1}
								onClick={(evt) => {
										onRecipientsSelected();
										handleCloseDialogRecipients();
									}} >
							{I18n.get("Send")}
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}
	
	const renderRecipientsList = () => {
		const toRender = [];
		const added = [];
		if( props?.observer?.currentUser?.email ) {
			toRender.push( renderRecipientToggleableBtn( props?.observer?.currentUser?.email ) );
			added.push( props.observer.currentUser.email );
		}
		availableRecipients.forEach( address => {
			if( ! added.includes( address ) ) {
				toRender.push( renderRecipientToggleableBtn( address ) );
				added.push( address );
			}
		} );
		return (
			<div className="recipients-list" >
				<div className="label" > {I18n.get("Favorite recipients")} : </div>
				<div className="field" > 
					{
						toRender.map( (item) => item )
					}
				</div>
			</div>
		);
	}
	
	const renderRecipientToggleableBtn = emailAddress => {
		if( selectedRecipients.includes( emailAddress ) ) {
			return (
				<Button variant="text" 
						key={`submit-recipients-${emailAddress}`}				
						className="Item hydrated toggable selected" 
						disableRipple
						onClick={(evt) => {
								handleToggleRecipient( emailAddress );
							}} >
					{emailAddress}
				</Button>
			);
		} else {
			return (
				<Button variant="text"
						key={`submit-recipients-${emailAddress}`}				
						className="Item hydrated toggable" 
						disableRipple
						onClick={(evt) => {
								handleToggleRecipient( emailAddress );
							}} >
					{emailAddress}
				</Button>
			);
		}
		
	}
	
	const renderRecipientsField = () => {
		return (
			<div className="other-recipients" >
				<InputRecipients id="recipients-send"
								 label={I18n.get("Other recipients") + " : "}
								 recipients={[]}
								 noLocale={true}
								 newEntryObserver={onNewRecipient}
								 onValueChanged={onUpdatedRecipients}
								 isDarkStyle={props.isDarkStyle} />
			</div>
		);
	}
	
	return (
		<React.Fragment>
			<div className={css}>
				<IconButton variant="text"  
							className="Icon" 
							disableRipple
							onClick={handleClick} >
					<FontAwesomeIcon icon={["fas" , "ellipsis-h"]} />
				</IconButton>
				<Menu anchorEl={anchorEl}
					  keepMounted
					  anchorOrigin={{
						  vertical: 'bottom',
						  horizontal: 'left',
					  }}
					  transformOrigin={{
						  vertical: 'top',
						  horizontal: 'right',
					  }}
					  open={Boolean(anchorEl) && !expanded}
					  classes={{ list: cssMenu }}
					  onClose={handleClose} >
					<div className={cssPopup}>
						<div className="grid-menu grid-menu-1col">
							{/*<Button variant="text"
									className="Item hydrated" 
									disableRipple
									disabled={isDisabled(REFRESH)}
									onClick={(evt) => {
											navigateTo(REFRESH);
											handleClose();
										}} >
								{I18n.get("Refresh data")}
							</Button>*/}
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									disabled={isDisabled(PRINT)}
									onClick={(evt) => {
											navigateTo(PRINT);
											handleClose();
										}} >
								{I18n.get("Print")}
							</Button>
							{renderExport( "Send" , anchorSendEl )}
							{renderExport( "Export" , anchorExportEl )}
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									disabled={isDisabled(CONFIG)}
									onClick={(evt) => {
											navigateTo(CONFIG);
											handleClose();
										}} >
								{I18n.get("Setting")}
							</Button>
						</div>
					</div>
				</Menu>
				<Dialog className="cm-dialog-pdf-generation card-box" 
						onClose={handleCloseDialogPdf} 
						aria-labelledby="simple-dialog-title" 
						open={openDialogPdf}>
					<div className={css}>
						<div className="card-header">
							<div className="card-header--title">
								<b>{getPdfDialogTitle()} :</b>
							</div>
						</div>
						<div className="card-content">
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									disabled={!exportableExists(EXPORTABLE_TABLE)}
									onClick={(evt) => {
											generatePdf(0);
											handleCloseDialogPdf();
										}} >
								{I18n.get("Table")}
							</Button>
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									disabled={!exportableExists(EXPORTABLE_GRAPHIC)}
									onClick={(evt) => {
											generatePdf(1);
											handleCloseDialogPdf();
										}} >
								{I18n.get("Graphic")}
							</Button>
							<Button variant="text"  
									className="Item hydrated" 
									disableRipple
									disabled={!(exportableExists(EXPORTABLE_TABLE) && exportableExists(EXPORTABLE_GRAPHIC) )}
									onClick={(evt) => {
											generatePdf(2);
											handleCloseDialogPdf();
										}} >
								{I18n.get("Table and Graphic")}
							</Button>
						</div>
					</div>
				</Dialog>
				{renderDialogRecipient()}
			</div>
		</React.Fragment>
	);
}
