
class LocaleHelper {	
	constructor() {
		this.oneHour = 1000*60*60;
		this.oneDay = this.oneHour * 24;
		this.oneWeek = this.oneDay * 6;
		this.locale = this.getLang();
		this.startingDay = this.weekStartLocale(this.locale);
	}
	
	getLang() {
		return navigator.language || navigator.browserLanguage || ( navigator.languages || [ "en" ] )[0];
	}
	
	weekStart(region, language) {
		const regionSat = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g);
		const regionSun = 'AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPTPYSASGSVTHTTTWUMUSVEVIWSYEZAZW'.match(/../g);
		const languageSat = ['ar','arq','arz','fa'];
		const languageSun = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'.match(/../g);

		return (
			region 
			? ( regionSun.includes(region) ? 'sun' : regionSat.includes(region) ? 'sat' : 'mon') 
			: ( languageSun.includes(language) ? 'sun' : languageSat.includes(language) ? 'sat' : 'mon')
		);
	}

	weekStartLocale(locale) {
		const parts = locale.match(/^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i);
		return this.weekStart(parts[4], parts[1]);
	}
	
	firstDayOfCurrentWeek() {
		let today = new Date();
		let delta;
		switch( this.startingDay ) {
			case "sun": //sunday 
				delta = 0;
			break;
			case "mon": //monday
				delta = 1;
			break;
			case "sat": //saturday
				delta = -1;
			break;
			default:
				delta = 0;
			break;
		}

		return new Date( today.getTime() - ( ( today.getDay() - delta ) * this.oneDay ) );
	}
	
	lastDayOfCurrentWeek() {
		return new Date( this.firstDayOfCurrentWeek().getTime() + this.oneWeek);
	}
	
	firstDayOfPastWeek( weekOnPast ) {
		let today = new Date( new Date().getTime() - ((7 * weekOnPast) * this.oneDay) );
		let delta;
		switch( this.startingDay ) {
			case "sun": //sunday 
				delta = 0;
			break;
			case "mon": //monday
				delta = 1;
			break;
			case "sat": //saturday
				delta = -1;
			break;
			default:
				delta = 0;
			break;
		}

		return new Date( today.getTime() - ( ( today.getDay() - delta ) * this.oneDay ) );
	}
	
	lastDayOfPastWeek( weekOnPast ) {
		return new Date( this.firstDayOfPastWeek(weekOnPast).getTime() + this.oneWeek );
	}
}

export default LocaleHelper;