export const getHopperByConfiguration = ( position , numMaterial ) => {
	if( numMaterial.includes( "_" ) ) {
		numMaterial = numMaterial.substring( 0 , 3 );
	}
	switch( numMaterial ) {
			case "EUR": //fall through
			case "48278": //Euro [EUR01]
				switch(position) {
					case "1": return { value:0.02 , realSize:"1", sortHole:null};
					case "2": return { value:0.10 , realSize:"2", sortHole:null};
					case "3": return { value:0.05 , realSize:"3", sortHole:null};
					case "4": return { value:0.20 , realSize:"4", sortHole:null};
					case "5": return { value:1 , 	realSize:"5", sortHole:null};
					case "6": return { value:0.50 , realSize:"6", sortHole:null};
					case "7": return { value:2 , 	realSize:"7", sortHole:null};
					case "8": return { value:0.01 , realSize:"8", sortHole:null};
					default: return null;
				}
			case "USD": //fall through
			case "44599":	//USA [USD01]
				switch(position) {
					case "2": return { value:0.01 , realSize:"2-1-3", sortHole:2};
					case "4": return { value:0.05 , realSize:"4", 	  sortHole:null};
					case "5": return { value:0.25 , realSize:"5-6",   sortHole:5};
					case "7": return { value:1 , 	realSize:"7", 	  sortHole:null};
					case "8": return { value:0.1 , 	realSize:"8", 	  sortHole:null};
					default: return null;
				}
			case "HRK": //fall through
			case "44601": //Croatia [HRK01]
				switch(position) {
					case "1": return { value:0.20 , realSize:"1", 	sortHole:null};
					case "2": return { value:0.10 , realSize:"2", 	sortHole:null};
					case "3": return { value:0.50 , realSize:"3", 	sortHole:null};
					case "4": return { value:1 , 	realSize:"4", 	sortHole:null};
					case "6": return { value:2 , 	realSize:"5-6", sortHole:6};
					case "7": return { value:5 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.05 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "GPB": //fall through
			case "47147":	//UK [GBP02]
				switch(position) {
					case "1": return { value:0.01 , realSize:"1", sortHole:null};
					case "2": return { value:0.20 , realSize:"2", sortHole:null};
					case "3": return { value:1 , 	realSize:"3", sortHole:null};
					case "4": return { value:0.10 , realSize:"4", sortHole:null};
					case "5": return { value:0.02 , realSize:"5", sortHole:null};
					case "6": return { value:0.50 , realSize:"6", sortHole:null};
					case "7": return { value:2 , 	realSize:"7", sortHole:null};
					case "8": return { value:0.05 , realSize:"8", sortHole:null};
					default: return null;
				}
			case "UAE": //fall through
			case "44558": //UAE [AED01]
				switch(position) {
					case "1": return { value:0.25 , realSize:"1-2", 	sortHole:1};
					case "3": return { value:0.50 , realSize:"3-4", 	sortHole:3};
					case "6": return { value:1 , 	realSize:"6-5-7", 	sortHole:6};
					default: return null;
				}
			case "43957":	//Mexico [MXN01]
				switch(position) {
					case "1": return { value:0.50 , realSize:"1", sortHole:null};
					case "2": return { value:1 , 	realSize:"2", sortHole:null};
					case "3": return { value:0.50 , realSize:"3", sortHole:null};
					case "4": return { value:2 , 	realSize:"4", sortHole:null};
					case "5": return { value:5 , 	realSize:"5", sortHole:null};
					case "6": return { value:10 , 	realSize:"6", sortHole:null};
					case "7": return { value:20 , 	realSize:"7", sortHole:null};
					case "8": return { value:0.20 , realSize:"8", sortHole:null};
					default: return null;
				}
			case "MXN": //fall through
			case "48518": //Mexico02 [MXN02]
				switch(position) {
					case "1": return { value:0.50 , realSize:"1", 	sortHole:null};
					case "2": return { value:1 , 	realSize:"2", 	sortHole:null};
					case "3": return { value:0.50 , realSize:"3", 	sortHole:null};
					case "4": return { value:2 , 	realSize:"4", 	sortHole:null};
					case "6": return { value:5 , 	realSize:"6-5", sortHole:6};
					case "7": return { value:10 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.20 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "THB": //fall through
			case "49287": //Thailand [THB01]
				switch(position) {
					case "1": return { value:0.50 , realSize:"1", 	sortHole:null};
					case "3": return { value:1 , 	realSize:"3-2", sortHole:3};
					case "4": return { value:2 , 	realSize:"4", 	sortHole:null};
					case "5": return { value:5 , 	realSize:"5-6", sortHole:5};
					case "7": return { value:10 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.25 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "DKK": //fall through
			case "48320": //Denmark [DKK01]
				switch(position) {
					case "1": return { value:1 , 	realSize:"1", 	sortHole:null};
					case "2": return { value:0.50 , realSize:"2", 	sortHole:null};
					case "3": return { value:10 , 	realSize:"3", 	sortHole:null};
					case "4": return { value:2 , 	realSize:"4", 	sortHole:null};
					case "6": return { value:20 , 	realSize:"6-5", sortHole:6};
					case "7": return { value:5 , 	realSize:"7-8", sortHole:7};
					default: return null;
				}
			case "CAD": //fall through
			case "44602": //Canada [CAD01]
				switch(position) {
					case "1": return { value:0.05 , realSize:"1-2", sortHole:1};
					case "3": return { value:0.25 , realSize:"3-4", sortHole:3};
					case "5": return { value:1 , 	realSize:"5-6", sortHole:5};
					case "7": return { value:2 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.10 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "TRY": //fall through
			case "48304": //Turkey [TRY01]
				switch(position) {
					case "1": return { value:0.01 , realSize:"1", 	sortHole:null};
					case "2": return { value:0.05 , realSize:"2", 	sortHole:null};
					case "3": return { value:0.10 , realSize:"3", 	sortHole:null};
					case "4": return { value:0.25 , realSize:"4", 	sortHole:null};
					case "5": return { value:0.50 , realSize:"5-6", sortHole:5};
					case "7": return { value:1 , 	realSize:"7-8", sortHole:6};
					default: return null;
				}
			case "PLN": //fall through
			case "44384": //Poland [PLN01]
				switch(position) {
					case "1": return { value:0.10 , realSize:"1", sortHole:null};
					case "2": return { value:0.20 , realSize:"2", sortHole:null};
					case "3": return { value:0.05 , realSize:"3", sortHole:null};
					case "4": return { value:0.50 , realSize:"4", sortHole:null};
					case "5": return { value:2 , 	realSize:"5", sortHole:null};
					case "6": return { value:1 , 	realSize:"6", sortHole:null};
					case "7": return { value:5 , 	realSize:"7", sortHole:null};
					case "8": return { value:0.01 , realSize:"8", sortHole:null};
					default: return null;
				}
			case "AUD": //fall through
			case "44322": //Australia [AUD02]
				switch(position) {
					case "1": return { value:2 , 	realSize:"1", 	sortHole:null};
					case "2": return { value:0.10 , realSize:"2-3", sortHole:2};
					case "4": return { value:1 , 	realSize:"4", 	sortHole:null};
					case "6": return { value:0.20 , realSize:"5-6", sortHole:6};
					case "7": return { value:0.50 , realSize:"7", 	sortHole:null};
					case "8": return { value:0.05 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "BGN": //fall through
			case "44386": //Bulgarian Lev [BGN01]
				switch(position) {
					case "1": return { value:0.02 , realSize:"1", sortHole:null};
					case "2": return { value:0.20 , realSize:"2", sortHole:null};
					case "3": return { value:0.10 , realSize:"3", sortHole:null};
					case "4": return { value:0.05 , realSize:"4", sortHole:null};
					case "5": return { value:0.50 , realSize:"5", sortHole:null};
					case "6": return { value:1 , 	realSize:"6", sortHole:null};
					case "7": return { value:2 , 	realSize:"7", sortHole:null};
					case "8": return { value:0.01 , realSize:"8", sortHole:null};
					default: return null;
				}
			case "BAM": //fall through
			case "45845": //Bosnia and Herzegovina [BAM01]
				switch(position) {
					case "1": return { value:0.10 , realSize:"1", 	sortHole:null};
					case "2": return { value:0.20 , realSize:"2", 	sortHole:null};
					case "3": return { value:1 , 	realSize:"3-4", sortHole:3};
					case "5": return { value:0.50 , realSize:"5", 	sortHole:null};
					case "6": return { value:2 , 	realSize:"6", 	sortHole:null};
					case "7": return { value:5 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.05 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "45876": //Russia [RUB01]
				switch(position) {
					case "1": return { value:0.10 , realSize:"1", sortHole:null};
					case "2": return { value:0.05 , realSize:"2", sortHole:null};
					case "3": return { value:0.50 , realSize:"3", sortHole:null};
					case "4": return { value:1 , 	realSize:"4", sortHole:null};
					case "5": return { value:10 , 	realSize:"5", sortHole:null};
					case "6": return { value:2 , 	realSize:"6", sortHole:null};
					case "7": return { value:5 , 	realSize:"7", sortHole:null};
					case "8": return { value:0.01 , realSize:"8", sortHole:null};
					default: return null;
				}
			case "RUB": //fall through
			case "49251": //Russia-02 [RUB02]
				switch(position) {
					case "1": return { value:0.50 , realSize:"1", 	sortHole:null};
					case "2": return { value:1 , 	realSize:"2", 	sortHole:null};
					case "4": return { value:10 , 	realSize:"4-3", sortHole:3};
					case "6": return { value:2 , 	realSize:"6-5", sortHole:5};
					case "7": return { value:5 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.10 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "UAH": //fall through
			case "48305": //Ukraine [UAH01]
				switch(position) {
					case "1": return { value:0.02 , realSize:"1", 	sortHole:null};
					case "3": return { value:0.25 , realSize:"3-2", sortHole:2};
					case "4": return { value:0.50 , realSize:"4", 	sortHole:null};
					case "6": return { value:0.05 , realSize:"6-5", sortHole:5};
					case "7": return { value:1 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.01 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "RON": //fall through
			case "48948": //Romania [RON01]
				switch(position) {
					case "2": return { value:0.01 , realSize:"2-1", sortHole:1};
					case "4": return { value:0.05 , realSize:"4-3", sortHole:3};
					case "6": return { value:0.10 , realSize:"6-5", sortHole:5};
					case "8": return { value:0.50 , realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "TWD": //fall through
			case "48950": //Taiwan [TWD01]
				switch(position) {
					case "2": return { value:1 ,  realSize:"2-1", sortHole:1};
					case "4": return { value:5 ,  realSize:"4-3", sortHole:3};
					case "6": return { value:10 , realSize:"6-5", sortHole:6};
					case "8": return { value:50 , realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "KRW": //fall through
			case "48302": //South Korea [KRW01]
				switch(position) {
					case "1": return { value:10 ,  realSize:"1", 	sortHole:null};
					case "3": return { value:50 ,  realSize:"3-2", 	sortHole:2};
					case "4": return { value:10 ,  realSize:"4", 	sortHole:null};
					case "6": return { value:100 , realSize:"6-5", 	sortHole:5};
					case "8": return { value:500 , realSize:"8-7", 	sortHole:7};
					default: return null;
				}
			case "ZAR": //fall through
			case "49120": //South Africa [ZAR01]
				switch(position) {
					case "1": return { value:0.20 , realSize:"1", 	sortHole:null};
					case "2": return { value:1 , 	realSize:"2", 	sortHole:null};
					case "3": return { value:0.05 , realSize:"3", 	sortHole:null};
					case "4": return { value:0.50 , realSize:"4", 	sortHole:null};
					case "6": return { value:2 , 	realSize:"6-5", sortHole:5};
					case "7": return { value:5 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.10 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "CLP": //fall through
			case "49118": //Chile [CLP01]
				switch(position) {
					case "2": return { value:5 , 	realSize:"2-1", sortHole:1};
					case "3": return { value:10 , 	realSize:"3", 	sortHole:null};
					case "4": return { value:100 , 	realSize:"4", 	sortHole:null};
					case "6": return { value:500 , 	realSize:"6-5", sortHole:6};
					case "8": return { value:100 , 	realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "SEK": //fall through
			case "000CLS01000": //Fall through
			case "CLS01000": //Sweden [SEK01]
				switch(position) {
					case "2": return { value:1 ,  realSize:"2-1", sortHole:1};
					case "4": return { value:10 , realSize:"4-3", sortHole:3};
					case "6": return { value:2 ,  realSize:"6-5", sortHole:5};
					case "8": return { value:5 ,  realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "HUF": //fall through
			case "000CLS01011": //Fall through
			case "CLS01011": //Hungary [HUF01]
				switch(position) {
					case "1": return { value:5 , 	realSize:"1", 	sortHole:null};
					case "2": return { value:100 ,  realSize:"2", 	sortHole:null};
					case "3": return { value:10 , 	realSize:"3", 	sortHole:null};
					case "4": return { value:20 , 	realSize:"4", 	sortHole:null};
					case "6": return { value:50 , 	realSize:"6-5", sortHole:6};
					case "8": return { value:200 , 	realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "CHF": //fall through
			case "000CLS01020": //Fall through
			case "CLS01020": //Switzerland [CHF01]
				switch(position) {
					case "1": return { value:0.05 , realSize:"1", 	sortHole:null};
					case "2": return { value:0.50 , realSize:"2", 	sortHole:null};
					case "3": return { value:0.10 , realSize:"3", 	sortHole:null};
					case "4": return { value:0.20 , realSize:"4", 	sortHole:null};
					case "5": return { value:1 , 	realSize:"5", 	sortHole:null};
					case "6": return { value:2 , 	realSize:"6", 	sortHole:null};
					case "8": return { value:5 , 	realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "CNY": //fall through
			case "000CLS01050": //Fall through
			case "CLS01050": //China [CNY01]
				switch(position) {
					case "2": return { value:0.10 , realSize:"2-1", 	sortHole:1};
					case "4": return { value:0.50 , realSize:"4-3", 	sortHole:3};
					case "5": return { value:0.10 , realSize:"5", 		sortHole:null};
					case "7": return { value:1 , 	realSize:"7-6-8", 	sortHole:7};
					default: return null;
				}
			case "ILS": //fall through
			case "000CLS01060": //Fall through
			case "CLS01060": //Israel [ILS-01]
				switch(position) {
					case "2": return { value:2 , 	realSize:"2-1", sortHole:1};
					case "4": return { value:10 , 	realSize:"4-3", sortHole:3};
					case "5": return { value:5 , 	realSize:"5", 	sortHole:null};
					case "7": return { value:0.50 , realSize:"7-6", sortHole:6};
					case "8": return { value:1 , 	realSize:"8", 	sortHole:null};
					default: return null;
				}
			case "MYR": //fall through
			case "000CLS01062": //Fall through
			case "CLS01062": //Malaysia-03 [MYR03]
				switch(position) {
					case "2": return { value:0.10 , realSize:"2-1", sortHole:1};
					case "3": return { value:0.20 , realSize:"3", 	sortHole:null};
					case "4": return { value:0.50 , realSize:"4", 	sortHole:null};
					case "6": return { value:0.20 , realSize:"6-5", sortHole:6};
					case "8": return { value:0.50 , realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "000CLS01071": //Fall through
			case "CLS01071": //Saudi Arabia-01 [SAR-01]
				switch(position) {
					case "2": return { value:0.25 , realSize:"2-1", sortHole:1};
					case "4": return { value:1 , 	realSize:"4-3", sortHole:3};
					case "6": return { value:0.50 , realSize:"6-5", sortHole:5};
					case "8": return { value:2 , 	realSize:"8-7", sortHole:7};
					default: return null;
				}
			case "SAR": //fall through
			case "000CLS01073": //Fall through
			case "CLS01073": //Saudi Arabia-02 [SAR-02]
				switch(position) {
					case "1": return { value:0.05 , realSize:"1", 	sortHole:null};
					case "2": return { value:0.10 , realSize:"2", 	sortHole:null};
					case "4": return { value:0.25 , realSize:"4-3", sortHole:4};
					case "5": return { value:1 , 	realSize:"5", 	sortHole:null};
					case "6": return { value:0.50 , realSize:"6", 	sortHole:null};
					case "7": return { value:2 , 	realSize:"7", 	sortHole:null};
					case "8": return { value:0.01 , realSize:"8", 	sortHole:null};
					default: return null;
				}
			default: return null;
		}
};