import React from 'react';
import {I18n} from "aws-amplify/utils";
import './InstallationQuickDIsplay.css';
import {getIconForNode, roles} from "../../../../Models/Roles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {messageType, operationType, stateType} from "../../../../Models/Report/Enums";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {getOperationAt} from "../../../../graphql/queries";
import PayStationFinder from "../../../../Utils/PayStationFinder";

const DESTINATION = {
    DIAGNOSTIC: 0,
    SYSTEM_DETAILS: 1,
    MESSAGES: 2,
    OPERATIONS: 3
}

const InstallationQuickDisplay = props => {
    const [messages, setMessages] = React.useState( null );
    const messageCount = 10;

    const navigateTo = ( destination , device ) => {
        switch ( destination ) {
            case DESTINATION.DIAGNOSTIC:
                if( props.onRequestedBoxDiagnostic ) {
                    props.onRequestedBoxDiagnostic( props.selected?.box.id , device.id );
                }
                break;
            case DESTINATION.SYSTEM_DETAILS:
                if( props.onRequestedBoxOverview ) {
                    props.onRequestedBoxOverview( props.selected?.box.id );
                }
                break;
            case DESTINATION.MESSAGES:
                if( props.onRequestedMessages ) {
                    props.onRequestedMessages( props.selected?.box.id , device.id , 2 );
                }
                break;
            case DESTINATION.OPERATIONS:
                //Device is lastOperation to do identify routing
                if( props.onRequestedOperations ) {
                    if( [ operationType.SALE , operationType.SALE_RETURN ].includes( device.type ) ) {
                        props.onRequestedOperations( null , 4 );
                    } else {
                        props.onRequestedOperations( null , 9 );
                    }
                }
                break;
            default:
                break;
        }
    }

    const getMaxLevel = candidate => {
        const last10 =  candidate.filter( item => item.level < 3 )
                                 .sort( ( a , b ) => b.at > a.at )
                                 .slice( 0, messageCount );
        let max = -1;
        last10.forEach( message => {
            max = Math.max( max , message.level );
        } );
        return max;
    }

    const getLatestOperation = terminal => {
        return new Promise( resolve => {
            if( props.API ) {
                props.API
                    .graphql({ query: getOperationAt, variables: { cbms: `6_${terminal.name}` , at: new Date().toISOString() } })
                    .then( returned => {
                        if( returned && returned.data && returned.data.getOperationAt ) {
                            resolve( returned.data.getOperationAt );
                        }
                    })
                    .catch(error => {
                        console.log( error );
                        resolve( null );
                    })
            }
        } );
    }

    const formatMessage = message => {
        return decodeURI( message.text
            .replace(/%3%4/g , "")
            .replace(/\{/g , "")
            .replace(/\}/g , "")
            .replace(/\?/g , "") ).replace(/\\u[0-9a-fA-F]{4}/g,function( unicode ) {
            const charCode = parseInt( unicode.replace("\\u", "") , 16 );
            return String.fromCharCode( charCode );
        })
    }

    const renderMessagesAnalysis = messages => {
        if( ! messages ) {
            return null;
        }

        const last10 =  messages.filter( item => item.level < 3 )
                                .sort( ( a , b ) => b.at > a.at )
                                .slice( 0, messageCount )
        return (
            <div className={`messages-analysis`}>
                {last10.map( message =>
                    <div className={`messages-line`}>
                        <div className={`messages-icon`}>
                            <FontAwesomeIcon icon={messageType.getIcon( message.level )}/>
                        </div>
                        <div className={`messages-text`}>
                            {formatMessage( message )}
                        </div>
                    </div> )}
            </div>
        );
    }

    const renderLastImportantMessage = device => {
        if( ! ( device.lastState?.state === "OFFLINE" ) || device.messages < 1 ) {
            return null;
        }

        return(
            <>
                <FontAwesomeIcon className={`message-button level${getMaxLevel( device.messages )}`}
                                 icon="fa-solid fa-message"
                                 onClick={ () => { setMessages( device.messages ) } }/>
            </>
        );
    }

    const renderAccountAffectation = () => {
        if ( ! props.selected ) {
            return null;
        }

        const reseller = props.accounts?.filter( item => item.code === props.selected.box?.informations?.sender?.reseller_id )[0];
        const customer = props.accounts?.filter( item => item.code === props.selected.box?.informations?.sender?.customer_code )[0];
        return (
            <div className={`account-details`}>
                <div className={`account-line`}>
                    <div className={`account-label`}>
                        {I18n.get( 'Reseller' )}
                    </div>
                    <div className={`account-value`}>
                        {reseller?.name}
                    </div>
                    <div className={`account-info`}>
                        <FontAwesomeIcon icon={["fas" , "circle-info"]}
                                         className={`clickable`}
                                         onClick={() => { navigateTo( DESTINATION.SYSTEM_DETAILS , props.selected ); }} />
                    </div>
                </div>
                <div className={`account-line`}>
                    <div className={`account-label`}>
                        {I18n.get( 'Customer' )}
                    </div>
                    <div className={`account-value`}>
                        {customer?.name}
                    </div>
                </div>
                <div className={`account-line`}>
                    <div className={`account-label`}>
                        {I18n.get( 'Software' )}
                    </div>
                    <div className={`account-value`}>
                        {props.selected.box?.software?.identity?.model} <span className={`version`}>{props.selected.box?.software?.attributes?.version}</span>
                    </div>
                </div>
                <div className={`account-line`}>
                    <div className={`account-label`}>
                        {I18n.get( 'License' )}
                    </div>
                    <div className={`account-value`}>
                        {props.selected.box?.software?.attributes?.license}
                    </div>
                </div>
            </div>
        );
    }

    const renderLatestError = () => {
        if ( ! props.selected ) {
            return null;
        }
        let errors = [];
        let lastDevice;
        props.selected.devices.forEach( device => {
            const messages = device.messages?.filter( item => item.level === 2 );
            messages.forEach( message => {
                errors.push( message );
                lastDevice = device;
            } );
        } );
        errors.sort( ( a , b ) => b.at > a.at );
        return (
            <div className={`latest-error-available`}>
                <div className={`error-line`}>
                    <div className={`error-label`}>
                        {I18n.get( 'Error found' )}
                    </div>
                    <div className={`error-value`}>
                        {errors.length}
                    </div>
                    {renderMessageNavigation( errors , lastDevice )}
                </div>
                <div className={`error-line`}>
                    <div className={`error-label`}>
                        {I18n.get( 'Last error' )}
                    </div>
                    <div className={`error-value`}>
                        {(errors.length > 0 ) ? formatMessage( errors[0] ) : I18n.get( 'No error available' )}
                    </div>
                </div>
            </div>
        );
    }

    const renderMessageNavigation = ( errors , device ) => {
        if( errors.length > 0 ) {
            return (
                <div className={`error-navigation`}>
                    <FontAwesomeIcon icon={["fas" , "envelope"]}
                                     className={`clickable`}
                                     onClick={() => { navigateTo( DESTINATION.MESSAGES , props.selected , device ); }} />
                </div>
            );
        }
        return null;
    }

    const renderLatestOperation = () => {
        if ( ! props.selected ) {
            return null;
        }

        let terminal = null;
        let latestOperation = { at: new Date( 0 ).toISOString() };
        props.selected?.terminals?.forEach( candidate => {
            const latestCandidate = candidate?.operations?.sort( ( a , b ) => b.at > a.at )[0];
            if( latestCandidate?.at > latestOperation.at ) {
                latestOperation = latestCandidate;
                terminal = candidate;
            }

            if( ! terminal ) {
                getLatestOperation( candidate )
                    .then( operation => {
                        candidate.operations.push( operation );
                        if( operation?.at > latestOperation.at ) {
                            latestOperation = operation;
                            terminal = candidate;
                        }
                    } );
            }
        } );
        const finder = new PayStationFinder( [props.selected] );
        console.log(latestOperation);
        return (
            <div className={`latest-operation-available`}>
                <div className={`operation-terminal`}>
                    {finder.buildTerminalExtraData( terminal )}
                </div>
                <div className={`operation-type`}>
                    <span className={`operation-label`}>
                        {I18n.get( operationType.getName( latestOperation.type ) )}
                    </span>
                    {( latestOperation.type ) ?
                        <span className={`operation-navigation`}>
                            <FontAwesomeIcon icon="fa-solid fa-chart-pie"
                                             className={`clickable`}
                                             onClick={() => { navigateTo( DESTINATION.OPERATIONS , latestOperation ); }} />
                        </span> : '' }
                </div>
                <div className={`operation-cashier`}>
                    {latestOperation.cashier}
                </div>
                <div className={`operation-at`}>
                    {new Date( latestOperation.at ).toLocaleString()}
                </div>
            </div>
        );
    }

    const renderAdvices = () => {
        //return null;
        if ( ! props.selected ) {
            return null;
        }
        let message = "";
        const softwareCLient = ['CashmagConnect' , 'ConnectUP'];
        if( softwareCLient.includes( props.selected.box?.software?.identity?.model ) ) {
            message = I18n.get( 'Restart the software' );
        } else {
            message = I18n.get( 'Restart the service' );
        }
        return (
            <div className={`technical-advice`}>
                <div className={`tip-line`}>
                    <div className={`tip-label`}>
                        {I18n.get( 'Recommendations' )} :
                    </div>
                    <div className={`tip-value`}>
                        {message}
                    </div>
                </div>
            </div>
        );
    }

    const renderDiagnosticNavigation = device => {
        if( device.informations?.identity?.model === 'MDB-CoinRecycler' ||
            device.informations?.identity?.model === 'VirtualDevice' ) {
            return null;
        }

        const color = ( props.alerts?.some( item => item.device.name === device.name ) ) ? 'partially' : '';
        return (
            <FontAwesomeIcon className={`clickable ${color}`}
                             icon={["fas" , "stethoscope"]}
                             onClick={() => { navigateTo( DESTINATION.DIAGNOSTIC , device ); }}/>
        );
    }

    const renderContent = () => {
        if ( ! props.selected ) {
            return I18n.get( 'No Installation selected' );
        }

        const items = [];
        props.selected.devices.forEach( device => {
            items.push(
                <div className={`device-state`} key={`device-state-${device.id}`}>
                    <div className={`icons`}>
                        <FontAwesomeIcon className={`${stateType.getCssClass( stateType.fromName( device?.lastState?.state ) )}`}
                                         icon={getIconForNode( {role: roles.DEVICE , name: device.informations?.identity?.model} )} />
                        <div className={`state-text`}>{I18n.get( device?.lastState?.state )}</div>
                    </div>
                    <div className={`identity`}>
                        <div className={`model`}>
                            {device.informations?.identity?.model.replace("MDB-CoinRecycler" , "Gryphon")}
                        </div>
                        <div className={`serial`}>
                            {(device.informations?.identity?.model === 'VirtualDevice') ? '' : device.informations?.identity?.serial}
                        </div>
                    </div>
                    <div className={`last-error`}>
                        {renderLastImportantMessage( device )}
                    </div>
                    <div className={`last-reception`}>
                        {new Date(device?.lastState?.at).toLocaleString( props.locale )}
                    </div>
                    <div className={`navigation-diagnostic`}>
                        {renderDiagnosticNavigation( device )}
                    </div>
                </div>
            );
        } );
        return (
            <>
                <div className={`modules-states`}>
                    <div className={`label`}>{I18n.get("Modules state")}</div>
                    {items.map( item => item )}
                </div>
            </>
        );
    };

    const content = renderContent();
    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
    if( props.isDarkStyle ) {
        css += " dark";
    }
    return (
        <>
            <div className={`material-container long ${( ! props.technicalAlerts || props.technicalAlerts.length < 1 ) ? 'no-data' : ''}`}>
                <div className="container-title">{I18n.get('Installation details')} : </div>
                <div className="container-content">
                    {renderAccountAffectation()}
                    {content}
                    {renderLatestError()}
                    {renderLatestOperation()}
                    {renderAdvices()}
                </div>
            </div>
            <Dialog disableEscapeKeyDown
                    className={css}
                    aria-labelledby="simple-dialog-title"
                    open={Boolean(messages)}>
                <DialogTitle id="remote-command-dialog-title">{I18n.get( 'Last important messages' )}</DialogTitle>
                <DialogContent dividers>
                    {renderMessagesAnalysis( messages )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => { setMessages( null ) } }>
					<span className="btn-wrapper--label">
						{I18n.get("OK")}
					</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InstallationQuickDisplay;
