import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import getSymbolFromCurrency from 'currency-symbol-map'
import UUID from "../../../Utils/UUID";
import { I18n } from 'aws-amplify/utils';
import {setUserAlert} from '../../../graphql/mutations.js';
import {getAlertForUser} from '../../../graphql/queries.js';
import './InventoryAlertConfig.css';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import '../../Views/Settings/Settings.css';
import '../../Views/Administration/Administration.css';
import '../AdministrationManagers/DialogGroupEdit.css'

const factoryUUID = new UUID();

let currentField = {
	id:null,
	lastChange:null,
	value:null
};

let alertCreationDate = null;
let alertLastSentAt = null;

export default function InventoryAlertConfig( props ) {
	const [value, setValue] = React.useState( ( props.user.userProperties.properties.alarmInventoryTemporalUnit  ) ? props.user.userProperties.properties.alarmInventoryTemporalUnit : "DAY" );
	const id = factoryUUID.generate();
	const defaultTimeoutForInputSaving = 3000;
	const defaultIntervalForInputSaving = 5000;
	const [open, setOpen] = React.useState( false );

	const [updated, setUpdated] = React.useState( false );
	
	const handleInputChange = ( evt , idField , property ) => {
		currentField = {
			id:idField,
			lastChange:new Date(),
			value:evt.target.value,
			property:property
		}
		setTimeout( () => {
			if( currentField.id ) {
				if( ( new Date().getTime() - currentField.lastChange.getTime() ) > defaultTimeoutForInputSaving ) { 
					updateData( currentField.property , currentField.value );
					currentField = {
						id:null,
						lastChange:null,
						value:null,
						property:null
					};
				} 
			} 
		} , defaultIntervalForInputSaving );
	}
	
	const handleInputBlur = ( evt , idField ) => {
		if( currentField.id ) {
			updateData( currentField.property , currentField.value );
			currentField = {
				id:null,
				lastChange:null,
				value:null,
				property:null
			};
		}
	}
	
	const toggleAlertMode = (evt , property) => {
		updateData( property , ! props.user.userProperties.properties[property] );
	}
	
	const updateData = ( property , value ) => {
		/*if( props.user.observer.currentUser ) {
			if( props.user && props.user.cognitoUser["phone_number"] === undefined ) {
				props.user.cognitoUser["phone_number"] = props.user.observer.currentUser.phone;
			}
		}*/
		let shouldUpdate = true;
		if( property === "warningInventoryLevel" ) {
			console.log( 'props.user' , props.user );
			const alarm = ( props.user.userProperties.properties.alarmInventoryLevel ) ? parseFloat( props.user.userProperties.properties.alarmInventoryLevel.replace( "," , "." ) ) : 0;
			const warning = parseFloat( value );
			if( warning > alarm ) {
				shouldUpdate = false;
				setOpen(true );
			}
		}

		if( props.user && property && shouldUpdate ) {
			//update dynamoDb alert table for mail and sms notification
			overrideUserAlert();
			
			props.user.updatePreferences( property , value );
			//update useless state to redraw GUI and avoid to toggle expandableCard with outdated children
			setUpdated(!updated);
		}
	}

	const getUserAttribute = key => {
		let found = null;
		if( props.user.observer.currentUser &&
			props.user.observer.currentUser.attributes &&
			props.user.observer.currentUser.attributes.UserAttributes ) {
			props.user.observer.currentUser.attributes.UserAttributes.forEach( candidate => {
				if( candidate.Name === key ) {
					found = candidate.Value;
				}
			} );
		}
		return found;
	}
	
	const overrideUserAlert = () => {
		checkAlertIfRequired().then( () => {
			if( alertCreationDate ) {
				const userMail = getUserAttribute( "email" );
				const userPhone = getUserAttribute( "phone_number" );
				const input = {
					userId:props.user.observer.props.user.userId,
					parameters:JSON.stringify({
							alarmInventoryLevel:( props.user.userProperties.properties.hasOwnProperty( "alarmInventoryLevel" ) ) ? props.user.userProperties.properties.alarmInventoryLevel : "-1",
							warningInventoryLevel:( props.user.userProperties.properties.hasOwnProperty( "warningInventoryLevel" ) ) ? props.user.userProperties.properties.warningInventoryLevel : "-1",
							sendInventoryAlertByMail:( props.user.userProperties.properties.hasOwnProperty( "sendInventoryAlertByMail" ) ) ? props.user.userProperties.properties.sendInventoryAlertByMail : false,
							sendInventoryAlertByPhone:( props.user.userProperties.properties.hasOwnProperty( "sendInventoryAlertByPhone" ) ) ? props.user.userProperties.properties.sendInventoryAlertByPhone : false,
							locale:props.locale,
							offset: new Date().getTimezoneOffset(),
							email:( userMail ) ? userMail : "",
							phone: ( userPhone ) ? userPhone : "",
							account: props.user.observer.currentUser.accountId,
							reminderFrequency:{value:( props.user.userProperties.properties.hasOwnProperty( "alarmInventoryFrequency" ) ) ? props.user.userProperties.properties.alarmInventoryFrequency : -1 , unit:value} ,
						}),
					creation:alertCreationDate,
					/*lastSentAt:alertLastSentAt,*/
					updatedAt:new Date().toISOString()
				}

				props.API.graphql({ query: setUserAlert, variables: {input:input}})
				.then( returned => {
					console.log("returned" , returned);
				})
				.catch((error) => {
					console.log("error" , error);
				});
			}
		} );
	}
	
	const checkAlertIfRequired = () => {
		return new Promise( (resolve, reject) => {
			if( alertCreationDate === null ) {
				props.API.graphql({ query: getAlertForUser, variables: {input:props.user.observer.props.user.userId}})
				.then( returned => {
					if( returned.data.getAlertForUser && returned.data.getAlertForUser.hasOwnProperty("creation") ) {
						alertCreationDate = returned.data.getAlertForUser.creation;
						alertLastSentAt = returned.data.getAlertForUser.lastSentAt;
						resolve();
					} else {
						alertCreationDate = new Date().toISOString();
						resolve();
					}
				})
				.catch(error => {
					alertCreationDate = new Date().toISOString();
					resolve();
				})
			} else {
				resolve();
			}
		});
	}
	
	const getCurrencyAdornment = ( type ) => {
		let currency = ( props.currency ) ? props.currency : "EUR";
		let formatter = new Intl.NumberFormat(props.locale, { style: 'currency', currency: currency });
		if( type === "start" ) {
			if( ! new RegExp("^[0-9]").test( formatter.format( 0 ) ) ) {
				return (<InputAdornment position="start">{getSymbolFromCurrency(currency)}</InputAdornment>);
			} 
		} else if ( type === "end" ) {
			if( ! new RegExp("[0.9]$").test( formatter.format( 0 ) ) ) {
				return (<InputAdornment position="end">{getSymbolFromCurrency(currency)}</InputAdornment>);
			} 
		}
		return null;
	}
	
	const getAlertSendingButtonStyle = ( isActive ) => {
		let css = "toggle-btn-inventory-alert-sending";
		if( isActive ) {
			css += " active";
		}
		return css;
	};
	
	const handleChange = ( evt ) => {
		setValue( evt.target.value );
		updateData( "alarmInventoryTemporalUnit" , evt.target.value );
	};
	
	let css = "InventoryAlertConfig-root";
	let cssSelect = "combo-temporal-unit";
	let cssDialog = "shadow-sm-dark rounded-sm cm-dialog-confirm";

	if( props.isDarkStyle ) {
		css += " dark";
		cssSelect += " dark";
		cssDialog += " dark";
	}
	if( props.className ) {
		css += " " + props.className ;
	}
	
	let valueAlarm = props.user.userProperties.properties.alarmInventoryLevel;
	let valueWarning = props.user.userProperties.properties.alarmInventoryLevel - props.user.userProperties.properties.warningInventoryLevel;
	let valueFrequency = props.user.userProperties.properties.alarmInventoryFrequency;
	
	let cssSendByMail = getAlertSendingButtonStyle(props.user.userProperties.properties.sendInventoryAlertByMail );
	let cssSendOnPhone = getAlertSendingButtonStyle(props.user.userProperties.properties.sendInventoryAlertByPhone );
	
	const keyStart = "start";
	const keyEnd = "end";

	return (
		<React.Fragment>
			<div key={id} className={css}>
				<div className="row">
					<Grid container spacing={1}>
						<Grid item xs={12} md={2} lg={2}>
							<div><FontAwesomeIcon icon={['fas', 'bell']} className="display-4" /></div>
						</Grid>
						

						<Grid item xs={12} md={4} lg={4}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={6} lg={6}>
									<div className="label">{I18n.get("Inventory alarm level")} : </div>
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									<div className="expandable">
										<TextField
										  id={`${id}-alarm`}
										  placeholder={I18n.get("Not Specified")}
										  defaultValue={valueAlarm}
										  InputProps={{
											readOnly: false,
											type:"number",
											startAdornment: getCurrencyAdornment( keyStart ),
											endAdornment:getCurrencyAdornment( keyEnd )
										  }}
										  onChange={(evt) => handleInputChange( evt , `${id}-alarm` , "alarmInventoryLevel" ) }
										  onBlur={(evt) => handleInputBlur( evt , `${id}-alarm` , "alarmInventoryLevel" ) }
										/>
									</div>
								</Grid>

							</Grid>
						</Grid>
						

						<Grid item xs={12} md={4} lg={4}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={6} lg={6}>
									<div className="label">{I18n.get("Inventory warning level")} : </div>
								</Grid>
								<Grid item xs={12} md={6} lg={6}>
									<div className="expandable">
										<TextField
										  id={`${id}-warning`}
										  placeholder={I18n.get("Not Specified")}
										  defaultValue={valueWarning}
										  InputProps={{
											readOnly: false,
											type:"number",
											startAdornment: getCurrencyAdornment( keyStart ),
											endAdornment:getCurrencyAdornment( keyEnd )
										  }}
										  onChange={(evt) => handleInputChange( evt , `${id}-warning` , "warningInventoryLevel" ) }
										  onBlur={(evt) => handleInputBlur( evt , `${id}-warning` , "warningInventoryLevel" )  }
										/>
									</div>
								</Grid>

							</Grid>
						</Grid>
						
						<Grid item xs={12} md={2} lg={2}>
							<Grid container spacing={1}>
								<Grid item xs={2} md={2} lg={2}>
									<div>
										<IconButton
											edge="start"
											color="inherit"
											aria-label="activate alert by mail"
											onClick={(evt) => toggleAlertMode( evt , "sendInventoryAlertByMail" ) }
											className={`Icon ${cssSendByMail}`}
										>
											<FontAwesomeIcon icon={['fas', 'envelope']} className="display-4" />
										</IconButton>
									</div>
								</Grid>
								{/*<Grid item xs={2} md={2} lg={2}>
									<div>
										<IconButton
											edge="start"
											color="inherit"
											aria-label="activate alert by sms"
											onClick={(evt) => toggleAlertMode( evt , "sendInventoryAlertByPhone" ) }
											className={`Icon ${cssSendOnPhone}`}
										>
											<FontAwesomeIcon icon={['fas', 'phone']} className="display-4" />
										</IconButton>
									</div>
								</Grid>*/}

							</Grid>
						</Grid>
					</Grid>
				</div>
				<div className="row">
					<Grid container spacing={1}>
						<Grid item xs={12} md={2} lg={2}>
							<div><FontAwesomeIcon icon={['fas', 'stopwatch']} className="display-4" /></div>
						</Grid>
						<Grid item xs={12} md={10} lg={10}>
							<Grid container spacing={1}>
								<Grid item xs={12} md={4} lg={4}>
									<div className="label">{I18n.get("Inventory alarm frequency")} : </div>
								</Grid>
								<Grid item xs={12} md={8} lg={8} className="left-align">
									<TextField
									  id={`${id}-frequency`}
									  placeholder={I18n.get("Not Specified")}
									  defaultValue={valueFrequency}
									  InputProps={{
										readOnly: false,
										type:"number"
									  }}
									  onChange={(evt) => handleInputChange( evt , `${id}-frequency` , "alarmInventoryFrequency" ) }
									  onBlur={(evt) => handleInputBlur( evt , `${id}-frequency` , "alarmInventoryFrequency" )  }
									/>
									<div className="spacer"></div>
									<Select classes={{selectMenu:cssSelect}}
											labelId={`combo-${id}-label`}
											id={`combo-${id}-select`}
											value={value}
											onChange={handleChange}
											MenuProps={{
												classes:{paper:cssSelect}
											}}
											label={I18n.get("Inventory alarm frequency")} >
											{/*<MenuItem  key={`menu-${id}-item-minute`}
													   value="MINUTE">
												{I18n.get("Minutes")}
											</MenuItem>*/}
											{/*<MenuItem  key={`menu-${id}-item-hour`}
													   value="HOUR">
												{I18n.get("Hours")}
											</MenuItem>*/}
											<MenuItem  key={`menu-${id}-item-day`} 
													   value="DAY">
												{I18n.get("Days")}
											</MenuItem>
									</Select>
								</Grid>

							</Grid>
						</Grid>
					</Grid>
				</div>
			</div>
			<Dialog
				open={open}
				className={cssDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{I18n.get("Error")}
				</DialogTitle>
				<DialogContent dividers>
					{I18n.get("Error warning alarm")}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => {
						props.user.updatePreferences( "warningInventoryLevel" , props.user.userProperties.properties.alarmInventoryLevel );
						setOpen(false);
					}} autoFocus>
						{I18n.get("Ok")}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
