import React , { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Styles from './SnackBar.module.css'

//Component render everytime the parent render, the PureComponent only when his own props or state is modified
export default class Snackbar extends PureComponent {
	messageType = ''
	message = ''
	icon = null
	duration = 3000

	state = {
		isActive: false,
	}

	openSnackBar = (messageType = '' , message = 'Something went wrong...' , duration = 3000 , icon = null ) => {
		this.messageType = messageType;
		this.message = message;
		this.icon = icon;
		this.duration = duration;
		
		this.setState( { isActive: true }, () => {
			setTimeout( () => {
				this.setState({ isActive: false });
			}, this.duration );
		});
	}
	
	getDurationCss = () => {
		if( this.duration === 3000 ) {
			return Styles.shortDuration;
		} else if ( this.duration === 5000 ) {
			return Styles.longDuration;
		} else {
			return ""; //no supported animation
		}
	}
	
	getIconCss = () => {
		switch( this.messageType ) {
			case "success":return Styles.success;
			case "warning":return Styles.warning;
			case "info":return Styles.info;
			case "error":return Styles.error;
			default:return "";
		}
	}

	render() {
		const { isActive } = this.state;
		if( this.icon !== null && this.icon !== undefined ) {
			return (
				<div className = {isActive ? [Styles.snackbar, Styles.show, this.getDurationCss()].join(" ") : Styles.snackbar}>
					<span>
						<FontAwesomeIcon icon={this.icon} className={[this.getIconCss(), "display-4"].join(" ")}/>
					</span>
					<span className={Styles.message}>{this.message}</span>
					<span>
						<FontAwesomeIcon icon={["fas" , "times"]} className="display-4"/>
					</span>
				</div>
			);
		}
		return (
			<div className = {isActive ? [Styles.snackbar, Styles.show].join(" ") : Styles.snackbar}>
				<span className={Styles.message}>{this.message}</span>
				<span>
					<FontAwesomeIcon icon={["fas" , "times"]} className="display-4"/>
				</span>
			</div>
		);
	}
}