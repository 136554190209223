import React from 'react';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import {I18n} from "aws-amplify/utils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import UUID from "../../../Utils/UUID";

import '../../Views/Settings/Settings.css';
import '../../Views/Administration/Administration.css';
import './DialogGroupEdit.css'

const factoryUUID = new UUID();

const Mode = {
    USERS: 'USERS',
    GROUPS: 'GROUPS'
};

const DialogGroupUsersEdit = props => {

    const [editing, setEditing] = React.useState( ( props.pendingEdit ) ? props.pendingEdit : null );
    const [userSelection, setUserSelection] = React.useState( ( props.pendingEdit ) ? props.pendingEdit?.usersSelected : [] );
    const [groupSelection, setGroupSelection] = React.useState( ( props.pendingEdit ) ? props.pendingEdit?.groupsSelected : [] );
    const [mode, setMode] = React.useState( Mode.USERS );

    const handleCancel = () => {
        props.handleClose();
        setEditing(null);
        setMode( Mode.USERS );
    };

    const handleOk = () => {
        buildSelection();
        if( props.pendingEdit.id > 0 ) {
            props.handleClose( "edit" , editing );
        } else {
            props.handleClose( "add" , editing );
        }
        setEditing(null);
        setMode( Mode.USERS );
    };

    const handleSelection = selection => {
        const copy = JSON.parse( JSON.stringify( userSelection ) );
        const index = copy.findIndex( candidate => candidate.id === selection.id );
        if (index !== -1) {
            copy.splice(index, 1);
        } else {
            copy.push( selection );
        }
        props.pendingEdit.usersSelected = copy;
        setUserSelection( copy );
    };

    const handleSelectionGroups = selection => {
        const copy = JSON.parse( JSON.stringify( groupSelection ) );
        const index = copy.findIndex( candidate => candidate.id === selection.id );
        if (index !== -1) {
            copy.splice(index, 1);
        } else {
            copy.push( selection );
        }
        props.pendingEdit.groupsSelected = copy;
        setGroupSelection( copy );
    };

    const buildSelection = () => {
        editing.usersSelected = [];
        userSelection.forEach( selection => {
            editing.usersSelected.push( {
                id: selection.id
            } );
        } );
    }

    const renderModeSelector = () => {
        return (
            <div className="mode-selector-groups">
                <Button className={`${(mode === Mode.USERS) ? 'selected' : ''} selection-button`}
                        key={factoryUUID.generate()}
                        onClick={ () => {
                            setMode( Mode.USERS );
                        }}> {I18n.get('Users')} </Button>
                <Button className={`${(mode === Mode.GROUPS ) ? 'selected' : ''} selection-button`}
                        key={factoryUUID.generate()}
                        onClick={ () => {
                            setMode( Mode.GROUPS );
                        }}> {I18n.get('Groups')} </Button>
            </div>
        );
    }

    const renderForm = () => {
        switch ( mode ) {
            case Mode.USERS: return renderFormUsers();
            case Mode.GROUPS: return renderFormGroups();
            default: return null;
        }
    }

    const renderFormUsers = () => {
        const availableUsers = props.availableUsers;
        return (
            <div className="text-center p-3">
                <div className="group-data">
                    <div className="material-container">
                        <div className="container-title">{I18n.get('Select users')} : </div>
                        <div className="container-content">
                            {availableUsers?.filter( user => user.userid !== props.user.id ).map( user => <Button className={`${(userSelection.some( candidate => candidate.id === user.userid )) ? 'selected' : ''} selection-button`}
                                                                                                                  key={factoryUUID.generate()}
                                                                                                                  onClick={ () => {
                                                                                                                      handleSelection( { id: user.userid , name: user.userlabel } );
                                                                                                                  }}> {user.userlabel} </Button> )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderFormGroups = () => {
        const availableGroups = props.availableGroups;
        return (
            <div className="text-center p-3">
                <div className="group-data">
                    <div className="material-container">
                        <div className="container-title">{I18n.get('Select groups')} : </div>
                        <div className="container-content">
                            {availableGroups?.map( group => <Button className={`${(groupSelection.some( candidate => candidate.id === group.id )) ? 'selected' : ''} selection-button`}
                                                                   key={factoryUUID.generate()}
                                                                   onClick={ () => {
                                                                       handleSelectionGroups( { id: group.id , name: group.name } );
                                                                   }}> {group.name} </Button> )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";

    if( props.isDarkStyle ) {
        css += " dark";
    }

    if( ! props.pendingEdit ) {
        return null;
    }

    if( ! editing ) {
        setEditing( props.pendingEdit );
    }

    if( userSelection.length !== props.pendingEdit?.usersSelected.length ) {
        setUserSelection( props.pendingEdit?.usersSelected );
    }

    if( groupSelection.length !== props.pendingEdit?.groupsSelected.length ) {
        setGroupSelection( props.pendingEdit?.groupsSelected );
    }

    const titleKey = "Attach users on group";
    return (
        <Dialog disableEscapeKeyDown
                className={css}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.pendingEdit)}>
            <DialogTitle id="group-dialog-title">{I18n.get( titleKey )}</DialogTitle>
            <DialogContent dividers>
                {renderModeSelector()}
                {renderForm()}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} >
                    {I18n.get("CANCEL")}
                </Button>
                <Button onClick={handleOk}>
                    {I18n.get("OK")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogGroupUsersEdit;
