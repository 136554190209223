import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SensorCounter.css';

export default function SensorCounter( props ) {
	
	return( 
		<React.Fragment>
			<div className={`sensor-counter-root`}>
				<div  className={`icon ${props.type}`}>
					<FontAwesomeIcon icon={['fas', 'microchip']} className="display-4 mx-2" />
				</div>
				<div  className={`value`}>
					{`${( props.count ) ? props.count : ""}`}
				</div>
			</div>
		</React.Fragment>
	);
}
