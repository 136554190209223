const french = {
	'Sign in to your account' : "Connectez-vous à votre compte",
	'Username *' : "Identifiant *",
	'Username' : "Identifiant",
	'Enter your username' : "Saisissez votre identifiant",
	'Enter your Username' : "Saisissez votre identifiant",
	'Password *' : "Mot de passe *",
	'Password' : "Mot de passe",
	'Confirm Password' : "Confirmez votre mot de passe",
	'Enter your password' : "Saisissez votre mot de passe",
	'Enter your Password': 'Saisissez votre mot de passe',
	'Forgot your password?' : "Mot de passe oublié ?",
	'Reset Password' : "Réinitialiser le mot de passe",
	'Change Password': 'Changez votre mot de passe',
	'Please confirm your Password': 'Merci de confirmer votre mot de passe',
	'Account recovery requires verified contact information':'La récupération de compte nécessite la vérification des vos informations de contact',
	'Verify': 'Vérifier',
	'Skip': 'Ignorer',
	'Submit': 'Soumettre',
	'No account?' : "Pas de compte ?",
	'Create account' : "Créer un compte",
	'Sign In': "Se connecter",
	'Sign Up': "S'inscrire",
	'Sign Out': "Déconnexion",
	'Reset your password': "Réinitialiser votre mot de passe",
	'Back to Sign In': "Retour à la connexion",
	'Send Code': "Envoyer le code",
	'Send code': "Envoyer le code",
	'New Password': 'Nouveau mot de passe',
	'Resend Code': 'Renvoyer le code',
	'Create a new account': "Créer un nouveau compte",
	'Email Address *': "Adresse email *",
	'Phone Number *': "Numéro de téléphone *",
	'Have an account?': "Vous avez un compte ?",
	'Sign in': "Se connecter",
	'Create Account': "Créer un compte",
	'Attempt limit exceeded, please try after some time.':"Limite de tentatives atteinte, veuillez réessayer dans un moment",
	'Access Token has expired':"Le jeton d'accès a expiré",
	'Copyright': "© 2024, CashMonitor, Tous droits réservés.",
	'All rights reserved': "Tous droits réservés.",
	'Unknown': "Inconnu(s)",
	'Cash': "Espèce(s)",
	'Coin': "Pièce(s)",
	'Note': "Billet(s)",
	'Recylers': "Recyleurs",
	'Cashbox': "Cassette",
	'Loader': "Chargeur",
	'Total': "Total",
	'Recycler-Hopper': "Recycleur/Hopper",
	'Recycler': "Recycleur",
	'Hopper': "Hopper",
	'TotalCashbox': "Cashbox BILLETS",
	'TotalCashboxAll': "Cashbox TOUTES",
	'TotalCashboxCoins': "01Cashbox PIECES",
	'TotalCashboxNotes': "Cashbox BILLETS",
	'TotalLoader': "Loader",
	'Dashboard': "Tableau de bord",
	'Reports': "Rapports",
	'Environment': "Environement",
	'Box list': "Liste des périphériques",
	'Cashiers': "Caissiers",
	'Inventories': "Inventaires",
	'Transactions': "Transactions",
	'Transaction': "Transaction",
	'Messages': "Messages",
	'Search parameters': "Critères de recherche",
	'Date': "Date",
	'Device': "Automate",
	'Location': "Localisation",
	'Type': "Type",
	'Value': "Valeur",
	'Currency': "Devise",
	'Low stop': "Stop bas",
	'High stop': "Stop haut",
	'Low warning': "Avert. bas",
	'High warning': "Avert. haut",
	'Quantity': "Quantité",
	'Level': "Niveaux",
	'OK': "VALIDER",
	'CANCEL': "ANNULER",
	'CLEAR': "CLEAR",
	'Inventory for': "Inventaire au",
	"No box available":"Pas d'automate disponible",
	"Details":"Détails",
	"Previous inventory total":"Total des inventaires précédents",
	"Empty":"Vide",
	"Full":"Pleine",
	"Daily operations":"Opérations du jour",
	"Sale":"Vente",
	"Refund":"Remboursement",
	"Refill":"Rechargement",
	"Pickup":"Délestage",
	"Last operation":"Dernière opération",
	"View report cashier":"Voir le rapport caissier",
	"View inventory":"Voir l'inventaire",
	"View report": "Voir le rapport",
	"Report": "Rapport",
	"Weekly cash in": "Encaissement semaine",
	"Voucher":"Titre(s) de paiement",
	"Other":"Autre(s)",
	"Normal":"Normal",
	"Search":"Rechercher",
	"No available elements":"Pas d'éléments disponibles",
	"Total cash":"Total espèces",
	"Current cash":"Espèces courantes",
	"Lifetime cash sum":"Cumul espèces",
	"Lifetime vips sum":"Cumul titres",
	"Lifetime other sum":"Cumul autres",
	"Sales":"Ventes",
	"Compare to yesterday":" par rapport à la veille",
	"Map":"Cartographie",
	"Quick search":"Recherche rapide",
	"Filter management":"Gestion des filtres",
	"Simple filter management":"Gestion simple des filtres",
	"Advanced filter management":"Gestion avancée des filtres",
	"No filter selected":"Aucun filtre sélectionné",
	"Create filter":"Créer un filtre",
	"Edit filter":"Éditer le filtre",
	"Remove filter":"Supprimer le filtre",
	"Apply":"Appliquer",
	"Filter name":"Nom du filtre",
	"selected elements":"éléments sélectionnés",
	"Filter already exists":"Ce filtre existe déjà",
	"Filter added":"Filtre ajouté",
	"Cancel": "Annuler",
	"Navigate":"Navigation",
	"Results":"Résultats",
	"Result":"Résultat",
	"No available data":"Aucune donnée disponible",
	"Daily transaction number":"Nombre de transaction du jour",
	"Transaction number":"Nombre de transaction",
	"Transaction total": "Total",
	"Daily cash in": "Encaissement du jour",
	"Cash in": "Encaissement",
	"Average Cash in": "Encaissement Moyen",
	"Cash in Number": "Nombre d'Encaissement",
	"Cash in Number mobile": "Nombre",
	"No device detected": "Aucun périphérique détecté",
	"Hardware global state": "État général des équipements",
	"Hardware online": "Fonctionnels",
	"Hardware warning": "Avec avertissement",
	"Hardware offline": "En erreur",
	"Hardware stopped": "Arrêté",
	"Hardware with maintenance required": "Nécessitant une maintenance",
	"Hardware under maintenance": "En cours de maintenance",
	"Box(es)": "Équipement(s)",
	"Box": "Équipement",
	"Device works properly": "Le périphérique fonctionne correctement",
	"Devices work properly": "Les périphériques fonctionnent correctement",
	"Manager": "Gestionnaire",
	"Device has warning": "Le périphérique fonctionne partiellement",
	"Device is offline": "Le périphérique présente des erreurs de fonctionnement",
	"Device is stopped": "Le périphérique est arrêté",
	"Account": "Compte",
	"My Account": "Mon Compte",
	"Refresh data": "Rafraichir",
	"Print": "Imprimer",
	"Setting": "Paramétrage",
	"Export": "Exporter",
	"No image available": "Pas d'image disponible",
	"Inventory": "Inventaire",
	"Comparisons": "Comparaisons Horaires",
	"Operations": "Opérations d'Administration",
	"Distributions": "Répartitions Encaissement",
	"Period": "Période",
	"Gap": "Écart",
	"Timetable": "Horaire",
	"Turnover": "Chiffre d'affaire",
	"Average Price": "Panier moyen",
	"Sale Number": "Nombre de vente",
	"And": "Et",
	"From": "Du",
	"To": "au",
	"At": "Au",
	"Start": "Début",
	"End": "Fin",
	"Terminal": "Terminal",
	"Cashier": "Caissier",
	"First Page": "Première Page",
	"Previous Page": "Page Précédente",
	"Next Page": "Page Suivante",
	"Last Page": "Dernière Page",
	"All": "Tous",
	"Rows per page": "Lignes par page",
	"of": "sur",
	"Cancellation": "Annulation",
	"Amount": "Montant",
	"PDF creation": "Création du PDF",
	"PDF export": "Export du PDF",
	"Printing": "Impression",
	"Table": "Tableau",
	"Graphic": "Graphique",
	"Table and Graphic": "Tableau et Graphique",
	"Send as CSV": "Envoyer en CSV",
	"Send as PDF": "Envoyer en PDF",
	"Send as PNG": "Envoyer en PNG",
	"Export as CSV": "Exporter en CSV",
	"Export as PDF": "Exporter en PDF",
	"Export as PNG": "Exporter en PNG",
	"Export as SVG": "Exporter en SVG",
	"SubTotal": "Sous-Total",
	"My Data": "Mes Données",
	"My Devices": "Mes Périphériques Connectés",
	"My Scheduled Tasks": "Mes Tâches Programmées",
	"My Alerts": "Mes Alertes",
	"My Preferences":"Mes Préférences",
	"My Messages": "Mes Messages",
	"Email": "Email",
	"Id": "Id",
	"Firstname": "Prénom",
	"Lastname": "Nom",
	"Node": "Noeud",
	"Role": "Rôle",
	"Admin": "Administrateur",
	"Root": "Racine",
	"User": "Utilisateur",
	"Phone": "Téléphone",
	"Connected on this device since": "Connecté sur ce périphérique depuis le ",
	"Basic info": "Informations générales",
	"Connection info": "Informations de connexion",
	"Contact info": "Coordonnées",
	"Name": "Nom",
	"Not Specified": "Non renseigné",
	"Verified": "Vérifié",
	"Pending verification": "En attente de vérification",
	"This devices": "Cet appareil",
	"Other devices": "Autres appareils",
	"Reseller Id": "Identifiant revendeur",
	"Client Id": "Votre identifiant",
	"Software info": "Logiciel",
	"Hardware info": "Matériel",
	"Operating system": "Système d'exploitation",
	"Browser": "Navigateur",
	"Protocol": "Protocole",
	"See details": "Voir les details",
	"No other devices connected": "Pas d'autre appareil connecté",
	"Other connected devices": "Autres appareils connectés",
	"No scheduled task": "Aucune tâche programmée",
	"Scheduled Task Configuration":"Configuration de la tâche programmée",
	"Delete Scheduled Task":"Suppression de la tâche programmée",
	"Are you sure":"Êtes-vous sûr ? ",
	"Confirm delete":"Voulez-vous réellement supprimer cet élément ? Ce procédé est irréversible.",
	"Confirm disable account": "Confirmer la désactivation du compte ?",
	"Confirm enable account": "Confirmer l'activation du compte ?",
	"Confirm delete account": "Voulez-vous réellement supprimer ce compte ? Ce procédé est irréversible et supprimera le compte, les utilisateurs et tout comptes créés par celui-ci.",
	"Scheduled time":"Heure de programmation",
	"No settings for the scheduled task":"Aucun rapport sélectionné.",
	"None":"Aucun",
	"Select type":"Sélection du type",
	"Select filter":"Sélection du filtre",
	"Select document format":"Sélection du format du document",
	"Select node":"Sélection du noeud",
	"Select language":"Sélection de la langue",
	"Select box":"Sélection du monnayeur",
	"Select device":"Sélection du module",
	"Add":"Ajouter",
	"Recipients":"Destinataires",
	"Home":"Accueil",
	"Disconnection":"Déconnexion",
	"Disconnected for inactivity": "Vous avez été déconnecté après une trop longue inactivité.",
	"Reload":"Recharger",
	"Title error on operation": "Liste des erreurs de l'opération",
	"ERROR_INVALID_EXCHANGE_RATE": "Taux de change invalide",
	"ERROR_FOR_CURRENCY_PARAM": "pour la devise suivante",
	"Send":"Envoyer",
	"Edited on": "Edité le",
	"By": "Par",
	"Page": "Page",
	'Scheduled report':'Rapport programmé',
	'Manual email body':'Cet email a été envoyé manuellement par',
	'Manual email subject':'Envoi de rapport depuis CashMonitor',
	"Favorite recipients": "Destinataires favoris",
	"Other recipients": "Ajouter d'autres destinataires",
	"On terminal": "Sur",
	"All boxes": "Toutes les box",
	"Inventory alarm level":"Niveau de l'alarme pour inventaire",
	"Inventory warning level":"Avertissement à partir de ",
	"error":"Erreur",
	"manual":"Manuel",
	"toLow":"Quantité vers level bas",
	"toNormal":"Quantité vers level normal",
	"toHigh":"Quantité vers level haut",
	"levelColor":"couleur du niveau",
	"low":"Bas",
	"normal":"Normal",
	"high":"Haut",
	"unknown":"Inconnu",
	"Inventory alarm frequency":"Fréquence de l'alarme pour inventaire",
	"Minutes":"Minutes",
	"Hours":"Heures",
	"Days":"Jours",
	"Administration":"Administration",
	"Manage Users":"Gestion des utilisateurs",
	"Manage Nodes":"Gestion des noeuds",
	"Manage Installations":"Gestion des installations",
	"Manage Accounts":"Gestion de comptes",
	"Installations":"Installations",
	"Add user":"Ajouter un utilisateur",
	"New":"Nouveau",
	"Disconnected after user changed":"Votre utilisateur a été modifié, une nouvelle identification est requise.",
	"Filter by roles":"Filtrer par rôle",
	"Filter by types":"Filtrer par type",
	"Filter by names":"Filtrer par noms",
	"Required field":"Champ obligatoire",
	"Invalid field":"Champ invalide (%format%)",
	"Email format":"exemple@domaine.fr",
	"Phone format":" E.164 : +33600000000",
	"Delete":"Supprimer",
	"Edit":"Modifier",
	"Add reseller":"Ajouter un revendeur",
	"Add customer":"Ajouter un client",
	"Add location":"Ajouter un lieu",
	"Add company":"Ajouter une société",
	"Add shop":"Ajouter un magasin",
	"Add terminal":"Ajouter un terminal",
	"Add box":"Ajouter un monnayeur",
	"Add device":"Ajouter un périphérique",
	"Move to another box":"Déplacer dans un autre monnayeur",
	"Move to another location":"Déplacer",
	"Move to another customer":"Déplacer dans un autre client",
	"Move to another reseller":"Déplacer dans un autre revendeur",
	"Remote command":"Commande distante",
	"Reseller":"Revendeur",
	"Customer":"Client",
	"Company":"Société",
	"Shop":"Magasin",
	"Place":"Lieu",
	"Box-Node":"Monnayeur",
	"Device-Node":"Périphérique",
	"Image":"Image",
	"Drag and drop file":"Glisser et déposer le fichier",
	"Address":"Adresse",
	"Street":"Rue",
	"Zip code":"Code postal",
	"City":"Ville",
	"Country":"Pays",
	"Coordinates":"Coordonnées",
	"Latitude":"Latitude",
	"Longitude":"Longitude",
	"Invalid coordinate":"Coordonnée invalide",
	"Unique upload":"Un seul transfert est possible à la fois",
	"Select destination":"Sélectionner la destination",
	"User access":"Accès utilisateur",
	"Show locked device":"Montrer les périphériques vérrouillés",
	"Show path without devices":"Montrer les branches sans périphérique",
	"Hide locked device":"Masquer les périphériques vérrouillés",
	"Hide path without devices":"Masquer les branches sans périphérique",
	"Recycle useless nodes":"Supprimer les noeuds inutiles",
	"Delete node and related users":"Supprimer le noeud et les utilisateurs associés",
	"Activations":"Activations",
	"Hardwares":"Matériels",
	"Activation overview":"Aperçu des activations",
	"Activated device overview":"Aperçu des appareils activés",
	"Cumulative quantity":"Quantité cumulée",
	"Yesterday":"Hier",
	"Last 7 days":"7 derniers jours",
	"Last 30 days":"30 derniers jours",
	"Device activation timeline":"Chronologie de l'activation des appareils",
	"Activated devices group by types":"Répartition des appareils activés par types",
	"Last inventory at":"Dernier inventaire à",
	"manufacturer":"Fabricant",
	"name":"Nom",
	"version":"Version",
	"date":"Date",
	"socket_count":"Nombre de socket",
	"architecture":"Architecture",
	"computer_name":"Nom de l'ordinateur",
	"disk_free":"Espace disque disponible",
	"disk_size":"Taille du disque",
	"memory_free":"Espace mémoire disponible",
	"memory_size":"Taille de la mémoire",
	"serial":"Numéro de série",
	"sku":"Unité de maintien du stock",
	"terminal_service":"Service terminal",
	"ip_address":"Adresse IP",
	"mac_address":"Adresse MAC",
	"horizontal_resolution":"Résolution",
	"vertical_resolution":"Résolution verticale",
	"baseboard":"Carte mère",
	"bios":"BIOS",
	"processor":"Processeur",
	"os":"Système d'exploitation",
	"network":"Réseau",
	"video":"Vidéo",
	"YES":"Oui",
	"NO":"Non",
	"vm":"Machine virtuelle",
	"hyperv_type":"Type Hyper-V",
	"run_on":"Virtualisé",
	"vmware_type":"Type VMware",
	"software":"Logiciel",
	"model":"Modèle",
	"organization":"Organisation",
	"run_on_server":"Serveur",
	"run_through_tse":"Terminal Server Edition",
	"Devices":"Périphériques",
	"Overview":"Aperçu général",
	"Configuration details":"Détails de la configuration",
	"Unit number":"Nombre d'unités",
	"Units grouped by memory sizes":"Unités réparties par mémoire",
	"Units grouped by architectures":"Unités réparties par architectures",
	"Units grouped by manufacturers and processors":"Unités réparties par fabricants et modèles",
	"Units grouped by screen resolutions":"Unités réparties par résolution",
	"Units grouped by OS versions":"Unités réparties par OS versions",
	"Devices grouped models":"Périphériques répartis par modèles",
	"Softwares grouped by models and versions":"Logiciels répartis par modèles et versions",
	"Forecasts":"Prévisions",
	"Input": "Entrée",
	"Output": "Sortie",
	"Min alert":"Alerte mini",
	"Max alert":"Alerte maxi",
	"No available forecast":"Aucune prévision possible",
	"Days before max capacity":"jour(s) estimé(s) avant capacité maximum",
	"Days before empty":"jour(s) estimé(s) avant capacité minimum",
	"Months before max capacity":"Plusieurs mois estimés avant capacité maximum",
	"Months before empty":"Plusieurs mois estimés avant capacité minimum",
	"Today":"Aujourd'hui",
	"Cancelled operation":"Opération abandonnée",
	"Generated":"Généré",
	"Expand":"Déplier",
	"Collapse":"Replier",
	"Email already used":"Cet email est déjà utilisé",
	"Administrator":"Administrateur",
	"Change my password":"Modifier mon mot de passe",
	"Password modification":"Modification du mot de passe",
	"Current password":"Mot de passe actuel",
	"New password":"Nouveau mot de passe",
	"Confirm password":"Confirmer le mot de passe",
	"Error password length":"Votre mot de passe est trop court ( 8 )",
	"Error new password invalid":"Votre nouveau mot de passe est trop court( 8 )",
	"Error password mismatches":"Votre nouveau mot de passe est différent de la confirmation",
	"Incorrect username or password.":"Mot de passe actuel incorrect.",
	"Unknown error":"Une erreur inconnue s'est produite",
	"No message pending":"Aucun message en attente",
	"Password has changed":"Le mot de passe a été modifié avec succès",
	"Too short":"Trop court",
	"Weak":"Faible",
	"Okay":"Correct",
	"Good":"Bon",
	"Strong":"Fort",
	"ping":"ping",
	"enableDataSending":"enableDataSending",
	"initParams":"initParams",
	"diagnostic":"diagnostic",
	"reset":"reset",
	"restart":"restart",
	"forceOut":"forceOut",
	"revokeLicense":"revokeLicense",
	"PING":"Ping",
	"ENABLE_DATA_SENDING":"Activer l'envoi de donner",
	"INIT_PARAMETERS":"Initialiser les paramètres",
	"DIAGNOSTIC":"Diagnostic",
	"RESET":"Réinitialiser le logiciel",
	"RESTART":"Redémarrage",
	"FORCE_OUT":"Sortie forcée",
	"REVOKE_LICENSE":"Révoquer la license",
	"Command to execute":"Commande à exécuter",
	"Data type":"Type de données",
	"TECHNICAL":"Technique",
	"FINANCIAL":"Financière",
	"Enabling":"Activation",
	"ENABLED":"Activé",
	"DISABLED":"Désactivé",
	"Reseller id":"Identifiant revendeur",
	"Customer code":"Code client",
	"The command":"La commande ",
	"is executing":"est en cours d'exécution",
	"has finished":"est terminée",
	"has failed with reason":"a échouée pour la raison suivante",
	"Diagnostic":"Diagnostic",
	"mainModule":"Module principal",
	"bundler":"Bundler",
	"spine":"Spine",
	"recycler":"Recycleurs",
	"cashbox":"Cassette",
	"loader":"Chargeur",
	"usb":"USB",
	"power":"Alimentation électrique",
	"acceptor":"Accepteur",
	"r1-2":"Recycleur 1 & 2",
	"r3-4":"Recycleur 3 & 4",
	"r5-6":"Recycleur 5 & 6",
	"acceptor_input":"Bac d'alimentation de l'accepteur",
	"acceptor_output":"Bac de sortie de l'accepteur",
	"hopper":"Hopper",
	"J-":"J -",
	"Basic":"Vue simplifiée",
	"Expert":"Vue détaillée",
	"Last check at":"Dernière vérification le",
	"Cycles":"Nombre de cycles",
	"Operational since":"Opérationnel depuis",
	"Total up time":"Temp total de fonctionnement",
	"Up Time":"En fonction depuis",
	"Year":"A",
	"Day":"J",
	"Hour":"H",
	"Minute":"m",
	"Seconde":"s",
	"Slippage":"Glissement",
	"Cycles since last maintenance":"Cycles depuis la dernière maintenance",
	"Dispensed":"Distribués",
	"LevelChangeCount":"Nombre de changement de niveaux",
	"ModuleChangedCount":"Nombre de changement du module",
	"ModuleFullCount":"Nombre remplissage du module",
	"ModuleRemovalCount":"Nombre de sortie du module",
	"InternalResetCount":"Nombre de réinitialisation interne",
	"PowerUpCount":"Nombre de mise sous tension",
	"BillErrorCount":"Nombre de billet en erreur",
	"BillJamCount":"Nombre de bourrage billet",
	"HardwareFailureCount":"Nombre de défaillance matérielle",
	"TransportErrorCount":"Nombre d'erreur de transport",
	"NotArmedCount":"Nombre de désarmement du module",
	"MissingModuleCount":"Nombre de modules manquants",
	"ResetWithCoverOpenCount":"Nombre de réinitialisation avec le capot ouvert",
	"ResetWithInterlockOpenCount":"Nombre de réinitialisation avec le vérrouillage ouvert",
	"AmountNotAvailableCount":"Nombre de montants non disponibles",
	"BillNotAvailableCount":"Nombre de billets non disponibles",
	"BillRequestedCount":"Nombre de demandes de billets",
	"DirectFromLoaderCount":"Nombre de billet distribués directement depuis le chargeur",
	"Lifetime dispensed":"Distribuées",
	"Coins accepted":"Acceptées",
	"Coins sorted":"Triées",
	"Coins dispensed":"Distribuées",
	"Coins error":"En erreur",
	"Coins timeout":"Hors délais",
	"Coins overcurrent":"Surintensité",
	"AcceptanceRate":"Taux d'acceptation",
	"AcceptedCoins":"Pièces acceptées",
	"AcceptorJams":"Accepteur bloqué",
	"AcceptorProcessedCoins":"Pièces traitées par l'accepteur",
	"AccountancyErrorRate":"Taux d'erreur comptable",
	"DispenserJams":"Distributeur bloqué",
	"JamRate":"Taux de bourrage",
	"MiscreditedCoins":"Pièces mal créditées",
	"MissortedCoins":"Pièces mal triées",
	"MissortingErrorRate":"Taux d'erreur de tri ",
	"PayInVolumeRate":"Taux de paiement en volume (>= 50 pièces)",
	"SortedCoins":"Pièces triées",
	"SystemProcessedCoins":"Pièces traitées par le système",
	"TransactionMisrouteRate":"Taux d'erreur de routage des transactions",
	"PowerUps":"Mises sous tension",
	"PowerUpsCozIndependentWatchdogReset":"Mises sous tension car réinitialisations indépendantes du 'Watchdog'",
	"PowerUpsCozLowPowerManagementReset":"Mises sous tension car réinitialisations pour énergie faible",
	"PowerUpsCozResetFromNrstPin":"Mises sous tension car réinitialisations à partir 'Nrst Pin'",
	"PowerUpsCozSoftwareReset":"Mises sous tension car réinitialisations logicielles",
	"PowerUpsCozWindowWatchdogReset":"Mises sous tension parce que la réinitialisation du 'Window Watchdog'",
	"PowerUpsCozPorPdrReset":"Mises sous tension car réinitialisations à partir 'Por Pdr'",
	"AcceptorKnownCoinsRejected":"Pièces identifiées rejetées",
	"AcceptorUnknownCoinsRejected":"Pièces non identifiées rejetées",
	"Unsupported":"non supporté",
	"CoinWasSlug":"Jetons",
	"Rejected disabled":"A définir (Rejected disabled)",
	"Rejected inhibit":"A définir (Rejected inhibit)",
	"Misroute before":"A définir (Misroute before)",
	"Misroute after":"A définir (Misroute after)",
	"PayInVolumeTransaction":"Transactions en volume de paiement",
	"1To4Coins":"1-4 pièces",
	"5To9Coins":"5-9 pièces",
	"10To19Coins":"10-19 pièces",
	"20To29Coins":"20-29 pièces",
	"30To39Coins":"30-39 pièces",
	"40To49Coins":"40-49 pièces",
	"50To99Coins":"50-99 pièces",
	"100To199Coins":"100-199 pièces",
	"200To299Coins":"200-299 pièces",
	"300To399Coins":"300-399 pièces",
	"400To499Coins":"400-499 pièces",
	"500To999Coins":"500-999 pièces",
	"out of":"sur",
	"Transaction count":"Nombre de transactions",
	"Hardware":"Matériel",
	"Software":"Logiciel",
	"Acceptance":"Acceptation",
	"Compatibility":"Compatibilité",
	"Serie":"Série",
	"Version":"Version",
	"Recyclable":"Recyclable",
	"Capacity":"Capacité",
	"Notes stacked":"Billets empillés",
	"Removal count":"Nombre de sortie",
	"Full capacity count":"Nombre de capacité max atteinte",
	"Out of service for recycler opened":"Hors service pour recycleur ouvert",
	"Notes inserted while recycler busy":"Billets insérés pendant que les recycleurs sont occupés",
	"Motor starts":"Démarrage des moteurs",
	"Banknotes stacked":"Billets empilés",
	"Flash download attempts":"Tentatives de téléchargement flash",
	"Host resets":"Réinitialisations de l'hôte",
	"Calibrations":"Étalonnages",
	"Resets":"Réinitialisations",
	"Banknotes passed recognition":"Billets ayant passé la reconnaissance",
	"Barcodes decoded":"Codes-barres décodés",
	"Docs reached escrow position":"Documents ayant atteint la position d'attente",
	"Docs passed validation":"Documents ayant passé la validation",
	"Notes floated down":"Billets transférés vers la cashbox",
	"Recognition rejections":"Reconnaissance invalide",
	"Document disabled rejections":"Rejetés pour acceptation désactivée",
	"Orientation disabled rejections":"Rejetés pour orientation désactivée",
	"Host return document rejections":"Rejetés par l'hôte",
	"Escrow timeout rejections":"Rejetés pour temps en attente dépassé",
	"Fast feed error rejections":"Rejetés pour remplissage trop rapide",
	"Security rejections":"Rejetés pour raison de sécurité",
	"Docs that failed to reach escrow position":"Documents n'ayant pas atteint la zone d'attente",
	"Docs inserted while disabled":"Documents insérés lors d'une acceptation désactivée",
	"Docs inserted while recycler busy":"Documents insérés alors que le recycler est occupé",
	"Docs less than mini allowable length":"Billets trop petit",
	"Docs greater than maxi allowable length":"Billet trop gros",
	"Rejections for other reasons":"Rejetés pour une autre raison",
	"All types of jams":"Bourrages de tout types",
	"Out of service for jammed":"Hors service pour bourrage",
	"Out of order conditions":"En panne",
	"Jam recovery efforts":"Tentative de récupération après bourrage",
	"Out of service conditions":"Hors service",
	"Out of service for jammed on reset":"Hors service pour bourrage lors de la réinitialisation",
	"Manage users":"Gérer les utilisateurs",
	"Manage Groups":"Gestion des groupes",
	"Close period":"Fermer la période",
	"Notify":"Notifier",
	"Filter installation by name":"Filtrer les installations par noms",
	"Filter installation by software":"Filtrer les installation par logiciels",
	"Filter account by name":"Filtrer les comptes par noms",
	"Filter account by type":"Filtrer les comptes par types",
	"Filter outdated software versions":"Filtrer les versions obsolètes",
	"Accounts":"Comptes",
	"Create reseller account":"Créer un compte revendeur",
	"Create customer account":"Créer un compte client",
	"Disable":"Désactiver",
	"Enable":"Activer",
	"Remove inactive":"Optimiser",
	"Last communication": "Dernière communication",
	"Simple mode": "Mode simple",
	"Advanced mode": "Mode avancé",
	"Available": "Disponible(s)",
	"Selected": "Sélectionné(s)",
	"Your selection": "Votre selection",
	"Your reports": "Vos rapports",
	"No selection": "Aucune sélection",
	"Select a reseller": "Sélectionner par revendeurs",
	"Select a customer": "Sélectionner par clients",
	"Select a pay station list": "Sélectionner par monnayeurs",
	"Select a filter": "Sélectionner par filtre",
	"Groups": "Groupes",
	"Create group": "Créer",
	"Manage group users": "Utilisateurs",
	"Edit group": "Modifier",
	"Delete group": "Supprimer",
	"Group creation": "Création du groupe",
	"Group modification": "Modification du groupe",
	"Data type allowed": "Type de données autorisées",
	"Financial data": "Données financières",
	"Technical data": "Données techniques",
	"Yes": "Oui",
	"No": "Non",
	"Filter groups by name": "Filtrer les groupes par nom",
	"Filter locked groups": "Filtrer les groupes verrouillés",
	"Attach users on group": "Affecter les utilisateurs au groupe",
	"Select users": "Sélectionner les utilisateurs",
	"Error warning alarm": "Le niveau de l'avertissement ne peur pas excéder le niveau de l'alarme",
	"Error": "Erreur",
	"Groups for the user":"Groupes de l'utilisateur",
	"Close":"Fermer",
	"Invalid fund type detection": "Un type de fond invalide a été détecté",
	"Group targets":"Cibles du groupe",
	"Manage options": "Gérer mes options",
	"Account options": "Options de votre compte",
	"Technical alerts recipients": "Destinataire(s) pour les alertes techniques",
	"Alert if inactive for ( days )": "Alerte si inactif depuis ( jours )",
	"Days of inactivity": "Jours d'inactivité",
	"Scheduled_disclaimer": "Votre tâche planifiée sera déclenchée toutes les 5 minutes. L'email pourra être envoyé avec un léger délai.",
	"Detailed": "Détaillée",
	"Consolidated": "Consolidée",
	"Select granularity": "Sélection de la granularité",
	"Remote commands": "Commandes distantes",
	"Customization": "Personnalisation",
	"License": "Licence",
	"Serial": "N° de série",
	"INTERNAL":"Interne",
	"ADMINISTRATIVE":"Administratif",
	"SALE_ROLE":"Commercial",
	"Licenses": "Licences",
	"Manage Licenses": "Gérer les licences",
	"Licenses allowed ( -1 for unlimited )": "Licences autorisées",
	"Licenses count": "Nombre de licences",
	"Invalid value": "Valeur invalide",
	"Installations requires a license": "Installations en attente de licence",
	"Not enough licenses": "Pas assez de licences",
	"Available licenses": "Licences disponibles",
	"Unlimited": "Illimitées",
	"Users": "Utilisateurs",
	"Select groups": "Sélectionner les groupes",
	"Licenses assignment": "Affectation des licences",
	"Licenses history": "Historique des licences",
	"Assignment": "Affectation",
	"History": "Historique",
	"Old value": "Ancienne valeur",
	"New Value": "Nouvelle valeur",
	"Movement": "Mouvement",
	"Last reception": "Dernière réception",
	"Technical alerts": "Alertes techniques",
	"No alert": "Aucune alerte",
	"Installation details": "Détails de l'installation",
	"No Installation selected": "Aucune installation sélectionnée",
	"OFFLINE": "Hors ligne",
	"ONLINE": "En ligne",
	"PARTIALLY": "Partiel",
	"STOPPED": "Arrêté",
	"Modules state": "État des modules",
	"Last important messages": "Derniers messages importants",
	"Error found": "Erreur trouvées",
	"Last error": "Dernière erreur",
	"No error available": "Aucune erreur disponible",
	"Monitoring": "Surveillance",
	"Restart the software": "Redémarrer le logiciel",
	"Restart the service": "Redémarrer le service",
	"Recommendations": "Recommandations",
	"Tools": "Outils",
	"Reseller/Client": "Revendeur/Client",
	"Account name": "Nom du compte",
	"Info": "Informations",
	"Provider": "Fournisseur",
	"TODAY": "Aujourd'hui",
    "YESTERDAY": "Hier",
	"CURRENT_WEEK": "Semaine en cours",
	"CURRENT_MONTH": "Mois en cours",
	"PREVIOUS_WEEK": "Semaine précédente",
	"PREVIOUS_MONTH": "Mois précédent",
	"RESEND_MAIL": "Renvoyer l'invitation",
	"Filter account": "Filtrer les comptes",
	"level_cleanup": "Nettoyages requis",
	"level_maintenance": "Maintenances requises",
	"ACTION_OK": "OK",
	"CONFIRM_RESEND": "Confirmer le renvoie de l'invitation ?",
	"STATUS": "Statut",
	"LAST_CONNECTION": "Dernière connexion",
	"FILTER_INSTALLATION": "Filtrer les installations",
	"DESACTIVATED_USER": "Utilisateur désactivé"
};

export default french;
