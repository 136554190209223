import React from 'react';
import {manageAmplifyException,sendEmail} from '../graphql/mutations';

class GlobalAppErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.monitorException(
            {
                input : {
                    error: error.message,
                    from:"DASHBOARD",
                    raisedTime:this.getNowOnServerFormat(),
                    info:info
                }
            } );
			
		this.sendMailToDevOpsTeam(
			{
                input: {
					recipients: "f.guichard@cashmag.fr", 
					subject: "Cashmag Connect Cloud Dashboard FAILURE", 
					body: "An unhandle exception has been raised at " + this.getNowOnServerFormat() + "\n\n" +
					"Please check Cloudwatch log : CloudWatch > Log groups > /aws/lambda/MonitorAmplifyExceptions\n\n" + 
					"Cause : " + error.message + "\n\n" + 
					"Stack : " + info.componentStack
				}
            }
		);	
			
        this.setState({ hasError: true });
    }

    monitorException( input ) {
        this.props.API.graphql({ query: manageAmplifyException, variables: input})
            .then((returned) => {
                console.log("returned" , returned);
            })
            .catch((error) => {
                console.log("error" , error);
            })
    }
	
	sendMailToDevOpsTeam( input ) {
        this.props.API.graphql({ query: sendEmail, variables: input})
            .then((returned) => {
                console.log("returned" , returned);
            })
            .catch((error) => {
                console.log("error" , error);
            })
    }

    getNowOnServerFormat() {
        return this.getDateOnServerFormat( new Date( Date.now() ) );
    }

    getDateOnServerFormat( date ) {
        try {
            return date.toISOString().replace("Z" , "") + "+00:00";
        } catch ( error ) {
            console.log( "Error on converting timestamp" , error );
        }
        return date;
    }

    render() {
        if (this.state.hasError) {
            document.location.reload();
            return null;
        }
        return this.props.children;
    }
}
export default GlobalAppErrorHandler;
