import React from 'react';
import Chart from 'react-apexcharts';
import AmountFormatter from '../../../Utils/AmountFormatter';
import { I18n } from 'aws-amplify/utils';
import { operationType } from "../../../Models/Report/Enums.js";

class GraphObsContainer extends React.Component{
	constructor( props ) {
		super(props);
		this.state = {
			page:0,
			rowsPerPage:props.defaultRowsPerPage,
			series:props.series,
			options:props.options
		};
		
		this.spaceGraphBefore = 0.80;
		this.spaceGraphAfter = 0.30;
		if( ! this.props.isDesktop ) {
			this.spaceGraphBefore -= 0.20;
			this.spaceGraphAfter += 0.20;
		}
		
		this.details = props.details;
		this.filterProp = props.defaultFilter;
	}
	
	setPage( page ) {
		this.setState({
			page:page,
			rowsPerPage:this.state.rowsPerPage,
			series:this.state.series,
			options:this.state.options
		});
	}
	
	setRowsPerPage( rowsPerPage ) {
		if( rowsPerPage > 0 ) {
			this.setState({
				page:0,
				rowsPerPage:rowsPerPage,
				series:this.state.series,
				options:this.state.options
			});
		}
	}
	
	setData( series , labels , details ) {
		let options = this.state.options;
		options.labels = labels;
		this.details = details;
		this.setState({
			page:this.state.page,
			rowsPerPage:this.state.rowsPerPage,
			series:series,
			options:options
		});
	}
	
	generateColorsForSeries( series , excludeEmpty ) {
		let colors = [];
		series.map((value , index) => {	
			if( excludeEmpty ) {
				if( parseFloat( value ) > 0 ) {
					colors.push( this.retrieveColor( index ) );
				}
			} else {
				colors.push( this.retrieveColor( index ) );
			}

			return true;
		} );
		return colors;
	}
	
	generateColorsForLabel( labels ) {
		let colors = [];
		labels.map( (label) => {
			if( this.props.isDarkStyle ) {
				colors.push("#FFFFFF");
			} else {
				colors.push("#000000");
			}
			return true;
		} );
		return colors;
	}
	
	retrieveColor( index ) {
		try {
			switch( index % 10 ) {
				case 0: return "#10c5db";
				case 1: return "rgb(0, 143, 251)";
				case 2: return "rgb(0, 227, 150)";
				case 3: return "rgb(254, 176, 25)";
				case 4: return "rgb(255, 69, 96)";
				case 5: return "rgb(119, 93, 208)";
				case 6: return "#d622a1";
				case 7: return "#008080";
				case 8: return "rgb(0, 143, 251)";
				case 9: return "rgb(0, 227, 150)";
				default:return "rgb(254, 176, 25)";
			}
		} catch ( exception ) {
			console.log("Exception" , exception)
		}
		return "#775DD0";//purple as Unknown
	}
	
	retrieveSeriesColor( seriesLabel ) {
		let currentSeries = this.filterSeries( this.state.series );
		let indexSeries = -1;
		currentSeries.map( (series) => {
			if( series.name === seriesLabel ) {
				indexSeries = currentSeries.indexOf( series );
			}
			return true;
		} );
		return this.retrieveColor( indexSeries );
	}
	
	filterSeries( series ) {
		let filtered = [];
		if( this.props.type === "donut" ) {
			series.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
				.map((value, index) => {
					filtered.push( value );
					return true;
			})
		} else if( this.props.type === "line" ) {
			if( this.props.shared ) {
				return series;	
			}
			series.map( (serie) => {  
				let filteredData = [];
				
				serie.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
					.map((value, index) => {
						
						filteredData.push( value );
						return true;
				})
				
				filtered.push( {
					name:serie.name,
					data:filteredData
				} );
				
				return true;
			} );
		}
		return filtered;
	}
	
	filterOptions( options ) {
		const instance = this;
		if( this.props.type === "donut" ) {
			let filteredLabels = [];
			if( options.hasOwnProperty("labels") ) {
				let labels = this.state.options.labels
				labels.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
					  .map((value, index) => {
							filteredLabels.push( value );
							return true;
					  });
				options.labels = filteredLabels;
			}
			const fillColors = this.generateColorsForSeries( this.filterSeries( this.state.series ) , false );
			const legendFillColors = this.generateColorsForSeries( this.filterSeries( this.state.series ) , false );
			return { 
				plotOptions: {
					pie: {
					  donut: {
						size: '45%'
					  }
					}
				},
				fill: {
				  colors: fillColors
				},
				labels: filteredLabels ,
				legend: {
					position: 'bottom',
					markers: {
						fillColors: legendFillColors,
					},
				},
				tooltip: {
					enabled: true,
					custom: function({series, seriesIndex, dataPointIndex, w}) {
						if( instance.props.name === "distribution" ) {
							return instance.handleDistributionTootip({series, seriesIndex, dataPointIndex, w} , fillColors , filteredLabels );
						}
						return null;
					}
				}
			}
		} else if ( this.props.type === "line" ) {
			if ( this.details.length > 0 ) {
				const formatter = new AmountFormatter(this.props.locale,  this.details[0].currency );
				const categories = this.generateTimelineLabels( this.details );

				let fillColors;
				if( instance.props.shared ) {
					fillColors = this.generateColorsForSeries( this.filterSeries( this.state.series ) , false );
				} else {
					fillColors = ['#e39600' , '#008ffb  '];//['#008ffb  ' , '#e39600'];
				}

				const optReturned = {
					chart: {
						toolbar: {
							show: true
						},
						dropShadow: {
							enabled: true,
							opacity: 0.3,
							blur: 5,
							left: -7,
							top: 22
						},
					},
					stroke: {
						show: true,
						width: 4,
						colors: fillColors
					},
					colors: fillColors,
					dataLabels: {
						enabled: true,
						formatter: function (value) {
							if( instance.filterProp === "calculateSale" ) {
								return value;
							}
							return formatter.format( value ).replace(/\s/g , ""); //label money
							//return value;
						},
						dropShadow: {
							enabled: true,
							left: 1,
							top: 1,
							opacity: 0.4
						},
						background: {
							enabled: true,
							foreColor: '#fff',
							padding: 4,
							borderRadius: 2,
							borderWidth: 1,
							borderColor: '#fff',
							opacity: 0.9,
							dropShadow: {
								enabled: false,
								top: 1,
								left: 1,
								blur: 1,
								color: '#000',
								opacity: 0.45
							},
							style: {
								colors: ['#008ffb  ' , '#e39600']
							},
						},
					},
					xaxis: {
						min:0,
						max:(categories.length + 1),
						crosshairs: {
							width: 1
						},
						categories: categories,
						labels: {
							show: true,
							style: {
								colors: instance.generateColorsForLabel(categories)
							},
							formatter: function (value) {
								return value;
							}
						},
						tooltip:{
							enabled:false
						},
					},
					yaxis: {
						labels: {
							formatter: function (value) {
								if( instance.filterProp === "calculateSale" ) {
									return value;
								}
								return formatter.format( value ).replace(/\s/g , "");
							}
						},
					},
				};

				if( this.props.shared ) {
					optReturned.dataLabels = {};
					optReturned.xaxis.tickAmount= categories.length;
					if( categories.length > 5 || !this.props.isDesktop) {
						optReturned.xaxis.labels= {
							show: true,
							rotate: -45,
							rotateAlways: true,
							minHeight: 100,
							maxHeight: 180,
						};
					} else {
						optReturned.xaxis.labels= {
							show: true,
							rotate: 0,
							rotateAlways: true,
							minHeight: 10,
							maxHeight: 20,
						};
					}

					optReturned.xaxis.tickPlacement= 'on'

					optReturned.annotations = this.generateAnnotations( fillColors );
				}

				return optReturned;
			}
		}
		return options;
	}
	
	generateAnnotations( fillColors ) {
		let safeDetail = this.details[this.props.selected].data;
		let annotationOnX = [];
		safeDetail.map( (annotation) => {
			let color = this.retrieveSeriesColor( annotation.label.typeLabel + " " + annotation.label.optDataLabel );
			annotationOnX.push( {
				// in a datetime series, the x value should be a timestamp, just like it is generated below
				x: new Date(annotation.date * 1000).toLocaleDateString() + " " + new Date(annotation.date * 1000).toLocaleTimeString(),
				strokeDashArray: 0,
				borderColor: color,
				label: {
				  borderColor: color,
				  style: {
					color: "#fff",
					background: color
				  },
				  text: I18n.get(operationType.getName( annotation.operationType )),
				  offsetY:-30
				}
			} );
			return true;
		} )
		return {
			xaxis: annotationOnX
		}
	}
	
	generateTimelineLabels( data ) {
		let labels = [];

		if( this.props.shared ) {
			if( this.state.options.labels !== null && this.state.options.labels !== undefined ) {
				return this.state.options.labels;
			}
		} else {
			data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
				.map((line, index) => {
					const dateTestLocale = new Date();
					dateTestLocale.setHours( 1 , 0 , 0 );
					if( ! dateTestLocale.toLocaleTimeString( this.props.locale ).trim().startsWith( "0" ) ) {
						const hour = parseInt( line.hour.substring(0 , line.hour.indexOf(" ")));
						const suffix = hour >= 12 ? "PM":"AM";
						const hours = ((hour + 11) % 12 + 1) + " " + suffix
						labels.push( hours );
					} else {
						labels.push( line.hour );
					}
					return true;
			});
		}
		return labels;
	}

	extractHighestSerieValue( data ) {
		var max = 0;
		data.map( (line) => {
			max = Math.max( max , line[this.filterProp] );
			max = Math.max( max , line[this.filterProp] );
			return true;
		} );
		return max;
	}
	
	handleDistributionTootip({series, seriesIndex, dataPointIndex, w} , fillColors, labels) {
		var currency = "EUR";
		var totalCashIn = 0;
		var totalCashInNumber = 0;

		if( this.details !== null ) {
			this.details.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
						.map((value, index) => {
								//filteredLabels.push( value );
								if(index === seriesIndex) {
									currency = value.currency;
									totalCashIn = value.totalCashIn;
									totalCashInNumber = value.totalNbCashIn;
								}
								return true;
						});
		}
		const formatter = new AmountFormatter( this.props.locale,  currency );
		
		return 	'<div class="apexcharts-tooltip-series-group" style="order: '+( seriesIndex+1 )+'; display: flex; background-color: '+fillColors[seriesIndex]+';">' +
				'	<span class="apexcharts-tooltip-marker" style="background-color: '+fillColors[seriesIndex]+'; display: none;"></span>' +
				'	<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
				'		<div class="apexcharts-tooltip-y-group font-size-lg">' +
				'			<span class="apexcharts-tooltip-text-label">'+ labels[seriesIndex] +' </span>' +
				'			<span class="cm-line-separator"></span>' +
				'		</div>' +
				'		<div class="apexcharts-tooltip-z-group">' +
				'			<span class="apexcharts-tooltip-text-z-label xl"> '+ I18n.get( "Cash in" ) +' : </span>' +
				'			<span class="apexcharts-tooltip-text-z-value">'  + formatter.format(totalCashIn) + '</span>' +
				'			<div class="cm-detail-content">' +
				'				<span class="apexcharts-tooltip-text-z-label xl"> '+ I18n.get( "Cash in Number" ) +' : </span>' +
				'				<span class="apexcharts-tooltip-text-z-value">' + totalCashInNumber + '</span>' +
				'			</div>' +
				'		</div>' +
				'	</div>' +
				'</div>' +
				'';
	}
	
	calculateHeight() {
		if( ( this.details !== null && this.details !== undefined && this.details.length === 0 ) || this.filterSeries(this.state.series).length === 0 ) {
			return "103px";
		}
		var height = 59;
		var ligneHeight = 0;
		if( this.props.type === "donut" ) {
			ligneHeight = 67;
		} else if ( this.props.type === "line" ) {
			ligneHeight = 53;
			if(this.props.shared) {
				ligneHeight = 73;
			}
		}
		if( this.state.rowsPerPage !== -1 ) {
			
			var safeDetail;
			if(this.props.shared) {
				safeDetail = this.details[this.props.selected].data;
			} else {
				safeDetail = this.details;
			}

			if( this.details !== null && this.details !== undefined ) {
				if( this.state.page >= Math.ceil(safeDetail.length / this.state.rowsPerPage) - 1 ) {
					height += ( ( safeDetail.length - ( this.state.page * this.state.rowsPerPage ) )  * ligneHeight ) + 53 + 53;
					return height + "px";
				}
			}

			height += ( this.state.rowsPerPage * ligneHeight ) + 53;
			return height + "px";
		}
		return "600px";
		
	}
	
	render() {
		var css = "cm-graph-auto distribution items-nb-" + this.state.rowsPerPage;
		if ( this.props.type === "line" ) {
			css = "cm-graph-auto comparison items-nb-" + this.state.rowsPerPage;
		}
		
		return (
			<div className={css} style={{height:this.calculateHeight()}}>
				<Chart 	options={this.filterOptions(this.state.options)} 
						series={this.filterSeries(this.state.series)} 
						type={this.props.type}
						height="100%"
						width="100%"
						style={{minWidth:"100%",minHeight:"100%"}}
						className="cm-chart"/>
			</div>
		);
	}

}

export default GraphObsContainer;
