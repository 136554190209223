import React from 'react';
import { I18n } from 'aws-amplify/utils';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Chart from 'react-apexcharts';
//import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
//import Button from '@mui/material/Button';
import {sharedTheme} from '../../Style/SharedTheme';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
//import NavigationSelector from '../Components/NavigationSelector';
//import NodeInspector from '../../Utils/NodeInspector.js';

import './Activations.css';

function DialogLastActivations( props ) {
	const renderListItem = ( device ) => {
		if( device ) {
			return (
				<div className="item" key={`activation-item-${device.id}`}>
					<div className="name">{device.name}</div>
					<div className="activation-time">{new Date(device.firstOpening).toLocaleString(props.locale)}</div>
				</div>
			);
		}
		return null;
	}
	
	const renderList = () => {
		let items = [];
		if( props.list ) {
			props.list.forEach( device => {
				items.push( renderListItem( device ) );
			} );
		}
		return (
			<div className="list-devices">
				{items.map( item => item )}
			</div>
		);
	}
	
	let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	
	return (
		<Menu anchorEl={null}
			  getContentAnchorEl={null}
			  open={Boolean(props.list)}
			  classes={{ list: css, paper:"Centered" }}
			  onClose={props.handleClose} >
			<div className={"dropdown-menu-md overflow-hidden p-3 Menu-CashMonitor-Popup" + (props.isDarkStyle ? " dark" : "")}>
				<div className="grid-menu grid-menu-1col">
					<div className="text-center p-3 dialog-content">
						{renderList()}
					</div>
				</div>
			</div>
		</Menu>
	);
}

let instanceActivation;
class Activations extends React.Component {
	
	constructor( props ) {
		super( props );
		instanceActivation = this;
		this.analyzer = new ConfigAnalyzer( this.props.payStations );
		this.rootCss =  sharedTheme.cardBox + " flex-grow-1" ;//+ " w-100 d-flex align-items-center mb-2 flex-column ";
		this.cssHeader = sharedTheme.cardHeader + "";
		this.state = {
			list:null
		}
	}
	
	extractSeriesMaxValue( series ) {
		let max = 0;
		series.forEach( series => {
			if( series && series.hasOwnProperty("data") ) {
				series.data.forEach( candidate => {
					max = Math.max( max , candidate );
				} );
			}
		} );
		return max;
	}
	
	setList( list ) {
		instanceActivation.setState({
			list:list
		});
	}
	
	handleClose() {
		instanceActivation.setState({
			list:null
		});
	}
	
	getColumnCss( data ) {
		if( data && data.length > 0 ) {
			return "column clickable";
		}
		return "column";
	}
	
	renderActivatedDeviceOverview() {
		const DAY = 1000 * 60 * 60 * 24;
		const cumulativeQuantity = this.analyzer.getDistinctActiveDeviceList().length;
		const todayStart = new Date();
		todayStart.setHours(0, 0, 0);
		todayStart.setMilliseconds(0);
		const today = this.analyzer.getDistinctActivatedDeviceListSince( todayStart.getTime() );
		const yesterday = this.analyzer.getDistinctActivatedDeviceListSince( new Date( todayStart.getTime() - DAY ).getTime() , todayStart.getTime() );
		const lastSeven = this.analyzer.getDistinctActivatedDeviceListSince( new Date( todayStart.getTime() - ( 7 * DAY ) ).getTime() );
		const lastThirty = this.analyzer.getDistinctActivatedDeviceListSince( new Date( todayStart.getTime() - ( 30 * DAY ) ).getTime() );
		const cumulativeQuantitySoftware = this.analyzer.getCountActivatedSoftwareSince( new Date('2020-01-01T00:00:00') );
		const todaySoftware = this.analyzer.getCountActivatedSoftwareSince( todayStart.getTime() );
		const yesterdaySoftware = this.analyzer.getCountActivatedSoftwareSince( new Date( todayStart.getTime() - DAY ).getTime() , todayStart.getTime() );
		const lastSevenSoftware = this.analyzer.getCountActivatedSoftwareSince( new Date( todayStart.getTime() - ( 7 * DAY ) ).getTime() );
		const lastThirtySoftware = this.analyzer.getCountActivatedSoftwareSince( new Date( todayStart.getTime() - ( 30 * DAY ) ).getTime() );
		const softwareLabels = this.analyzer.getLabelsForDeviceSoftwares();
		const softwareSeries = this.analyzer.getSeriesForDeviceSoftwares();
		const typeLabels = this.analyzer.getLabelsForType();
		const typeSeries = this.analyzer.getSeriesForType();
		return (
			<Card className={this.rootCss}>
				<div className={this.cssHeader}>
					<div className={sharedTheme.cardHeaderTitle}>
						<b className={sharedTheme.cardHeaderTitleBigBlack}>{I18n.get("Activation overview")}</b>
					</div>
				</div>
				<div className="content-table">
					<div className="column">
						{I18n.get("Hardware")}
					</div>
					<div className="separator"></div>
					<div className="column">
						<div className="label">{I18n.get("Total")}</div>
						<div className="number">{cumulativeQuantity}</div>
					</div>
					<div className="separator"></div>
					<div className={this.getColumnCss( today )} onClick={(evt) => {
						if( today.length > 0 ) {
							instanceActivation.setList( today );
						}
					}}>
						<div className="label">{I18n.get("Today")}</div>
						<div className="number">{today.length}</div>
					</div>
					<div className="separator"></div>
					<div className={this.getColumnCss( yesterday )} onClick={(evt) => {
						if( yesterday.length > 0 ) {
							instanceActivation.setList( yesterday );
						}
					}}>
						<div className="label">{I18n.get("Yesterday")}</div>
						<div className="number">{yesterday.length}</div>
					</div>
					<div className="separator"></div>
					<div className={this.getColumnCss( lastSeven )} onClick={(evt) => {
						if( lastSeven.length > 0 ) {
							instanceActivation.setList( lastSeven );
						}
					}}>
						<div className="label">{I18n.get("Last 7 days")}</div>
						<div className="number">{lastSeven.length}</div>
					</div>
					<div className="separator"></div>
					<div className={this.getColumnCss( lastThirty )} onClick={(evt) => {
						if( lastThirty.length > 0 ) {
							instanceActivation.setList( lastThirty );
						}
					}}>
						<div className="label">{I18n.get("Last 30 days")}</div>
						<div className="number">{lastThirty.length}</div>
					</div>
				</div>
				<div className="content-table">
					<div className="column">
						{I18n.get("Software")}
					</div>
					<div className="separator"></div>
					<div className="column">
						<div className="label">{I18n.get("Total")}</div>
						<div className="number">{cumulativeQuantitySoftware}</div>
					</div>
					<div className="separator"></div>
					<div className="column">
						<div className="label">{I18n.get("Today")}</div>
						<div className="number">{todaySoftware}</div>
					</div>
					<div className="separator"></div>
					<div className="column">
						<div className="label">{I18n.get("Yesterday")}</div>
						<div className="number">{yesterdaySoftware}</div>
					</div>
					<div className="separator"></div>
					<div className="column">
						<div className="label">{I18n.get("Last 7 days")}</div>
						<div className="number">{lastSevenSoftware}</div>
					</div>
					<div className="separator"></div>
					<div className="column">
						<div className="label">{I18n.get("Last 30 days")}</div>
						<div className="number">{lastThirtySoftware}</div>
					</div>
				</div>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6} lg={6} className="pie-container">
						<Chart 	options={{
											legend: {
												position: 'left',
												horizontalAlign: 'left', 
											},
											labels: softwareLabels,
											colors:['#10c5db', 'rgb(0, 143, 251)', 'rgb(0, 227, 150)' , 'rgb(254, 176, 25)' , 'rgb(255, 69, 96)' , 'rgb(119, 93, 208)' ,'#d622a1' , '#008080'],
										}} 
								series={softwareSeries}
								type="pie"
								height="100%"
								width="100%"
								style={{minWidth:"100%",minHeight:"18em", maxHeight:"18em"}}
								className="cm-chart"/>
					</Grid>
					<Grid item xs={12} md={6} lg={6} className="pie-container">
						<Chart 	options={{	legend: {
												position: 'right',
												horizontalAlign: 'right', 
											},
											labels:typeLabels,
											colors:['#10c5db', 'rgb(0, 143, 251)', 'rgb(0, 227, 150)' , 'rgb(254, 176, 25)' , 'rgb(255, 69, 96)' , 'rgb(119, 93, 208)' ,'#d622a1' , '#008080'],
										}} 
								series={typeSeries}
								type="pie"
								height="100%"
								width="100%"
								style={{minWidth:"100%",minHeight:"18em", maxHeight:"18em"}}
								className="cm-chart"/>
					</Grid>
				</Grid>
				<DialogLastActivations 	handleClose={this.handleClose}
									    list={this.state.list}
										locale={this.props.locale}
										isDarkStyle={this.props.isDarkStyle}/>
			</Card>
		);
	}
	
	renderActivatedDeviceGraph() {
		let spaceGraphBefore = 0;
		let spaceGraphAfter = 1;

		let timelines = this.analyzer.getDistinctTimeline( 15 );
		let series = this.analyzer.generateSeriesForTimelines( timelines );
		let categories = this.analyzer.formatTimeline( timelines , this.props.locale , true );

		let maxValue = this.extractSeriesMaxValue( series ) + 1;
		return (
			<Card className={this.rootCss}>
				<div className={this.cssHeader}>
					<div className={sharedTheme.cardHeaderTitle}>
						<b className={sharedTheme.cardHeaderTitleBigBlack}>{I18n.get("Device activation timeline")}</b>
					</div>
				</div>
				<div className="content-table graph">
		
				<Chart 	options={{
										chart: {
											id: "basic-line",
											dropShadow: {
												enabled: true,
												opacity: 0.3,
												blur: 5,
												left: -7,
												top: 22
											},
										},
										markers:{
											size:2
										},
										stroke: {
											curve: 'smooth'
										},
										colors:['#10c5db', 'rgb(0, 143, 251)', 'rgb(0, 227, 150)' , 'rgb(254, 176, 25)' , 'rgb(255, 69, 96)' , 'rgb(119, 93, 208)' ,'#d622a1' , '#008080'],
										xaxis: {
											min:spaceGraphBefore,
											max:(timelines.length + spaceGraphAfter),//replace with dynamic
											labels: {
												rotate: -45,
												formatter: function (value) {
													if( value === spaceGraphBefore || value === ( timelines.length + spaceGraphAfter ) ) {
														return " ";
													}
													
													if(value > 0) {
														return categories[value-1];
													} 
													return value // The formatter function overrides format property
												}, 
											}
										},
										yaxis: {
											min:0,
											max:maxValue,
											labels: {
												formatter: function (value) {
													return "" + parseInt( value ) ;
												}
											},
										},
									 }} 
							series={series} 
							type="line"
							height="100%"
							width="100%"
							style={{minWidth:"100%",minHeight:"30em", maxHeight:"30em", marginBottom:"2.5em"}}
							className="cm-chart"/>
				</div>
			</Card>
		);
	}

	render() {
		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.rootNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>	
				<div className="z-over fullscreen Activations-root">
					<Grid container spacing={1}>
						<Grid item xs={12} md={12} lg={12}>
							{this.renderActivatedDeviceOverview()}
						</Grid>
						<Grid item xs={12} md={12} lg={12}>
							{this.renderActivatedDeviceGraph()}
						</Grid>
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}

export default Activations;
