import React from 'react';
import {createTheme} from '@mui/material/styles';
import {styled, ThemeProvider} from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {I18n} from 'aws-amplify/utils';
import AppBar from './Components/AppBar/AppBar';
import LoadingIndicator from './Components/LoadingIndicator/LoadingIndicator';
import SnackBar from './Components/SnackBar/SnackBar';
import Administration from "./Views/Administration/Administration";
import Diagnostic from "./Views/Diagnotic/Diagnostic";
import Hardwares from "./Views/Hardwares/Hardwares";
import MobileDetection from '../Utils/MobileDetection';
import UserDataManager from "../Utils/UserDataManager";
import GraphQlTool from '../Utils/GraphQlTool';
import InactivityDelegate from '../Utils/InactivityDelegate';
import SyncDataManager from "../Utils/SyncDataManager";

import {getUserByUsername} from '../graphql/queries';

import './Dashboard.css';
import Activations from "./Views/Activations/Activations";
import Operation from "./Views/Operation/Operation";
import Transaction from "./Views/Transaction/Transaction";
import Message from "./Views/Message/Message";
import Inventory from "./Views/Inventory/Inventory";
import Account from "./Views/Account/Account";
import Settings from "./Views/Settings/Settings";
import Distribution from "./Views/Distribution/Distribution";
import Comparison from "./Views/Comparison/Comparison";
import {PeriodSynchronizer} from "../Utils/PeriodSynchronizer";
import Features from "../Models/features";
import DisconnectionDialog from "./Components/DisconnectionDialog/DisconnectionDialog";
import ConfigAnalyzer from "../Utils/ConfigAnalyzer";
import License from "./Views/License/License";
import LicenseIndicator from "./Components/LicenseIndicator/LicenseIndicator";
import {UserRoles} from "../Models/Roles";
import LicenseHistory from "./Views/License/LicenseHistory";
import Home from "./Views/Home/Home";

let currentSelectedNode = null;
let currentSelectedBoxId = null;
let currentSelectedDeviceId = null;
let filterDelegate;
let lastUpdate = null;
let currentDashboardInstance;
class Dashboard extends React.Component {
    constructor( props ) {
        super( props );
        this.id = '-dashboard';
        this.mobileDetector = new MobileDetection();
        this.locale = this.getLang();
        this.selectedIndex = 4;
        this.processLoadingData = false;
        this.state = {
            open:! this.mobileDetector.isMobile(),
            expanded:false,
            selectedIndex:4,
            darkStyle:true,
            selectedNode:currentSelectedNode,
            selectedBoxId:currentSelectedBoxId,
            selectedDeviceId:currentSelectedDeviceId,
            operations: [],
            currencies: ['EUR'],
            currencySelected: 'EUR',
            inventories: [],
            states:[],
            forceRefresh: false,
            features: [],
            messageLevel: 0
        };
        this.loadingStates = {
            operation:false,
            inventory:false,
            message:false
        };
        this.pdfDataDelegate = {};
        this.inactivityDelegate = new InactivityDelegate( this );
        this.periodSynchronizer = new PeriodSynchronizer();
        this.snackbarRef = React.createRef();
        this.currentCurrencies = ['EUR'];
        this.queuedNotification = [];
        currentDashboardInstance = this;
    }

    componentDidMount() {
        const retrieveUser = new Promise( resolve => {
            if( currentDashboardInstance.props.API ) {
                currentDashboardInstance.processLoadingData = true;
                const userEmail = currentDashboardInstance.props.user.username;
                this.props.API
                    .graphql({ query: getUserByUsername, variables: { username: userEmail }})
                    .then( returned  => {
                        const tool = new GraphQlTool( 'getUserByUsername' , returned );
                        currentDashboardInstance.currentUser = tool.extract();
                        currentDashboardInstance.currentUser.attributes = JSON.parse( currentDashboardInstance.currentUser.attributes );

                        if( currentDashboardInstance.currentUser ) {
                            if( this.props.fetchAuthSession ) {
                                this.props.fetchAuthSession().then( authSession => {
                                    currentDashboardInstance.currentUser.accessToken = authSession.tokens.accessToken.toString();
                                    currentDashboardInstance.currentUser.authTime = authSession.tokens.accessToken.payload.auth_time;
                                    currentDashboardInstance.currentUser.features = Features.identifyFeaturesAccess( currentDashboardInstance.currentUser.uuid );

                                    const userManager = new UserDataManager(
                                        {
                                            subjectId: currentDashboardInstance.currentUser.uuid ,
                                            authTime: authSession.tokens.accessToken.payload.auth_time ,
                                            idToken:'to-remove'
                                        } , currentDashboardInstance );

                                    resolve( currentDashboardInstance.currentUser );
                                } );
                            }
                        }
                    })
                    .catch( error => {
                        console.error( error );
                        if( error.errors &&
                            error.errors.length > 0 &&
                            error.errors[0].errorType === "RDSHttp:ExecutionTimeoutException" ) {
                            window.location.reload(); //database may take time to start the cluster reload for timeout
                        }
                    })
            } else {
                console.error( "Invalid API configuration" );
            }
        } );
        const loadUserDevicesData = user => {
            return new Promise( resolve => {
                if( currentDashboardInstance.props.API ) {
                    currentDashboardInstance.syncDataManager = new SyncDataManager(
                        user ,
                        currentDashboardInstance
                    );
                    currentDashboardInstance.syncDataManager.subscribe();
                }
                resolve();
            } );
        };
        if( currentDashboardInstance.props.user ) {
            retrieveUser
                .then( user => loadUserDevicesData( user ) )
                .then( () => {
                    //currentDashboardInstance.releaseLoaderIndicator();
                } );
        }
    }

    componentWillUnmount() {
        console.log('will unmount');
        if( currentDashboardInstance.syncDataManager ) {
            console.log('unsubscribe');
            currentDashboardInstance.syncDataManager.unsubscribe();
        }
    }

    registerFilterDelegate( toRegister ) {
        filterDelegate = toRegister;
    }

    onLoggedUserUpdated( newUser , requiresReload = false ) {
        this.userRDS = newUser;
        if( requiresReload ) {
            currentDashboardInstance.inactivityDelegate.setDisconnected( true );
            currentDashboardInstance.onDisconnection( I18n.get("Disconnected after user changed") , true );
        }
    }

    onDisconnection( message , forceLogout = false ) {
        currentDashboardInstance.notify(
            currentDashboardInstance.disconnectionDialogRef ,
            "onDisconnection" ,
            {disconnected:currentDashboardInstance.inactivityDelegate.isDisconnected(),message:message, forceLogout:forceLogout}
        );
    }

    onUserPreferencesLoaded( userManager ) {
        currentDashboardInstance.setState({
            user:userManager,
            open:currentDashboardInstance.state.open,
            expanded:currentDashboardInstance.state.expanded,
            selectedIndex:currentDashboardInstance.state.selectedIndex,
            darkStyle:userManager.userProperties.properties.themeStyle,
            selectedNode:currentDashboardInstance.state.selectedNode,
            operations: currentDashboardInstance.state.operations,
            states: currentDashboardInstance.state.states
        });
    }

    onLicenseRequired( missingLicenses ) {
        currentDashboardInstance.setState( {missingLicenses: missingLicenses} )
    }

    onDataLoaded( payStations ) {
        currentDashboardInstance.payStations = payStations;
        currentDashboardInstance.processLoadingData = false;
        currentDashboardInstance.onLoadingRoutineCompleted();
    }

    onFilterUpdated( filteredPayStations ) {
        if( ! currentDashboardInstance.processLoadingData ) {
            if( ! lastUpdate || Date.now() - lastUpdate > 100 ) {
                lastUpdate = Date.now();
                setTimeout( () => {
                    currentDashboardInstance.setState({
                        filteredPayStations: filteredPayStations,
                    } );
                } , 100 );
            }
        }
    }

    onCurrenciesUpdate( currencies ) {
        if( currentDashboardInstance.currentCurrencies?.length !== currencies?.length ) {
            currentDashboardInstance.currentCurrencies = currencies
            currentDashboardInstance.setState({
                currencies: currencies
            } );
        }
    }

    onCurrencySelection( currencyCode ) {
        currentDashboardInstance.setState({
            currencySelected: currencyCode
        } );
    }

    onLoadingRoutineCompleted() {
        //TMP
        if( ! currentDashboardInstance.processLoadingData ) {
            currentDashboardInstance.setState({
                currentUser: currentDashboardInstance.currentUser,
            } );
            currentDashboardInstance.releaseLoaderIndicator();
        } else {
            console.log("Still processing data");
        }
        /*if ( ! currentDashboardInstance.processLoadingData && ! currentDashboardInstance.syncDataManager.isLoaded ) {
            currentDashboardInstance.notify(
                currentDashboardInstance.appBarRef ,
                "setCurrentNode" ,
                currentDashboardInstance.state.user.rootNode
            );
            currentDashboardInstance.syncDataManager.onInitialLoadingCompleted();
            currentDashboardInstance.releaseLoaderIndicator();
        }

        if( currentDashboardInstance.syncDataManager.isNavigationRefresh ) {
            currentDashboardInstance.syncDataManager.onRefreshComplete();
            currentDashboardInstance.notify(
                currentDashboardInstance.monitoringRef ,
                "onRefreshStateUpdated" ,
                false
            );
        }

        if( currentDashboardInstance.isFailureSync ) {
            currentDashboardInstance.notify(
                currentDashboardInstance.appBarRef ,
                "setSyncState" ,
                {isOffline:false , isLoading:false}
            );
        }*/
    }

    onPayStationRemoved( boxId ) {
        const newList = [];
        currentDashboardInstance.payStations.forEach( payStation => {
            if( payStation.box.id !== boxId ) {
                newList.push( payStation );
            }
        } );
        currentDashboardInstance.payStations = newList;
        currentDashboardInstance.setState({
            user:currentDashboardInstance.state.user,
            open:currentDashboardInstance.state.open,
            expanded:currentDashboardInstance.state.expanded,
            selectedIndex:currentDashboardInstance.state.selectedIndex,
            darkStyle:currentDashboardInstance.state.darkStyle,
            selectedNode:currentDashboardInstance.state.selectedNode,
            operations: currentDashboardInstance.state.operations,
            states: currentDashboardInstance.state.states,
            removedBoxId: boxId
        });
    }

    onRequestedBoxOverview( boxId ) {
        currentSelectedBoxId = boxId;
        currentDashboardInstance.setState({
            selectedBoxId: currentSelectedBoxId,
            selectedIndex: 15
        });
    }

    onRequestedBoxDiagnostic( boxId , deviceId = 0 ) {
        currentSelectedBoxId = boxId;
        currentSelectedDeviceId = deviceId;
        currentDashboardInstance.setState({
            selectedBoxId: currentSelectedBoxId,
            selectedIndex: 17,
            selectedDeviceId: currentSelectedDeviceId
        });
    }

    onRequestedMessages( boxId , deviceId = 0 , level = 0 ) {
        currentSelectedBoxId = boxId;
        currentSelectedDeviceId = deviceId;
        currentDashboardInstance.setState({
            selectedBoxId: currentSelectedBoxId,
            selectedIndex: 6,
            selectedDeviceId: currentSelectedDeviceId,
            messageLevel: level
        });
    }

    onNewSubscriptionReceived( type , data ){
        currentDashboardInstance.notify(
            currentDashboardInstance.appBarRef ,
            "onDataUpdated" ,
            new Date()
        );
        currentDashboardInstance.notify(
            currentDashboardInstance.inactivityDelegate ,
            "updateActivity" ,
            new Date()
        );
        switch ( type ) {
            case "onNewOperation":
                console.log( 'onNewOperation' , data );
                currentDashboardInstance.addOperationToPayStation( data )
                    .then( () => {
                        currentDashboardInstance.notify(
                            currentDashboardInstance.comparisonRef ,
                            "onNewIncomingOperation"
                        );

                        currentDashboardInstance.notify(
                            currentDashboardInstance.distributionRef ,
                            "onNewIncomingOperation"
                        );

                        currentDashboardInstance.notify(
                            currentDashboardInstance.transactionRef ,
                            "onNewIncomingOperation"
                        );

                        currentDashboardInstance.notify(
                            currentDashboardInstance.operationRef ,
                            "onNewIncomingOperation"
                        );
                    } );
                break;
            case "onNewInventory":
                currentDashboardInstance.updateDeviceInventory( data )
                    .then( () => {
                        currentDashboardInstance.notify(
                            currentDashboardInstance.inventoryRef ,
                            "onNewInventory" ,
                            data
                        );
                    } );
                break;
            case "onNewState":
                currentDashboardInstance.updateDeviceState( data )
                    .then( () => {
                        currentDashboardInstance.notify(
                            currentDashboardInstance.home ,
                            "onNewState" ,
                            data
                        );
                    } );
                break;
            case "onNewTechnicalData":
                currentDashboardInstance.updateDeviceTechnicalData( data )
                    .then( () => {
                        currentDashboardInstance.notify(
                            currentDashboardInstance.diagnosticRef ,
                            "onNewTechnicalData" ,
                            data
                        );
                    } );
                break;
            case "onNewNotificationState":
                currentDashboardInstance.onNotificationProgressReceived( data );
                break;
            default:
                break;
        }
    }

    onSubscriptionError( error ) {
        console.warn( error );
        let reloadConnection = false;
        try {
            //extract error message
            if( error.error.errors[0].message === "Connection closed" )  {
                //network anomaly on websocket
                reloadConnection = true;
            }
        } catch ( parseError ) {
            //ignored
        }
        if( reloadConnection ) {
            if( currentDashboardInstance.lostSyncAt === null ) {
                currentDashboardInstance.lostSyncAt = new Date();
                currentDashboardInstance.notify(
                    currentDashboardInstance.appBarRef ,
                    "setSyncState" ,
                    { isOffline: true , isLoading: false }
                );
            }
        } else {
            currentDashboardInstance.inactivityDelegate.forceDisconnection( error );
        }
        console.log( 'To do handle error !!!' , error );
    }

    onNotificationSent( parameters ) {
        const FIVE_SECONDES = 5000;
        const timeoutId = setTimeout( () => {
            parameters.progressState = "failed";
            parameters.comment = "TIMEOUT";
            currentDashboardInstance.onNotificationProgressReceived(parameters );
        } , FIVE_SECONDES );
        currentDashboardInstance.queuedNotification.push( { jobId: parameters.jobId , timeoutId: timeoutId } );
    }

    onNotificationProgressReceived( parameters ) {
        let newQueue = [];
        currentDashboardInstance.queuedNotification.forEach( queued => {
            if( queued.jobId === parameters.jobId ) {
                switch( parameters.progressState ) {
                    case "pending" : currentDashboardInstance.handleSnackbar( "info" , I18n.get( "The command" ) + " " + I18n.get( parameters.command ) + " " + I18n.get( "is executing" ) , true );
                        break;
                    case "completed" : currentDashboardInstance.handleSnackbar( "success" , I18n.get( "The command" ) + " " + I18n.get( parameters.command ) + " " + I18n.get( "has finished" ) , true );
                        break;
                    case "failed" : currentDashboardInstance.handleSnackbar( "error" , I18n.get( "The command" ) + " " + I18n.get( parameters.command ) + " " + I18n.get( "has failed with reason" ) + " : " + parameters.comment , true );
                        break;
                    default: console.warn( "Unsupported progress state" , parameters.progressState );
                        break;
                }
                clearTimeout( queued.timeoutId )
            } else {
                newQueue.push( queued ); //pending job
            }
        } );
        currentDashboardInstance.queuedNotification = newQueue;
    }

    onBoxUpdated( preferences ) {
        currentDashboardInstance.payStations?.forEach( payStation => {
            if( payStation.box?.id === preferences.boxId ) {
                if( payStation.box?.informations?.attributes?.name ) {
                    payStation.box.informations.attributes.name = preferences.box;
                } else {
                    payStation.box.name = preferences.box;
                }

                payStation.terminals?.forEach( terminal => {
                    const terminalItem = preferences.terminals[payStation.terminals.indexOf( terminal )];
                    if( terminal.informations?.attributes?.terminal ) {
                        terminal.informations.attributes.terminal = terminalItem?.name;
                        terminal.informations.attributes.company = preferences.company;
                        terminal.informations.attributes.shop = preferences.shop;
                    } else {
                        terminal.name = terminalItem?.name;
                    }
                } );
            }
        } );
    }

    notify( reference , methodName , data ) {
        if ( reference ) {
            reference[methodName]( data );
        }
    }

    reloadSubscription() {
        if( currentDashboardInstance.syncDataManager ) {
            currentDashboardInstance.syncDataManager.unsubscribe();
            currentDashboardInstance.syncDataManager.subscribe();
        }
    }

    releaseLoaderIndicator() {
        let loader = document.querySelector("#modal-loader");
        if( loader ) {
            loader.className = "";
        } else {
            setTimeout( () => { document.querySelector("#modal-loader").className = ""; } , 1000 );
        }
    }

    getLang() {
        let locale = navigator.language || navigator.browserLanguage || ( navigator.languages || [ "fr" ] )[0];
        if( locale.indexOf("-") === -1 ) {
            locale = `${locale}-${locale.toUpperCase()}`;
        }
        return locale;
    }

    getAppTitle( forReports = false ) {
        switch( this.state.selectedIndex ) {
            case 0: return I18n.get("Dashboard");
            case 1: return I18n.get("Reports");
            case 2: return I18n.get("Environment");
            case 3: return I18n.get("Cashiers");
            case 4: return I18n.get("Cash in");
            case 5: return ( forReports ) ? I18n.get("Inventory") : I18n.get("Inventories");
            case 6: return I18n.get("Messages");
            case 7: return I18n.get("Map");
            case 8: return I18n.get("Comparisons");
            case 9: return I18n.get("Operations");
            case 10: return I18n.get("Distributions");
            case 11: return I18n.get("Account");
            case 12: return I18n.get("Setting");
            case 13: return I18n.get("Administration");
            case 14: return I18n.get("Activations");
            case 15: return I18n.get("Overview");
            case 16: return I18n.get("Forecasts");
            case 17: return I18n.get("Diagnostic");
            case 18: return I18n.get("Licenses assignment");
            case 19: return I18n.get("Licenses history");
            default: return I18n.get("");
        }
    }

    getTheme() {
        return createTheme({
            palette: {
                type: this.state.darkStyle ? 'dark' : 'light',
            },
        })
    }

    handleLogout() {
        const removeCurrentDevice = new Promise((resolve, reject) => {
            let fileUpdateObserver = {
                onFileUpdated:function() {
                    currentDashboardInstance.state.user.unregisterFileObserver();
                    resolve();
                }
            }
            currentDashboardInstance.state.user.registerFileObserver( fileUpdateObserver );
            currentDashboardInstance.state.user.userProperties.removeCurrentDevice();
        });

        removeCurrentDevice.then( () => {
            currentDashboardInstance.props.signOut()
        } );
    }

    handleStyleChanged() {
        currentDashboardInstance.state.user.updatePreferences( "themeStyle" , !currentDashboardInstance.state.darkStyle );
        currentDashboardInstance.setState({
            user:currentDashboardInstance.state.user,
            open:currentDashboardInstance.state.open,
            expanded:currentDashboardInstance.state.expanded,
            selectedIndex:currentDashboardInstance.state.selectedIndex,
            darkStyle:!currentDashboardInstance.state.darkStyle,
            selectedNode:currentDashboardInstance.state.selectedNode,
        });
    }

    handleListItemClick(event, index , optData) {
        if( index != null ) {
            //secure unpredictable events triggered from the template
            currentDashboardInstance.optionalNavigationData = optData;
            let isOpen = currentDashboardInstance.state.open;
            if( currentDashboardInstance.mobileDetector.isMobile() && index !== 1 ) {
                isOpen = false;
            }
            currentDashboardInstance.selectedIndex = index;
            currentDashboardInstance.setState({
                user:currentDashboardInstance.state.user,
                open:isOpen,
                expanded:currentDashboardInstance.state.expanded,
                selectedIndex:index,
                darkStyle:currentDashboardInstance.state.darkStyle,
                selectedNode:currentDashboardInstance.state.selectedNode,
            });

        }
        //ScheduledAnimationManager.clear();
    };

    handleSnackbar( type , message , isTranslated = false) {
        let icon = [];
        switch( type ) {
            case "success":icon = ["fas" , "check-circle"];
                break;
            case "warning":icon = ["fas" , "exclamation-triangle"];
                break;
            case "info":icon = ["fas" , "info-circle"];
                break;
            case "error":icon = ["fas" , "times-circle"];
                break;
            default:
                break;
        }

        const displayedMessage = ( isTranslated ) ? message : I18n.get( message );
        this.snackbarRef?.current?.openSnackBar( type , displayedMessage , 8000 , icon );
    }

    extractCurrencies( payStations ) {
        const currencies = [];
        console.log( 'extractCurrencies' , payStations );

        if( payStations && payStations.length > 0 ) {
            payStations.forEach( payStation => {
                payStation.terminals?.forEach( terminal => {
                    terminal.operations?.forEach( operation => {
                        if( ! currencies.includes( operation.currency ) ) {
                            currencies.push( operation.currency );
                        }
                    } );
                } );
            } );
        }

        if( currencies.length < 1 ) {
            currencies.push( 'EUR' );
        }

        return currencies;
    }

    addOperationToPayStation( operation ) {
        return new Promise( resolve => {
            const analyzer = new ConfigAnalyzer( this.state.filteredPayStations );
            const terminals = analyzer.getDistinctActiveTerminalList();
            terminals.forEach( terminal => {
                if( terminal.informations?.bms === operation.cbms.replace( '6_' , '' ) ) {
                    terminal.operations.push( operation );
                }

                if( terminals.indexOf( terminal ) === terminals.length - 1 ) {
                    resolve();
                }
            } );
        } );
    }

    updateDeviceInventory( inventory ) {
        return new Promise( resolve => {
            this.state.filteredPayStations?.forEach( payStation => {
                if( payStation.devices ) {
                    payStation.devices.forEach( device => {
                        if( device.latestInventory?.cbms === inventory.cmbs ) {
                            console.log( 'Update device' , device );
                            device.latestInventory = inventory;
                        }
                    } );
                }
                if( this.state.filteredPayStations.indexOf( payStation ) === this.state.filteredPayStations.length - 1 ) {
                    resolve();
                }
            } );
        } );
    }

    updateDeviceTechnicalData( technicalData ) {
        return new Promise( resolve => {
            this.state.filteredPayStations?.forEach( payStation => {
                if( payStation.devices ) {
                    payStation.devices.forEach( device => {
                        if( device.technicalData?.cbms === technicalData.cmbs ) {
                            device.technicalData = technicalData;
                        }
                    } );
                }
                if( this.state.filteredPayStations.indexOf( payStation ) === this.state.filteredPayStations.length - 1 ) {
                    resolve();
                }
            } );
        } );
    }

    updateDeviceState( state ) {
        return new Promise( resolve => {
            this.state.filteredPayStations?.forEach( payStation => {
                if( payStation.devices ) {
                    payStation.devices.forEach( device => {
                        if( device.lastState?.cbms === state.cmbs ) {
                            device.lastState = state;
                        }
                    } );
                }
                if( this.state.filteredPayStations.indexOf( payStation ) === this.state.filteredPayStations.length - 1 ) {
                    resolve();
                }
            } );
        } );
    }

    renderNavigation() {
        const titleApp = this.getAppTitle();
        return (
            <AppBar locale={this.locale}
                    title={titleApp}
                    handleLogout={this.handleLogout}
                    handleStyleChanged={this.handleStyleChanged}
                    isDesktop={!this.mobileDetector.isMobile()}
                    isIOS={!this.mobileDetector.isIOS()}
                    handleListItemClick={this.handleListItemClick}
                    selectedIndex={(this.state.user?.role === UserRoles.ADMINISTRATIVE) ? 17 : this.selectedIndex}
                    isDarkStyle={this.state.darkStyle}
                    lastUpdate={new Date()}
                    emailDelegate={this.emailDelegate}
                    currentUser={this.state.currentUser}
                    selectedNode={currentSelectedNode}
                    user={this.state.user}
                    observer={this}
                    isSearchMenuOpened={this.isSearchMenuOpened}
                    onSearchMenuOpenedStateChanged={this.onSearchMenuOpenedStateChanged}
                    onRefreshRequested={this.onRefreshRequested}
                    payStations={this.payStations}
                    currencies={this.state.currencies}
                    onCurrencySelection={this.onCurrencySelection}
                    registerFilterDelegate={this.registerFilterDelegate}
                    onFilterUpdated={this.onFilterUpdated}
                    ref={(component) => currentDashboardInstance.appBarRef = component}
            />
        );

        /*
        return (
            <AppBar cssClasses={this.classes}
                    title={titleApp}
                    handleLogout={this.handleLogout}
                    handleStyleChanged={this.handleStyleChanged}
                    isDesktop={!this.mobileDetector.isMobile()}
                    isIOS={!this.mobileDetector.isIOS()}
                    handleListItemClick={this.handleListItemClick}
                    selectedIndex={this.selectedIndex}
                    isDarkStyle={this.state.darkStyle}
                    lastUpdate={new Date()}
                    emailDelegate={this.emailDelegate}
                    userRDS={this.userRDS}
                    selectedNode={currentSelectedNode}
                    user={this.state.user}
                    isSearchMenuOpened={this.isSearchMenuOpened}
                    onSearchMenuOpenedStateChanged={this.onSearchMenuOpenedStateChanged}
                    onRefreshRequested={this.onRefreshRequested}
                    isLoading={this.isFailureSync}
                    isOffline={this.lostSyncAt !== null}
                    onReconnecting={this.onReconnectionRequired}
                    observer={currentDashboardInstance}
                    onNodeChanged={this.handleNodeChanged}
                    ref={(component) => currentDashboardInstance.appBarRef = component}
            />
        );
         */
    }

    renderMain() {
        let rendered;
        switch(this.state.selectedIndex) {
            case 0: rendered = null;//this.renderHome();
                break;
            case 1: rendered = null;
                break;
            case 2: rendered = null;
                break;
            case 3: rendered = null;
                break;
            case 4: rendered = this.renderTransactions();
                break;
            case 5: rendered = this.renderInventory();
                break;
            case 6: rendered = this.renderMessages();
                break;
            case 7: //rendered = this.renderMap();
                break;
            case 8: rendered = this.renderComparisons();
                break;
            case 9: rendered = this.renderOperations();
                break;
            case 10: rendered = this.renderDistributions();
                break;
            case 11: rendered = this.renderAccount();
                break;
            case 12: rendered = this.renderSettings();
                break;
            case 13: rendered = this.renderAdmin();
                break;
            case 14: rendered = this.renderActivations();
                break;
            case 15: rendered = this.renderHardwares();
                break;
            case 16: //rendered = this.renderForecasts();
                break;
            case 17: rendered = this.renderDiagnostic();
                break;
            case 18: rendered = this.renderLicenses();
                break;
            case 19: rendered = this.renderLicensesHistory();
                break;
            case 20: rendered = this.renderMonitoring();
                break;
            default: rendered = null;
                break;
        }

        if( this.state.currentUser?.role === UserRoles.ADMINISTRATIVE ) {
            rendered = (this.state.selectedIndex === 18) ? this.renderLicenses() : this.renderLicensesHistory();
        }

        let MainDiv;
        if( this.mobileDetector.isMobile() ) {
            MainDiv = styled('div')(
                ({ theme }) => `
                    width: calc( 100% - ${theme.spacing(7)}px );
                    min-height: 91.05vh;
                    overflowX: hidden!important;
                    overflow: auto;
                    margin-left: ${theme.spacing(7)}px;
                    margin-top: 64px;
                `,
            );
        } else {
            MainDiv = styled('div')(
                ({ theme }) => `
                    flex-grow: 1;
                    min-height: 91.05vh;
                    margin-left: 0px!important;
                    margin-top: 82px;
                `,
            );
        }

        return (
            <MainDiv className={`main`}>
                <Container maxWidth="xl" >
                    <Grid container spacing={1}>
                        {rendered}
                    </Grid>
                </Container>
            </MainDiv>
        );
    }

    renderMonitoring() {
        return (
            <Home   locale={this.locale}
                    isDarkStyle={this.state.darkStyle}
                    isDesktop={!this.mobileDetector.isMobile()}
                    filteredPayStations={this.state.filteredPayStations}
                    API={this.props.API}
                    observer={this}
                    currentUser={this.currentUser}
                    onRequestedBoxOverview={this.onRequestedBoxOverview}
                    onRequestedBoxDiagnostic={this.onRequestedBoxDiagnostic}
                    onRequestedMessages={this.onRequestedMessages}
                    onRequestedOperations={this.handleListItemClick}
                    ref={ component => currentDashboardInstance.home = component} />
        );
    }

    renderAdmin() {
        return (
            <Administration locale={this.locale}
                            isDarkStyle={this.state.darkStyle}
                            isDesktop={!this.mobileDetector.isMobile()}
                            payStations={this.payStations}
                            filteredPayStations={this.state.filteredPayStations}
                            onPaystationRemoved={this.onPayStationRemoved}
                            onRequestedBoxOverview={this.onRequestedBoxOverview}
                            onRequestedBoxDiagnostic={this.onRequestedBoxDiagnostic}
                            removedBoxId={this.state.removedBoxId}
                            API={this.props.API}
                            observer={this} />
        );
    }

    renderHardwares() {
        /*let analyzer = new DataAnalyzer(
            currentDashboardInstance.syncDataManager.registeredNodes ,
            currentDashboardInstance.inventoriesDelegate.getDataList(),
            currentDashboardInstance.operationsDelegate.getDataList(),
            currentDashboardInstance.messagesDelegate.getDataList()
        );
        analyzer.analyze();*/
        //console.log("TODO : Test flag for visibility to define");
        //TMP open full access for dev !!!
        //console.log("render hardware" , currentDashboardInstance.currentInspector );
        if( true ) {
            /*<Hardwares locale={this.locale}
                            isDarkStyle={this.state.darkStyle}
                            isDesktop={!this.mobileDetector.isMobile()}
                            observer={this}
                            boxList={currentBoxList}
                            inspector={currentDashboardInstance.currentInspector}
                            onNodeChanged={this.handleNodeChanged}
                            rootNode={currentSelectedNode} />*/
            return (
                <Hardwares locale={this.locale}
                           isDarkStyle={this.state.darkStyle}
                           isDesktop={!this.mobileDetector.isMobile()}
                           observer={this}
                           selected={this.state.selectedBoxId}
                           payStations={this.payStations} />
            );
        }
        return null;
    }

    renderDiagnostic() {
        return (
            <Diagnostic locale={this.locale}
                        isDarkStyle={this.state.darkStyle}
                        isDesktop={!this.mobileDetector.isMobile()}
                        selected={this.state.selectedBoxId}
                        selectedDevice={this.state.selectedDeviceId}
                        payStations={this.state.filteredPayStations}
                        ref={ component => currentDashboardInstance.diagnosticRef = component} />
        );
    }

    renderActivations() {
        return (
            <Activations locale={this.locale}
                         isDarkStyle={this.state.darkStyle}
                         isDesktop={!this.mobileDetector.isMobile()}
                         observer={this}
                         payStations={this.state.filteredPayStations} />
        );
    }

    renderOperations() {
        return (
            <Operation  locale={this.locale}
                        isDarkStyle={this.state.darkStyle}
                        isDesktop={!this.mobileDetector.isMobile()}
                        payStations={this.state.filteredPayStations}
                        pdfDataDelegate={this.pdfDataDelegate}
                        API={this.props.API}
                        periodSynchronizer={this.periodSynchronizer}
                        onCurrenciesUpdate={this.onCurrenciesUpdate}
                        currencySelected={this.state.currencySelected}
                        currentUser={this.currentUser}
                        ref={ component => currentDashboardInstance.operationRef = component} />
        );
    }

    renderTransactions() {
        return (
            <Transaction  locale={this.locale}
                          isDarkStyle={this.state.darkStyle}
                          isDesktop={!this.mobileDetector.isMobile()}
                          payStations={this.state.filteredPayStations}
                          pdfDataDelegate={this.pdfDataDelegate}
                          API={this.props.API}
                          periodSynchronizer={this.periodSynchronizer}
                          onCurrenciesUpdate={this.onCurrenciesUpdate}
                          currencySelected={this.state.currencySelected}
                          currentUser={this.currentUser}
                          ref={ component => currentDashboardInstance.transactionRef = component} />
        );
    }

    renderMessages() {
        return (
            <Message    locale={this.locale}
                        isDarkStyle={this.state.darkStyle}
                        isDesktop={!this.mobileDetector.isMobile()}
                        payStations={this.state.filteredPayStations}
                        pdfDataDelegate={this.pdfDataDelegate}
                        API={this.props.API}
                        periodSynchronizer={this.periodSynchronizer}
                        selectedBoxId={this.state.selectedBoxId}
                        selectedDeviceId={this.state.selectedDeviceId}
                        messageLevel={this.state.messageLevel}
                        ref={(component) => currentDashboardInstance.messageRef = component} />
        );
    }

    renderInventory() {
        return (
            <Inventory locale={this.locale}
                       isDarkStyle={this.state.darkStyle}
                       isDesktop={!this.mobileDetector.isMobile()}
                       payStations={this.state.filteredPayStations}
                       pdfDataDelegate={this.pdfDataDelegate}
                       user={this.state.user}
                       API={this.props.API}
                       ref={(component) => currentDashboardInstance.inventoryRef = component} />
        );
    };

    renderAccount() {
        return (
            <Account locale={this.locale}
                     isDarkStyle={this.state.darkStyle}
                     isDesktop={!this.mobileDetector.isMobile()}
                     userRDS={this.userRDS}
                     observer={this}
                     user={this.state.user} />
        );
    }

    renderSettings() {
        return (
            <Settings 	locale={this.locale}
                         isDarkStyle={this.state.darkStyle}
                         isDesktop={!this.mobileDetector.isMobile()}
                         user={this.state.user}
                         userRDS={this.userRDS}
                         payStations={this.payStations}
                         API={this.props.API}
                         rootNode={currentSelectedNode} />
        );
    }

    renderDistributions() {
        return (
            <Distribution locale={this.locale}
                          isDarkStyle={this.state.darkStyle}
                          isDesktop={!this.mobileDetector.isMobile()}
                          payStations={this.state.filteredPayStations}
                          pdfDataDelegate={this.pdfDataDelegate}
                          API={this.props.API}
                          periodSynchronizer={this.periodSynchronizer}
                          onCurrenciesUpdate={this.onCurrenciesUpdate}
                          currencySelected={this.state.currencySelected}
                          currentUser={this.currentUser}
                          ref={(component) => currentDashboardInstance.distributionRef = component} />
        );
    }

    renderComparisons() {
        return (
            <Comparison locale={this.locale}
                        isDarkStyle={this.state.darkStyle}
                        isDesktop={!this.mobileDetector.isMobile()}
                        payStations={this.state.filteredPayStations}
                        pdfDataDelegate={this.pdfDataDelegate}
                        API={this.props.API}
                        periodSynchronizer={this.periodSynchronizer}
                        onCurrenciesUpdate={this.onCurrenciesUpdate}
                        currencySelected={this.state.currencySelected}
                        currentUser={this.currentUser}
                        ref={(component) => currentDashboardInstance.comparisonRef = component}	/>
        );
    }

    renderLicenses() {
        return (
            <License    locale={this.locale}
                        isDarkStyle={this.state.darkStyle}
                        isDesktop={!this.mobileDetector.isMobile()}
                        payStations={this.payStations}
                        API={this.props.API}
                        currentUser={this.currentUser} />
        );
    }

    renderLicensesHistory() {
        return (
            <LicenseHistory locale={this.locale}
                            isDarkStyle={this.state.darkStyle}
                            isDesktop={!this.mobileDetector.isMobile()}
                            payStations={this.payStations}
                            API={this.props.API}
                            periodSynchronizer={this.periodSynchronizer}
                            currentUser={this.currentUser} />
        );
    }

    render() {
        let cssRoot = "Root Dashboard";
        if( this.state.open ) {
            cssRoot += " Opened";
        }
        if( this.state.darkStyle ) {
            cssRoot += " Alternative";
        }

        if ( navigator.userAgent.includes("OPR/") ) {
            cssRoot += " Opera";
        }

        Object.keys( currentDashboardInstance.pdfDataDelegate ).forEach( key => {
            delete currentDashboardInstance[key];
        } );

        return (
            <ThemeProvider theme={this.getTheme()}>
                <div id="Dashboard-Root" className={cssRoot} key={this.id}>
                    <CssBaseline />
                    {this.renderNavigation()}
                    {this.renderMain()}
                    <LoadingIndicator modal={true} loading={true}/>
                    <DisconnectionDialog disconnected={this.inactivityDelegate.isDisconnected()}
                                         observer={this}
                                         ref={(component) => currentDashboardInstance.disconnectionDialogRef = component} />
                    <SnackBar ref = {this.snackbarRef} />
                    <LicenseIndicator missingLicenses={this.state.missingLicenses}/>
                </div>
            </ThemeProvider>
        );
    }
}

export default Dashboard;
