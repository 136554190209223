import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@mui/material/IconButton';
import './OutOfSyncIndicator.css';

export default function OutOfSyncIndicator( props ) {
	if( props.isOffline ) {
		return (
			<React.Fragment>
				<IconButton className="Icon" color="inherit" onClick={(event) => props.onReconnecting()} >
					<FontAwesomeIcon icon={["fas" , "sync-alt"]} className="pulse"/>
					<div className="slash"></div>
				</IconButton>
			</React.Fragment>
		);
	}
	
	if( props.isLoading ) {
		return (
			<React.Fragment>
				<FontAwesomeIcon icon={["fas" , "sync-alt"]} className="display-5 spining"/>
			</React.Fragment>
		);
	}
	
	return null;
}
