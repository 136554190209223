import React from 'react';
import {I18n} from "aws-amplify/utils";
import './TechnicalAlerts.css'
import {StateLevel} from "../../../../Utils/DeviceDataAnalyzer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {List, ListItem} from "@mui/material";
import UUID from "../../../../Utils/UUID";
import PayStationFinder from "../../../../Utils/PayStationFinder";

const factoryUUID = new UUID();

const SensorAlert = props => {
    const [opened, setOpened] = React.useState( false );
    const getColorCss = () => {
        let css = '';
        switch( props.stateLevel ) {
            case StateLevel.OPERATIONAL:
                css += "success";
                break;
            case StateLevel.CLEANUP:
                css += "warning";
                break;
            case StateLevel.MAINTENANCE:
                css += "danger";
                break;
            default:
                break;
        }
        return css;
    };

    const handleSelection = payStation => {
        if( props?.onSelection ) {
            props?.onSelection( payStation );
        }
        setOpened( false );
    }

    const renderListItem = alerts => {
        const finder = new PayStationFinder( props.payStations );
        const boxName = finder.extractBoxName( alerts[0].payStation?.box );
        const extra = ( alerts[0].payStation?.terminals.length > 0 ) ? finder.buildTerminalExtraData( alerts[0].payStation?.terminals[0] ) : 'Non renseigné';
        return (
            <ListItem key={alerts[0].payStation?.box?.id}
                      className={`payStation-card clickable bordered`}
                      onClick={() => { handleSelection( alerts[0].payStation ) }}>
                <div className={`item-expandable`}>
                    <div className={`main`}>
                        <div  className={`general-block`}>
                            <div className={`box-name large`}>
                                <div className={`main`}>{boxName}</div>
                                <div className={`extra`}>{extra}</div>
                            </div>
                            <div className={`spacer`}></div>
                        </div>
                    </div>
                </div>
            </ListItem>
        );
    }

    const renderList = () => {
        const items = [];
        Object.keys( props?.list ).forEach( boxId => {
            items.push( renderListItem( props?.list[boxId] ) );
        } );
        return (
            <List>
                {items.map( item => item )}
            </List>
        );
    }

    const renderDialog = () => {
        let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";

        if( props.isDarkStyle ) {
            css += " dark";
        }
        return (
            <Dialog disableEscapeKeyDown
                    className={css}
                    aria-labelledby="simple-dialog-title"
                    open={Boolean(opened)}>
                <DialogTitle id="group-dialog-title">{I18n.get( `level_${props.stateLevel}` )}</DialogTitle>
                <DialogContent dividers>
                    {renderList()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpened( false ) }} >
                        {I18n.get("ACTION_OK")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    if( ! props.list || props.list.length < 1 ) {
        return null;
    }
    return (
        <div key={factoryUUID.generate()} className={`indicator ${getColorCss()}`}>
            <div key={factoryUUID.generate()} className={`icon`} onClick={ () => setOpened( true ) }>
                <FontAwesomeIcon icon={props.icon} />
            </div>
            <div key={factoryUUID.generate()} className={`label`}>
               {Object.keys( props.list ).length}
            </div>
            {renderDialog()}
        </div>
    );
};

const TechnicalAlerts = props => {

    const extractList = state => {
        let extracted = null;
        const candidates = props.alerts?.filter( item => item.state === state );
        candidates.forEach( alert => {
            if( ! extracted ) {
                extracted = {};
            }

            if( ! extracted[ alert.payStation.box.id ] ) {
                extracted[ alert.payStation.box.id ] = [];
            }
            extracted[ alert.payStation.box.id ].push( alert );
        } );
        return extracted;
    };

    const renderSensorAlert = () => {
        return (
            <div key={factoryUUID.generate()} className={`cleanup-alert-container`}>
                <SensorAlert list={extractList( StateLevel.CLEANUP )}
                             stateLevel={StateLevel.CLEANUP}
                             icon={"fa-solid fa-toolbox"}
                             isDarkStyle={props.isDarkStyle}
                             onSelection={props.onSelection}/>
                <SensorAlert list={extractList( StateLevel.MAINTENANCE )}
                             stateLevel={StateLevel.MAINTENANCE}
                             icon={"fa-solid fa-screwdriver-wrench"}
                             isDarkStyle={props.isDarkStyle}
                             onSelection={props.onSelection}/>
            </div>
        );
    };

    const items = [];
    let noData = I18n.get('No alert');

    if( props.alerts && props.alerts.length > 0 ) {
        noData = '';
        items.push( renderSensorAlert( renderSensorAlert() ) );
    }

    return (
        <div key={factoryUUID.generate()} className={`material-container technical-alert no-data`}>
            <div key={factoryUUID.generate()} className="container-title">{I18n.get('Technical alerts')} : </div>
            <div key={factoryUUID.generate()} className="container-content">
                {noData}
                {items.map( item => item )}
            </div>
        </div>
    );
};

export default TechnicalAlerts;
