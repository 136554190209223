import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SyncIndicator.css';

export default function SyncIndicator(props) {
	if( props.loading ) {
		const className = ( props.className ) ? props.className : "display-5 spining" ;
		return (
			<React.Fragment>
				<FontAwesomeIcon icon={["fas" , "sync-alt"]} className={className}/>
			</React.Fragment>
		);
	}
	return null;
}
