import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Thermometer from '../Thermometer/Thermometer';
import ElapsedTimeIndicator from '../ElapsedTimeIndicator/ElapsedTimeIndicator';
import SensorCounter from '../SensorCounter/SensorCounter';
import SensorStateIndicator from '../SensorStateIndicator/SensorStateIndicator';
import CycleStateIndicator from '../CycleStateIndicator/CycleStateIndicator';
import HopperData from '../HopperData/HopperData';
import {Mode} from '../../../Utils/DeviceTemplate';
import DeviceDataAnalyzer from '../../../Utils/DeviceDataAnalyzer';
import NoDataPlaceholder from '../NoDataPlaceholder/NoDataPlaceholder';
import SortableTable from '../SortableTable/SortableTable';
import Chart from 'react-apexcharts';
import { StateLevel } from '../../../Utils/DeviceDataAnalyzer';
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";
import './DeviceTechnicalData.css';

const factoryUUID = new UUID();
let instance;
export default class DeviceTechnicalData extends React.Component {
	constructor( props ) {
		super( props );
		this.state = {
			device:null,
			mode:Mode.BASIC,
			parameters: null
		};
		instance = this;
		this.slippageAdjustement = 1; //10 to have a 100% gauge
	}
	
	onSelectionChanged( device , parameters ) {
		let params = null;
		if( parameters ) {
			params = parameters;
		}
		
		if( device && device.hasOwnProperty( "options" ) ) {
			instance.props.onInternalNavigation( ["hopper" + device.options[0]] );
		}
		
		instance.setState( {
			device:device,
			mode: instance.state.mode,
			parameters: params
		} );
	}
	
	handleModeSelection( mode ) {
		this.setState( {
			device:this.state.device,
			mode: mode,
			parameters:this.state.parameters
		} );
	}
	
	navigateToDetails( module , parameters ) {
		let toHighlight = [];
		if( instance.props.onInternalNavigation ) {
			if( module === "recycler" ) {
				//must identify if selection from overview should highlight 1 or 2 recyclers groups
				const recyclersGroups = this.deviceDataAlnalyzer.extractRecyclerGroupsFromParameter( parameters );
				recyclersGroups.map( ( group ) => {
					toHighlight.push( group );
					return true;
				} );

			} else {
				//simple module highlight
				toHighlight.push( module );
			}
			instance.props.onInternalNavigation( toHighlight );
					
			this.onSelectionChanged( {name:module , options:toHighlight} , parameters );
		}
	}
	
	locateOnSchema( id ) {
		if( instance.props.onSchemaLocationRequested ) {
			instance.props.onSchemaLocationRequested( id );
		}
	}

	getModuleNameById( id ) {
		switch( id ) {
			case "196870": return "BILL_AND_DRUM_SENSOR";
			case "196873": return "INLET_BILL_SENSOR_A";
			case "196874": return "INLET_BILL_SENSOR_B";
			case "198144": return "XCHANNEL_SENSOR ";
			case "196865": return "BILL_WIDTH_SENSOR_A";
			case "196866": return "BILL_WIDTH_SENSOR_B";
			case "196867": return "BILL_WIDTH_SENSOR_C";
			case "196868": return "BILL_WIDTH_SENSOR_D";
			case "196869": return "BILL_INTAKE_BILL_SENSOR";
			case "196864": return "POSITIONER_BILL_SENSOR";
			case "196871": return "BOTTOM_TRANSPORT_BILL_SENSOR";
			case "196875": //fall through (BNR3 advance);
			case "196872": return "OUTLET_BILL_SENSOR";
			case "198402": return "SPINE_BILL_SENSOR1";
			case "198403": return "SPINE_BILL_SENSOR3";
			case "198404": return "SPINE_BILL_SENSOR5";
			case "198450": return "RECYCLER1_BILL_SENSOR";
			case "198451": return "RECYCLER2_BILL_SENSOR";
			case "198452": return "RECYCLER3_BILL_SENSOR";
			case "198453": return "RECYCLER4_BILL_SENSOR";
			case "198454": return "RECYCLER5_BILL_SENSOR";
			case "198455": return "RECYCLER6_BILL_SENSOR";
			default: return "UNKNOWN";
		}
	}
	
	getElementOrderById( id ) {
		switch( id ) {
			case "196870": return 10;
			case "196873": return 0;
			case "196874": return 1;
			case "198144": return 2;
			case "196865": return 3;
			case "196866": return 4;
			case "196867": return 5;
			case "196868": return 6;
			case "196869": return 8;
			case "196864": return 7;
			case "196871": return 9;
			case "196875": //fall through (BNR3 advance)
			case "196872": return 11;
			case "198402": return 1;
			case "198403": return 2;
			case "198404": return 3;
			case "198450": return 1;
			case "198451": return 2;
			case "198452": return 3;
			case "198453": return 4;
			case "198454": return 5;
			case "198455": return 6;
			default: return -1;
		}
	}
	
	getRecyclerPrefixForSensorId( id ) {
		switch( id ) {
			case "198450": return "r1";
			case "198451": return "r2";
			case "198452": return "r3";
			case "198453": return "r4";
			case "198454": return "r5";
			case "198455": return "r6";
			default: return "";
		}
	}
	
	getFormattedModelName() {
		if( this.props.model === "CLS" ) {
			if( this.props.variant.trim() !== "" ) {
				return this.props.variant;
			}
			return this.props.model;
		} else if( this.props.model === "BNR" ) {
			return this.props.variant;
		} else if( this.props.model === "SCR" ) {
			return this.props.model;
		} 
		return I18n.get("Unsupported")
	}
	
	getDataForHopper( position , organizedData ) {
		const currency = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Currency" , `h${position}Currency` );
		return {
			hopper:position , 
			hopperValue:new Intl.NumberFormat( 
				this.props.locale, 
				{ style: 'currency', currency: `${( currency && currency.length === 3 ) ? currency : "EUR" }` }
			).format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Amount" , `h${position}Value` ) ) ,
			dispensed:this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `h${position}LifetimeDispensed` ) ,
			accepted:this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `h${position}Accepted` ) ,
			sorted:this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `h${position}Sorted` ) ,
			errorCoins:this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `h${position}ErrorDispensed` ) ,
			overcurrent:this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `h${position}Overcurrent` ) ,
			timeout:this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `h${position}CoinEjectTimeoutExceeded` ) ,
		};
	}
	
	getDataForScrRecycler( denomination ) {
		return {
			id:denomination.value , 
			currency: denomination.currency,
			compatibility: denomination.compatibility.join(' , ') ,
			serie: denomination.serie.sort().join(' , ') ,
			type: denomination.type.sort().join(' , ') ,
			version: denomination.version.sort().join(' , ') ,
			recyclable: ( denomination && denomination.recyclable ) ? I18n.get("YES") +` ( ${denomination.recycler} ) ` : I18n.get("NO")
		};
	}
	
	mergeArray( array1 , array2  ) {
		let returned = [];
		if( array1 !== null && array1 !== undefined ) {
			array1.map( ( candidate ) => {
				returned.push( candidate );
				return true;
			} );
		}
		
		if( array2 !== null && array2 !== undefined ) {
			array2.map( ( candidate ) => {
				returned.push( candidate );
				return true;
			} );
		}
		return returned;
	}
	
	renderModeChooser() {
		let label = "";
		let action = "";
		
		if( this.props.model === "CLS" && this.state.device ) {
			return null;
		}

		if( this.state.mode === Mode.BASIC ) {
			label = "Expert";
			action = Mode.EXPERT;
		} else if( this.state.mode === Mode.EXPERT ) {
			label = "Basic";
			action = Mode.BASIC;
		}

		return ( 
			<Button onClick={(evt) => {this.handleModeSelection(action)}}>
				{I18n.get( label )}
			</Button>
		);
	}
	
	manageVisibilityForMode() {
		let css ="";
		
		if( this.props.model === "CLS" && this.state.device ) {
			css = "not-visible";
		}
		
		if( this.props.model === "BNR" && this.state.device ) {
			if( this.state.device.name === "mainModule" || this.state.device.name === "recycler" || this.state.device.name === "spine" ) {
				css = "not-visible";
			}
		}
		
		if( this.props.model === "SCR" ) {
			css = "not-visible";
		}
		
		return css;
	}
	
	renderThermometerItem() {
		return (
			<Thermometer />
		);
	}
	
	renderThermometerZone() {
		let items = [];
		
		this.deviceDataAlnalyzer.extractThermomethersCandidates().map( (candidate) => {
			items.push( this.renderThermometerItem( candidate ) );
			return true;
		} );
		
		return (
			<div className={`thermometers`}>
				{items.map( item => item )}
			</div>
		);
	}
	
	renderLastCheckDate( lastCheckDateAsString ) {
		const lastCheckDate = new Date( lastCheckDateAsString );
		return (
			<div className={`last-check`} key={factoryUUID.generate()}>
				{`${I18n.get("Last check at")} : ${lastCheckDate.toLocaleDateString( this.props.locale )} ${lastCheckDate.toLocaleTimeString( this.props.locale )}`}
			</div>
		);
	}
	
	renderSystem( organizedData ) {
		if( this.state.mode === Mode.EXPERT ) {
			//no display if expert mode
			return null;
		}
		
		if( this.props.model === "CLS" ) {
			return (
				<Grid container spacing={1} className={`system`}>
					<Grid item xs={12} md={12} lg={12} className={`line`}>
						<Grid container spacing={1}>
							<Grid item xs={3} md={3} lg={3} className={``}>
								<div className={`combinedIcon`}>
									{/*<FontAwesomeIcon icon={['fas', 'trash']} className="display-4 main" />
									<FontAwesomeIcon icon={['fas', 'check']} className="display-5 decorator" />*/}
									{I18n.get("AcceptorKnownCoinsRejected")}
								</div>
								<div className={`value`}>
									{new Intl.NumberFormat( this.props.locale ).format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `acceptorKnownCoinsRejected` ) )}
								</div>
							</Grid>

							<Grid item xs={3} md={3} lg={3} className={``}>
								<div className={`combinedIcon`}>
									{/*<FontAwesomeIcon icon={['fas', 'trash']} className="display-4 main" />
									<FontAwesomeIcon icon={['fas', 'question']} className="display-5 decorator" />*/}
									{I18n.get("AcceptorUnknownCoinsRejected")}
								</div>
								<div className={`value`}>
									{new Intl.NumberFormat( this.props.locale ).format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `acceptorUnknownCoinsRejected` ) )}
								</div>
							</Grid>
							
							<Grid item xs={3} md={3} lg={3} className={``}>
								<div className={`combinedIcon`}>
									{/*<FontAwesomeIcon icon={['fas', 'trash']} className="display-4 main" />
									<FontAwesomeIcon icon={['fas', 'question']} className="display-5 decorator" />*/}
									{I18n.get("CoinWasSlug")}
								</div>
								<div className={`value`}>
									{new Intl.NumberFormat( this.props.locale ).format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `coinWasSlug` ) )}
								</div>
							</Grid>
							
							<Grid item xs={3} md={3} lg={3} className={``}>
								<ElapsedTimeIndicator label={I18n.get("Total up time")}
													  nbLevelDisplayed={2}
													  value={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Chrono" , "operatingLifetime" )}/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
		
		if( this.props.model === "SCR" ) {
			return (
				<Grid container spacing={1} className={`system`}>
					<Grid item xs={12} md={12} lg={12} className={`line`}>
						<Grid container spacing={1}>
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								{I18n.get("Acceptance") + " : "}
							</Grid>
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								<FontAwesomeIcon icon={['fas', 'check-circle']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesRecognized" )}
							</Grid>
							
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								<FontAwesomeIcon icon={['fas', 'search-dollar']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesRecognized" ) + this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "recognitionRejection" )}
							</Grid>
							
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								<FontAwesomeIcon icon={['fas', 'times-circle']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "recognitionRejection" )}
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={12} md={12} lg={12} className={`line`}>
						<Grid container spacing={1}>
							<Grid item xs={3} md={3} lg={3} className={``}>
							<ElapsedTimeIndicator label={I18n.get("Operational since")}
												  nbLevelDisplayed={2}
												  value={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Chrono" , "operatingHours" ) * 60 * 60}/>
							</Grid>
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								<FontAwesomeIcon icon={['fas', 'layer-group']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesStacked" )}
							</Grid>
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								<FontAwesomeIcon icon={['fas', 'money-bill-wave']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesDispensed" )}
							</Grid>
							<Grid item xs={3} md={3} lg={3} className={`voltage`}>
								<FontAwesomeIcon icon={['far', 'dot-circle']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "calibrations" )}
							</Grid>
						</Grid>
						
					</Grid>
				</Grid>
			);
		}
		
		return (
			<Grid container spacing={1} className={`system`}>
				<Grid item xs={12} md={12} lg={12} className={`line`}>
					<Grid container spacing={1}>
						<Grid item xs={4} md={4} lg={4} className={`voltage`}>
							<FontAwesomeIcon icon={['fas', 'plug']} className="display-4 mx-2" /> {this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "voltage" )}
						</Grid>
						
						<Grid item xs={4} md={4} lg={4} className={`cycle`}>
							{I18n.get("Cycles")} : {new Intl.NumberFormat( this.props.locale ).format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , `cycleCount` ) )}
						</Grid>
						
						<Grid item xs={4} md={4} lg={4} className={`temperature`}>
							<Thermometer value={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Thermometer" , "temperature" )}/>
						</Grid>
					</Grid>
				</Grid>
				
				<Grid item xs={12} md={12} lg={12} className={`line`}>
					<Grid container spacing={1}>
						<Grid item xs={4} md={4} lg={4} className={``}>
						<ElapsedTimeIndicator label={I18n.get("Operational since")}
											  nbLevelDisplayed={2}
											  value={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Chrono" , "operationalSince" )}/>
						</Grid>
						<Grid item xs={4} md={4} lg={4} className={``}>
							<ElapsedTimeIndicator label={I18n.get("Total up time")}
												  nbLevelDisplayed={2}
												  value={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Chrono" , "totalUpTime" )}/>
						</Grid>
						<Grid item xs={4} md={4} lg={4} className={``}>
							<ElapsedTimeIndicator label={I18n.get("Up Time")}
												  nbLevelDisplayed={2}
												  value={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Chrono" , "upTime" )}/>
						</Grid>
					</Grid>
					
				</Grid>
			</Grid>
		);
	}
	
	renderMainModule( organizedData ) {
		const moduleName = "mainModule";
		const lowerSensor = this.deviceDataAlnalyzer.extractLowestSensor( moduleName , organizedData );
		const operational = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.OPERATIONAL , moduleName , organizedData );
		const cleanup = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.CLEANUP , moduleName , organizedData );
		const maintenance = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.MAINTENANCE , moduleName , organizedData );
		return (
			<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()}>
				<Grid container spacing={1}>
					<Grid item xs={3} md={3} lg={3} className={`module-type`}>
						{I18n.get( moduleName )} :
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`}>
						<SensorCounter type={StateLevel.OPERATIONAL} count={operational.length}/>
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`}
													title={`${(cleanup.length > 0) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( cleanup.length > 0 ) { this.navigateToDetails( moduleName , cleanup ) } }} 
													style={{cursor:`${(cleanup.length > 0) ? "pointer" : "default"}`}}>
						<SensorCounter type={StateLevel.CLEANUP} count={cleanup.length}/>
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`}  
													title={`${(maintenance.length > 0) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( maintenance.length > 0 ) { this.navigateToDetails( moduleName , maintenance ) } }} 
													style={{cursor:`${(maintenance.length > 0) ? "pointer" : "default"}`}}>
						<SensorCounter type={StateLevel.MAINTENANCE} count={maintenance.length}/>
					</Grid>
					
					<Grid item xs={3} md={3} lg={3} className={`lower-sensor`}  
													title={`${I18n.get("See details")}`} 
													onClick={(evt) => { this.navigateToDetails( moduleName , [lowerSensor.id] ) }} 
													style={{cursor:"pointer"}}>
						<SensorStateIndicator sensor={lowerSensor} locale={this.props.locale}/>
					</Grid>
				</Grid>
			</Grid>
		);
	}
	
	renderMainModuleDetails( organizedData ) {
		const moduleName = "mainModule";
		if( this.state.device && this.state.device.name === moduleName ) {
			let lines = [];
			let sensorList = this.deviceDataAlnalyzer.extractSensorListForModule( moduleName );
			if( sensorList.length > 0 ) {
				sensorList.sort( ( sensorA , sensorB ) => this.getElementOrderById( sensorA ) - this.getElementOrderById( sensorB ) );
				sensorList.map( ( sensor ) => {
					const sensorCurrentValue = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Sensor" , sensor );
					lines.push(this.renderMainModuleSensorLine( sensor , { value:sensorCurrentValue , state:this.deviceDataAlnalyzer.extractStateForSensor( moduleName , sensorCurrentValue ) } , organizedData));
					return true;
				} );
			}
			
			return (
				<Grid container spacing={1} key={factoryUUID.generate()} className={`mt-4`}>
					{lines.map( item => item )}
				</Grid>
			);
		}
		return null;
	}
	
	renderMainModuleSensorLine( id , sensor , organizedData ) {
		if( id === "196870" ) {
			const currentValue = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Sensor" , "maintenaceBundlerSlippage" ) * this.slippageAdjustement; //adapt to display device works between 0 - 10%
			const slippageState = this.deviceDataAlnalyzer.extractStateForSensor( "slippage" , currentValue );
			//return specific display for this sensor
			return (
				<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={`detail-sensor-line ${(this.state.parameters && this.state.parameters.includes(id)) ? "selected" : ""}`}>
					<Grid container spacing={0}>
						<Grid item xs={2} md={2} lg={2} className={`sensorId`}>
							{id}
						</Grid>
						<Grid item xs={5} md={5} lg={5} className={`sensorNameGrouped`}>
							{this.getModuleNameById( id )}
							<CycleStateIndicator label={``} 
												 current={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cyclesSinceLastMaintenance" )} 
												 recommended={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "maintenanceInterval" )}/>
						</Grid>
						<Grid item xs={2} md={2} lg={2} className={`sensorName`} title={`Slippage`} style={{cursor:"default"}}>
							<SensorStateIndicator sensor={{id:`` , value:currentValue , state:slippageState}} small={true} locale={this.props.locale} decimal={true}/> 
						</Grid>
						<Grid item xs={2} md={2} lg={2} className={`sensorName`} title={`Cleanness`} style={{cursor:"default"}}>
							<SensorStateIndicator sensor={sensor} small={true} locale={this.props.locale}/>
						</Grid>
						<Grid item xs={1} md={1} lg={1} className={`sensorName`} onClick={(evt) => { this.locateOnSchema( id ) }} style={{cursor:"pointer"}}>
							<FontAwesomeIcon icon={['fas', 'search-location']} className="display-4" />
						</Grid>
					</Grid>
				</Grid>
			);
		}
		return (
			<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={`detail-sensor-line ${(this.state.parameters && this.state.parameters.includes(id)) ? "selected" : ""}`}>
				<Grid container spacing={0}>
					<Grid item xs={2} md={2} lg={2} className={`sensorId`}>
						{id}
					</Grid>
					<Grid item xs={5} md={5} lg={5} className={`sensorName`}>
						{this.getModuleNameById( id )}
					</Grid>
					<Grid item xs={2} md={2} lg={2} className={`sensorName`}>
					</Grid>
					<Grid item xs={2} md={2} lg={2} className={`sensorName`} title={`Cleanness`} style={{cursor:"default"}}>
						<SensorStateIndicator sensor={sensor} small={true} locale={this.props.locale}/>
					</Grid>
					<Grid item xs={1} md={1} lg={1} className={`sensorName`} onClick={(evt) => { this.locateOnSchema( id ) }} style={{cursor:"pointer"}}>
						<FontAwesomeIcon icon={['fas', 'search-location']} className="display-4" />
					</Grid>
				</Grid>
			</Grid>
		);
	}
	
	renderBundler( organizedData ) {
		const moduleName = "slippage";
		const currentValue = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Sensor" , "maintenaceBundlerSlippage" ) * this.slippageAdjustement; //adapt to display device works between 0 - 10%
		const slippageState = this.deviceDataAlnalyzer.extractStateForSensor( moduleName , currentValue );
		return (
			<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()}>
				<Grid container spacing={1}>
					<Grid item xs={2} md={2} lg={2} className={`module-type`}></Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`slippage`}></Grid>
					
					<Grid item xs={5} md={5} lg={5} className={`cycles`}>
						<CycleStateIndicator label={`${I18n.get("Cycles since last maintenance")} ( ${I18n.get("bundler")} )`} 
											 current={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cyclesSinceLastMaintenance" )} 
											 recommended={this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "maintenanceInterval" )}/>
					</Grid>
					
					<Grid item xs={3} md={3} lg={3} className={`slippage`} 
													title={`${(slippageState !== StateLevel.OPERATIONAL) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( slippageState !== StateLevel.OPERATIONAL ) { this.navigateToDetails( "mainModule" , ["196870"] ) } }} 
													style={{cursor:`${(slippageState !== StateLevel.OPERATIONAL) ? "pointer" : "default"}`}}>
						<SensorStateIndicator sensor={{id:`Slippage` , value:currentValue , state:slippageState}} locale={this.props.locale} decimal={true}/> 
					</Grid>
					
				</Grid>
			</Grid>
		);
	}
	
	renderSpine( organizedData ) {
		const moduleName = "spine";
		const lowerSensor = this.deviceDataAlnalyzer.extractLowestSensor( moduleName , organizedData );
		const operational = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.OPERATIONAL , moduleName , organizedData );
		const cleanup = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.CLEANUP , moduleName , organizedData );
		const maintenance = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.MAINTENANCE , moduleName , organizedData );
		return (
			<Grid item xs={12} md={12} lg={12}>
				<Grid container spacing={1}>
					<Grid item xs={3} md={3} lg={3} className={`module-type`}>
						{I18n.get( moduleName )} :
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`}>
						<SensorCounter type={StateLevel.OPERATIONAL} count={operational.length}/>
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`} 
													title={`${(cleanup.length > 0) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( cleanup.length > 0 ) { this.navigateToDetails( moduleName , cleanup ) } }} 
													style={{cursor:`${(cleanup.length > 0) ? "pointer" : "default"}`}}>
						<SensorCounter type={StateLevel.CLEANUP} count={cleanup.length}/>
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`} 
													title={`${(maintenance.length > 0) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( maintenance.length > 0 ) { this.navigateToDetails( moduleName , maintenance ) } }} 
													style={{cursor:`${(maintenance.length > 0) ? "pointer" : "default"}`}}>
						<SensorCounter type={StateLevel.MAINTENANCE} count={maintenance.length}/>
					</Grid>
					
					<Grid item xs={3} md={3} lg={3} className={`lower-sensor`} 
													title={`${I18n.get("See details")}`} 
													onClick={(evt) => { this.navigateToDetails( moduleName , [lowerSensor.id] ) }} 
													style={{cursor:"pointer"}}>
						<SensorStateIndicator sensor={lowerSensor} locale={this.props.locale}/>
					</Grid>
				</Grid>
			</Grid>
		);
	}
	
	renderSpineDetails( organizedData ) {
		const moduleName = "spine";
		if( this.state.device && this.state.device.name === moduleName ) {
			let lines = [];
			let sensorList = this.deviceDataAlnalyzer.extractSensorListForModule( moduleName );
			if( sensorList.length > 0 ) {
				sensorList.sort( ( sensorA , sensorB ) => this.getElementOrderById( sensorA ) - this.getElementOrderById( sensorB ) );
				sensorList.map( ( sensor ) => {
					const sensorCurrentValue = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Sensor" , sensor );
					lines.push(this.renderSpineSensorLine( sensor , { value:sensorCurrentValue , state:this.deviceDataAlnalyzer.extractStateForSensor( moduleName , sensorCurrentValue ) }));
					return true;
				} );
			}
			
			return (
				<Grid container spacing={1} key={factoryUUID.generate()} className={`mt-4`}>
					{lines.map( item => item )}
				</Grid>
			);
		}
		return null;
	}
	
	renderSpineSensorLine( id , sensor ) {
		return (
			<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={`detail-sensor-line ${(this.state.parameters !== null && this.state.parameters !== undefined && this.state.parameters.includes(id)) ? "selected" : ""}`}>
				<Grid container spacing={0}>
					<Grid item xs={3} md={3} lg={3} className={`sensorId`}>
						{id}
					</Grid>
					<Grid item xs={6} md={6} lg={6} className={`sensorName`}>
						{this.getModuleNameById( id )}
					</Grid>
					<Grid item xs={2} md={2} lg={2} className={`sensorName`} title={`Cleanness`} style={{cursor:"default"}}>
						<SensorStateIndicator sensor={sensor} small={true} locale={this.props.locale}/>
					</Grid>
					<Grid item xs={1} md={1} lg={1} className={`sensorName`} onClick={(evt) => { this.locateOnSchema( id ) }} style={{cursor:"pointer"}}>
						<FontAwesomeIcon icon={['fas', 'search-location']} className="display-4" />
					</Grid>
				</Grid>
			</Grid>
		);
	}
	
	renderRecyclers( organizedData ) {
		const moduleName = "recycler";
		const lowerSensor = this.deviceDataAlnalyzer.extractLowestSensor( moduleName , organizedData );
		const operational = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.OPERATIONAL , moduleName , organizedData );
		const cleanup = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.CLEANUP , moduleName , organizedData );
		const maintenance = this.deviceDataAlnalyzer.extractSensorIdList( StateLevel.MAINTENANCE , moduleName , organizedData );
		return (
			<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()}>
				<Grid container spacing={1}>
					<Grid item xs={3} md={3} lg={3} className={`module-type`}>
						{I18n.get( moduleName )} :
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`}>
						<SensorCounter type={StateLevel.OPERATIONAL} count={operational.length}/>
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`} 
													title={`${(cleanup.length > 0) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( cleanup.length > 0 ) { this.navigateToDetails( moduleName , cleanup ) } }} 
													style={{cursor:`${(cleanup.length > 0) ? "pointer" : "default"}`}}>
						<SensorCounter type={StateLevel.CLEANUP} count={cleanup.length}/>
					</Grid>
					
					<Grid item xs={2} md={2} lg={2} className={`sensor-counter`} 
													title={`${(maintenance.length > 0) ? I18n.get("See details") : ""}`} 
													onClick={(evt) => { if( maintenance.length > 0 ) { this.navigateToDetails( moduleName , maintenance ) } }} 
													style={{cursor:`${(maintenance.length > 0) ? "pointer" : "default"}`}}>
						<SensorCounter type={StateLevel.MAINTENANCE} count={maintenance.length}/>
					</Grid>
					
					<Grid item xs={3} md={3} lg={3} className={`lower-sensor`}
													title={`${I18n.get("See details")}`} 					
													onClick={(evt) => { this.navigateToDetails( moduleName , [lowerSensor.id] ) }} 
													style={{cursor:"pointer"}}>
						<SensorStateIndicator sensor={lowerSensor} locale={this.props.locale}/>
					</Grid>
				</Grid>
			</Grid>
		);
	}
	
	renderRecyclerDetails( organizedData ) {
		const moduleName = "recycler";
		if( this.state.device !== null && this.state.device !== undefined && this.state.device.name === moduleName ) {
			let lines = [];
			
			if( this.state.device.hasOwnProperty("options") ) {
				let sensorList = [];
				this.state.device.options.map( ( group ) => {
					switch( group ) {
						case "r1-2":
							sensorList = this.mergeArray( sensorList , this.mergeArray( this.deviceDataAlnalyzer.extractSensorListForModule( "recycler1" ) , this.deviceDataAlnalyzer.extractSensorListForModule( "recycler2" ) ) );
						break;
						case "r3-4":
							sensorList = this.mergeArray( sensorList , this.mergeArray( this.deviceDataAlnalyzer.extractSensorListForModule( "recycler3" ) , this.deviceDataAlnalyzer.extractSensorListForModule( "recycler4" ) ) );
						break;
						case "r5-6":
							sensorList = this.mergeArray( sensorList , this.mergeArray( this.deviceDataAlnalyzer.extractSensorListForModule( "recycler5" ) , this.deviceDataAlnalyzer.extractSensorListForModule( "recycler6" ) ) );
						break;
						default:
						break;
					}
					return true;
				} );

				if( sensorList.length > 0 ) {
					sensorList.sort( ( sensorA , sensorB ) => this.getElementOrderById( sensorA ) - this.getElementOrderById( sensorB ) );
					sensorList.map( ( sensor ) => {
						
						const sensorCurrentValue = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Sensor" , sensor );
						const cycles = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , this.getRecyclerPrefixForSensorId( sensor ) + "CyclesSinceLastMaintenance" );
						const recommended = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , this.getRecyclerPrefixForSensorId( sensor ) + "MaintenanceInterval" );
						const currency = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Currency" , this.getRecyclerPrefixForSensorId( sensor ) + "Currency" );
						const value = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Amount" , this.getRecyclerPrefixForSensorId( sensor ) + "Value" )
						const dispensed = this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , this.getRecyclerPrefixForSensorId( sensor ) + "Dispensed" )
						
						lines.push(this.renderRecyclerDetailsLine( 
							sensor , 
							{ value:sensorCurrentValue , 
							state:this.deviceDataAlnalyzer.extractStateForSensor( moduleName , sensorCurrentValue ) } , 
							cycles , 
							recommended ,  
							value , 
							currency , 
							dispensed 
						) );
						
						return true;
					} );
				}
			}

			return (
				<Grid container spacing={1} key={factoryUUID.generate()} className={`mt-4`}>
					{lines.map( item => item )}
				</Grid>
			);
		}
		return null;
	}
	
	renderRecyclerDetailsLine( id , sensor , cycles , recommended , value , currency , dispensed ) {	
		return (
			<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={`detail-recycler-line ${(this.state.parameters !== null && this.state.parameters !== undefined && this.state.parameters.includes(id)) ? "selected" : ""}`}>
				<Grid container spacing={0}>
					<Grid item xs={12} md={12} lg={12}>
						<Grid container spacing={0}>
							<Grid item xs={2} md={2} lg={2} className={`sensorId`}>
								{id}
							</Grid>
							<Grid item xs={6} md={6} lg={6} className={`sensorName`}>
								{this.getModuleNameById( id )}
							</Grid>
							<Grid item xs={3} md={3} lg={3} className={`sensorName`} title={`Cleanness`} style={{cursor:"default"}}>
								<SensorStateIndicator sensor={sensor} small={true} locale={this.props.locale}/>
							</Grid>
							<Grid item xs={1} md={1} lg={1} className={`sensorName`} onClick={(evt) => { this.locateOnSchema( id ) }} style={{cursor:"pointer"}}>
								<FontAwesomeIcon icon={['fas', 'search-location']} className="display-4" />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={12} lg={12} className={`mt-2`}>
						<Grid container spacing={0}>
							<Grid item xs={2} md={2} lg={2} className={`recycler-value`}>
								{new Intl.NumberFormat( this.props.locale, { style: 'currency', currency: `${( currency !== null && currency !== undefined && currency.length === 3 ) ? currency : "EUR" }` }).format( value )}
							</Grid>
							<Grid item xs={5} md={5} lg={5} className={`sensorName`}>
								<CycleStateIndicator label={``} 
													 current={cycles} 
													 recommended={recommended}/>
							</Grid>
							<Grid item xs={5} md={5} lg={5} className={`sensorName`}>
								{/*{I18n.get("Dispensed")} : <span className={`qty-dispensed`}>{dispensed}</span> <FontAwesomeIcon icon={['fas', 'money-bill']} className={`mx-2 display-5`} />*/}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				
			</Grid>
		);
	}
	
	renderCashbox( organizedData ) {
		const moduleName = "cashbox";
		if( this.state.device && this.state.device.name === moduleName && this.props.model === "BNR" ) {
			const iconClassName = `display-4`; 
			return (
				<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={``}>
					<Grid container spacing={0}>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'exchange-alt']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "LevelChangeCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbLevelChangeCount" ) )}
										{this.renderSimpleTableAssociation( "ModuleChangedCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbModuleChangedCount" ) )}
										{this.renderSimpleTableAssociation( "ModuleFullCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbModuleFullCount" ) )}
										{this.renderSimpleTableAssociation( "ModuleRemovalCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbModuleRemovalCount" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'power-off']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "InternalResetCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbInternalResetCount" ) )}
										{this.renderSimpleTableAssociation( "PowerUpCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbPowerUpCount" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "BillErrorCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbBillErrorCount" ) )}
										{this.renderSimpleTableAssociation( "BillJamCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbBillJamCount" ) )}
										{this.renderSimpleTableAssociation( "HardwareFailureCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbHardwareFailureCount" ) )}
										{this.renderSimpleTableAssociation( "TransportErrorCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbTransportErrorCount" ) )}
										{this.renderSimpleTableAssociation( "NotArmedCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "cbNotArmedCount" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					
				</Grid>
			);
		} 
		
		if( this.state.device && this.state.device.name === moduleName && this.props.model === "SCR" ) {
			const iconClassName = `display-4`; 
			return (
				<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={``}>
					<Grid container spacing={0}>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'info']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "Capacity" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "capacity" ) )}
										{this.renderSimpleTableAssociation( "Notes stacked" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesStackedToCashbox" ) )}
										{this.renderSimpleTableAssociation( "Removal count" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "removed" ) )}
										{this.renderSimpleTableAssociation( "Full capacity count" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "full" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
		
		return null;
	}
	
	renderSimpleTableAssociation( label , value ) {
		if( isNaN(value) ) {
			return (
				<Grid key={factoryUUID.generate()} item xs={12} md={12} lg={12} className={``}>
					<Grid container spacing={0}>
						<Grid item xs={8} md={8} lg={8} className={`simple-table-label`}>
							{I18n.get( label )}
						</Grid>
						<Grid item xs={4} md={4} lg={4} className={`simple-table-value`}>
							{value}
						</Grid>
					</Grid>
				</Grid>
			);
		}
		return (
			<Grid key={factoryUUID.generate()} item xs={12} md={12} lg={12} className={``}>
				<Grid container spacing={0}>
					<Grid item xs={8} md={8} lg={8} className={`simple-table-label`}>
						{I18n.get( label )}
					</Grid>
					<Grid item xs={4} md={4} lg={4} className={`simple-table-value`}>
						{new Intl.NumberFormat( this.props.locale ).format( value )}
					</Grid>
				</Grid>
			</Grid>
		);
	}
	
	renderLoader() {
		const moduleName = "loader";
		if( this.state.device !== null && this.state.device !== undefined && this.state.device.name === moduleName ) {
			return( 
				<Grid container spacing={1} key={factoryUUID.generate()} className={`mt-4 loader`}>
					<NoDataPlaceholder className={``} shown={true}/>
				</Grid>
			);
		}
		return null;
	}
	
	renderHopperOverview( organizedData ) {
		if( this.state.mode === Mode.EXPERT ) {
			//no display if expert mode
			return null;
		}

		const headerProperties = [
		  	{ id: "hopper", 		numeric: true, 	disablePadding: false, label: I18n.get("hopper"), 				money: false, absolute: false, 	className:"header hopper" , 	width: 0 	, 	align: 'center' },
			{ id: 'hopperValue', 	numeric: false, disablePadding: false, label: I18n.get("Value"), 				money: false, absolute: false, 	className:"header value" , 		width: 0	, 	align: 'center'},
			{ id: 'accepted', 		numeric: true,  disablePadding: false, label: I18n.get("Coins accepted"), 		money: false, absolute: false, 	className:"header accepted" , 	width: 0	, 	align: 'center'},
		  	{ id: 'sorted', 		numeric: true,  disablePadding: false, label: I18n.get("Coins sorted"), 		money: false, absolute: false, 	className:"header sorted", 		width: 0  	, 	align: 'center' },
		  	{ id: 'dispensed', 		numeric: true,  disablePadding: false, label: I18n.get("Lifetime dispensed"), 	money: false, absolute: false, 	className:"header dispensed", 	width: 0	, 	align: 'center' },
		  	//{ id: 'errorCoins', 	numeric: true,  disablePadding: false, label: I18n.get("Coins error"), 			money: false, absolute: false, 	className:"header errorCoins",	width: 0  	, 	align: 'center' },
			{ id: 'overcurrent', 	numeric: true,  disablePadding: false, label: I18n.get("Coins overcurrent"), 	money: false, absolute: false, 	className:"header errorCoins",	width: 0  	, 	align: 'center' },
			{ id: 'timeout', 		numeric: true,  disablePadding: false, label: I18n.get("Coins timeout"), 		money: false, absolute: false, 	className:"header errorCoins",	width: 0  	, 	align: 'center' },
		];
		
		const data = [];
		const maxHopper = 8;
		for( let i = 1 ; i < maxHopper + 1 ; i++ ) {
			if( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Amount" , `h${i}Value` ) > 0 ) {
				data.push( this.getDataForHopper( i , organizedData ) );
			}
		}
		
		return (
			<Grid container spacing={1} className={`system`}>
				<Grid item xs={12} md={12} lg={12} className={`line`}>
					<SortableTable header={headerProperties} 
								   rows={data} 
								   className={`table-hopper`}
								   locale={this.props.locale}
								   isDarkStyle={this.props.isDarkStyle}
								   isDesktop={this.props.isDesktop}
								   orderBy="id"
								   noFooter={false}
								   noPagination={true}
								   onLineSelection={this.onSelectionChanged}
								   defaultRowsPerPage={10}/>
				</Grid>
			</Grid>
		);
	}
	
	renderHopperDetails( organizedData ) {
		const moduleName = "hopper";
		if( this.state.device && this.state.device.name === moduleName ) {
			if( this.state.device.hasOwnProperty("options") ) {
				return (
					<Grid container spacing={1} key={factoryUUID.generate()} className={`mt-4`}>
						<HopperData locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={this.state.device.options[0]} onSelection={this.onSelectionChanged} />
					</Grid>
				);
			}
		}
		return null;
	}
	
	renderAcceptorDetails( organizedData ) {
		const moduleName = "acceptor";
		if( this.state.device && this.state.device.name === moduleName && this.props.model === "SCR" ) {
			const iconClassName = `display-4`; 
			return (
				<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={``}>
					<Grid container spacing={0}>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'info']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "Motor starts" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "motorStarts" ) )}
										{this.renderSimpleTableAssociation( "Flash download attempts" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "flashDownloadAttempts" ) )}
										{this.renderSimpleTableAssociation( "Host resets" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "hostResets" ) )}
										{this.renderSimpleTableAssociation( "Resets" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "resets" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						{/*<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'info']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "Banknotes passed recognition" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "capacity" ) )}
										{this.renderSimpleTableAssociation( "Barcodes decoded" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "barcodesDecoded" ) )}
										{this.renderSimpleTableAssociation( "Docs reached escrow position" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "removed" ) )}
										{this.renderSimpleTableAssociation( "Docs passed validation" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "full" ) )}
										{this.renderSimpleTableAssociation( "Notes floated down" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "full" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>*/}
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'eye-slash']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "Recognition rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "recognitionRejection" ) )}
										{this.renderSimpleTableAssociation( "Document disabled rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "documentDisabledRejections" ) )}
										{this.renderSimpleTableAssociation( "Orientation disabled rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "orientationDisabledRejections" ) )}
										{this.renderSimpleTableAssociation( "Host return document rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "hostReturnDocumentRejections" ) )}
										{this.renderSimpleTableAssociation( "Escrow timeout rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "escrowTimeoutRejections" ) )}
										{this.renderSimpleTableAssociation( "Fast feed error rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "fastFeedErrorReject" ) )}
										{this.renderSimpleTableAssociation( "Security rejections" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "securityRejections" ) )}
										{this.renderSimpleTableAssociation( "Docs that failed to reach escrow position" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "docsThatFailedToReachEscrowPosition" ) )}
										{this.renderSimpleTableAssociation( "Docs inserted while disabled" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "docInsertedWhileDisabled" ) )}
										{this.renderSimpleTableAssociation( "Docs inserted while recycler busy" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "docInsertedWhileBusy" ) )}
										{this.renderSimpleTableAssociation( "Docs less than mini allowable length" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesTooSmall" ) )}
										{this.renderSimpleTableAssociation( "Docs greater than maxi allowable length" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesTooBig" ) )}
										{this.renderSimpleTableAssociation( "Rejections for other reasons" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "docsRejectForOtherReason" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'toolbox']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "All types of jams" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "allTypesOfJams" ) )}
										{this.renderSimpleTableAssociation( "Jam recovery efforts" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "jamRecoveryEfforts" ) )}
										{this.renderSimpleTableAssociation( "Out of service for jammed" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "outOfServiceForJammed" ) )}
										{this.renderSimpleTableAssociation( "Out of order conditions" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "outOfOrder" ) )}
										{this.renderSimpleTableAssociation( "Out of service for recycler opened" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "outOfServiceForRecyclerOpened" ) )}
										{this.renderSimpleTableAssociation( "Out of service conditions" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "outOfService" ) )}
										{this.renderSimpleTableAssociation( "Out of service for jammed on reset" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "outOfServiceForJammedOnReset" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		}
		return null;
	}
	
	renderScrRecyclersDetails( organizedData ) {
		const moduleName = "recyclers";
		if( this.state.device && this.state.device.name === moduleName && this.props.model === "SCR" ) {
			const headerProperties = [
				{ id: "id", 			numeric: true,   disablePadding: false, label: I18n.get("Value"), 			money: true,  absolute: false, 	className:"header " , 	width: 0 	, 	align: 'center' },
				{ id: 'compatibility', 	numeric: false,  disablePadding: false, label: I18n.get("Compatibility"), 	money: false, absolute: false, 	className:"header " , 	width: 0	, 	align: 'center'},
				{ id: 'serie', 			numeric: false,  disablePadding: false, label: I18n.get("Serie"), 			money: false, absolute: false, 	className:"header ", 	width: 0  	, 	align: 'center' },
				{ id: 'type', 			numeric: false,  disablePadding: false, label: I18n.get("Type"), 			money: false, absolute: false, 	className:"header ", 	width: 0	, 	align: 'center' },
				{ id: 'version', 		numeric: false,  disablePadding: false, label: I18n.get("Version"), 		money: false, absolute: false, 	className:"header ",	width: 0  	, 	align: 'center' },
				{ id: 'recycler', 		numeric: false,  disablePadding: false, label: I18n.get("Recycler"), 		money: false, absolute: false, 	className:"header ",	width: 0  	, 	align: 'center' }
			];
			
			const data = [];
			if( this.props.data.denominations ) {
				this.props.data.denominations.map( ( denomination ) => {
					if( denomination.recyclable ) {
						data.push( {
							id:denomination.value , 
							currency: denomination.currency,
							compatibility: denomination.compatibility.join(' , ') ,
							serie: denomination.serie.sort().join(' , ') ,
							type: denomination.type.sort().join(' , ') ,
							version: denomination.version.sort().join(' , ') ,
							recycler: ( denomination && denomination.recyclable ) ? denomination.recycler : 0
						} );
					}
					return true;
				} );
			}
			const iconClassName = `display-4`; 
			return (
				<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={``}>
					<Grid container spacing={0}>
						<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<Grid container spacing={0}>
								<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
									<FontAwesomeIcon icon={['fas', 'info']} className={iconClassName} />
								</Grid>
								<Grid item xs={9} md={9} lg={9} className={``}>
									<Grid container spacing={0}>
										{this.renderSimpleTableAssociation( "Notes stacked" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "notesStackedToRecyclers" ) )}
										{this.renderSimpleTableAssociation( "Out of service for recycler opened" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "outOfServiceForRecyclerOpened" ) )}
										{this.renderSimpleTableAssociation( "Notes inserted while recycler busy" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "docInsertedWhileBusy" ) )}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						{/*<Grid item xs={12} md={12} lg={12} className={`data-block`}>
							<SortableTable header={headerProperties}
								   rows={data} 
								   className={`table-hopper`}
								   locale={this.props.locale}
								   isDarkStyle={this.props.isDarkStyle}
								   isDesktop={this.props.isDesktop}
								   orderBy="id"
								   noFooter={true}
								   noPagination={true}
								   onLineSelection={this.onSelectionChanged}
								   defaultRowsPerPage={0}/>
						</Grid>*/}
					</Grid>
				</Grid>
			);
		}
		return null;
	}
	
	renderScrOverview() {
		if( this.state.mode === Mode.EXPERT || ! this.props.data || this.props.model !== "SCR") {
			//no display if expert mode
			return null;
		}

		const headerProperties = [
		  	{ id: "id", 			numeric: true,   disablePadding: false, label: I18n.get("Value"), 			money: true,  absolute: false, 	className:"header " , 	width: 0 	, 	align: 'center' },
			{ id: 'compatibility', 	numeric: false,  disablePadding: false, label: I18n.get("Compatibility"), 	money: false, absolute: false, 	className:"header " , 	width: 0	, 	align: 'center'},
		  	{ id: 'serie', 			numeric: false,  disablePadding: false, label: I18n.get("Serie"), 			money: false, absolute: false, 	className:"header ", 	width: 0  	, 	align: 'center' },
		  	{ id: 'type', 			numeric: false,  disablePadding: false, label: I18n.get("Type"), 			money: false, absolute: false, 	className:"header ", 	width: 0	, 	align: 'center' },
			{ id: 'version', 		numeric: false,  disablePadding: false, label: I18n.get("Version"), 		money: false, absolute: false, 	className:"header ",	width: 0  	, 	align: 'center' },
			{ id: 'recyclable', 	numeric: false,  disablePadding: false, label: I18n.get("Recyclable"), 		money: false, absolute: false, 	className:"header ",	width: 0  	, 	align: 'center' }
		];
		
		const data = [];
		if( this.props.data.denominations ) {
			this.props.data.denominations.map( ( denomination ) => {
				data.push( this.getDataForScrRecycler( denomination ) );
				return true;
			} );
		}

		return (
			<Grid container spacing={1} className={`system`}>
				<Grid item xs={12} md={12} lg={12} className={`line`}>
					<SortableTable header={headerProperties} 
								   rows={data} 
								   className={`table-hopper`}
								   locale={this.props.locale}
								   isDarkStyle={this.props.isDarkStyle}
								   isDesktop={this.props.isDesktop}
								   orderBy="id"
								   noFooter={true}
								   noPagination={true}
								   onLineSelection={this.onSelectionChanged}
								   defaultRowsPerPage={10}/>
				</Grid>
			</Grid>
		);
	}
	
	/*renderHopperOverviewCard( organizedData ) {
		if( this.state.mode === Mode.EXPERT ) {
			//no display if expert mode
			return null;
		}
		
		return (
			<Grid container spacing={1} className={`system`}>
				<Grid item xs={12} md={12} lg={12} className={`line`}>
					<Grid container spacing={1}>
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={1} onSelection={this.onSelectionChanged}/>
						</Grid>
						
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={2} onSelection={this.onSelectionChanged} />
						</Grid>
						
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={3} onSelection={this.onSelectionChanged} />
						</Grid>
						
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={4} onSelection={this.onSelectionChanged} />
						</Grid>
					</Grid>
				</Grid>
				
				<Grid item xs={12} md={12} lg={12} className={`line`}>
					<Grid container spacing={1}>
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={5} onSelection={this.onSelectionChanged} />
						</Grid>
						
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={6} onSelection={this.onSelectionChanged} />
						</Grid>
						
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={7} onSelection={this.onSelectionChanged} />
						</Grid>
						
						<Grid item xs={3} md={3} lg={3} className={`hopper-overview`}>
							<HopperData compact={true} locale={this.props.locale} data={organizedData} deviceAnalyzer={this.deviceDataAlnalyzer} position={8} onSelection={this.onSelectionChanged} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}*/
	
	renderDeviceOverview( organizedData ) {
		if( this.state.device !== null && this.state.device !== undefined ) {
			//no display if device
			return null;
		}
		
		if( this.state.mode === Mode.EXPERT ) {
			//no display if expert mode
			return null;
		}
		
		if( this.props.model === "CLS" ) {
			return (
				<div key={factoryUUID.generate()} className={`modules-overview`}>
					{this.renderSystem( organizedData )}
					<Grid container spacing={1}>
						{this.renderHopperOverview( organizedData )}
					</Grid>
				</div>
			);
		}
		
		if( this.props.model === "SCR" ) {
			return (
				<div key={factoryUUID.generate()} className={`modules-overview`}>
					{this.renderSystem( organizedData )}
					<Grid container spacing={1}>
						{/*this.renderScrOverview( organizedData )*/}
					</Grid>
				</div>
			);
		}
		
		return (
			<div key={factoryUUID.generate()} className={`modules-overview`}>
				{this.renderSystem( organizedData )}
				<Grid container spacing={1}>
					{this.renderMainModule( organizedData )}
					{this.renderBundler( organizedData )}
					{this.renderSpine( organizedData )}
					{this.renderRecyclers( organizedData )}
					{this.renderCashbox()}
					{this.renderLoader()}
				</Grid>
			</div>
		);
	}
	
	renderDeviceHistory( organizedData ) {
		if( this.state.device !== null && this.state.device !== undefined ) {
			//no display if device
			return null;
		}
		
		if( this.state.mode === Mode.BASIC ) {
			//no display if expert mode
			return null;
		}
		
		const iconClassName = `display-4`; 
		
		const options = {
          chart: {
				type: 'bar',
				height: 350
			},
			plotOptions: {
				bar: {
					borderRadius: 4,
					horizontal: true,
				}
			},
			dataLabels: {
				enabled: false
			},
			xaxis: {
				categories: [
					I18n.get("1To4Coins") ,
					I18n.get("5To9Coins") ,
					I18n.get("10To19Coins") ,
					I18n.get("20To29Coins") ,
					I18n.get("30To39Coins") ,
					I18n.get("40To49Coins") ,
					I18n.get("50To99Coins") ,
					I18n.get("100To199Coins") ,
					I18n.get("200To299Coins") ,
					I18n.get("300To399Coins") ,
					I18n.get("400To499Coins") ,
					I18n.get("500To999Coins")
				],
			}
        };
		
		const series = [{name:I18n.get("Transaction count"), data: 
			[
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "1To4Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "5To9Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "10To19Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "20To29Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "30To39Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "40To49Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "50To99Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "100To199Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "200To299Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "300To399Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "400To499Coins" ), 
				this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "500To999Coins" )
			]
		}];
		const formaterPrct = new Intl.NumberFormat(this.props.locale ,
			{
				style: 'percent'
			});
		
		if( this.props.model === "CLS" ) {
			return (
				<div key={factoryUUID.generate()} className={`modules-overview`}>
					{this.renderSystem( organizedData )}
					<Grid container spacing={1}>
						<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={``}>
							<Grid container spacing={0}>
								<Grid item xs={12} md={12} lg={12} className={`data-block`}>
									<Grid container spacing={0}>
										<Grid item xs={12} md={12} lg={12} >
											{I18n.get("PayInVolumeTransaction")}
										</Grid>
										<Grid item xs={12} md={12} lg={12} >
											<Chart 	options={options} 
													series={series} 
													type="bar"
													height="100%"
													width="100%"
													style={{minWidth:"100%",minHeight:"25em"}}
													className="cm-chart"/>
										</Grid>
									</Grid>
								</Grid>
							
								<Grid item xs={12} md={12} lg={12} className={`data-block`}>
									<Grid container spacing={0}>
										<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
											<FontAwesomeIcon icon={['fas', 'chart-line']} className={iconClassName} />
										</Grid>
										<Grid item xs={9} md={9} lg={9} className={``}>
											<Grid container spacing={0}>
												{this.renderSimpleTableAssociation( "AcceptanceRate" , formaterPrct.format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorAcceptanceRate" ) / 100 ) )}
												{this.renderSimpleTableAssociation( "AcceptedCoins" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorAcceptedCoins" ) )}
												{this.renderSimpleTableAssociation( "AcceptorJams" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorAcceptorJams" ) )}
												{this.renderSimpleTableAssociation( "AcceptorProcessedCoins" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorAcceptorProcessedCoins" ) )}
												{this.renderSimpleTableAssociation( "AccountancyErrorRate" , "1 " + I18n.get("out of") + " " + this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorAccountancyErrorRate" ) )}
												{this.renderSimpleTableAssociation( "DispenserJams" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorDispenserJams" ) )}
												{this.renderSimpleTableAssociation( "JamRate" , formaterPrct.format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorJamRate" ) / 100 ) )}
												{this.renderSimpleTableAssociation( "MiscreditedCoins" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorMiscreditedCoins" ) )}
												{this.renderSimpleTableAssociation( "MissortedCoins" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorMissortedCoins" ) )}
												{this.renderSimpleTableAssociation( "MissortingErrorRate" , "1 " + I18n.get("out of") + " " + this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorMissortingErrorRate" ) )}
												{this.renderSimpleTableAssociation( "PayInVolumeRate" , formaterPrct.format( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorPayInVolumeRate" ) / 100 ) )}
												{this.renderSimpleTableAssociation( "SortedCoins" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorSortedCoins" ) )}
												{this.renderSimpleTableAssociation( "SystemProcessedCoins" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorSystemProcessedCoins" ) )}
												{this.renderSimpleTableAssociation( "TransactionMisrouteRate" , "1 " + I18n.get("out of") + " " +  this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "indicatorTransactionMisrouteRate" ) )}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								
								<Grid item xs={12} md={12} lg={12} className={`data-block`}>
									<Grid container spacing={0}>
										<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
											<FontAwesomeIcon icon={['fas', 'plug']} className={iconClassName} />
										</Grid>
										<Grid item xs={9} md={9} lg={9} className={``}>
											<Grid container spacing={0}>
												{this.renderSimpleTableAssociation( "PowerUps" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUps" ) )}
												{this.renderSimpleTableAssociation( "PowerUpsCozIndependentWatchdogReset" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUpsCozIndependentWatchdogReset" ) )}
												{this.renderSimpleTableAssociation( "PowerUpsCozLowPowerManagementReset" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUpsCozLowPowerManagementReset" ) )}
												{this.renderSimpleTableAssociation( "PowerUpsCozPorPdrReset" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUpsCozPorPdrReset" ) )}
												{this.renderSimpleTableAssociation( "PowerUpsCozResetFromNrstPin" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUpsCozResetFromNrstPin" ) )}
												{this.renderSimpleTableAssociation( "PowerUpsCozSoftwareReset" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUpsCozSoftwareReset" ) )}
												{this.renderSimpleTableAssociation( "PowerUpsCozWindowWatchdogReset" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "powerUpsCozWindowWatchdogReset" ) )}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								
								
								
								
								
								{/*<Grid item xs={12} md={12} lg={12} className={`data-block`}>
									<Grid container spacing={0}>
										<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
											<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={iconClassName} />
										</Grid>
										<Grid item xs={9} md={9} lg={9} className={``}>
											<Grid container spacing={0}>
												{this.renderSimpleTableAssociation( "BillErrorCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceBillErrorCount" ) )}
												{this.renderSimpleTableAssociation( "BillJamCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceBillJamCount" ) )}
												{this.renderSimpleTableAssociation( "HardwareFailureCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceHardwareFailureCount" ) )}
												{this.renderSimpleTableAssociation( "MissingModuleCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceMissingModuleCount" ) )}
												{this.renderSimpleTableAssociation( "ResetWithCoverOpenCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceResetWithCoverOpenCount" ) )}
												{this.renderSimpleTableAssociation( "ResetWithInterlockOpenCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceResetWithInterlockOpenCount" ) )}
												{this.renderSimpleTableAssociation( "TransportErrorCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceTransportErrorCount" ) )}
											</Grid>
										</Grid>
									</Grid>
								</Grid>*/}
							</Grid>
							
						</Grid>
					</Grid>
				</div>
			);
		}
		
		return (
			<div key={factoryUUID.generate()} className={`modules-overview`}>
				{this.renderSystem( organizedData )}
				<Grid container spacing={1}>
					<Grid item xs={12} md={12} lg={12} key={factoryUUID.generate()} className={``}>
						<Grid container spacing={0}>
							<Grid item xs={12} md={12} lg={12} className={`data-block`}>
								<Grid container spacing={0}>
									<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
										<FontAwesomeIcon icon={['fas', 'history']} className={iconClassName} />
									</Grid>
									<Grid item xs={9} md={9} lg={9} className={``}>
										<Grid container spacing={0}>
											{this.renderSimpleTableAssociation( "AmountNotAvailableCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceAmountNotAvailableCount" ) )}
											{this.renderSimpleTableAssociation( "BillNotAvailableCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceBillNotAvailableCount" ) )}
											{this.renderSimpleTableAssociation( "BillRequestedCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceBillRequestedCount" ) )}
											{this.renderSimpleTableAssociation( "DirectFromLoaderCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceDirectFromLoaderCount" ) )}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={12} lg={12} className={`data-block`}>
								<Grid container spacing={0}>
									<Grid item xs={3} md={3} lg={3} className={`type-icon`}>
										<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className={iconClassName} />
									</Grid>
									<Grid item xs={9} md={9} lg={9} className={``}>
										<Grid container spacing={0}>
											{this.renderSimpleTableAssociation( "BillErrorCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceBillErrorCount" ) )}
											{this.renderSimpleTableAssociation( "BillJamCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceBillJamCount" ) )}
											{this.renderSimpleTableAssociation( "HardwareFailureCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceHardwareFailureCount" ) )}
											{this.renderSimpleTableAssociation( "MissingModuleCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceMissingModuleCount" ) )}
											{this.renderSimpleTableAssociation( "ResetWithCoverOpenCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceResetWithCoverOpenCount" ) )}
											{this.renderSimpleTableAssociation( "ResetWithInterlockOpenCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceResetWithInterlockOpenCount" ) )}
											{this.renderSimpleTableAssociation( "TransportErrorCount" , this.deviceDataAlnalyzer.getValueFromData( organizedData , "Int" , "deviceTransportErrorCount" ) )}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						
					</Grid>
				</Grid>
			</div>
		);
	}
	
	renderContent() {
		let organizedData = this.deviceDataAlnalyzer.extractDataGroupedByType();

		if( this.props.data !== null && this.props.data !== undefined ) {
			return (
				<div className={`module-details`}>
					{this.renderLastCheckDate( this.deviceDataAlnalyzer.getValueFromData( organizedData , "Date" , "lastCheckDate" ) )}
					{this.renderDeviceOverview( organizedData )}
					{this.renderDeviceHistory( organizedData )}
					{this.renderMainModuleDetails( organizedData )}
					{this.renderSpineDetails( organizedData )}
					{this.renderRecyclerDetails( organizedData )}
					{this.renderHopperDetails( organizedData )}
					{this.renderAcceptorDetails( organizedData )}
					{this.renderScrRecyclersDetails( organizedData )}
					{this.renderCashbox( organizedData )}
					{this.renderLoader( organizedData )}
				</div>
			);
		}
		
		return null;
	}
	
	render() {
		this.deviceDataAlnalyzer = new DeviceDataAnalyzer( this.props.model , this.props.variant , this.props.attributes , this.props.data , this.state.mode );
		this.deviceDataAlnalyzer.setLocale( this.props.locale );
		let title = I18n.get( "Overview" ) + " : " + this.getFormattedModelName();
		if( this.state.device !== null && this.state.device !== undefined ) {
			if( this.state.device.name === "recycler" ) {
				if( this.state.device.hasOwnProperty("options") && this.state.device.options.length === 1 ) {
					title = I18n.get( "Details" ) + " : " + I18n.get( this.state.device.options[0] );
				} else {
					title = I18n.get( "Details" ) + " : " + I18n.get( this.state.device.name );
				}
			} else if( this.state.device.name === "hopper" ) {
				title = I18n.get( "Details" ) + " : " + I18n.get( "hopper" ) + " " + this.state.device.options[0];
			} else {
				title = I18n.get( "Details" ) + " : " + I18n.get( this.state.device.name );
			}
		}
		return( 
			<React.Fragment>
				<div className={`device-technical-data-root`}>
					<Paper className={`container card-box`}>
						<div className={`module-name`}>
							<div className={`title`}>{title}</div>
							<div className={`mode ${this.manageVisibilityForMode()}`}>{this.renderModeChooser()}</div>
						</div>
						{this.renderContent()}
					</Paper>
				</div>
			</React.Fragment>
		);
	}
}
