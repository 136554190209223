import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordStrengthBar from 'react-password-strength-bar';
import { I18n } from 'aws-amplify/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {changeUserPassword} from '../../../graphql/mutations.js';
import './ChangePasswordDialog.css';

let currentField = {
	id:null,
	lastChange:null,
	value:null
}

export default function ChangePasswordDialog( props ) {

	const [values, setValues] = React.useState( {
		previous:"",
		proposed:"",
		confirmed:""
	} );
	
	const [icons, setIcons] = React.useState( {
		previous:["fas" , "eye" ],
		proposed:["fas" , "eye" ],
		confirmed:["fas" , "eye" ]
	} );
	
	const handleValidation = () => {
		changePassword( values.previous , values.proposed );
		if( props.onDialogClose ) {
			props.onDialogClose();
		}
	}
	
	const changePassword = ( previousPassword , proposedPassword ) => {
		if( props.observer ) {;
			if( props.observer.props.API ) {
				const input = {
					accessToken:props.observer.currentUser.accessToken,
					previousPassword:previousPassword,
					proposedPassword:proposedPassword
				};
				props.observer.props.API.graphql({ query: changeUserPassword, variables: input})
					.then( returned => {
						if( props.onPasswordRequestCompleted ) {
							props.onPasswordRequestCompleted( "success" );
						}
					})
					.catch(error => {
						if( props.onPasswordRequestCompleted ) {
							props.onPasswordRequestCompleted( "failure" , ( error.hasOwnProperty("errors") && error.errors.length > 0 && error.errors[0].hasOwnProperty("message") ) ? error.errors[0].message : "Unknown error" );
						}
					})
			}
		}
	}
	
	const handleInputChange = ( evt , property ) => {
		currentField = {
			id:`password-edit-${property}`,
			lastChange:new Date(),
			value:evt.target.value,
			property:property
		}
		
		setTimeout( () => {
			if( currentField.id !== null && currentField.id !== undefined ) {
				if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) { 
					
					values[currentField.property] = currentField.value;
					
					currentField = {
						id:null,
						lastChange:null,
						value:null,
						property:null
					};
				} 
			} 
		} , 5000 );
		
		let clone = Object.assign( {} , values );
		clone[currentField.property] = currentField.value;
		setValues( clone );
	}

	const handleInputBlur = ( evt ) => {
		if( currentField.id !== null && currentField.id !== undefined ) {
			values[currentField.property] = currentField.value;
			currentField = {
				id:null,
				lastChange:null,
				value:null,
				property:null
			};
		}
	}

	const handleShowPassword = ( evt , id ) => {
		let elementToShow = document.getElementById(`password-edit-${id}`);
		if( elementToShow && elementToShow.type === "password" ) {
			elementToShow.type = "text";
			let clone = Object.assign( {} , icons );
			clone[id] = ["fas" , "eye-slash"];
			setIcons( clone );
		} else {
			elementToShow.type = "password";
			let clone = Object.assign( {} , icons );
			clone[id] = ["fas" , "eye"];
			setIcons( clone );
		}
		
	}
	
	const isSubmitDisabled = () => {
		return  !isValidPassword(`previous`) || 
				!isValidPassword(`proposed`) || 
				!isValidPassword(`confirmed`) || 
				!isConfirmedPassword(`proposed` , `confirmed`);
	}
	
	const isValidPassword = ( idField ) => {
		return values[idField] !== null && values[idField] !== undefined && values[idField].length >= 8;
	}
	
	const isConfirmedPassword = ( idFieldRef , idFieldToCompare ) => {
		return isValidPassword( idFieldRef ) && isValidPassword( idFieldToCompare ) && values[idFieldRef] === values[idFieldToCompare];
	}
	
	const silentEmpty = ( idProperty , isError ) => {
		if( isError ) {
			if( values.hasOwnProperty( idProperty ) && ( values[idProperty] === null || values[idProperty] === undefined || values[idProperty] === "" ) ) {
				return true;
			}
			return false;
		}
		return true;
	}
	
	const getHelperTextPassword = ( idField ) => {
		if( silentEmpty( idField , !isValidPassword( idField ) ) ) {
			return null;
		}
		
		if( !isValidPassword( idField ) ) {
			return I18n.get("Error password length");
		}
		return null;
	}
	
	const getHelperTextConfirmedPassword = () => {
		if( silentEmpty( `confirmed` , !isConfirmedPassword( `proposed` , `confirmed` ) ) ) {
			return null;
		}
		
		if( !isValidPassword( `proposed` ) ) {
			return I18n.get("Error new password invalid");
		}
		
		if( !isValidPassword( `confirmed` ) ) {
			return I18n.get("Error password length");
		}
		
		if( !isConfirmedPassword( `proposed` , `confirmed` ) ) {
			return I18n.get("Error password mismatches");
		} 
		return null;
	}

	const renderShowPasswordIcon = ( id ) => {
		return (
			<InputAdornment className="icon-btn" position="end" onClick = {(evt) => { handleShowPassword( evt , id ) }}>
				<FontAwesomeIcon id={`icon-show-${id}`} icon={icons[id]}/>
			</InputAdornment>
		);
	}

	const renderLogin = () => {
		return (
			<div className="body-form">
				<div className="field">
					<TextField
						required
						error={!silentEmpty( `previous` , !isValidPassword( `previous` ) )}
						helperText={getHelperTextPassword( `previous` )}
						variant="outlined"
						id={`password-edit-previous`}
						placeholder={I18n.get("Current password")}
						defaultValue={values.previous}
						InputProps={{
							readOnly: props.readOnly,
							type:"password",
							endAdornment: renderShowPasswordIcon( `previous` )
						}}
						onChange={(evt) => handleInputChange( evt , "previous" ) }
						onBlur={(evt) => handleInputBlur( evt ) }
					/>
				</div>
				<div className="field">
					<TextField
						required
						error={!silentEmpty( `proposed` , !isValidPassword( `proposed` ) )}
						/*helperText={getHelperTextPassword( `proposed` )}*/
						variant="outlined"
						id={`password-edit-proposed`}
						placeholder={I18n.get("New password")}
						value={values.proposed}
						InputProps={{
							readOnly: props.readOnly,
							type:"password",
							endAdornment: renderShowPasswordIcon( `proposed` )
						}}
						onChange={(evt) => handleInputChange( evt , "proposed" ) }
						onBlur={(evt) => handleInputBlur( evt ) }
					/>
					<PasswordStrengthBar className="cm-password-strength-bar" 
										 password={values.proposed} 
										 barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
										 scoreWords={[I18n.get('Weak'), I18n.get('Weak'), I18n.get('Okay'), I18n.get('Good'), I18n.get('Strong')]}
										 minLength={8}
										 shortScoreWord={I18n.get("Too short")}/>
				</div>
				<div className="field">
					<TextField
						required
						error={!silentEmpty( `confirmed` , !isConfirmedPassword( `proposed` , `confirmed` ) )}
						helperText={getHelperTextConfirmedPassword()}
						variant="outlined"
						id={`password-edit-confirmed`}
						placeholder={I18n.get("Confirm password")}
						value={values.confirmed}
						InputProps={{
							readOnly: props.readOnly,
							type:"password",
							endAdornment: renderShowPasswordIcon( `confirmed` )
						}}
						onChange={(evt) => handleInputChange( evt , "confirmed" ) }
						onBlur={(evt) => handleInputBlur( evt ) }
					/>
				</div>
			</div>
		);
	}

	const renderActions = () => {
		if( props.readOnly ) {
			return (
				<div className="action-bar-dialog">
					<Button onClick={evt => {
						if( props.onDialogClose ) {
							props.onDialogClose();
						}
					}}>
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
					</Button>
				</div>
			);
		}
		
		return (
			<div className="action-bar-dialog">
				<Button onClick={(evt) => {
					if( props.onDialogClose !== null && props.onDialogClose !== undefined ) {
						props.onDialogClose();
					}
				}}>
					<span className="btn-wrapper--label">
						{I18n.get("CANCEL")}
					</span>
				</Button>
				<Button disabled={isSubmitDisabled()} onClick={handleValidation}>
					<span className="btn-wrapper--label">
						{I18n.get("OK")}
					</span>
				</Button>
			</div>
		);
	}

	let css = "shadow-sm-dark rounded-sm cm-dialog-confirm cm-dialog-change-password";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	
	return (
		<React.Fragment>
			<Dialog
				disableEscapeKeyDown
				classes={{ paper: css }}
				aria-labelledby="simple-dialog-title" 
				open={Boolean(props.opened)}>
				<div className="title">{I18n.get("Password modification")}</div>
				<div className="text-center p-3">
					{renderLogin()}
					{renderActions()}
				</div>
			</Dialog>
		</React.Fragment>
	);
}
