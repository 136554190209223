import React from "react";
import Grid from "@mui/material/Grid";
import InstallationStateMonitoring
    from "../../Components/HomeMonitoring/InstallationStateMonitoring/InstallationStateMonitoring";
import TechnicalAlerts from "../../Components/HomeMonitoring/TechnicalAlerts/TechnicalAlerts";
import './Home.css';
import InstallationQuickDisplay
    from "../../Components/HomeMonitoring/InstallationQuickDisplay/InstallationQuickDisplay";
import {getDetailedAccounts} from "../../../graphql/queries";
import GraphQlTool from "../../../Utils/GraphQlTool";
import {TechnicalAlertIdentifier} from "../../../Utils/TechnicalAlertIdentifier";

let instanceHome;
class Home extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            selected: null,
            refresh: false,
            accounts: null
        }
        instanceHome = this;
    }

    loadAccounts() {
        if( this.props.API ) {
            this.props.API
                .graphql({ query: getDetailedAccounts, variables: { accountId: this.props.currentUser.accountId }})
                .then( rawAccounts => {
                    const tool = new GraphQlTool( 'getDetailedAccounts' , rawAccounts );
                    this.setState( { accounts: tool.extract() } );
                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    onNewState( state ) {
        instanceHome.setState({ refresh: !instanceHome.state.refresh }) ;
    }

    handleSelection( payStation ) {
        instanceHome.setState({ selected: payStation }) ;
    }

    render() {
        if( ! this.state.accounts ) {
            this.loadAccounts();
        }
        const alerts = TechnicalAlertIdentifier.fetchAlerts( this.props.filteredPayStations );
        return (
            <React.Fragment>
                <Grid container spacing={1} className="main-lines">
                    <Grid item xs={12} md={8} lg={8} className={`installation-state-monitoring`}>
                        <InstallationStateMonitoring payStations={this.props.filteredPayStations}
                                                     onSelection={this.handleSelection}
                                                     selected={this.state.selected}
                                                     locale={this.props.locale} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} className={`display ${this.props.isDarkStyle ? 'dark' : ''}`}>
                        <Grid item xs={12} md={12} lg={12} className={`technical-alert`}>
                            <TechnicalAlerts alerts={ alerts }
                                             isDarkStyle={this.props.isDarkStyle}
                                             onSelection={this.handleSelection}/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} className={`installation-display`}>
                            <InstallationQuickDisplay selected={this.state.selected}
                                                      API={this.props.API}
                                                      accounts={this.state.accounts}
                                                      alerts={ alerts }
                                                      onRequestedBoxOverview={this.props.onRequestedBoxOverview}
                                                      onRequestedBoxDiagnostic={this.props.onRequestedBoxDiagnostic}
                                                      onRequestedMessages={this.props.onRequestedMessages}
                                                      onRequestedOperations={this.props.onRequestedOperations}
                                                      isDarkStyle={this.props.isDarkStyle} />
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default Home;
