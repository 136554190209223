import DeviceDataAnalyzer, {StateLevel} from "./DeviceDataAnalyzer";
import DeviceTemplate, {Mode} from "./DeviceTemplate";

export const TechnicalAlertIdentifier = {
    fetchAlerts: payStations => {
        const alerts = [];
        payStations?.forEach( payStation => {
            TechnicalAlertIdentifier
                .analyzeModulesForBox( payStation )
                .forEach( alert => {
                    alerts.push( alert );
                } );
        } );
        return alerts;
    },
    analyzeModulesForBox: payStation => {
        let alerts = [];
        payStation?.devices?.forEach( device => {
            if( TechnicalAlertIdentifier.isSupported( device?.informations?.identity?.model ) ) {
                const model = device?.informations?.identity?.model;
                const variant = ( device?.informations?.identity?.variant ) ? device?.informations?.identity?.variant : '';
                let attributes = {};
                try {
                    attributes = ( device?.technicalData?.attributes && device?.technicalData?.attributes !== `""` && device?.technicalData?.attributes !== ``) ? JSON.parse( device?.technicalData.attributes ) : {};
                } catch ( error ) {
                    console.error( error );
                }
                const data = TechnicalAlertIdentifier.buildDataStructure( device , new DeviceTemplate( model , variant , attributes ).getTemplate() , attributes );
                const analyzer = new DeviceDataAnalyzer(
                    model ,
                    ( model === 'BNR' && variant === '' ) ? 'BNR3' : variant ,
                    attributes ,
                    data ,
                    Mode.ALL
                );

                alerts = alerts.concat( TechnicalAlertIdentifier.analyzeSensorForMaintenance( data , analyzer , payStation , device ) );
            }
        } );
        return alerts;
    },
    isSupported: model => {
        return model?.substring( 0 , 3 ) === 'BNR';
    },
    buildDataStructure: ( device , template , attributes ) => {
        const dataReturned = {};
        if( device ) {
            const latest = TechnicalAlertIdentifier.extractLatestValuesForDevice( device );
            if( latest && latest.data ) {
                if( device.name.includes( "_BNR_" ) ) {
                    TechnicalAlertIdentifier.addDeviceData( JSON.parse( latest.data ) , dataReturned , template , device?.informations?.identity?.model );
                    TechnicalAlertIdentifier.addModulesData( JSON.parse( latest.data ) , dataReturned , template , device?.informations?.identity?.model );
                } else {
                    console.log("unknown model " , device);
                }
            } else {
                console.log( "device" , device );
            }

            return dataReturned;
        }
    },
    extractLatestValuesForDevice: device => {
        let latest = { updatedAt: "0000-00-00T00:00:00.000+00:00" };
        console.log( 'device' , device );
        if( device && device.technicalData ) {
            latest = device.technicalData;
        }
        return latest;
    },
    addDeviceData: ( fullData , objectToLoad , template , model ) => {
        if( fullData ) {
            let device = {};
            if( template.hasOwnProperty("device") ) {
                if( model.startsWith( "BNR" ) && fullData.hasOwnProperty( "Device" ) ) {
                    Object.keys( template.device ).forEach(  key  => {
                        let extractedValue = 0;
                        switch( key ) {
                            case "useHistory":
                                if( fullData.Device.hasOwnProperty("UseHistory") ) {
                                    extractedValue = {
                                        lastCheckDate:( fullData.Device.UseHistory.hasOwnProperty("CurrentDateTime") ) ? fullData.Device.UseHistory.CurrentDateTime : "0000-00-00T00:00:00.000+00:00",
                                        voltage: ( fullData.Device.UseHistory.hasOwnProperty("PowerSupplyVoltage") ) ? fullData.Device.UseHistory.PowerSupplyVoltage : 0,
                                        cycleCount: ( fullData.Device.UseHistory.hasOwnProperty("SystemCycleCount") ) ? fullData.Device.UseHistory.SystemCycleCount : 0,
                                        temperature: ( fullData.Device.UseHistory.hasOwnProperty("SystemTemperature") ) ? fullData.Device.UseHistory.SystemTemperature : 0,
                                        operationalSince: ( fullData.Device.UseHistory.hasOwnProperty("TimeSinceOperational") ) ? fullData.Device.UseHistory.TimeSinceOperational : 0,
                                        totalUpTime: ( fullData.Device.UseHistory.hasOwnProperty("TotalUpTime") ) ? fullData.Device.UseHistory.TotalUpTime : 0,
                                        upTime: ( fullData.Device.UseHistory.hasOwnProperty("UpTime") ) ? fullData.Device.UseHistory.UpTime : 0,
                                    };
                                }
                                break;
                            case "deviceAmountNotAvailableCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("AmountNotAvailableCount") ) ? fullData.Device.BillDispenseHistory.AmountNotAvailableCount : 0;
                                break;
                            case "deviceBillNotAvailableCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("BillNotAvailableCount") ) ? fullData.Device.BillDispenseHistory.BillNotAvailableCount : 0;
                                break;
                            case "deviceBillRequestedCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("BillRequestedCount") ) ? fullData.Device.BillDispenseHistory.BillRequestedCount : 0;
                                break;
                            case "deviceDirectFromLoaderCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("BillDispenseHistory") && fullData.Device.BillDispenseHistory.hasOwnProperty("DirectFromLoaderCount") ) ? fullData.Device.BillDispenseHistory.DirectFromLoaderCount : 0;
                                break;
                            case "deviceBillErrorCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("BillErrorCount") ) ? fullData.Device.FailureHistory.BillErrorCount : 0;
                                break;
                            case "deviceBillJamCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("BillJamCount") ) ? fullData.Device.FailureHistory.BillJamCount : 0;
                                break;
                            case "deviceHardwareFailureCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("HardwareFailureCount") ) ? fullData.Device.FailureHistory.HardwareFailureCount : 0;
                                break;
                            case "deviceMissingModuleCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("MissingModuleCount") ) ? fullData.Device.FailureHistory.MissingModuleCount : 0;
                                break;
                            case "deviceResetWithCoverOpenCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("ResetWithCoverOpenCount") ) ? fullData.Device.FailureHistory.ResetWithCoverOpenCount : 0;
                                break;
                            case "deviceResetWithInterlockOpenCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("ResetWithInterlockOpenCount") ) ? fullData.Device.FailureHistory.ResetWithInterlockOpenCount : 0;
                                break;
                            case "deviceTransportErrorCount":
                                extractedValue = ( fullData.Device.hasOwnProperty("FailureHistory") && fullData.Device.FailureHistory.hasOwnProperty("TransportErrorCount") ) ? fullData.Device.FailureHistory.TransportErrorCount : 0;
                                break;
                            default:
                                break;
                        }
                        device[key] = extractedValue;
                    } );
                }
            }

            objectToLoad.device = device;
        }
    },
    addModulesData: ( fullData , objectToLoad , template , attributes ) => {
        if( fullData ) {
            let modules = {};
            if( template.hasOwnProperty("modules") ) {
                modules['mainModule'] = TechnicalAlertIdentifier.buildMainModuleData( 'mainModule' , fullData.Module , template );
            }

            objectToLoad.modules = modules;
        }
    },
    buildMainModuleData: ( moduleName , fullData , template ) => {
        let sensorList = ( fullData.MainModule.hasOwnProperty("MaintenanceInfo") && fullData.MainModule.MaintenanceInfo.hasOwnProperty("MaintenanceSensorStatus") ) ? fullData.MainModule.MaintenanceInfo.MaintenanceSensorStatus : [];
        let sensorBundlerList = ( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("MaintenanceSensorStatus") ) ? fullData.Bundler.MaintenanceInfo.MaintenanceSensorStatus : [];

        return {
            sensors : {
                196870:TechnicalAlertIdentifier.extractSensorValue( sensorBundlerList , 196870),
                196873:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196873),
                196874:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196874),
                198144:TechnicalAlertIdentifier.extractSensorValue( sensorList , 198144),
                196865:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196865),
                196866:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196866),
                196867:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196867),
                196868:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196868),
                196869:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196869),
                196864:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196864),
                196871:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196871),
                196872:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196872),
                196875:TechnicalAlertIdentifier.extractSensorValue( sensorList , 196875),
            },
            bundler: {
                cyclesSinceLastMaintenance:( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("CycleSinceLastMaintenance") ) ? fullData.Bundler.MaintenanceInfo.CycleSinceLastMaintenance : 0,
                maintenaceBundlerSlippage:( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("MaintenanceBundlerSlippage") ) ? fullData.Bundler.MaintenanceInfo.MaintenanceBundlerSlippage/10 : 0,
                maintenanceInterval:( fullData.Bundler.hasOwnProperty("MaintenanceInfo") && fullData.Bundler.MaintenanceInfo.hasOwnProperty("MaintenanceInterval") ) ? fullData.Bundler.MaintenanceInfo.MaintenanceInterval : 0
            }
        };
    },
    extractSensorValue: ( sensorList , id ) => {
        let value = -1;
        sensorList.forEach(  candidate  => {
            try {
                if( candidate.ElementId === id ) {
                    value = candidate.SensorSignalEstimation;
                }
            } catch ( error ) {
                //secure unsafe data silently
            }
        } );
        return value;
    },
    analyzeSensorForMaintenance: ( data , analyzer , payStation , device ) => {
        const alertFound = [];
        if( ! data?.modules?.mainModule?.sensors ) {
            return alertFound;
        }
        Object.keys( data?.modules?.mainModule?.sensors ).forEach( key => {
            const state = analyzer.extractStateForSensor( 'mainModule' , data?.modules?.mainModule?.sensors[key] );
            if( state !== StateLevel.OPERATIONAL && key !== '196872' && key !== '196875' ) {
                //voir Seb 196872 / 196875
                alertFound.push( { type: 'SENSOR' , state: state , sensor: key , payStation: payStation , value: data?.modules?.mainModule?.sensors[key] , device: device } );
            }
        } );
        return alertFound;
    }
};
