import Task from './Task';
import UUID from "../../Utils/UUID";
const factoryUUID = new UUID();

class ScheduledTask {
	constructor( userManager , userChoice ) {
		this.id = factoryUUID.generate();
		this.time = "00:00";
		this.tasks = [];
		if( userChoice ) {
			this.userAccountId = userChoice.accountId;
			this.selection = userChoice.selection;
		}
		this.availableRecipients = [];
		this.loadDistinctRecipients( userManager );
	}
	
	loadDistinctRecipients( userManager ) {
		if( userManager && userManager.hasOwnProperty( "userProperties" ) ) {
			if( userManager.userProperties  &&
			userManager.userProperties.hasOwnProperty( "scheduledTasks" ) ) {
				userManager.userProperties.scheduledTasks.forEach( scheduledTask => {
					if( scheduledTask && scheduledTask.hasOwnProperty("tasks") ) {
						scheduledTask.tasks.forEach( ( task ) => {
							if( task  &&
								task.hasOwnProperty("recipients") && 
								task.recipients.length > 0 ) {
								this.mergeDistinct( task.recipients );	
							}
						} )
					}
				} );
			}
		}
	}
	
	mergeDistinct( toMergeRecipients ) {
		let newList = [];
		this.availableRecipients.forEach( recipient => {
			newList.push( recipient );
		} );
		toMergeRecipients.forEach( recipient => {
			let shouldInclude = true;
			newList.forEach( alreadySet => {
				if( recipient.emailAddress === alreadySet.emailAddress ) {
					shouldInclude = false;
				}
			} );
			
			if( shouldInclude ) {
				newList.push( recipient );
			}
		} );
		this.availableRecipients = newList;
	}
	
	createNewTask() {
		return new Task( this );
	}
	
	updateTaskList( task ) {
		let newTasks = [];
		let exists = false;
		this.tasks.forEach( registeredTask => {
			if( registeredTask.id === task.id ) {
				exists = true;
				newTasks.push( task );
			} else {
				newTasks.push( registeredTask );
			}
		} );
		
		if( ! exists ) {
			newTasks.push( task );
		}
		
		this.tasks = newTasks;
	}

	updateSelection( selection ) {
		this.selection = selection;
	}
	
	removeFromList( task ) {
		let newTasks = [];

		this.tasks.forEach( registeredTask => {
			if( registeredTask.id !== task.id ) {
				newTasks.push( registeredTask );
			}
		} );
		
		this.tasks = newTasks;
	}
	
	equals( anotherScheduledTask ) {
		return this.id === anotherScheduledTask.id;
	}
	
	static fromJson( jsonObject , propertiesProvider ) {
		let scheduledTask = new ScheduledTask();
		scheduledTask.id = jsonObject.id;
		scheduledTask.time = jsonObject.time;
		scheduledTask.userAccountId = jsonObject.userAccountId;
		scheduledTask.selection = jsonObject.selection;
		scheduledTask.tasks = [];
		jsonObject.tasks.forEach( task => {
			let taskGenerated = Task.fromJson( task , scheduledTask );
			scheduledTask.tasks.push( taskGenerated );
			scheduledTask.mergeDistinct( taskGenerated.recipients );
		} )
		return scheduledTask;
	}
	
	toJson() {
		return {
			id: this.id,
			time: this.time,
			tasks: this.tasks,
			userAccountId: this.userAccountId,
			selection:this.selection
		};
	}
}

export default ScheduledTask;
