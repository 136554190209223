export const Mode = {
	ALL:"all",
	BASIC:"basic",
	EXPERT:"expert"
}

class DeviceTemplate {	
	constructor( model , variant , attributes ) {
		this.model = model;
		this.variant = variant;
		this.attributes = attributes;
	}
	
	getTemplate() {
		switch( this.model ) {
			case "BNR": return this.getBNR();
			case "CLS": return this.getCLS();
			case "SCR": return this.getSCR();
			case "MDB_CoinRecycler": return this.getMDB();
			case "Scanner": return this.getVIPS();
			default : return {};
		}
	}
	
	buildFromAttributes() {
		if( this.attributes !== null && this.attributes !== undefined && this.attributes.hasOwnProperty( "identification" ) ) {
			let template = {
				device : {
					useHistory:{
						lastCheckDate:{type:"Date" , modes:[Mode.BASIC , Mode.EXPERT]},
						voltage:{type:"Int" , modes:[Mode.BASIC]},
						cycleCount:{type:"Int" , modes:[Mode.BASIC]},
						temperature:{type:"Thermometer" , modes:[Mode.BASIC]},
						operationalSince:{type:"Chrono" , modes:[Mode.BASIC]},
						totalUpTime:{type:"Chrono" , modes:[Mode.BASIC]},
						upTime:{type:"Chrono" , modes:[Mode.BASIC]},
						temperatureSensor:{type:"Thermometer" , modes:[Mode.BASIC] , attributes:{count:"Int",value:"Array"}}
					},
					deviceAmountNotAvailableCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceBillNotAvailableCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceBillRequestedCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceDirectFromLoaderCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceBillErrorCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceBillJamCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceHardwareFailureCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceMissingModuleCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceResetWithCoverOpenCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceResetWithInterlockOpenCount:{type:"Int" , modes:[Mode.EXPERT]},
					deviceTransportErrorCount:{type:"Int" , modes:[Mode.EXPERT]},
				},
				modules : {
					
				}
			};
			this.attributes.identification.map( (component) => {
				if( component !== null && component !== undefined && component.hasOwnProperty("iot_name") ) {
					template.modules[component["iot_name"].replace(".Id." , "")] = {
						identification:component
					}
				}
				return true;
			} );
		}
	}
	
	getBNR() {
		//build a BNR 3 template
		let bnrTemplate = {
			device : {
				useHistory:{
					lastCheckDate:{type:"Date" , modes:[Mode.BASIC , Mode.EXPERT]},
					voltage:{type:"Int" , modes:[Mode.BASIC]},
					cycleCount:{type:"Int" , modes:[Mode.BASIC]},
					temperature:{type:"Thermometer" , modes:[Mode.BASIC]},
					operationalSince:{type:"Chrono" , modes:[Mode.BASIC]},
					totalUpTime:{type:"Chrono" , modes:[Mode.BASIC]},
					upTime:{type:"Chrono" , modes:[Mode.BASIC]},
					temperatureSensor:{type:"Thermometer" , modes:[Mode.BASIC] , attributes:{count:"Int",value:"Array"}}
				},
				deviceAmountNotAvailableCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillNotAvailableCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillRequestedCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceDirectFromLoaderCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillErrorCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillJamCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceHardwareFailureCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceMissingModuleCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceResetWithCoverOpenCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceResetWithInterlockOpenCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceTransportErrorCount:{type:"Int" , modes:[Mode.EXPERT]},
			},
			modules : {
				mainModule : {
					sensors : {
						196870:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196873:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196874:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						198144:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196865:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196866:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196867:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196868:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196869:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196864:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196871:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					bundler: {
						cyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						maintenaceBundlerSlippage:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						maintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				spine : {
					sensors : {
						198402:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						198403:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				cashbox : {
					cbLevelChangeCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbModuleChangedCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbModuleFullCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbModuleRemovalCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbInternalResetCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbPowerUpCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbBillErrorCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbBillJamCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbHardwareFailureCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbTransportErrorCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbNotArmedCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				},
				recycler3 : {
					sensors : {
						198452:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r3Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				},
				recycler4 : {
					sensors : {
						198453:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r4Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				}
			}
		};
			
		if( this.variant.includes( "BNR4" ) ) {
			//add BNR 4 optionnal sensor
			bnrTemplate.modules.spine.sensors["198404"] = {type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]};
			bnrTemplate.modules.recycler5 = {
					sensors : {
						198454:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r5Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				};
			bnrTemplate.modules.recycler6 = {
					sensors : {
						198455:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r6Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				};
		} else if ( this.variant.includes( "BNR3" )  ) {
			//add specific recyclers for BNR 3
			
			if( this.variant !== null && this.variant !== undefined ) {
				if( this.variant.trim().endsWith("Advance") ) {
					bnrTemplate.modules.mainModule.sensors["196875"] = {type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]};
				} else {
					bnrTemplate.modules.mainModule.sensors["196872"] = {type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]};
				}
			} 

			bnrTemplate.modules.recycler1 = {
					sensors : {
						198450:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r1Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r1Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r1Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r1CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r1MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				};
			bnrTemplate.modules.recycler2 = {
					sensors : {
						198451:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r2Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r2Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r2Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r2CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r2MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				};
		}
		
		return bnrTemplate;
		/*
		return {
			device : {
				useHistory:{
					lastCheckDate:{type:"Date" , modes:[Mode.BASIC , Mode.EXPERT]},
					voltage:{type:"Int" , modes:[Mode.BASIC]},
					cycleCount:{type:"Int" , modes:[Mode.BASIC]},
					temperature:{type:"Thermometer" , modes:[Mode.BASIC]},
					operationalSince:{type:"Chrono" , modes:[Mode.BASIC]},
					totalUpTime:{type:"Chrono" , modes:[Mode.BASIC]},
					upTime:{type:"Chrono" , modes:[Mode.BASIC]},
					temperatureSensor:{type:"Thermometer" , modes:[Mode.BASIC] , attributes:{count:"Int",value:"Array"}}
				},
				deviceAmountNotAvailableCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillNotAvailableCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillRequestedCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceDirectFromLoaderCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillErrorCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceBillJamCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceHardwareFailureCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceMissingModuleCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceResetWithCoverOpenCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceResetWithInterlockOpenCount:{type:"Int" , modes:[Mode.EXPERT]},
				deviceTransportErrorCount:{type:"Int" , modes:[Mode.EXPERT]},
			},
			modules : {
				mainModule : {
					sensors : {
						196870:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196873:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196874:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						198144:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196865:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196866:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196867:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196868:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196869:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196864:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196871:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						196875:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					bundler: {
						cyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						maintenaceBundlerSlippage:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						maintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				spine : {
					sensors : {
						198402:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						198403:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]},
						198404:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				cashbox : {
					cbLevelChangeCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbModuleChangedCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbModuleFullCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbModuleRemovalCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbInternalResetCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbPowerUpCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbBillErrorCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbBillJamCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbHardwareFailureCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbTransportErrorCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					cbNotArmedCount:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				},
				recycler1 : {
					sensors : {
						198450:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				recycler2 : {
					sensors : {
						198451:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				recycler3 : {
					sensors : {
						198452:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r3Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r3MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				},
				recycler4 : {
					sensors : {
						198453:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r4Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r4MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				},
				recycler5 : {
					sensors : {
						198454:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r5Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r5MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				},
				recycler6 : {
					sensors : {
						198455:{type:"Sensor" , modes:[Mode.BASIC , Mode.EXPERT]}
					},
					r6Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6Dispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6CyclesSinceLastMaintenance:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					r6MaintenanceInterval:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
				}
			}
		};*/
	}
	
	getCLS() {
		return {
			device:{
				useHistory:{
					lastCheckDate:{type:"Date" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				coinOutputTrayFull:{type:"Boolean" , modes:[Mode.BASIC , Mode.EXPERT]},
				coinOutputTrayPresent:{type:"Boolean" , modes:[Mode.BASIC , Mode.EXPERT]},
				lowerCtcPowered:{type:"Boolean" , modes:[Mode.BASIC , Mode.EXPERT]},
				upperCtcPowered:{type:"Boolean" , modes:[Mode.BASIC , Mode.EXPERT]},
				checksumErrorDatablock:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				checksumErrorFirmware:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				checksumErrorRAM:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				coinJam:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				coinOversize:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				coinTooBig:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				coinUndersize:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				coinWasSlug:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				creditSensorBlocked:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				daughterBoardNotConnected:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				deviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				diameterMeasurementErrorTriggerCalculation:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				diameterTimingMeasurementMisfit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				errorStateTransitions:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				fullCoinOutputTrayConditions:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				hoppersCommunicationsErrors:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				hostCommunicationsErrors:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				measurementTimeout:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				missingJammedCoin:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				operatingLifetime:{type:"Chrono" , modes:[Mode.BASIC , Mode.EXPERT]},
				opticalSensorBlocked:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				performances:{
					indicatorAcceptanceRate:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorAcceptedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorAcceptorJams:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorAcceptorProcessedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorAccountancyErrorRate:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorDispenserJams:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorJamRate:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorMiscreditedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorMissortedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorMissortingErrorRate:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorPayInVolumeRate:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorSortedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorSystemProcessedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					indicatorTransactionMisrouteRate:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				payInTransactionVolume:{
					"1To4Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"5To9Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"10To19Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"20To29Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"30To39Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"40To49Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"50To99Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"100To199Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"200To299Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"300To399Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"400To499Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					"500To999Coins":{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				sortedCoin:{
					sortedCoinTotal:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					sortedCoinTotalWithMisroutedCoins:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					error:{
						sortingPath1SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath2SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath3SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath4SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath5SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath6SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath7SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						sortingPath8SensorError:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]}
					}
				},
				power:{
					powerUps:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					powerUpsCozIndependentWatchdogReset:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					powerUpsCozLowPowerManagementReset:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					powerUpsCozPorPdrReset:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					powerUpsCozResetFromNrstPin:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					powerUpsCozSoftwareReset:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					powerUpsCozWindowWatchdogReset:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				transportBeltMotorMalfunction:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				transportBeltMotorStarts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				trashdoorMotorStarts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
			},
			modules:{
				hopper1:{
					h1Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1Rejected:{
						h1DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h1SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h1Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h1SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper2:{
					h2Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2Rejected:{
						h2DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h2SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h2Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h2SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper3:{
					h3Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3Rejected:{
						h3DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h3SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h3Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h3SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper4:{
					h4Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4Rejected:{
						h4DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h4SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h4Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h4SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper5:{
					h5Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5Rejected:{
						h5DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h5SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h5Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h5SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper6:{
					h6Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6Rejected:{
						h6DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h6SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h6Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h6SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper7:{
					h7Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7Rejected:{
						h1DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h1SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h7Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h7SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				hopper8:{
					h8Currency:{type:"Currency" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8Value:{type:"Amount" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8LifetimeDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8SuccessDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8ErrorDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8CoinEjectTimeoutExceeded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8Sorted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8Accepted:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8Rejected:{
						h8DeviceDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
						h8SerialInhibit:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					},
					h8Overcurrent:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8MisroutedAfterSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8MisroutedBeforeSortCoinType:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8SelectorRef:{type:"String" , modes:[Mode.BASIC , Mode.EXPERT]},
					h8SortHole:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				centrifuge:{
					centrifugeMotorStarts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					centrifugeMotorMalfunction:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				conveyor:{
					conveyorMotorStarts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					conveyorMotorErrors:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				acceptor:{
					acceptorCommunicationsErrors:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					acceptorKnownCoinsRejected:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					acceptorDeviceErrors:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					acceptorUnknownCoinsRejected:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				}
			}
		};
	}
	
	getSCR() {
		return {
			device:{
				useHistory:{
					lastCheckDate:{type:"Date" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				motorStarts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				securityRejections:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				operatingHours:{type:"Chrono" , modes:[Mode.BASIC , Mode.EXPERT]},
				outOfOrder:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				outOfService:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				hostResets:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				resets:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				recognitionRejection:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				documentDisabledRejections:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				orientationDisabledRejections:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				hostReturnDocumentRejections:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				barcodesDecoded:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				docsRejectForOtherReason:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				escrowTimeoutRejections:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				docsThatFailedToReachEscrowPosition:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				allTypesOfJams:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				jamRecoveryEfforts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				outOfServiceForJammedOnReset:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				flashDownloadAttempts:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
			},
			modules:{
				acceptor:{
					notesRecognized:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					docInsertedWhileDisabled:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					docInsertedWhileBusy:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					notesStacked:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					fastFeedErrorReject:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					notesValidated:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					notesDispensed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					outOfServiceForJammed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					escrowPositionFailure:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					calibrations:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					notesTooBig:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					notesTooSmall:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					docRejectedForOtherReason:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				vault:{
					
				},
				recyclers:{
					notesStackedToRecyclers:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					outOfServiceForRecyclerOpened:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				cashbox:{
					notesStackedToCashbox:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					removed:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					full:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
					capacity:{type:"Int" , modes:[Mode.BASIC , Mode.EXPERT]},
				},
				chassis:{
					
				}
			}
		};
	}
	
	getMDB() {
		return {};
	}
	
	getVIPS() {
		return {};
	}
}

export default DeviceTemplate;
