import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ComparisonIcon from '@mui/icons-material/AccessTime';
import DistributionIcon from '@mui/icons-material/BubbleChart';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BarChartIcon from '@mui/icons-material/BarChart';
import MessageIcon from '@mui/icons-material/Email';
import SecurityIcon from '@mui/icons-material/Security';
import TransactionIcon from '@mui/icons-material/LocalAtm';
import ReportIcon from '@mui/icons-material/Assignment';
import OperationIcon from '@mui/icons-material/Receipt';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { I18n } from 'aws-amplify/utils';

import './Drawer.css';
import {UserRoles} from "../../../Models/Roles";
import {AssignmentTurnedIn, WorkspacePremium , History} from "@mui/icons-material";

const Restricted = {
	ADMIN:"ADMIN",
	ACTIVATIONS:"ACTIVATIONS",
	HARDWARES:"HARDWARES",
	LICENSES:"LICENSES"
}

export default function CmDrawer( props ) {
	const [expanded, setExpanded] = React.useState(true);
	const [expandedTech, setExpandedTech] = React.useState(false);
	const [expandedLicense, setExpandedLicense] = React.useState(true);
	const handleNestedListClick = () => {
		setExpanded( ! expanded ); 
	};
	
	const handleTechNestedListClick = () => {
		setExpandedTech( ! expandedTech ); 
	};

	const handleLicenseNestedListClick = () => {
		setExpandedLicense( ! expandedLicense );
	};
	
	const renderRestrictedAccess =  menu  => {
		const ADMIN = 1;
		const SUPPORT = 2;
		const RESELLER = 4;
		const FULL_ACCESS = 1;
		const ADMIN_ACCESS = 2;
		const UNRESTRICTED_USERS = [ 1 , 401 ];
		switch( menu ) {
			case Restricted.ADMIN:
				if( props.currentUser ) {
					if( ( props.currentUser.type === FULL_ACCESS || props.currentUser.type === ADMIN_ACCESS ) &&
						props.isDesktop &&
						props.currentUser.role !== UserRoles.SALE_ROLE &&
						props.currentUser.role !== UserRoles.ADMINISTRATIVE ) {
						return (
							<ListItemButton selected={props.selectedIndex === 13}
									  		onClick={(event) => props.handleListItemClick(event, 13)}>
								<ListItemIcon>
									<SecurityIcon />
								</ListItemIcon>
								<ListItemText primary={I18n.get("Administration")} />
							</ListItemButton>
						);
					}
				}
			break;
			case Restricted.ACTIVATIONS:
				//return null;
				if( props.currentUser ) {
					if( ( props.currentUser.accountType === ADMIN ||
						props.currentUser.accountType === SUPPORT ||
						props.currentUser.accountType === RESELLER ||
						props.currentUser.type === FULL_ACCESS ) &&
						props.isDesktop &&
						props.currentUser.role !== UserRoles.SALE_ROLE &&
						props.currentUser.role !== UserRoles.ADMINISTRATIVE ) {
						return (
							<ListItemButton selected={props.selectedIndex === 14}
											onClick={(event) => props.handleListItemClick(event, 14)}>
								<ListItemIcon>
									<ImportantDevicesIcon />
								</ListItemIcon>
								<ListItemText primary={I18n.get("Activations")} />
							</ListItemButton>
						);
					}
				}
			break;
			case Restricted.HARDWARES:
				//return null;
				if( props.currentUser ) {
					if( ( props.currentUser.accountType === ADMIN ||
						props.currentUser.accountType === SUPPORT ||
						props.currentUser.accountType === RESELLER ||
						props.currentUser.type === FULL_ACCESS ) &&
						props.isDesktop &&
						props.currentUser.role !== UserRoles.ADMINISTRATIVE) {
						return (
							<React.Fragment>
								<ListItemButton
									onClick={handleTechNestedListClick}>
									<ListItemIcon>
										<SettingsSystemDaydreamIcon />
									</ListItemIcon>
									<ListItemText primary={I18n.get("Hardwares")} />
									{expandedTech ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
								<Collapse in={expandedTech} timeout="auto" unmountOnExit>
									<List component="div" disablePadding className="subList">
										{renderMonitoring()}
										{renderOverview()}
										<ListItemButton selected={props.selectedIndex === 17}
														onClick={(event) => props.handleListItemClick(event, 17)}>
											<ListItemIcon>
												<SettingsIcon />
											</ListItemIcon>
											<ListItemText primary={I18n.get("Diagnostic")} />
										</ListItemButton>
									</List>
								</Collapse>
							</React.Fragment>
						);
					}
				}

			break;
			case Restricted.LICENSES:
				if( props.currentUser ) {
					if( props.isDesktop &&
						( props.currentUser.role === UserRoles.ADMINISTRATIVE ||
							UNRESTRICTED_USERS.includes(props.currentUser.id)  ) ) {
						return (
							<>
								<ListItemButton selected={props.selectedIndex === 18 || props.selectedIndex === 19 }
												onClick={handleLicenseNestedListClick}>
									<ListItemIcon>
										<WorkspacePremium />
									</ListItemIcon>
									<ListItemText primary={I18n.get("Licenses")} />
									{expandedLicense ? <ExpandLess /> : <ExpandMore />}
								</ListItemButton>
								<Collapse in={expandedLicense} timeout="auto" unmountOnExit>
									<List component="div" disablePadding className="subList">
										<ListItemButton selected={props.selectedIndex === 18}
														onClick={(event) => props.handleListItemClick(event, 18)}>
											<ListItemIcon>
												<AssignmentTurnedIn />
											</ListItemIcon>
											<ListItemText primary={I18n.get("Assignment")} />
										</ListItemButton>
										<ListItemButton selected={props.selectedIndex === 19}
														onClick={(event) => props.handleListItemClick(event, 19)}>
											<ListItemIcon>
												<History />
											</ListItemIcon>
											<ListItemText primary={I18n.get("History")} />
										</ListItemButton>
									</List>
								</Collapse>
							</>
						);
					}
				}

				break;
			default:
			break;
		}
		
		return null;
	}

	const renderOverview = () => {
		if( props?.currentUser?.role !== UserRoles.SALE_ROLE ) {
			return (
				<ListItemButton selected={props.selectedIndex === 15}
								onClick={(event) => props.handleListItemClick(event, 15)}>
					<ListItemIcon>
						<DeveloperBoardIcon />
					</ListItemIcon>
					<ListItemText primary={I18n.get("Overview")} />
				</ListItemButton>
			);
		}
		return null;
	}

	const renderReports = () => {
		if( props?.currentUser?.role === UserRoles.ADMINISTRATIVE ) {
			return null;
		}

		return (
			<>
				<ListItemButton selected={props.selectedIndex === 1}
								onClick={handleNestedListClick}>
					<ListItemIcon>
						<ReportIcon />
					</ListItemIcon>
					<ListItemText primary={I18n.get("Reports")} />
					{expanded ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<List component="div" disablePadding className="subList">
						<ListItemButton selected={props.selectedIndex === 9}
										onClick={(event) => props.handleListItemClick(event, 9)}
										className={nested}>
							<ListItemIcon>
								<OperationIcon />
							</ListItemIcon>
							<ListItemText primary={I18n.get("Operations")} disableTypography={true}/>
						</ListItemButton>
						<ListItemButton selected={props.selectedIndex === 4}
										onClick={(event) => props.handleListItemClick(event, 4)}
										className={nested}>
							<ListItemIcon>
								<TransactionIcon />
							</ListItemIcon>
							<ListItemText primary={I18n.get("Cash in")} disableTypography={true}/>
						</ListItemButton>
						<ListItemButton selected={props.selectedIndex === 5}
										onClick={(event) => props.handleListItemClick(event, 5)}
										className={nested}>
							<ListItemIcon>
								<BarChartIcon />
							</ListItemIcon>
							<ListItemText primary={I18n.get("Inventories")} disableTypography={true}/>
						</ListItemButton>
						<ListItemButton selected={props.selectedIndex === 10}
										onClick={(event) => props.handleListItemClick(event, 10)}
										className={nested}>
							<ListItemIcon>
								<DistributionIcon />
							</ListItemIcon>
							<ListItemText primary={I18n.get("Distributions")} disableTypography={true} />
						</ListItemButton>
						<ListItemButton selected={props.selectedIndex === 8}
										onClick={(event) => props.handleListItemClick(event, 8)}
										className={nested}>
							<ListItemIcon>
								<ComparisonIcon />
							</ListItemIcon>
							<ListItemText primary={I18n.get("Comparisons")} disableTypography={true}/>
						</ListItemButton>
						{/*<ListItem button
										  selected={props.selectedIndex === 3}
										  onClick={(event) => props.handleListItemClick(event, 3)}
										  className={nested}>
									<ListItemIcon>
										<CashierIcon />
									</ListItemIcon>
									<ListItemText primary={I18n.get("Cashiers")} />
								</ListItem>*/}
						{/*<ListItemButton selected={props.selectedIndex === 16}
												onClick={(event) => props.handleListItemClick(event, 16)}
												className={nested}>
									<ListItemIcon>
										<InsightsIcon />
									</ListItemIcon>
									<ListItemText primary={I18n.get("Forecasts")} disableTypography={true}/>
								</ListItemButton>*/}
						<ListItemButton selected={props.selectedIndex === 6}
										onClick={(event) => props.handleListItemClick(event, 6)}
										className={nested}>
							<ListItemIcon>
								<MessageIcon />
							</ListItemIcon>
							<ListItemText primary={I18n.get("Messages")} disableTypography={true}/>
						</ListItemButton>
					</List>
				</Collapse>
			</>
		);
	}

	const renderHome = () => {
		return null;
		/*if( props?.currentUser?.role === UserRoles.ADMINISTRATIVE ) {
			return null;
		}
		return (
			<ListItemButton selected={props.selectedIndex === 0}
							onClick={(event) => props.handleListItemClick(event, 0)}>
				<ListItemIcon>
					<DashboardIcon />
				</ListItemIcon>
				<ListItemText primary={I18n.get("Dashboard")} />
			</ListItemButton>
		);*/
	}

	const renderMonitoring = () => {
		if( props?.currentUser?.role === UserRoles.ADMINISTRATIVE ) {
			return null;
		}
		return (
			<ListItemButton selected={props.selectedIndex === 20}
							onClick={(event) => props.handleListItemClick(event, 20)}>
				<ListItemIcon>
					<MonitorHeartIcon />
				</ListItemIcon>
				<ListItemText primary={I18n.get("Monitoring")} />
			</ListItemButton>
		);
	}

	const nested = "";
	const sharedStyle = ( props.isDesktop ) ? 'drawer-paper desktop' : 'drawer-paper';
	return (
		<Drawer variant="permanent"
				open={props.opened}
				className={`${ (props.opened) ? sharedStyle : sharedStyle + ' close' }`}
				classes={{
					paper: `${(props.opened)? sharedStyle : sharedStyle + ' close'}`
				}}>
			<div className="toolbarIcon">
				<IconButton onClick={ event => props.handleDrawer(false)}>
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<Divider />
			<List>
				{renderHome()}
				{renderReports()}
				{/*<ListItemButton selected={props.selectedIndex === 7}
								onClick={(event) => props.handleListItemClick(event, 7)}>
					<ListItemIcon>
						<MapIcon />
					</ListItemIcon>
					<ListItemText primary={I18n.get("Map")} />
				</ListItemButton>*/}
				{renderRestrictedAccess( Restricted.ACTIVATIONS )}
				{renderRestrictedAccess( Restricted.HARDWARES )}
				{renderRestrictedAccess( Restricted.ADMIN )}
				{renderRestrictedAccess( Restricted.LICENSES )}
				{/*<ListItemButton
						  selected={props.selectedIndex === 2}
						  onClick={(event) => props.handleListItemClick(event, 2)}>
					<ListItemIcon>
						<ImportantDevicesIcon />
					</ListItemIcon>
					<ListItemText primary={I18n.get("Environment")} />
				</ListItemButton>*/}
			</List>
		</Drawer>
	);
}
