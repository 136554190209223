import React from 'react';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import UUID from "../../../Utils/UUID";
import { getIconForNode } from '../../../Models/Roles';
import './BoxSelector.css';

export default function BoxSelector( props ) {
	const factoryUUID = new UUID();
	
	const getBoxById = ( id ) => {
		let found = null;
		if( props.boxes ) {
			props.boxes.forEach( box => {
				if( box.id === id ) {
					found = box;
				}
			} );
		}
		return found;
	}
	
	const handleChange = ( evt ) => {
		if( props.onChange ) {
			props.onChange( evt , props.boxes.indexOf( getBoxById( evt.target.value ) ) );
		}
	};
	
	const renderMenuItem = ( box ) => {
		
		if( box ) {
			let optValue =  box.id;
			if( props.showIcon /*&& props.icons !== null && props.icons !== undefined /*&& props.icons.hasOwnProperty(optionValue)*/ ) {
				return (
					<MenuItem  key={`menu-box-selector-item-${optValue}-${factoryUUID.generate()}`}
							   className={`box-select-item`}
							   value={optValue}>
						<span className={`box-select-icon`}>
							<FontAwesomeIcon icon={getIconForNode(box)} style={{fontSize:"1.75em"}}/>
						</span>
						<span className={`box-select-line`}>
							<div className={`main`}>
								<span className="ml-2">{renderBoxLabel( box )}</span>
							</div>
							<div className={`sub ml-2`}>
								{renderBoxExtraLabel( box )}
							</div>
						</span>


					</MenuItem>
				);
			}
			return (
				<MenuItem  key={`menu-box-selector-item-${optValue}-${factoryUUID.generate()}`}
						   value={optValue}>
					<div className={`box-select-line`}>
						<div className={`main`}>
							<span className="ml-2">{renderBoxLabel( box )}</span>
						</div>
						<div className={`sub`}>
							{renderBoxExtraLabel( box )}
						</div>
					</div>
				</MenuItem>
			);
		}
		return (
			<MenuItem key={`menu-box-selector-item-none`} value="-1">
				<em>{I18n.get("None")}</em>
			</MenuItem>
		);
	}
	
	const renderBoxLabel = box => {
		if ( box && box.name ) {
			return `${( box.informations && box.informations.attributes && box.informations.attributes.name ) ? box.informations.attributes.name : box.name}`;
		}
		return "Error";
	};

	const renderBoxExtraLabel = box => {
		let label = "";
		if ( box && box.name ) {
			let extraData = ( box.extraData ) ? box.extraData : null;
			if ( extraData && extraData.trim().length > 0 ) {
				label += `${extraData}`;
			}
		}
		return label;
	};
	
	const options = [];
	if( props.allowEmpty ) {
		options.push( renderMenuItem ( null ) );
	}

	if( props.boxes ) {
		props.boxes.forEach( box => {
			options.push( renderMenuItem( box ) );
		} );
	}
	
	let css = "select-box-selector";
	let cssSelect = "combo-box-selector";
	let cssLabel = "label-box-selector";
	if( props.isDarkStyle ) {
		css += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}

	if( props.className ) {
		css += ` ${props.className}`;
	}

	return (
		<React.Fragment>
			<FormControl variant="outlined" className={css}>
				<InputLabel className={cssLabel} 
							id={`input-combo-box-selector`}>
					{props.label}
				</InputLabel>
				<Select classes={{selectMenu:cssSelect}}
						labelId={`combo-box-selector-label`}
						id={`combo-box-selector-select`}
						value={ ( props.selected >= 0 ) ? props.boxes[props.selected].id : "-1" }
						onChange={handleChange}
						MenuProps={{
							classes:{paper:cssSelect}
						}}
						label={props.label} >
					{options.map( item => item )}
				</Select>
			</FormControl>
		</React.Fragment>
	);
}
