import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';

import '../NodeItemDialog/NodeItemDialog.css';

export default function DialogConfirmResend( props ) {
	const handleCancel = () => {
		props.handleClose();
	};

	const handleOk = () => {
		props.handleClose( "resend" , props.pendingResend );
	};
	
	const renderCustomLabel = () => {
		if( props.customLabel ) {
			return (<div className="custom-label">{props.customLabel}</div>);
		}
		return null;
	}
	
	let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
	if( props.isDarkStyle ) {
		css += " dark";
	}

	const title = ( props.pendingResend && props.pendingResend.title ) ? props.pendingResend.title : I18n.get("Are you sure") ;
	const message = ( props.pendingResend  ) ? I18n.get("CONFIRM_RESEND") : '' ;

	return (
		<Dialog classes={{ paper: css }}
				aria-labelledby="simple-dialog-title" 
				open={Boolean(props.pendingResend)}>
			<div className="text-center p-5">
				<div className="avatar-icon-wrapper rounded-circle m-0">
					<div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
						<FontAwesomeIcon icon={['fas', 'question']} className="d-flex align-self-center display-3"/>
					</div>
				</div>
				{renderCustomLabel()}
				<h4 className="font-weight-bold mt-4">{title}</h4>
				<p className="mb-0 text-black-50">{message}</p>
				<div className="pt-4">
					<Button autoFocus onClick={handleCancel} className="btn-neutral-secondary btn-pill text-danger mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("CANCEL")}
						</span>
					</Button>
					<Button onClick={handleOk} className="btn-first btn-pill mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
					</Button>
				</div>
			</div>
		</Dialog>
		
	);
}
