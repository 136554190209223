import React from 'react';
import {GridLoader} from 'react-spinners';
import './LoadingIndicator.css';

export default function LoadingIndicator( props ) {
	let className;
	if( props.loading ) {
		className = "loading";
	} else {
		className = "";
	}
	if( props.modal ) {
		return (
			<React.Fragment>
				<div id="modal-loader" className={className}>
					<div id="loading-indicator">
						 <div className="d-flex align-items-center justify-content-center" style={{width: '150px', height: '80px'}}>
							<GridLoader color={'#FFFFFF'} loading={true}/>
						</div>
					</div>
				</div>
			</React.Fragment>
			
		);
	}
	return (
		<React.Fragment>
			<div id="loading-indicator" className={className}>
				<div className="d-flex align-items-center justify-content-center" style={{width: '150px', height: '80px'}}>
					<GridLoader color={'#FFFFFF'} loading={true}/>
				</div>
			</div>
		</React.Fragment>
		
	);
}