import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import './SensorStateIndicator.css';
import '../InventoryCard/InventoryCard.css';
import '../../Views/Inventory/Inventory.css';

const StateLevel = {
	OPERATIONAL:"operational",
	CLEANUP:"cleanup",
	MAINTENANCE:"maintenance",
}

export default function SensorStateIndicator( props ) {
	
	const percentFormatter = new Intl.NumberFormat(props.locale ,
			{
				style: 'percent',
				minimumFractionDigits: (props.decimal) ? 1 : 0,
				maximumFractionDigits: (props.decimal) ? 1 : 0
			});
	const strokeWidth = 8;
	const getCssProgress = () => {
		let css = `${(props.small) ? "" : "m-3"} circular-progress-cm circular-progress-${(props.small) ? "xs" : "sm"} circular-progress-`;
		//info danger success
		
		if( props.sensor && props.sensor.hasOwnProperty("state") ) {
			switch( props.sensor.state ) {
				case StateLevel.OPERATIONAL:
					css += "success";
				break;
				case StateLevel.CLEANUP:
					css += "warning";
				break;
				case StateLevel.MAINTENANCE:
					css += "danger";
				break;
				default:
				break;
			}
		}
		
		return css;
	}
	
	if( props.sensor !== null && props.sensor !== undefined ) {
		return( 
			<React.Fragment>
				<div className={`sensor-state-indicator-root`}>
					<CircularProgressbar value={props.sensor.value} text={percentFormatter.format( props.sensor.value/100 )} strokeWidth={strokeWidth} className={getCssProgress()} />
					<div className={`id`}>{props.sensor.id}</div>
				</div>
			</React.Fragment>
		);
	}
	return null;
}
