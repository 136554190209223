import React from "react";
import {I18n} from "aws-amplify/utils";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import UUID from "../../../Utils/UUID";
import {getIconForNode, roles} from "../../../Models/Roles";
import CountrySelector from "../CountrySelector/CountrySelector";

let currentField = {
    id:null,
    lastChange:null,
    value:null
};

const RESELLER = 4;
const CUSTOMER = 5;

export default function DialogAccount( props ) {
    const [editing, setEditing] = React.useState(null );

    const factoryUUID = new UUID();

    const handleCancel = () => {
        props.handleClose();
        setEditing(null);
    };

    const handleOk = () => {
        props.handleClose( editing );
        setEditing(null);
    };

    const handleInputChange = ( evt , property ) => {
        currentField = {
            id:`user-edited-${property}`,
            lastChange:new Date(),
            value:evt.target.value,
            property:property
        }

        setTimeout( () => {
            if( currentField.id ) {
                if( ( new Date().getTime() - currentField.lastChange.getTime() ) > 3000 ) {
                    props.pendingEdit[currentField.property] = currentField.value;
                    editing[currentField.property] = currentField.value;
                    currentField = {
                        id:null,
                        lastChange:null,
                        value:null,
                        property:null
                    };
                }
            }
        } , 5000 );

        let clone = Object.assign( {} , editing );
        clone[currentField.property] = currentField.value;
        props.pendingEdit[currentField.property] = currentField.value;
        setEditing( clone );
    }

    const handleSelectChange = evt => {
        updateSelectProperty( "Type" , evt.target.value );
    };

    const onCountryChanged = newValue => {
        updateSelectProperty( "Locale" , newValue );
    };

    const updateSelectProperty = ( property , newValue ) => {
        let clone = Object.assign({}, editing);
        props.pendingEdit[ property ] = newValue;
        clone[ property ] = newValue;
        setEditing(clone);
    };

    const handleInputBlur = evt => {
        if( currentField.id ) {
            props.pendingEdit[currentField.property] = currentField.value;
            editing[currentField.property] = currentField.value;
            currentField = {
                id:null,
                lastChange:null,
                value:null,
                property:null
            };
        }
    }

    const isSubmitDisabled = () => {
        return !isValidName() || !isValidEmail();
    }

    const isValidName = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Name && props.pendingEdit.Name.trim() !== "" ) {
                return true;
            }
        }
        return false;
    }

    const isValidEmail = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Email ) {
                return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( props.pendingEdit.Email );
            }
        }
        return false;
    }

    const isValidPhoneNumber = () => {
        /*if( props.pendingEdit ) {
            if( props.pendingEdit.Phone && props.pendingEdit.Phone.trim() !== "" ) {
                return /^\+[1-9]\d{10,14}$/.test( props.pendingEdit.Phone );
            }
        }*/
        return true; //optional
    }

    const getHelperTextEmail = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Email && props.pendingEdit.Email.trim() !== "" ) {
                if( ! isValidEmail() ) {
                    return I18n.get("Invalid field").replace("%format%" , I18n.get( "Email format" ) );
                }
            } else {
                return I18n.get("Required field");
            }
        }

        return null;
    }

    const getHelperTextName = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Name && props.pendingEdit.Name.trim() !== "" ) {
                return null;
            } else {
                return I18n.get("Required field");
            }
        }

        return null;
    }

    const getHelperTextPhone = () => {
        if( props.pendingEdit ) {
            if( props.pendingEdit.Phone && props.pendingEdit.Phone.trim() !== "" ) {
                if( !isValidPhoneNumber() ) {
                    return I18n.get("Invalid field").replace("%format%" , I18n.get( "Phone format" ) );
                }
            }
        }

        return null;
    }

    const renderSelect = () => {
        if( ! props.pendingEdit ) {
            return null;
        }

        return (
            <Select classes={{selectMenu:cssSelect}}
                    labelId={`user-edited-role-label`}
                    id={`user-edited-role`}
                    required
                    value={(props.pendingEdit["Type"])?props.pendingEdit["Type"]:CUSTOMER}
                    onChange={handleSelectChange}
                    MenuProps={{
                        classes:{paper:cssSelect}
                    }}
                    label={I18n.get("Type")} >
                <MenuItem value={CUSTOMER}>
                    <FontAwesomeIcon icon={getIconForNode( {role: roles.CUSTOMER} )} style={{fontSize:"1.25em"}}/>
                    <span className="ml-2">{I18n.get("Customer")}</span>
                </MenuItem>
                <MenuItem value={RESELLER}>
                    <FontAwesomeIcon icon={getIconForNode( {role: roles.RESELLER} )} style={{fontSize:"1.25em"}}/>
                    <span className="ml-2">{I18n.get("Reseller")}</span>
                </MenuItem>
            </Select>
        );
    }

    const renderIdField = () => {
        const codeIfNotNew = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Code") && props.pendingEdit.Code !== '') ? props.pendingEdit.Code : "";
        const idIfNotNew = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Id") && props.pendingEdit.Id !== '') ? props.pendingEdit.Id : factoryUUID.generate();

        if( editing ) {
            if( props.pendingEdit["Type"] === RESELLER ) {
                editing.Id = idIfNotNew;
                props.pendingEdit.Id = idIfNotNew;
            }
        }

        if( props.pendingEdit["Type"] === RESELLER ) {
            return ( <TextField
                required
                variant="outlined"
                id={`user-edited-code`}

                label={ I18n.get("Id") }
                placeholder={ I18n.get("Not Specified") }
                value={ idIfNotNew }
                InputProps={{
                    readOnly: true,
                }}
            /> );
        }

        return (
            <TextField
                required
                error={!isValidName()}
                helperText={getHelperTextName()}
                variant="outlined"
                id={`user-edited-code`}

                label={ I18n.get("Code") }
                placeholder={ I18n.get("Not Specified") }
                value={ codeIfNotNew }
                InputProps={{
                    readOnly: false,
                }}
                onChange={(evt) => handleInputChange( evt , "Code" ) }
                onBlur={(evt) => handleInputBlur( evt ) }
            />
        );

    }

    let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
    let cssForm = "select-task-properties";
    let cssSelect = "combo-task-properties";
    let cssLabel = "label-task-properties";

    if( props.isDarkStyle ) {
        css += " dark";
        cssForm += " dark";
        cssSelect += " dark";
        cssLabel += " dark";
    }

    if( ! props.pendingEdit ) {
        return null;
    }

    if( ! editing ) {
        setEditing( props.pendingEdit );
    }

    const nameIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Name") ) ? props.pendingEdit.name : "";
    const emailIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Email") ) ? props.pendingEdit.Email : "";
    const phoneIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Phone") ) ? props.pendingEdit.Phone : "";
    const localeIfExists = (props.pendingEdit && props.pendingEdit.hasOwnProperty("Locale") ) ? props.pendingEdit.Locale : "en";

    return (
        <Dialog classes={{ paper: css }}
                aria-labelledby="simple-dialog-title"
                open={Boolean(props.pendingEdit)}>
            <div className="text-center p-3">
                <div className="user-data">
                    <div className="user-data-item role">
                        <FormControl variant="outlined" className={cssForm}>
                            <InputLabel className={cssLabel}
                                        id={`user-edited-role-input`}>
                                {I18n.get("Type")}
                            </InputLabel>
                            {renderSelect()}
                        </FormControl>
                    </div>
                    <div className="user-data-item code">
                        {renderIdField()}
                    </div>
                    <div className="user-data-item name">
                        <TextField
                            required
                            error={!isValidName()}
                            helperText={getHelperTextName()}
                            variant="outlined"
                            id={`user-edited-name`}

                            label={I18n.get("Name")}
                            placeholder={I18n.get("Not Specified")}
                            defaultValue={nameIfExists}
                            InputProps={{
                                readOnly: false,
                            }}
                            onChange={(evt) => handleInputChange( evt , "Name" ) }
                            onBlur={(evt) => handleInputBlur( evt ) }
                        />
                    </div>
                    <div className="user-data-item email">
                        <TextField
                            variant="outlined"
                            required
                            error={!isValidEmail()}
                            helperText={getHelperTextEmail()}
                            id={`user-edited-email`}
                            label={I18n.get("Email")}
                            placeholder={I18n.get("Not Specified")}
                            defaultValue={emailIfExists}
                            InputProps={{
                                readOnly: false,
                                required: true
                            }}
                            onChange={(evt) => handleInputChange( evt , "Email" ) }
                            onBlur={(evt) => handleInputBlur( evt ) }
                        />
                    </div>
                    <div className="user-data-item phone">
                        <TextField
                            variant="outlined"
                            error={!isValidPhoneNumber()}
                            helperText={getHelperTextPhone()}
                            id={`user-edited-phone`}
                            label={I18n.get("Phone")}
                            placeholder={I18n.get("Not Specified")}
                            defaultValue={phoneIfExists}
                            InputProps={{
                                readOnly: false,
                            }}
                            onChange={(evt) => handleInputChange( evt , "Phone" ) }
                            onBlur={(evt) => handleInputBlur( evt ) }
                        />
                    </div>
                    <div className="user-data-item phone">
                        <CountrySelector defaultValue={localeIfExists}
                                         onValueChanged={onCountryChanged}
                                         isCountryMode={true}
                                         label={I18n.get("Country")}
                                         showIcon={true}
                                         readOnly={false}
                                         isDarkStyle={props.isDarkStyle} />
                    </div>
                </div>
                <div className="pt-1">
                    <Button autoFocus onClick={handleCancel} className="btn-neutral-secondary btn-pill text-danger mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("CANCEL")}
						</span>
                    </Button>
                    <Button onClick={handleOk} className="btn-neutral-secondary btn-pill text-danger mx-1" disabled={isSubmitDisabled()}>
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
                    </Button>
                </div>
            </div>
        </Dialog>

    );
}
