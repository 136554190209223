
class MobileDetection {	
	constructor() {
		this.navigator = navigator;
		
		this.supportedOrientation = {
			portrait:"PORTRAIT",
			landscape:"LANDSCAPE",
			undefined:"UNDEFINED"
		}
		
		this.supportedType = {
			phone:"PHONE",
			tablet:"TABLET",
			desktop:"DESKTOP"
		}
		
		this.deviceBreakPoint = {
			phoneWidth:500,
			phoneHeight:900,
			tabletWidth:1024,
			tabletHeight:1400
		}
		
		this.detectDeviceProperties();
	}
	
	detectDeviceProperties() {
		this.device = {
			width: window.screen.width,
			height: window.screen.height,
			orientation: this.detectOrientation(),
			isMobile: this.isMobile(),
			type: this.detectType()
		};
	}
	
	isMobile() {
		if( this.navigator.userAgent.match(/Android/i)
		 || this.navigator.userAgent.match(/webOS/i)
		 || this.navigator.userAgent.match(/iPhone/i)
		 || this.navigator.userAgent.match(/iPad/i)
		 || this.navigator.userAgent.match(/iPod/i)
		 || this.navigator.userAgent.match(/BlackBerry/i)
		 || this.navigator.userAgent.match(/Windows Phone/i) ) {
			return true;
		 }
		 return false;
	}
	
	isIOS() {
		return this.navigator.userAgent.match(/iPhone/i)
			|| this.navigator.userAgent.match(/iPad/i)
			|| this.navigator.userAgent.match(/iPod/i)
			|| this.navigator.userAgent.match(/Macintosh/i);
	}
	
	detectOrientation() {
		if( window.screen.width > window.screen.height ) {
			return this.supportedOrientation.landscape;
		} else if( window.screen.width < window.screen.height ) {
			return this.supportedOrientation.portrait;
		}
		return this.supportedOrientation.undefined;
	}
	
	detectType() {
		let width;
		let height;
		if( this.detectOrientation() === this.supportedOrientation.landscape ) {
			width = window.screen.height;
			height = window.screen.width;
		} else if( this.detectOrientation() === this.supportedOrientation.portrait ) {
			width = window.screen.width;
			height = window.screen.height;
		} else {
			return this.supportedType.desktop;
		}
		
		if( ( width <  this.deviceBreakPoint.phoneWidth ) && 
			( height <  this.deviceBreakPoint.phoneHeight ) ) {
			return this.supportedType.phone;	
		} else if( ( width <  this.deviceBreakPoint.tabletWidth ) && 
				   ( height <  this.deviceBreakPoint.tabletHeight ) ) {
			return this.supportedType.tablet;
		} else {
			return this.supportedType.desktop;
		}
	}
	
	displayDeviceInformations() {
		console.log( "MobileDetection" , this );
	}
	
	getDevice() {
		return this.device;
	}
}

export default MobileDetection;