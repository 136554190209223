import {I18n} from "aws-amplify/utils";

export default class PayStationFinder {
    constructor( payStations ) {
        this.payStations = payStations;
    }

    cleanCBMS( deviceCBMS ) {
        if( deviceCBMS.includes( "/" ) ) {
            const parts = deviceCBMS.split( "_" );
            let cbms = "";
            parts.forEach( part => {
                if( parts.indexOf( part ) > 0 ) {
                    cbms += "_";
                }
                cbms += part.split("/")[0];
            } );
            return cbms;
        }
        return deviceCBMS;
    }

    getBoxForDevice( deviceCBMS ) {
        let found = null;
        this.payStations.forEach( payStation => {
            if( payStation.box.informations?.attributes?.devices?.includes( this.cleanCBMS( deviceCBMS ) ) ) {
                found = payStation.box;
            }
        } );
        return found;
    }

    getBoxForEptOperation( terminalCBMS ) {
        let found = null;
        this.payStations.forEach( payStation => {
            payStation.terminals.forEach( terminal => {
                if( terminal.name === terminalCBMS ) {
                    found = payStation.box;
                }
            } );
        } );
        return found;
    }

    extractCompanyShopLabel( fullLabel ) {
        const firstIndex = fullLabel.indexOf('|');
        if (firstIndex === -1) {
            return fullLabel;
        }

        const secondIndex = fullLabel.indexOf('|', firstIndex + 1);
        if (secondIndex === -1) {
            return fullLabel;
        }

        return fullLabel.substring(0, secondIndex);
    }

    getTerminalByCBMS( cbms , terminals ) {
        let found = null;
        if( terminals ) {
            terminals.forEach( terminal => {
                if( terminal.informations.bms === cbms.substring( 2 ) ) {
                    found = terminal;
                }
            } )
        }
        return found;
    }

    buildTerminalExtraData( terminal ) {
        let label = "";
        if( terminal && terminal.informations && terminal.informations.attributes ) {
            if( terminal.informations.attributes.company ) {
                label += this.formatText( terminal.informations.attributes.company );
            }

            if( terminal.informations.attributes.shop ) {
                if( label.trim() !== "" ) {
                    label += " | ";
                }
                label += this.formatText( terminal.informations.attributes.shop );
            }

            if( terminal.informations.attributes.terminal ) {
                if( label.trim() !== "" ) {
                    label += " | ";
                }
                label += this.formatText( terminal.informations.attributes.terminal );
            }
        }
        return label;
    }

    formatText( text ) {
        return decodeURI( text
            .replace(/%3%4/g , "")
            .replace(/\{/g , "")
            .replace(/\}/g , "")
            .replace(/\?/g , "") ).replace(/\\u[0-9a-fA-F]{4}/g,function( unicode ) {
            const charCode = parseInt( unicode.replace("\\u", "") , 16 );
            return String.fromCharCode( charCode );
        })
    }

    isManual( details ) {
        let isManual = false;
        if( details ) {
            details.forEach( detail => {
                if( detail.VCR ) {
                    isManual = detail.VCR;
                }
            } );
        }
        return isManual;
    }

    hasError( details ) {
        let hasError = false;
        if ( details ) {
            details.forEach( line => {
                if ( line && line.error ) {
                    hasError = true;
                }

                if ( line && ! line.type > 0 ) {
                    hasError = true;
                }
            } );
        }
        return hasError
    }

    extractErrorMessageList( details , defaultErrorMessage ) {
        let errorMessageList = [];
        if ( details ) {
            details.forEach( line => {
                if ( line && line.error ) {
                    errorMessageList.push( line.error );
                }

                if ( line && ! line.type > 0 ) {
                    errorMessageList.push( defaultErrorMessage );
                }
            } );
        }
        return errorMessageList;
    }

    extractBoxName ( box ) {
        if( box && box.informations && box.informations.attributes && box.informations.attributes.name ) {
            return box.informations.attributes.name;
        }

        if( box && box.informations && box.informations.identity &&
            box.informations.identity.model && box.informations.identity.serial ) {
            return `${box.informations.identity.model} ${box.informations.identity.serial}`;
        }

        return I18n.get( "Unknown" );
    };
}
