import React from 'react';
import PropTypes from 'prop-types';
//import html2canvas from 'html2canvas';
//import { jsPDF } from "jspdf";
import { I18n } from 'aws-amplify/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AmountFormatter from '../../../Utils/AmountFormatter';
//import FinancialDataHelper from '../../Utils/FinancialDataHelper';
import NoDataPlaceholder from '../../Components/NoDataPlaceholder/NoDataPlaceholder';
import PeriodChooser from '../../Components/PeriodChooser/PeriodChooser';
import SortableTable from '../../Components/SortableTable/SortableTable';
//import GraphObsContainer from '../../Components/GraphObsContainer';
//import NavigationSelector from '../../Components/NavigationSelector';
import SyncIndicator from '../../Components/SyncIndicator/SyncIndicator';
import { operationType } from "../../../Models/Report/Enums.js";
import './Comparison.css';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import {listOperations} from "../../../graphql/queries";
import PayStationFinder from "../../../Utils/PayStationFinder";
import {UserRoles} from "../../../Models/Roles";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};


//CA: chiffre affaire, PM : mode de paiement, VT: taxes
const supportedType = {
	CA:0,
	PM:1,
	VT:2
}

const supportedOrder = {
	ASC:"ascending",
	DESC:"descending",
}

const supportedGraph = {
	TABLE:"table",
	BAR:"bar"
}

let currencies = [];
let loadingTerminals = [];
let loadedTerminals = [];
let instanceComparison = null;
class Comparison extends React.Component {
	
	constructor( props ) {
		super( props );
		//this.financialHelper = new FinancialDataHelper(props.financialData);
		this.defaultInterval = 7;
		let day = 1000 * 60 * 60 * 24;
		this.state = {
			value:0,
			operations:[],
			period1:{
				start:this.getDailyStartingTimestamp( new Date().getTime() ),
				end:this.getDailyEndingTimestamp( new Date().getTime() )
			},
			period2:{
				start:this.getDailyStartingTimestamp( new Date( new Date().getTime() - ( this.defaultInterval * day ) ).getTime() ),
				end:this.getDailyEndingTimestamp( new Date( new Date().getTime() - ( this.defaultInterval * day ) ).getTime() )
			},
			isLoading:true,
		}
		//on first loading request asynchronous comparative data
		/*if( this.props.onLoadingRequired !== null && this.props.onLoadingRequired !== undefined ) {
			this.props.onLoadingRequired( "operation" , this.state.period2 );
		}*/
		instanceComparison = this;
		this.chartContainerRef = null;
		this.spaceGraphBefore = 0.80;
		this.spaceGraphAfter = 0.20;
		if( ! this.props.isDesktop ) {
			this.spaceGraphBefore -= 0.20;
			this.spaceGraphAfter += 0.20;
		}

		const analyzer = new ConfigAnalyzer( instanceComparison.props.payStations );
		const terminals = analyzer.getDistinctActiveTerminalList();
		loadingTerminals = terminals;
		loadedTerminals = [];
		terminals.forEach( terminal => {
			instanceComparison.loadOperations( terminal , `6_${terminal.informations.bms}` , {
				start: new Date( instanceComparison.state.period2.start ).toISOString(),
				end: new Date( instanceComparison.state.period1.end ).toISOString()
			} , null );
		} );
	}

	/*updateOperations( data ) {
		this.financialHelper = new FinancialDataHelper(data);
		this.setState( {
			value:this.state.value,
			period1:this.state.period1,
			period2:this.state.period2,
			operations:data,
			isLoading:this.state.isLoading
		});
	}*/
	
	onLoadingStateUpdated( isLoading ) {
		this.setState({
			isLoading:isLoading,
			value:this.state.value,
			period1:this.state.period1,
			period2:this.state.period2,
			operations:this.state.operations,
		});
	}
	
	generateTimelineLabels( data ) {
		let labels = [];
		data.map( (line) => {
			labels.push( line.hour );
			return true;
		} );
				
		return labels;
	}
	
	generateColorsForLabel( labels ) {
		let colors = [];
		labels.map( (label) => {
			if( this.props.isDarkStyle ) {
				colors.push("#FFFFFF");
			} else {
				colors.push("#000000");
			}
			return true;
		} );
		return colors;
	}
	
	generateSeries( data ) {
		const series = [];
		
		let period1 = this.extractSeries( data , 1 );
		let period2 = this.extractSeries( data , 2 );

		/*if( this.state.order === supportedOrder.DESC ) {
			period1.reverse();
			period2.reverse();
		}*/

		series.push({ name: I18n.get("Period")+" 1", data:period2 });
		series.push({ name: I18n.get("Period")+" 2", data:period1 });
		
		return series;
	}
	
	getDailyStartingTimestamp( timestamp ) {
		let date = new Date( timestamp );
		date.setHours(0, 0, 0);
		return date.getTime();
	}
	
	getDailyEndingTimestamp( timestamp ) {
		let date = new Date( timestamp );
		date.setHours(23, 59, 59);
		return date.getTime();
	}
	
	getLabelTooltipForPeriod( period ) {
		let tooltipTextTop = I18n.get("Period") + " " + I18n.get("From").toLowerCase() + " ";
		//tooltipText += new Date( period.start ).toLocaleDateString() + " " + new Date( period.start ).toLocaleTimeString().substring(0,5);
		tooltipTextTop += new Date( period.start ).toLocaleDateString() + " " + new Date( period.start ).toLocaleTimeString().substring(0,5);
		
		let tooltipTextBottom =  " " + I18n.get("To").toLowerCase() + " ";
		tooltipTextBottom += new Date( period.end ).toLocaleDateString() + " " + new Date( period.end ).toLocaleTimeString().substring(0,5);
		
		//tooltipText +=  " " + I18n.get("To").toLowerCase() + " ";
		//tooltipText += new Date( period.end ).toLocaleDateString() + " " + new Date( period.end ).toLocaleTimeString().substring(0,5);
		//return tooltipText;
		
		return (
			<div className="label-period">
				<div className="top">{tooltipTextTop}</div>
				<div className="bottom">{tooltipTextBottom}</div>
			</div>
		);
	}
	
	getCssForAmountOperation( targetedAmount , comparingAmount ) {
		if( targetedAmount > comparingAmount ) {
			return "cell amount highlight";
		}
		return "cell amount";
	}
	
	getCssForGap( gapAmount ) {
		if( gapAmount > 0 ) {
			return "cell positive";
		} else if ( gapAmount < 0 ) {
			return "cell negative";
		} else {
			return "cell equals";
		}
	}
	
	getExportFilename( format ) {
		return I18n.get("Comparison") + 
				"_" + 
				new Date().toLocaleDateString() + 
				"_" + new Date().toLocaleTimeString() + 
				"." + 
				format.toLowerCase();
	}
	
	getChartOptions( data ) {
		//let currency = dataSample.currentWeek.currency;
		const formatter = new AmountFormatter(this.props.locale,  data[0].currency );
		const maxValue = this.extractHighestSeriesValue( data ) + 20;
		const categories = this.generateTimelineLabels( data );
		const options = {
			dataLabels: {
				enabled: true,
				formatter: function (value) {
						if( instanceComparison.state.value === supportedType.VT ) {
							return value;
						}
						return formatter.format( value ).replace(/\s/g , ""); //label money
						//return value;
					},
				dropShadow: {
					enabled: true,
					left: 1,
					top: 1,
					opacity: 0.4
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderRadius: 2,
					borderWidth: 1,
					borderColor: '#fff',
					opacity: 0.9,
					dropShadow: {
						enabled: false,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45
					},
					style: {
						colors: ['#008ffb  ' , '#e39600']
					},
				},
			},
			stroke: {
				show: false,
				width: 2,
				/*colors: ['transparent']*/
				colors: ['#008ffb  ' , '#e39600']
			},
			colors: ['#008ffb  ' , '#e39600' , '#f4772e' ],
			legend: {
				show: true
			},
			xaxis: {
				min:this.spaceGraphBefore,
				max:(categories.length + this.spaceGraphAfter),
				crosshairs: {
					width: 1
				},
				categories: categories,
				labels: {
					style: {
						colors: this.generateColorsForLabel(categories)
					},
					formatter: function (value) {
						return value;
					}
				},
			},
			yaxis: {
				min: 0,
				max: maxValue,
				labels: {
					style: {
						colors: this.generateColorsForLabel(categories)
					},
					formatter: function (value) {
						if( instanceComparison.state.value === supportedType.VT ) {
							return value;
						}
						return formatter.format( value ).replace(/\s/g , ""); 
					}
				},
			},
        };
		return options;
	}
	
	getIconForGraphSelection() {
		switch( instanceComparison.state.graphType ) {
			case supportedGraph.TABLE:return ["fas","chart-bar"];
			case supportedGraph.BAR:return ["fas","table"];
			default: return ["fas","chart-bar"];
		}
	}
	
	getIconForOrder() {
		switch( instanceComparison.state.order ) {
			case supportedOrder.DESC: //fall through;
			case supportedOrder.ASC://fall through
			default:	return ["fas","sort-up"];
		}
	}
	
	getCssForOrder() {
		switch(instanceComparison.state.order) {
			case supportedOrder.DESC: return "display-4 reversed";
			case supportedOrder.ASC://fall through
			default:	return "display-4";
		}
	}

	extractSeries(data , period ) {
		const series = [];
		data.map( (line) => {
			switch( period ) {
				case 1: series.push( line.operationAmountPeriod1 );
				break;
				case 2: series.push( line.operationAmountPeriod2 );
				break;
				default:
				break;
			}
			return true;
		});
		return series;
	}
	
	extractHighestSeriesValue( data ) {
		let max = 0;
		data.map( (line) => {
			max = Math.max( max , line.operationPeriod1 );
			max = Math.max( max , line.operationPeriod2 );
			return true;
		} );
		return max;
	}

	filterByTypeForPeriod( operationTypeValue , period ) {
		const filtered = [];
		if( this.state.operations ) {
			this.state.operations.forEach( operation => {
				if( operation && operation.at ) {
					if( period.end >= new Date( operation.at ).getTime() &&
						new Date( operation.at ).getTime() >= period.start &&
						operation.type === operationTypeValue &&
						operation.currency === this.props.currencySelected ) {
						filtered.push( operation );
					}
				}
			} );
		}
		return filtered;
	}

	extractCurrencies() {
		const currencies = [];
		if( this.state.operations ) {
			this.state.operations.forEach( operation => {
				if( ! currencies.includes( operation.currency ) ) {
					currencies.push( operation.currency );
				}
			} );
		}
		return currencies;
	}

	extractDataForPeriods() {
		let returnedRow = [];
		currencies = this.extractCurrencies();
		const allCurrencies = this.extractCurrencies();;

		const dataPeriod1 = this.filterByTypeForPeriod( 1 , instanceComparison.state.period1 );
		const dataPeriod2 = this.filterByTypeForPeriod( 1 , instanceComparison.state.period2 );

		let minTimestamp = this.state.period1.end;
		let maxTimestamp = this.state.period2.start;
		let startingHour = 24//new Date(minTimestamp).getHours();
		let endingHour = 0;//new Date(maxTimestamp).getHours();

		const identifyTimeRanges = operation => {
			if( operation && operation.at ) {
				if( ! currencies.includes( operation.currency ) ) {
					currencies.push( operation.currency );
				}

				const operationDate = new Date( operation.at ).getTime();
				minTimestamp = Math.min( minTimestamp , operationDate );
				maxTimestamp = Math.max( maxTimestamp , operationDate );
				startingHour = Math.min( startingHour , new Date( operationDate ).getHours() );
				endingHour = Math.max( endingHour , new Date( operationDate ).getHours() );
			}
		};

		dataPeriod1.forEach( operation => {
			identifyTimeRanges( operation );
		} );
		dataPeriod2.forEach( operation => {
			identifyTimeRanges( operation );
		} );
		
		if( currencies.length < 1 ) {
			currencies.push("EUR");
		}

		for( let i=startingHour ; i < (endingHour + 1) ; i++ ) {
			const operationAmountPeriod1 = this.calculateAmount( this.extractAtHour( dataPeriod1 , i ) );
			const operationAmountPeriod2 = this.calculateAmount( this.extractAtHour( dataPeriod2 , i ) );
			const operationAveragePeriod1 = this.calculateAmountPerSaleNumber( this.extractAtHour( dataPeriod1 , i ) );
			const operationAveragePeriod2 = this.calculateAmountPerSaleNumber( this.extractAtHour( dataPeriod2 , i ) );
			const operationSaleNbPeriod1 = this.calculateSaleNumber( this.extractAtHour( dataPeriod1 , i ) );
			const operationSaleNbPeriod2 = this.calculateSaleNumber( this.extractAtHour( dataPeriod2 , i ) );

			let period1;
			let period2;
			let gap;
			
			switch( instanceComparison.state.value ) {
				case 0: 
					period1 = operationAmountPeriod1;
					period2 = operationAmountPeriod2;
					gap = operationAmountPeriod1 - operationAmountPeriod2;
				break;
				case 1: 
					period1 = operationAveragePeriod1;
					period2 = operationAveragePeriod2;
					gap = operationAveragePeriod1 - operationAveragePeriod2;
				break;
				case 2: 
					period1 = operationSaleNbPeriod1;
					period2 = operationSaleNbPeriod2;
					gap = operationSaleNbPeriod1 - operationSaleNbPeriod2;
				break;
				default: 
					period1 = 0;
					period2 = 0;
					gap = 0;
				break;
			}

			returnedRow.push({
				hour:i + " H",
				operationAmountPeriod1:operationAmountPeriod1,
				operationAmountPeriod2:operationAmountPeriod2,
				operationAveragePeriod1:operationAveragePeriod1,
				operationAveragePeriod2:operationAveragePeriod2,
				operationSaleNbPeriod1:operationSaleNbPeriod1,
				operationSaleNbPeriod2:operationSaleNbPeriod2,
				gapAmountPeriod:(operationAmountPeriod1-operationAmountPeriod2),
				gapAveragePeriod:(operationAveragePeriod1-operationAveragePeriod2),
				gapSaleNbPeriod:(operationSaleNbPeriod1-operationSaleNbPeriod2),
				currency:this.props.currencySelected,
				period1:period1,
				period2:period2,
				gap:gap
			});
		}

		if( ( dataPeriod1.length > 0 || dataPeriod2.length ) && this.props.onCurrenciesUpdate ) {
			this.props.onCurrenciesUpdate( allCurrencies );
		}

		return returnedRow;
	} 
	
	extractAtHour( dataPeriod , hour ) {
		let returned = [];
		const added = [];
		dataPeriod.forEach( operation => {
			const date = new Date( new Date( operation.at ).getTime() );
			if( date.getHours() === hour && ! added.includes( operation.uuid ) ) {
				returned.push( operation );
				added.push( operation.uuid );
			}
		} );
		return returned;
	}

	calculateAmount( operations ) {
		let total = 0;
		operations.forEach( operation => {
			if( operation && operation.data && operation.data.details ) {
				total += this.calculateTotalForOperation( operation.data.details );
			}
			return true;
		} )
		return total;
	}

	calculateTotalForOperation( details ) {
		let total = 0;
		if( details ) {
			details.forEach( detail => {
				total += detail.qty * detail.value * detail.rate ;
			} );
		}
		return total;
	}
	
	calculateSaleNumber( operations ) {
		let total = 0;
		operations.forEach( operation => {
			if( operation && this.calculateTotalForOperation( operation.data.details ) > 0 ) {
				total += 1;
			}
		} )
		return total;
	}
	
	calculateAmountPerSaleNumber( operations ) {
		let amount = this.calculateAmount( operations );
		let nbSale = this.calculateSaleNumber( operations );
		if( nbSale > 0 ) {
			return amount/nbSale;
		}
		return 0;
	}
	
	setValue( newValue ) {
		instanceComparison.setState({
			value:newValue
		});
	}
	
	handleChange(event, newValue) {
        instanceComparison.setValue(newValue);
    };
	
	handleOrderSelection ( order ) {
		switch( order ) {
			case supportedOrder.ASC:
				instanceComparison.setState(
					{
						type:instanceComparison.state.type,
						order:supportedOrder.DESC,
						period1:instanceComparison.state.period1,
						period2:instanceComparison.state.period2,
						graphType:instanceComparison.state.graphType,
					} );
			break;
			case supportedOrder.DESC://do nothing
				instanceComparison.setState(
					{
						type:instanceComparison.state.type,
						order:supportedOrder.ASC,
						period1:instanceComparison.state.period1,
						period2:instanceComparison.state.period2,
						graphType:instanceComparison.state.graphType,
					} );
			break;
			default://do nothing
			break;
		}
	}
	
	handleGraphSelection( graphType ) {
		switch( instanceComparison.state.graphType ) {
			case supportedGraph.TABLE:
				instanceComparison.setState(
					{
						type:instanceComparison.state.type,
						order:instanceComparison.state.order,
						period1:instanceComparison.state.period1,
						period2:instanceComparison.state.period2,
						graphType:supportedGraph.BAR,
					} );
			break;
			case supportedGraph.BAR:
				instanceComparison.setState(
					{
						type:instanceComparison.state.type,
						order:instanceComparison.state.order,
						period1:instanceComparison.state.period1,
						period2:instanceComparison.state.period2,
						graphType:supportedGraph.TABLE,
					} );
			break;
			default: //not implemented
			break;
		}
		
	}

	isCancellation( operation ) {
		try {
			if( operation.data.details[0].error && operation.data.details[0].error.includes(I18n.get( "Cancelled operation" ) ) ) {
				return true;
			}
		} catch( error ) {

		}

		return false;
	}

	extractOperations( payStations ) {
		return new Promise( resolve => {
			const anonymized = {};
			const analyzer = new ConfigAnalyzer( payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			const operations = [];
			const done = [];
			terminals.forEach( terminal => {
				if( terminal.operations.length > 0 ) {
					terminal.operations.forEach( operation => {
						if( ! this.isCancellation( operation ) ) {
							if( instanceComparison.props.currentUser.role === UserRoles.SALE_ROLE ) {
								if( ! anonymized[operation.cashier] ) {
									anonymized[operation.cashier] = `Caissier ${Object.keys( anonymized ).length + 1}`;
								}
								operation.cashier = anonymized[operation.cashier];
							}
							operations.push( operation );
						}

						if( terminal.operations.indexOf( operation ) === terminal.operations.length - 1 ) {
							done.push( terminal );
							if( done.length === terminals.length ) {
								resolve( operations );
							}
						}
					} );
				} else {
					done.push( terminal );
					if( done.length === terminals.length ) {
						resolve( operations );
					}
				}
			} );
		} );

	}

	onNewIncomingOperation() {
		instanceComparison.extractOperations( instanceComparison.props.payStations ).then( operations => {
			this.setState( {operations: operations} );
		} );
	}
	
	loadOperations( terminal , cbms , period , nextToken ) {
		if( instanceComparison.props.API ) {
			instanceComparison.props.API
				.graphql({ query: listOperations, variables: { input: { cbms: cbms , count: 500 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listOperations ) {
						returned.data.listOperations.operations.forEach( operation => {
							terminal.operations.push( operation );
						} );
						if( returned.data.listOperations.nextToken ) {
							instanceComparison.loadOperations( terminal , cbms , period , returned.data.listOperations.nextToken );
						} else {
							loadedTerminals.push( terminal );
							if( loadedTerminals.length === loadingTerminals.length ) {
								loadedTerminals = [];
								instanceComparison.extractOperations( instanceComparison.props.payStations )
									.then( operations => {
										instanceComparison.setState({
											isLoading: false,
											order:instanceComparison.order,
											value:instanceComparison.state.value,
											period1:instanceComparison.state.period1,
											period2:instanceComparison.state.period2,
											graphType:instanceComparison.state.graphType,
											operations: operations
										});
									} );
							}
						}
					}
				})
				.catch((error) => {

				})
		}
	}

	handlePeriodSelection( period , identifier ) {
		if( instanceComparison.props.payStations ) {
			const analyzer = new ConfigAnalyzer( instanceComparison.props.payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			loadingTerminals = terminals;
			loadedTerminals = [];
			terminals.forEach( terminal => {
				terminal.operations = [];
				instanceComparison.extractOperations( instanceComparison.props.payStations )
					.then( operations => {
						instanceComparison.setState(
							{
								type:instanceComparison.state.type,
								order:instanceComparison.order,
								period1: ( identifier === "period1" ) ? period : instanceComparison.state.period1,
								period2:( identifier === "period2" ) ? period : instanceComparison.state.period2,
								graphType:instanceComparison.state.graphType,
								operations: operations
							} , () => {
								instanceComparison.loadOperations( terminal , `6_${terminal.informations.bms}` , {
									start: new Date( instanceComparison.state.period2.start ).toISOString(),
									end: new Date( instanceComparison.state.period1.end ).toISOString()
								} , null );
							} );
					} );
			} );
		}
	}
	
	handleHighlightLine( id ) {
		const selectionCss = "selected";
		let domRows = document.getElementsByClassName("selectable-row");
		if( domRows !== null ) {
			for(var i=0 ; i <domRows.length ; i++) {
				let domElement = domRows[i];
				domElement.classList.remove( selectionCss );
				
				if( domElement.id === id ) {
					domElement.classList.add( selectionCss );
				}
			}
		}
	}
	
	handlePageChanged( page ) {
		if( instanceComparison.chartContainerRef !== null && instanceComparison.chartContainerRef !== undefined ) {
			instanceComparison.chartContainerRef.setPage( page );
		}
	}
	
	handleRowsPerPageChanged( rowsPerPage ) {
		if( instanceComparison.chartContainerRef !== null && instanceComparison.chartContainerRef !== undefined ) {
			instanceComparison.chartContainerRef.setRowsPerPage( rowsPerPage );
		}
	}
	
	handleSorting( sortedData ) {
		if( instanceComparison.chartContainerRef !== null && instanceComparison.chartContainerRef !== undefined ) {
			instanceComparison.chartContainerRef.setData( instanceComparison.generateSeries( sortedData ) , 
															instanceComparison.generateTimelineLabels( sortedData ) ,
															sortedData );
		}
	}
	
	getTitleType( value ) {
		switch(value) {
			case supportedType.TERMINAL: 	return I18n.get("Terminal");
			case supportedType.CASHIER:		return I18n.get("Cashier");
			default: return "";
		}
	}
	
	renderIconHour() {
		return (
			<FontAwesomeIcon icon={["far","clock"]} className="display-4" />
		);
	}
	
	renderComparatorIcon( gap ) {
		if( gap > 0 ) {
			return (
				<FontAwesomeIcon icon={["fas","arrow-up"]} className="display-4 positive" />
			);
		} else if ( gap < 0 ) {
			return (
				<FontAwesomeIcon icon={["fas","arrow-down"]} className="display-4 negative" />
			);
		}
		return (
			<FontAwesomeIcon icon={["fas","equals"]} className="display-4 equals" />
		);
	}
	
	getHeaderProperties( data , type ) {
		if( data.length < 1 ) {
			return [];
		} 

		const labelPeriod1 = I18n.get("Period") + " 2";
		const labelPeriod2 = I18n.get("Period") + " 1";
		const labelGap = I18n.get("Gap");

		switch( type ) {
			case supportedType.CA:
				return [
				  { id: "hour", numeric: false, disablePadding: false, label:this.renderIconHour() , money: false, className:"header type clock", width: 80,  align: 'center' },
				  { id: 'operationAmountPeriod2', numeric: true, disablePadding: false, label: labelPeriod2, money: true, className:"header period2",  width: 150, align: 'center' },
				  { id: 'operationAmountPeriod1', numeric: true, disablePadding: false, label: labelPeriod1, money: true, className:"header period1",  width: 150, align: 'center' },
				  { id: 'gapAmountPeriod', numeric: true, disablePadding: false, label: labelGap, money: true, className:"header gap",  width: 150, align: 'center' },
				];
			case supportedType.PM:
				return [
				  { id: "hour", numeric: false, disablePadding: false, label: this.renderIconHour(), money: false, className:"header type clock", width: 80,  align: 'center' },
				  { id: 'operationAveragePeriod2', numeric: true, disablePadding: false, label: labelPeriod2, money: true, className:"header period2",  width: 150, align: 'center' },
				  { id: 'operationAveragePeriod1', numeric: true, disablePadding: false, label: labelPeriod1, money: true, className:"header period1",  width: 150, align: 'center' },
				  { id: 'gapAveragePeriod', numeric: true, disablePadding: false, label: labelGap, money: true, className:"header gap",  width: 150, align: 'center' },
				];
			case supportedType.VT:
				return [
				  { id: "hour", numeric: false, disablePadding: false, label: this.renderIconHour(), money: false, className:"header type clock", width: 80,  align: 'center' },
				  { id: 'operationSaleNbPeriod2', numeric: true, disablePadding: false, label: labelPeriod2, money: false, className:"header period2",  width: 150, align: 'center' },
				  { id: 'operationSaleNbPeriod1', numeric: true, disablePadding: false, label: labelPeriod1, money: false, className:"header period1",  width: 150, align: 'center' },
				  { id: 'gapSaleNbPeriod', numeric: true, disablePadding: false, label: labelGap, money: false, className:"header gap",  width: 150, align: 'center' },
				];
			default: return [];
		}
	}
	
	getAverageForPeriod( period , data ) {
		let average = 0;
		let total = 0;
		let nbTransaction = 0;
		
		data.map(( line ) => {
			if( line !== null && line !== undefined ) {
				total += line[`operationAmountPeriod${period}`];
				nbTransaction += line[`operationSaleNbPeriod${period}`];
			}
			return true;
		});
		
		if( nbTransaction > 0 ) {
			average = total / nbTransaction;
		}
		
		return average
	}
	
	renderTab( label ) {
		const key = "comparison-tab-nav-item-" + label;
		return ( 
			<Tab 	key={key} 
					label={I18n.get( label )}/> 
		);
	}
	
	renderTabPanel( label , counterIndex , data , type ) {
		let shown = false;
		let cssTable = "comparison-table";

		if( instanceComparison.state.isLoading ) {
			cssTable += " hidden";
		} else {
			if( data.length < 1 ) {
				shown = true;
				cssTable += " hidden";
			}
		}

		const key = "comparison-tab-panel-item-" + label;
		//OVERRIDE data for PDF
		if( counterIndex === instanceComparison.state.value ) {
			delete instanceComparison.props.pdfDataDelegate.map;
			delete instanceComparison.props.pdfDataDelegate.lines;
			const properties = this.getHeaderProperties( data , type );
			if( properties.length > 0 ) {
				properties[0].label = ""; //override icon hour
			}
			instanceComparison.props.pdfDataDelegate.columnDefinition = properties;
			instanceComparison.props.pdfDataDelegate.lines = data;
			instanceComparison.props.pdfDataDelegate.subTitle = I18n.get( label );
			instanceComparison.props.pdfDataDelegate.startLocalizedDate = new Date(instanceComparison.state.period1.start).toLocaleString().replace("," , "").substring( 0 , new Date(instanceComparison.state.period1.start).toLocaleString().replace("," , "").length - 3 );
			instanceComparison.props.pdfDataDelegate.endLocalizedDate = new Date(instanceComparison.state.period1.end).toLocaleString().replace("," , "").substring( 0 , new Date(instanceComparison.state.period1.end).toLocaleString().replace("," , "").length - 3 );
			const handlerExtraData = {
				locale: instanceComparison.props.locale.substring( 0 , instanceComparison.props.locale.indexOf("-") ),
				offset:0,
				comparativeStart: new Date(instanceComparison.state.period2.start).toLocaleString().replace("," , "").substring( 0 , new Date(instanceComparison.state.period2.start).toLocaleString().replace("," , "").length - 3 ),//instanceComparison.state.period2.start,//new Date(instanceComparison.state.period2.start).toLocaleString().replace("," , "") ,
				comparativeEnd: new Date(instanceComparison.state.period2.end).toLocaleString().replace("," , "").substring( 0 , new Date(instanceComparison.state.period2.end).toLocaleString().replace("," , "").length - 3 )//instanceComparison.state.period2.end//new Date(instanceComparison.state.period2.end).toLocaleString().replace("," , "") 
			}
			instanceComparison.props.pdfDataDelegate.data = {
				boxExtraData: I18n.get( "From" ) + ' ' + instanceComparison.props.pdfDataDelegate.startLocalizedDate + ' ' +
					I18n.get( "To" ).toLowerCase() + ' ' + instanceComparison.props.pdfDataDelegate.endLocalizedDate + ' ' +
					I18n.get( "And" ).toLowerCase() + ' ' +
					I18n.get( "From" ).toLowerCase() + ' ' + handlerExtraData.comparativeStart + ' ' +
					I18n.get( "To" ).toLowerCase() + ' ' + handlerExtraData.comparativeEnd
			}
			instanceComparison.props.pdfDataDelegate.handler = JSON.stringify( handlerExtraData );
		}

		return ( 
			<TabPanel key={key} value={instanceComparison.state.value} index={counterIndex}>
				<div className="tab-panel-content">
					<Grid container spacing={1} >
						<Grid id="exportable-table" item xs={12} md={12} lg={12}>
							<Paper className="card-box">		
								<SortableTable header={this.getHeaderProperties( data , type )} 
											   rows={data} 
											   className={cssTable} 
											   locale={this.props.locale}
											   isDarkStyle={this.props.isDarkStyle}
											   isDesktop={this.props.isDesktop}
											   currency={this.props.currencySelected}
											   onPageChanged={this.handlePageChanged}
											   onRowsPerPageChanged={this.handleRowsPerPageChanged}
											   onSortingData={this.handleSorting}
											   orderBy="hour"
											   defaultRowsPerPage={25}/>
								<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
								<SyncIndicator loading={this.state.isLoading} className={`big spaced spining`}/>
							</Paper>
						</Grid>
					</Grid>
				</div>
			</TabPanel>
		);
		
	}
	
	renderReminderPanel( label , counterIndex , data , type ) {
		const key = "backoffice-tab-reminder-item-" + counterIndex;
		let totalPeriod1 = 0;
		let totalPeriod2 = 0;
		let currency = this.props.currencySelected;

		data.forEach( line  => {
			if( line ) {
				switch( type ) {
					case supportedType.CA:
						totalPeriod1 += line.operationAmountPeriod1;
						totalPeriod2 += line.operationAmountPeriod2;
						break;
					case supportedType.PM:
						totalPeriod1 += line.operationAveragePeriod1;
						totalPeriod2 += line.operationAveragePeriod2;
						break;
					case supportedType.VT:
						totalPeriod1 += line.operationSaleNbPeriod1;
						totalPeriod2 += line.operationSaleNbPeriod2;
						break;
					default:
				}
				currency = line.currency;
			}
			return true;
		});
		
		if( type === supportedType.PM ) {
			totalPeriod1 = this.getAverageForPeriod( 1 , data );
			totalPeriod2 = this.getAverageForPeriod( 2 , data );
		}
		
		let gap = totalPeriod1 - totalPeriod2;
		const formatter = new AmountFormatter(this.props.locale,  currency );
		if( type === supportedType.VT ) {
			return ( 
				<Grid key={key} item xs={12} md={12} lg={4}>
					<div className={`tab-panel-reminder item-${counterIndex} ${(counterIndex === instanceComparison.state.value) ? 'active' : ''}`}
						 onClick={event => {
							 instanceComparison.handleChange( event , counterIndex );
						 }}>
						<Paper className="card-box reminder comparison-reminder">
							<div className="type-reminder">
								{I18n.get("Total")} {I18n.get(label).toLowerCase()}
							</div>
							<div className="total-reminder-content">							
								<div className="total-period-content">
									<div className="period2">
										{totalPeriod2}
									</div>	
									<div className="period1">
										{totalPeriod1}
									</div>
								</div>	
								<div className="total-comparator">
									<div>{gap} </div>
									<div>{instanceComparison.renderComparatorIcon(gap)}</div>
								</div>		
							</div>									
						</Paper>
					</div>
				</Grid>
			);
		}
		return ( 
			<Grid key={key} item xs={12} md={12} lg={4}>
				<div className={`tab-panel-reminder item-${counterIndex} ${(counterIndex === instanceComparison.state.value) ? 'active' : ''}`}
					 onClick={event => {
						 instanceComparison.handleChange( event , counterIndex );
					 }}>
					<Paper className="card-box reminder comparison-reminder">
						<div className="type-reminder">
							{I18n.get("Total")} {I18n.get(label).toLowerCase()}
						</div>
						<div className="total-reminder-content">
							<div className="total-period-content">
								<div className="period2">
									{formatter.format(totalPeriod2)}
								</div>	
								<div className="period1">
									{formatter.format(totalPeriod1)}
								</div>	
							</div>	
							<div className="total-comparator">
								<div>{formatter.format(gap)} </div>
								<div>{instanceComparison.renderComparatorIcon(gap)}</div>
							</div>	
						</div>									
					</Paper>
				</div>
			</Grid>
		);
		
	}
	
	render() {
		
		const tabs = [];
		const panels = [];
		const reminders = [];
		let counterIndex = 0;
		let data = this.extractDataForPeriods();
		let currency = ''
		
		tabs.push( instanceComparison.renderTab( "Cash in" ) );
		panels.push( instanceComparison.renderTabPanel( "Cash in" , counterIndex , data , supportedType.CA ) );
		reminders.push( instanceComparison.renderReminderPanel( "Cash in" , counterIndex , data , supportedType.CA ) );
		counterIndex++;
		tabs.push( instanceComparison.renderTab( "Average Cash in" ) );
		panels.push( instanceComparison.renderTabPanel( "Average Cash in" , counterIndex , data , supportedType.PM ) );
		reminders.push( instanceComparison.renderReminderPanel( "Average Cash in" , counterIndex , data , supportedType.PM ) );
		counterIndex++;
		tabs.push( instanceComparison.renderTab( "Cash in Number" ) );
		panels.push( instanceComparison.renderTabPanel( "Cash in Number" , counterIndex , data , supportedType.VT ) );
		reminders.push( instanceComparison.renderReminderPanel( "Cash in Number" , counterIndex , data , supportedType.VT  ) );
		counterIndex++;

		if( this.props.isDesktop ) {
			return (
				<React.Fragment>
					<div className="z-over fullscreen mb-2 read-only-breadscrum">
						{/*<NavigationSelector selectedNode={this.props.selectedNode}
											isDarkStyle={this.props.isDarkStyle}
											onSelectionChanged={this.props.onNodeChanged}
											readOnly={true}
											user={this.props.user} />*/}
					</div>	
					<div id="exportable" className="z-over fullscreen pb-0 pt-0">
						<div className="periods">
							<div className="period2">
								<SyncIndicator loading={this.state.isLoading}
								               className={"display-4 spining space-top"}/>
								<PeriodChooser period={this.state.period2}
											   onPeriodChanged={this.handlePeriodSelection} 
											   callbackIdentifier="period2" 
											   isDarkStyle={this.props.isDarkStyle} />
							</div>
							<div className="period1">
								<PeriodChooser period={this.state.period1}
											   onPeriodChanged={this.handlePeriodSelection} 
											   callbackIdentifier="period1" 
											   isDarkStyle={this.props.isDarkStyle} />
							</div>
						</div>
						<Tabs 	className="nav-tabs-primary hidden"
								key="backoffice-tab-nav"
								value={instanceComparison.state.value}
								variant="fullWidth"
								onChange={instanceComparison.handleChange}>
							{tabs.map( item => item )}	
						</Tabs>
						<Grid id="exportable-reminder" container spacing={1}>
							{reminders.map( item => item )}
						</Grid>
						{panels.map( item => item )}	
					</div>
				</React.Fragment>
			);
		}
		
		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.selectedNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>	
				<div id="exportable" className="z-over fullscreen pb-0 pt-0">
					<div className="periods">
						<SyncIndicator loading={this.state.isLoading}
						               className={"display-4 spining space-top"}/>
						<div className="period1">
							<PeriodChooser period={this.state.period1}
										   onPeriodChanged={this.handlePeriodSelection} 
										   callbackIdentifier="period1" 
										   isDarkStyle={this.props.isDarkStyle} />
						</div>
						<div className="period2">
							<PeriodChooser period={this.state.period2}
										   onPeriodChanged={this.handlePeriodSelection} 
										   callbackIdentifier="period2" 
										   isDarkStyle={this.props.isDarkStyle} />
						</div>
					</div>
					<Tabs 	className="nav-tabs-primary hidden"
							key="backoffice-tab-nav"
							value={instanceComparison.state.value}
							variant="scrollable"
							scrollButtons="auto"
							onChange={instanceComparison.handleChange}>
						{tabs.map( item => item )}	
					</Tabs>
					<Grid id="exportable-reminder" container spacing={1}>
						{reminders.map( item => item )}
					</Grid>
					{panels.map( item => item )}	
				</div>
			</React.Fragment>
		);
	}
	/*render() {
		let data = this.extractDataForPeriods();
		var shown = false;
		if( data.length < 1 ) {
			shown = true;
		}
		var cssPaper = "card-box mode-" + this.state.graphType;
		var height = 77;
		height += ( data.length + 1 ) * 33;
		return (
			<React.Fragment>
				<Grid container spacing={1} >
					<Grid item xs={12} md={5} lg={5}>
						<Paper className={cssPaper}>		
							<TypeSelector onSelection={this.handleTypeSelection} caller={this} isDesktop={this.props.isDesktop}/>
							<Table size="small" className="comparison-table">
							{this.renderHeader()}
							{this.renderBody( data )}
							{this.renderFooter( data )}
							</Table>
							<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
						</Paper>
					</Grid>
				

					<Grid item xs={12} md={7} lg={7}>
						<Paper className={cssPaper}>	
							<div className="cm-graph-xl comparison">
								<Chart 	options={this.getChartOptions( data )} 
										series={this.generateSeries( data )} 
										type="bar" 
										height={height} 
										className="cm-chart" 
										ref={(component) => instanceComparison.chartRef = component}
										id="chart-comparison"/>
							</div>
						</Paper>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}*/
}

export default Comparison;
