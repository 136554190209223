import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'aws-amplify/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AmountFormatter from '../../../Utils/AmountFormatter';
//import BoxExtraDataFormatter from '../../Utils/BoxExtraDataFormatter';
//import FinancialDataHelper from '../../Utils/FinancialDataHelper';
import NoDataPlaceholder from '../../Components/NoDataPlaceholder/NoDataPlaceholder';
import PeriodChooser from '../../Components/PeriodChooser/PeriodChooser';
import SortableTable from '../../Components/SortableTable/SortableTable';
//import GraphObsContainer from '../Components/GraphObsContainer';
//import NavigationSelector from '../Components/NavigationSelector';
import SyncIndicator from '../../Components/SortableTable/SortableTable';
import { operationType } from "../../../Models/Report/Enums.js";
import '../Comparison/Comparison.css';
import './Distribution.css';
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import PayStationFinder from "../../../Utils/PayStationFinder";
import {listOperations} from "../../../graphql/queries";
import {UserRoles} from "../../../Models/Roles";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const supportedType = {
	TERMINAL:0,
	CASHIER:1,
}

let loadingTerminals = [];
let loadedTerminals = [];
let instanceDistribution = null;
class Distribution extends React.Component {
	
	constructor( props ) {
		super( props );
		instanceDistribution = this;
		const periods = props.periodSynchronizer.getPeriods();
		this.state = {
			value:0,
			period1:periods[0],
			isLoading: true
		};

		this.chartContainerRef = null;
		this.spaceGraphBefore = 0.80;
		this.spaceGraphAfter = 0.20;
		if( ! this.props.isDesktop ) {
			this.spaceGraphBefore -= 0.20;
			this.spaceGraphAfter += 0.20;
		}
		this.boxList = this.props.boxList;
	}

	componentDidMount() {
		this.extractOperations( instanceDistribution.props.payStations )
			.then( operations => {
				instanceDistribution.setState({
					isLoading: false,
					value:instanceDistribution.state.value,
					period1:instanceDistribution.state.period1,
					operations: operations
				});
			} );
	}

	generateSeries( data ) {
		let series = [];
		
		data.map( (line) => {
			if( line !== null ) {
				series.push( line.totalCashIn );
			}
			return true;
		} );

		return series;
	}
	
	generateLabelsForGraph( data ) {
		let labels = [];
		
		data.map( (line) => {
			if( line !== null ) {
				if ( line.label.customLabel !== null && line.label.customLabel !== undefined && line.label.customLabel.trim().length > 0 ) {
					labels.push( line.label.customLabel +"  "+ line.label.optDataLabel + "   " );
				} else {
					labels.push( line.label.typeLabel +"  "+ line.label.optDataLabel + "   " );
				}

			}
			return true;
		} );
		
		return labels;
	}

	extractNameForLabel( operation , type) {
		const finder = new PayStationFinder( instanceDistribution.props.payStations );
		const analyzer = new ConfigAnalyzer( instanceDistribution.props.payStations );
		const terminals = analyzer.getDistinctActiveTerminalList();
		const terminal = finder.getTerminalByCBMS( operation.cbms , terminals );

		let labels = [];
		let label = {
			typeLabel:"",
			optDataLabel:"",
			boxId:"",
			customLabel:""
		};

		const friendlyName = ( terminal ) ? finder.buildTerminalExtraData( terminal ) : '';

		if( type === supportedType.TERMINAL ) {
			if( terminal ) {
				label.customLabel = friendlyName;
				label.typeLabel = friendlyName;
			}
		} else if ( type === supportedType.CASHIER ) {
			if( operation.cashier ) {
				label.typeLabel = operation.cashier + ' ( ' + friendlyName + ' )';
				label.customLabel = operation.cashier + ' ( ' + friendlyName + ' )';
			}
		}
		labels.push( label );
		return labels;
	}
	
	extractLineLabelsList( operations , type ) {
		let returnedLabels = [];
		operations.forEach( operation => {
			const labels = this.extractNameForLabel( operation , type);
			labels.forEach( label => {
				if( label ) {
					if( ! returnedLabels.some( item => item.typeLabel === label.typeLabel ) ) {
						returnedLabels.push( label );
					}
				}
			} );
		} )
		return returnedLabels;
	}
	
	setValue( newValue ) {
		instanceDistribution.setState({
			value:newValue
		});
	}
	
	handleChange(event, newValue) {
        instanceDistribution.setValue(newValue);
    };

	filterByTypeForPeriod( operationTypeValue ) {
		const filtered = [];
		if( this.state.operations ) {
			this.state.operations.forEach( operation => {
				if( operation && operation.at ) {
					if( this.state.period1.end >= new Date( operation.at ).getTime() &&
						new Date( operation.at ).getTime() >= this.state.period1.start &&
						operation.type === operationTypeValue &&
						operation.currency === this.props.currencySelected ) {
						filtered.push( operation );
					}
				}
			} );
		}
		return filtered;
	}

	extractCurrencies() {
		const currencies = [];
		if( this.state.operations ) {
			this.state.operations.forEach( operation => {
				if( ! currencies.includes( operation.currency ) ) {
					currencies.push( operation.currency );
				}
			} );
		}
		return currencies;
	}

	extractDataForPeriods( type ) {
		const returnedRow = [];
		const currencies = this.extractCurrencies();
		const analyzer = new ConfigAnalyzer( instanceDistribution.props.payStations );
		const finder = new PayStationFinder( instanceDistribution.props.payStations );
		const terminals = analyzer.getDistinctActiveTerminalList();
		const filtered = this.filterByTypeForPeriod( 1 );
		const labelList = this.extractLineLabelsList( filtered , type );
		if( currencies.length < 1 ) {
			currencies.push("EUR");
		}
		
		labelList.forEach( label => {
			const operations = this.extractForSelection( filtered , label.typeLabel , type );

			let totalCashIn = this.calculateAmount( operations );
			if( operations.length > 0 ) {
				returnedRow.push({
					label: label,
					name: label.customLabel,
					totalCashIn:totalCashIn,
					total:totalCashIn,
					totalNbCashIn:operations.length,
					count:operations.length,
					currency:currencies[0]
				});
			}
		} );

		if( filtered.length > 0 &&  this.props.onCurrenciesUpdate ) {
			this.props.onCurrenciesUpdate( currencies );
		}

		return returnedRow;
	}

	extractForSelection( operations , typeValue , type ) {
		let returned = [];
		const added = [];
		operations.forEach( operation => {
			if ( operation.amount !== 0 && ! this.isCancellation( operation ) && ! added.includes( operation.uuid ) ) {
				const finder = new PayStationFinder( instanceDistribution.props.payStations );
				const analyzer = new ConfigAnalyzer( instanceDistribution.props.payStations );
				const terminals = analyzer.getDistinctActiveTerminalList();
				const terminal = finder.getTerminalByCBMS( operation.cbms , terminals );
				const friendlyName = ( terminal ) ? finder.buildTerminalExtraData( terminal ) : '' ;
				if( type === supportedType.TERMINAL ) {
					if( friendlyName === typeValue ) {
						returned.push(operation);
					}
				} else if ( type === supportedType.CASHIER ) {
					if( operation.cashier ) {
						if( operation.cashier === typeValue.replace( `( ${friendlyName} )` , '' ).trim() ) {
							returned.push(operation);
						}
					}
				}
				added.push( operation.uuid );
			}
		} );

		return returned;
	}

	/*extractForSelection( dataPeriod , typeValue , boxId, type ) {
		let returned = [];
		dataPeriod.map( box => {
			if( box && box.hasOwnProperty("operations") ) {
				box.operations.sort( (a , b) => b.timestamp - a.timestamp );
				box.operations.map( (operation) => {
					if ( operation.amount !== 0 && ! this.isCancellation( operation ) ) {
						if( type === supportedType.TERMINAL ) {
							if( operation.hasOwnProperty("terminal") ) {
								if( operation.terminal === typeValue ) {
									returned.push(operation);
								}
							}
						} else if ( type === supportedType.CASHIER ) {
							if( operation.hasOwnProperty("cashier") ) {
								if( operation.cashier === typeValue  ) {
									returned.push(operation);
								}
							}
						}
					}
					return true;
				} );
			}
			return true;
		} );

		return returned;
	}*/

	isCancellation( operation ) {
		try {
			if( operation.data.details[0].error && operation.data.details[0].error.includes(I18n.get( "Cancelled operation" ) ) ) {
				return true;
			}
		} catch( error ) {

		}

		return false;
	}
	
	calculateAmount( operations ) {
		let total = 0;
		operations.forEach( operation => {
			if( operation && operation.data && operation.data.details ) {
				total += this.calculateTotalForOperation( operation.data.details );
			}
			return true;
		} )
		return total;
	}

	calculateTotalForOperation( details ) {
		let total = 0;
		if( details ) {
			details.forEach( detail => {
				total += detail.qty * detail.value * detail.rate ;
			} );
		}
		return total;
	}
	
	calculateSaleNumber( operations ) {
		var total = 0;
		operations.map( (operation) => {
			if( operation !== null && operation !== undefined && operation.hasOwnProperty("amount") ) {
				total += 1;
			}
			return true;
		} )
		return total;
	}
	
	calculateAmountPerSaleNumber( operations ) {
		let amount = this.calculateAmount( operations );
		let nbSale = this.calculateSaleNumber( operations );
		if( nbSale > 0 ) {
			return amount/nbSale;
		}
		return 0;
	}
	
	handlePageChanged( page ) {
		if( instanceDistribution.chartContainerRef !== null && instanceDistribution.chartContainerRef !== undefined ) {
			instanceDistribution.chartContainerRef.setPage( page );
		}
	}
	
	handleRowsPerPageChanged( rowsPerPage ) {
		if( instanceDistribution.chartContainerRef !== null && instanceDistribution.chartContainerRef !== undefined ) {
			instanceDistribution.chartContainerRef.setRowsPerPage( rowsPerPage );
		}
	}
	
	handleSorting( sortedData ) {
		if( instanceDistribution.chartContainerRef !== null && instanceDistribution.chartContainerRef !== undefined ) {
			instanceDistribution.chartContainerRef.setData( instanceDistribution.generateSeries( sortedData ) , 
															instanceDistribution.generateLabelsForGraph( sortedData ) ,
															sortedData );
		}
	}
	
	getTitleType( value ) {
		switch(value) {
			case supportedType.TERMINAL: 	return I18n.get("Terminal");
			case supportedType.CASHIER:		return I18n.get("Cashier");
			default: return "";
		}
	}
	
	getHeaderProperties( data , type ) {
		if( data.length < 1 ) {
			return [];
		}
		const mobileLabelCount = ( instanceDistribution.props.isDesktop ) ? I18n.get("Cash in Number") : I18n.get("Cash in Number mobile");
		return [
		  { id: "label", numeric: false, disablePadding: false, label: instanceDistribution.getTitleType( type ), money: false, absolute: false, className:"header type terminal-name", width: 150, align: 'center' },
		  { id: 'totalCashIn', numeric: true, disablePadding: false, label: I18n.get("Cash in"), money: true, absolute: false, className:"header sale cash-in", width: 100, align: 'center' },
		  { id: 'totalNbCashIn', numeric: true, disablePadding: false, label: mobileLabelCount, money: false, absolute: false, className:"header sale-number", width: 180, align: 'center' },
		];
	}

	extractOperations( payStations ) {
		return new Promise( resolve => {
			const anonymized = {};
			const analyzer = new ConfigAnalyzer( payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			const operations = [];
			terminals.forEach( terminal => {
				if( terminal.operations.length > 0 ) {
					terminal.operations.forEach( operation => {
						if( ! this.isCancellation( operation ) ) {
							if( instanceDistribution.props.currentUser.role === UserRoles.SALE_ROLE ) {
								if( ! anonymized[operation.cashier] ) {
									anonymized[operation.cashier] = `Caissier ${Object.keys( anonymized ).length + 1}`;
								}
								operation.cashier = anonymized[operation.cashier];
							}
							operations.push( operation );
						}

						if( terminals.indexOf( terminal ) === terminals.length - 1 &&
							terminal.operations.indexOf( operation ) === terminal.operations.length - 1 ) {
							resolve( operations );
						}
					} );
				} else {
					if( terminals.indexOf( terminal ) === terminals.length - 1 ) {
						resolve( operations );
					}
				}
			} );
		} );
	}

	onNewIncomingOperation() {
		instanceDistribution.extractOperations( instanceDistribution.props.payStations ).then( operations => {
			this.setState( {operations: operations} );
		} );
	}
	
	loadOperations( terminal , cbms , period , nextToken ) {
		if( instanceDistribution.props.API ) {
			instanceDistribution.props.API
				.graphql({ query: listOperations, variables: { input: { cbms: cbms , count: 10 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listOperations ) {
						returned.data.listOperations.operations.forEach( operation => {
							terminal.operations.push( operation );
						} );
						if( returned.data.listOperations.nextToken ) {
							instanceDistribution.loadOperations( terminal , cbms , period , returned.data.listOperations.nextToken );
						} else {
							loadedTerminals.push( terminal );
							if( loadedTerminals.length === loadingTerminals.length ) {
								instanceDistribution.extractOperations( instanceDistribution.props.payStations )
									.then( operations => {
										instanceDistribution.setState({
											isLoading: false,
											value:instanceDistribution.state.value,
											period1:instanceDistribution.state.period1,
											operations: operations
										});
									} );
							}
						}
					}
				})
				.catch((error) => {

				})
		}
	}

	handlePeriodSelection( period , identifier ) {
		if( instanceDistribution.props.periodSynchronizer ) {
			instanceDistribution.props.periodSynchronizer.updatePeriod( period , identifier );
		}
		if( instanceDistribution.props.payStations ) {
			instanceDistribution.setState({
				isLoading: true,
				value: instanceDistribution.state.value,
				period1: period,
			});
			const analyzer = new ConfigAnalyzer( instanceDistribution.props.payStations );
			const terminals = analyzer.getDistinctActiveTerminalList();
			loadingTerminals = terminals;
			loadedTerminals = [];
			terminals.forEach( terminal => {
				terminal.operations = [];
				instanceDistribution.loadOperations( terminal , `6_${terminal.informations.bms}` , {
					start: new Date( period.start ).toISOString(),
					end: new Date( period.end ).toISOString()
				} , null );
			} );
		}
	}
	
	renderTab( label , icon ) {
		const key = "distribution-tab-nav-item-" + label;
		return ( 
			<Tab 	key={key} 
					icon={<FontAwesomeIcon icon={icon} className="display-5" />}
					label={I18n.get( label )}/> 
		);
	}
	
	renderTabPanel( label , counterIndex , data , type ) {
		let shown = false;
		let cssTable = "comparison-table";
		if( data.length < 1 ) {
			shown = true;
			cssTable += " hidden";
		}
		const key = "distribution-tab-panel-item-" + label;
		
		if( counterIndex === instanceDistribution.state.value ) {
			delete instanceDistribution.props.pdfDataDelegate.map;
			delete instanceDistribution.props.pdfDataDelegate.lines;
			let properties = this.getHeaderProperties( data , type );
			if( properties.length > 0 ) {
				properties[0].id = "type";
			}
			
			instanceDistribution.props.pdfDataDelegate.columnDefinition = properties;
			instanceDistribution.props.pdfDataDelegate.lines = data;
			instanceDistribution.props.pdfDataDelegate.subTitle = I18n.get( label );
			instanceDistribution.props.pdfDataDelegate.startLocalizedDate = new Date(instanceDistribution.state.period1.start).toLocaleString().replace("," , "").substring( 0 , new Date(instanceDistribution.state.period1.start).toLocaleString().replace("," , "").length - 3 );
			instanceDistribution.props.pdfDataDelegate.endLocalizedDate = new Date(instanceDistribution.state.period1.end).toLocaleString().replace("," , "").substring( 0 , new Date(instanceDistribution.state.period1.end).toLocaleString().replace("," , "").length - 3 );
			let handlerExtraData = {
				locale: instanceDistribution.props.locale.substring( 0 , instanceDistribution.props.locale.indexOf("-") ),
				offset:0
			}
			instanceDistribution.props.pdfDataDelegate.data = {
				boxExtraData: I18n.get( "From" ) + ' ' + instanceDistribution.props.pdfDataDelegate.startLocalizedDate + ' ' +
					I18n.get( "To" ).toLowerCase() + ' ' + instanceDistribution.props.pdfDataDelegate.endLocalizedDate
			}
			instanceDistribution.props.pdfDataDelegate.handler = JSON.stringify( handlerExtraData );
		}

		if( instanceDistribution.state.isLoading === true ) {
			return (
				<TabPanel key={key} value={instanceDistribution.state.value} index={counterIndex}>
					<div className="tab-panel-content">
						<Grid container spacing={1} >
							<Grid id="exportable-table" item xs={12} md={12} lg={12}>
								<Paper className="card-box">
									<SyncIndicator loading={instanceDistribution.state.isLoading}></SyncIndicator>
								</Paper>
							</Grid>
						</Grid>
					</div>
				</TabPanel>
			)
		}

		return ( 
			<TabPanel key={key} value={instanceDistribution.state.value} index={counterIndex}>
				<div className="tab-panel-content">
					<Grid container spacing={1} >
						<Grid id="exportable-table" item xs={12} md={12} lg={12}>
							<Paper className="card-box">		
								<SortableTable header={this.getHeaderProperties( data , type )} 
											   rows={data} 
											   className={cssTable} 
											   locale={this.props.locale}
											   isDarkStyle={this.props.isDarkStyle}
											   isDesktop={this.props.isDesktop}
											   onPageChanged={this.handlePageChanged}
											   onRowsPerPageChanged={this.handleRowsPerPageChanged}
											   onSortingData={this.handleSorting}
											   /*orderBy="label"*/
											   defaultRowsPerPage={25}/>
								<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
							</Paper>
						</Grid>
						{/*<Grid item xs={12} md={6} lg={6}>
							<Paper id="exportable-graphic" className="card-box">	
								<GraphObsContainer 	options={{}} 
													series={this.generateSeries( data )} 
													locale={this.props.locale}
													type="donut"
													name="distribution"
													defaultRowsPerPage={25}
													ref={(component) => instanceDistribution.chartContainerRef = component} />
							</Paper>
						</Grid>*/}
					</Grid>
				</div>
			</TabPanel>
		);
		
	}
	
	renderReminderPanel( label , counterIndex , data , type ) {
		const key = "backoffice-tab-reminder-item-" + counterIndex;
		let totalCashIn = 0;
		let totalSaleNumber = 0;
		let currency = "EUR";
		data.forEach( line  => {
			if( line ) {
				totalCashIn += line.totalCashIn;
				totalSaleNumber += line.totalNbCashIn;
				currency = line.currency;
			}
		});
		const formatter = new AmountFormatter(this.props.locale,  currency );
		return ( 
			<Grid key={key} item xs={12} md={12} lg={12}>
				<div className="tab-panel-reminder no-click">
					<Paper className="card-box reminder comparison-reminder">
						<div className="type-reminder">
							{I18n.get("Total")} {I18n.get(label).toLowerCase()} 
						</div>
						<div className="total-reminder-content">
							<div className="total-period-content">
								<div><span className="small">{I18n.get("Cash in")} :</span> {formatter.format(totalCashIn)}</div>
								<div><span className="small">{I18n.get("Cash in Number")} :</span> {totalSaleNumber}</div>
							</div>	
						</div>									
					</Paper>
				</div>
			</Grid>
		);
		
	}
	
	render() {
		
		const tabs = [];
		const panels = [];
		const reminders = [];
		let counterIndex = 0;
		const dataTerminal = this.extractDataForPeriods( supportedType.TERMINAL , instanceDistribution.state.operations );
		const dataCashier = this.extractDataForPeriods( supportedType.CASHIER , instanceDistribution.state.operations );
		tabs.push( instanceDistribution.renderTab( "Terminal" , ["fas","cash-register"] ) );
		panels.push( instanceDistribution.renderTabPanel( "Terminal" , counterIndex , dataTerminal , supportedType.TERMINAL ) );
		reminders.push( instanceDistribution.renderReminderPanel( "" , counterIndex , dataTerminal , supportedType.TERMINAL ) );
		counterIndex++;
		tabs.push( instanceDistribution.renderTab( "Cashier" , ["fas","users"] ) );
		panels.push( instanceDistribution.renderTabPanel( "Cashier" , counterIndex , dataCashier , supportedType.CASHIER ) );
		//reminders.push( instanceDistribution.renderReminderPanel( "Cashier" , counterIndex , dataCashier , supportedType.CASHIER ) );
		counterIndex++;
		
		let variant;
		let scrollButton;
		if( this.props.isDesktop ) {
			variant = "fullWidth";
			scrollButton="off";
		} else {
			variant = "scrollable";
			scrollButton="on";
		}
		
		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum">
					{/*<NavigationSelector selectedNode={this.props.selectedNode}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.props.onNodeChanged}
										readOnly={true}
										user={this.props.user} />*/}
				</div>	
				<div id="exportable" className="z-over fullscreen">
					<div className="period">
						<SyncIndicator loading={this.state.isLoading}
						                className={"display-4 spining space-top"}/>
						<PeriodChooser period={this.state.period1}
									   onPeriodChanged={this.handlePeriodSelection} 
									   callbackIdentifier="period1" 
									   isDarkStyle={this.props.isDarkStyle} />
					</div>
					<Tabs 	className="nav-tabs-primary mt-4"
							key="backoffice-tab-nav"
							value={instanceDistribution.state.value}
							variant={variant}
							scrollButtons="auto"
							onChange={instanceDistribution.handleChange}>
						{tabs.map( item => item )}	
					</Tabs>
					<Grid id="exportable-reminder" container spacing={1}>
						{reminders.map( item => item )}
					</Grid>
					{panels.map( item => item )}	
				</div>
			</React.Fragment>
		);
	}
}

export default Distribution;
