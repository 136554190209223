import React from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import ScheduledTask from '../../../Models/Structures/ScheduledTask';
import {getIconForNode, roles} from '../../../Models/Roles.js';
import ExpandableCard from '../../Components/ExpandableCard/ExpandableCard';
import TimeChooser from '../../Components/PeriodChooser/TimeChooser';
import InputRecipients from '../../Components/InputRecipients/InputRecipients';
import InventoryAlertConfig from '../../Components/InventoryAlertConfig/InventoryAlertConfig';
import {I18n} from 'aws-amplify/utils';
import {operationType, operationTypeTranslations} from "../../../Models/Report/Enums.js";
import {setUserTask} from '../../../graphql/mutations.js';
import {getTaskForUser} from '../../../graphql/queries.js';
import './Settings.css';
import UUID from "../../../Utils/UUID";
import GuidedSelection from "../../Components/UserFilteringEngine/GuidedSelection";

const factoryUUID = new UUID();
const maxElementForBadge = 99;
const optionSeparator = "[opt-separator]";
I18n.putVocabularies( operationTypeTranslations );
let taskCreationDate = null;
const generatePathLabel = ( item , isRoot ) => {
	let label = "";
	if( item.hasOwnProperty("parentPaths") ) {
		if( isRoot ) {
			label += "";
		} else {
			label += "";
		}
		
		item.parentPaths.map( (path) => {
			label += generatePathLabel( path , false );
			label += path.name.trim();
			return true;
		} );
		
		if( isRoot ) {
			label += "";
		} else {
			label += " > ";
		}
	}
			
	return label;
}

function SelectTaskProperties( props ) {
	const [value, setValue] = React.useState( ( props.defaultValue  &&
												props.options  && 
												props.options.includes( props.defaultValue ) ) ? props.defaultValue : "" );
	
	const handleChange = ( evt ) => {
		setValue( evt.target.value );
		if( props.onValueChanged ) {
			props.onValueChanged( props.id , evt.target.value );
		}
	};
	
	const renderMenuItem = ( optionValue ) => {
		
		if( optionValue && optionValue !== "" ) {
			let optValue =  optionValue;
			let optLabel =  I18n.get(optionValue);
			if( props.isHashMapOption ) {
				let parts = optionValue.split( optionSeparator );
				optValue =  parts[0];
				optLabel =  parts[1];
			}
			
			if( props.showIcon && props.icons !== null && props.icons !== undefined && props.icons.hasOwnProperty(optionValue) ) {
				return (
					<MenuItem  key={`menu-${props.id}-item-${optionValue}-${factoryUUID.generate()}`} 
							   value={optionValue}>
						<FontAwesomeIcon icon={props.icons[optValue]} style={{fontSize:"1.25em"}}/>
						<span className="ml-2">{optLabel}</span>
					</MenuItem>
				);
			}
			return (
				<MenuItem  key={`menu-${props.id}-item-${optionValue}-${factoryUUID.generate()}`} 
						   value={optValue}>
					{optLabel}
				</MenuItem>
			);
		}
		return (
			<MenuItem key={`menu-${props.id}-item-none`} value="">
				<em>{I18n.get("None")}</em>
			</MenuItem>
		);
	}
	
	const options = [];
	if( props.options ) {
		if( props.allowEmpty ) {
			options.push( renderMenuItem ( null ) );
		}
		if( props.isHashMapOption ) {
			props.options.forEach( opt => {
				options.push( renderMenuItem ( opt ) );
			} );
		} else {
			if( props.options.includes( value ) || ( props.allowEmpty && value === "" ) ) {
				props.options.forEach( opt => {
					options.push( renderMenuItem ( opt ) );
				} );
			}
		}
	}
	
	let css = "select-task-properties";
	let cssSelect = "combo-task-properties";
	let cssLabel = "label-task-properties";
	if( props.isDarkStyle ) {
		css += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}
	
	React.useEffect(() => {
		if( props.id === "filter" ) {
			let currentValue = props.defaultValue;
			if( props.isHashMapOption ) {
				currentValue = props.defaultValue.split( optionSeparator )[0];
			}
			setValue( currentValue );
		}
	}, [ props.defaultValue , props.id , props.isHashMapOption ]);

	let currentValue = value;
	if( props.isHashMapOption ) {
		currentValue = value.split( optionSeparator )[0];
	} else {
		if( props.options && !props.options.includes( value ) ) {
			return null;
		}
	}

	return (
		<FormControl variant="outlined" className={css}>
			<InputLabel className={cssLabel} 
						id={`input-combo-${props.id}`}>
				{props.label}
			</InputLabel>
			<Select classes={{selectMenu:cssSelect}}
					labelId={`combo-${props.id}-label`}
					id={`combo-${props.id}-select`}
					value={currentValue}
					onChange={handleChange}
					MenuProps={{
						classes:{paper:cssSelect}
					}}
					label={props.label} >
				{( props.options && props.options.length > 0 )&&
					options.map( item => item )
				}
			</Select>
		</FormControl>
	);
}

function DialogTaskConfig( props ) {
	const [editedTask, setEditedTask] = React.useState( null );
	const [currentType, setCurrentType] = React.useState("");
	const [pendingDeletionScheduledTask, setPendingDeletionScheduledTask] = React.useState(null);
	const [currentNodeId, setCurrentNodeId] = React.useState( ( props.scheduleTask ) ? props.scheduleTask?.node?.id : "" );
	const [targetSelection, setTargetSelection] = React.useState( ( props.scheduleTask ) ? props.scheduleTask?.selection : [] );
	const [isSelecting, setIsSelecting] = React.useState( false );
	let modeSelectionClearDelegate = null;

	const handleCancel = () => {
		if( modeSelectionClearDelegate ) {
			modeSelectionClearDelegate();
			modeSelectionClearDelegate = null;
			setTargetSelection( [] );
		} else if( editedTask ) {
			//discard task changes
			setEditedTask( null );
			setCurrentType("");
			setCurrentNodeId("");
		} else if ( isSelecting ) {
			setIsSelecting( false );
		} else {
			props.handleClose();
		}
	};

	const handleOk = () => {
		if( modeSelectionClearDelegate ) {
			modeSelectionClearDelegate();
			modeSelectionClearDelegate = null;
		}

		if( editedTask ) {
			if( props.scheduleTask ) {
				props.scheduleTask.updateTaskList( editedTask );
				setEditedTask( null );
				setCurrentType("");
				setCurrentNodeId("");
				setTargetSelection( [] );
			}
		} else if ( isSelecting ) {
			console.log( 'Updating ...' , targetSelection );
			props.scheduleTask.updateSelection( targetSelection );
			setIsSelecting( false );
		} else {
			props.handleClose( props.scheduleTask );
		}
	};
	
	const handleTimeSelection = ( timeAsString ) => {
		props.scheduleTask.time = timeAsString;
	}
	
	const handleTaskPropertyChange = ( id , value ) => {
		editedTask[id] = value;
		if( id === "type" ) {
			editedTask["filter"] = "";
			setCurrentType( value );
		}
	}
	
	const handleAdd = () => {
		let task = null;
		if( props.scheduleTask ) {
			task = props.scheduleTask.createNewTask();
		}
		setCurrentType( task?.type );
		setEditedTask( task );
	}

	const handleAddTarget = () => {
		setIsSelecting( true );
	}
	
	const handleRemove = ( task ) => {
		if( props.scheduleTask ) {
			setPendingDeletionScheduledTask( task );
		}
	}
	
	const handleConfirm = ( mode , task ) => {
		if( mode === "delete" && props.scheduleTask ) {
			props.scheduleTask.removeFromList( task );
		}
		setPendingDeletionScheduledTask( null );
	}
	
	const handleSelection = selection => {
		if( props.scheduleTask ) {
			props.scheduleTask.updateSelection( selection );
			setTargetSelection( selection );
		}
	};

	const handleModeSelectionActive = onCancel => {
		modeSelectionClearDelegate = onCancel;
	}

	const adjustTime = () => {
		const date = new Date();
		if( props.scheduleTask ) {
			let parts = props.scheduleTask.time.split(":");
			if( parts.length === 2 ) {
				const hours = parseInt( parts[0] );
				const minutes = parseInt(  parts[1]  );
				date.setHours(hours, minutes, 0);
			}
		}
		return date;
	} 
	
	const getFiltersForType = ( type ) => {
		switch( type ) {
			case "Operations":
				return [ 
					operationType.getName( operationType.REFILL_MANUAL ) , 
					operationType.getName( operationType.REFILL_BY_CASHBOX ) , 
					operationType.getName( operationType.PICKUP_MANUAL ) , 
					operationType.getName( operationType.PICKUP_BY_CASHBOX )  
				];
			case "Cash in":
				return [ 
					operationType.getName( operationType.SALE ) , 
					"Refund" , 
					"Cancellation" 
				];
			case "Inventories": return [];
			case "Distributions":
			return ["Terminal" , "Cashier"];
			case "Comparisons":
			return ["Cash in" , "Average Cash in" , "Cash in Number"];
			default:
			return [""];
		}
	}
	
	const getColorCssClassForBadge = ( role ) => {
		switch( role ) {
			case roles.RESELLER: return "badge-orange";
			case roles.CUSTOMER: return "badge-green";
			case roles.PLACE: return "badge-blue";
			case roles.BOX: return "badge-yellow";
			case roles.DEVICE: return "badge-purple";
			case roles.COMPANY: return "badge-blue";
			case roles.SHOP: return "badge-blue";
			case roles.TERMINAL: return "badge-blue";
			default : return "";
		}
	};
	
	const getOptionForNode = node => {
		if( node ) {
			return node.id + optionSeparator + node.name + optionSeparator + generatePathLabel( node , true );
		}
		return "";
	}
	
	const extractChildOptionsForNode = node => {
		let children = [];
		
		props.nodeList?.forEach( child => {
			children.push( getOptionForNode( child ) );
		} );
		
		return children;
	}
	
	const getAvailableOptionsForNode = node => {
		let availableOption = [];
		if( node ) {
			availableOption.push( getOptionForNode( node ) );
			let childrenOptions = extractChildOptionsForNode( node );
			childrenOptions.forEach( childOpt => {
				availableOption.push( childOpt );
			} );
			return availableOption;
		}
		return [""];
	}

	const renderLine = task => {
		let filter = I18n.get( task.filter );
		if( task.type === "Inventories" ) {
			/*let candidates = props.boxListHandler( props.rootNode , true );
			candidates.forEach( box => {
				if( box.isActivePeriod ) {
					if( box.id === parseInt( task.filter ) ) {
						filter = box.name + " ";
					}
				}
			} )*/
			console.log('Check it out');
		}
		return (
			<ListItemButton 
						key={`config-task-${task.id}`}
						onClick={(evt) => {
							setCurrentType(task.type);
							setEditedTask( task );
						}}
						className="">

				<div className="task-line">
					<div className="details">
						<div className="task-type">{I18n.get( task.type )}</div>
						<div className="task-filter">{filter}</div>
					</div>
					<div className="recipients-counter">
						<Badge 	color="secondary" 
								badgeContent={task.recipients.length}
								max={maxElementForBadge} 
								showZero
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}>
							<FontAwesomeIcon icon={["fas" , "envelope"]} className="display-4"/>
						</Badge>
					</div>
					<div className="delete">
						<IconButton variant="text"  
									className="Delete-Icon" 
									disableRipple
									onClick={(evt) => {
										evt.stopPropagation();
										handleRemove( task )
										
									}} >
							<FontAwesomeIcon icon={["fas" , "trash"]} />
						</IconButton>
					</div>
				</div>
			</ListItemButton>
		);
	}
	
	const renderTaskList = () => {
		if( props.scheduleTask && props.scheduleTask.tasks.length > 0 ) {
			let items = [];
			props.scheduleTask.tasks.forEach( task => {
				if( task ) {
					items.push( renderLine( task ) );
				}
			} )
			
			return (
				<List component="div" disablePadding className="task-list">
				{items.map( item => item )}
				</List>
			);
		}
		return ( 
			<div className="No-Data">
				{/*I18n.get("No settings for the scheduled task")*/}
				{I18n.get("No selection")}
			</div>
		);
	}

	const renderReportGranularity = task => {
		if( task.type !== "Operations" && task.type !== "Cash in" ) {
			return null;
		}

		return (
			<SelectTaskProperties id="granularity"
								  label={I18n.get("Select granularity")}
								  allowEmpty={false}
								  defaultValue={(task?.granularity) ? task?.granularity : "Detailed"}
								  onValueChanged={handleTaskPropertyChange}
								  isDarkStyle={props.isDarkStyle}
								  options={[
									  "Detailed" ,
									  "Consolidated"
								  ]}
			/>
		);
	}

	const renderContent = task => {
		if( isSelecting ) {
			return null;
		}

		if( task ) {
			const filtersOption = getFiltersForType( currentType );
			const defaultValueType = task.type; 
			let defaultValueFilter = task.filter;
			const defaultValueFormat = task.documentFormat;
	
			if( ! filtersOption.includes( defaultValueFilter ) || defaultValueFilter === "" ) {
				if( defaultValueType !== "Inventories" ) {
					defaultValueFilter = filtersOption[0];
					if( editedTask["filter"] === "" ) {
						editedTask["filter"] = filtersOption[0];
					}
				} 
			}
			let newEntryHandler = null;
			if( props.user ) {
				newEntryHandler = props.user;
			}

			let filterUI = null;
			if( filtersOption && filtersOption.length > 0 ) {
				filterUI = <SelectTaskProperties  id="filter"
												  label={I18n.get("Select filter")}
												  allowEmpty={false}
												  defaultValue={defaultValueFilter}
												  isHashMapOption={editedTask["type"] === "Inventories"}
												  onValueChanged={handleTaskPropertyChange}
												  isDarkStyle={props.isDarkStyle}
												  options={filtersOption}
				/>;
			}

			return (
				<div className="task-editor">
					<SelectTaskProperties id="type"
										  label={I18n.get("Select type")}
										  allowEmpty={false}
										  defaultValue={defaultValueType}
										  onValueChanged={handleTaskPropertyChange}
										  isDarkStyle={props.isDarkStyle}
										  options={[
											  "Operations" ,
											  "Cash in" ,
											  "Inventories" ,
											  "Distributions" ,
											  "Comparisons"
										  ]}
					/>

					{filterUI}


					<SelectTaskProperties id="documentFormat"
										  label={I18n.get("Select document format")}
										  allowEmpty={false}
										  showIcon={true}
										  icons={{
											  PDF:["fas" , "file-pdf"],
											  CSV:["fas" , "file-csv"]/*,
											  PNG:["fas" , "file-image"],*/
										  }}
										  defaultValue={defaultValueFormat}
										  onValueChanged={handleTaskPropertyChange}
										  isDarkStyle={props.isDarkStyle}
										  options={["PDF" , "CSV"/* , "PNG"*/]}
					/>

					{renderReportGranularity( task )}

					<InputRecipients id="recipients"
									 label={I18n.get("Recipients")}
									 recipients={task.recipients}
									 newEntryObserver={newEntryHandler}
									 onValueChanged={handleTaskPropertyChange}
									 isDarkStyle={props.isDarkStyle} />

				</div>
			);
		}
		return (
			<div className="material-container">
				<div className="container-title">{I18n.get('Your reports')} : </div>
				<div className="container-content">
					<div key="config-editor-task-list" className="config-editor-task-list">
						{renderTaskList()}
						<IconButton variant="text"
									className="Task-Icon"
									disableRipple
									onClick={handleAdd} >
							<FontAwesomeIcon icon={["fas" , "plus-circle"]} />
						</IconButton>
						{I18n.get("Add")}
					</div>
				</div>
			</div>
		);
	}
	
	const renderTimeChooser = () => {
		if( ! props.scheduleTask || isSelecting || editedTask ) {
			return null;
		}

		return (
			<TimeChooser period={date}
						 key="period-time-chooser-scheduled-task"
						 onTimeChanged={handleTimeSelection} 
						 isDarkStyle={props.isDarkStyle}/>
		);
	}
	
	const renderNodeChooser = () => {
		if( ! props.scheduleTask  || isSelecting ) {
			return null;
		}
		
		let currentOption = currentNodeId;
		let options = [];
		let rootNode = {};
		if( props.scheduleTask ) {
			currentOption = props.scheduleTask.node;
			if( props.scheduleTask.rootNode ) {
				options = getAvailableOptionsForNode( props.scheduleTask.rootNode );
			} else {
				options = getAvailableOptionsForNode( props.user.rootNode );
			}
			//options = getAvailableOptionsForNode( props.scheduleTask.rootNode );
			rootNode = props.user.rootNode;//props.scheduleTask.rootNode;
		}
		
		if( currentOption === "" && options.length === 0 ) {
			return null;
		} 
		
		const properties = {
			id:"node",
			allowEmpty:false,
			label:I18n.get("Select node"),
			defaultValue:currentOption,
			onValueChanged:handleTaskPropertyChange,
			isHashMapOption:true,
			options:options
		}
		const idSearch = "scheduledTaskNodeSearchEngine"
		return null;
		/*return (
			<NavigationSearchEngine key={idSearch} 
									isDarkStyle={props.isDarkStyle}
									onSelectionChanged={handleListItemClick}
									cssProvider={getColorCssClassForBadge} 
									iconProvider={getIconForNode} 
									variant="select"
									SelectProps={properties}
									selectedNode={rootNode} />
		);*/
	}

	const renderTargetSelection = () => {
		if( ! props.scheduleTask || editedTask ) {
			return null;
		}

		if(  isSelecting ) {
			return (
				<GuidedSelection API={props.API}
								 user={props.user}
								 defaultValue={targetSelection}
								 notifySelection={handleSelection}
								 payStations={props.payStations}
								 onModeSelection={handleModeSelectionActive}/>
			);
		} else {
			if( targetSelection && targetSelection.length > 0 ) {
				return (
					<div className="material-container">
						<div className="container-title">{I18n.get('Your selection')} : </div>
						<div className={`container-content  ${(props.payStations?.length === 1) ? '' : 'clickable'}`}
							 onClick={ () => {
								 if( props.payStations?.length > 1 ) {
									 setIsSelecting( true );
								 }
							 } }>
							{targetSelection.map( item => <div key={factoryUUID.generate()}>{item.name}</div> )}
						</div>
					</div>
				);
			} else {
				return (
					<div className="material-container">
						<div className="container-title">{I18n.get('Your selection')} : </div>
						<div className="container-content">
							{I18n.get("No selection")}
							<IconButton variant="text"
										className="Task-Icon"
										disableRipple
										onClick={handleAddTarget} >
								<FontAwesomeIcon icon={["fas" , "plus-circle"]} />
							</IconButton>
							{I18n.get("Add")}
						</div>
					</div>
				);
			}
		}
	}

	let css = "cm-dialog card-box config";
	
	if( props.scheduleTask ) {
		css += " items-nb-" + props.scheduleTask.tasks.length;
		if( targetSelection.length !== props.scheduleTask?.selection.length &&
			props.scheduleTask?.selection.length > 0 ) {
			setTargetSelection( props.scheduleTask?.selection );
		}
	}
	
	if( props.isDarkStyle ) {
		css += " dark";
	}
	const date = adjustTime();
	if( props.payStations?.length === 1 && targetSelection.length !== 1 ) {
		setTargetSelection( [{
			tag: `box-${props.payStations[0].box?.id}` ,
			name: `${ ( props.payStations[0]?.box?.informations?.attributes?.name ) ? props.payStations[0]?.box?.informations?.attributes?.name : props.payStations[0]?.box?.name }`
		}] );
	}

	return (
		<React.Fragment>
			<Dialog disableEscapeKeyDown
					className={css} 
					aria-labelledby="simple-dialog-title" 
					open={Boolean(props.scheduleTask)}>
				<DialogTitle id="confirmation-dialog-title">{I18n.get("Scheduled Task Configuration")}</DialogTitle>
				<DialogContent dividers>
					{renderTimeChooser()}
					{renderNodeChooser()}
					{renderTargetSelection()}
					{renderContent(editedTask)}
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleCancel} >
						{I18n.get("CANCEL")}
					</Button>
					<Button onClick={handleOk} >
						{I18n.get("OK")}
					</Button>
				</DialogActions>
			</Dialog>
			<DialogConfirmRemove scheduleTask={pendingDeletionScheduledTask} 
								 handleClose={handleConfirm}
								 isDarkStyle={props.isDarkStyle}/>
		</React.Fragment>
	);
}

function DialogConfirmRemove( props ) {
	const handleCancel = () => {
		props.handleClose();
	};

	const handleOk = () => {
		props.handleClose( "delete" , props.scheduleTask );
	};
	
	let css = "shadow-sm-dark rounded-sm cm-dialog-confirm";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	
	return (
		<Dialog disableEscapeKeyDown
				classes={{ paper: css }}
				aria-labelledby="simple-dialog-title" 
				open={Boolean(props.scheduleTask)}>
			<div className="text-center p-5">
				<div className="avatar-icon-wrapper rounded-circle m-0">
					<div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-first text-first m-0 d-130">
						<FontAwesomeIcon icon={['fas', 'question']} className="d-flex align-self-center display-3"/>
					</div>
				</div>
				<h4 className="font-weight-bold mt-4">{I18n.get("Are you sure")}</h4>
				<p className="mb-0 text-black-50">{I18n.get("Confirm delete")}</p>
				<div className="pt-4">
					<Button autoFocus onClick={handleCancel} className="btn-neutral-secondary btn-pill text-danger mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("CANCEL")}
						</span>
					</Button>
					<Button onClick={handleOk} className="btn-first btn-pill mx-1">
						<span className="btn-wrapper--label">
							{I18n.get("OK")}
						</span>
					</Button>
				</div>
			</div>
		</Dialog>
		
	);
}

function TaskScheduler( props ) {
	const [tasks, setTasks] = React.useState( ( props.scheduled ) ? props.scheduled : [] );
	const key = "task-scheduler";
	const css = `${tasks.length}-task-items scheduler `;
	const [isAdding, setIsAdding] = React.useState(false);
	const [editedScheduledTask, setEditedScheduledTask] = React.useState(null);
	const [pendingDeletionScheduledTask, setPendingDeletionScheduledTask] = React.useState(null);
	
	const handleClickOpen = scheduledTask => {
		setEditedScheduledTask( scheduledTask );
	};
	
	const handleClose = scheduledTask => {
		if( scheduledTask ) {
			delete scheduledTask.rootNode;
			if( isAdding ) {
				addScheduledTask( scheduledTask );
			} else {
				updateScheduledTask( scheduledTask );
			}
		}
		setEditedScheduledTask(null);
		setIsAdding( false );
	};
	
	const addScheduledTask = scheduledTask => {
		const registeredTask = [];
		tasks.forEach( task => {
			if( task ) {
				registeredTask.push( task );
			}
		} );
		scheduledTask.accountId = editedScheduledTask.userAccountId;
		scheduledTask.selection = editedScheduledTask.selection;
		registeredTask.push( scheduledTask );
		setTasks( registeredTask );
		setIsAdding( false );
		props.user.userProperties.updateScheduledTasks( registeredTask );
		overrideUserTasks( registeredTask );
	};
	
	const updateScheduledTask = scheduledTask => {
		const registeredTask = [];
		tasks.forEach( task => {
			if( task ) {
				if( task.id === scheduledTask.id ) {
					scheduledTask.accountId = editedScheduledTask.userAccountId;
					scheduledTask.selection = editedScheduledTask.selection;
					registeredTask.push( scheduledTask );
				} else {
					registeredTask.push( task );
				}
			}
		} )
		setTasks( registeredTask );
		setIsAdding( false );
		props.user.userProperties.updateScheduledTasks( registeredTask );
		overrideUserTasks( registeredTask );
	};
	
	const removeScheduledTask = id => {
		const registeredTask = [];
		tasks.forEach( task => {
			if( task ) {
				if( task.id !== id ) {
					//task.accountId = editedScheduledTask?.userAccountId;
					//task.selection = editedScheduledTask?.selection;
					registeredTask.push( task );
				}
			}
		} )
		setTasks( registeredTask );
		props.user.userProperties.updateScheduledTasks( registeredTask );
		overrideUserTasks();
	};
	
	const overrideUserTasks = registeredTask => {
		checkTaskIfRequired().then( () => {
			if( taskCreationDate ) {
				const input = {
					userId:props.user?.observer?.currentUser?.uuid,
					parameters:JSON.stringify( registeredTask ),
					creation:taskCreationDate,
					updatedAt:new Date().toISOString(),
					offset:new Date().getTimezoneOffset()
				}
			
				props.API.graphql({ query: setUserTask, variables: {input:input}})
				.then((returned) => {
					console.log("returned" , returned);
				})
				.catch((error) => {
					console.log("error" , error);
				});
			}
		} );
	}
	
	const checkTaskIfRequired = () => {
		return new Promise( (resolve, reject) => {
			if( taskCreationDate === null ) {
				props.API.graphql({ query: getTaskForUser, variables: {input:props.user?.observer?.currentUser?.uuid}})
				.then((returned) => {
					if( returned.data.getTaskForUser && returned.data.getTaskForUser.hasOwnProperty("creation") ) {
						taskCreationDate = returned.data.getTaskForUser.creation;
						resolve();
					} else {
						taskCreationDate = new Date().toISOString();
						resolve();
					}
				})
				.catch((error) => {
					taskCreationDate = new Date().toISOString();
					resolve();
				})
			} else {
				resolve();
			}
		});
	}
	
	const handleAdd = event => {
			setIsAdding( true );
			handleClickOpen( new ScheduledTask( props.user , {accountId: props.user?.observer?.currentUser?.accountId , selection:[]} ) );
	};
	
	const handleRemove = scheduleTask => {
		setPendingDeletionScheduledTask( scheduleTask );
	};
	
	const handleConfirm = ( mode , scheduleTask ) => {
		if( mode === "delete" && scheduleTask ) {
			removeScheduledTask( scheduleTask.id );
		}
		setPendingDeletionScheduledTask( null );
	}

	const extractCandidateForSearch = ( node ) => {
		let returned = [];
		if( node && props.user.rootNode ) {
			if( node.id === props.user.rootNode.id ) {
				let instanceForBackNav = Object.assign({parentPaths:[]}, node);
				returned.push( instanceForBackNav );
			}

			if( node.hasOwnProperty("children") ) {
				node.children.forEach( child => {
					const clonedChild = Object.assign({}, child);
					if( clonedChild !== null ) {

						returned.push( clonedChild );

						if( clonedChild.hasOwnProperty("children") ) {
							let children = extractCandidateForSearch( clonedChild );
							children.forEach( childElement => {
								returned.push( childElement );
							} )
						}
					}
					return true;
				} )
			}
		}
		return returned;
	}

	const renderLocation = ( locationNode ) => {
		return (
			<div key={factoryUUID.generate()} className="path-element">{locationNode}</div>
		);
	}

	const findNode = ( nodeId , node ) => {
		if( ! nodeId || nodeId === node.id ) {
			return node;
		}

		if( node.hasOwnProperty( "children" ) ) {
			let found = null;
			node.children.forEach( child => {
				let candidate = findNode( nodeId , child );
				if( candidate && candidate.id === nodeId) {
					found = candidate;
				}
			} )

			if( found ) {
				return found;
			}
		}

		return null;
	}

	const renderNode = ( selection ) => {
		if( ( selection && selection.length > 0 ) || props.payStations?.length === 1 ) {
			const selectionForLine = ( props.payStations?.length === 1 ) ? [{
				tag: `box-${props.payStations[0].box?.id}` ,
				name: `${ ( props.payStations[0]?.box?.informations?.attributes?.name ) ? props.payStations[0]?.box?.informations?.attributes?.name : props.payStations[0]?.box?.name }`
			}] : selection;

			const resolveIconForItem = item => {
				if( item && item.tag ) {
					if( item.tag.startsWith('account-') && item.tag.endsWith('-reseller') ) {
						return ["fas" , "industry"];
					} else if( item.tag.startsWith('account-') && item.tag.endsWith('-customer') ) {
						return ["fas" , "shop"];
					} else if( item.tag.startsWith('box-') ) {
						return ["fas" , "archive"];
					}
				}
				return "fa-solid fa-house";
			}
			let label = '';
			const elements = [];
			selectionForLine.forEach( item => {
				if( label.trim() !== '' ) {
					label += ' , ';
				}
				label += `<i class="fa-solid fa-house"></i>`;
				elements.push(
					<span key={factoryUUID.generate()} className={`mr-4`}>
						<FontAwesomeIcon key={factoryUUID.generate()} icon={resolveIconForItem( item )} className="display-5 mr-1"/>
						<span key={factoryUUID.generate()} >{item.name}</span>
					</span>
				);
			} );

			return (
				<div className="selected-node">
					<div key={factoryUUID.generate()} className="Node-Icon" >
						{/*<FontAwesomeIcon icon={resolveIconForSample()} className="display-4"/>*/}
					</div>
					<div key={factoryUUID.generate()} className="Node-Label" >
						<div key={factoryUUID.generate()} className="Node-Name" >{/*label*/}{elements.map( element => element )}</div>
						<div key={factoryUUID.generate()} className="Node-Location" >
							{/*locations.map( item => item )*/}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className="selected-node">
				{I18n.get('No selection')}
			</div>
		);
	}

	const renderLine = scheduleTask => {
		return (
			<ListItemButton
						key={`scheduleTask-${scheduleTask.id}`}
						onClick={(evt) => {
							handleClickOpen(scheduleTask);
						}}
						className="">

				<div key={key} className="task-line">
					<div className="time">
						<FontAwesomeIcon icon={["far" , "clock"]} className="display-4"/>
						<span className="time-value">{scheduleTask.time}</span>
					</div>
					<div className="report-counter">
						<Badge 	color="secondary" 
								badgeContent={scheduleTask.tasks.length} 
								max={maxElementForBadge} 
								showZero
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}>
							<FontAwesomeIcon icon={["fas" , "clipboard-list"]} className="display-4"/>
						</Badge>
					</div>
					{renderNode( scheduleTask.selection )}
					<div className="delete">
						<IconButton variant="text"  
									className="Delete-Icon" 
									disableRipple
									onClick={(evt) => {
										evt.stopPropagation();
										handleRemove(scheduleTask);
										
									}} >
							<FontAwesomeIcon icon={["fas" , "trash"]} />
						</IconButton>
					</div>
				</div>
			</ListItemButton>
		);
	}
	
	const renderContent = () => {
		if( tasks && tasks.length > 0 ) {
			let items = [];
			tasks.forEach( task => {
				if( task ) {
					items.push( renderLine( task ) );
				}
			} )
			
			return (
				<List component="div" disablePadding className="task-list">
				{items.map( item => item )}
				</List>
			);
		}
		return ( 
			<div className="No-Data">
				{I18n.get("No scheduled task")}
			</div>
		);
	}

	return (
		<React.Fragment>
			<div key={key} className={css}>
				<div className={`disclaimer`}>{I18n.get("Scheduled_disclaimer")}</div>
				{renderContent()}
				<IconButton variant="text"  
							className="Task-Icon" 
							disableRipple
							onClick={handleAdd} >
					<FontAwesomeIcon icon={["fas" , "calendar-plus"]} />
				</IconButton>
				{I18n.get("Add")}
				<DialogTaskConfig scheduleTask={editedScheduledTask} 
								  handleClose={handleClose}
								  user={props.user}
								  API={props.API}
								  payStations={props.payStations}
								  isDarkStyle={props.isDarkStyle}/>
								  
				<DialogConfirmRemove scheduleTask={pendingDeletionScheduledTask} 
									 handleClose={handleConfirm}
									 isDarkStyle={props.isDarkStyle}/>
			</div>
		</React.Fragment>
	);
	
}

class Settings extends React.Component {
	constructor( props ) {
		super( props );
	}

	renderCardContent( title ) {
		if ( title === I18n.get("My Scheduled Tasks") ) {
			const scheduledTasks = this.props.user.userProperties.scheduledTasks;
			//return null;
			return ( <TaskScheduler locale={this.props.locale}
									isDarkStyle={this.props.isDarkStyle}
									isDesktop={this.props.isDesktop}
									user={this.props.user}
									scheduled={scheduledTasks}
									API={this.props.API}
									payStations={this.props.payStations} /> );
		} else if ( title === I18n.get("My Alerts") ) {
			return( <InventoryAlertConfig locale={this.props.locale}
										  isDarkStyle={this.props.isDarkStyle}
										  isDesktop={this.props.isDesktop}
										  API={this.props.API}
										  userRDS={this.props.userRDS}
										  user={this.props.user}/> );
		} else if ( title === I18n.get("My Preferences") ) {
			return null;
			/*return( <PreferencesConfig  locale={this.props.locale}
										isDarkStyle={this.props.isDarkStyle}
										isDesktop={this.props.isDesktop}
										hideLockedDevices={this.props.user.userProperties.properties.hideLockedDevices}
										hideEmptyPaths={this.props.user.userProperties.properties.hideEmptyPaths}
										user={this.props.user}/> );*/
		} 
		return null;
	}
	
	renderCard( title ) {
		return (
			<ExpandableCard title={title}>
				{this.renderCardContent( title )}
			</ExpandableCard>
		);
	}
	
	render() {
		return (
			<React.Fragment>
				<div key="mySettings" className="Settings-root z-over fullscreen">
					<Grid container spacing={2} >
						{this.renderCard(I18n.get("My Scheduled Tasks"))}
						{this.renderCard(I18n.get("My Alerts"))}
						{/*this.renderCard(I18n.get("My Preferences"))*/}
					</Grid>
				</div>
			</React.Fragment>
		);
	}
}

export default Settings;
