import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UUID from "../../../Utils/UUID";
import './Identity.css';

const factoryUUID = new UUID();

class Identity extends React.Component {
	constructor(props) {
		super(props);
		this.id = factoryUUID.generate();
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			opened:false 
		};
	}

	handleClick() {
		this.setState({opened:!this.state.opened});
	}

	getStatedCss() {
		let css = "Identity";
		if( this.state.opened ) {
			css += " opened";
		} else {
			css += " closed";
		}		
		
		return css;
	}

	getStatedIcon() {
		if( ! this.state.opened ) {
			return ["fas","id-card"];
		}
		return ["fas","compress-alt"];
	}
	
	getStatedOpeningIcon() {
		if( !this.state.opened ) {
			return ["fas","chevron-down"];
		}
		return ["fas","chevron-up"];
	}

	render() {
		let data = this.props.data.box;
		if ( data && this.props.shown ) {
			try {
				data = data.informations.identity;
			} catch (exception) {
				data = {
					brand: "Unknown",
					model: "Unknown",
					serial: "Unknown",
					id: "Unknown",
				}
				console.warn(`invalid box inventory node format` , exception);
			}
		} else {
			return null;
		}

		const hasCustomName = this.props.data &&
							  this.props.data.box &&
							  this.props.data.box.informations &&
							  this.props.data.box.informations.attributes &&
							  this.props.data.box.informations.attributes.name;

		const boxName = `${( hasCustomName ) ? this.props.data.box.informations.attributes.name : this.props.data.box.name }`;
		return (
			<div id={this.id} className={this.getStatedCss()} >
				
				{/*<i className="material-icons">{this.getStatedIcon()}</i>*/}
					<FontAwesomeIcon icon={this.getStatedIcon()} className="display-4" />
				<div className="Name" onClick={this.handleClick}>
					{boxName} {this.props.data.boxExtraData}
					<FontAwesomeIcon icon={this.getStatedOpeningIcon()} className="display-5 opening-indicator" />
				</div>
				<div className="IdCard">
					<div className="InfoLabel">Brand :</div><div className="InfoValue">{data.brand}</div>
					<div className="InfoLabel">Model :</div><div className="InfoValue">{data.model}</div>
					<div className="InfoLabel">Serial :</div><div className="InfoValue">{data.serial}</div>
					<div className="InfoLabel">UUID :</div><div className="InfoValue">{data.id}</div>
				</div>
			</div>
		);
	}
}

export default Identity;
