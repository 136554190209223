import React from 'react';
import ReactCountryFlag from "react-country-flag"
import countryList from 'country-list'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import translations from '../../../AWS/i18n/Translations.js';
import UUID from "../../../Utils/UUID";
import './CountrySelector.css';

const factoryUUID = new UUID();

export default function CountrySelector( props ) {
	const id = factoryUUID.generate();
	const options = React.useMemo(() => countryList.getData(), []);
	const [value, setValue] = React.useState( ( props.defaultValue ) ? props.defaultValue : ( props.isCountryMode ) ? "" : "FR" );
	const supportedLocales = Object.keys(translations);
	
	let css = "CountrySelector-root";
	let cssSelect = "CountrySelector-Label";
	let cssLabel = "CountrySelector-Select";
	
	if( props.isDarkStyle ) {
		css += " dark";
		cssSelect += " dark";
		cssLabel += " dark";
	}
	
	if( props.className ) {
		css += " " + props.className ;
	}
	
	const handleChange =  evt  => {
		setValue( evt.target.value );
		if( props.onValueChanged ) {
			props.onValueChanged( evt.target.value );
		}
	};
	
	const renderMenuItem = optionValue => {
		if( optionValue && optionValue !== "" ) {
			let optValue =  optionValue.code;
			let optLabel =  optionValue.name;
			if( ! props.isCountryMode ) {
				if( ! supportedLocales.includes(optValue.toLowerCase() ) ) {
					return null;
				}
			} 
			
			if( props.showIcon ) {
				return (
					<MenuItem  key={`menu-${id}-item-${optValue}-${factoryUUID.generate()}`} 
							   value={optValue}>
						<ReactCountryFlag
							countryCode={optValue}
							svg
							cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
							cdnSuffix="svg"
							title={optValue}
						/>
						<span className="ml-2">{optLabel}</span>
					</MenuItem>
				);
			}
			return (
				<MenuItem  key={`menu-${id}-item-${optValue}-${factoryUUID.generate()}`} 
						   value={optValue}>
					{optLabel}
				</MenuItem>
			);
		}
	}
	
	const optionItems = [];
	options.forEach( opt => {
		optionItems.push( renderMenuItem ( opt ) );
	} );
	
	return (
		<React.Fragment>
			<div key={id} className={css}>
				<FormControl variant="outlined">
					<InputLabel className={cssLabel} 
								id={`input-label-${id}`}>
						{props.label}
					</InputLabel>
					<Select classes={{selectMenu:cssSelect}}
							labelId={`id-label-${id}-label`}
							id={`id-select-${id}-select`}
							value={value}
							disabled={props.readOnly}
							onChange={handleChange}
							MenuProps={{
								classes:{paper:cssSelect}
							}}
							label={props.label} >
						{optionItems.map( item => item )}
					</Select>
				</FormControl>
			</div>
		</React.Fragment>
	);
}
