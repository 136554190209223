class Filter {
	constructor( userManager , rootNode ) {
		this.name = 'unknown';
		this.selection = [];
		this.expanded = [];
	}

	static fromJson( jsonObject , propertiesProvider ) {
		const filter = new Filter();
		filter.name = jsonObject.name;
		filter.selection = ( jsonObject.selection ) ? jsonObject.selection : [];
		filter.expanded = ( jsonObject.expanded ) ? jsonObject.expanded : [];
		return filter;
	}
	
	toJson() {
		return {
			name: this.name,
			selection: this.selection,
			expanded: this.expanded
		};
	}
}

export default Filter;
