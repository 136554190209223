import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DarkThemeIcon from '@mui/icons-material/Brightness4';
import LightThemeIcon from '@mui/icons-material/BrightnessHigh';
import Typography from '@mui/material/Typography';
//import * as theme from "@mui/system";
import UUID from "../../../Utils/UUID";
import MenuUser from '../Menus/MenuUser';
import MenuAction from '../Menus/MenuAction';
import Drawer from '../Drawer/Drawer';
import { roles , getIconForNode } from '../../../Models/Roles.js';
//import NavigationSearchEngine from './NavigationSearchEngine';
import OutOfSyncIndicator from '../OutOfSyncIndicator/OutOfSyncIndicator';

import './AppBar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserFilteringEngine from "../UserFilteringEngine/UserFilteringEngine";
import CurrencySelector from "../CurrencySelector/CurrencySelector";

const factoryUUID = new UUID();

let instanceAppBar;
let nodeHistory = [];
class CmAppBar extends React.Component {
	constructor( props ) {
		super( props );
		instanceAppBar = this;
		this.idSearch = factoryUUID.generate();
		this.state = {
			open: props.isDesktop,
			lastUpdate: props.lastUpdate,
			currentNode:props.selectedNode,
			isOffline:props.isOffline,
			isLoading:props.isLoading,
			lastRefresh: new Date().getTime()
		}
	}

	onDataUpdated( newDate ) {
		instanceAppBar.updateLastUpdate( newDate );
	}

	setCurrentNode( node ){
		this.setState({
			open: this.state.open,
			lastUpdate: this.state.lastUpdate,
			currentNode:node,
			isOffline:this.state.isOffline,
			isLoading:this.state.isLoading
		});
	}
	
	setSyncState( states ){
		this.setState({
			open: this.state.open,
			lastUpdate: this.state.lastUpdate,
			currentNode:this.state.currentNode,
			isOffline:states.isOffline,
			isLoading:states.isLoading
		});
	}

	handleListItemClick(event, element) {
		let indexSelectCurrent;
		if( element.role === roles.BOX ) {
			indexSelectCurrent = 2;
		} else if ( element.role === roles.DEVICE ) {
			indexSelectCurrent = 3;
			
		} else {
			indexSelectCurrent = 1;
		}
		
		indexSelectCurrent = 1;
		
		nodeHistory = instanceAppBar.calculateHistoryForNode( element , [] );
		instanceAppBar.setCurrentNode( nodeHistory[nodeHistory.length - indexSelectCurrent] );
	}
	
	calculateHistoryForNode( node , history ) {
		if( node ) {
			history.push( node );
			if( node.hasOwnProperty("parentPaths") ) {
				this.calculateHistoryForNode( node.parentPaths[0] , history );
			}
		}
		return history;
	}
	
	getColorCssClassForBadge( role ) {
		switch( role ) {
			case roles.DEALER: return "badge-orange";
			case roles.CUSTOMER: return "badge-green";
			case roles.PLACE: return "badge-blue";
			case roles.BOX: return "badge-yellow";
			case roles.DEVICE: return "badge-purple";
			case roles.COMPANY: return "badge-blue";
			case roles.SHOP: return "badge-blue";
			case roles.TERMINAL: return "badge-blue";
			default : return "";
		}
	}

	setOpen( opened ) {
		this.setState({
			open: opened,
			lastUpdate: this.state.lastUpdate,
			currentNode:this.state.currentNode,
			isOffline:this.state.isOffline,
			isLoading:this.state.isLoading
		} );
	}

	updateLastUpdate( date ) {
		const OUTDATED_DELAY = 1000 * 60;
		if( ( new Date().getTime() - instanceAppBar.state.lastRefresh )  >= OUTDATED_DELAY ) {
			this.setState({
				open: this.state.open,
				lastUpdate: date,
				currentNode:this.state.currentNode,
				isOffline:this.state.isOffline,
				isLoading:this.state.isLoading,
				lastRefresh: new Date().getTime()
			} );
		}
	}

	handleDrawer() {
		instanceAppBar.setOpen( ! instanceAppBar.state.open );
	}

	getIconStyle() {
		if( this.props.isDarkStyle ) {
			return ( <LightThemeIcon/> );
		}
		return ( <DarkThemeIcon/> );
	}

	formatDate( date ) {
		if ( date ) {
			if( this.props.locale && this.props.locale.split("-")[0] === 'fr' ) {
				return date.toLocaleDateString() + " " + date.toLocaleTimeString().substring(0 , 5);
			}
			return date.toLocaleDateString() + " " + date.toLocaleTimeString();
		} else {
			const now = new Date();
			return now.toLocaleDateString() + " " + now.toLocaleTimeString().substring(0 , 5);
		}
	}

	renderSearchEngine() {
		return ( <UserFilteringEngine key={this.idSearch}
									  isDarkStyle={this.props.isDarkStyle}
									  payStations={this.props.payStations}
									  user={this.props.user}
									  API={this.props.observer.props.API}
									  onFilterUpdated={this.props.onFilterUpdated}
									  registerFilterDelegate={this.props.registerFilterDelegate}
									  isSearchMenuOpened={this.props.isSearchMenuOpened}
									  onSearchMenuOpenedStateChanged={this.props.onSearchMenuOpenedStateChanged}/> );
		/*return (<NavigationSearchEngine key={this.idSearch}
										isDarkStyle={this.props.isDarkStyle}
										onSelectionChanged={this.handleListItemClick}
										cssProvider={this.getColorCssClassForBadge} 
										iconProvider={getIconForNode} 
										user={this.props.user}
										isSearchMenuOpened={this.props.isSearchMenuOpened}
										onSearchMenuOpenedStateChanged={this.props.onSearchMenuOpenedStateChanged}
										selectedNode={(this.props.user !== null && this.props.user !== undefined) ? this.props.user.rootNode : this.props.selectedNode} />);*/
	}

	render() {
		return (
			<React.Fragment>
				<AppBar key={`app-bar-container`} position="absolute" >
					<Toolbar className="toolbar-mui">
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={this.handleDrawer}
							className="Icon"
						>
							<MenuIcon className={( this.state.open ) ? 'menu-button-hidden' : ''}/>
							<ChevronLeftIcon className={( !this.state.open ) ? 'menu-button-hidden' : ''}/>
						</IconButton>
						<Typography component="h1" variant="h6" color="inherit" noWrap className="toolbar-title">
							{this.props.title}
						</Typography>
						{<OutOfSyncIndicator isOffline={this.state.isOffline}
											 isLoading={this.state.isLoading}
											 onReconnecting={this.props.onReconnecting}/>}
						{<CurrencySelector isDarkStyle={this.props.isDarkStyle} currencies={this.props.currencies} onCurrencySelection={this.props.onCurrencySelection}/>}
						{this.renderSearchEngine()}
						<IconButton className="Icon" color="inherit" onClick={(event) => this.props.handleStyleChanged()} >
							{this.getIconStyle()}
						</IconButton>
						{<MenuUser 	handleLogout={this.props.handleLogout}
									isDarkStyle={this.props.isDarkStyle}
									handleListItemClick={this.props.handleListItemClick} />}
						{<MenuAction selectedIndex={this.props.selectedIndex}
									handleListItemClick={this.props.handleListItemClick}
									isDarkStyle={this.props.isDarkStyle}
									isDesktop={this.props.isDesktop}
									observer={this.props.observer}
									onRefreshRequested={this.props.onRefreshRequested}
									emailDelegate={this.props.emailDelegate}/>}
						<div className="last-data-received">{this.formatDate(this.state.lastUpdate)}</div>
					</Toolbar>
				</AppBar>
				<Drawer opened={this.state.open}
						handleDrawer={this.handleDrawer}
						isDesktop={this.props.isDesktop}
						isIOS={this.props.isIOS}
						currentUser={this.props.currentUser}
						observer={this.props.observer}
						handleListItemClick={this.props.handleListItemClick}
						selectedIndex={this.props.selectedIndex}
				/>
			</React.Fragment>

		);
	}
}

export default CmAppBar;
