import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from '@mui/material/Card';
import CountUp from 'react-countup';
import ReactCardFlip from 'react-card-flip';
import { CircularProgressbar } from 'react-circular-progressbar';
import LevelManager from '../../../Utils/LevelManager';
import './InventoryCard.css';

export default function InventoryCard( props ) {
	const [flipped, setFlipped] = React.useState(false);

	const handleClick = () => {
		setFlipped( !flipped );
	}

	const formatAmount = ( amount , currencyCode ) => {
		const secureEmpty = () => {
			return currencyCode ? currencyCode : 'EUR';
		}

		if( secureEmpty() === 'XPF' ) {
			return new Intl.NumberFormat( props.locale ).format( amount ) + ' ' + secureEmpty();
		} else {
			return new Intl.NumberFormat( props.locale, { style: 'currency', currency: secureEmpty() }).format( amount );
		}
	}
	
	const renderLevelAdjustment = ( dynamicCss , line ) => {
		let cssIcon = "align-self-center font-size-lg mr-2 text-" + dynamicCss + " font-weight-bold";
		let cssBlock = "cm-level-adjustement-block text-black";
		let cssIndicator = "side-indicator";
		let cssIndicatorUp = cssIndicator + " up";
		let cssIndicatorDown = cssIndicator + " down";
		let cssIndicatorAdjust = cssIndicator + " regulate";
		const iconUp = ['fas', 'plus'];
		const iconDown = ['fas', 'minus'];
		switch( dynamicCss ) {
			case "success":
				return (
					<span className={cssIcon}>
						<div className={cssBlock}>
							<span className={cssIndicatorUp}>
								<FontAwesomeIcon icon={iconUp}  />
							</span>
							{line.high - line.qty}
						</div>
						<div className={cssBlock}>
							<span className={cssIndicatorDown}>
								<FontAwesomeIcon icon={iconDown}  />
							</span>
							{line.qty - line.low}
						</div>
					</span>
				);
			case "danger":
				return (
					<span className={cssIcon}>
						<div className={cssBlock}>
							<span className={cssIndicatorAdjust}>
								<FontAwesomeIcon icon={iconDown}  />
							</span>
							{line.qty - line.high}
						</div>
					</span>
				);
			case "info":
				return (
					<span className={cssIcon}>
						<div className={cssBlock}>
							<span className={cssIndicatorAdjust}>
								<FontAwesomeIcon icon={iconUp}  />
							</span>
							{line.low - line.qty}
						</div>
					</span>
				);
			default:
				return null;
		}
		
	}

	if( props.line && props.inventory ) {
		//let manager = new LevelManager( props.rows );
		const manager = new LevelManager( [props.inventory] );
		let progress = manager.getFillingLevelInPct( props.line );
		if( progress > 100 ) {
			progress = 100;
		}
		const dynamicCss = manager.getColorCss( props.line );

		const totalLine = manager.getAmountForRow( props.line );

		const cssCard = "clickable card-box card-box-border-bottom border-" + dynamicCss ;//+ " shadow-" + dynamicCss + "-sm";
		const cssIcon = "font-size-lg mr-2 text-" + dynamicCss + " font-weight-bold";
		const cssProgress = "m-3 circular-progress-cm circular-progress-sm circular-progress-" + dynamicCss;

		return (
			<ReactCardFlip isFlipped={flipped} flipDirection="vertical">
				<Card className={cssCard}  onClick={handleClick}>
					<div className="d-flex justify-content-start align-items-center card-container-root">
						<div className="p-3 cm-card-data">
							<div className="text-black text-uppercase pb-2 font-size-sm">
								{formatAmount( props.line.value , props.line.currency )}
							</div>
							<h3 className="font-weight-bold display-4 mb-0 text-black">
								<span className={cssIcon}>
									<FontAwesomeIcon icon={props.iconType} className="display-4" />
								</span>
								<CountUp
									start={0}
									end={props.line.qty}
								/>
								<div className="opacity-6 pl-1 text-black-20 font-size-lg">
									{formatAmount( totalLine , props.line.currency )}
								</div>
							</h3>
						</div>
						<CircularProgressbar value={progress} text={progress + '%'} strokeWidth={8} className={cssProgress} />
						<div className="align-self-right font-size-md opacity-5 flip-card-cm">
							<FontAwesomeIcon icon={['fas', 'chevron-right']} />
						</div>
					</div>
				</Card>
				<Card className={cssCard}  onClick={handleClick}>
					<div className="d-flex justify-content-start align-items-center">
						<div className="p-3 cm-card-data">
							<div className="text-black text-uppercase pb-2 font-size-sm">
								{formatAmount( props.line.value , props.line.currency )}
							</div>
							<h3 className="font-weight-bold display-4 mb-0 text-black">
								<span className={cssIcon}>
									<FontAwesomeIcon icon={props.iconType} className="display-4"/>
								</span>
								<CountUp
									start={0}
									end={props.line.qty}
								/>
								<div className="opacity-6 pl-1 text-black-20 font-size-lg" >
									{formatAmount( totalLine , props.line.currency )}
								</div>
							</h3>
						</div>
						{renderLevelAdjustment(dynamicCss, props.line)}
						<div className="align-self-center font-size-md opacity-5 flip-card-cm">
							<FontAwesomeIcon icon={['fas', 'chevron-right']} />
						</div>
					</div>
				</Card>
			</ReactCardFlip>	
		);
	}
	return null;
}
