import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '@mui/material/Dialog';

//import './StateManager.css';
import {sharedTheme} from "../../Style/SharedTheme";
import {I18n} from "aws-amplify/utils";
import Card from "@mui/material/Card";

export default function TableErrorIndicator( props ) {
	const [open, setOpen] = React.useState(false);
	
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};

	if (props.row !== null && props.row !== undefined && props.row.hasOwnProperty("hasError") && props.row.hasError ) {
		let cssOpt = "";
		if( props.isDarkStyle ) {
			cssOpt += " dark Alternative";
		}

		return (
			<React.Fragment>
				<div className={`error-line-indicator`} onClick={handleClickOpen}>
					<FontAwesomeIcon icon={["fas" , "exclamation-triangle"]} className={`display-4 error-icon pulse-animation`} />
				</div>
				<Dialog className={`cm-dialog-error ${cssOpt}`} onClose={handleClose} aria-labelledby="error-dialog-title" open={open}>
					<Card className={`${sharedTheme.cardBox} ${cssOpt}`}>
						<div className={`${sharedTheme.cardHeader} ${cssOpt}`}>
							<div className={sharedTheme.cardHeaderTitle}>
								<b className={sharedTheme.cardHeaderTitleBigBlack}>{I18n.get("Title error on operation")} </b>
							</div>
						</div>
						<div className="px-5 pt-2 pb-3">
							{props.row.errorMessages.map( (message) => <div key={crypto.randomUUID()}>- {message?.replace(/%5Cu0027/g , "'")}</div> )}
						</div>
					</Card>
				</Dialog>
			</React.Fragment>
		);
	}
	return null;
}
