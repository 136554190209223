import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'aws-amplify/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import NoDataPlaceholder from '../../Components/NoDataPlaceholder/NoDataPlaceholder';
import BoxSelector from '../../Components/BoxSelector/BoxSelector';
import DeviceSelector from '../../Components/DeviceSelector/DeviceSelector';
import PeriodChooser from '../../Components/PeriodChooser/PeriodChooser';
import SortableTable from '../../Components/SortableTable/SortableTable';
//import NavigationSelector from '../../Components/NavigationSelector/NavigationSelector';
import SyncIndicator from '../../Components/SyncIndicator/SyncIndicator';
import {messageType, messageTypeTranslations} from "../../../Models/Report/Enums.js";
import '../Operation/Operation.css';
import './Message.css';
import {listMessages} from "../../../graphql/queries";
import ConfigAnalyzer from "../../../Utils/ConfigAnalyzer";
import PayStationFinder from "../../../Utils/PayStationFinder";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}


			{...other}
		>
			{value === index && <div>{children}</div>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

I18n.putVocabularies( messageTypeTranslations );

let loadingDevices = [];
let loadedDevices = [];
let messageInstance = null;
class Message extends React.Component {
	
	constructor( props ) {
		super( props );
		this.state = {
			isLoading:false,
			value: ( this.props.messageLevel ) ? this.props.messageLevel : 0,
			selectedBox: null,
			selectedDevice: null,
			period:{
				start:this.getDailyStartingTimestamp( new Date().getTime() ),
				end:this.getDailyEndingTimestamp( new Date().getTime() )
			},
		}
		messageInstance = this;
	}

	getDailyStartingTimestamp( timestamp ) {
		let date = new Date( timestamp );
		date.setHours(0, 0, 0);
		return date.getTime();
	}
	
	getDailyEndingTimestamp( timestamp ) {
		let date = new Date( timestamp );
		date.setHours(23, 59, 59);
		return date.getTime();
	}
	
	setValue( newValue ) {
		messageInstance.setState({
			isLoading:messageInstance.state.isLoading,
			value:newValue
		});
	}
	
	handleChange(event, newValue) {
        messageInstance.setValue(newValue);
    };
	
	isSupportedMessage( type ) {
		return ( type === messageType.INFORMATION ||
				 type === messageType.WARNING ||
				 type === messageType.SERIOUS_ERROR );
	}
	
	extractDataForType( operationTypeValue ) {
		let returnedRow = [];
		if( messageInstance.state.selectedDevice ) {
			const messages = messageInstance.extractMessages( messageInstance.props.payStations );
			messages?.sort( ( a , b ) => b.at - a.at );
			messages?.forEach( message => {
				if( `5_${messageInstance.state.selectedDevice.informations.bms}` === message.cbms &&
					message.level === operationTypeValue ) {
					try {
						returnedRow.push( {
							date: new Date( message.at ).getTime()/1000,
							text: decodeURI( message.text
								.replace(/%3%4/g , "")
								.replace(/\{/g , "")
								.replace(/\}/g , "")
								.replace(/\?/g , "") ).replace(/\\u[0-9a-fA-F]{4}/g,function( unicode ) {
								const charCode = parseInt( unicode.replace("\\u", "") , 16 );
								return String.fromCharCode( charCode );
							}),
							cbms:message.cbms
						} );
					} catch (error) {
						console.log(error);
						console.log( message.at , message.text.replace(/\{/g , "")
							.replace(/\}/g , "")
							.replace(/\?/g , "") );
					}
				}
			} );
		}

		return returnedRow;
	}

	getHeaderProperties( data ) {
		if( data.length < 1 ) {
			return [];
		}
		if( messageInstance.props.isDesktop ) {
			return [
				{ id: 'date', numeric: false, disablePadding: false, label: I18n.get("Date"), money: false, className:"header date", width: 80,  align: 'left' },
				{ id: 'cbms', numeric: false, disablePadding: false, label: I18n.get("Device"), money: false, className:"header cbms", width: 120,  align: 'left' },
				{ id: 'text', numeric: false, disablePadding: false, label: I18n.get("Message"), money: false, className:"header text", width: 300,  align: 'left' }
			];
		}
		return [
			{ id: 'date', numeric: false, disablePadding: false, label: I18n.get("Date"), money: false, className:"header date", width: 80,  align: 'left' },
			{ id: 'text', numeric: false, disablePadding: false, label: I18n.get("Message"), money: false, className:"header text", width: 300,  align: 'left' }
		];
	}

	extractMessages( payStations ) {
		const analyzer = new ConfigAnalyzer( payStations );
		const devices = analyzer.getDistinctActiveDeviceList();
		const messages = [];
		devices?.forEach( device => {
			device?.messages?.forEach( message => {
				messages.push( message );
			} );
		} );
		return messages;
	}

	loadMessages( device , cbms , period , nextToken ) {
		if( messageInstance.props.API ) {
			messageInstance.props.API
				.graphql({ query: listMessages, variables: { input: { cbms: cbms , count: 10 , start: period.start , end: period.end, nextToken: nextToken } } })
				.then( returned => {
					if( returned && returned.data && returned.data.listMessages ) {
						returned.data.listMessages.messages?.forEach( message => {
							device.messages.push( message );
						} );
						if( returned.data.listMessages.nextToken ) {
							messageInstance.loadMessages( device , cbms , period , returned.data.listMessages.nextToken );
						} else {
							loadedDevices.push( device );
							if( loadedDevices.length === loadingDevices.length ) {
								messageInstance.setState({
									isLoading: false,
									value:messageInstance.state.value,
									period:period,
									messages: messageInstance.extractMessages( messageInstance.props.payStations )
								});
							}
						}
					}
				})
				.catch(error => {
					console.log("error" , error);
				})
		}
	}
	
	handlePeriodSelection( period , identifier ) {
		if( messageInstance.props.payStations ) {
			messageInstance.setState({
				isLoading: true,
				value:messageInstance.state.value,
				period:period,
			});
			const analyzer = new ConfigAnalyzer( messageInstance.props.payStations );
			const devices = analyzer.getDistinctActiveDeviceList();
			loadingDevices = devices;
			loadedDevices = [];
			devices?.forEach( device => {
				device.messages = [];
				messageInstance.loadMessages( device , `5_${device.informations.bms}` , {
					start: new Date( period.start ).toISOString(),
					end: new Date( period.end ).toISOString()
				} , null );
			} );
		}
	}

	renderTab( enumValue ) {
		const key = "backoffice-tab-nav-item-" + enumValue;
		return ( 
			<Tab 	key={key} 
					icon={<FontAwesomeIcon icon={messageType.getIcon(enumValue)} className="display-5" />}
					label={I18n.get( messageType.getName( enumValue ))}/> 
		);
	}
	
	renderTabPanel( enumValue , counterIndex , data ) {
		let shown = false;
		let cssTable = "comparison-table";
		if( data.length < 1 ) {
			shown = true;
			cssTable += " hidden";
		}
		
		if( counterIndex === messageInstance.state.value ) {
			delete messageInstance.props.pdfDataDelegate.map;
			delete messageInstance.props.pdfDataDelegate.lines;
			messageInstance.props.pdfDataDelegate.columnDefinition = this.getHeaderProperties( data );
			messageInstance.props.pdfDataDelegate.lines = data;
			messageInstance.props.pdfDataDelegate.subTitle = I18n.get( messageType.getName( enumValue ));
			messageInstance.props.pdfDataDelegate.startLocalizedDate = new Date(messageInstance.state.period.start).toLocaleString().replace("," , "").substring( 0 , new Date(messageInstance.state.period.start).toLocaleString().replace("," , "").length - 3 );
			messageInstance.props.pdfDataDelegate.endLocalizedDate = new Date(messageInstance.state.period.end).toLocaleString().replace("," , "").substring( 0 , new Date(messageInstance.state.period.end).toLocaleString().replace("," , "").length - 3 );
			let handlerExtraData = {
				locale: messageInstance.props.locale.substring( 0 , messageInstance.props.locale.indexOf("-") ),
				offset:new Date().getTimezoneOffset(),
			}
			const boxName = ( messageInstance.state.selectedBox?.informations?.attributes?.name ) ? ( messageInstance.state.selectedBox?.informations?.attributes?.name ) : '';
			const deviceName = `${( messageInstance.state.selectedDevice ) ? messageInstance.state.selectedDevice.name : ''}`;
			messageInstance.props.pdfDataDelegate.data = {
				boxExtraData: I18n.get( "From" ) + ' ' + messageInstance.props.pdfDataDelegate.startLocalizedDate + ' ' +
					I18n.get( "To" ).toLowerCase() + ' ' + messageInstance.props.pdfDataDelegate.endLocalizedDate + '  ' +
					I18n.get( messageType.getName( enumValue )) + ' - ' + boxName  + ' - ' + deviceName
			}
			messageInstance.props.pdfDataDelegate.handler = JSON.stringify( handlerExtraData );
		}
		
		const key = "backoffice-tab-panel-item-" + enumValue;
		return ( 
			<TabPanel key={key} value={messageInstance.state.value} index={counterIndex}>
				<div className="tab-panel-content">
					<Grid container spacing={1} >
						<Grid item xs={12} md={12} lg={12}>
							<Paper className="card-box">		
								<SortableTable header={this.getHeaderProperties( data )} 
											   rows={data} 
											   className={cssTable} 
											   locale={this.props.locale}
											   isDarkStyle={this.props.isDarkStyle}
											   isDesktop={this.props.isDesktop}
											   onPageChanged={this.handlePageChanged}
											   onRowsPerPageChanged={this.handleRowsPerPageChanged}
											   onSortingData={this.handleSorting}
											   orderBy="date"
											   order="desc"
											   defaultRowsPerPage={5}/>
								<NoDataPlaceholder className="comparison-placeholder-no-data" shown={shown}/>
							</Paper>
						</Grid>
					</Grid>
				</div>
			</TabPanel>
		);
		
	}
	
	render() {
		const boxes = [];
		const analyzer = new ConfigAnalyzer( this.props.payStations );
		const finder = new PayStationFinder( this.props.payStations );
		this.props.payStations?.forEach( payStation => {
			if( analyzer.isActiveBoxAtSearchTime( payStation ) && analyzer.isValid( payStation ) ) {
				boxes.push( payStation.box );
				const terminal = ( payStation.terminals && payStation.terminals.length > 0 ) ? payStation.terminals[0] : null;
				payStation.box.extraData = finder.extractCompanyShopLabel( finder.buildTerminalExtraData( terminal ) );
				if( this.props.selectedBoxId && this.state.selectedBox === null && this.props.selectedBoxId === payStation.box.id ) {
					const module = payStation.devices.filter( item => item.id === this.props.selectedDeviceId )[0];
					this.setState( {
						selectedBox: payStation.box,
						selectedDevice: ( module ) ? module : payStation.devices[0]
					} );
				}
			}

			return true;
		} );

		let tabs = [];
		let panels = [];
		let counterIndex = 0;
		Object.keys(messageType).map( (key) => {
			if( messageInstance.isSupportedMessage( messageType[ key ] ) ) {
				let data = messageInstance.extractDataForType( messageType[ key ] );
				tabs.push( messageInstance.renderTab( messageType[ key ] ) );
				panels.push( messageInstance.renderTabPanel( messageType[ key ] , counterIndex , data ) );
				counterIndex++;
			}
			return true;
		} )

		const getPayStationForBoxId = boxId => {
			let found = null;
			if( this.props.payStations ) {
				this.props.payStations?.forEach( payStation => {
					if( payStation.box && payStation.box.id === boxId ) {
						found = payStation;
					}
				} );
			}
			return found;
		}

		const filterDevicesForSelection = devices => {
			const filtered = [];
			if( devices ) {
				devices?.forEach( candidate => {
					if( candidate.informations &&
						candidate.informations.identity ) {
						filtered.push( candidate );
					}
					return true;
				} );
			}
			return filtered;
		}

		const getFirstActiveDeviceForBox = devices => {
			let found = null;
			if( devices ) {
				devices?.forEach( device => {
					if( !found && analyzer.isActiveAtRequestTime( device.periods , new Date().toISOString() ) ) {
						if(! device.name.includes("_VirtualDevice_") ) {
							found = device;
						}
					}
				} );
			}
			return found;
		}

		const handleBoxChange = ( event, newValue ) => {
			if( newValue >= 0 ) {
				const payStation = getPayStationForBoxId( boxes[newValue].id );
				messageInstance.setState( {
					selectedBox: boxes[newValue],
					selectedDevice: getFirstActiveDeviceForBox( payStation.devices )
				} );
			}
		};

		const handleDeviceChange = ( event, newValue ) => {
			if( newValue >= 0 ) {
				const payStation = getPayStationForBoxId( messageInstance.state.selectedBox.id );
				messageInstance.setState( {
					selectedDevice: payStation.devices[newValue]
				} );
			}
		};

		const payStation = ( this.state.selectedBox ) ? getPayStationForBoxId( this.state.selectedBox?.id ) : null;
console.log( 'payStation' , payStation );
		if( this.props.isDesktop ) {
			return (
				<React.Fragment>
					<div className="z-over fullscreen mb-2 read-only-breadscrum flex-row">
						<Grid item xs={12} md={6} lg={6} className={`grid-item`}>
							<BoxSelector boxes={boxes}
										 allowEmpty={true}
										 label={I18n.get("Select box")}
										 selected={boxes.indexOf( this.state.selectedBox )}
										 defaultValue={`${boxes[0]?.id}`}
										 showIcon={true}
										 isDarkStyle={this.props.isDarkStyle}
										 onChange={handleBoxChange} />
						</Grid>
						<Grid item xs={12} md={6} lg={6} className={`grid-item`}>
							<DeviceSelector  devices={( this.state.selectedBox ) ? filterDevicesForSelection( payStation.devices ) : []}
											 allowEmpty={false}
											 label={I18n.get("Select device")}
											 activeOnly={true}
											 selected={( this.state.selectedDevice ) ? payStation.devices.indexOf( this.state.selectedDevice ) : 0}
											 defaultValue={`${( this.state.selectedDevice && this.state.selectedDevice?.id ) ? this.state.selectedDevice?.id : ""}`}
											 showIcon={true}
											 isDarkStyle={this.props.isDarkStyle}
											 onChange={handleDeviceChange} />
						</Grid>
						{/*<NavigationSelector selectedNode={this.props.selectedNode}
											isDarkStyle={this.props.isDarkStyle}
											onSelectionChanged={this.props.onNodeChanged}
											readOnly={true}
											user={this.props.user} />*/}
					</div>	
					<div id="exportable" className="z-over fullscreen">
						<div className="period">
							<SyncIndicator loading={this.state.isLoading}
							               className={"display-4 spining space-top"}/>
							<PeriodChooser period={this.state.period}
										   onPeriodChanged={this.handlePeriodSelection} 
										   callbackIdentifier="period"
										   isDarkStyle={this.props.isDarkStyle} />
						</div>
						<Tabs 	className="nav-tabs-primary" 
								key="backoffice-tab-nav"
								value={messageInstance.state.value}
								variant="fullWidth"
								onChange={messageInstance.handleChange}>
							{tabs.map( item => item )}	
						</Tabs>
						<div id="exportable-table" className="groupedBy">
							{panels.map( item => item )}
						</div>
					</div>
				</React.Fragment>
			);
		}
		
		return (
			<React.Fragment>
				<div className="z-over fullscreen mb-2 read-only-breadscrum mobile-space-top">
					<Grid item xs={12} md={6} lg={6} className={`grid-item`}>
						<BoxSelector boxes={boxes}
									 allowEmpty={true}
									 className={`mobile-space-bottom`}
									 label={I18n.get("Select box")}
									 selected={boxes.indexOf( this.state.selectedBox )}
									 defaultValue={`${boxes[0]?.id}`}
									 showIcon={true}
									 isDarkStyle={this.props.isDarkStyle}
									 onChange={handleBoxChange} />
					</Grid>
					<Grid item xs={12} md={6} lg={6} className={`grid-item`}>
						<DeviceSelector  devices={( this.state.selectedBox ) ? filterDevicesForSelection( payStation.devices ) : []}
										 allowEmpty={false}
										 label={I18n.get("Select device")}
										 activeOnly={true}
										 selected={( this.state.selectedDevice ) ? payStation.devices.indexOf( this.state.selectedDevice ) : 0}
										 defaultValue={`${( this.state.selectedDevice && this.state.selectedDevice?.id ) ? this.state.selectedDevice?.id : ""}`}
										 showIcon={true}
										 isDarkStyle={this.props.isDarkStyle}
										 onChange={handleDeviceChange} />
					</Grid>
				</div>	
				<div id="exportable" className="z-over fullscreen message">
					<div className="period">
						<SyncIndicator loading={this.state.isLoading}
						                className={"display-4 spining space-top"}/>
						<PeriodChooser period={this.state.period}
									   onPeriodChanged={this.handlePeriodSelection} 
									   callbackIdentifier="period"
									   isDarkStyle={this.props.isDarkStyle} />
					</div>
					<Tabs 	 className="nav-tabs-primary"
							 key="backoffice-tab-nav"
							 value={messageInstance.state.value}
							 variant="scrollable"
							 orientation="vertical"
							 scrollButtons="auto"
							 onChange={messageInstance.handleChange}>
						{tabs.map( item => item )}	
					</Tabs>
					<div id="exportable-table" className="groupedBy">
						{panels.map( item => item )}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Message;
