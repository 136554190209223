

export const sharedTheme = {
	cardBox:"card-box",
	cardHeader:"card-header",
	cardHeaderTitle:"card-header--title",
	cardHeaderTitleSmallGrey:"d-block text-uppercase mt-1",
	cardHeaderTitleBigBlack:"font-size-lg",
	cardHeaderAction:"card-header--actions",
	blue:"#11c5db",
	green:"#1bc943",
	red:"#f83245",
	orange:"#f4772e",
};