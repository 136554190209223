import React from 'react';
import ReactCountryFlag from "react-country-flag"
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import UUID from "../../../Utils/UUID";
import { I18n } from 'aws-amplify/utils';
import Recipient from '../../../Models/Structures/Recipient';
import Menu from '@mui/material/Menu';
import CountrySelector from '../CountrySelector/CountrySelector'
import './InputRecipients.css';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Add} from "@mui/icons-material";

let inputContent = "";

const factoryUUID = new UUID();

function RecipientChip ( props ) {
	const [anchorEl, setAnchorEl] = React.useState( null );
	const [editedLocale, setEditedLocale] = React.useState( props.recipient.locale );
	let css = " RecipientChip-popover";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	if( props.className ) {
		css += " " + props.className ;
	}
	if( ! props.recipient ) {
		return null;
	}

	const handleChipClick = ( evt , locale ) => {
		setAnchorEl( evt.currentTarget );
	}
	
	const handleLocaleSelectorClose =  evt  => {
		setAnchorEl( null );
	}
	
	const handleLocaleChange =  value  => {
		props.recipient.locale = value;
		setEditedLocale( value );
	}
	
	let idItem = `recipient-item-${props.recipient.id}`;
	
	if( props.noLocale ) {
		return (
		<React.Fragment>
				<Chip key={idItem} 
					  label={props.recipient.emailAddress} 
					  onDelete={ (evt) => props.onDelete( evt , props.recipient.id ) } />
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<Chip key={idItem} 
					  icon={<ReactCountryFlag
								countryCode={editedLocale}
								svg
								cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
								cdnSuffix="svg"
								title={editedLocale}
							/>}
					  label={props.recipient.emailAddress} 
					  onClick={(evt) => handleChipClick( evt , props.recipient.locale ) }
					  onDelete={ (evt) => props.onDelete( evt , props.recipient.id ) } />
				<Menu anchorEl={anchorEl}
					  keepMounted
					  anchorOrigin={{
						  vertical: 'top',
						  horizontal: 'center',
					  }}
					  transformOrigin={{
						  vertical: 'top',
						  horizontal: 'center',
					  }}
					  open={Boolean(anchorEl)}
					  classes={{ paper: css }}
					  onClose={handleLocaleSelectorClose} >
					<div >
						<CountrySelector defaultValue={editedLocale}
								 onValueChanged={handleLocaleChange}
								 anchorEl={anchorEl}
								 label={I18n.get("Select language")}
								 showIcon={true}
								 isDarkStyle={props.isDarkStyle}
								 onClose={handleLocaleSelectorClose} />
					</div>
				</Menu>
			</React.Fragment>
		);
	}
}

export default function InputRecipients( props ) {
	const id = factoryUUID.generate();
	const [recipients, setRecipients] = React.useState( ( props.recipients ) ? props.recipients : [] );
	let css = "InputRecipients-root";
	
	if( props.isDarkStyle ) {
		css += " dark";
	}
	if( props.className ) {
		css += " " + props.className ;
	}
	
	let label = "";
	if( props.label ) {
		label += props.label;
	}
	
	const updateRecipients =  newRecipients  => {
		if( props.onValueChanged ) {
			try {
				props.onValueChanged( props.id , newRecipients );
			} catch( error ) {
				
			}
		}
		setRecipients( newRecipients );
	}
	
	const mergeRecipients =  toMergeRecipients  => {
		let newRecipients = [];
		recipients.forEach( ( recipient ) => {
			newRecipients.push( recipient );
		} );
		toMergeRecipients.forEach( ( recipient ) => {
			let shouldInclude = true;
			newRecipients.forEach( ( alreadySet ) => {
				if( recipient.id === alreadySet.id ) {
					shouldInclude = false;
				}
				
				if( recipient.emailAddress === alreadySet.emailAddress && recipient.locale === alreadySet.locale ) {
					shouldInclude = false;
				}
			} );
			
			if( shouldInclude ) {
				newRecipients.push( new Recipient( recipient ) );
				if( props.newEntryObserver ) {
					try {
						props.newEntryObserver.onNewRecipient( recipient );
					} catch( error ) {
						props.newEntryObserver( recipient );
					}
				}
			}
		} );
		updateRecipients( newRecipients );
	}
	
	const removeRecipient =  recipientId  => {
		let newRecipients = [];
		recipients.forEach( ( recipient ) => {
			if( recipient.id !== recipientId ) {
				newRecipients.push( recipient );
			}
		} );
		updateRecipients( newRecipients );
	}
	
	const isValidEmail =  candidateEmail  => {
		const w3cValidationRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return w3cValidationRegex.test( candidateEmail );
	}
	
	const manageInputValidation = ( evt , fromBlur ) => {
		inputContent = evt.target.value;
		if( ! evt.target.value  ) {

			inputContent = evt.target.parentNode.parentNode.parentNode.querySelector(`input`).value;
		}
		let addedEmails = [];
		let parts = inputContent.split(" ");
		parts.forEach( ( candidateEmail ) => {
			if( ( fromBlur || ( parts.length > 1 && candidateEmail !== parts[parts.length-1]) ) && 
				isValidEmail( candidateEmail ) ) {
				addedEmails.push( candidateEmail );
			}
		} );
		if( addedEmails.length > 0 ) {
			addedEmails.forEach( ( email ) => {
				inputContent = inputContent.replace( email , "" );
			} );
			inputContent = inputContent.trim();
			mergeRecipients( addedEmails );
		}
	}
	
	const handleInputChange =  evt  => {
		manageInputValidation( evt , false );
	}
	
	const handleInputBlur =  evt  => {
		manageInputValidation( evt , true );
	}
	
	const handleKeyPress =  evt  => {
		if( evt.keyCode === 13 ){
			handleInputBlur( evt );
		  }
	}
	
	const handleDelete = ( evt , recipientId ) => {
		removeRecipient( recipientId );
	}

	const renderRecipient =  recipient  => {
		let idItem = `recipient-chip-${recipient.id}`;
		return (
			<RecipientChip key={idItem} 
						   recipient={recipient}
						   noLocale={ props.hasOwnProperty("noLocale") && props.noLocale}
						   onDelete={handleDelete}
						   isDarkStyle={props.isDarkStyle} />
		);
	}
	
	let recipientsItems = [];
	recipients.forEach(  recipientAddress  => {
		recipientsItems.push( renderRecipient( recipientAddress ) );
	} );
	
	return (
		<React.Fragment>
			<div key={id} className={css}>
				<TextField
						  id={`id-input-recipient-${id}`}
						  label={label}
						  variant="outlined"
						  defaultValue={inputContent}
						  InputProps={{
							 readOnly: false,
							 endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="add recipient"
												onClick={handleInputBlur}
												edge="end" >
										<Add />
									</IconButton>
								</InputAdornment>
							  )
						  }}
						  onChange={handleInputChange}
						  onBlur={handleInputBlur}
						  onKeyDown={handleKeyPress}
					>
				</TextField>
				<div className="email-container">
					{recipientsItems.map( item => item )}
				</div>
			</div>
		</React.Fragment>
	);
}
