/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onNotifyNode = /* GraphQL */ `
  subscription OnNotifyNode {
    onNotifyNode {
      id
    }
  }
`;

export const onNewState = /* GraphQL */ `
  subscription OnNewState {
    onNewState {
      cbms
      at
      deviceType
      state
    }
  }
`;

export const onNewTechnicalData = /* GraphQL */ `
  subscription OnNewTechnicalData {
    onNewTechnicalData {
      cbms
      at
      data
    }
  }
`;

export const onNewOperation = /* GraphQL */ `
    subscription OnNewOperation {
      onNewOperation {
        at
        cashier
        cbms
        currency
        data {
          cashier
          currency
          details {
            VCR
            currency
            deviceCBMS
            error
            qty
            rate
            type
            value
          }
          terminal
          type
          uuid
        }
        type
        uuid
      }
    }
`;

export const onNewInventory = `
    subscription OnNewInventory {
        onNewInventory {
            cbms
            data {
                deviceCBMS
                uuid
                details {
                    value
                    empty
                    low
                    high
                    full
                    qty
                    currency
                }
                cashBox {
                    empty
                    low
                    high
                    full
                    content {
                        value
                        qty
                        currency
                    }
                }
                loader {
                    empty
                    low
                    high
                    full
                    content {
                        value
                        qty
                        currency
                    }
                }
            }
            at
            sender {
                customer_code
                gateway {
                    brand
                    model
                    serial
                }
                reseller_id
            }
            uuid
        }
    }
`;

export const onNewNotificationState = `
    subscription OnNewNotificationState {
        onNewNotificationState {
            command
			message
			executedAt
			jobId
			progressState
			at
			senderId
        }
    }
`;

/*export const onNewOperation =  `
    subscription OnNewOperation {
        onNewOperation {
            bms_terminal
            cashier
            cbms
            currency
            operation_date
            operation_uuid
            type
        }
    }
`;*/

/*export const onNewOperation = `
    subscription OnNewOperation {
        onNewOperation {
            bms_terminal
            cashier
            cbms
            currency
            operation_date
            operation_uuid
            type
            data {
                cashier
                currency
                iuuid_operation
                local_timestamp
                failed_details {
                    attributs
                    currency
                    exchange_rate
                    fund_type
                    quantity
                    value
                    device_identity {
                        brand
                        category
                        display_name
                        geolocation {
                            latitude
                            longitude
                        }
                        hash_value
                        id
                        model
                        model_variant
                        serial
                        timestamp
                    }
                }
                successful_details {
                    attributs
                    currency
                    device_identity {
                        brand
                        category
                        display_name
                        hash_value
                        id
                        model
                        model_variant
                        geolocation {
                            latitude
                            longitude
                        }
                        serial
                        timestamp
                    }
                    exchange_rate
                    fund_type
                    quantity
                    value
                }
                terminal_identity {
                    brand
                    category
                    display_name
                    hash_value
                    geolocation {
                        latitude
                        longitude
                    }
                    id
                    model
                    model_variant
                    serial
                    timestamp
                }
                type
                utc_timestamp
            }
        }
    }
`;*/
