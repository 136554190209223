import React from 'react';
import './LicenseIndicator.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {I18n} from "aws-amplify/utils";

const LicenseIndicator = props => {

    if( ! props.missingLicenses || props.missingLicenses < 1 ) {
        return null;
    }

    return (
        <div className={`license-indicator`}>
            <div className={`icon-license`}><FontAwesomeIcon icon="fa-solid fa-lock" /></div>
            <div className={`label-license`}>{`${props.missingLicenses} ${I18n.get('Installations requires a license')}`}</div>
        </div>
    );
};

export default LicenseIndicator;
