import { deviceDetect , deviceType , browserName , browserVersion } from "react-device-detect";

class Device {
	constructor( cognitoUser ) {
		this.id = cognitoUser.subjectId;
		this.authTime = cognitoUser.authTime;
		this.properties = deviceDetect();
		this.idToken = cognitoUser.idToken;
		this.deviceType = deviceType;
		this.browserName = browserName;
		this.browserVersion = browserVersion;
		this.ip = "0.0.0.0";
		this.countryTag = "";
	}
	
	equals( anotherDevice ) {
		return this.id === anotherDevice.id && this.ip === anotherDevice.ip;
	}
	
	static fromJson( jsonObject ) {
		let device = new Device({
			subjectId:"",
			authTime:0,
			idToken:{}
		});
		device.id = jsonObject.id ;
		device.authTime = jsonObject.authTime ;
		device.properties = jsonObject.properties ;
		device.idToken = jsonObject.idToken ;
		device.deviceType = jsonObject.deviceType;
		device.browserName = jsonObject.browserName;
		device.browserVersion = jsonObject.browserVersion;
		device.ip = jsonObject.ip;
		device.countryTag = jsonObject.countryTag;
		return device;
	}
	
	getIconForBrowser() {
		const libraryTag = "fab";
		const browser = this.browserName.toLowerCase();
		return [libraryTag , browser];
	}
	
	toJson() {
		return {
			id:this.id,
			authTime:this.authTime,
			properties:this.properties,
			idToken:this.idToken,
			lastUpdate:new Date(),
			deviceType:this.deviceType,
			browserName:this.browserName,
			browserVersion:this.browserVersion,
			ip:this.ip,
			countryTag:this.countryTag,
		};
	}
}

export default Device;
