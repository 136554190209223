import React from 'react';
import {InputAdornment, List, ListItemButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import NoDataPlaceholder from "../NoDataPlaceholder/NoDataPlaceholder";
import {I18n} from "aws-amplify/utils";
import {Search} from "@mui/icons-material";
import DialogConfirmRemove from "../DialogConfirmRemove/DialogConfirmRemove";
import DialogGroupEdit from "./DialogGroupEdit";
import DialogGroupUsersEdit from "./DialogGroupUsersEdit";
import {getGroups} from "../../../graphql/queries";
import {updateGroup, insertGroup, deleteGroup , updateUsersForGroup} from "../../../graphql/mutations";
import GraphQlTool from "../../../Utils/GraphQlTool";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './GroupManager.css';
import IconButton from "@mui/material/IconButton";
import DialogDisplayGroups from "./DialogDisplayGroups";

const GroupManager = props => {
    const [filterName, setFilterName] = React.useState( null );
    const [filterLocked, setFilterLocked] = React.useState( true );
    const [selected, setSelected] = React.useState( null );
    const [pendingEdit, setPendingEdit] = React.useState( null );
    const [pendingUsers, setPendingUsers] = React.useState( null );
    const [pendingDelete, setPendingDelete] = React.useState( null );
    const [groups, setGroups] = React.useState( null );
    const [groupTargets, setGroupTargets] = React.useState( null );

    const loadGroups = () => {
        if( props.API ) {
            props.API
                .graphql({ query: getGroups, variables: { accountId:props.user.accountId }})
                .then( returned => {
                    const tool = new GraphQlTool( 'getGroups' , returned );
                    setGroups( tool.extract() );

                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    const handleConfirm = ( mode , pending ) => {
        switch( mode ) {
            case "add":
                addGroup( pending );
                break;
            case "edit":
                overrideGroup( pending );
                break;
            case "delete":
                removeGroup( pending );
                break;
            default:
                setPendingEdit( null );
                setPendingDelete( null );
                break;
        }
    }

    const handleConfirmUsers = ( mode , pending ) => {
        if( pending ) {
            console.log( 'pending' , pending );
            if( props.API ) {
                props.API.graphql({
                    query: updateUsersForGroup,
                    variables: {
                        input:{
                            id: selected.id,
                            accountId: props.user.accountId,
                            users: pending.usersSelected,
                            groups: pending.groupsSelected,
                        }
                    }
                })
                    .then( returned => {
                        const tool = new GraphQlTool( 'updateUsersForGroup' , returned );
                        setGroups( tool.extract() );
                        setPendingEdit( null );
                    })
                    .catch((error) => {
                        console.log("error" , error);
                    });
            }
        }
        setPendingUsers( null );
    }

    const handleClickAddGroup = () => {
        setPendingEdit( {
            isNew: true,
            selection: [],
            usersSelected: []
        } );
    }

    const handleClickEditGroup = () => {
        const edited = JSON.parse( JSON.stringify( selected ) );
        console.log( 'edited' , edited );
        if( edited && edited.selection && typeof edited.selection === "string" ) {
            const parts = edited.selection.split( ',' );
            const partsTag = edited.selectiontags.replaceAll( ', ' , ',' ).split( ',' );
            edited.selection = [];
            parts.forEach( part => {
                edited.selection.push( {
                    tag: partsTag[ parts.indexOf( part ) ],
                    name: formatName( part )
                } );
            } )
            edited.hasFinancial = edited.financial;
            edited.hasTechnical = edited.technical;
        }
        setPendingEdit( edited );
    }

    const handleClickDeleteGroup = () => {
        setPendingDelete( selected );
    }

    const handleClickManageUsers = () => {
        const formattedUsers = [];
        const formattedGroups = [];
        if ( selected && selected.users && selected.users.trim() !== '' ) {
            selected.users.split(',').forEach( userId => {
                formattedUsers.push( { id: parseInt( userId , 10 ) } );
            } );
        }
        selected.usersSelected = formattedUsers;

        if ( selected && selected.groupsattached && selected.groupsattached.trim() !== '' ) {
            selected.groupsattached.split(',').forEach( groupId => {
                formattedGroups.push( { id: parseInt( groupId , 10 ) } );
            } );
        }
        selected.groupsSelected = formattedGroups;
        console.log( 'Send' , {
            usersSelected: formattedUsers,
            groupsSelected: formattedGroups
        } );
        setPendingUsers( {
            usersSelected: formattedUsers,
            groupsSelected: formattedGroups
        } );
    }

    const handleFilterLockedChanges = () => {
        setFilterLocked( ! filterLocked );
    }

    const displayGroupsHandler = groups => {
        setGroupTargets( groups );
    }

    const handleClearUserGroups = () => {
        setGroupTargets( null );
    }

    const addGroup = pending => {
        if( props.API ) {
            props.API.graphql({
                query: insertGroup,
                variables: {
                    input:{
                        id: 0,
                        name: pending.name.trim(),
                        createdBy: props.user.id,
                        accountId: props.user.accountId,
                        selection: pending.selection
                    }
                }
            })
            .then( returned => {
                const tool = new GraphQlTool( 'insertGroup' , returned );
                setGroups( tool.extract() );
                setPendingEdit( null );
            })
            .catch((error) => {
                console.log("error" , error);
            });
        }
    }

    const overrideGroup = pending => {
        if( props.API ) {
            props.API.graphql({
                query: updateGroup,
                variables: {
                    input:{
                        id: pending.id,
                        name: pending.name.trim(),
                        createdBy: props.user.id,
                        accountId: props.user.accountId,
                        selection: pending.selection
                    }
                }
            })
            .then( returned => {
                const tool = new GraphQlTool( 'updateGroup' , returned );
                setGroups( tool.extract() );
                setPendingEdit( null );
            })
            .catch((error) => {
                console.log("error" , error);
            });
        }
    }

    const removeGroup = pending => {
        if( props.API ) {
            props.API.graphql({
                query: deleteGroup,
                variables: {
                    groupId: pending.id,
                    accountId: props.user.accountId
                }
            })
                .then( returned => {
                    const tool = new GraphQlTool( 'deleteGroup' , returned );
                    setGroups( tool.extract() );
                    setPendingDelete( null );
                })
                .catch((error) => {
                    console.log("error" , error);
                });
        }
    }

    const filterByName = list => {
        if( ! filterName ) {
            return list;
        }
        const filtered = [];
        if( list && list.length > 0 ) {
            list.forEach( userGroup => {
                /*const reseller = extractReseller( payStation.box );
                const customer = extractCustomer( payStation.box );
                const resellerAccount = accounts.filter( item => item.code === reseller.code && item.type === RESELLER );
                const customerAccount = accounts.filter( item => item.code === customer.code && item.type === CUSTOMER );

                try {
                    if( payStation.box.informations.attributes.name.toLowerCase().includes( filterName.toLowerCase() ) ||
                        ( resellerAccount.length > 0 && resellerAccount[0].name.toLowerCase().includes( filterName.toLowerCase() ) ) ||
                        ( customerAccount.length > 0 && customerAccount[0].name.toLowerCase().includes( filterName.toLowerCase() ) ) ) {
                        filtered.push( payStation );
                    }
                } catch ( error ) {
                    console.error( error );
                }*/
                //TO DO
            } );
        }
        return filtered;
    }

    const filterLockedGroups = list => {
        if( ! filterLocked ) {
            return list;
        }
        const filtered = [];
        if( list && list.length > 0 ) {
            list.forEach( userGroup => {
                if( ! userGroup.name.startsWith( 'ROOT-' ) ) {
                    filtered.push( userGroup );
                }
            } );
        }
        return filtered;
    }

    const onFilterNameChanges = evt => {
        setFilterName( evt.target.value );
    };

    const formatName = tag => {
        if( tag?.trim().startsWith( 'BOX-' ) ) {
            let formatted = '';
            props.payStations.forEach( payStation => {
                if( payStation.box?.name?.trim() === tag.replace( 'BOX-' , '' ).trim() ) {
                    formatted += `${ ( payStation?.box?.informations?.attributes?.name ) ? payStation?.box?.informations?.attributes?.name : payStation?.box?.name }`;
                }
            } );
            return formatted;
        }
        return tag.split( '-' )[1];
    }

    const renderListHeader = () => {
        	    return (
        	        <div className={`list-header payStation-card`}>
        	            <div className={`general-block`}>
                            <div className={`type-block groupmanager`}>{ I18n.get('Groups') }</div>
                            <div className={`selection-block title`}>{ I18n.get('Group targets') }</div>
                            <div className={`financial-block`}>{ I18n.get('Info') }</div>
                            <div className={`technical-block`}></div>
                            <div className={`users-block`}></div>
                            <div className={`users-block`}></div>
                            <div className={`creator-block title`}>{ I18n.get('Provider') }</div>
                            <div className={`created-block`}>{ I18n.get('Date') }</div>
                        </div>
        	        </div>
        	    );
        	};

    const renderHeader = () => {
        return (
            <div className={`header-admin`}>
                <div className={`action-bar`}>
                    <div className={`title`}>{ I18n.get("Groups") }</div>
                    <div className={`count`}>( { (groups) ? filterLockedGroups( filterByName( groups ) ).length : 0 } )</div>
                    <div className={`spacer`}></div>
                    <div className={`actions`}>
                        <div className={`button`}  onClick={handleClickAddGroup}>
                            { I18n.get("Create group") }
                        </div>
                        <div className={`button ${( ! selected )?'disabled':''}`}  onClick={handleClickManageUsers}>
                            { I18n.get("Manage group users") }
                        </div>
                        <div className={`button ${( ! selected )?'disabled':''}`}  onClick={handleClickEditGroup}>
							{ I18n.get("Edit group") }
						</div>
                        <div className={`button ${( ! selected )?'disabled':''}`}  onClick={handleClickDeleteGroup}>
							{ I18n.get("Delete group") }
						</div>
                    </div>
                </div>
                <div className={`filter-bar`}>
                    <TextField id="search-groups"
                               className={`search-field-expand`}
                               label={ I18n.get("Filter groups by name") }
                               variant="outlined"
                               size="small"
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <Search />
                                       </InputAdornment>
                                   ),
                               }}
                               onChange={ onFilterNameChanges }/>
                    <div className={`spacer ${( props.user.username !== 'f.guichard@cashmag.fr' ) ? 'hidden' : '' }`}></div>
                    <ToggleButtonGroup
                        classename={`${( props.user.id !== 1 ) ? 'hidden' : '' }`}
                        value={filterLocked}
                        exclusive
                        onChange={handleFilterLockedChanges}
                        aria-label={ I18n.get("Filter locked groups") }
                    >
                        <Tooltip title={ I18n.get("Filter locked groups") }>
                            <ToggleButton value="locked groups" aria-label={ I18n.get("Filter locked groups") }>
                                <FontAwesomeIcon icon="fa-solid fa-lock" />
                            </ToggleButton>
                        </Tooltip>
                    </ToggleButtonGroup>
                </div>
            </div>
        );
    };

    const renderListItem = group => {
        const selectedCss = (selected === group)?'selected':'';
        const disabledCss = (group.account !== props.user.accountId ||
                                    group.createdby !== props.user.id ||
                                    group.name.startsWith( 'ROOT-' ) ) ? 'disabled' : '';

        const resolveName = name => {
            let returned = '';
            const parts = name?.split( ',' );
            parts?.forEach( part => {
                if( part.trim() !== '' ) {
                    if( returned !== '' && ! returned.trim().endsWith(',') ) {
                        returned += ' , ';
                    }
                    if( name.startsWith( 'BOX-' ) ) {
                        props.payStations.forEach( payStation => {
                            if( payStation.box?.name?.trim() === part.replace( 'BOX-' , '' ).trim() ) {
                                returned += `${ ( payStation?.box?.informations?.attributes?.name ) ? payStation?.box?.informations?.attributes?.name : payStation?.box?.name }`;
                            }
                        } );
                    } else {
                        returned += part.replace( 'ACCOUNT-' , '' );
                    }
                }
            } );
            return returned;
        };

        const cleanedSelection = resolveName( group.selection );
        return (
            <ListItemButton key={group.id}
                      className={`payStation-card ${selectedCss} ${disabledCss}`}
                      disabled={disabledCss === 'disabled'}
                      onClick={() => {
                          if( group.account === props.user.accountId &&
                              group.createdby === props.user.id &&
                              ! group.name.startsWith( 'ROOT-' ) )  {
                              setSelected( group );
                          }
                      }}>
                <div className={`type-block`}>
                    {group.name}
                </div>
                <div className={`selection-block`}>
                    {/*cleanedSelection*/}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="groups"
                        onClick={()=>{displayGroupsHandler( cleanedSelection )}}
                        className={`Icon ${( cleanedSelection && cleanedSelection?.trim() === "" ) ? "no-click" : "" }`}
                    >
                        <FontAwesomeIcon icon="fa-solid fa-rectangle-list" className="display-5"/>
                        <span className="user-group-count">{( cleanedSelection && cleanedSelection?.trim() !== "" ) ? cleanedSelection?.split(',').length : 0 }</span>
                    </IconButton>
                </div>
                <div className={`financial-block`}>
                    <FontAwesomeIcon icon="fa-solid fa-chart-line" style={{fontSize:"1.25em"}} className={`${(group.financial) ? 'enabled' : ''}`}/>
                </div>
                <div className={`technical-block`}>
                    <FontAwesomeIcon icon="fa-solid fa-gear" style={{fontSize:"1.25em"}} className={`${(group.technical) ? 'enabled' : ''}`}/>
                </div>
                <div className={`users-block`}>
                    <FontAwesomeIcon icon={["fas" , "users"]} style={{fontSize:"1.25em"}}/>
                    <div className={`count`}> {`${( group.users ) ? group.users?.split( ',' ).length : 0 } `} </div>
                </div>
                <div className={`users-block`}>
                    <FontAwesomeIcon icon={["fas" , "users-rectangle"]} style={{fontSize:"1.25em"}}/>
                    <div className={`count`}> {`${( group.groupsattached ) ? group.groupsattached?.split( ',' ).length : 0 } `} </div>
                </div>
                <div className={`creator-block`}>
                    {group.creator} ( {group.owner} )
                </div>
                <div className={`created-block`}>
                    {new Date( group.updatedat ).toLocaleDateString() + ' ' + new Date( group.updatedat ).toLocaleTimeString()}
                </div>
            </ListItemButton>
        );
    };

    const items = [];
    let filtered = [];
    if( ! groups ) {
        loadGroups();
    } else {
        filtered = filterLockedGroups( filterByName( groups ) );
        filtered.forEach( group => {
            items.push( renderListItem( group ) );
        } );
    }

    const shown = filtered.length < 1;
    if( shown && selected ) {
        setSelected( null );
    }
    return (
        <React.Fragment>
            <div id="group-manager">
                {renderHeader()}
                {renderListHeader()}
                <List>
                    {items.map( item => item )}
                </List>
                <NoDataPlaceholder className="comparison-placeholder-no-data"
                                   shown={shown}/>
                <DialogConfirmRemove handleClose={handleConfirm}
                                     pendingDelete={pendingDelete}
                                     isDarkStyle={props.isDarkStyle}/>

                <DialogGroupEdit handleClose={handleConfirm}
                                 user={props.user}
                                 API={props.API}
                                 payStations={props.payStations}
                                 filteredPayStations={props.filteredPayStations}
                                 pendingEdit={pendingEdit}
                                 isDarkStyle={props.isDarkStyle}/>

                <DialogGroupUsersEdit handleClose={handleConfirmUsers}
                                 user={props.user}
                                 API={props.API}
                                 payStations={props.payStations}
                                 pendingEdit={pendingUsers}
                                 availableUsers={props.availableUsers}
                                 availableGroups={groups?.filter( group => group.id !== selected?.id )}
                                 isDarkStyle={props.isDarkStyle}/>

                <DialogDisplayGroups onClear={handleClearUserGroups}
                                     isDarkStyle={props.isDarkStyle}
                                     title={I18n.get( 'Group targets' )}
                                     userGroups={groupTargets}/>
            </div>
        </React.Fragment>
    );
};

export default GroupManager;
