import { uploadData , copy , downloadData , list , remove  } from 'aws-amplify/storage';
import UserProperties from '../../Models/Structures/UserProperties';
import Device from '../../Models/Structures/Device';

class UserStorage {
	static loggedUser = null;
	
	constructor( caller , cognitoUser ) {
		this.isFirstInitialization = true;
		this.storageChecked = false;
		this.caller = caller;
		UserStorage.loggedUser = cognitoUser;
		this.userDevices = [];
		this.currentDevice = new Device( cognitoUser );

		list( { prefix:'', options: { accessLevel: 'private'} } )
		.then(result => this.initStorageIfRequired(result))
		.catch( error => {
			this.createDefault();
			console.log("error storage App" , error )
		});
	}
	
	async initStorageIfRequired(results) {
		const instance = this;
		if (!this.storageChecked) {
			if ( this.hasPreferencesFile( results ) ) {
				const result = await downloadData({
					key: `preferences-${UserStorage.loggedUser.subjectId}.json`,
					options: { accessLevel: 'private'}
				}).result;

				result.body.text().then( jsonDataAsString => {
					if ( instance.caller ) {
						let preferences = JSON.parse( jsonDataAsString );
						preferences.currentDevice = instance.currentDevice;
						instance.caller.onPreferencesLoaded(preferences);
					}
				});

			} else {
				this.createDefault();
			}
			this.storageChecked = true;
		}
	}
	
	onPreferencesUpdated( json ) {
		if( json !== null && json !== undefined ) {
			this.upload( `preferences-${UserStorage.loggedUser.subjectId}.json` , json );
		}
	}
	
	createDefault() {
		let defaultProperties = new UserProperties( {
			userDevices:[],
			currentDevice:this.currentDevice
		} , this.caller );
		
		if( this.caller !== null && this.caller !== undefined ) {
			this.caller.onPreferencesLoaded( defaultProperties );
		}
		this.upload( `preferences-${UserStorage.loggedUser.subjectId}.json` , defaultProperties.toJson() );
	}
	
	hasPreferencesFile( results ) {
		let found = false;
		results.items.forEach( (file) => {
			this.isFirstInitialization = false;
			if(file.key === `preferences-${UserStorage.loggedUser.subjectId}.json`) {
				found = true; 
			}
		});
		return found;
	}
	
	isFirstInitRequired() {
		return this.isFirstInitialization;
	}
	
	async upload(filename, jsonData) {
		try {
			const instance = this;
			await uploadData({
				key: filename,
				data: JSON.stringify(jsonData),
				options: {
					accessLevel: 'private',
					contentType: 'text/json',
					cacheControl: 'no-cache'

				}
			});

			if (filename === `preferences-${UserStorage.loggedUser.subjectId}.json`) {
				if (instance.caller ) {
					instance.caller.onPreferencesUpdated(jsonData);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
	
	static clearStorage( user ) {
		remove({ key: `preferences-${user.uuid}.json` })
			.then(resultDelete => {
			})
			.catch(err => {
				console.log(`Error on deleting preferences-${user.uuid}.json` , err)
			});
	}
	
	static copyStorage( oldUUID , user ) {
		if( oldUUID === UserStorage.loggedUser.subjectId ) {
			//update current logged user with new space for preferences files otherwise the deletion will be useless 
			UserStorage.loggedUser.subjectId = user.uuid;
		}

		return new Promise(async resolve => {
			try {
				const response = await copy({
					source: { key: `preferences-${oldUUID}.json` },
					destination: { key: `preferences-${user.uuid}.json` },
				});
				resolve();
			} catch ( error ) {
				console.error('Error', error);
				resolve();
			}
		});
	}
}

export default UserStorage;
