export class PeriodSynchronizer {
    constructor() {
        this.defaultInterval = 7;
        const day = 1000 * 60 * 60 * 24;
        this.period1 = {
            start: this.getDailyStartingTimestamp( new Date().getTime() ),
            end: this.getDailyEndingTimestamp( new Date().getTime() )
        };

        this.period2 = {
            start: this.getDailyStartingTimestamp( new Date( new Date().getTime() - ( this.defaultInterval * day ) ).getTime() ),
            end: this.getDailyEndingTimestamp( new Date( new Date().getTime() - ( this.defaultInterval * day ) ).getTime() )
        };
        this.isCustom = false;
    }

    getDailyStartingTimestamp( timestamp ) {
        let date = new Date( timestamp );
        date.setHours(0, 0, 0);
        return date.getTime();
    }

    getDailyEndingTimestamp( timestamp ) {
        let date = new Date( timestamp );
        date.setHours(23, 59, 59);
        return date.getTime();
    }

    getPeriods() {
        return [ this.period1 , this.period2 ];
    }

    updatePeriod( period , identifier ) {
        this.isCustom = true;
        if( identifier === "period1" ) {
            this.period1 = period;
        } else if ( identifier === "period2" ) {
            this.period2 = period;
        }
    }

    isUserChoice() {
        return this.isCustom;
    }
}
