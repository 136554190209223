import React from 'react';
import Button from '@mui/material/Button';
import './DisconnectionDialog.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {I18n} from "aws-amplify/utils";
import {sharedTheme} from "../../Style/SharedTheme";
import Card from "@mui/material/Card";

let instance;
class DisconnectionDialog extends React.Component {
	constructor( props ) {
		super( props );
		instance = this;
		this.state = {
			disconnected: this.props.disconnected,
			message:"",
			forceLogout:false
		}
	}

	onDisconnection( data ) {
		instance.setState({
			disconnected:data.disconnected,
			message:data.message,
			forceLogout:data.forceLogout
		});
	}

	handleClick(event) {
		if(instance.state.forceLogout) {
			if( instance.props.observer !== null && instance.props.observer !== undefined ) {
				instance.props.observer.handleLogout();
			}
		} else {
			document.location.reload();
		}
	}

	render() {
		if ( this.state.disconnected ) {
			return (
				<React.Fragment>
					<div className="Modal-Refresh-overlay">
						<Card className={sharedTheme.cardBox}>
							<div className={sharedTheme.cardHeader}>
								<div className={sharedTheme.cardHeaderTitle}>
									<b className={sharedTheme.cardHeaderTitleBigBlack}>{I18n.get("Disconnection")} </b>
								</div>
							</div>
							<div className="px-5 pt-1 pb-2">
								{instance.state.message}
							</div>
							<div className="card-footer p-1 text-center">
								<div className="text-center px-3">
									<Button className="Modal-Refresh-dialog-button d-flex btn-transition-none border-0 shadow-none btn-neutral-dark mx-auto"
											onClick={this.handleClick}>
										<span className="btn-wrapper--icon">
											<FontAwesomeIcon icon={["fas" , "sync-alt"]} />
										</span>
										<span className="btn-wrapper--label">
											{I18n.get("Reload")}
										</span>
									</Button>
								</div>
							</div>
						</Card>
					</div>
				</React.Fragment>
			);
		}
		return null;
	}
}

export default DisconnectionDialog;
