import { Buffer } from 'buffer';
import UserProperties from '../Models/Structures/UserProperties';
import UserStorage from '../AWS/storage/UserStorage.js';

class UserDataManager {	
	constructor( cognitoUser , observer ) {
		this.observer = observer;
		this.userStorage = new UserStorage( this , cognitoUser );
		this.fileObserver = null;
		this.userProperties = new UserProperties( {
			userDevices:[],
			currentDevice:null
		} , this );
	}
	
	registerFileObserver( fileUpdateObserver ) {
		this.fileObserver = fileUpdateObserver;
	}
	
	unregisterFileObserver() {
		this.fileObserver = null;
	}
	
	onNewRecipient( recipientAddress ) {
		this.userProperties.scheduledTasks.map( ( scheduledTask ) => {
			scheduledTask.mergeDistinct([recipientAddress]);
			scheduledTask.tasks.map( (task) => {
				scheduledTask.mergeDistinct([task.recipients]);
				return true;
			} )
			return true;
		} );
	}
	
	extractMatch( regexAsString , string ) {
		const regex = new RegExp( regexAsString );
		const matches = string.match( regex );
		if( matches.length === 2 ) {
			return matches[1];	//return filtered group !!!
		}
		throw new Error( `Not unique extraction for this regex [${regexAsString}]` ); 
	}
	
	updatePreferences( name , value ) {
		this.userProperties.updateProperty( name , value );
	}
	
	onPreferencesLoaded( preferences ) {
		const instance = this;
		fetch("https://www.cloudflare.com/cdn-cgi/trace")
		.then(response => response.body )
		.then(stream => stream.getReader().read())
		.then(({ done, value }) => {
			//const buffer = Buffer.from(string, 'utf8');
			const stringRead = Buffer.from(value).toString();
			const ip = this.extractMatch( 'ip=([0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3})' , stringRead );
			const countryTag = this.extractMatch( 'loc=([a-zA-Z]*)' , stringRead );
			//console.log(`found ${ip} ${countryTag} `)
			//console.log("instance.userProperties.userDevices" , instance.userProperties.userDevices);
			instance.userProperties.userDevices.updateCurrent( ip , countryTag );
			if( instance.userProperties.currentDevice !== null && instance.userProperties.currentDevice !== undefined ) {
				instance.userProperties.currentDevice.ip = ip;
				instance.userProperties.currentDevice.countryTag = countryTag;
				//update preference file to override the current device:
				
			}
			instance.userStorage.onPreferencesUpdated( instance.userProperties.toJson() );
		})
		
		this.userProperties = new UserProperties( preferences , this );
		if( this.observer !== null && this.observer !== undefined ) {
			this.observer.onUserPreferencesLoaded( this );
		}
		//update preference file to override the current device:
		//this.userStorage.upload( 'preferences.json' , this.userProperties.toJson() );
	}
	
	onPreferencesUpdated( preferences ) {
		if( this.fileObserver !== null && this.fileObserver !== undefined ) {
			this.fileObserver.onFileUpdated();
		}
		
		if( this.observer !== null && this.observer !== undefined ) {
			this.observer.onLoadingRoutineCompleted();
		}
	}
	
	onPropertyChanged( name , value ) {
		this.userStorage.onPreferencesUpdated( this.userProperties.toJson() );
	}
	
	getAvailableRecipientAddresses() {
		let available = [];
		this.userProperties.scheduledTasks.forEach( scheduledTask => {
			scheduledTask.availableRecipients.forEach( candidate => {
				if( ! available.includes( candidate.emailAddress ) ) {
					available.push( candidate.emailAddress );
				}
			} )
		} );
		return available;
	}
}

export default UserDataManager;
