import React from "react";
import {getAccountOptions, getDetailedAccounts} from "../../../graphql/queries";
import GraphQlTool from "../../../Utils/GraphQlTool";
import LicenseManager from "../../Components/AdministrationManagers/LicenseManager";

let instanceLicense;
class License extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            accounts: null
        };
        instanceLicense = this;
    }

    onUpdate() {
        instanceLicense.loadAccounts();
    }

    loadAccounts() {
        if( this.props.API ) {
            this.props.API
                .graphql({ query: getDetailedAccounts, variables: { accountId: this.props.currentUser.accountId }})
                .then( returned => this.loadAllAccountOptions( returned ) )
                .then( accounts => {
                    this.setState( { accounts: accounts } );
                })
                .catch((error) => {
                    console.error("error" , error);
                })
        }
    }

    loadAllAccountOptions( rawAccounts ) {
        return new Promise( resolve => {
            const tool = new GraphQlTool( 'getDetailedAccounts' , rawAccounts );
            const accounts = tool.extract();
            if( accounts && accounts.length > 0 ) {
                const done = [];
                accounts.forEach( account => {
                    this.loadOneAccountOptions( account )
                        .then( () => {
                            done.push( account );
                            if( accounts.length === done.length ) {
                                resolve( accounts );
                            }
                        } );
                } )
            } else {
                resolve( null );
            }
        } )
    }

    loadOneAccountOptions( account ) {
        return new Promise( resolve => {
            if( this.props.API ) {
                this.props.API.graphql({
                    query: getAccountOptions,
                    variables: { accountId: account.id }
                })
                .then( returned => {
                    if( returned ) {
                        if( returned && returned.data && returned.data.getAccountOptions ) {
                            account.options = returned.data.getAccountOptions;
                        }
                        resolve();
                    }
                })
                .catch((error) => {
                    console.log("error" , error);
                    resolve();
                });
            }
        } )
    }

    render() {
        if( ! this.state.accounts ) {
            this.loadAccounts();
        }

        return (
            <React.Fragment>
                <div className="z-over fullscreen">
                    <LicenseManager isDarkStyle={this.props.isDarkStyle}
                                    user={this.props.currentUser}
                                    API={this.props.API}
                                    payStations={this.props.payStations}
                                    onUpdate={this.onUpdate}
                                    accounts={(this.state.accounts)?this.state.accounts:[]}/>
                </div>
            </React.Fragment>
        );
    }
}

export default License;
