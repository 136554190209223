import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { I18n } from 'aws-amplify/utils';
import dayjs from 'dayjs';
import "dayjs/locale/fr";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import UUID from "../../../Utils/UUID";
import { frFR } from '@mui/x-date-pickers/locales';

import './PeriodChooser.css';

const factoryUUID = new UUID();

const PRESET_PERIODS = {
	TODAY: 'TODAY',
	YESTERDAY: 'YESTERDAY',
	CURRENT_WEEK: 'CURRENT_WEEK',
	PREVIOUS_WEEK: 'PREVIOUS_WEEK',
	CURRENT_MONTH: 'CURRENT_MONTH',
	PREVIOUS_MONTH: 'PREVIOUS_MONTH'
};

export default function PeriodChooser(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [presetPeriod, setPresetPeriod] = React.useState( null );
	const [selectedPeriod, setSelectedPeriod] = React.useState( props.period );

	const dateType = {
		START:"start",
		END:"end"
	};

	const getDate = ( period , property ) => {
		return new Date(selectedPeriod[property])
	}

	const getLocale = () => {
		let locale = navigator.language || navigator.browserLanguage || ( navigator.languages || [ "fr" ] )[0];
		if( locale.indexOf("-") === -1 ) {
			locale = `${locale}-${locale.toUpperCase()}`;
		}
		return locale;
	}

	const getLocalizedDateFormat = () => {
		switch ( getLocale() ) {
			case "fr-FR": return "DD/MM/YYYY HH:mm"
			default: return "MM/DD/YYYY HH:mm"
		}
	}
	
	const is12HourTimeFormat = () => {
		let userLang = navigator.language || navigator.userLanguage; 
		switch ( userLang.toLowerCase() ) {
			case "fr-fr": return false
			case "fr-ca": return false
			case "fr-be": return false
			case "fr": return false
			default: return true
		}
	}
	
	const identifyDate = type => {
		dateCurrentlyChanging = type;
	}

	const calculatePeriod = ( startsAt , endsAt ) => {
		const startOfDay = new Date(startsAt.getFullYear(), startsAt.getMonth(), startsAt.getDate(), 0, 0, 0, 0);
		const endOfDay = new Date(endsAt.getFullYear(), endsAt.getMonth(), endsAt.getDate(), 23, 59, 59, 999);

		return {
			start: startOfDay.getTime(),
			end: endOfDay.getTime()
		};
	}

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};
	
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	const handleDateChange = dayJsDate => {
	    setPresetPeriod( null );
		const date = new Date( dayJsDate.toISOString() );
		try {
			if ( date && ! isNaN( date.getTime() ) ) {
				let newPeriod = {
					start:selectedPeriod.start,
					end:selectedPeriod.end
				};
				switch( dateCurrentlyChanging ) {
					case dateType.START:
						newPeriod.start = date.getTime();
					break;
					case dateType.END:
						newPeriod.end = date.getTime();
					break;
					default:
					break;
				}
				dateCurrentlyChanging = null;
				setSelectedPeriod( newPeriod );
				if( props.onPeriodChanged ) {
					props.onPeriodChanged( newPeriod , props.callbackIdentifier );
				}
			} 
		} catch( error ) {
			console.log( error );
		}
		
	};

	const handlePresetPeriods = preset => {
	    setPresetPeriod( preset );
		let period = {
			start:selectedPeriod.start,
			end:selectedPeriod.end
		};
		const ONE_DAY = 24 * 60 * 60 * 1000;
		const WEEK = 7 * ONE_DAY;
		let extraTime = WEEK;
		let extraMonth = 1;
		const today = new Date();
		const dayOfWeek = today.getDay();
		switch ( preset ) {
			case PRESET_PERIODS.TODAY :
				period = calculatePeriod( today , today );
				break;
			case PRESET_PERIODS.YESTERDAY :
				const yesterday = new Date( today.getTime() - ONE_DAY );
				period = calculatePeriod( yesterday , yesterday );
				break;
			case PRESET_PERIODS.CURRENT_WEEK : //Fall through
				extraTime = 0;
			// eslint-disable-next-line no-fallthrough
			case PRESET_PERIODS.PREVIOUS_WEEK :
				const frenchWeekIndex = ( dayOfWeek > 0 ) ? dayOfWeek - 1 : 6 ;
				const firstDayOfWeek = new Date( today.getTime() - ( ( frenchWeekIndex * ONE_DAY ) + extraTime ) );
				const lastDayOfWeek = new Date( firstDayOfWeek.getTime() + ( 6 * ONE_DAY ) );
				period = calculatePeriod( firstDayOfWeek , lastDayOfWeek );
				break;
			case PRESET_PERIODS.CURRENT_MONTH : //Fall through
				extraMonth = 0;
			// eslint-disable-next-line no-fallthrough
			case PRESET_PERIODS.PREVIOUS_MONTH :
				const startOfMonth = new Date( today.getFullYear(),  today.getMonth() - extraMonth, 1 );
				const endOfMonth = new Date( today.getFullYear(), today.getMonth() + ( 1 - extraMonth ), 0 );
				period = calculatePeriod( startOfMonth , endOfMonth );
				break;
			default:
				break;
		}
		setSelectedPeriod( period );
		setAnchorEl(null);
		if( props.onPeriodChanged ) {
			props.onPeriodChanged( period , props.callbackIdentifier );
		}
	}

	const renderQuickAccessButtons = () => {
		return (
		    <div className={`quick-access-buttons`}>
		        {Object.keys( PRESET_PERIODS ).map( key =>
                    <Button key={key}
                            className= {`${(presetPeriod === key ? 'selected' : '')} btn selection-button`}
                            onClick={ () => { handlePresetPeriods( key ) } }>
                        {I18n.get( key )}
                    </Button>
                )}
		    </div>
		);
	}
	const labelStart = I18n.get("Start") + " :";
	const labelEnd = I18n.get("End") + " :";

	let css = "Period-chooser";
	let cssMenu = "p-0";
	let cssPopup = "dropdown-menu-md overflow-hidden p-3 Period-chooser-Popup";
	let cssDial = "dial-cm";
	if( props.isDarkStyle ) {
		css += " dark";
		cssMenu += " dark";
		cssPopup += " dark";
		cssDial += " dark";
	} 
	let dialogProps = {
		'className':cssDial
	};
	let dateCurrentlyChanging = null;
	const idButton = factoryUUID.generate();
	let tooltipTextTop = /*I18n.get("Period") + " " +*/ I18n.get("From").toLowerCase() + " ";
	tooltipTextTop += new Date( selectedPeriod.start ).toLocaleDateString() + " " + new Date( selectedPeriod.start ).toLocaleTimeString().substring(0,5);
	
	let tooltipTextBottom =  " " + I18n.get("To").toLowerCase() + " ";
	tooltipTextBottom += new Date( selectedPeriod.end ).toLocaleDateString() + " " + new Date( selectedPeriod.end ).toLocaleTimeString().substring(0,5);
	
	return (
		<React.Fragment>
			<div className={css}>
				<Button letiant="text"  
						id={idButton}
						className="Icon" 
						disableRipple
						onClick={handleClick} >
					<FontAwesomeIcon icon={["fas" , "calendar-week"]} className="display-4"/>
					<div className="label-period">
						<div className="top">{tooltipTextTop}</div>
						<div className="bottom">{tooltipTextBottom}</div>
					</div>
				</Button>
				<Menu anchorEl={anchorEl}
					  keepMounted
					  anchorOrigin={{
						  vertical: 'bottom',
						  horizontal: 'left',
					  }}
					  transformOrigin={{
						  vertical: 'top',
						  horizontal: 'right',
					  }}
					  open={Boolean(anchorEl)}
					  classes={{ list: cssMenu }}
					  onClose={handleClose} >
					<div className={cssPopup}>
						<div className="grid-menu grid-menu-1col">
							<LocalizationProvider dateAdapter={AdapterDayjs}
												  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
												  adapterLocale="fr">

                                {renderQuickAccessButtons()}
								<div className="period-line start" onClick={(evt) => identifyDate(dateType.START)}>
									<MobileDateTimePicker label={labelStart}
														  id={factoryUUID.generate()}
														  format={getLocalizedDateFormat()}
														  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
														  ampm={is12HourTimeFormat()}
														  onChange={handleDateChange}
														  labelFunc={(date , invalidLabel) => {return date.toLocaleDateString() + " " + date.toLocaleTimeString().substring(0,5)}}
														  disableFuture={true}
														  defaultValue={dayjs(getDate( selectedPeriod , dateType.START).toISOString())}/>
								</div>
								<div className="period-line end" onClick={(evt) => identifyDate(dateType.END)}>
									<MobileDateTimePicker label={labelEnd}
														  id={factoryUUID.generate()}
														  format={getLocalizedDateFormat()}
														  localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
														  ampm={is12HourTimeFormat()}
														  onChange={handleDateChange}
														  labelFunc={(date , invalidLabel) => {return date.toLocaleDateString() + " " + date.toLocaleTimeString().substring(0,5)}}
														  disableFuture={true}
														  defaultValue={dayjs(getDate( selectedPeriod , dateType.END).toISOString())}/>
								</div>
							</LocalizationProvider>
						</div>
					</div>
				</Menu>
			</div>
		</React.Fragment>
	);
}
