import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import './CycleStateIndicator.css';
import '../LastWeekSales/LastWeekSales.css';


export default function CycleStateIndicator( props ) {
	const pctWarning = 10; //Replace with prop parameter
	const getCssProgress = ( isMoreThanRecommended , hasWarning ) => {
		let css = "progress-bar-rounded progress-sm mb-2 progress-bar-success";	
		if( isMoreThanRecommended )	{
			css = "progress-bar-rounded progress-sm mb-2 progress-bar-danger";	
		} else if( hasWarning )	{
			css = "progress-bar-rounded progress-sm mb-2 progress-bar-warning";	
		}
		return css;
	}
	
	const hasReachWarningLevel = ( current , recommended , pct ) => {
		return current >= ( recommended * ( ( 100 - pct ) / 100 ) );
	}
	
	if( props.current && props.recommended ) {
		const pct = ( props.current > props.recommended ) ? 100 : (props.current/props.recommended) * 100 ;
		return( 
			<React.Fragment>
				<div className={`cycle-state-indicator-root`}>
					<div className={`label`}>{props.label}</div>
					<div className={`value`}>{new Intl.NumberFormat( props.locale ).format( props.current )} / {new Intl.NumberFormat( props.locale ).format( props.recommended )}</div>
					<LinearProgress variant="determinate" value={pct} className={getCssProgress( props.current >= props.recommended , hasReachWarningLevel( props.current , props.recommended , pctWarning ) )} />
				</div>
			</React.Fragment>
		);
	}
	return null;
}
