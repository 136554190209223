import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {fundType} from "../../../Models/Report/Enums";

//import './StateManager.css';

export default function TableManualIndicator( props ) {
	if (props.row && props.row.hasOwnProperty("manual") && props.row.manual ) {
		return (
			<React.Fragment>
				<div className={`manual-line-indicator`}>
					<FontAwesomeIcon icon={["fas" , "hand-holding-usd"]} className={`display-5 manual-icon`} />
				</div>
			</React.Fragment>
		);
	}
	
	if( props.row.details[0].type < 4 ) {
		return (
			<React.Fragment>
				<div className={`manual-line-indicator`}>
					<FontAwesomeIcon icon={["fas" , "donate"]} className={`display-5 recycler-icon`} />
				</div>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<div className={`manual-line-indicator`}>
				<FontAwesomeIcon icon={fundType.getIcon( props.row.details[0].type )} className={`display-5 recycler-icon`} />
			</div>
		</React.Fragment>
	);
}
