import MobileDetection from './MobileDetection';

class AmountFormatter {	
	constructor( locale , currency ) {
		const mobileDetector = new MobileDetection();
		this.locale = locale;
		
		if( mobileDetector.isIOS() && this.locale.startsWith("fr") ) {
			this.locale = "fr-ca";
		}
		
		
		this.currency = currency;
		if( ! this.currency ) {
			this.currency = "EUR";
		}
		this.roundingAmount = 999.99;
	}
	
	getFormatter( withDecimal ) {
		try {
			if( withDecimal ) {
				return new Intl.NumberFormat(this.locale, { 
				style: 'currency', 
				currency: this.currency , 
				});
			} else {
				return new Intl.NumberFormat(this.locale, { 
				style: 'currency', 
				currency: this.currency , 
				minimumFractionDigits: 0, 
				maximumFractionDigits: 0, 
				});
			}
		} catch ( error ) {
			if( withDecimal ) {
				return new Intl.NumberFormat(this.locale, { 
				style: 'currency', 
				currency: "EUR" , 
				});
			} else {
				return new Intl.NumberFormat(this.locale, { 
				style: 'currency', 
				currency: "EUR" , 
				minimumFractionDigits: 0, 
				maximumFractionDigits: 0, 
				});
			}
		}
	}
	
	getAmount( amount, withDecimal ) {
		if( ! withDecimal ) {
			return Math.round(amount);
		} 
		return amount;
	}
	
	format( amount ) {
		let withDecimal = amount < this.roundingAmount;
		if( this.currency === 'XPF' ) {
			return new Intl.NumberFormat( this.locale ).format( this.getAmount( amount, false ) ) + ' ' + this.currency;
		} else {
			return this.getFormatter( withDecimal ).format( this.getAmount( amount, withDecimal ) );
		}
	}
}

export default AmountFormatter;
