import React from 'react';
import { I18n } from 'aws-amplify/utils';
import {
    TextField,
    List,
    InputAdornment,
    ListItem
} from "@mui/material";

import './InstallationStateMonitoring.css';
import {Search} from "@mui/icons-material";
import NoDataPlaceholder from "../../NoDataPlaceholder/NoDataPlaceholder";
import PayStationFinder from "../../../../Utils/PayStationFinder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { stateType } from '../../../../Models/Report/Enums';


const defaultStateFilters = [ stateType.OFFLINE, stateType.ONLINE , stateType.PARTIALLY , stateType.STOPPED , stateType.UNKNOWN ];

export default function InstallationStateMonitoring( props ) {
    const [filterName, setFilterName] = React.useState( null );
    const [selected, setSelected] = React.useState( null );
    const [activeFilters, setActiveFilters] = React.useState( defaultStateFilters );

    const toggleFilterState = type => {
        const copy = JSON.parse( JSON.stringify( activeFilters ) );
        const index = copy.findIndex( candidate => candidate === type );
        if (index !== -1) {
            copy.splice(index, 1);
        } else {
            copy.push( type );
        }
        setActiveFilters( copy );
    };

    const onFilterNameChanges = evt => {
        setFilterName( evt.target.value );
    };

    const filterByName = list => {
        if( ! filterName ) {
            return list;
        }
        const filtered = [];
        if( list && list.length > 0 ) {
            list.forEach( payStation => {
                try {
                    if( payStation.box.informations.attributes.name.toLowerCase().includes( filterName.toLowerCase() ) ) {
                        filtered.push( payStation );
                    }
                } catch ( error ) {
                    console.error( error );
                }
            } );
        }
        return filtered;
    };

    const filterByState = list => {
        const filtered = [];
        if( list && list.length > 0 ) {
            list.forEach( payStation => {
                if( activeFilters.includes( extractGlobalState( payStation.devices ) ) ) {
                    filtered.push( payStation );
                }
            } );
        }
        return filtered;
    };

    const extractLatestReceptionDate = devices => {
        let last = '1970-01-01T00:00:00.000+00:00:00';
        devices?.forEach( device => {
            if( device?.lastState?.at >= last ) {
                last = device.lastState.at;
            }
        } );
        return formatDateToLocale( new Date( last ) );
    };

    const formatDateToLocale = date => {
        return date.toLocaleString( props.locale );
    };

    const extractGlobalState = devices => {
        let lowestState = stateType.UNKNOWN;
        let highestState = stateType.UNKNOWN;
        devices?.forEach( device => {
            const numericValue = stateType.fromName( device?.lastState?.state );
            if( numericValue && numericValue !== stateType.UNKNOWN ) {
                if( lowestState === stateType.UNKNOWN ) {
                    lowestState = numericValue;
                }
                lowestState = Math.min( lowestState , numericValue );

                if( highestState === stateType.UNKNOWN ) {
                    highestState = numericValue;
                }
                highestState = Math.max( highestState , numericValue );
            }
        } );

        if( highestState === lowestState ) {
            return highestState;
        } else {
            return stateType.PARTIALLY;
        }
    };

    const renderHeader = () => {
        return (
            <div className={`header-admin`}>
                <div className={`action-bar`}>
                    <div className={`title`}>{ I18n.get("Installations") }</div>
                    <div className={`count`}>({ ( filtered ) ? filtered.length : 0 })</div>
                    <div className={`spacer`}></div>
                    <div className={`actions`}>
                        {renderStateFilters()}
                    </div>
                </div>
                <div className={`filter-bar`}>
                    <TextField id="search-installations-states"
                               className={`search-field-expand`}
                               label={ I18n.get("Filter installation by name") }
                               variant="outlined"
                               size="small"
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <Search />
                                       </InputAdornment>
                                   ),
                               }}
                               onChange={ onFilterNameChanges }/>
                    <div className={`spacer`}></div>
                </div>
            </div>
        );
    };

    const renderStateFilters = () => {
        return (
            <>
                <div className={`button-filter-state ${stateType.getCssClass( stateType.OFFLINE )} ${activeFilters.includes( stateType.OFFLINE )?'selected':''}`}
                     onClick={ () => toggleFilterState( stateType.OFFLINE ) }>
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                    <div className={`state label`}>{I18n.get( stateType.getName( stateType.OFFLINE ) )}</div>
                </div>
                <div className={`button-filter-state ${stateType.getCssClass( stateType.ONLINE )} ${activeFilters.includes( stateType.ONLINE )?'selected':''}`}
                     onClick={ () => toggleFilterState( stateType.ONLINE ) }>
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                    <div className={`state label`}>{I18n.get( stateType.getName( stateType.ONLINE ) )}</div>
                </div>
                <div className={`button-filter-state ${stateType.getCssClass( stateType.PARTIALLY )} ${activeFilters.includes( stateType.PARTIALLY )?'selected':''}`}
                     onClick={ () => toggleFilterState( stateType.PARTIALLY ) }>
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                    <div className={`state label`}>{I18n.get( stateType.getName( stateType.PARTIALLY ) )}</div>
                </div>
                <div className={`button-filter-state ${stateType.getCssClass( stateType.STOPPED )} ${activeFilters.includes( stateType.STOPPED )?'selected':''}`}
                     onClick={ () => toggleFilterState( stateType.STOPPED ) }>
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                    <div className={`state label`}>{I18n.get( stateType.getName( stateType.STOPPED ) )}</div>
                </div>
                <div className={`button-filter-state ${stateType.getCssClass( stateType.UNKNOWN )} ${activeFilters.includes( stateType.UNKNOWN )?'selected':''}`}
                     onClick={ () => toggleFilterState( stateType.UNKNOWN ) }>
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                    <div className={`state label`}>{I18n.get( stateType.getName( stateType.UNKNOWN ) )}</div>
                </div>
            </>
        );
    };

    const renderListItem = payStation => {
        const state = "collapsed";
        const finder = new PayStationFinder( props.payStations );
        const boxName = finder.extractBoxName( payStation.box );
        const extra = ( payStation.terminals.length > 0 ) ? finder.buildTerminalExtraData( payStation.terminals[0] ) : 'Non renseigné';
        return (
            <ListItem key={payStation.box.id}
                      className={`payStation-card ${(selected === payStation.box.id)?'selected':''}`}
                      onClick={() => {
                          const selection = window.getSelection();
                          if (selection.toString().length > 0) {
                              return;
                          }
                          setSelected( payStation.box.id );
                          props.onSelection( payStation );
                          const expandable = document.querySelector( `.item-expandable .expandable.candidate-${payStation.box.id}` );
                          if( expandable ) {
                              if( expandable.classList.contains( state ) ) {
                                  expandable.classList.remove( state );
                              } else {
                                  expandable.classList.add( state );
                              }
                          }
                      }}>
                <div className={`item-expandable`}>
                    <div className={`main`}>
                        <div  className={`general-block`}>
                            <div className={`box-name`}>
                                <div className={`main`}>{boxName}</div>
                                <div className={`extra`}>{extra}</div>
                            </div>
                            <div className={`spacer`}></div>
                            <div className={`last-connection-block`}>
                                <div className={``}> {I18n.get( 'Last reception' )} : </div>
                                <div className={``}> {extractLatestReceptionDate( payStation.devices )} </div>
                            </div>
                            <div className={`spacer-fixed`}></div>
                            <div className={`state-block ${stateType.getCssClass( extractGlobalState( payStation.devices ) )}`}>
                                <FontAwesomeIcon icon="fa-solid fa-circle" />
                            </div>

                        </div>
                    </div>
                    <div className={`expandable ${state} payStation-${payStation.box.id}`}>

                    </div>
                </div>
            </ListItem>
        );
    };

    const filtered = filterByState( filterByName( props.payStations ) );
    const items = [];
    filtered?.sort( ( a , b ) => extractGlobalState( a.devices ) - extractGlobalState( b.devices ) );
    filtered?.forEach( candidate => {
        items.push( renderListItem( candidate ) );
    } );

    if( props.selected ) {
        if( selected !== props.selected.box.id ) {
            setSelected( props.selected.box.id );
        }
    }

    const shown = filtered.length < 1;

    return (
        <React.Fragment>
            <div id="installation-state-monitoring">
                {renderHeader()}
                <List>
                    {items.map( item => item )}
                </List>
                <NoDataPlaceholder className="comparison-placeholder-no-data"
                                   shown={shown}/>
            </div>
        </React.Fragment>
    );
}
