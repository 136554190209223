import React from 'react';
import ReactCountryFlag from "react-country-flag"
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sharedTheme } from '../../Style/SharedTheme';
import ExpandableCard from '../../Components/ExpandableCard/ExpandableCard';
import ChangePasswordDialog from '../../Components/ChangePasswordDialog/ChangePasswordDialog';
import { I18n } from 'aws-amplify/utils';
import './Account.css';

function DialogDetails( props ) {
	const [open, setOpen] = React.useState(false);
	
	const handleClickOpen = () => {
		setOpen(true);
	};
	
	const handleClose = () => {
		setOpen(false);
	};
	
	let css = "cm-dialog card-box";
	if( props.isDarkStyle ) {
		css += " dark";
	}
	
	return (
		<div className="btn-details text-center px-3 pb-2">
			<Button fullWidth className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark" onClick={event => handleClickOpen()}>
				<span className="btn-wrapper--label">
					{I18n.get("See details")}
				</span>
			</Button>
			<Dialog className={css} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
				{props.children}
			</Dialog>
		</div>
		
	);
}

function DeviceInfo( props ) {
	
	const getBrowserIcon = ( name ) => {
		if( name.toLowerCase() === "mobile safari" ) {
			return "safari";
		}
		return name.toLowerCase();
	}
	
	const getOsIcon = ( name ) => {
		if( name.toLowerCase() === "ios" ) {
			return "apple";
		}
		return name.toLowerCase();
	}
	
	const device = props.device;
	let os;

	if( device.properties.hasOwnProperty("os") ) {
		os = device.properties.os;
	} else {
		os = device.properties.osName;
	}
	
	let browserFullVersion = "";
	if( device.properties.hasOwnProperty("browserFullVersion") ) {
		browserFullVersion += `( ${device.properties.browserFullVersion} )`;
	} 
	
	return (
		<div className="current-device-online">
			<div className="layer">
				<div className="current-device-details">
					<div className="line os">
						<div className="label">{I18n.get("Operating system")}</div>
						<div className="value inline-display">
							<div className="browser-icon data-block">
								<FontAwesomeIcon icon={["fab" , getOsIcon(os)]} />
							</div>
							<div className="browser-name data-block">{os}</div>
							<div className="browser-short-version data-block">{/*device.properties.osVersion*/}</div>
						</div>
					</div>
					<div className="line browser">
						<div className="label">{I18n.get("Browser")}</div>
						<div className="value inline-display">
							<div className="browser-icon data-block">
								<FontAwesomeIcon icon={["fab" , getBrowserIcon(device.browserName)]} />
							</div>
							<div className="browser-name data-block">{device.browserName}</div>
							<div className="browser-short-version data-block">{device.browserVersion}</div>
							<div className="browser-full-version data-block small">{browserFullVersion}</div>
						</div>
					</div>
					<div className="line authentication-time">
						<div className="label">{I18n.get("Connected on this device since")}</div>
						<div className="value inline-display">
							<div className="browser-icon data-block">{props.authTimeLabel}</div>
						</div>
					</div>
					<div className="line authentication-place">
						<div className="label">{I18n.get("Protocol")}</div>
						<div className="value inline-display">
							<div className="browser-ip data-block">{device.ip} </div>
							<div className="browser-country data-block ">
								 <ReactCountryFlag
									countryCode={device.countryTag}
									svg
									cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
									cdnSuffix="svg"
									title={device.countryTag}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

let currentField = {
	id:null,
	lastChange:null,
	value:null
}
let instanceAccount;
class Account extends React.Component {
	
	constructor(props) {
		super(props);
		instanceAccount = this;
		this.defaultTimeoutForInputSaving = 3000;
		this.defaultIntervalForInputSaving = 5000;
		this.state = {
			open: false,
			openChangePassword:false,
			openSnackbar:false,
		}
	}

	updateData( property , value ) {
		if( this.props.user !== null && this.props.user !== undefined && property !== null ) {
			this.props.user.updatePreferences( property , value );
			//this.props.user.userProperties.properties[property] = value;
			//update useless state to redraw GUI and avoid to toggle expandableCard with outdated children
			this.setState({open: !this.state.open});
		}
	}
	
	handleInputChange( evt , idField , property ) {
		currentField = {
			id:idField,
			lastChange:new Date(),
			value:evt.target.value,
			property:property
		}
		setTimeout( () => {
			if( currentField.id !== null && currentField.id !== undefined ) {
				if( ( new Date().getTime() - currentField.lastChange.getTime() ) > instanceAccount.defaultTimeoutForInputSaving ) { 
					instanceAccount.updateData( currentField.property , currentField.value );
					currentField = {
						id:null,
						lastChange:null,
						value:null,
						property:null
					};
				} 
			} 
		} , instanceAccount.defaultIntervalForInputSaving );
	}
	
	handleInputBlur( evt , idField ) {
		if( currentField.id !== null && currentField.id !== undefined ) {
			instanceAccount.updateData( currentField.property , currentField.value );
			currentField = {
				id:null,
				lastChange:null,
				value:null,
				property:null
			};
		}
	}
	
	handlePasswordDialog() {
		instanceAccount.setState({
			open: instanceAccount.state.open,
			openChangePassword:true
		});
	}
	
	handleDialogClose() {
		instanceAccount.setState({
			open: instanceAccount.state.open,
			openChangePassword:false
		});
	}

	onPasswordRequestCompleted( state , errorIfExists ) {
		if( state === "success" ) {
			if( instanceAccount.props.observer ) {
				instanceAccount.props.observer.handleSnackbar( "success" , "Password has changed" );
			}
		} else if ( state === "failure" ) {
			if( instanceAccount.props.observer ) {
				instanceAccount.props.observer.handleSnackbar( "error" , errorIfExists );
			}
		}
	}
	
	getPropertyForLabel( label ) {
		if( label === I18n.get("Name") ) {
			return "contactName";
		}
		return null;
	}
	
	getValue( property ) {
		if( this.props.user !== null && 
			this.props.user !== undefined && 
			this.props.user.userProperties.properties.hasOwnProperty( property ) ) {
			return this.props.user.userProperties.properties[property];
		}
		return null;
	}
	
	getIconForDeviceType( type ) {
		if( type === "mobile" ) {
			return ["fas" , "mobile-alt" ];
		} else if( type === "tablet" ) {
			return ["fas" , "tablet-alt" ];
		} else {
			return ["fas" , "desktop" ]
		}
	}
	
	getDeviceCountExcludingCurrent(devices , type ) {
		if( type !== "browser" && 
			type !== "mobile" && 
			type !== "tablet" &&		
			type !== "all" ) {
			throw new Error( "Invalid type allowed for count extration : [desktop, mobile, tablet, all]" );	
		}
		let counter = 0;
		devices.map( (device) => { 
			if( ! device.equals( this.props.user.userProperties.getCurrentDevice() ) ) {
				if( device.deviceType === type ) {
					counter++;
				}
			} 
			return true;
		} );
		
		return counter;
	}

	getUserAttribute( key ) {
		let found = null;
		if( this.props.user.observer.currentUser &&
			this.props.user.observer.currentUser.attributes &&
			this.props.user.observer.currentUser.attributes.UserAttributes ) {
			this.props.user.observer.currentUser.attributes.UserAttributes.forEach( candidate => {
				if( candidate.Name === key ) {
					found = candidate.Value;
				}
			} );
		}
		return found;
	}

	renderVerificationBadge( label ) {
		let property;
		let value;
		let icon;
		if( label === I18n.get("Email") ) {
			property = this.getUserAttribute( "email_verified" );
			value = this.getUserAttribute( "email" );
			icon = ["fas" , "envelope"];
		} else if ( label === I18n.get("Phone") ) {
			property = this.getUserAttribute( "phone_number_verified" );
			value = this.getUserAttribute( "phone_number" );
			icon = ["fas" , "phone"];
		}
		
		if( property ) {
			const cleanLabel = label.replace(" " , "-");
			const key = `badge-verification-${cleanLabel}`;
			let labelBadge = I18n.get("Not Specified");
			if( property ) {
				labelBadge = I18n.get("Verified");
			} else {
				if( value ) {
					labelBadge = I18n.get("Pending verification");
				} 
			}
			return (
				<div key={key} className="verification-bagdge">
					<FontAwesomeIcon icon={icon} />
					<span className="state"> {labelBadge} </span>
				</div>
			);
		}
		return null;
	}
	
	renderEditableIcon( readOnly ) {
		if( ! readOnly ) {
			return (
				<InputAdornment position="start">
					<FontAwesomeIcon icon={["fas" , "pencil-alt" ]}/>
				</InputAdornment>
			);
		}
		return null;
	}
	
	renderLine( label , value , readOnly , extraLabel = '') {
		const cleanLabel = label.replace(" " , "-");
		const key = `textfield-${cleanLabel}`;
		const idField = `input-${key}`;

		return (
			<div key={key} className="line">
				<div className="label">
					{label}
					<div key={key} className="verification-bagdge">
						<span className="state"> {extraLabel} </span>
					</div>
					{this.renderVerificationBadge( label )}
				</div>
				<div className="value"> 
					<TextField
					  id={idField}
					  placeholder={I18n.get("Not Specified")}
					  defaultValue={value}
					  InputProps={{
						readOnly: readOnly,
						startAdornment: this.renderEditableIcon(readOnly),
					  }}
					  onChange={(evt) => this.handleInputChange( evt , idField , this.getPropertyForLabel( label ) ) }
					  onBlur={(evt) => this.handleInputBlur( evt , idField) }
					/>
				</div>
			</div>
		);
	}
	
	renderLargeButton( label , icon , onClick ) {
		return (
			<Button fullWidth className="d-flex btn-transition-none border-0 shadow-none btn-neutral-dark mb-2" onClick={onClick}>
				<span className="btn-wrapper--icon">
					<FontAwesomeIcon icon={icon} />
				</span>
				<span className="btn-wrapper--label">
					{label}
				</span>
			</Button>
		);
	}
	
	renderData() {
		if( this.props.user ) {
			let userRootNode = {resellerUUID:null, customerCode:null};
			/*if( this.props.userRDS && this.props.observer ) {
				userRootNode = this.props.observer.syncDataManager.registeredNodes[ this.props.userRDS["node_id"] ];
			}*/
			console.log("this.props.user.observer.currentUser" , this.props.user.observer.currentUser);
			const phoneNumberIfExists = this.getUserAttribute( "phone_number" );
			return (
				<div className="content">
					<Grid container spacing={2} >
						<Grid item xs={12} md={6} lg={6}>
							<div className="container">
								<div className="title">{I18n.get("Connection info")}</div>
								<div className="form">
									{this.renderLine(I18n.get("Username") , this.props?.user?.observer?.props?.user?.username , true )}
									{this.renderLine(I18n.get("Reseller Id") , this.props.user.observer.currentUser.creatorCode , true , this.props.user.observer.currentUser.creatorLabel )}
									{this.renderLine(I18n.get("Client Id") , this.props.user.observer.currentUser.accountCode , true , this.props.user.observer.currentUser.accountLabel )}
									{this.renderLargeButton(I18n.get("Change my password") , ['fas', 'key'] , this.handlePasswordDialog )}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<div className="container">
								<div className="title">{I18n.get("Contact info")}</div>
								<div className="form">
									{this.renderLine(I18n.get("Name") , this.props.user.observer.currentUser.label , true )}
									{this.renderLine(I18n.get("Email") , this.props.user.observer.currentUser.email , true )}
									{this.renderLine(I18n.get("Phone") , ( phoneNumberIfExists && phoneNumberIfExists !== "" ) ? phoneNumberIfExists : null , true )}
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			);
		}
		return null;
	}
	
	renderCurrentDevice( device , authTimeLabel ) {
		return (
			<DeviceInfo key="current-device-online" device={device} authTimeLabel={authTimeLabel}/>
		);
	}
	
	renderDialog( devices , currentDevice ) {
		const otherDevicesDetailsLines = [];
		devices.map( (device) => { 
			if( ! device.equals( this.props.user.userProperties.getCurrentDevice() ) ) {
				const index = devices.indexOf( device );
				const authDate = new Date( device.authTime * 1000 );
				const dateTimeDisplayed = authDate.toLocaleDateString() + " " + authDate.toLocaleTimeString().substring(0,5);

				otherDevicesDetailsLines.push(
					<ListItem 	button 
								key={`device-details-${index}`}
								onClick={event => {
									console.log("wait for pepito to ask for device management !!!");
								}}
								className="">
						<ListItemIcon>
							<FontAwesomeIcon icon={this.getIconForDeviceType( device.deviceType )} className="display-4"/>
						</ListItemIcon>
						<DeviceInfo  device={device} authTimeLabel={dateTimeDisplayed}/>
					</ListItem>
				);
			} 
			return true;
		} );
		if( otherDevicesDetailsLines.length === 0 ) {
			return (
				<DialogDetails isDarkStyle={this.props.isDarkStyle}>
					<div className="header card-header">
						<div className={sharedTheme.cardHeaderTitle}>
							<small className={sharedTheme.cardHeaderTitleSmallGrey}></small>
							<b className={sharedTheme.cardHeaderTitleBigBlack}> {I18n.get("Other connected devices")}  </b>
						</div>
					</div>
					<div className="No-device">
						{I18n.get("No other devices connected")}
					</div>
				</DialogDetails>
			);		
		} else {
			return(
				<DialogDetails isDarkStyle={this.props.isDarkStyle}>
					<div className="header card-header">
						<div className={sharedTheme.cardHeaderTitle}>
							<small className={sharedTheme.cardHeaderTitleSmallGrey}></small>
							<b className={sharedTheme.cardHeaderTitleBigBlack}> {I18n.get("Other connected devices")}  </b>
						</div>
					</div>
					<List component="div" disablePadding className="device-list">
						{otherDevicesDetailsLines.map( item => item )}
					</List>
				</DialogDetails>
			);
		}
	}
	
	renderOtherDevices( devices , currentDevice ) {
		const key = "other-device-online";

		return (
			<div key={key} className="other-device-online">
				<div className="table">
					<div className="column">
						<div className="cell icon">
							<FontAwesomeIcon icon={["fas" , "desktop" ]} />
						</div>	
						<div className="cell value">
							{this.getDeviceCountExcludingCurrent(devices , "browser")}
						</div>	
					</div>	
					<div className="column">
						<div className="cell icon">
							<FontAwesomeIcon icon={["fas" , "tablet-alt" ]} />
						</div>	
						<div className="cell value">
							{this.getDeviceCountExcludingCurrent(devices , "tablet")}
						</div>	
					</div>	
					<div className="column">
						<div className="cell icon">
							<FontAwesomeIcon icon={["fas" , "mobile-alt" ]} />
						</div>	
						<div className="cell value">
							{this.getDeviceCountExcludingCurrent(devices , "mobile")}
						</div>	
					</div>	
				</div>
				{this.renderDialog( devices , currentDevice )}
			</div>
		);
	}
	
	renderDevices() {
		if( this.props.observer.currentUser ) {
			const authDate = new Date( this.props.observer.currentUser.authTime * 1000 );
			const dateTimeDisplayed = authDate.toLocaleDateString() + " " + authDate.toLocaleTimeString().substring(0,5);

			return (
				<div className="content">
					<Grid container spacing={2} >
						<Grid item xs={12} md={6} lg={6}>
							<div className="container">
								<div className="title">{I18n.get("This devices")}</div>
								<div className="form">
									{this.renderCurrentDevice( this.props.user.userProperties.getCurrentDevice() , dateTimeDisplayed )}
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={6} lg={6}>
							<div className="container">
								<div className="title">{I18n.get("Other devices")}</div>
								<div className="form">
									{this.renderOtherDevices( this.props.user.userProperties.getDevices() , this.props.user.userProperties.getCurrentDevice() )}
								</div>
							</div>
						</Grid>
					</Grid>
				</div>
			);
		}
		return null;
	}
	
	renderCardContent( title ) {
		if( title === I18n.get("My Data") ) {
			return this.renderData();
		} else if ( title === I18n.get("My Devices") ) {
			return this.renderDevices();
		} else if ( title === I18n.get("My Messages") ) {
			
		} 
		return null;
	}
	
	renderCard( title ) {
		return (
			<ExpandableCard title={title}>
				{this.renderCardContent( title )}
			</ExpandableCard>
		);
	}
	
	render() {
		return (
			<React.Fragment>
				<div key="myAccount" className="Account-root z-over fullscreen">
					<Grid container spacing={2} >
						{this.renderCard(I18n.get("My Data"))}
						{this.renderCard(I18n.get("My Devices"))}
						{this.renderCard(I18n.get("My Messages"))}
					</Grid>
					<ChangePasswordDialog observer={this.props.observer}
										  readOnly={false}
										  opened={this.state.openChangePassword}
										  onDialogClose={this.handleDialogClose}
										  onPasswordRequestCompleted={this.onPasswordRequestCompleted}
										  isDarkStyle={this.props.isDarkStyle} />
				</div>
			</React.Fragment>
		);
	}
}

export default Account;
